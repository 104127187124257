import React, { useContext, useEffect, useState } from 'react'
import EachTableRowInCASW from '../../Components/CreateApplications/EachTableRowInCASW'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CreateApplicationSummaryWorkspace.module.css'

const CreateApplicationSummaryWorkspace = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, caswToggleHandler, selectedJob, jobIdentifier } = props
    // console.log(selectedJob)
    const [activeTab, setActiveTab] = useState('VIEWED')
    const [isLoading, setIsLoading] = useState(false)

    const [data, setData] = useState([])

    const getCASWData = async () => {
        setIsLoading(true)
        const getCASWDataResponse = await fetch(BASE_URL_API + "/getCASWData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier,
                    action: activeTab
                })
            });

        if (!getCASWDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCASWDataRespo = await getCASWDataResponse.json();
            if (getCASWDataRespo.status === "success") {
                setData(getCASWDataRespo.response)
            } else {
                if (getCASWDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setData([])
                }
            }
            console.log(getCASWDataRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (activeTab !== "") {
            getCASWData()
        }
    }, [activeTab])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.mhLeftWrapper}>
                    <div className={styles.profileTxt}>
                        <div>{selectedJob?.job_category_name}</div>
                        <div className={styles.salaryRange}>Offering &#8377;{selectedJob?.salary_min} - {selectedJob?.salary_max} for experience of {selectedJob?.experience}</div>
                    </div>
                    <div className={styles.storeDetailsWrapper}>
                        <div className={styles.storeName}>{selectedJob?.company_name}</div>
                        <div className={styles.storeAddress}>{selectedJob?.address}</div>
                    </div>
                </div>
                <div>
                    <div className={styles.modeWrapper}>
                        <div>Workspace mode</div>
                        {/* | <div>Analytics Mode</div> */}
                    </div>
                </div>
                <div>
                    <div className={styles.closeBtn}>
                        <button onClick={() => caswToggleHandler()}>Close</button>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.actionBtnWrapper}>
                    <button
                        className={activeTab === "VIEWED" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("VIEWED")}
                    >
                        Viewed
                    </button>
                    <button
                        className={activeTab === "CONTACTED" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("CONTACTED")}
                    >
                        Contacted
                    </button>
                    <button
                        className={activeTab === "HAD_CONVERSATION" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("HAD_CONVERSATION")}
                    >
                        HAD CONVERSATION
                    </button>
                    <button
                        className={activeTab === "COULD_NOT_HAD_CONVERSATION" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("COULD_NOT_HAD_CONVERSATION")}
                    >
                        COULD NOT HAD CONVERSATION
                    </button>
                    <button
                        className={activeTab === "PROFILE_NOT_CONSIDERED" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("PROFILE_NOT_CONSIDERED")}
                    >
                        Not Considered
                    </button>
                    <button
                        className={activeTab === "PROFILE_CONSIDERED" ? styles.activeActionBtn : styles.defaultActionBtn}
                        onClick={() => setActiveTab("PROFILE_CONSIDERED")}
                    >
                        Considered
                    </button>
                </div>

                {isLoading === false ?
                    <div>
                        <div className={styles.filterWrapper}>
                            {/* <select>
                        <option>Had Conversation</option>
                        <option>Had No Conversation</option>
                    </select> */}
                        </div>
                        <div className={styles.dataWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.srno}>#</div>
                                <div className={styles.fullname}>Full Name</div>
                                <div className={styles.phone}>Phone</div>
                                <div className={styles.workDomains}>Work Domains</div>
                                <div className={styles.minimumSalaryExpectation}>Expectations</div>
                                <div className={styles.lastContacted}>Action On</div>
                                <div className={styles.status}>Remarks</div>
                                <div className={styles.onStylelinkSince}>Registered On</div>
                                <div className={styles.action}>Action</div>
                            </div>
                            <div className={styles.tableBody}>
                                {data?.map((item, index) => (
                                    <EachTableRowInCASW item={item} key={index} count={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.loaderWrapper}>
                        <div className={styles.loaderContainer}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/pulsing-round.gif" />
                        </div>
                    </div>
                }


            </div>
        </div>
    )
}

export default CreateApplicationSummaryWorkspace