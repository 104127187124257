import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './EachStoreAuthPersonnelDetails.module.css'

const EachStoreAuthPersonnelDetails = props => {
    const { storeIdentifier } = props
    const [eachPerson, setEachPerson] = useState([])
    const authCtx = useContext(AuthContext)

    const getAuthPersonnelDetails = async () => {
        const getAuthPersonnelDetailsResponse = await fetch(BASE_URL_API+"/getStorePocs",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : storeIdentifier
            })
        });

        if(!getAuthPersonnelDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAuthPersonnelDetailsRespo = await getAuthPersonnelDetailsResponse.json()
            if(getAuthPersonnelDetailsRespo.status==='success') {
                setEachPerson(getAuthPersonnelDetailsRespo.response)
            } else {
                if(getAuthPersonnelDetailsRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setEachPerson([])
                }
            }
            console.log(getAuthPersonnelDetailsRespo)
        }
    }

    useEffect(() => {
        storeIdentifier && getAuthPersonnelDetails()
    }, [storeIdentifier])
    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                {eachPerson.map((item, index) => (
                    <div className={styles.eachPerson} key={index}>
                        <div>
                            <div>{item?.personnelName}</div>
                        </div>
                        <div className={styles.tagsWrapper}>
                            <div>
                                {item?.personnelGender}
                            </div>
                            <div>
                                {item?.designation}
                            </div>
                        </div>
                        <div className={styles.phone}>
                            +91-{item?.phoneNumber}
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default EachStoreAuthPersonnelDetails