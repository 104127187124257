import React, { useState } from 'react'
import Collapsible from 'react-collapsible';
import JobSelector from '../../Components/ManageJobs/JobSelector'
import styles from '../JobApplications/CreateApplications.module.css'
import CreateApplicationActivityStats from './CreateApplicationActivityStats';
import RelevantProfilesToJob from './RelevantProfilesToJob';


const CreateApplications = props => {
    const [selectedJob, setSelectedJob] = useState(null);
    const [activeCandidate, setActiveCandidate] = useState(null)

    const jobSelectHandler = (k) => {
        setSelectedJob(k);
        setActiveCandidate(null)
    }
    const activeCandidateHandler = (k) => setActiveCandidate(k)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <JobSelector
                    selectedJob={selectedJob}
                    jobSelectHandler={jobSelectHandler}
                />
            </div>
            <div className={styles.centerWrapper}>
                {selectedJob!==null &&
                    <RelevantProfilesToJob 
                        selectedJob={selectedJob}
                        activeCandidate={activeCandidate}
                        activeCandidateHandler={activeCandidateHandler}
                    />
                }
            </div>
            <div className={styles.rightWrapper}>
                <CreateApplicationActivityStats 
                    selectedJob={selectedJob}
                    activeCandidate={activeCandidate}
                />
            </div>
        </div>
    )
}

export default CreateApplications