import React from 'react'
import styles from './TasksRouter.module.css'
import { Link } from "react-router-dom";

const TasksRouter = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.header}>
                Hey... You landed right, we are just upgrading our systems..
            </div>
            <div className={styles.body}>
                <Link to={`/tasks`} >
                    <div className={styles.eachSection}>
                        Profile Leads
                    </div>
                </Link>
                <Link to={`/course-leads-converter`}>
                    <div className={styles.eachSection}>
                        Course Leads
                    </div>
                </Link>
                <Link to={`/jobs-tasks`} >
                    <div className={styles.eachSection}>
                        Jobs Task
                    </div>
                </Link>
                <div className={styles.eachSection}>
                    <div onClick={() => alert("Hold on underoos, JARVIS is getting this done!")}>
                        Salon Leads
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TasksRouter