import React, { useContext, useEffect, useState } from 'react'
import { BsSortNumericDown, BsSortNumericUp } from 'react-icons/bs';
import { FaSortNumericDown } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Partners/PincodeHolder.module.css'

const PincodeHolder = props => {
    const { io, aio, pcHandler, pc } = props;

    const authCtx = useContext(AuthContext)
    const [allPincodes, setAllPincodes] = useState([])
    const [sortDirection, setSortDirection] = useState("ASC")
    const [searchKey, setSearchKey] = useState('')

    const getPincodesPartnersLeads = async () => {
        const getPincodesPartnersLeadsResponse = await fetch(BASE_URL_API + "/getPincodesPartnersLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!getPincodesPartnersLeadsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPincodesPartnersLeadsRespo = await getPincodesPartnersLeadsResponse.json();
            if (getPincodesPartnersLeadsRespo.status === "success") {
                setAllPincodes(getPincodesPartnersLeadsRespo.response)
            } else {
                if (getPincodesPartnersLeadsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllPincodes([])
                    console.log(getPincodesPartnersLeadsRespo)
                }
            }
        }
    }

    useEffect(() => {
        getPincodesPartnersLeads()
    }, [])

    window.onclick = function (event) {
        if (event.target.id === "pwfcsd") {
            aio();
        }
    }




    if (!io) return null;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="pwfcsd" >
                <div className={styles.contentWrapper}>
                    <div className={styles.pincodeHeader}>
                        <div className={styles.searchBarWrapper}>
                            <input className={styles.searchBar} placeholder={"Search among " + allPincodes.length + " available pincodes.."}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </div>
                        <div>
                            <button className={styles.sortingBtn} onClick={() => setSortDirection(sortDirection==="DESC" ? "ASC" : "DESC")} >
                                {sortDirection === "DESC" ? <BsSortNumericUp size={20} /> : <BsSortNumericDown size={20} />}
                            </button>
                        </div>
                    </div>
                    <div className={styles.pincodesWrapper}>
                        {allPincodes.filter((item) => item.pincode.toLowerCase().includes(searchKey)).sort((a, b) => {
                            if (sortDirection === 'ASC') {
                                return a.pincode.localeCompare(b.pincode);
                            } else {
                                return b.pincode.localeCompare(a.pincode);
                            }
                        }).map((item, index) => (
                            <div key={index} className={pc.includes(item.pincode) ? styles.eachPincodeSelected : styles.eachPincode} onClick={() => pcHandler(item.pincode)}>
                                <div>
                                    {item.pincode}
                                </div>
                                <div>
                                    ({item.volume})
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PincodeHolder