import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import EachClientRow from '../../Components/ManageClients/EachClientRow'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ManageClientsHome.module.css'

const ManageClientsHome = () => {
    const authCtx = useContext(AuthContext)
    const [allClients, setAllClients] = useState([])
    const [allExecutives, setAllExecutives] = useState([])

    const getAllPartnersAccount = async () => {
        const getAllPartnersAccountResponse = await fetch(BASE_URL_API + "/getAllPartnersAccount",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllPartnersAccountResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllPartnersAccountRespo = await getAllPartnersAccountResponse.json()
            if (getAllPartnersAccountRespo.status === "success") {
                setAllClients(getAllPartnersAccountRespo.response)
            } else {
                if (getAllPartnersAccountRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllPartnersAccountRespo)
        }
    }

    useEffect(() => {
        getAllPartnersAccount()
    }, [])

    
    
    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API+"/adminGetAllExecutives",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });
        if(!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json()
            if(adminGetAllExecutivesRespo.status==="success") {
                setAllExecutives(adminGetAllExecutivesRespo.response)
            } else {
                if(adminGetAllExecutivesRespo.message==="token Expired, please login again!") {
                
                } else {
                    setAllExecutives([])
                }
            }
            console.log(adminGetAllExecutivesRespo)
        }
    }
    useEffect(() => {
        adminGetAllExecutives()
    }, [])

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <Link to={`/`}>
                        Home
                    </Link>
                </div>
                <div>
                    <div className={styles.filtersWrapper}>
                        <div className={styles.optionsWrapper}>
                            <button className={styles.eachOption}>Assigned</button>
                            <button className={styles.eachOption}>Un-Assigned</button>
                        </div>
                        <div className={styles.searchNFilterWrapper}>
                            <div>
                                <input />
                            </div>
                            <div>
                                <FaFilter />
                            </div>
                        </div>
                    </div>
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableHeader}>
                            <div className={styles.sn}>#</div>
                            <div className={styles.companyName}>Company Name / Address</div>
                            <div className={styles.storesCount}>Total Store(s)</div>
                            <div className={styles.onboardedOn}>Onboarded On</div>
                            <div className={styles.assignedTo}>Assigned To</div>
                            <div className={styles.planType}>Plan Type</div>
                            <div className={styles.planActivatedOn}>Plan Activated On</div>
                            <div className={styles.actions}>Actions</div>
                        </div>
                        <div className={styles.tableBody}>
                            {allClients?.map((item, index) => (
                                <EachClientRow key={index} item={item} count={index} allExecutives={allExecutives} />
                            ))}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ManageClientsHome