import React, { useContext, useEffect, useState } from 'react'
import styles from '../ManageUsers/SearchUserBar.module.css'
import { FaFilter, FaUserCircle } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import Collapsible from 'react-collapsible';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { Link } from 'react-router-dom';



const SearchUserBar = props => {
    const selectUserHandlerMain = props.selectUserHandlerMain;
    const selectedUser = props.selectedUser;
    const authCtx = useContext(AuthContext);
    const [userSelectionStatus, setUserSelectionStatus] = useState(false);
    const [searchingStatus, setSearchingStatus] = useState('NOT_FETCHING'); // NOT_FETCHING,  FETCHING, NO_RESULTS_FOUND, RESULTS
    const [resultSuggested, setResultSuggested] = useState([
        {
            "identifier": "1",
            "stylist_name": "navneet"
        }
    ])

    const [profileData, setProfileData] = useState([])
    const [locationsData, setLocationsData] = useState([])
    const [selectedLocations, setSelectedLocations] = useState([])


    const [phoneNumber, setPhoneNumber] = useState('')
    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [fieldsValidationStatus, setFieldsValidationStatus] = useState(false);
    const [creatingAccountStatus, setCreatingAccountStatus] = useState('NOT_CREATING'); // CREATING, CREATED, CREATION_FAILED, ALREADY_REGISTERED

    const [showSearchBar, setShowSearchBar] = useState(false)


    const searchKeyHandler = async (k) => {
        if (k.length > 4) {
            fetchSuggestedUsers(k)
            // setSearchingStatus('NO_RESULTS_FOUND')
            // setSearchingStatus('RESULTS')
        } else {
            setSearchingStatus('NOT_FETCHING')
        }
    }

    const fetchSuggestedUsers = async (k) => {
        setSearchingStatus('FETCHING');
        const fetchSuggestedUsersResponse = await fetch(BASE_URL_API + "/fetchSuggestedUsers",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    key: k,
                    sortBy: sortBy,
                    genderType: genderType,
                    maritalStatus: maritalStatus,
                    primaryWorkDomain: selectedPrimaryWorkDomain,
                    secondaryWorkDomain: selectedSecondaryWorkDomain,
                    tertiaryWorkDomain: selectedTertiaryWorkDomain,
                    workDomainAtAnyPreference: selectedWorkDomainAtAnyPreference,
                    fromDate: fromDate,
                    toDate: toDate,
                    minAge: minAge,
                    maxAge: maxAge,
                    minSalary: minSalary,
                    maxSalary: maxSalary,
                    desiredLocations: selectedLocations
                })
            });

        if (!fetchSuggestedUsersResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const fetchSuggestedUsersRespo = await fetchSuggestedUsersResponse.json();
            if (fetchSuggestedUsersRespo.status === 'success') {
                setSearchingStatus('RESULTS')
                setResultSuggested(fetchSuggestedUsersRespo.response)
            } else {
                if (fetchSuggestedUsersRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else if (fetchSuggestedUsersRespo.message === 'no records found') {
                    setSearchingStatus('NO_RESULTS_FOUND');
                    console.log(fetchSuggestedUsersRespo.response)
                } else {
                    setSearchingStatus('NOT_FETCHING');
                }
            }
            // console.log(fetchSuggestedUsersRespo);
        }
    }



    const createAccountHandler = async () => {
        // console.log(authCtx.userIdentifier)
        setCreatingAccountStatus('CREATING')
        const registerUserResponse = await fetch(BASE_URL_API + "/registerUser",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    phone: phoneNumber,
                    fullName: fullName,
                    stylist_gender: gender,
                    stylist_birthdate: dob,
                    stylist_pincode: '110016'
                })
            });

        if (!registerUserResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const registerUserRespo = await registerUserResponse.json();
            if (registerUserRespo.message === 'token Expired, please login again!') {
                authCtx.logout();
            } else {
                console.log(registerUserRespo);
                if (registerUserRespo.message === "successfully registered") {
                    if (registerUserRespo.response.registrationStatus === '1') {
                        setCreatingAccountStatus('ALREADY_REGISTERED');
                    } else if (registerUserRespo.response.registrationStatus === '2') {
                        setCreatingAccountStatus('CREATED');
                    }
                } else {
                    setCreatingAccountStatus('CREATION_FAILED');
                    console.log(registerUserRespo);
                }
            }
        }
    }

    const selectUserHandler = async (k) => {
        setUserSelectionStatus(true)
        selectUserHandlerMain(k)
    }
    const cancelSelectionHandler = () => {
        setUserSelectionStatus(false)
        selectUserHandlerMain(null)
    }

    useEffect(() => {
        if (phoneNumber.length === 10) {
            if (fullName.length > 1) {
                if (gender.length > 0) {
                    if (dob.length > 0) {
                        setFieldsValidationStatus(true)
                    } else {
                        setFieldsValidationStatus(false)
                    }
                } else {
                    setFieldsValidationStatus(false)
                }
            } else {
                setFieldsValidationStatus(false)
            }
        } else {
            setFieldsValidationStatus(false)
        }
    }, [phoneNumber, fullName, gender, dob])


    const openFilterPanel = () => {
        document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft 0.5s forwards";
    }

    window.onclick = function (event) {
        if (event.target.id === "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideLeftToRight .1s forwards";
        }
    }

    document.addEventListener('keydown', function (e) {
        if (e.keyCode === 27) {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideLeftToRight .1s forwards";
        }
    });

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === 'success') {
                setProfileData(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getCategoryOptionsRespo);
                }
            }
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])

    // --------- filters start here ------ 
    const [sortBy, setSortBy] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [genderType, setGenderType] = useState([])
    const [selectedWorkDomainAtAnyPreference, setSelectedWorkDomainAtAnyPreference] = useState([])
    const [selectedPrimaryWorkDomain, setSelectedPrimaryWorkDomain] = useState([])
    const [selectedSecondaryWorkDomain, setSelectedSecondaryWorkDomain] = useState([])
    const [selectedTertiaryWorkDomain, setSelectedTertiaryWorkDomain] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [minAge, setMinAge] = useState('')
    const [maxAge, setMaxAge] = useState('')
    const [minSalary, setMinSalary] = useState('')
    const [maxSalary, setMaxSalary] = useState('')

    const setGenderTypeHandler = (k) => {
        if (genderType.includes(k) === true) {
            setGenderType(genderType.filter((item) => item !== k));
        } else {
            setGenderType([...genderType, k]);
        }
    }

    const workDomainSelectHandler = (k, v) => {
        if (k === 'primaryWorkDomain') {
            if (selectedPrimaryWorkDomain.includes(v) === true) {
                setSelectedPrimaryWorkDomain(selectedPrimaryWorkDomain.filter((item) => item !== v));
            } else {
                setSelectedPrimaryWorkDomain([...selectedPrimaryWorkDomain, v]);
            }
        }
        if (k === 'secondaryWorkDomain') {
            if (selectedSecondaryWorkDomain.includes(v) === true) {
                setSelectedSecondaryWorkDomain(selectedSecondaryWorkDomain.filter((item) => item !== v));
            } else {
                setSelectedSecondaryWorkDomain([...selectedSecondaryWorkDomain, v]);
            }
        }
        if (k === 'tertiaryWorkDomain') {
            if (selectedTertiaryWorkDomain.includes(v) === true) {
                setSelectedTertiaryWorkDomain(selectedTertiaryWorkDomain.filter((item) => item !== v));
            } else {
                setSelectedTertiaryWorkDomain([...selectedTertiaryWorkDomain, v]);
            }
        }
        if (k === 'workDomainAtAnyPreference') {
            if (selectedWorkDomainAtAnyPreference.includes(v) === true) {
                setSelectedWorkDomainAtAnyPreference(selectedWorkDomainAtAnyPreference.filter((item) => item !== v));
            } else {
                setSelectedWorkDomainAtAnyPreference([...selectedWorkDomainAtAnyPreference, v]);
            }
        }
    }

    const domainAllCheckHandler = (k) => {
        let temp = [];
        profileData.map((item, index) => {
            temp.push(item.ID);
        })

        if (k === 'workDomainAtAnyPreference') {
            if (selectedWorkDomainAtAnyPreference.length === profileData.length) {
                setSelectedWorkDomainAtAnyPreference([])
            } else {
                setSelectedWorkDomainAtAnyPreference(temp);
            }
        }

        if (k === 'primaryWorkDomain') {
            if (selectedPrimaryWorkDomain.length === profileData.length) {
                setSelectedPrimaryWorkDomain([])
            } else {
                setSelectedPrimaryWorkDomain(temp);
            }
        }

        if (k === 'secondaryWorkDomain') {
            if (selectedSecondaryWorkDomain.length === profileData.length) {
                setSelectedSecondaryWorkDomain([])
            } else {
                setSelectedSecondaryWorkDomain(temp);
            }
        }

        if (k === 'tertiaryWorkDomain') {
            if (selectedTertiaryWorkDomain.length === profileData.length) {
                setSelectedTertiaryWorkDomain([])
            } else {
                setSelectedTertiaryWorkDomain(temp);
            }
        }

        console.log(k)
    }

    const toggleSalaryRangeSelector = () => {
        setMinSalary('');
        setMaxSalary('')
    }

    const toggleLocationsHandler = (k) => {
        if (selectedLocations.includes(k) === true) {
            setSelectedLocations(selectedLocations.filter((item) => item !== k));
        } else {
            setSelectedLocations([...selectedLocations, k])
        }
    }

    const locationAllCheckHandler = (k) => {
        let tempAreas = [];
        JSON.parse(k).map((item) => {
            if (selectedLocations.includes(item.id) === true) {

            } else {
                tempAreas.push(item.id)
            }
        })
        let mergedArr = selectedLocations.concat(tempAreas);
        setSelectedLocations(mergedArr);
    }
    // console.log(selectedLocations)

    useEffect(() => {
        fetchSuggestedUsers()
    }, [sortBy, genderType, maritalStatus, selectedPrimaryWorkDomain,
        selectedSecondaryWorkDomain, selectedTertiaryWorkDomain, selectedWorkDomainAtAnyPreference,
        fromDate, toDate, minAge, maxAge, minSalary, maxSalary, selectedLocations
    ])

    const getAreasLocationWise = async () => {
        const getAreasLocationWiseResponse = await fetch(BASE_URL_API + "/getAreasLocationWise",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAreasLocationWiseResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAreasLocationWiseRespo = await getAreasLocationWiseResponse.json();
            if (getAreasLocationWiseRespo.status === 'success') {
                setLocationsData(getAreasLocationWiseRespo.response)
            } else {
                if (getAreasLocationWiseRespo.message === 'failed') {


                } else {
                    console.log(getAreasLocationWiseRespo);
                }
            }
            // console.log(getAreasLocationWiseRespo);
        }
    }

    useEffect(() => {
        getAreasLocationWise();
    }, [])


    return (
        <div className={styles.searchBarWrapper}>
            <div>
                <div className={styles.searchBarContainer}>
                    <div className={styles.searchBar}>
                        <div className={styles.searchBarInput}>
                            <input placeholder="Please enter user id / phone / e-mail..."
                                onChange={(e) => searchKeyHandler(e.target.value)}
                            />
                        </div>
                        <div className={styles.filterBtnWrapper}>
                            <div onClick={() => openFilterPanel()}>
                                <FaFilter />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.filtersPanelWrapper}
                    id="filtersPanelWrapperMUG"
                >
                    <div className={styles.filtersContainer}>
                        <div className={styles.sortingColWrapper}>
                            <div className={styles.colHeader}>Sort By</div>
                            <div>
                                <div className={styles.eachSortingActionsWrapper}>
                                    <button className={sortBy === 'REGISTRATION_DATE_DESC' ? styles.sortingBtnSelected : styles.sortingBtn}
                                        onClick={() => setSortBy('REGISTRATION_DATE_DESC')}
                                    >
                                        Registration Date - Newest First
                                    </button>
                                    <button className={sortBy === 'REGISTRATION_DATE_ASC' ? styles.sortingBtnSelected : styles.sortingBtn}
                                        onClick={() => setSortBy('REGISTRATION_DATE_ASC')}
                                    >
                                        Registration Date - Newest Last
                                    </button>
                                </div>
                            </div>


                            <div className={styles.registrationDateRangeWrapper}>
                                <div className={styles.rdrwHeader}>
                                    Registration Date
                                </div>
                                <div className={styles.dateSelectorWrapper}>
                                    <div>
                                        <div className={styles.dateTitle}>From</div>
                                        <div className={styles.dateFieldWrapper}>
                                            <input type="date"
                                                onChange={(e) => setFromDate(e.target.value)}
                                                max={toDate}
                                                value={fromDate}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.dateTitle}>To</div>
                                        <div className={styles.dateFieldWrapper}>
                                            <input type="date"
                                                onChange={(e) => setToDate(e.target.value)}
                                                min={fromDate}
                                                value={toDate}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                        <div className={styles.filterColWrapper}>
                            <div className={styles.colHeader}>
                                Filters
                            </div>
                            <div className={styles.workDomainColWrapper}>

                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Work Domain At Any Preference
                                                ({selectedWorkDomainAtAnyPreference.length}/{profileData.length})
                                            </div>
                                            <div onClick={() => domainAllCheckHandler('workDomainAtAnyPreference')}>
                                                {selectedWorkDomainAtAnyPreference.length === profileData.length ?
                                                    <ImCheckboxChecked color="green" size={16} />
                                                    :
                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.eachWorkDomainBody}>
                                            {profileData.map((item, index) => (
                                                <div className={styles.eachProfileWrapper}
                                                    onClick={() => workDomainSelectHandler('workDomainAtAnyPreference', item.ID)}
                                                    key={"SUIN" + index}
                                                >
                                                    <div className={styles.checkBoxContainer}>
                                                        {selectedWorkDomainAtAnyPreference.includes(item.ID) === true ?
                                                            <ImCheckboxChecked color="green" size={16} />
                                                            :
                                                            <ImCheckboxUnchecked color="#00000086" size={16} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {item.category_name}
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </Collapsible>
                                </div>

                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Primary Work Domain
                                                ({selectedPrimaryWorkDomain.length}/{profileData.length})
                                            </div>
                                            <div onClick={() => domainAllCheckHandler('primaryWorkDomain')}>
                                                {selectedPrimaryWorkDomain.length === profileData.length ?
                                                    <ImCheckboxChecked color="green" size={16} />
                                                    :
                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.eachWorkDomainBody}>
                                            {profileData.map((item, index) => (
                                                <div className={styles.eachProfileWrapper}
                                                    onClick={() => workDomainSelectHandler('primaryWorkDomain', item.ID)}
                                                    key={"SU" + index}
                                                >
                                                    <div className={styles.checkBoxContainer}>
                                                        {selectedPrimaryWorkDomain.includes(item.ID) === true ?
                                                            <ImCheckboxChecked color="green" size={16} />
                                                            :
                                                            <ImCheckboxUnchecked color="#00000086" size={16} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {item.category_name}
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </Collapsible>
                                </div>

                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Secondary Work Domain
                                                ({selectedSecondaryWorkDomain.length}/{profileData.length})
                                            </div>
                                            <div onClick={() => domainAllCheckHandler('secondaryWorkDomain')}>
                                                {selectedSecondaryWorkDomain.length === profileData.length ?
                                                    <ImCheckboxChecked color="green" size={16} />
                                                    :
                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                }
                                            </div>

                                        </div>
                                    }
                                    >
                                        <div className={styles.eachWorkDomainBody}>
                                            {profileData.map((item, index) => (
                                                <div className={styles.eachProfileWrapper}
                                                    onClick={() => workDomainSelectHandler('secondaryWorkDomain', item.ID)}
                                                    key={"SUPER" + index}
                                                >
                                                    <div className={styles.checkBoxContainer}>
                                                        {selectedSecondaryWorkDomain.includes(item.ID) === true ?
                                                            <ImCheckboxChecked color="green" size={16} />
                                                            :
                                                            <ImCheckboxUnchecked color="#00000086" size={16} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {item.category_name}
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </Collapsible>
                                </div>

                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Tertiary Work Domain
                                                ({selectedTertiaryWorkDomain.length}/{profileData.length})
                                            </div>
                                            <div onClick={() => domainAllCheckHandler('tertiaryWorkDomain')}>
                                                {selectedTertiaryWorkDomain.length === profileData.length ?
                                                    <ImCheckboxChecked color="green" size={16} />
                                                    :
                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.eachWorkDomainBody}>
                                            {profileData.map((item, index) => (
                                                <div className={styles.eachProfileWrapper}
                                                    onClick={() => workDomainSelectHandler('tertiaryWorkDomain', item.ID)}
                                                    key={"AMA" + index}
                                                >
                                                    <div className={styles.checkBoxContainer}>
                                                        {selectedTertiaryWorkDomain.includes(item.ID) === true ?
                                                            <ImCheckboxChecked color="green" size={16} />
                                                            :
                                                            <ImCheckboxUnchecked color="#00000086" size={16} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {item.category_name}
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </Collapsible>
                                </div>

                            </div>

                            <div className={styles.genderBoxColWrapper}>
                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Gender
                                            </div>
                                            <div>
                                                ({genderType.length}/2)
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.optionsWrapper}>
                                            <button
                                                className={styles.eachOptionWrapper}
                                                onClick={() => setGenderTypeHandler('FEMALE')}
                                            >
                                                <div className={styles.checkBoxContainer}>
                                                    {genderType.includes('FEMALE') === true ?
                                                        <ImCheckboxChecked color="green" size={16} />
                                                        :
                                                        <ImCheckboxUnchecked color="#00000086" size={16} />
                                                    }
                                                </div>
                                                <div>
                                                    Female
                                                </div>
                                            </button>

                                            <button
                                                className={styles.eachOptionWrapper}
                                                onClick={() => setGenderTypeHandler('MALE')}
                                            >
                                                <div className={styles.checkBoxContainer}>
                                                    {genderType.includes('MALE') === true ?
                                                        <ImCheckboxChecked color="green" size={16} />
                                                        :
                                                        <ImCheckboxUnchecked color="#00000086" size={16} />
                                                    }
                                                </div>
                                                <div>
                                                    Male
                                                </div>
                                            </button>


                                        </div>
                                    </Collapsible>

                                </div>

                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            Age
                                            <div>
                                                {minAge} - {maxAge}
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.dateRangeSliderWrapper}>
                                            <RangeSlider
                                                min={14}
                                                max={65}
                                                onInput={(e) => {
                                                    setMinAge(e[0])
                                                    setMaxAge(e[1])
                                                }}
                                                value={[minAge, maxAge]}
                                            />
                                        </div>
                                    </Collapsible>
                                </div>


                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            <div>
                                                Marital Status
                                            </div>
                                            <div>
                                                ({maritalStatus !== '' && "selected"})
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.optionsWrapper}>
                                            <button
                                                className={styles.eachOptionWrapper}
                                                onClick={() => setMaritalStatus(maritalStatus === 'Married' ? '' : 'Married')}
                                            >
                                                <div className={styles.checkBoxContainer}>
                                                    {maritalStatus === 'Married' ?
                                                        <ImCheckboxChecked color="green" size={16} />
                                                        :
                                                        <ImCheckboxUnchecked color="#00000086" size={16} />
                                                    }
                                                </div>
                                                <div>
                                                    Married
                                                </div>
                                            </button>
                                            <button
                                                className={styles.eachOptionWrapper}
                                                onClick={() => setMaritalStatus(maritalStatus === 'Un-married' ? '' : 'Un-married')}
                                            >
                                                <div className={styles.checkBoxContainer}>
                                                    {maritalStatus === 'Un-married' ?
                                                        <ImCheckboxChecked color="green" size={16} />
                                                        :
                                                        <ImCheckboxUnchecked color="#00000086" size={16} />
                                                    }
                                                </div>
                                                <div>
                                                    Un-married
                                                </div>
                                            </button>
                                            <button
                                                className={styles.eachOptionWrapper}
                                                onClick={() => setMaritalStatus(maritalStatus === 'Unknown' ? '' : 'Unknown')}
                                            >
                                                <div className={styles.checkBoxContainer}>
                                                    {maritalStatus === 'Unknown' ?
                                                        <ImCheckboxChecked color="green" size={16} />
                                                        :
                                                        <ImCheckboxUnchecked color="#00000086" size={16} />
                                                    }
                                                </div>
                                                <div>
                                                    Unknown
                                                </div>
                                            </button>
                                        </div>
                                    </Collapsible>
                                </div>


                                <div>
                                    <Collapsible trigger={
                                        <div className={styles.eachWorkDomainTitle}>
                                            Salary Expectation
                                            ({minSalary} - {maxSalary})
                                            <div
                                                onClick={() => toggleSalaryRangeSelector()}
                                            >
                                                {minSalary !== '' && maxSalary !== '' ?
                                                    <ImCheckboxChecked color="green" size={16} />
                                                    :
                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                }
                                            </div>
                                        </div>
                                    }
                                    >
                                        <div className={styles.dateRangeSliderWrapper}>
                                            <RangeSlider
                                                min={2000}
                                                max={65000}
                                                onInput={(e) => {
                                                    setMinSalary(e[0])
                                                    setMaxSalary(e[1])
                                                }}
                                                value={[minSalary, maxSalary]}
                                                step={500}
                                            />
                                        </div>
                                    </Collapsible>
                                </div>





                            </div>


                        </div>

                        <div className={styles.locationColWrapper}>
                            <div className={styles.colHeader}>
                                <div>
                                    Location Filters
                                </div>
                                <div className={styles.selectedLocationsCounts}>
                                    [{selectedLocations.length} selected]
                                </div>
                            </div>
                            {locationsData.length > 0 &&
                                <div className={styles.locationColBody}>
                                    {locationsData.map((item, index) => (
                                        <div className={styles.eachLocationMainWrapper}
                                            key={index + "Tatiana"}
                                        >
                                            <Collapsible trigger={
                                                <div className={styles.eachWorkDomainTitle}>
                                                    <div>
                                                        {item.city_name}
                                                        ({JSON.parse(item.local_areas).length})
                                                    </div>
                                                    <div onClick={() => locationAllCheckHandler(item.local_areas)}>
                                                        {selectedPrimaryWorkDomain.length === profileData.length ?
                                                            <ImCheckboxChecked color="green" size={16} />
                                                            :
                                                            <ImCheckboxUnchecked color="#00000086" size={16} />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            >
                                                <div className={styles.eachLocationBodyWrapper}>
                                                    {JSON.parse(item.local_areas).map((itemArea, indexArea) => (
                                                        <button
                                                            className={styles.eachOptionWrapper}
                                                            onClick={() => toggleLocationsHandler(itemArea.id)}
                                                            key={"kondzilla" + indexArea}
                                                        >
                                                            <div className={styles.checkBoxContainer}>
                                                                {selectedLocations.includes(itemArea.id) === true ?
                                                                    <ImCheckboxChecked color="green" size={16} />
                                                                    :
                                                                    <ImCheckboxUnchecked color="#00000086" size={16} />
                                                                }
                                                            </div>
                                                            <div>
                                                                {/* {itemArea.id} */}
                                                                {itemArea.areaName}
                                                            </div>
                                                        </button>
                                                    ))}
                                                </div>
                                            </Collapsible>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>



                    </div>
                </div>

                <div className={styles.responseWrapper}>
                    {searchingStatus === 'FETCHING' &&
                        <div className={styles.findingResponseWrapper}>
                            finding the perfect match...
                        </div>
                    }

                    {searchingStatus === 'NO_RESULTS_FOUND' &&
                        <div className={styles.noResultsWrapper}>
                            <div className={styles.noResultsFoundWrapper}>
                                No results found!
                            </div>
                            <div className={styles.addNewUserWrapper}>
                                {creatingAccountStatus === 'NOT_CREATING' ?
                                    <div>
                                        <div className={styles.addNewUserTitle}>
                                            Add New User!
                                        </div>
                                        <div>
                                            <div className={styles.eachInputFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Phone Number
                                                </div>
                                                <div className={styles.eachFieldInput}>
                                                    <input placeholder="99XX64XX06" type="number"
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        value={phoneNumber}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.eachInputFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Full Name
                                                </div>
                                                <div className={styles.eachFieldInput}>
                                                    <input placeholder="Artist name..."
                                                        onChange={(e) => setFullName(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.eachInputFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Gender
                                                </div>
                                                <div className={styles.eachFieldOptions}>
                                                    <button className={gender === 'FEMALE' ? styles.eachOptionSelected : styles.eachOption}
                                                        onClick={() => setGender('FEMALE')}
                                                    >
                                                        Female
                                                    </button>
                                                    <button className={gender === 'MALE' ? styles.eachOptionSelected : styles.eachOption}
                                                        onClick={() => setGender('MALE')}
                                                    >
                                                        Male
                                                    </button>
                                                </div>
                                            </div>

                                            <div className={styles.eachInputFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Date of Birth
                                                </div>
                                                <div className={styles.eachFieldOptions}>
                                                    <input type="date"
                                                        onClick={(e) => setDob(e.target.value)}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                        <div className={styles.btnsWrapper}>
                                            <div className={styles.secondaryBtnWrapper}>
                                                Cancel
                                            </div>
                                            {fieldsValidationStatus === true ?
                                                <div className={styles.primaryBtnWrapper}
                                                    onClick={() => createAccountHandler()}
                                                >
                                                    Create Account
                                                </div>
                                                :
                                                <div className={styles.primaryBtnWrapperDull}>
                                                    Create Account
                                                </div>
                                            }

                                        </div>

                                    </div>
                                    :
                                    creatingAccountStatus === 'CREATING' ?
                                        <div className={styles.accountCreatingStatusWrapper}>
                                            <div className={styles.addNewUserTitle}>
                                                Adding a new user...
                                            </div>
                                            <div className={styles.accountCreatingStatusBody}>
                                                Please wait for a moment...
                                                <p>
                                                    I'm just creating an account on your request!
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        creatingAccountStatus === 'CREATED' ?
                                            <div className={styles.accountCreatingStatusWrapper}>
                                                <div className={styles.addNewUserTitle}>
                                                    Account Created Successfully!
                                                </div>
                                                <div className={styles.accountCreatingStatusBody}>
                                                    Done!
                                                    <p>
                                                        Stylist registered, you can search for this user now!
                                                    </p>
                                                    <button onClick={() => {
                                                        setCreatingAccountStatus('NOT_CREATING');
                                                        setSearchingStatus('NOT_FETCHING')
                                                    }}>Search Now</button>
                                                </div>
                                            </div>
                                            :
                                            creatingAccountStatus === 'ALREADY_REGISTERED' ?
                                                <div className={styles.accountCreatingStatusWrapper}>
                                                    <div className={styles.addNewUserTitle}>
                                                        User already registered!
                                                    </div>
                                                    <div className={styles.accountCreatingStatusBody}>
                                                        Phone number already registered!
                                                        <p>
                                                            Please try to search with the phone number again!
                                                        </p>
                                                        <button onClick={() => {
                                                            setCreatingAccountStatus('NOT_CREATING');
                                                            setSearchingStatus('NOT_FETCHING')
                                                        }}>Search User</button>
                                                    </div>
                                                </div>
                                                :
                                                <div className={styles.accountCreatingStatusWrapper}>
                                                    -
                                                </div>
                                }
                            </div>
                        </div>
                    }

                    {searchingStatus === 'RESULTS' &&
                        <div className={styles.searchResultsWrapper}>
                            <div>
                                <div className={styles.noResultsFoundWrapper}>
                                    {resultSuggested.length} results found!
                                </div>
                                <div className={styles.suggestionListWrapper}>
                                    {resultSuggested.map((item, index) => (
                                        <Link to={"/manage-users/grow/" + item.stylist_id} className={styles.eachSuggestedWrapper}
                                            // onClick={() => selectUserHandler(item)}
                                            key={item.stylist_id + "OO" + index}
                                        >
                                            <div className={styles.userIconSuggestionList}>
                                                {item.stylist_profile_image !== '' ?
                                                    <img src={item.stylist_profile_image} />
                                                    :
                                                    <FaUserCircle size={38} color="#dfdfdf" />
                                                }
                                            </div>
                                            <div>
                                                <div className={styles.suggestedStylistName}>
                                                    {item.stylist_name}
                                                </div>
                                                <div>
                                                    {item.stylist_username}
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}

export default SearchUserBar