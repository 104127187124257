import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Workshops/MWRSuggestedList.module.css'
import { ShimmerThumbnail } from 'react-shimmer-effects';

const MWRSuggestedList = props => {

    let authCtx = useContext(AuthContext);
    const [isStatusUpdating, setIsStatusUpdating] = useState(false);
    const [workshopsData, setWorkshopsData] = useState([]);
    const [getAllRegistrationsData, setGetAllRegistrationsData] = useState([]);
    const toggleSelectedWorkshop = props.toggleSelectedWorkshop;
    const toggleSelectedUser = props.toggleSelectedUser;
    const selectedUser = props.selectedUser;
    const selectedWorkshop = props.selectedWorkshop;
    const requestingMethod = props.requestingMethod;
    const toggleRequestingMethod = props.toggleRequestingMethod;


    const getAllWorkshops = async () => {
        setIsStatusUpdating(true);
        const workshopsResponse = await fetch(BASE_URL_API + "/getAllWorkshops",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!workshopsResponse.ok) {
            console.log("Something went wrong!");
        } else {
            const workshopsRespo = await workshopsResponse.json();
            if (workshopsRespo.status === "success") {
                setWorkshopsData(workshopsRespo.response);
                setGetAllRegistrationsData([])
            } else {
                if (workshopsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(workshopsRespo);
                }
            }
            // console.log(workshopsRespo);
        }
        setIsStatusUpdating(false);
    }

    const getAllRegistrations = async () => {
        const getAllRegistrationsResponse = await fetch(BASE_URL_API + "/getAllWorkshopRegistrations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllRegistrationsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAllRegistrationsRespo = await getAllRegistrationsResponse.json();
            if (getAllRegistrationsRespo.status == "success") {
                setGetAllRegistrationsData(getAllRegistrationsRespo.response)
                setWorkshopsData([])
            } else {
                setGetAllRegistrationsData([]);
            }
            console.log(getAllRegistrationsRespo.response);
        }
    }

    useEffect(() => {
        if(requestingMethod==='WORKSHOPS') {
            getAllWorkshops()
        } else if(requestingMethod==='REGISTRATIONS') {
            getAllRegistrations();
        }
    }, [requestingMethod])
    



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <button
                        // onClick={() => getAllRegistrations()}
                        onClick={() => toggleRequestingMethod('REGISTRATIONS')}
                        className={styles.eachBtn}
                    >
                        Get All Registrations
                    </button>
                    <button
                        // onClick={() => getAllWorkshops()}
                        onClick={() => toggleRequestingMethod('WORKSHOPS')}
                        className={styles.eachBtn}
                    >
                        Load All Workshops
                    </button>
                </div>

                <div className={styles.mainBody}>
                    {isStatusUpdating ?
                        <div className={styles.loadingWrapper}>
                            <div className={styles.listLoader}>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                                <div className={styles.eachLoader}>
                                    <ShimmerThumbnail height={100} className="m-0" rounded />
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.suggestedListWrapper}>
                            {workshopsData.length > 0 ?
                                <div className={styles.suggestedListBody}>
                                    <div className={styles.foundCount}>
                                        {workshopsData.length} workshop(s) found!
                                    </div>
                                    <div className={styles.suggestedListData}>
                                        {workshopsData.map((item, index) => (
                                            <div
                                                key={Math.random()}
                                                className={selectedWorkshop === item ? styles.eachWorkshopContainerSelected : styles.eachWorkshopContainer}
                                                onClick={() => toggleSelectedWorkshop(item)}
                                            >
                                                <div className={styles.workshopCategory}>{item.workshopDetails.workshopCategory}</div>
                                                <div className={styles.startDate}>{item.workshopDetails.startingTime}</div>
                                                {item.workshopDetails.workshopTitle}
                                                <div>
                                                    {item.workshopDetails.instructorName}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div>
                                    {/* No workshop found!! */}
                                    -
                                </div>
                            }

                            {getAllRegistrationsData.length > 0 &&
                                getAllRegistrationsData.map((item, index) => (
                                    <div className={item == selectedUser ? styles.eachStylistSelected : styles.eachStylist}
                                        onClick={() => toggleSelectedUser(item)}
                                    >
                                        <div >
                                            {item.stylist_name}
                                        </div>
                                        <div>
                                            {item.registeredOn}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}

export default MWRSuggestedList