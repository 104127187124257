import { Add, Remove, RemoveCircle } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { BsSubtract } from 'react-icons/bs'
import SLCEachSkill from './SLCEachSkill'
import styles from './SLCEachSkillCol.module.css'

const SLCEachSkillCol = props => {
    const { columnData, position, columnDataManipulator, columnDataDelete, skills, profiles, selectedSkills, selectSkillHandler, selectAllSkillsHandler } = props
    const [selectedCategory, setSelectedCategory] = useState(columnData?.ID)
    const [activeGender, setActiveGender] = useState('2')
    // console.log(profiles)
    // console.log(skills)
    console.log("Position : ", position)

    useEffect(() => {
        setSelectedCategory(columnData?.ID)
    }, [columnData])
    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.profileCategoryWrapper}>
                    <select 
                        // onChange={(e) => setSelectedCategory(e.target.value)}
                        onChange={(e) => columnDataManipulator(position, e.target.value, "")}
                        value={selectedCategory}
                    >
                        <option value="Select Profile">Select Profile</option>
                        <option value="">Generic</option>
                        {profiles?.map((item, index) => (
                            <option key={index} value={item.ID}>{item.category_name}</option>
                        ))}
                    </select>
                </div>

                <div className={styles.genderWrapper}>
                    <button onClick={() => setActiveGender('2')} className={activeGender === '2' ? styles.genderActive : styles.genderDefault}
                    >
                        For Ladies
                    </button>
                    <button onClick={() => setActiveGender('1')} className={activeGender === '1' ? styles.genderActive : styles.genderDefault}
                    >
                        For Gents
                    </button>
                </div>
            </div>
            <div className={styles.mainBody}>
                {skills.filter(item => item.categoryId === selectedCategory).map((item, index) => (
                    <div key={index}>
                        {item.for_gender === activeGender &&
                            <SLCEachSkill item={item} selectedSkills={selectedSkills} selectSkillHandler={selectSkillHandler} />
                        }
                    </div>
                ))}

            </div>
            <div className={styles.mainFooter}>
                <button className={styles.selectAll} onClick={() => selectAllSkillsHandler(selectedCategory, activeGender)}>Select All</button>
                <button className={styles.addNew} onClick={() => columnDataDelete(position)} ><RemoveCircle /></button>
            </div>
        </div>
    )
}

export default SLCEachSkillCol