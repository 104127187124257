import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './EachApplicationStatus.module.css'

const EachApplicationStatus = props => {
    const { applicationIdentifier, timelineStatus, getApplicationStatus } = props
    const authCtx = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)

    const [reverseRejection, setReverseRejection] = useState(false)

    console.log(authCtx.userIdentifier)


    const unRejectApplication = async () => {
        setReverseRejection(true)
        const unRejectApplicationResponse = await fetch(BASE_URL_API + "/unRejectApplication",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!unRejectApplicationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const unRejectApplicationRespo = await unRejectApplicationResponse.json()
            if (unRejectApplicationRespo.status === "success") {
                getApplicationStatus()
            } else {
                if (unRejectApplicationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(unRejectApplicationRespo)
        }
        setReverseRejection(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.timelineWrapper}>
                {timelineStatus?.map((item, index) => (
                    <div className={styles.eachStatus} key={index}>
                        <div>
                            <div>
                                {item?.applicationStatus}
                                {item?.applicationStatus === "INTERVIEW_SLOT_BOOKED" &&
                                    <div>
                                        {item?.timeSlot}
                                    </div>
                                }
                            </div>
                            <div title={item?.updatedOn} className={styles.updatedOn}>
                                {humanReadableTimePassed(item?.updatedOn)}
                            </div>
                        </div>
                        {item?.applicationStatus === "REJECTED" &&
                            <div className={styles.reverseBtn}>
                                <div className={styles.actionRemarks}>{item?.actionRemarks}</div>
                                {reverseRejection === true ?
                                    <button>Un-rejecting...</button>
                                    :
                                    <button onClick={() => unRejectApplication()}>Un-reject</button>
                                }
                            </div>
                        }
                    </div>

                ))}
            </div>
        </div>
    )
}

export default EachApplicationStatus