import React from 'react'
import styles from './MpaAuthUsers.module.css'

const MpaAuthUsers = props => {
    const { vendorIdentifier } = props

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>

            </div>
            <div className={styles.rightWrapper}>

            MpaAuthUsers
            </div>
        </div>
    )
}

export default MpaAuthUsers