import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Partners/ManageStoreHeader.module.css'
import ManageStoresFilter from './ManageStoresFilter';

const ManageStoreHeader = props => {
    const authCtx = useContext(AuthContext)
    const { storeListHandler, storesList } = props;
    const [sortBy, setSortBy] = useState('NEWEST_FIRST')
    const [hasCordinatesUpdated, setHasCordinatesUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [hasCityUpdated, setHasCityUpdated] = useState()
    const cityUpdatedHandler = (k) => {
        setHasCityUpdated(hasCityUpdated === k ? null : k)
    }

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const filterToggler = async () => {
        setIsFilterOpen(false)
    }


    const getAllStores = async () => {
        setIsLoading(true)
        const getAllStoresResponse = await fetch(BASE_URL_API + "/getAllStores",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    sortBy: sortBy,
                    hasCordinatesUpdated: hasCordinatesUpdated,
                    hasCityUpdated: hasCityUpdated
                })
            });

        if (!getAllStoresResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllStoresRespo = await getAllStoresResponse.json();
            if (getAllStoresRespo.status === 'success') {
                storeListHandler(getAllStoresRespo.response)
            } else {
                if (getAllStoresRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    storeListHandler([])
                    console.log(getAllStoresRespo)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getAllStores()
    }, [])

    useEffect(() => {
        getAllStores()
    }, [sortBy, hasCordinatesUpdated, hasCityUpdated])


    return (
        <div className={styles.headerWrapper}>
            {isFilterOpen && <ManageStoresFilter
                isOpen={isFilterOpen}
                onClose={filterToggler}
                hasCityUpdated={hasCityUpdated}
                cityUpdatedHandler={cityUpdatedHandler}
                isLoading={isLoading}
            />
            }
            <div className={styles.actionWrapper}>
                <div className={styles.filterWrapper}>
                    <button onClick={() => setHasCordinatesUpdated(false)}
                        className={hasCordinatesUpdated === false ? styles.eachActionBtnSelected : styles.eachActionBtn}
                    >
                        Not-Updated
                    </button> &nbsp;
                    <button onClick={() => setHasCordinatesUpdated(true)}
                        className={hasCordinatesUpdated === true ? styles.eachActionBtnSelected : styles.eachActionBtn}
                    >
                        Updated
                    </button>
                </div>
                <div className={styles.availabilityTxt}>
                    {storesList.length} store(s) available!
                </div>
                <div className={styles.sortingWrapper}>
                    <button onClick={() => setSortBy('NEWEST_FIRST')}
                        className={sortBy === "NEWEST_FIRST" ? styles.eachActionBtnSelected : styles.eachActionBtn}
                    >
                        Newest First
                    </button> &nbsp;
                    <button onClick={() => setSortBy('OLDEST_FIRST')}
                        className={sortBy === "OLDEST_FIRST" ? styles.eachActionBtnSelected : styles.eachActionBtn}
                    >
                        Oldest First
                    </button>
                </div>
                <div>
                    <button onClick={() => setIsFilterOpen(!isFilterOpen)}>Filters</button>
                </div>
            </div>
        </div>
    )
}

export default ManageStoreHeader