import React, { useContext, useEffect, useState } from 'react'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { MdCheck, MdCheckBox } from 'react-icons/md'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../ManageLeads/LeadsDistributor.module.css'

const LeadsDistributor = () => {
    const authCtx = useContext(AuthContext)
    const [allLeads, setAllLeads] = useState([])
    const [selectedLeads, setSelectedLeads] = useState([])
    const [allExe, setAllExe] = useState([])
    const [assignedTo, setAssignedTo] = useState('')

    const [isAssigning, setIsAssigning] = useState(false)

    const getLeadsForDistribution = async () => {
        const getLeadsForDistributionResponse = await fetch(BASE_URL_API + "/getLeadsForDistribution",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getLeadsForDistributionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getLeadsForDistributionRespo = await getLeadsForDistributionResponse.json();
            if (getLeadsForDistributionRespo.status === 'success') {
                setAllLeads(getLeadsForDistributionRespo.response);
            } else if (getLeadsForDistributionRespo.message === 'token Expired, please login again!') {
                authCtx.logout()
            }
            console.log(getLeadsForDistributionRespo)
        }
    }

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json();
            if (adminGetAllExecutivesRespo.status === 'success') {
                setAllExe(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllExe([])
                }
            }
        }

    }

    useEffect(() => {
        getLeadsForDistribution().then(adminGetAllExecutives())
    }, [])


    const toggleSelectedLeads = async (k) => {
        if (selectedLeads.includes(k)) {
            setSelectedLeads(selectedLeads.filter((el) => el !== k))
        } else {
            setSelectedLeads([...selectedLeads, k])
        }
    }

    function addInBulk(k) {
        setSelectedLeads(prevSelectedLeads => {
            // Remove any extra items beyond k
            const newSelectedLeads = prevSelectedLeads.slice(0, k);

            // Create a set of phones for the items already in selectedLeads
            const selectedPhones = new Set(newSelectedLeads.map(item => item.phone));

            // Add up to k new items from allLeads
            for (const item of allLeads) {
                if (!selectedPhones.has(item.phone)) {
                    newSelectedLeads.push(item);
                    selectedPhones.add(item.phone);
                    if (newSelectedLeads.length >= k) {
                        break;
                    }
                }
            }

            return newSelectedLeads;
        });
    }

    // console.log(allExe)
    const assignNow = async () => {
        // console.log(assignedTo)
        // console.log(selectedLeads)
        setIsAssigning(true)
        const adminAssignLeadsResponse = await fetch(BASE_URL_API + "/adminAssignLeads",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    assigningTo: assignedTo,
                    leadsToBeAssigned: selectedLeads
                })
            });

        if (!adminAssignLeadsResponse.ok) {
            console.log("Something went wrong : server error");
        } else {
            const adminAssignLeadsRespo = await adminAssignLeadsResponse.json()
            if (adminAssignLeadsRespo.status === 'success') {
                getLeadsForDistribution()
                setSelectedLeads([])
            } else {
                if (adminAssignLeadsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            setIsAssigning(false)

            console.log(adminAssignLeadsRespo)
        }

    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div>
                    Leads Distributor
                </div>
                <div className={styles.subTitle}>
                    Control leads assignments to executives.
                </div>
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.headerLeftSection}>
                    <div className={styles.eachFilterOption}>
                        <div title="Assigned leads with no action taken.">
                            Assigned Leads
                        </div>
                    </div>
                    <div className={styles.eachFilterOption}>
                        <div title="Unassigned and newly added leads">
                            Fresh Leads
                        </div>
                    </div>
                </div>
                <div className={styles.headerRightSection}>
                    <select className={styles.eachFilterDd}>
                        <option>Select locations</option>
                        <option>Delhi</option>
                    </select>

                    <select className={styles.eachFilterDd}>
                        <option>Newest First</option>
                        <option>Newest Last</option>
                    </select>

                    <select className={styles.eachFilterDd}>
                        <option>Fed By</option>
                        <option>Navneet Chandan</option>
                    </select>
                </div>
            </div>

            <div className={styles.mainBodyWrapper}>
                <div className={styles.tableWrapper}>
                    <div className={styles.smartSelector}>
                        <button onClick={() => addInBulk(10)}>Select 10 leads</button>
                        <button onClick={() => addInBulk(20)}>Select 20 leads</button>
                        <button onClick={() => addInBulk(25)}>Select 25 leads</button>
                        <button onClick={() => addInBulk(50)}>Select 50 leads</button>
                        <button onClick={() => addInBulk(80)}>Select 80 leads</button>
                        <button onClick={() => addInBulk(100)}>Select 100 leads</button>
                        <button onClick={() => addInBulk(150)}>Select 150 leads</button>
                        <button onClick={() => addInBulk(200)}>Select 200 leads</button>
                    </div>
                    <div className={styles.tableContainer}>
                        <div className={styles.tableHeader}>
                            <div className={styles.checkboxWrapper}>
                                #
                            </div>
                            <div className={styles.phoneWrapper}>
                                Phone
                            </div>
                            <div className={styles.fullnameWrapper}>
                                Full Name
                            </div>
                            <div className={styles.locationWraper}>
                                Location
                            </div>
                        </div>
                        <div className={styles.tableBody}>
                            {allLeads.map((item, index) => (
                                <div className={styles.eachLeadRow} key={index}
                                    onClick={() => toggleSelectedLeads(item)}
                                >
                                    <div className={styles.checkboxWrapper}>
                                        {selectedLeads.includes(item) ?
                                            <ImCheckboxChecked />
                                            :
                                            <ImCheckboxUnchecked />
                                        }
                                    </div>
                                    <div className={styles.phoneWrapper}>
                                        {item.phone}
                                    </div>
                                    <div className={styles.fullnameWrapper}>
                                        {item.firstName} {item.middleName} {item.lastName}
                                    </div>
                                    <div className={styles.locationWraper}>
                                        {item.city}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.sumActionWrapper}>
                    <div className={styles.selectedLeadsCount}>
                        {selectedLeads.length} leads selected!
                    </div>
                    <div className={styles.assignToWrapper}>
                        <div className={styles.assignToTitle}>Assign To:</div>
                        <div className={styles.assignToBody}>
                            <div className={styles.assignToBodyEachCol}>
                                <select onChange={(e) => setAssignedTo(e.target.value)} value={assignedTo}>
                                    <option value="">Assigning To</option>
                                    {allExe.map((item, index) => (
                                        <option key={index} value={item.userID} >{item.firstName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.assignToBodyEachCol}>
                                {isAssigning === false &&
                                    <div>
                                        {assignedTo !== '' && selectedLeads.length > 0 && <button onClick={() => assignNow()}>Assign Now</button>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default LeadsDistributor