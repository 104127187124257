import { Brush } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './PlotDataForStylistPlacements.module.css'

const PlotDataForStylistPlacements = () => {
    const authCtx = useContext(AuthContext)
    const [chartType, setChartType] = useState("TOTAL_APPLIED") //TOTAL_APPLIED

    const [clubbedData, setClubbedData] = useState([])
    const [appliedData, setAppliedData] = useState([])
    const [interviewsData, setInterviewsData] = useState([])
    const [hiredData, setHiredData] = useState([])

    function convertStringToNumber(data) {
        return data.map(item => {
            Object.entries(item).forEach(([key, value]) => {
                item[key] = key === 'month' ? value : Number(value);
            });
            return item;
        });
    }

    function transformData(inputData) {
        const monthVolumes = {};

        Object.entries(inputData).forEach(([category, entries]) =>
            entries.forEach(entry => {
                const { month, volume } = entry;
                monthVolumes[month] ||= { month, Applications: 0, Interviews: 0, Hired: 0 };
                monthVolumes[month][`${category.charAt(0).toUpperCase() + category.slice(1)}`] += volume;
            })
        );

        // Convert the monthVolumes object to an array and sort it based on the "month" field
        const resultArray = Object.values(monthVolumes).sort((a, b) => a.month.localeCompare(b.month));

        return resultArray;
    }
    // console.log("in the pipeline", Object.values(monthVolumes))

    const kpisForRecruitmentPlacementPlot = async () => {
        const kpisForRecruitmentPlacementPlotResponse = await fetch(BASE_URL_API + "/kpisForRecruitmentPlacementPlot",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!kpisForRecruitmentPlacementPlotResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const kpisForRecruitmentPlacementPlotRespo = await kpisForRecruitmentPlacementPlotResponse.json()
            if (kpisForRecruitmentPlacementPlotRespo.status === "success") {
                setAppliedData(convertStringToNumber(kpisForRecruitmentPlacementPlotRespo?.response?.applications))
                setInterviewsData(convertStringToNumber(kpisForRecruitmentPlacementPlotRespo?.response?.interviews))
                setHiredData(convertStringToNumber(kpisForRecruitmentPlacementPlotRespo?.response?.hired))
                console.log("alldata", kpisForRecruitmentPlacementPlotRespo?.response)
                setClubbedData(transformData(kpisForRecruitmentPlacementPlotRespo?.response))
            } else {
                if (kpisForRecruitmentPlacementPlotRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAppliedData([])
                    setInterviewsData([])
                    setHiredData([])
                }
            }
            console.log(kpisForRecruitmentPlacementPlotRespo)
        }
    }


    useEffect(() => {
        kpisForRecruitmentPlacementPlot()
    }, [])

    console.log("clubbed", clubbedData)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <div className={styles.mainTitleWrapper}>
                        <div>
                            Stylist Placements Summary
                        </div>
                    </div>


                    {chartType === "TOTAL_APPLIED" &&
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart width="100%" height={350} data={appliedData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="volume" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    }
                    {chartType === "TOTAL_INTERVIEWS" &&
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart width="100%" height={350} data={interviewsData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="volume" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    }
                    {chartType === "TOTAL_HIRED" &&
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart width="100%" height={350} data={hiredData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="volume" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    }
                    {chartType === "CLUBBED" &&
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart width="100%" height={350} data={clubbedData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Applications" stroke="#8884d8" />
                                <Line type="monotone" dataKey="Interviews" stroke="#da1880" />
                                <Line type="monotone" dataKey="Hired" stroke="#04A67D" />
                            </LineChart>
                        </ResponsiveContainer>
                    }

                </div>
                <div className={styles.operatingWrapper}>
                    <button className={chartType==="TOTAL_APPLIED" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("TOTAL_APPLIED")}>Total Applications</button>
                    <button className={chartType==="TOTAL_INTERVIEWS" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("TOTAL_INTERVIEWS")}>Total Interviews</button>
                    <button className={chartType==="TOTAL_HIRED" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("TOTAL_HIRED")}>Total Hired</button>
                    <button className={chartType==="CLUBBED" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("CLUBBED")}>Clubbed</button>
                </div>

            </div>
        </div>
    )
}

export default PlotDataForStylistPlacements