import React, { useContext, useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../JobsFinder/UserSelectorForJobsFinder.module.css'

const UserSelectorForJobsFinder = props => {
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const { io, abortUserSelector } = props

    const [query, setQuery] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestions, setSuggestions] = useState([])
    const [searchingStatus, setSearchingStatus] = useState("NOT_SEARCHING")

    function handleInputChange(event) {
        setQuery(event.target.value);
        fetchSuggestedUsers(event.target.value)
        setSelectedIndex(-1);
    }

    function handleSuggestionClick(index) {
        setSelectedIndex(index);
        setQuery(suggestions[index]);
    }

    function handleKeyDown(event) {
        if (event.key === 'ArrowUp' && selectedIndex > -1) {
            setSelectedIndex(selectedIndex - 1);
        } else if (event.key === 'ArrowDown' && selectedIndex < suggestions.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        } else if (event.key === 'Enter' && selectedIndex > -1) {
            setQuery(suggestions[selectedIndex]?.stylist_name)
            navigate("/jobs-finder/" + suggestions[selectedIndex]?.stylist_id)
            abortUserSelector()
        }
    }


    const fetchSuggestedUsers = async (k) => {
        setSearchingStatus('FETCHING');
        const fetchSuggestedUsersResponse = await fetch(BASE_URL_API + "/fetchSuggestedUsers",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    key: k
                })
            });

        if (!fetchSuggestedUsersResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const fetchSuggestedUsersRespo = await fetchSuggestedUsersResponse.json();
            if (fetchSuggestedUsersRespo.status === 'success') {
                setSearchingStatus('RESULTS')
                setSuggestions(fetchSuggestedUsersRespo.response)
            } else {
                if (fetchSuggestedUsersRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else if (fetchSuggestedUsersRespo.message === 'no records found') {
                    setSearchingStatus('NO_RESULTS_FOUND');
                    console.log(fetchSuggestedUsersRespo.response)
                } else {
                    setSearchingStatus('NOT_FETCHING');
                }
                setSuggestions([])
            }
            // console.log(fetchSuggestedUsersRespo);
        }
    }

    
    

    window.onclick = event => event.target.id === "usfjfc" && abortUserSelector()

    if (!io) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="usfjfc">
                <div className={styles.searchBoxWrapper}>
                    <div className={styles.searchBoxContainer}>

                        <div className={suggestions.length > 0 ? styles.searchBoxContainerResults : styles.seachBarContainer}>
                            <input placeholder="Search via phone / name / username"
                                value={query}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                autoFocus={true}
                            />
                        </div>

                        {suggestions.length > 0 &&
                            <div className={styles.searchResultsContainer}>
                                <ul className={styles.suggestedList}>
                                    {suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className={index === selectedIndex ? styles.selectedSuggestion : styles.eachSuggestion}
                                            onClick={() => handleSuggestionClick(index)}
                                        >
                                            <div className={styles.suggestionDetWrapper}>
                                                <div className={styles.suggestedUserPic}>
                                                    {suggestion.stylist_profile_image !== "" ?
                                                        <img src={suggestion.stylist_profile_image} />
                                                        :
                                                        <FaUserAlt size={23} color="#a3a3a3" />
                                                    }
                                                </div>
                                                <div>
                                                    <div>
                                                        <div className={styles.stylistName}>
                                                            {suggestion?.stylist_name}
                                                        </div>
                                                        <div className={styles.phone}>
                                                            {suggestion?.contact_number}
                                                        </div>
                                                    </div>
                                                    <div className={styles.locationTxt}>
                                                        {suggestion?.currentLocation}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserSelectorForJobsFinder