import logo from './logo.svg';
import './App.css';
import { MainNavigator } from './AdminNavigator/MainNavigator';


function App() {
  return (
   <MainNavigator />
  )
}

export default App;
