import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './JAAssignMode.module.css'
import JAAssignModeEachRow from './JAAssignModeEachRow'

const JAAssignMode = props => {
    const authCtx = useContext(AuthContext)
    const { allExecutives } = props

    const [isAll, setIsAll] = useState(true)
    const [openCloseStatus, setOpenCloseStatus] = useState(null)
    const [assignedStatus, setAssignedStatus] = useState(null)
    const [paidHiringStatus, setPaidHiringStatus] = useState(null)

    const [jobsData, setJobsData] = useState([])
    const [recentJobs, setRecentJobs] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getJobsForAllocation = async () => {
        const getJobsForAllocationResponse = await fetch(BASE_URL_API + "/getJobsForAllocation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    timeFrame: 'Today',
                    openCloseStatus : openCloseStatus,
                    assignedStatus : assignedStatus,
                    paidHiringStatus : paidHiringStatus
                })
            });

        if (!getJobsForAllocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobsForAllocationRespo = await getJobsForAllocationResponse.json()
            if (getJobsForAllocationRespo.status === "success") {
                setJobsData(getJobsForAllocationRespo.response)
            } else {
                if (getJobsForAllocationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setJobsData([])
                }
            }
            console.log(getJobsForAllocationRespo)
        }
    }

    useEffect(() => {
        getJobsForAllocation()
    }, [])

    function filterData(data, searchKey) {
        return data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        );
    }

    const getRecentAllocatedJobs = async () => {
        const getRecentAllocatedJobsResponse = await fetch(BASE_URL_API + "/getRecentAllocatedJobs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getRecentAllocatedJobsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getRecentAllocatedJobsRespo = await getRecentAllocatedJobsResponse.json();
            if (getRecentAllocatedJobsRespo.status === "success") {
                setRecentJobs(getRecentAllocatedJobsRespo.response)
            } else {
                if (getRecentAllocatedJobsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setRecentJobs([])
                }
            }
            console.log(getRecentAllocatedJobsRespo)
        }
    }

    useEffect(() => {
        getRecentAllocatedJobs()
    }, [])


    const filtersHandler = () => {
        
    }

    useEffect(() => {
        if(isAll===true) {
            setPaidHiringStatus(null)
            setOpenCloseStatus(null)
            setAssignedStatus(null)
        } 
    }, [isAll])

    useEffect(() => {
        if(openCloseStatus===null) {
            if(assignedStatus===null) {
                if(paidHiringStatus===null) {
                    setIsAll(true)
                } else {
                    setIsAll(false)
                }
            } else {
                setIsAll(false)
            }
        } else {
            setIsAll(false)
        }
        getJobsForAllocation()
    }, [openCloseStatus, assignedStatus, paidHiringStatus])
    
    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.upperWrapper}>
                    <div className={styles.summaryWrapper}>
                        <div className={styles.recentsWrapper}>
                            {recentJobs.map((itemRecent, index) => (
                                <div className={styles.eachRecentWrapper} key={index}>
                                    <div>
                                        <div className={styles.storeName}>{itemRecent?.jobIdentifier}</div>
                                        <div className={styles.storeAddress}>Sector - 119, Noida, 201307</div>
                                    </div>
                                    <div className={styles.minsAgo}>{humanReadableTimePassed(itemRecent?.allotedOn)}</div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <select>
                                <option>Today</option>
                                <option>Yesterday</option>
                                <option>This Week</option>
                                <option>Last Week</option>
                                <option>This Month</option>
                                <option>Last Month</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.filterWrapper}>
                        <div className={styles.btnsWrapper}>
                            <div className={styles.eachTabWrapper}>
                                <button onClick={() => setIsAll(true)} className={isAll === true ? styles.activeBtn : styles.defBtn}>All Jobs</button>
                            </div>
                            <div className={styles.eachTabWrapper}>
                                <button onClick={() => setOpenCloseStatus("OPEN")} className={openCloseStatus === "OPEN" ? styles.activeBtn : styles.defBtn}>Open</button>
                                <button onClick={() => setOpenCloseStatus("CLOSE")} className={openCloseStatus === "CLOSE" ? styles.activeBtn : styles.defBtn}>Closed</button>
                            </div>
                            <div className={styles.eachTabWrapper}>
                                <button onClick={() => setAssignedStatus("Assigned")} className={assignedStatus === "Assigned" ? styles.activeBtn : styles.defBtn}>Assigned Jobs</button>
                                <button onClick={() => setAssignedStatus("Unassigned")} className={assignedStatus === "Unassigned" ? styles.activeBtn : styles.defBtn}>Unassigned Jobs</button>
                            </div>
                            <div className={styles.eachTabWrapper}>
                                <button onClick={() => setPaidHiringStatus("PAID")} className={paidHiringStatus === "PAID" ? styles.activeBtn : styles.defBtn}>Paid</button>
                                <button onClick={() => setPaidHiringStatus("UNPAID")} className={paidHiringStatus === "UNPAID" ? styles.activeBtn : styles.defBtn}>Un-paid</button>
                            </div>
                        </div>
                        <div className={styles.searchBar}>
                            <input placeholder="Search...." onChange={(e) => setSearchKey(e.target.value)} value={searchKey} />
                        </div>
                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.jobTitle}>Job Title</div>
                        <div className={styles.nameLocation}>Salon Name & Location</div>
                        <div className={styles.postedOn}>Posted On</div>
                        <div className={styles.jobStatus}>Job Status</div>
                        <div className={styles.currentStatus}>Current Work Status</div>
                        <div className={styles.allotedTo}>Alloted To</div>
                        <div className={styles.allotedOn}>Alloted On</div>
                        <div className={styles.manage}>Manage</div>
                    </div>
                    <div className={styles.tableBody}>
                        {filterData(jobsData, searchKey).map((item, index) => (
                            <JAAssignModeEachRow item={item} key={index} count={index} allExecutives={allExecutives} getRecentAllocatedJobs={getRecentAllocatedJobs} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JAAssignMode