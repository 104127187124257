import React, { useContext, useEffect, useState } from 'react'
import styles from './ViewAllContracts.module.css'
import { Link } from 'react-router-dom'
import { MdLogout, MdSearch } from "react-icons/md";
import AuthContext from '../../store/auth-context'
import { BASE_URL_API } from '../../References/urls';

const ViewAllContracts = () => {
    const authCtx = useContext(AuthContext)
    const [allAgreements, setAllAgreements] = useState([])

    const getAllAgreements = async () => {
        const getAllAgreementsResponse = await fetch(BASE_URL_API + "/getAllAgreements",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllAgreementsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllAgreementsRespo = await getAllAgreementsResponse.json()
            if (getAllAgreementsRespo.status === "success") {
                setAllAgreements(getAllAgreementsRespo.response)
            } else {
                if (getAllAgreementsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllAgreementsRespo)
        }
    }

    useEffect(() => {
        getAllAgreements()
    }, [])



    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>

                <div className={styles.headerNavigation}>
                    <Link to="">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks-router">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div className={styles.headerNavigationRight}>
                    <Link to="/global-search" className={styles.gsIcon}>
                        <MdSearch size={26} />
                    </Link>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>

            <div className={styles.optionContainer}>
                <div className={styles.filterWrapper}>
                    <div>View All Contracts </div>
                    <div>Filter</div>
                </div>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeaderWrapper}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.companyName} >Company Name</div>
                        <div className={styles.sharedOn}>Shared On</div>
                        <div className={styles.currentStatus}>currentStatus</div>
                        <div className={styles.action}>Action</div>
                    </div>
                    <div className={styles.tableBodyWrapper}>
                        {allAgreements?.map((item, index) => (
                            <div key={index} className={styles.eachAgreementRow}>
                                <div className={styles.sn}>{index + 1}</div>
                                <div className={styles.companyName} >
                                    {item?.secondPartyName}
                                    <div className={styles.secondPartyAddress}>
                                        {item?.secondPartyAddress}
                                    </div>
                                </div>
                                <div className={styles.sharedOn}>Shared On</div>
                                <div className={styles.currentStatus}>currentStatus</div>
                                <div className={styles.action}>
                                    <Link to={`/manage-service-agreement/${item?.agreementIdentifier}`} >
                                        View
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ViewAllContracts