import React, { useContext, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ActivateSubscription from '../../../Components/ManageSubscriptions/ResumeSubscriptions/ActivateSubscription'
import CurrentSubscription from '../../../Components/ManageSubscriptions/ResumeSubscriptions/CurrentSubscription'
import styles from './ResumeSubscriptionsHome.module.css'
import { useParams } from 'react-router'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import { humanReadableTimePassed } from '../../../Helper/helpers'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResumeSubscriptionsHome = () => {
    const authCtx = useContext(AuthContext)
    const { stylistIdentifier } = useParams()
    const [activeOption, setActiveOption] = useState('Current') // Activate
    const [userDetails, setUserDetails] = useState(null)
    const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(true)

    const toggleActiveOption = (k) => setActiveOption(k)


    const getStylistDetails = async () => {
        setIsLoadingUserDetails(true)
        const getStylistDetailsResponse = await fetch(BASE_URL_API + "/getStylistDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: stylistIdentifier
                })
            });

        if (!getStylistDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStylistDetailsRespo = await getStylistDetailsResponse.json()
            if (getStylistDetailsRespo.status === "success") {
                setUserDetails(getStylistDetailsRespo.response)
            } else {
                if (getStylistDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setUserDetails(null)
                }
            }
            console.log(getStylistDetailsRespo)
        }
        setIsLoadingUserDetails(false)
    }


    useEffect(() => {
        getStylistDetails()
    }, [stylistIdentifier])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.leftIcon}>
                        <Link to={`/manage-subscriptions/resume`}>
                            <FaArrowLeft />
                        </Link>
                    </div>
                    <div className={styles.optionsWrapper}>
                        <div className={styles.eachOptionWrapper} onClick={() => setActiveOption('Current')} >
                            <div className={styles.eachOptionContainer}>
                                <div>

                                </div>
                                <div className={styles.eachOptionTxt}>
                                    Current Plan
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachOptionWrapper} onClick={() => setActiveOption('Activate')} >
                            <div className={styles.eachOptionContainer}>
                                <div>

                                </div>
                                <div className={styles.eachOptionTxt}>
                                    Activate Plan
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className={styles.rightColWrapper}>
                    {isLoadingUserDetails === false ?
                        <div className={styles.upperRowWrapper}>
                            <div className={styles.urwLeft}>
                                {userDetails?.stylist_name}
                            </div>
                            <div className={styles.urwCenter}>
                                {userDetails?.contact_number}
                                {userDetails?.stylist_gender}
                                {userDetails?.primaryWorkDomain}
                            </div>
                            <div className={styles.urwRight}>
                                <div>
                                    Registered On : {userDetails?.registred_on}
                                    {humanReadableTimePassed(userDetails?.registred_on)}
                                </div>
                                <div>
                                    Registered via
                                    {userDetails?.registeredVia}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.upperRowWrapper}>
                        </div>
                    }
                    <div className={styles.contentWrapper}>
                        {activeOption === "Current" && <CurrentSubscription toggleActiveOption={toggleActiveOption} resumeUrl={`https://cv.stylelink.in/${userDetails?.stylist_username}`} stylistIdentifier={stylistIdentifier} />}
                        {activeOption === "Activate" && <ActivateSubscription toggleActiveOption={toggleActiveOption} resumeUrl={`https://cv.stylelink.in/${userDetails?.stylist_username}`} stylistIdentifier={stylistIdentifier} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResumeSubscriptionsHome