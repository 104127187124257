import React, { useContext, useEffect, useState } from 'react'
import styles from './PreviewAgreement.module.css'
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import MembershipTables from '../../Components/Contracts/MembershipTables';

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import { convertToArray } from '../../Helper/helpers';

const PreviewAgreement = (props) => {
    const { agreementIdentifier } = props
    const authCtx = useContext(AuthContext)
    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    const options = {
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };


    const getTargetElement = () => document.getElementById('content-id');

    const [isCollecting, setIsCollecting] = useState(false)
    const [contractLevel, setContractLevel] = useState('')
    const [contractValidTill, setContractValidTill] = useState('')
    const [dateOfContract, setDateOfContract] = useState('')
    const [vendorIdentifier, setVendorIdentifier] = useState('')
    const [storeIdentifier, setStoreIdentifier] = useState('')
    const [secondPartyName, setSecondPartyName] = useState('')
    const [secondPartyAddress, setSecondPartyAddress] = useState('')
    const [paymentDueDate, setPaymentDueDate] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [servicesOffered, setServicesOffered] = useState([])
    const [hasMultipleInterviewLocations, setHasMultipleInterviewLocations] = useState('')
    const [interviewLocations, setInterviewLocations] = useState('')
    const [profilesCovered, setProfilesCovered] = useState([])
    const [firstPartyPOCName, setFirstPartyPOCName] = useState('')
    const [firstPartyPOCPhone, setFirstPartyPOCPhone] = useState('')
    const [firstPartyPOCEmail, setFirstPartyPOCEmail] = useState('')
    const [secondPartyPOCName, setSecondPartyPOCName] = useState('')
    const [secondPartyPOCPhone, setSecondPartyPOCPhone] = useState('')
    const [secondPartyPOCEmail, setSecondPartyPOCEmail] = useState('')
    const [firstPartyPOCDesignation, setFirstPartyPOCDesignation] = useState('')
    const [secondPartyPOCDesignation, setSecondPartyPOCDesignation] = useState('')
    const [planBenefits, setPlanBenefits] = useState([])
    const [isSubscriptionFee, setIsSubscriptionFee] = useState(false)
    const [subscriptionFeeTerms, setSubscriptionFeeTerms] = useState(null)
    const [isHiringFee, setIsHiringFee] = useState(false)
    const [hiringFeeTerms, setHiringFeeTerms] = useState(null)

    const [storesCovered, setStoresCovered] = useState([])
    const [citiesCovered, setCitiesCovered] = useState([])


    const getAgreementDetails = async () => {
        setIsCollecting(true)
        const getAgreementDetailsResponse = await fetch(BASE_URL_API + "/getAgreementDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier
                })
            });

        if (!getAgreementDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAgreementDetailsRespo = await getAgreementDetailsResponse.json()
            if (getAgreementDetailsRespo.status === "success") {
                setContractLevel(getAgreementDetailsRespo?.response?.contractLevel)
                setDateOfContract(getAgreementDetailsRespo?.response?.dateOfContract.substr(0, 10))
                setContractValidTill(getAgreementDetailsRespo?.response?.contractValidTill.substr(0, 10))
                setVendorIdentifier(getAgreementDetailsRespo?.response?.vendorIdentifier)
                setStoreIdentifier(getAgreementDetailsRespo?.response?.storeIdentifier)
                setSecondPartyName(getAgreementDetailsRespo?.response?.secondPartyName)
                setSecondPartyAddress(getAgreementDetailsRespo?.response?.secondPartyAddress)
                setPaymentDueDate(getAgreementDetailsRespo?.response?.paymentDueDate?.substr(0, 10))
                setSelectedPlan(getAgreementDetailsRespo?.response?.selectedPlan)
                setServicesOffered(convertToArray(getAgreementDetailsRespo?.response?.servicesOffered))
                setHasMultipleInterviewLocations(getAgreementDetailsRespo?.response?.hasMultipleInterviewLocations)
                setInterviewLocations(getAgreementDetailsRespo?.response?.interviewLocations)
                setProfilesCovered(convertToArray(getAgreementDetailsRespo?.response?.profilesCovered))
                setPlanBenefits(convertToArray(getAgreementDetailsRespo?.response?.planBenefits))
                setFirstPartyPOCName(getAgreementDetailsRespo?.response?.firstPartyPOCName)
                setFirstPartyPOCPhone(getAgreementDetailsRespo?.response?.firstPartyPOCPhone)
                setFirstPartyPOCEmail(getAgreementDetailsRespo?.response?.firstPartyPOCEmail)
                setSecondPartyPOCName(getAgreementDetailsRespo?.response?.secondPartyPOCName)
                setSecondPartyPOCPhone(getAgreementDetailsRespo?.response?.secondPartyPOCPhone)
                setSecondPartyPOCEmail(getAgreementDetailsRespo?.response?.secondPartyPOCEmail)
                setFirstPartyPOCDesignation(getAgreementDetailsRespo?.response?.firstPartyPOCDesignation)
                setSecondPartyPOCDesignation(getAgreementDetailsRespo?.response?.secondPartyPOCDesignation)
                setIsSubscriptionFee(getAgreementDetailsRespo?.response?.isSubscriptionFee)
                setSubscriptionFeeTerms(getAgreementDetailsRespo?.response?.subscriptionFeeTerms)
                setIsHiringFee(getAgreementDetailsRespo?.response?.isHiringFee)
                setHiringFeeTerms(getAgreementDetailsRespo?.response?.hiringFeeTerms)

                setCitiesCovered(convertToArray(getAgreementDetailsRespo?.response?.citiesCovered))
                setStoresCovered(convertToArray(getAgreementDetailsRespo?.response?.storesCovered))
            } else {
                if (getAgreementDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAgreementDetailsRespo)
        }
        setIsCollecting(false)
    }

    useEffect(() => {
        getAgreementDetails()
    }, [agreementIdentifier])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                Preview Service Agreement
            </div>
            <div className={styles.mainBody}>
                {isCollecting === true ?
                    <div className={styles.invoiceWrapper}>
                        Loading Agreement
                    </div>
                    :
                    <div className={styles.invoiceWrapper}
                        ref={targetRef}
                        id="content-id"
                    >
                        <div className={styles.mainContractTitle}>
                            Service Agreement
                        </div>
                        <div className={styles.agreementDateWrapper}>
                            This Service Agreement ("Agreement") is made and entered into as of <span className={styles.agreementDate}> 28th June 2024</span>, by and between:
                        </div>
                        <div className={styles.partiesWrapper}>
                            <p>
                                <span className={styles.firmNameWrapper}>Lokaci Innovations & Technologies Pvt. Ltd. ("Service Provider")</span>, a company incorporated under the provisions of the Companies Act, 1956 having its registered office at
                                and
                                its principal place of business at <span> GT - 8, Sector 117, Noida Baraula Dadri Gautam Buddha Nagar , Uttar Pradesh. </span> (hereinafter referred to as “Stylelink”), which expression unless repugnant to the context or meaning thereof shall mean and include its principal officers, employees, contractors,
                                promoter(s)/majority shareholder(s), successors in business, affiliates,
                                subsidiaries and assign) of the ONE PART;
                            </p>
                        </div>
                        <div className={styles.textAlignCenter}>
                            <p>
                                AND
                            </p>
                        </div>
                        <div className={styles.partiesWrapper}>
                            <p>
                                <span className={styles.secondPartyName}>{secondPartyName} </span> a company incorporated under the Companies Act, 1956 having its office at
                                <span className={styles.secondPartyAddress}>&nbsp;{secondPartyAddress}</span> (hereinafter referred to as “Entity” which expression shall, unless repugnant to the context or meaning thereof, mean and includes their respective successors and assigns) of the OTHER PART. The above referred Entity, and Stylelink are collectively referred to hereinafter as “Parties” and individually as “Party”.
                            </p>
                        </div>
                        <div>
                            <p>
                                The above referred Entity, and Stylelink are collectively referred to hereinafter as “Parties” and individually as
                            </p>
                        </div>
                        <ol>
                            <li>
                                <h4>Services Provided</h4>
                                Service Provider agrees to provide the following services to Client:
                                <ul>
                                    <li>Training and recruitment services for salon staff.</li>
                                    <li>Software solutions for managing staffing needs, offer letters, and documentation.</li>
                                </ul>
                            </li>
                            <li>
                                <h4>Term</h4>
                                This Agreement shall commence on {dateOfContract}. The Term of this Agreement shall be valid till {contractValidTill}.
                            </li>
                            <li>
                                <h4>
                                    Plan Subscribed & Payment Terms
                                </h4>
                                <p>
                                    Entity has availed the subscription plan as follows:
                                </p>
                                <MembershipTables plan={`plus`} planBenefits={planBenefits} />
                                Entity agrees to pay for the services based on the following model(s):
                                <ul>
                                    {isSubscriptionFee === "1" && <li>Annual Subscription shall has to be paid <span className={styles.subscriptionFeeTerms}>{subscriptionFeeTerms}.</span> </li>}
                                    {isHiringFee === "1" && <li>Pay As You Hire <span className={styles.hiringFeeTerms}>{hiringFeeTerms}</span> </li>}
                                </ul>
                                <p>
                                    Payments shall be due <span className={styles.paymentDueDate}>{paymentDueDate}</span>
                                </p>
                            </li>
                            <li>
                                <h4>Responsibilities of the Service Provider</h4>
                                Service Provider shall:
                                <ul>
                                    <li>Provide training and recruitment services in a professional manner.</li>
                                    <li>Offer continuous support and updates for the software solutions provided.</li>
                                    <li>Ensure confidentiality and security of Client's data.</li>
                                </ul>
                                <p>List of Profiles in which Stylelink will provide candidates:</p>
                                <div>
                                    <ol>
                                        {profilesCovered?.map((itemPc, index) => (
                                            <li key={index}>
                                                {itemPc?.category_name}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </li>
                            <li>
                                <h4>Responsibilities of the Client</h4>
                                Client shall:
                                <ul>
                                    <li>Provide necessary access to their premises and systems as required.</li>
                                    <li>Provide complete requirements of the candidates such as salary range, experience, age, gender, qualifications, and etc.</li>
                                    <li>Pay for the services in a timely manner.</li>
                                    <li>Maintain the confidentiality of any proprietary software or materials provided by the Service Provider.</li>
                                </ul>
                            </li>
                            <li>
                                <h4>Confidentiality</h4>
                                Both parties agree to keep all sensitive information confidential and not disclose it to any third party without prior written consent.
                            </li>
                            <li>
                                <h4>Termination</h4>
                                This Agreement may be terminated by either party with 30 days' written notice if the other party fails to comply with any material term or condition of this Agreement.
                            </li>
                            <li>
                                <h4>Limitation of Liability</h4>
                                Service Provider’s liability under this Agreement shall be limited to the amount paid by Client for the services provided.
                            </li>
                            <li>
                                <h4>Entire Agreement</h4>
                                This Agreement constitutes the entire agreement between the parties and supersedes all prior agreements and understandings.
                            </li>
                            <li>
                                <h4>Dispute Resolutions</h4>
                                Any dispute or claim arising out of this Agreement shall be referred to the sole arbitrator appointed by mutual agreement between both parties under the provisions of Arbitration and Conciliation Act, 1996 (as amended up to date). The place of arbitration shall be at Uttar Pradesh. The Courts in Uttar Pradesh shall have exclusive jurisdiction.
                            </li>
                            <li>
                                <h4>Contact Information</h4>
                                Service Provider: support@stylelink.in
                                Client: vivek@mausamsalon.com
                            </li>
                        </ol>
                        IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the day and year first above written.

                        <div className={styles.signingWrapper}>
                            <div className={styles.swLeftWrapper}>
                                <div className={styles.swTitle}>For Service Provider</div>
                                <div className={styles.signingSpace}>
                                </div>
                                <div className={styles.designation}>
                                    <div className={styles.pocName}>
                                        {firstPartyPOCName}
                                    </div>
                                    <div className={styles.pocDesignation}>
                                        {firstPartyPOCDesignation}
                                    </div>
                                </div>

                            </div>
                            <div className={styles.swRightWrapper}>
                                <div className={styles.swTitle}>For Entity</div>
                                <div className={styles.signingSpace}>
                                </div>
                                <div className={styles.designation}>
                                    <div className={styles.pocName}>
                                        {secondPartyPOCName}
                                    </div>
                                    <div className={styles.pocDesignation}>
                                        {secondPartyPOCDesignation}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
            <div className={styles.mainFooter}>
                <button onClick={() => generatePDF(getTargetElement, options)} >Download</button>
            </div>

        </div>
    )
}

export default PreviewAgreement