import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './MpaHome.module.css'

const MpaHome = (props) => {
    const { vendorIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [salonDetails, setSalonDetails] = useState(null)
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)

    const getSalonAccountDetails = async () => {
        setIsLoadingDetails(true)
        const getSalonAccountDetailsResponse = await fetch(BASE_URL_API + "/getSalonAccountDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!getSalonAccountDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getSalonAccountDetailsRespo = await getSalonAccountDetailsResponse.json()
            if (getSalonAccountDetailsRespo.status === "success") {
                setSalonDetails(getSalonAccountDetailsRespo?.response)
            } else {
                if (getSalonAccountDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSalonDetails(null)
                }
            }
        }
        setIsLoadingDetails(false)
    }

    useEffect(() => {
        getSalonAccountDetails()
    }, [vendorIdentifier])



    return (
        <div>
            <div>
                {isLoadingDetails === true ?
                    <div>
                        <div>
                            Hold On!
                        </div>
                        <div>
                            Loading details...
                        </div>
                    </div>
                    :
                    <div>
                        <div>
                            {salonDetails?.company_name}
                        </div>
                        <div>
                            {salonDetails?.address}
                        </div>
                        <div>
                            Onboarded on Lokaci {humanReadableTimePassed(salonDetails?.created_at)}
                        </div>
                        <div>
                            Phone : {salonDetails?.vender_contact}
                        </div>

                        <div>
                            {/* {JSON.stringify(salonDetails)} */}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MpaHome