import React, { useState } from 'react'
import { generateJobDescription } from '../../Helper/generateJobDescriptions'
import styles from './SLCPreviewJobDetailsAndDescription.module.css'

const SLCPreviewJobDetailsAndDescription = (props) => {
    const { isOpen, activeDraft, jdndespHandlerClose } = props
    const [jd, setJd] = useState('')

    // console.log(JSON.parse(activeDraft))

    const generateJDHandler = () => {
        // (jobTitle, jobType, experience, gender, minAge, maxAge, languageSpoken, educationQualification, salaryMin, salaryMax, incentive, foodAndAccomodation)
        setJd(generateJobDescription(activeDraft?.title, "Full-time", activeDraft?.experience, "male", "2", "24", ["hindi"], "none req", "2344", 453, "yes", "none", activeDraft?.skill!==null && JSON.parse(activeDraft?.skill)))
    }

    if (!isOpen) {
        return null

    } else {
        return (
            <div className={styles.mainWrapper}>
                <div>
                    <button onClick={() => jdndespHandlerClose()} >Close</button>
                </div>
                SLCPreviewJobDetailsAndDescription
                {JSON.stringify(activeDraft)}

                <div dangerouslySetInnerHTML={{__html: jd}}>
                </div>
                <div>
                    <button onClick={() => generateJDHandler()} >Generate now</button>
                </div>
            </div>
        )
    }
}

export default SLCPreviewJobDetailsAndDescription