import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { IoIosCreate } from 'react-icons/io'
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers'
import AllStoreConversations from '../Conversations/AllStoreConversations'
import styles from './AllotedJobEachRow.module.css'

const AllotedJobEachRow = props => {
    const { item, count } = props
    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)


    return (
        <div className={`${styles.mainWrapper} ${item?.isPaidHiring === "1" ? styles.paid : ''}`}>
            {isScHolderOpen && <AllStoreConversations
                isOpen={isScHolderOpen}
                storeIdentifier={item?.store_id}
                onClose={onClosingScHolder}
                jobIdentifier={item?.jobIdentifier}
            />}
            <div className={styles.sn}>{count + 1}</div>
            <div className={styles.profile}>
                <div className={styles.title}>{item?.title}</div>
                <div className={styles.jobCategoryName}>{item?.job_category_name}</div>
            </div>
            <div className={styles.storeDetails}>
                <div className={styles.storeName}>
                    {item?.storeName}
                </div>
                <div className={styles.storeAddress}>
                    {item?.storeAddress}
                </div>
            </div>
            <div className={styles.jobStatus}>
                {item?.isClosed === "1" ? <span className={styles.hasClosed}>Closed</span> : <span className={styles.hasNotClosed}>Active</span>}
            </div>
            <div className={styles.jobStatus}>
                <div>
                    {item?.currentStatus}
                </div>
                <div className={styles.lastStatusUpdatedOn} >
                    {humanReadableTimePassed(item?.lastStatusUpdatedOn)}
                </div>
            </div>
            <div className={styles.postedOn}>
                {humanReadableTimePassed(item?.postedOn)}
            </div>
            <div className={styles.postedOn}>
                {humanReadableTimePassed(item?.allotedOn)}
            </div>
            <div className={styles.actionWrapper}>
                <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px' }}
                    onClick={() => setIsScHolderOpen(true)}
                    title="Open all conversation related to this store"
                >
                    <IoChatbubbleEllipsesSharp size={20} color="white" />
                </button>
                <Link to={`/manage-job/${item?.job_id}`}
                    title="View Job"
                >
                    <FaEye size={20} color="white" />
                </Link>
                <Link to={`/create-applications/${item?.job_id}`}
                    title="Create Applications"
                >
                    <IoIosCreate size={20} color="white" />
                </Link>
            </div>

        </div>
    )
}

export default AllotedJobEachRow