import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { convertTimeStampToHHMM, getDayFromDate } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AcademyVisitorsCalendar.module.css'

const AcademyVisitorsCalendar = () => {
    const authCtx = useContext(AuthContext)
    const [collectedData, setCollectedData] = useState([])
    const [timeFrame, setTimeFrame] = useState('W0')
    const [isLoading, setIsLoading] = useState(true)

    // getDayWiseAcadVisitorsData

    const getDayWiseAcadVisitorsData = async () => {
        setIsLoading(true)
        const getDayWiseAcadVisitorsDataResponse = await fetch(BASE_URL_API + "/getDayWiseAcadVisitorsData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    timeFrame: timeFrame
                })
            });

        if (!getDayWiseAcadVisitorsDataResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getDayWiseAcadVisitorsDataRespo = await getDayWiseAcadVisitorsDataResponse.json()
            if (getDayWiseAcadVisitorsDataRespo.status === 'success') {
                setCollectedData(Object.values(getDayWiseAcadVisitorsDataRespo.response))
            } else {
                if (getDayWiseAcadVisitorsDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setCollectedData([])
                }
                console.log(getDayWiseAcadVisitorsDataRespo)
            }

        }
        setIsLoading(false)
    }

    useEffect(() => {
        getDayWiseAcadVisitorsData()
    }, [timeFrame])
    

    return (
        <div>
            <div>
                <div className={styles.mainHeaderWrapper}>
                    <div className={styles.mhwupper}>
                        <div className={styles.mhwLeftWrapper}>
                            <div className={styles.headerNavigation}>
                                <Link to="/" >
                                    <div className={styles.eachHeaderNavigation}>Home</div>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.mhwRightWrapper}>
                            <div>
                                <button
                                    className={timeFrame === 'W-6' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-6')}
                                    title="Six Weeks Ago"
                                >
                                    W-6
                                </button>
                                <button
                                    className={timeFrame === 'W-5' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-5')}
                                    title="Five Weeks Ago"
                                >
                                    W-5
                                </button>
                                <button
                                    className={timeFrame === 'W-4' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-4')}
                                    title="Four Weeks Ago"
                                >
                                    W-4
                                </button>
                                <button
                                    className={timeFrame === 'W-3' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-3')}
                                    title="Three Weeks Ago"
                                >
                                    W-3
                                </button>
                                <button
                                    className={timeFrame === 'W-2' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-2')}
                                    title="Last To Last Week"
                                >
                                    W-2
                                </button>
                                <button
                                    className={timeFrame === 'W-1' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-1')}
                                    title="Last Week"
                                >
                                    W-1
                                </button>
                                <button
                                    className={timeFrame === 'W0' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W0')}
                                    title="This Week"
                                >
                                    W0
                                </button>
                                <button
                                    className={timeFrame === 'W+1' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+1')}
                                    title="Next Week"
                                >
                                    W+1
                                </button>
                                <button
                                    className={timeFrame === 'W+2' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+2')}
                                    title="Next To Next Week"
                                >
                                    W+2
                                </button>
                                <button
                                    className={timeFrame === 'W+3' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+3')}
                                    title="Third Week"
                                >
                                    W+3
                                </button>
                                <button
                                    className={timeFrame === 'W+4' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+4')}
                                    title="Forth Week"
                                >
                                    W+4
                                </button>
                                <button
                                    className={timeFrame === 'W+5' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+5')}
                                    title="Fifth Week"
                                >
                                    W+5
                                </button>
                                <button
                                    className={timeFrame === 'W+6' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+6')}
                                    title="Sixth Week"
                                >
                                    W+6
                                </button>
                            </div>
                            <div>
                                {/* <button onClick={() => getDayWiseAppStatusData()} >Refresh</button> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.smallTxt}>
                        {/* Total Interviews Scheduled in this Week : {collectedData.reduce((acc, item) => acc + item.records.length, 0)} */}
                    </div>
                </div>
            </div>

            {isLoading !== true ?
                <div>
                    <div className={styles.headerWrapper}>
                        {collectedData.map((item, index) => (
                            <div className={styles.eachCol} key={index}>
                                <div className={styles.eachColContainer}>
                                    <div className={styles.eachColLeftContainer}>
                                        <div className={styles.day}>
                                            {getDayFromDate(item?.day)}
                                        </div>
                                        <div className={styles.date}>
                                            {item?.day}
                                        </div>
                                    </div>
                                    <div>
                                        ({item?.records?.length})
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.bodyWrapper}>
                        {collectedData.map((item, index) => (
                            <div className={styles.eachBodyCol} key={index}>
                                <div className={styles.eachBodyColContainer}>
                                    <div>
                                        {item?.records.map((itemRec, indexRec) => (
                                            <div className={styles.eachTileWrapper} key={indexRec}
                                                // onClick={() => ajtInitiateHandler(itemRec?.applicationId)}
                                            >
                                                <div title={itemRec?.applicationId}>
                                                    <div className={styles.etfrWrapper}>
                                                        <div className={styles.stylistName}>
                                                            {itemRec?.fullName?.toLowerCase()}
                                                        </div>
                                                        <div className={styles.timeSlot}>
                                                            <div>
                                                                {convertTimeStampToHHMM(itemRec?.visitTime).split(" ")[0]}
                                                            </div>
                                                            <div className={styles.ampm}>
                                                                {convertTimeStampToHHMM(itemRec?.visitTime).split(" ")[1]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {itemRec?.appliedSource}
                                                    <div className={styles.salonName}>
                                                        {itemRec?.address}
                                                    </div>
                                                    <div className={styles.slotRemarks}>
                                                        {itemRec?.comments?.toLowerCase()}
                                                    </div>
                                                    {/* {itemRec?.jobIdentifier} */}
                                                    <div>
                                                        {/* {itemRec.applicationStatus} */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                :
                <div>

                </div>
            }


        </div>
    )
}

export default AcademyVisitorsCalendar