import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AddItemToInvoicePopUp.module.css'

const AddItemToInvoicePopUp = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, onClose, vendorIdentifier } = props
    const [searchKey, setSearchKey] = useState('')
    const [responseData, setResponseData] = useState([])
    const [jobsData, setJobsData] = useState([1, 2, 3, 4, 5])
    const [filteredData, setFilteredData] = useState([1, 3, 4, 534, 5, 53, 5])
    const [candidatesData, setCandidatesData] = useState([])
    const [loadingCandidates, setLoadingCandidates] = useState(false)

    window.onclick = event => event.target.id === "mcaitipopid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });


    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }


    // useEffect(() => {
    //     filterData(jobsData, searchKey)
    // }, [searchKey])


    const [selectedStore, setSelectedStore] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)

    const getAllStoreAndJobsForVendor = async () => {
        const getAllStoreAndJobsForVendorResponse = await fetch(BASE_URL_API + "/getAllStoreAndJobsForVendor",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier : vendorIdentifier
                    // vendorIdentifier: '1708443057hPKYQxDMsaiZ'
                })
            });

        if (!getAllStoreAndJobsForVendorResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllStoreAndJobsForVendorRespo = await getAllStoreAndJobsForVendorResponse.json()
            if (getAllStoreAndJobsForVendorRespo.status === "success") {
                setResponseData(getAllStoreAndJobsForVendorRespo?.response)
            } else {
                if (getAllStoreAndJobsForVendorRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setResponseData([])
                }
            }
            console.log(getAllStoreAndJobsForVendorRespo)
        }
    }

    const selectStoreHandler = (k) => {
        if (selectedStore?.store_id === k?.store_id) {
            setSelectedStore(null)
        } else {
            setSelectedStore(k)
        }
    }

    const selectJobHandler = (k) => {
        if (selectedJob?.job_id === k?.job_id) {
            setSelectedJob(null)
        } else {
            setSelectedJob(k)
        }
    }

    useEffect(() => {
        getAllStoreAndJobsForVendor()
    }, [])


    const getHiredCandidatesRelatedToJob = async () => {
        setLoadingCandidates(true)
        const getHiredCandidatesRelatedToJobResponse = await fetch(BASE_URL_API + "/getHiredCandidatesRelatedToJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: selectedJob?.job_id
                })
            });

        if (!getHiredCandidatesRelatedToJobResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getHiredCandidatesRelatedToJobRespo = await getHiredCandidatesRelatedToJobResponse.json()
            if (getHiredCandidatesRelatedToJobRespo.status === "success") {
                setCandidatesData(getHiredCandidatesRelatedToJobRespo?.response)
            } else {
                if (getHiredCandidatesRelatedToJobRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCandidatesData([])
                }
            }
            console.log(getHiredCandidatesRelatedToJobRespo)
        }
        setLoadingCandidates(false)
    }


    useEffect(() => {
        getHiredCandidatesRelatedToJob()
    }, [selectedJob])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="mcaitipopid">
                <div className={`${styles.mainContent} ${selectedStore !== null ? styles.twoVisible : ''} ${selectedJob !== null ? styles.threeVisible : ''}`}>
                    <div className={styles.storesWrapper}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.headerContainer}>
                                <div className={styles.inputWrapper}>
                                    <input placeholder="Search for a store..." />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.storesListWrapper}>
                                {responseData?.map((item, index) => (
                                    <div onClick={() => selectStoreHandler(item)} key={index} className={selectedStore?.store_id === item?.store_id ? styles.eachJobWrapperSelected : styles.eachStoreWrapper} >
                                        <div>{item?.company_name}</div>
                                        <div className={styles.address}>{item?.address}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {selectedStore !== null &&
                        <div className={styles.jobsWrapper}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.headerContainer}>
                                    <div className={styles.inputWrapper}>
                                        <input className={styles.inputField} autoFocus={true} placeholder="Search for a job..." />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.jobsListWrapper}>
                                {JSON.parse(selectedStore?.jobs)?.map((item, index) => (
                                    <div className={styles.eachJobWrapper} key={index} onClick={() => setSelectedJob(item)}>
                                        <div>{item?.title}</div>
                                        <div>Posted on {item?.creation_date} </div>
                                        {item?.isPaidHiring === '1' &&
                                            <div>
                                                ₹ {item?.hiringPrice}
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                    {selectedStore !== null && selectedJob !== null &&
                        <div className={styles.candidatesWrapper}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.headerContainer}>
                                    <div className={styles.inputWrapper}>
                                        <input className={styles.inputField} autoFocus={true} placeholder="Search for a candidate..." />
                                    </div>
                                </div>
                            </div>

                            {loadingCandidates === false ?
                                <div className={styles.jobsListWrapper}>
                                    {/* {selectedJob?.job_id} */}
                                    {candidatesData?.map((item, index) => (
                                        <div className={styles.eachCandidateItem} key={index}>
                                            <div>
                                                <div>
                                                    {item?.stylist_name}
                                                </div>
                                                {item?.is_hired === "1" &&
                                                    <div>
                                                        Hired on {item?.hired_on?.substring(0, 10)}
                                                    </div>
                                                }
                                            </div>
                                            {item?.is_hired === "1" &&
                                                <div>
                                                    <button>Add +</button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className={styles.jobsListWrapper}>
                                    Hold on, loading data...
                                </div>
                            }
                        </div>
                    }


                </div>
            </div>
        </div>
    )
}

export default AddItemToInvoicePopUp