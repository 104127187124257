import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/CourseAdministration.module.css';
import { encode, decode } from 'js-base64';
import { BASE_URL_API } from '../../References/urls';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const CourseAdministration = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");
    let authType = JSON.parse(decode(authCtx.token));

    const courseId = props.courseId;
    // const [courseId, setCourseId] = useState(props.courseId);
    const setIsLoading = props.setIsLoading;
    const getAllCourses = props.getAllCourses;

    const [courseRouteUpdated, setCourseRouteUpdated] = useState('');
    const [courseRoute, setCourseRoute] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        getCourseActions();
    }, [courseId])

    const courseRouteHandler = (e) => {
        let p = e.trim();
        p = p.replace(/\s/g, '');
        p = p.replace(/\//g, '');
        p = p.replace(/\$/g, '');
        p = p.replace(/\?/g, '');
        p = p.replace(/\!/g, '');
        p = p.replace(/\@/g, '');
        p = p.replace(/\#/g, '');
        p = p.replace(/\%/g, '');
        p = p.replace(/\^/g, '');
        p = p.replace(/\&/g, '');
        p = p.replace(/\*/g, '');
        p = p.replace(/\(/g, '');
        p = p.replace(/\)/g, '');
        p = p.replace(/\=/g, '');
        p = p.replace(/\+/g, '');
        p = p.replace(/\`/g, '');
        p = p.replace(/\~/g, '');
        p = p.replace(/\|/g, '');
        p = p.replace(/\\/g, '');
        p = p.replace(/\[/g, '');
        p = p.replace(/\]/g, '');
        p = p.replace(/\{/g, '');
        p = p.replace(/\}/g, '');
        p = p.replace(/\:/g, '');
        p = p.replace(/\;/g, '');
        p = p.replace(/\'/g, '');
        p = p.replace(/\"/g, '');
        p = p.replace(/\./g, '');
        p = p.replace(/\,/g, '');
        p = p.replace(/\>/g, '');
        p = p.replace(/\</g, '');
        setCourseRoute(p)
    }

    const updateRouteHandler = () => {
        if (courseRoute != "") {
            if (authType.username != "") {
                if (authType.username == "navneetchandan") {
                    if (authType.expire > (Date.now() / 1000).toFixed(0)) {
                        console.log("we are good to go!");
                        updateRoute();
                    } else {
                        alert("You have been logged out, please login again!");
                        navigate("/")
                    }
                } else {
                    alert("You are not allowed to amend route, please contact admin!");
                }
            } else {
                alert("You have been logged out, please login again!");
            }
        } else {
            alert("Route can't be empty!");
        }
    }

    if (authType.username != "navneetchandan") {
        return (
            <section className={styles.mainContainer}>
                <div className={styles.sectionMainTitle}>
                    <h3>Course Administration</h3>
                </div>
                <div className={styles.sectionContent}>
                    {courseId}
                    You are not allowed to manage admin section.
                    If you are admin, kindly login with your credentials or contact your admin
                </div>
            </section>
        )
    }

    const toggleActivateHandler = () => {
        toggleActivation();
    }

    const deleteCourse = async () => {
        console.log(courseId)
        setIsLoading(true)
        const deleteCourseResponse = await fetch(BASE_URL_API + "/updateCourseActions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId,
                    actionType: 'deleteCourse'
                })
            });

        if (!deleteCourseResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const deleteCourseRespo = await deleteCourseResponse.json();
            if (deleteCourseRespo.status == "success") {
                setIsDeleted(true);
                alert("course deleted!");
                getAllCourses();
            } else {
                alert(deleteCourseRespo.message)
            }
        }
        setIsLoading(false);
    }

    const updateRoute = async () => {
        setIsLoading(true);
        const updateRouteResponse = await fetch(BASE_URL_API + "/updateCourseActions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId,
                    actionType: 'updateRoute',
                    courseRoute: courseRoute
                })
            });

        if (!updateRouteResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const updateRouteRespo = await updateRouteResponse.json();
            if (updateRouteRespo.status == "success") {
                getCourseActions()
            } else {
                alert(updateRouteRespo.message)
            }
            console.log(updateRouteRespo);
        }
        setIsLoading(false);

    }

    const toggleActivation = async () => {
        setIsLoading(true);
        const toggleActivationResponse = await fetch(BASE_URL_API + "/updateCourseActions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    actionType: 'toggleActivation',
                    courseId: courseId
                })
            });

        if (!toggleActivationResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const toggleActivationRespo = await toggleActivationResponse.json();
            if (toggleActivationRespo.status == "success") {
                setIsActive(isActive != true ? true : false);
            } else {
                alert(toggleActivationRespo.message)
            }
            console.log(toggleActivationRespo)
        }
        setIsLoading(false);
    }

    const getCourseActions = async () => {
        setIsLoading(true);
        const getCourseActionsReponse = await fetch(BASE_URL_API + "/getCourseActions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId
                })
            });

        if (!getCourseActionsReponse.ok) {

        } else {
            const getCourseActionsRespo = await getCourseActionsReponse.json();
            if (getCourseActionsRespo.status == "success") {
                setIsActive(getCourseActionsRespo.response.isActive == '1' ? true : false);
                setCourseRouteUpdated(getCourseActionsRespo.response.courseRoute);
                setCourseRoute(getCourseActionsRespo.response.courseRoute);
            } else {
                alert(getCourseActionsRespo.message);
            }
            console.log(getCourseActionsRespo);
        }
        setIsLoading(false);
    }

    if(isDeleted) {
        return (
            <section className={styles.mainContainer}>
                <div className={styles.sectionMainTitle}>
                    <h3>Course Administration</h3>
                </div>
                <div className={styles.sectionContent}>
                    {courseId}
                    This course has been deleted!
                    Select another course to start!
                </div>
            </section>
        )
    }


    return (
        <section className={styles.mainContainer}>
            <div className={styles.sectionMainTitle}>
                <h3>Course Administration</h3>
            </div>
            <div className={styles.sectionContent}>
                {courseId}
                <div className={styles.eachField}>
                    <div className={styles.eachFieldTitle}>
                        <span>Enter Course Route</span>
                    </div>
                    <div className={styles.eachFieldInput}>
                        <input
                            className={styles.courseRoute}
                            placeholder="Please enter the course route..."
                            onChange={(e) => courseRouteHandler(e.target.value)}
                            value={courseRoute}
                        />
                    </div>
                </div>
                {courseRouteUpdated != courseRoute &&
                    <div className={styles.submitBtnContainer}>
                        <button onClick={() => updateRouteHandler()}>Submit</button>
                    </div>}

                <div className={styles.eachField}>
                    <div className={styles.eachFieldTitle}>
                        <span>Action Buttons</span>
                    </div>
                    <div className={styles.eachFieldOptions}>
                        <button
                            onClick={() => toggleActivateHandler()}
                        >
                            {isActive == true ? 'De-Activate' : 'Activate'}
                        </button>
                        <Popup
                            trigger={
                                <button>
                                    Delete
                                </button>
                            }
                            modal
                            
                        >
                            <div className={styles.deletePopupContainer}>
                                <div>
                                    <h4>Do you really want to delete the course!
                                        </h4>
                                    </div>
                                <div>
                                    <p>
                                    Deleting a course will remove all the stats / content / materials / reviews / ratings / feedback / watch hours related to the course!
                                    </p>
                                </div>
                                <div>
                                    <p>
                                    This action can't be undone!
                                    </p>
                                </div>
                                <div>
                                    {/* <button
                                    className={styles.deleteButton}
                                >
                                    Cancel
                                </button> */}
                                    <button
                                        onClick={() => deleteCourse()}
                                        className={styles.deleteButton}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>




            </div>
        </section>
    )
}

export default CourseAdministration