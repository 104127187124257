import React, { useState } from 'react'
import { BsCheckCircle, BsCheckCircleFill, BsImageAlt } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import styles from '../JobApplications/EachRelevantProfileToJob.module.css'

const EachRelevantProfileToJob = props => {
    let item = props.item
    const {activeCandidate, activeCandidateHandler} = props;
    const toggleSelectedProfileHandler = props.toggleSelectedProfileHandler;
    const selectedProfiles = props.selectedProfiles;
    let isSelected = selectedProfiles.includes(item) === true ? true : false;

    return (
        <div className={item.stylist_id===activeCandidate ? styles.mainWrapperSelected : styles.mainWrapper} >
            <div className={styles.mainContainer}
            // onClick={() => setIsSelected(!isSelected)}
            // onClick={() => toggleSelectedProfileHandler(item)}
            >
                <div className={styles.checkBoxContainer}
                >
                    {isSelected === true ?
                        <BsCheckCircleFill color="blue" size={20} />
                        :
                        <BsCheckCircle color="#dfdfdf" size={20} />
                    }
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.profilePicName}

                        onClick={() => toggleSelectedProfileHandler(item)}
                    >
                        <div className={styles.profilePicWrapper}>
                            {item.stylist_profile_image !== '' ?
                                <img src={item.stylist_profile_image} />
                                :
                                <BsImageAlt />
                            }
                        </div>
                        <div className={styles.userName}>
                            {item.stylist_name}
                        </div>
                    </div>
                    <div className={styles.detailsSection}>
                        <div onClick={() => activeCandidateHandler(item.stylist_id)}>
                            <div className={styles.profiles}>
                                {item.primaryWorkDomain !== '' && item.primaryWorkDomain}
                                {item.secondaryWorkDomain !== '' && ' cum ' + item.secondaryWorkDomain}
                                {item.tertiaryWorkDomain !== '' && ' cum ' + item.tertiaryWorkDomain}, 24 Years old, {item.maritalStatus}, {item.stylist_gender}
                            </div>
                            <div className={styles.dobWrapper}>
                                Date of Birth : {item.stylist_birthdate}
                            </div>
                            <div className={styles.salaryExpectation}>
                                Salary Expectation - {item.minimumSalaryExpectation}
                            </div>
                            <div className={styles.addressWrapper}>
                                <div>
                                    Current Address : {item.currentLocation}

                                </div>
                                <div>
                                    {item?.currentLocationLat !== '' ?
                                        <span>
                                            {parseFloat(item.distance).toFixed(1)} km(s) from store
                                        </span>
                                        :
                                        <span>Current location missing!</span>
                                    }
                                </div>
                            </div>
                            <div className={styles.currentStatusSection}>
                                <div>
                                    Is Currently working?&nbsp;
                                    {item.isCurrentlyWorking === '1' ? "Yes" :
                                        item.isCurrentlyWorking === '0' ? "No" : "Un-known"}
                                </div>
                                <div>
                                    Actively Looking for Job : {item.isLookingForJob === '1' ? "Yes" :
                                        item.isLookingForJob === '0' ? "No" : "Un-known"
                                    }
                                </div>
                            </div>
                            <div className={styles.contactDetailsWrapper}>
                                <div>Contact Number : {item.contact_number}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default EachRelevantProfileToJob