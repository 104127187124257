import React, { useState } from 'react'
import styles from '../../Styles/Components/Jobs/JobBenefits.module.css'
import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const JobBenefits = props => {
    const updateSalaryHandler = props.updateSalaryHandler;
    const minSalary = props.minSalary;
    const maxSalary = props.maxSalary;

    const [incentivesData, setIncentivesData] = useState([
        "Yes", "No"
    ])

    const [fnaData, setFnaData] = useState([
        "None", "1 - time Food Only", "2 - time Food Only", "Accomodation Only", "1 - time Food with Accomodation", "2 - time Food with Accomodation"
    ])

    const incentive = props.incentive;
    const incentiveUpdateHandler = props.incentiveUpdateHandler;

    const fnaOffering = props.fnaOffering
    const fnaHandler = props.fnaHandler

    return (
        <div className={styles.rcMainBody}>
            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Salary Range <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please enter the range of salary you are offering..
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <div className={styles.eachFieldOptionContainer}>
                            <div>
                                <div className={styles.ageCaption}>
                                    Min Salary
                                </div>
                                <input placeholder="Enter minimum salary" className={styles.salaryFields}
                                    onChange={(e) => updateSalaryHandler("minSalary", e.target.value)}
                                    value={minSalary}
                                ></input>
                            </div>
                            <div>
                                <div className={styles.ageCaption}>
                                    Max Salary
                                </div>
                                <input placeholder="Enter maximum salary" className={styles.salaryFields}
                                    onChange={(e) => updateSalaryHandler("maxSalary", e.target.value)}
                                    value={maxSalary}
                                ></input>
                            </div>
                        </div>
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Incentives <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please enter incentives details
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {incentivesData.map((item, index) => (
                            <div className={incentive === item ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "C"} onClick={() => incentiveUpdateHandler(item)}>
                                <div className={styles.eachStoreTitle}>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Food & Accomodation <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the if you offer food and accomodation!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {fnaData.map((item, index) => (
                            <div className={fnaOffering === item ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "C"} onClick={() => fnaHandler(item)} >
                                <div className={styles.eachStoreTitle}>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export default JobBenefits