import React from 'react'
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers'
import styles from './MseJobsEachRow.module.css'

const MseJobsEachRow = (props) => {
    const { item, count, viewJdHandler, handleRepostingJob } = props

    return (
        <div className={styles.eachRowWrapper}>
            <div className={styles.sn}>
                {count + 1}
            </div>
            <div className={styles.title}>
                {item?.title}
                <div className={styles.jobCategory}>
                    {item?.job_category_name}
                </div>
            </div>

            <div className={styles.salaryRange}>
                {item?.salary_min} &nbsp;-&nbsp;{item?.salary_max}
            </div>
            <div className={styles.currentStatus}>
                {item?.isClosed==="1" ? <span className={styles.closedLabel}>Closed</span> : <span className={styles.activeLabel}>Active</span>}
            </div>
            <div className={styles.postedOn}>
                {humanReadableTimePassed(item?.creation_date)}
            </div>
            <div className={styles.closingSummary}>
                <div>
                    {item?.closedOn}
                </div>
                <div className={styles.closingNotes}>
                    {item?.closingNotes}
                </div>
            </div>
            <div className={styles.reasonOfClosing}>
                {item?.reasonOfClosing}
            </div>
            <div className={styles.actionWrapper}>
                <div>

                    <button onClick={() => viewJdHandler(item)}>View JD</button>
                </div>
                <div className={styles.createApplication}>
                    <Link to={`/create-applications/${item?.job_id}`}>Create Applications</Link>
                </div>

                <div>
                    {item?.isClosed === "1" && <button onClick={() => handleRepostingJob(true, item)} >Repost</button>}
                </div>
            </div>

        </div>
    )
}

export default MseJobsEachRow