import React, { useContext, useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SelectVendor.module.css'

const SelectVendor = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, onClose, onSelect } = props
    const [allResults, setAllResults] = useState([])

    const [searchKey, setSearchKey] = useState('')
    const [isSearching, setIsSearching] = useState(false)

    const searchKeyHandler = (k) => {
        setSearchKey(k)
    }

    const salonSearchEngine = async () => {
        const salonSearchEngineResponse = await fetch(BASE_URL_API + "/salonSearchEngine",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    searchKey: searchKey
                })
            });

        if (!salonSearchEngineResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const salonSearchEngineRespo = await salonSearchEngineResponse.json()
            if (salonSearchEngineRespo.status === "success") {
                setAllResults(salonSearchEngineRespo?.response)
            } else {
                if (salonSearchEngineRespo.message === "token Expired, please login again") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(salonSearchEngineRespo)
        }
    }

    useEffect(() => {
        if (searchKey.length > 3) {
            salonSearchEngine()
        }
    }, [searchKey])


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContentWrapper}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.backWrapper}>
                                <button onClick={() => onClose()}  >
                                    <MdArrowBack size={24} />
                                </button>
                            </div>
                            <div className={styles.searchWrapper}>
                                <input autoFocus={true}
                                    value={searchKey}
                                    placeholder="Search for a salon..."
                                    onChange={(e) => searchKeyHandler(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            {isSearching === true ?
                                <div>
                                    Please wait, looking for the perfect match...
                                </div>
                                :
                                allResults?.length > 0 ?
                                    <div className={styles.allResultsWrapper}>
                                        <div className={styles.resultCount}>
                                            {allResults?.length} results found!
                                        </div>
                                        {allResults?.map((item, index) => (
                                            <div className={styles.eachResultWrapper} key={index}
                                                onClick={() => onSelect(item)}
                                            >
                                                <div className={styles.salonName}>
                                                    {item?.company_name}
                                                </div>
                                                <div className={styles.address}>
                                                    {item?.address}
                                                </div>
                                            </div>
                                        ))}

                                        <div>
                                            Thats' it!
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={styles.noResults}>
                                            Could not found any results matching...
                                        </div>
                                    </div>

                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectVendor