import React, { useContext, useEffect, useState } from 'react'
import { BsFilter } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import AppCalendarFilter from '../../Components/ApplicationsCalendar/AppCalendarFilter'
import ApplicationJourneyTracker from '../../Components/ApplicationsCalendar/ApplicationJourneyTracker'
import { convertTimeStampToHHMM, getDayFromDate } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AppCalendarHolder.module.css'

const AppCalendarHolder = () => {
    const authCtx = useContext(AuthContext)
    const [allExecutivesData, setAllExecutivesData] = useState([])
    const [collectedData, setCollectedData] = useState([])
    const [collectedDataForMapping, setCollectedDataForMapping] = useState([])
    const [timeFrame, setTimeFrame] = useState('W0')
    const [isLoading, setIsLoading] = useState(true)
    const [selectedApplication, setSelectedApplication] = useState('')
    const [isTrackerActivated, setIsTrackerActivated] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const [appCurrentStatus, setAppCurrentStatus] = useState('ALL'); // UnderProcess, isHired, isRejected
    const appCurrentStatusHandler = (k) => setAppCurrentStatus(k)
    const [selectedExecutive, setSelectedExecutive] = useState("")
    const selectExecutiveHandler = (k) => setSelectedExecutive(k)

    const getDayWiseAppStatusData = async () => {
        setIsLoading(true)
        const getDayWiseAppStatusDataResponse = await fetch(BASE_URL_API + "/getDayWiseAppStatusData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    timeFrame: timeFrame
                })
            });

        if (!getDayWiseAppStatusDataResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getDayWiseAppStatusDataRespo = await getDayWiseAppStatusDataResponse.json()
            if (getDayWiseAppStatusDataRespo.status === 'success') {
                setCollectedData(Object.values(getDayWiseAppStatusDataRespo.response))
            } else {
                if (getDayWiseAppStatusDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setCollectedData([])
                }
                console.log(getDayWiseAppStatusDataRespo)
            }

        }
        setIsLoading(false)
    }

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json()
            if (adminGetAllExecutivesRespo.status === "success") {
                setAllExecutivesData(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllExecutivesData([])
                }
            }
        }
    }

    useEffect(() => {
        getDayWiseAppStatusData()
    }, [timeFrame])

    const ajtInitiateHandler = k => {
        setSelectedApplication(k)
        setIsTrackerActivated(true)
    }
    const ajtCloser = () => setIsTrackerActivated(false)
    const acfCloser = () => setIsFilterOpen(false)

    const filtersHandler = (searchKey) => {
        const filtered = collectedData.map((day) => ({
            ...day,
            records: day.records.filter((record) => {
                switch (appCurrentStatus) {
                    case 'HIRED':
                        return record.hasHired === '1';
                    case 'REJECTED':
                        return record.hasRejected === '1';
                    case 'ONGOING':
                        return record.hasHired === '0' && record.hasRejected === '0';
                    case 'ALL':
                    default:
                        return true;
                }
            }).filter((record) =>
                (!searchKey ||
                    Object.values(record).some((value) =>
                        String(value).toLowerCase().includes(searchKey.toLowerCase())
                    )) &&
                (!selectedExecutive ||
                    (record.applicationCreatingExecutiveIdentifier &&
                        record.applicationCreatingExecutiveIdentifier.toLowerCase() ===
                        selectedExecutive.toLowerCase())
                )
            ),
        }));

        setCollectedDataForMapping(filtered);
    };



    useEffect(() => {
        adminGetAllExecutives()
    }, [])


    useEffect(() => {
        filtersHandler()
    }, [collectedData, appCurrentStatus, selectedExecutive])


    return (
        <div>
            <ApplicationJourneyTracker
                selectedApplication={selectedApplication}
                isTrackerActivated={isTrackerActivated}
                ajtCloser={ajtCloser}
            />
            <AppCalendarFilter
                isFilterOpen={isFilterOpen}
                acfCloser={acfCloser}
                allExecutivesData={allExecutivesData}
                appCurrentStatus={appCurrentStatus}
                appCurrentStatusHandler={appCurrentStatusHandler}
                selectedExecutive={selectedExecutive}
                selectExecutiveHandler={selectExecutiveHandler}
            />
            <div>
                <div className={styles.mainHeaderWrapper}>
                    <div className={styles.mhwupper}>
                        <div className={styles.mhwLeftWrapper}>
                            <div className={styles.headerNavigation}>
                                <Link to="/" >
                                    <div className={styles.eachHeaderNavigation}>Home</div>
                                </Link>
                                <Link to="/performance-summary" >
                                    <div className={styles.eachHeaderNavigation}>Summary</div>
                                </Link>
                                <Link to="/application-calendar" >
                                    <div className={styles.eachHeaderNavigation}>Application Calendar</div>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.mhwRightWrapper}>
                            <div>
                                <select 
                                    onChange={(e) => setTimeFrame(e.target.value)}
                                >
                                    <option value={``} >Select Older Week</option>
                                    <option value={`W-7`} >7 Weeks Ago</option>
                                    <option value={`W-8`} >8 Weeks Ago</option>
                                    <option value={`W-9`} >9 Weeks Ago</option>
                                    <option value={`W-10`} >10 Weeks Ago</option>
                                    <option value={`W-11`} >11 Weeks Ago</option>
                                    <option value={`W-12`} >12 Weeks Ago</option>
                                    <option value={`W-13`} >13 Weeks Ago</option>
                                    <option value={`W-14`} >14 Weeks Ago</option>
                                    <option value={`W-15`} >15 Weeks Ago</option>
                                    <option value={`W-16`} >16 Weeks Ago</option>
                                    <option value={`W-17`} >17 Weeks Ago</option>
                                    <option value={`W-18`} >18 Weeks Ago</option>
                                    <option value={`W-19`} >19 Weeks Ago</option>
                                    <option value={`W-20`} >20 Weeks Ago</option>
                                </select>
                                <button
                                    className={timeFrame === 'W-6' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-6')}
                                    title="Six Weeks Ago"
                                >
                                    W-6
                                </button>
                                <button
                                    className={timeFrame === 'W-5' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-5')}
                                    title="Five Weeks Ago"
                                >
                                    W-5
                                </button>
                                <button
                                    className={timeFrame === 'W-4' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-4')}
                                    title="Four Weeks Ago"
                                >
                                    W-4
                                </button>
                                <button
                                    className={timeFrame === 'W-3' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-3')}
                                    title="Three Weeks Ago"
                                >
                                    W-3
                                </button>
                                <button
                                    className={timeFrame === 'W-2' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-2')}
                                    title="Last To Last Week"
                                >
                                    W-2
                                </button>
                                <button
                                    className={timeFrame === 'W-1' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W-1')}
                                    title="Last Week"
                                >
                                    W-1
                                </button>
                                <button
                                    className={timeFrame === 'W0' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W0')}
                                    title="This Week"
                                >
                                    W0
                                </button>
                                <button
                                    className={timeFrame === 'W+1' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+1')}
                                    title="Next Week"
                                >
                                    W+1
                                </button>
                                <button
                                    className={timeFrame === 'W+2' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+2')}
                                    title="Next To Next Week"
                                >
                                    W+2
                                </button>
                                <button
                                    className={timeFrame === 'W+3' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+3')}
                                    title="Third Week"
                                >
                                    W+3
                                </button>
                                {/* <button
                                    className={timeFrame === 'W+4' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+4')}
                                    title="Forth Week"
                                >
                                    W+4
                                </button>
                                <button
                                    className={timeFrame === 'W+5' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+5')}
                                    title="Fifth Week"
                                >
                                    W+5
                                </button>
                                <button
                                    className={timeFrame === 'W+6' ? styles.activeWeek : styles.defaultWeek}
                                    onClick={() => setTimeFrame('W+6')}
                                    title="Sixth Week"
                                >
                                    W+6
                                </button> */}
                            </div>
                            <div className={styles.filtersWrapper}>
                                <button className={styles.filterIcon} onClick={() => setIsFilterOpen(true)}><BsFilter size={24} /></button>
                                <button onClick={() => getDayWiseAppStatusData()} >Refresh</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.smallTxt}>
                        Total Interviews Scheduled in this Week : {collectedData.reduce((acc, item) => acc + item.records.length, 0)}
                    </div>
                </div>
                {isLoading !== true ?
                    <div>
                        <div className={styles.headerWrapper}>
                            {collectedData.map((item, index) => (
                                <div className={styles.eachCol} key={index}>
                                    <div className={styles.eachColContainer}>
                                        <div className={styles.eachColLeftContainer}>
                                            <div className={styles.day}>
                                                {getDayFromDate(item?.day)}
                                            </div>
                                            <div className={styles.date}>
                                                {item?.day}
                                            </div>
                                        </div>
                                        <div>
                                            ({item?.records?.length})
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.bodyWrapper}>
                            {collectedDataForMapping.map((item, index) => (
                                <div className={styles.eachBodyCol}>
                                    <div className={styles.eachBodyColContainer}>
                                        <div>
                                            {item?.records?.map((itemRec, indexRec) => (
                                                <div className={styles.eachTileWrapper} key={indexRec}
                                                    onClick={() => ajtInitiateHandler(itemRec?.applicationId)}
                                                >
                                                    <div title={itemRec?.applicationId}>
                                                        <div className={styles.etfrWrapper}>
                                                            <div className={styles.stylistName}>
                                                                {itemRec?.stylistName.toLowerCase()}
                                                            </div>
                                                            <div className={styles.timeSlot}>
                                                                <div>
                                                                    {convertTimeStampToHHMM(itemRec?.timeSlot).split(" ")[0]}
                                                                </div>
                                                                <div className={styles.ampm}>
                                                                    {convertTimeStampToHHMM(itemRec?.timeSlot).split(" ")[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {itemRec?.appliedSource}
                                                        <div className={styles.salonName}>
                                                            {itemRec?.salonName}
                                                        </div>
                                                        <div className={styles.slotRemarks}>
                                                            {itemRec?.slotRemarks.toLowerCase()}
                                                        </div>
                                                        {/* {itemRec?.jobIdentifier} */}
                                                        <div className={styles.applicationStatus}>
                                                            {itemRec?.hasRejected === "1" && <span className={styles.rejected}>Rejected</span>}
                                                            {itemRec?.hasHired === "1" && <span className={styles.hired}>Hired</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <div>
                        <div className={styles.headerWrapper}>
                            Loading...
                        </div>
                        <div className={styles.bodyWrapper}>
                            Loading...
                        </div>
                    </div>
                }

            </div>
        </div >
    )
}

export default AppCalendarHolder