import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Partners/ManageStoreEachDetails.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageStoreEachDetails = props => {
    const authCtx = useContext(AuthContext)
    const { currentStoreIdentifier } = props
    const [storeDetails, setStoreDetails] = useState(null)
    const [fetchingStoreDetails, setFetchingStoreDetails] = useState(true)
    const [latitude, setLatitude] = useState(storeDetails?.latitude)
    const [longitude, setLongitude] = useState(storeDetails?.langitude)
    const [isUpdating, setIsUpdating] = useState(false)
    const [cordinateDeletionStarted, setCordinateDeletionStarted] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [locations, setLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [updateCityActive, setUpdateCityActive] = useState(true)
    const [isUpdatingStoreCity, setIsUpdatingStoreCity] = useState(false)

    const getStoreDetails = async () => {
        setFetchingStoreDetails(true)
        const getStoreDetailsResponse = await fetch(BASE_URL_API + "/getStoreDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: currentStoreIdentifier
                })
            });

        if (!getStoreDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreDetailsRespo = await getStoreDetailsResponse.json();
            if (getStoreDetailsRespo.status === 'success') {
                setStoreDetails(getStoreDetailsRespo.response)
                getStoreDetailsRespo?.response?.cityIdentifier !== '' ? setUpdateCityActive(false) : setUpdateCityActive(true)
            } else {
                if (getStoreDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setStoreDetails(null)
                }
            }
            console.log(getStoreDetailsRespo)
        }
        setFetchingStoreDetails(false)
    }

    useEffect(() => {
        currentStoreIdentifier !== '' && getStoreDetails()
    }, [currentStoreIdentifier])


    const enterCordinateHandler = (t, v) => {
        if (t === 'lat') {
            setLatitude(v)
        } else if (t === 'lng') {
            setLongitude(v)
        }
    }

    const updateStoreCordinates = async () => {
        if (latitude !== undefined && longitude !== undefined) {
            if (latitude !== "" || longitude !== "") {
                console.log("Cool, I'm updating now...")
                setIsUpdating(true)
                const updateStoreCordinatesResponse = await fetch(BASE_URL_API + "/updateStoreCordinates",
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            token: authCtx.token,
                            executiveIdentifier: authCtx.userIdentifier,
                            latitude: latitude,
                            longitude: longitude,
                            storeIdentifier: currentStoreIdentifier
                        })
                    });

                if (!updateStoreCordinatesResponse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const updateStoreCordinatesRespo = await updateStoreCordinatesResponse.json();
                    if (updateStoreCordinatesRespo.status === 'success') {
                        setLatitude('')
                        setLongitude('')
                        getStoreDetails();
                    } else {
                        if (updateStoreCordinatesRespo.message === 'token Expired, please login again!') {
                            authCtx.logout()
                        } else {

                        }
                    }
                    console.log(updateStoreCordinatesRespo)
                }
                setIsUpdating(false)
                console.log(currentStoreIdentifier, latitude, longitude)
            } else {
                alert("Cordinates cannot be empty");
                console.log(currentStoreIdentifier, latitude, longitude)
            }
        } else {
            alert("Cordinates cannot be blank");
            console.log(currentStoreIdentifier, latitude, longitude)
        }
    }

    const initiateCordinatesDeletion = async () => {
        setCordinateDeletionStarted(true)
    }

    const removeCordinates = async () => {
        setIsDeleting(true)
        const removeCordinatesResponse = await fetch(BASE_URL_API + "/removeCordinates",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    storeIdentifier: currentStoreIdentifier
                })
            });

        if (!removeCordinatesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const removeCordinatesRespo = await removeCordinatesResponse.json()
            if (removeCordinatesRespo.status === "success") {
                getStoreDetails()
            } else {
                if (removeCordinatesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(removeCordinatesRespo)
        }
        setIsDeleting(false)
    }

    const adminGetOperatingLocations = async () => {
        const adminGetOperatingLocationsResponse = await fetch(BASE_URL_API + "/adminGetOperatingLocations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!adminGetOperatingLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetOperatingLocationsRespo = await adminGetOperatingLocationsResponse.json()
            if (adminGetOperatingLocationsRespo.status === 'success') {
                setLocations(adminGetOperatingLocationsRespo.response)
                
            } else {
                if (adminGetOperatingLocationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setLocations([])
                }
            }

            console.log(adminGetOperatingLocationsRespo)
        }
    }

    useEffect(() => {
        adminGetOperatingLocations()
    }, [])

    const updateStoreCity = async () => {
        setIsUpdatingStoreCity(true)
        const updateStoreCityResponse = await fetch(BASE_URL_API+"/updateStoreCity",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : currentStoreIdentifier,
                cityLabel : JSON.parse(selectedLocation)?.location,
                cityIdentifier : JSON.parse(selectedLocation)?.ID
            })
        });

        if(!updateStoreCityResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateStoreCityRespo = await updateStoreCityResponse.json()
            if(updateStoreCityRespo?.status==="success") {
                toast.success("Successfully updated!")
                setUpdateCityActive(false)
            } else {
                if(updateStoreCityRespo?.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast("Failed")
                }
                console.log(updateStoreCityRespo)
            }
        }
        setIsUpdatingStoreCity(false)
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {fetchingStoreDetails === true ?
                <div>
                    <div>
                        Hold On!
                    </div>
                    <div>
                        I'm collecting store details on your request!
                    </div>
                </div>
                :
                <div className={styles.mainContainer}>
                    <div className={styles.upperSection}>
                        <div>
                            <div>
                                {currentStoreIdentifier}
                            </div>
                            <div>
                                {storeDetails?.company_name}
                            </div>
                        </div>
                        <div>
                            {storeDetails?.address}
                        </div>

                        <div className={styles.cordinatesWrapper}>
                            <div>
                                <div className={styles.latitude} >Latitude</div>
                                {storeDetails?.latitude}
                            </div>
                            <div>
                                <div className={styles.longitude} >Longitude</div>
                                {storeDetails?.longitude}
                            </div>
                        </div>
                    </div>
                    <div className={styles.lowerSection}>
                        {storeDetails?.latitude === '' || storeDetails?.longitude === '' ?
                            <div className={styles.cordinatesWrapper}>
                                <div className={styles.eachCordinateWrapper}>
                                    <input
                                        placeholder="Please enter latitude"
                                        value={latitude}
                                        onChange={(e) => enterCordinateHandler('lat', e.target.value)}
                                    />
                                </div>
                                <div className={styles.eachCordinateWrapper}>
                                    <input
                                        placeholder="Please enter longitude"
                                        value={longitude}
                                        onChange={(e) => enterCordinateHandler('lng', e.target.value)}
                                    />
                                </div>
                                <div className={styles.updateBtn}>
                                    {isUpdating === true ?
                                        <button>Updating..</button>
                                        :
                                        <button onClick={() => updateStoreCordinates()} >Update</button>
                                    }
                                </div>
                            </div>
                            :
                            <div className={styles.actionWrapper}>
                                <div>
                                    Already updated the cordinates!
                                </div>
                                {authCtx?.userIdentifier === "999964N8406" &&
                                    <div className={styles.deletedCordinatesWrapper}>
                                        {cordinateDeletionStarted === false ?
                                            <div>
                                                <button onClick={() => initiateCordinatesDeletion()} >Delete The Cordinates</button>
                                            </div>
                                            :
                                            isDeleting === false ?
                                                <div className={styles.actionBtnWrapper}>
                                                    <button className={styles.confirmDelete} onClick={() => removeCordinates()} >Yes, Delete them!</button>
                                                    <button className={styles.abortDelete} onClick={() => setCordinateDeletionStarted(false)} >No, Do not delete them!</button>
                                                </div>
                                                :
                                                <div className={styles.actionBtnWrapper}>
                                                    <button className={styles.confirmDelete}>Please wait, deleting them...!</button>
                                                </div>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        {storeDetails?.cityIdentifier !== '' &&
                            <div>
                                <div>
                                    City : {storeDetails?.cityLabel}
                                </div>
                                <div>
                                    <button onClick={() => setUpdateCityActive(true)} onClick={() => setUpdateCityActive(true)} >Change</button>
                                </div>
                            </div>
                        }

                        {updateCityActive === true &&
                            <div>
                                <div>
                                    Update City
                                </div>
                                <div>
                                    <select onChange={(e) => setSelectedLocation(e.target.value)}>
                                        <option>Select City</option>
                                        {locations?.map((item, index) => (
                                            <option key={index} value={JSON.stringify(item)} >{item?.location}</option>
                                        ))}
                                    </select>
                                    {isUpdatingStoreCity===false ? <button onClick={() => updateStoreCity()} >Update</button> : <button >Updating...</button>}
                                    <button onClick={() => setUpdateCityActive(false)}>Cancel</button>
                                </div>
                            </div>
                        }



                    </div>
                </div>
            }
        </div>
    )
}

export default ManageStoreEachDetails