import React, { useState, useCallback, useContext } from 'react';
import Cropper from 'react-easy-crop';
import { Slider } from '@mui/material';
import { getCroppedImg } from './getCroppedImg'; // Utility function to get the cropped image
import { v4 as uuidv4 } from 'uuid';
import styles from './ImageUploader.module.css'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';

const ImageUploader = (props) => {
    const { isOpen, toggleOpen, stylistIdentifier, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                setImageSrc(reader.result);
            };
        }
    };

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels]);

    const handleUpload = async () => {
        
        if (!croppedImage) return;

        // Read the file as base64
        const reader = new FileReader();
        reader.readAsDataURL(croppedImage);
        reader.onloadend = async () => {
            const base64data = reader.result;

            // Create the payload
            const payload = {
                file: base64data,
                fileName: `${uuidv4()}.jpeg`,
                token: authCtx.token,
                executiveIdentifier : authCtx.userIdentifier,
                stylistIdentifier : stylistIdentifier
            };

            console.log('Uploading file:', payload);

            try {
                setIsUploading(true)
                const uploadProfilePicResponse = await fetch("https://admin.stylelink.in/apis/uploadUserProfilePic.php", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });

                if (!uploadProfilePicResponse.ok) {
                    // Handle error
                    console.error('Failed to upload image');
                } else {
                    // Handle success
                    const responseData = await uploadProfilePicResponse.json();
                    if (responseData.message === 'tokenExpired') {
                        authCtx.logout()
                    } else {
                        onSuccess()
                        console.log('Image uploaded successfully:', responseData);
                        toggleOpen()
                    }
                }
                setIsUploading(false)
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        };

        
    };



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.actionWrapper}>
                    <div>
                        <button onClick={() => toggleOpen()}>Close</button>
                    </div>
                    {croppedImage &&
                        <div>
                            {isUploading === true ?
                                <button>Please wait, Uploading....</button>
                                :
                                <button onClick={() => handleUpload()}>Upload</button>
                            }
                        </div>
                    }
                </div>

                <div className={styles.editorWrapper}>
                    <div className={styles.selectorWrapper}>
                        <input type="file" accept="image/*" onChange={onSelectFile} />
                        {imageSrc && (
                            <div>
                                <div style={{ position: 'relative', width: '100%', height: 400 }}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </div>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                                <button onClick={showCroppedImage}>Crop</button>
                            </div>
                        )}
                    </div>
                    {croppedImage ?
                        <div className={styles.croppedImageWrapper}>
                            <div className={styles.croppedImageContainer}>
                                <img src={URL.createObjectURL(croppedImage)} alt="Cropped" />
                            </div>
                        </div>
                        :
                        <div className={styles.croppedImageWrapper}>
                            <div className={styles.croppedImageContainerNull}>
                                Cropped image will appear here!
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
};

export default ImageUploader;
