import React, { useContext, useEffect, useState } from 'react'
import { MdFileCopy, MdOutlinePreview } from 'react-icons/md'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './CurrentSubscription.module.css'

const CurrentSubscription = (props) => {
    const { stylistIdentifier, resumeUrl } = props
    const authCtx = useContext(AuthContext)
    const [allSubscriptions, setAllSubscriptions] = useState([])
    
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(resumeUrl)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    

    const getAllResumeSubscriptions = async () => {
        const getAllResumeSubscriptionsResponse = await fetch(BASE_URL_API + "/getAllResumeSubscriptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getAllResumeSubscriptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllResumeSubscriptionsRespo = await getAllResumeSubscriptionsResponse.json()
            if (getAllResumeSubscriptionsRespo.status === "success") {
                setAllSubscriptions(getAllResumeSubscriptionsRespo.response)
            } else {
                if (getAllResumeSubscriptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllSubscriptions([])
                }
            }
            console.log(getAllResumeSubscriptionsRespo)
        }
    }

    useEffect(() => {
        getAllResumeSubscriptions()
    }, [])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.leftContainer}>
                        <div className={styles.tableHeader}>
                            <div className={styles.sn}>
                                #
                            </div>
                            <div className={styles.amount}>
                                Amount
                            </div>
                            <div className={styles.paymentMode}>
                                Payment Mode
                            </div>
                            <div className={styles.expiryDate}>
                                Expiry Date
                            </div>
                            <div className={styles.extendingForDays}>
                                Plan
                            </div>
                        </div>
                        <div>
                            {allSubscriptions?.map((item, index) => (
                                <div key={index} className={styles.eachRowWrapper}>
                                    <div className={styles.sn}>
                                        {index + 1}.
                                    </div>
                                    <div className={styles.amount}>
                                        {item?.amountPaid}
                                    </div>
                                    <div className={styles.paymentMode}>
                                        {item?.paymentMode}
                                    </div>
                                    <div className={styles.expiryDate}>
                                        {item?.expiryDate}
                                    </div>
                                    <div className={styles.extendingForDays}>
                                        {item?.extendingForDays} Days
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.shareContainer}>
                            <div className={styles.urlTxtInput}>
                                <input value={resumeUrl} readOnly />
                            </div>

                            <div className={styles.actionWrapper}>
                                <button onClick={handleCopy}>
                                    <MdFileCopy size={22} />
                                </button>
                                <a href={resumeUrl} target="_blank">
                                    <MdOutlinePreview size={22} />
                                </a>
                            </div>
                            {copied && <span style={{ color: 'green' }}>Copied!</span>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CurrentSubscription