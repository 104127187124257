import React, { useContext, useEffect, useState } from 'react'
import AllStoreConversations from '../../Components/Conversations/AllStoreConversations'
import JTEachRow from '../../Components/Jobs/JobsTracker/JTEachRow'
import JTFilters from '../../Components/Jobs/JobsTracker/JTFilters'
import { getTimeDifferenceHumanReadable } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './JobsTracker.module.css'

const JobsTracker = () => {
    const authCtx = useContext(AuthContext)
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const [openingStatus, setOpeningStatus] = useState('ALL') // CLOSED, OPEN
    const [searchKey, setSearchKey] = useState('')
    const [isFetchingJobs, setIsFetchingJobs] = useState(false)

    const [isLoadingCategoryData, setIsLoadingCategoryData] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const selectCategoryHandler = (k) => {
        selectedCategory.includes(k) === false ? setSelectedCategory([...selectedCategory, k]) : setSelectedCategory(selectedCategory.filter((item) => item !== k))
    }

    const [paidHiringStatus, setPaidHiringStatus] = useState(null) // true, false
    const togglePaidHiringStatus = (k) => {
        setPaidHiringStatus(paidHiringStatus===k ? null : k)
    }

    
    const openingStatusHandler = (k) => setOpeningStatus(k)

    const applyFilters = () => getJobsForTracker()

    const [allData, setAllData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const abort = () => setIsFilterOpen(false)

    const getJobsForTracker = async () => {
        setIsFetchingJobs(true)
        const getJobsForTrackerResponse = await fetch(BASE_URL_API + "/getJobsForTracker",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    openingStatus: openingStatus,
                    selectedCategory: selectedCategory,
                    paidHiringStatus : paidHiringStatus
                })
            });

        if (!getJobsForTrackerResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobsForTrackerRespo = await getJobsForTrackerResponse.json()
            if (getJobsForTrackerRespo.status === "success") {
                setAllData(getJobsForTrackerRespo.response)
            } else {
                if (getJobsForTrackerRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getJobsForTrackerRespo)
        }
        setIsFetchingJobs(false)
    }

    useEffect(() => {
        getJobsForTracker()
    }, [])




    const getCategoryOptions = async () => {
        setIsLoadingCategoryData(true)
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json()
            if (getCategoryOptionsRespo.status === "success") {
                setCategoryData(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getCategoryOptionsRespo)
        }

        setIsLoadingCategoryData(false)
    }


    useEffect(() => {
        getCategoryOptions()
    }, [])

    // const filterDataBySearchKey = () => {
    //     if (!searchKey.trim()) {
    //         setFilteredData(allData);
    //     } else {
    //         setFilteredData(allData.filter(item => {
    //             return Object.values(item.jobDetails).some(value => {
    //                 return value !== null && value !== undefined && value?.toString()?.toLowerCase()?.includes(searchKey?.toLowerCase());
    //             });
    //         }));
    //     }
    // };

    const filterDataBySearchKey = () => {
        if (!searchKey.trim()) {
            setFilteredData(allData);
        } else {
            setFilteredData(allData.filter(item => {
                const jobDetailsMatch = Object.values(item.jobDetails).some(value => {
                    return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchKey.toLowerCase());
                });

                const allotedDataMatch = Array.isArray(item.allotedData) && item.allotedData.some(allotment => {
                    return Object.values(allotment).some(value => {
                        return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchKey.toLowerCase());
                    });
                });

                return jobDetailsMatch || allotedDataMatch;
            }));
        }
    };




    useEffect(() => {
        filterDataBySearchKey()
    }, [allData, searchKey])



    return (
        <div>
            
            <JTFilters
                isOpen={isFilterOpen} abort={abort}
                openingStatus={openingStatus}
                openingStatusHandler={openingStatusHandler}
                categoryData={categoryData}
                selectedCategory={selectedCategory}
                selectCategoryHandler={selectCategoryHandler}
                applyFilters={applyFilters}
                paidHiringStatus={paidHiringStatus}
                togglePaidHiringStatus={togglePaidHiringStatus}
                isFetchingJobs={isFetchingJobs}
            />
            <div className={styles.headerWrapper}>
                <div>
                    Track Jobs
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.searchWrapper}>
                        <input placeholder="Please enter that you want to search..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                    </div>
                    <select>
                        <option>All</option>
                        {/* <option>Nav</option> */}
                    </select>
                    
                    <button onClick={() => setIsFilterOpen(true)}>
                        Filters
                    </button>
                </div>
            </div>
            <div className={styles.headerTxt}>
                {filteredData?.length} / {allData?.length} jobs found!
            </div>
            <div>
                <div className={styles.tableHeader}>
                    <div className={styles.postedOn}>
                        Posted On
                    </div>
                    <div className={styles.title}>
                        Title
                    </div>
                    <div className={styles.storeDetails}>
                        Store Details
                    </div>
                    <div className={styles.allocationData}>
                        Alloted Data
                    </div>
                    <div className={styles.currentStatus}>
                        Current Status
                    </div>
                    <div className={styles.densityWrapper}>
                        Candidate Density
                    </div>
                    <div className={styles.applicationData}>
                        Application Data
                    </div>
                    <div className={styles.actionWrapper}>
                        Actions
                    </div>

                </div>
                <div className={styles.tableDataWrapper}>
                    {filteredData?.map((item, index) => (
                        <JTEachRow key={index} item={item} />
                    ))}
                </div>
            </div>
        </div>

    )
}

export default JobsTracker