import React from 'react'
import styles from '../Partners/PartnersLeadListView.module.css'
import PartnersLeadListEachLead from './PartnersLeadListEachLead';

const PartnersLeadListView = props => {
    const { sd } = props;
    

    return (
        <div className={styles.leftCol}>
            <div>
                <div>
                </div>
                <div>
                    {sd.map((item, index) => (
                        <PartnersLeadListEachLead item={item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PartnersLeadListView