import React, { useState, useContext } from "react";
import { BASE_URL_API } from "../References/urls";
import AuthContext from "../store/auth-context";
import styles from "../Styles/Content/AddInstructor.module.css"
import { useNavigate } from "react-router-dom";

const AddInstructor = props => {
    const authCtx = useContext(AuthContext);
    let navigate = useNavigate();

    const [instructorName, setInstructorName] = useState("");
    const [instructorGender, setInstructorGender] = useState("");
    const [instructorDescription, setInstructorDescription] = useState("");
    const [instructorProfilePic, setInstructorProfilePic] = useState("");
    const [instructorCategory, setInstructorCategory] = useState("");
    const [dd, setDd] = useState("");
    const [mm, setMm] = useState("");
    const [yyyy, setYyyy] = useState("");
    let instructorDateOfBirth = yyyy+"-"+mm+"-"+dd+" 00:00:01";

    const submitHandler = async () => {
        const addInstructorResponse = await fetch(BASE_URL_API+"/addInstructor",
        {
            method: "POST",
            body : JSON.stringify({
                token : authCtx.token,
                instructorName : instructorName, 
                instructorGender : instructorGender, 
                instructorDescription : instructorDescription,
                instructorProfilePic : instructorProfilePic,
                instructorCategory : instructorCategory,
                instructorDateOfBirth : instructorDateOfBirth
            })
        });

        if(!addInstructorResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const addInstructorRespo = await addInstructorResponse.json();
            if(addInstructorRespo.status==="success") {
                navigate("/instructors");
            } else {
                console.log(addInstructorRespo);
            }
        }
    }
    
    return (
        <div className={styles.mainContainer}>
            <div className={styles.formContainer}>
                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Name</div>
                    <input placeholder="Enter instructor name" onChange={(e) => setInstructorName(e.target.value)} value={instructorName}></input>
                </div>
                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Gender</div>
                    <select onChange={(e) => setInstructorGender(e.target.value)}>
                        <option>Select Gender</option>
                        <option>Female</option>
                        <option>Male</option>
                    </select>
                </div>
                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Description</div>
                    <textarea onChange={(e) => setInstructorDescription(e.target.value)}></textarea>
                </div>

                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Profile Pic</div>
                    <input placeholder="Enter profile pic URL... e.g. https://res.cloudinary.com/something/profile/pic/image.png" onChange={(e) => setInstructorProfilePic(e.target.value)}></input>
                </div>
                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Category</div>
                    <select onChange={(e) => setInstructorCategory(e.target.value)}>
                        <option>Select Category</option>
                        <option>MAKEUP</option>
                        <option>HAIR</option>
                        <option>BEAUTY</option>
                        <option>CAREER</option>
                    </select>
                </div>
                <div className={styles.eachRow}>
                    <div className={styles.eachRowTitle}>Instructor Birthdate (DD/MM/YYYY)</div>
                    <div className={styles.dateOfBirth}>
                        <select onChange={(e) => setDd(e.target.value)}>
                            <option>Select Birth Date</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                        </select>
                        <select onChange={(e) => setMm(e.target.value)}>
                            <option>Select Birth Month</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                        </select>
                        <select onChange={(e) => setYyyy(e.target.value)}>
                            <option>Select Birth Year</option>
                            <option>1970</option>
                            <option>1971</option>
                            <option>1972</option>
                            <option>1973</option>
                            <option>1974</option>
                            <option>1975</option>
                            <option>1976</option>
                            <option>1977</option>
                            <option>1978</option>
                            <option>1979</option>
                            <option>1980</option>
                            <option>1981</option>
                            <option>1982</option>
                            <option>1983</option>
                            <option>1984</option>
                            <option>1985</option>
                            <option>1986</option>
                            <option>1987</option>
                            <option>1988</option>
                            <option>1989</option>
                            <option>1990</option>
                            <option>1991</option>
                            <option>1992</option>
                            <option>1993</option>
                            <option>1994</option>
                            <option>1995</option>
                            <option>1996</option>
                            <option>1997</option>
                            <option>1998</option>
                            <option>1999</option>
                            <option>2000</option>
                            <option>2001</option>
                            <option>2002</option>
                            <option>2003</option>
                            <option>2004</option>
                            <option>2005</option>
                            <option>2006</option>
                            <option>2007</option>
                            <option>2008</option>
                            <option>2009</option>
                            <option>2010</option>
                            <option>2011</option>
                            <option>2012</option>
                            <option>2013</option>
                            <option>2014</option>
                            <option>2015</option>
                            <option>2016</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                        </select>
                    </div>
                </div>

                {instructorName!=="" && instructorGender!=="" && instructorDescription!=="" && instructorProfilePic!=="" && instructorCategory!=="" &&
                <button className={styles.submitBtn} onClick={() => submitHandler()}>Submit</button>}

            </div>
        </div>
    )
}

export default AddInstructor;