import React, { useContext, useEffect, useState } from 'react'
import styles from './ActivateSubscription.module.css'
import { MdOutlinePreview } from "react-icons/md";
import { MdFileCopy } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

import AuthContext from '../../../store/auth-context';
import { BASE_URL_API } from '../../../References/urls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ActivateSubscription = (props) => {
    const { stylistIdentifier, resumeUrl, toggleActiveOption } = props
    const authCtx = useContext(AuthContext)

    
    const [selectedPlan, setSelectedPlan] = useState('')
    const [selectedPMode, setSelectedPMode] = useState('')
    const [amount, setAmount] = useState('')
    const [hasPaymentReceived, setHasPaymentReceived] = useState(false)
    const [paramConfirm, setParamConfirm] = useState(false)
    const [isActivatingPlan, setIsActivatingPlan] = useState(false)
    const [extendingRemark, setExtendingRemark] = useState(0)
    const [isExtendingNow, setIsExtendingNow] = useState(false)

   


    useEffect(() => {
        if (selectedPlan !== '') {
            if (selectedPMode !== '') {
                if (amount !== '') {
                    if (hasPaymentReceived) {
                        setParamConfirm(true)
                    } else {
                        setParamConfirm(false)
                    }
                } else {
                    setParamConfirm(false)
                }
            } else {
                setParamConfirm(false)
            }
        } else {
            setParamConfirm(false)
        }
    }, [selectedPlan, selectedPMode, amount, hasPaymentReceived])

    const previewActivationHandler = () => {
        setIsActivatingPlan(true)
        previewResumeSubscriptionActivation()
    }

    const previewResumeSubscriptionActivation = async () => {
        const previewResumeSubscriptionActivationResponse = await fetch(BASE_URL_API + "/previewResumeSubscriptionActivation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    extendingForDays: selectedPlan
                })
            })

        if (!previewResumeSubscriptionActivationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const previewResumeSubscriptionActivationRespo = await previewResumeSubscriptionActivationResponse.json()
            if (previewResumeSubscriptionActivationRespo.status === "success") {
                setExtendingRemark(parseInt(previewResumeSubscriptionActivationRespo.message))
            } else {
                if (previewResumeSubscriptionActivationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setExtendingRemark(0)
                }
            }
            console.log(previewResumeSubscriptionActivationRespo)
        }
    }

    const extendResumeSubscription = async () => {
        setIsExtendingNow(true)
        const extendResumeSubscriptionResponse = await fetch(BASE_URL_API + "/extendResumeSubscription",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    extendingForDays: selectedPlan,
                    amount: amount,
                    paymentMode: selectedPMode
                })
            });

        if (!extendResumeSubscriptionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const extendResumeSubscriptionRespo = await extendResumeSubscriptionResponse.json()
            if (extendResumeSubscriptionRespo.status === "success") {
                toast("Successfully updated the resume access!")
                toggleActiveOption('Current')
            } else {
                if (extendResumeSubscriptionRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast("Failed!!, Please contact admin!")
                }
            }
            console.log(extendResumeSubscriptionRespo)
        }
        setIsExtendingNow(false)
    }


    return (
        <div className={styles.mainWrapper}>
            
            <div className={styles.mainContainer}>
                {isActivatingPlan === false ?
                    <div className={styles.leftContainer}>
                        <div className={styles.plansWrapper}>
                            <div className={styles.plansContainer}>
                                <div className={selectedPlan === '1' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('1')}
                                >
                                    1 Day
                                </div>
                                <div className={selectedPlan === '7' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('7')}
                                >
                                    7 Days
                                </div>
                                <div className={selectedPlan === '30' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('30')}
                                >
                                    30 Days
                                </div>
                                <div className={selectedPlan === '90' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('90')}
                                >
                                    90 Days
                                </div>
                                <div className={selectedPlan === '180' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('180')}
                                >
                                    180 Days
                                </div>
                                <div className={selectedPlan === '365' ? styles.eachSelectedPlanContainer : styles.eachPlanContainer}
                                    onClick={() => setSelectedPlan('365')}
                                >
                                    365 Days
                                </div>
                            </div>
                        </div>
                        <div className={styles.paymentWrapper}>
                            <div className={styles.paymentContainer}>
                                <div>
                                    Payment Details
                                </div>
                                <div>
                                    <div className={styles.eachOptionWrapper}>
                                        <div className={styles.eachOptionTitle}>
                                            Amount
                                        </div>
                                        <div className={styles.eachOptionField}>
                                            <input autoFocus value={amount} onChange={(e) => setAmount(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className={styles.eachOptionWrapper}>
                                        <div className={styles.eachOptionTitle}>
                                            Payment Mode
                                        </div>
                                        <div className={styles.pmodeWrapper}>
                                            <button className={selectedPMode === 'QR' ? styles.eachPModeWrapperSelected : styles.eachPModeWrapper}
                                                onClick={() => setSelectedPMode('QR')}
                                            >
                                                QR
                                            </button>
                                            <button className={selectedPMode === 'URL' ? styles.eachPModeWrapperSelected : styles.eachPModeWrapper}
                                                onClick={() => setSelectedPMode('URL')}
                                            >
                                                URL
                                            </button>
                                        </div>
                                    </div>

                                    <div className={styles.eachOptionWrapper}>
                                        <div className={styles.eachOptionTitle}>
                                            Have you received the payment?
                                        </div>
                                        <div className={styles.payConfirmBtn}>
                                            <button className={hasPaymentReceived === false ? styles.payConfirmSelected : styles.payConfirm}
                                                onClick={() => setHasPaymentReceived(false)}
                                            >
                                                No
                                            </button>
                                            <button className={hasPaymentReceived === true ? styles.payConfirmSelected : styles.payConfirm}
                                                onClick={() => setHasPaymentReceived(true)}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>


                                    <div className={styles.eachOptionWrapper}>
                                        <div className={styles.eachOptionTitle}>
                                            Would you like to activate resume?
                                        </div>
                                        {paramConfirm &&
                                            <div className={styles.payConfirmBtn}>
                                                <button className={styles.payConfirm} onClick={() => previewActivationHandler()} >Yes</button>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.leftContainer}>
                        <div className={styles.confirmationTitle}>
                            <div>
                                You are extending the resume access for next {extendingRemark} days.
                            </div>
                            <div>
                                {parseInt(extendingRemark) !== parseInt(selectedPlan) && `${selectedPlan} days from current plan and additional ${parseInt(extendingRemark) - parseInt(selectedPlan)} day(s) from older plan.`}
                            </div>
                        </div>
                        <div className={styles.warningWrapper}>
                            <div className={styles.warning}>
                                <IoWarningOutline size={22} />&nbsp;&nbsp;Do not confirm the resume access if you haven't received & confirmed the payment status!
                            </div>
                        </div>
                        <div className={styles.confirmActivationWrapper}>
                            <div className={styles.confirmActivationContainer}>
                                <button className={styles.cancelBtn} onClick={() => setIsActivatingPlan(false)}>Cancel</button>
                                {isExtendingNow === true ?
                                    <button className={styles.confirmBtn} >Please wait...</button>
                                    :
                                    <button className={styles.confirmBtn} onClick={() => extendResumeSubscription()} >Confirm</button>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className={styles.rightContainer}>
                    {/* <div className={styles.shareContainer}>
                        <div className={styles.urlTxtInput}>
                            <input value={resumeUrl} readOnly />
                        </div>

                        <div className={styles.actionWrapper}>
                            <button onClick={handleCopy}>
                                <MdFileCopy size={22} />
                            </button>
                            <a href={resumeUrl} target="_blank">
                                <MdOutlinePreview size={22} />
                            </a>
                        </div>
                        {copied && <span style={{ color: 'green' }}>Copied!</span>}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ActivateSubscription