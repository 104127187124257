import React, { useContext, useState } from 'react'
import { BiUser } from 'react-icons/bi'
import { FaArrowLeft, FaDocker, FaHome, FaSlidersH, FaStore, FaStoreSlash } from 'react-icons/fa'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import MpaAuthUsers from '../../Components/ManagePartnersAccounts/MpaAuthUsers'
import MpaHome from '../../Components/ManagePartnersAccounts/MpaHome'
import MpaStores from '../../Components/ManagePartnersAccounts/MpaStores'
import AuthContext from '../../store/auth-context'
import styles from './ManagePartnersAccountEach.module.css'

const ManagePartnersAccountEach = () => {
    const { vendorIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('HOME')

    console.log(vendorIdentifier)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.lcwTop}>
                        <Link to="/manage-partners-account" >
                            <FaArrowLeft size={20} color="#a5a5a5" />
                        </Link>
                    </div>
                    <div className={styles.lcwCenter}>
                        <button className={styles.eachOption} onClick={() => setActiveTab("HOME")}>
                            <FaHome size={20} />    
                        </button>
                        <button className={styles.eachOption} onClick={() => setActiveTab("USER")}>
                            <BiUser size={20} />
                        </button>
                        <button className={styles.eachOption} onClick={() => setActiveTab("STORE")}>
                            <FaStore size={20} />
                        </button>
                    </div>
                    <div className={styles.lcwBottom}>
                        <FaSlidersH size={20} />
                    </div>
                </div>
                <div className={styles.rightColWrapper}>
                    {activeTab==="HOME" && <MpaHome vendorIdentifier={vendorIdentifier} />}
                    {activeTab==="USER" && <MpaAuthUsers vendorIdentifier={vendorIdentifier} />}
                    {activeTab==="STORE" && <MpaStores  vendorIdentifier={vendorIdentifier} />}
                </div>
            </div>
        </div>
    )
}

export default ManagePartnersAccountEach