import React, { useState } from 'react'
import AddItemToInvoicePopUp from '../../Components/Invoices/AddItemToInvoicePopUp'
import styles from './EachHiringInvoice.module.css'

const EachHiringInvoice = () => {
    const [isAddingItemOpen, setIsAddingItemOpen] = useState(false)
    const toggleAddingItem = () => setIsAddingItemOpen(false)

    return (
        <div className={styles.mainWrapper}>
            {isAddingItemOpen && <AddItemToInvoicePopUp isOpen={isAddingItemOpen} onClose={toggleAddingItem} vendorIdentifier={"1709702135N1F3a0LoK0OF"} />}
            <div className={styles.mainContainer}>
                <div className={styles.navWrapper}>

                </div>
                <div className={styles.editingWrapper}>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachFieldTitle}>
                                Invoice No.
                            </div>
                            <div className={styles.eachFieldInput}>
                                <input />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachFieldTitle}>
                                Please select account
                            </div>
                            <div className={styles.eachFieldInput}>
                                <input />
                            </div>
                        </div>


                        <div className={styles.itemsWrapper}>
                            <div className={styles.itemsContainer}>
                                <div>

                                </div>
                                <div onClick={() => setIsAddingItemOpen(true)}>
                                    Add Item + 
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className={styles.previewWrapper}>
                    <div className={styles.pwBodyWrapper}>

                    </div>
                    <div className={styles.pwActionWrapper}>
                        EachHiringInvoice
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachHiringInvoice