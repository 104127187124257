import React from 'react'
import styles from '../Generic/DateTimePickerUniversal.module.css'

const DateTimePickerUniversal = props => {
    let selectedSlot = props.selectedSlot;
    let selectedSlotToggle = props.selectedSlotToggle;
    return (
        <div className={styles.mainDateTimeSelectorWrapper}>
            <input 
                type="datetime-local"
                min={new Date().toISOString().slice(0, 16)}
                onChange={(e) => selectedSlotToggle(e.target.value)}
            />
        </div>
    )
}

export default DateTimePickerUniversal