import React from 'react'
import styles from './JTApplicationsDataEachRow.module.css'

const JTApplicationsDataEachRow = ({ item, index }) => {
    return (
        <div className={styles.eachRowWrapper}>
            <div className={styles.sn}>
                {index + 1}
            </div>
            <a className={styles.cn} href={`/manage-application/${item?.applicationId}`} target="_blank" >
                {item?.stylist_name}
            </a>
            <div className={styles.phone}>
                {item?.stylist_contact}
            </div>
            <div className={styles.gender}>
                {item?.applicantGender}
            </div>
            <div className={styles.appliedOn}>
                {item?.applied_on}
            </div>
            <div className={styles.appliedBy}>
                {item?.firstName} {item?.lastName}
            </div>
            <div className={styles.currentStatus}>
                {item?.is_rejected === '0' ?
                    <span>
                        {item?.is_hired === "1" ?
                            <span>Hired</span>
                            :
                            <span>Applied</span>
                        }
                    </span>
                    :
                    <span>
                        Rejected
                    </span>
                }
            </div>
            <div className={styles.currentStatus}>
                <a className={styles.viewAppBtn} href={`/manage-application/${item?.applicationId}`} target="_blank">View Application</a>
            </div>

        </div>
    )
}

export default JTApplicationsDataEachRow