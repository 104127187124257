import React, { useContext, useEffect, useState } from "react";
import styles from "../Styles/Content/ManageJobs.module.css";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL_API } from "../References/urls";
import EachJob from "../Components/EachJob";
import AuthContext from "../store/auth-context";
import MainLoader from "../Components/MainLoader";

import { FiFilter } from 'react-icons/fi';
import { BsCheck2All } from 'react-icons/bs';


import {
    ShimmerThumbnail,
} from "react-shimmer-effects";
import LocationsFilter from "../Components/Jobs/LocationsFilter";


const ManageJobs = () => {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [jobsFetchingStatus, setJobsFetchingStatus] = useState('FETCHING'); //FETCHED
    const [jobsData, setJobsData] = useState([]);
    const [categoryOptionsData, setCategoryOptionsData] = useState([]);
    const [selectedJob, setSelectedJob] = useState();
    const [selectedJobCategories, setSelectedJobCategories] = useState([])
    const [selectedLocations, setSelectedLocations] = useState([])

    const [isRoute, setIsRoute] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [orderBy, setOrderBy] = useState('NEWEST_FIRST');

    const selectLocationHandler = k => {
        setSelectedLocations(selectedLocations.includes(k) === true ? selectedLocations.filter((item) => item != k) : [...selectedLocations, k]);
    }

    useEffect(() => {
        getAllJobs();
    }, [isRoute, isActive, orderBy, selectedJobCategories, selectedLocations])

    const notify = () => toast("Wow so easy!");

    const getAllJobs = async () => {
        setJobsFetchingStatus('FETCHING');
        const allJobsResponse = await fetch(BASE_URL_API + "/getAllJobs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    isRoute: isRoute,
                    isActive: isActive,
                    orderBy: orderBy,
                    jobCategories: selectedJobCategories,
                    locations: selectedLocations
                })
            });

        if (!allJobsResponse.ok) {
            console.log("Something went wrong while fetching the jobs");
        } else {
            const allJobsRespo = await allJobsResponse.json();
            if (allJobsRespo.status === 'success') {
                setJobsData(allJobsRespo.response);
            } else {
                if (allJobsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setJobsData([]);
                }
            }
        }
        setJobsFetchingStatus('FETCHED');
    }

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong!");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setCategoryOptionsData(getCategoryOptionsRespo.response)
            } else {
                setCategoryOptionsData([])
            }
        }
    }

    const fetchJobDetails = (x) => setSelectedJob(x)

    const openFilterPanel = () => {
        // document.getElementById("filtersPanelWrapper").style.display = 'block';
        document.getElementById("filtersPanelWrapper").style.animation = "slideRightToLeft 0.5s forwards";
    }

    const toggleCategorySelectionHandler = (k) => {
        if (selectedJobCategories.includes(k)) {
            setSelectedJobCategories(selectedJobCategories.filter((item) => item != k));
        } else {
            setSelectedJobCategories([...selectedJobCategories, k]);
        }
    }



    window.onclick = function (event) {
        if (event.target.id == "filtersPanelWrapper") {
            // document.getElementById("filtersPanelWrapper").style.right = '100%';
            document.getElementById("filtersPanelWrapper").style.animation = "slideLeftToRight .1s forwards";
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])


    if (isLoading) {
        return (
            <div>
                <MainLoader />
            </div>
        )
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftPanel}>
                <div className={styles.jobListHeader}>
                    <div className={styles.getAll}>
                        <button onClick={() => getAllJobs()}>
                            All
                        </button>
                        <span className={styles.loadingTxt}>
                            {jobsFetchingStatus === 'FETCHING' ? 'Loading jobs...' :
                                jobsData.length + ' result(s) found!'
                            }
                        </span>
                    </div>
                    <div className={styles.filterIcon}
                        onClick={() => openFilterPanel()}
                    >
                        <FiFilter />
                    </div>
                </div>
                <div className={styles.jobsListWrapper}>
                    {jobsFetchingStatus === 'FETCHING' ?
                        <div>
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                        </div>
                        :
                        jobsData.map((item, index) => (
                            <div className={selectedJob == item ? styles.eachJobNavSelected : styles.eachJobNav} key={item.job_id} onClick={() => fetchJobDetails(item)}>
                                <div className={styles.categoryContainer}>
                                    <div className={styles.categoryFc}>{item.job_category_name.substr(0, 1)}</div>
                                </div>
                                <div className={styles.eachJobNavRight}>
                                    <div>
                                        {item.company_name}
                                    </div>
                                    <div className={styles.jobTitle}>{item.title}</div>
                                    <div className={styles.postedOn}>
                                        <div>
                                            {item.creation_date}
                                        </div>
                                        {item.isClosed === '0' ?
                                            <div className={styles.jobStatusActive}>
                                                Active
                                            </div>
                                            :
                                            <div className={styles.jobStatusClosed}>
                                                Closed
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>

                <div className={styles.filtersPanelWrapper}
                    id="filtersPanelWrapper"
                >
                    <div className={styles.filtersContainer}>
                        <div className={styles.filtersColumns}>
                            <div className={styles.eachFilterColumn}>
                                <div className={styles.eachColTitle}>
                                    Sort By
                                </div>
                                <div>
                                    Salary, Posted Date, Closing
                                </div>
                            </div>

                            <div className={styles.eachFilterColumn}>
                                <div className={styles.eachColTitle}>
                                    Current Status
                                </div>
                                <div>
                                    <div className={styles.eachFilterOption}>
                                        <div className={styles.eachFilterTitle}>
                                            Route Updated
                                        </div>
                                        <div className={styles.eachFilterOptionBtns}>
                                            <button onClick={() => setIsRoute('UPDATED')}
                                                className={isRoute == 'UPDATED' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => setIsRoute('NOT_UPDATED')}
                                                className={isRoute == 'NOT_UPDATED' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>

                                    <div className={styles.eachFilterOption}>
                                        <div className={styles.eachFilterTitle}>
                                            Active
                                        </div>
                                        <div className={styles.eachFilterOptionBtns}>
                                            <button onClick={() => setIsActive('ACTIVE')}
                                                className={isActive == 'ACTIVE' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => setIsActive('NOT_ACTIVE')}
                                                className={isActive == 'NOT_ACTIVE' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>


                                    <div>

                                        -
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachFilterColumn}>
                                <div className={styles.eachColTitle}>
                                    Profile Category
                                </div>
                                <div>
                                    {categoryOptionsData.map((item, index) => (
                                        <div className={styles.eachCategoryOption}
                                            onClick={() => toggleCategorySelectionHandler(item.ID)}
                                            key={index}
                                        >
                                            <div>
                                                {item.category_name}
                                            </div>
                                            <div>
                                                {selectedJobCategories.includes(item.ID) == true &&
                                                    <BsCheck2All />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>


                            <div className={styles.eachFilterColumn}>
                                <LocationsFilter
                                    selectedLocations={selectedLocations}
                                    selectLocationHandler={selectLocationHandler}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.centerPanel}>
                <EachJob jobData={JSON.stringify(selectedJob)}
                    getAllJobs={getAllJobs}
                />
            </div>
            <div className={styles.rightPanel}>
                <button onClick={() => notify()}>Notify!</button>
                <ToastContainer />
                <a href="https://stylelink.in/masterGenSitemap.php" target="_blank">Update Sitemap</a>
            </div>
        </div>
    )
}

export default ManageJobs;