import React, { useContext, useEffect, useState } from 'react'
import { BsArrowLeft, BsListTask } from 'react-icons/bs'
import { MdBarChart, MdPhone, MdSummarize } from 'react-icons/md'
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import CAEJAnalysisMode from '../../Components/CreateApplications/CAEJAnalysisMode'
import CAEJCurrentStatusToggler from '../../Components/CreateApplications/CAEJCurrentStatusToggler'
import CAEJShowJobDescription from '../../Components/CreateApplications/CAEJShowJobDescription'
import CAEJSummary from '../../Components/CreateApplications/CAEJSummary'
import CAEJWorkMode from '../../Components/CreateApplications/CAEJWorkMode'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CreateApplicationForEachJob.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JTApplicationsData from '../../Components/Jobs/JobsTracker/JTApplicationsData'
import CAEJShowStorePocs from '../../Components/CreateApplications/CAEJShowStorePocs'
import CreateApplicationSummaryWorkspace from './CreateApplicationSummaryWorkspace'
import AllStoreConversations from '../../Components/Conversations/AllStoreConversations'

const CreateApplicationForEachJob = () => {
    const authCtx = useContext(AuthContext)
    const { jobIdentifier } = useParams()
    const [jobDetails, setJobDetails] = useState([])
    const [showJd, setShowJd] = useState(false)
    const [activeMode, setActiveMode] = useState('WORK')
    const [isApplicationDataModalOpen, setIsApplicationDataModalOpen] = useState(false)
    const [isPocOpen, setIsPocOpen] = useState(false)

    const viewApplicationHandler = (k) => setIsApplicationDataModalOpen(k)
    const togglePocOpen = () => setIsPocOpen(false)

    const [isCurrentStatusOpen, setIsCurrentStatusOpen] = useState(true)
    const currentStatusPopUpToggle = () => setIsCurrentStatusOpen(false)

    const [isWSOpen, setIsWSOpen] = useState(false)
    const toggleWSOpen = () => setIsWSOpen(false)

    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)


    const getJobDetailsForTask = async () => {
        const getJobDetailsForTaskResponse = await fetch(BASE_URL_API + "/getJobDetailsForTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier
                })
            });
        if (!getJobDetailsForTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDetailsForTaskRespo = await getJobDetailsForTaskResponse.json()
            if (getJobDetailsForTaskRespo.status === "success") {
                setJobDetails(getJobDetailsForTaskRespo.response)
            } else {
                if (getJobDetailsForTaskRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setJobDetails([])
                }
            }
            console.log(getJobDetailsForTaskRespo)
        }
    }

    useEffect(() => {
        getJobDetailsForTask()
    }, [jobIdentifier])




    const closeHandler = () => setShowJd(false)

    console.log("this is job details :", jobDetails)

    return (
        <div className={styles.mainWrapper}>
            {showJd && <CAEJShowJobDescription isOpen={showJd} jd={jobDetails} closeHandler={closeHandler} />}
            {isCurrentStatusOpen && <CAEJCurrentStatusToggler isOpen={isCurrentStatusOpen} onClose={currentStatusPopUpToggle} onSuccess={getJobDetailsForTask} jobIdentifier={jobIdentifier} defaultStatus={jobDetails?.currentStatus} />}
            {isApplicationDataModalOpen && <JTApplicationsData isOpen={isApplicationDataModalOpen} viewApplicationHandler={viewApplicationHandler} jobIdentifier={jobIdentifier} />}
            {isPocOpen && <CAEJShowStorePocs isOpen={isPocOpen} togglePocOpen={togglePocOpen} storeIdentifier={jobDetails?.store_id} />}
            {isWSOpen && <CreateApplicationSummaryWorkspace isOpen={isWSOpen} caswToggleHandler={toggleWSOpen} selectedJob={jobDetails} jobIdentifier={jobDetails?.job_id} />}

            {isScHolderOpen && <AllStoreConversations
                isOpen={isScHolderOpen} 
                storeIdentifier={jobDetails?.store_id}
                onClose={onClosingScHolder} 
                jobIdentifier={jobDetails?.job_id}
            />}

            <ToastContainer />
            <div className={styles.headerWrapper}>
                <div className={styles.headerLeft}>
                    <div className={styles.goToJobs}>
                        <Link to="/jobs-tasks"><BsArrowLeft size={22} color="#00000070" /></Link>
                    </div>
                    <div className={styles.jobTitleWrapper}>
                        <div className={styles.title}>
                            {jobDetails?.title}
                        </div>
                        <div className={styles.jobCategory}>
                            {jobDetails?.job_category_name}
                        </div>
                    </div>
                    <div className={styles.storeDetails}>
                        <a target="_blank" href={`/manage-stores/${jobDetails?.store_id}`} className={styles.companyName}>{jobDetails?.company_name}</a>
                        <div className={styles.address}>{jobDetails?.address}</div>
                    </div>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.currentStatus}>
                        <button onClick={() => setIsCurrentStatusOpen(true)} >{jobDetails?.currentStatus}</button>
                    </div>
                    <div className={styles.jobDetails}>
                        <button title="View workspace" onClick={() => setIsWSOpen(true)}>View WS</button>
                    </div>
                    <div className={styles.jobDetails}>
                        <button onClick={() => setIsApplicationDataModalOpen(true)}>Track Applications</button>
                    </div>
                    <div className={styles.jobDetails}>
                        <button onClick={() => setShowJd(true)}>View JD</button>
                    </div>
                    <div className={styles.jobDetails, styles.chatIcon}>
                        <button onClick={() => setIsScHolderOpen(true)}>
                            <IoChatbubbleEllipsesSharp size={18} color="white" />
                        </button>
                    </div>
                </div>

            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.allModeWrapper}>
                    <div className={styles.modeWrapper} onClick={() => setActiveMode("WORK")} >
                        <BsListTask size={20} color={activeMode === "WORK" ? "#da1884" : "#000000"} />&nbsp;
                        <div className={activeMode === "WORK" ? styles.activeModeCaption : styles.modeCaption}>
                            Work Mode
                        </div>
                    </div>
                    <div className={styles.modeWrapper} onClick={() => setActiveMode("SUMMARY")}>
                        <MdSummarize size={20} color={activeMode === "SUMMARY" ? "#da1884" : "#000000"} />&nbsp;
                        <div className={activeMode === "SUMMARY" ? styles.activeModeCaption : styles.modeCaption}>
                            Summary
                        </div>
                    </div>
                    <div className={styles.modeWrapper} onClick={() => setActiveMode("ANALYSIS")}>
                        <MdBarChart size={20} color={activeMode === "ANALYSIS" ? "#da1884" : "#000000"} />&nbsp;
                        <div className={activeMode === "ANALYSIS" ? styles.activeModeCaption : styles.modeCaption}>
                            Analysis Mode
                        </div>
                    </div>
                    <div className={styles.modeWrapper} onClick={() => setIsPocOpen(true)}>
                        <MdPhone size={20} color={isPocOpen === true ? "#da1884" : "#000000"} />&nbsp;
                        <div className={isPocOpen === true ? styles.activeModeCaption : styles.modeCaption}>
                            Get Store POCs
                        </div>
                    </div>
                </div>

                <div className={styles.dataWrapper}>
                    {activeMode === "WORK" && <CAEJWorkMode jobIdentifier={jobIdentifier} />}
                    {activeMode === "SUMMARY" && <CAEJSummary jobIdentifier={jobIdentifier} />}
                    {activeMode === "ANALYSIS" && <CAEJAnalysisMode jobIdentifier={jobIdentifier} />}
                </div>

            </div>
        </div>
    )
}

export default CreateApplicationForEachJob