import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbEducationQualifications.module.css'

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import { BASE_URL_API } from '../../../References/urls';

const RbEducationQualifications = props => {
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    const [addedData, setAddedData] = useState([])
    const authCtx = useContext(AuthContext)
    const [fetchingAddedData, setFetchingAddedData] = useState(true)

    const [classEq, setClassEq] = useState('');
    const [passing_year, setPassing_year] = useState('')
    const [completionStatus, setCompletionStatus] = useState('');
    const [college_name, setCollege_name] = useState('');
    const [college_address, setCollege_address] = useState('');
    const [isValidated, setIsValidated] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)



    const getAddedData = async () => {
        setFetchingAddedData(true)
        const getAddedDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'educationQualification'
                })
            });

        if (!getAddedDataResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getAddedDataRespo = await getAddedDataResponse.json();
            if (getAddedDataRespo.status === 'success') {
                if(getAddedDataRespo.message==='no data found') {
                    setAddedData([]);
                } else {
                    setAddedData(getAddedDataRespo.response);
                }
            } else {
                if (getAddedDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getAddedDataRespo);
                }
            }
            console.log(getAddedDataRespo);
        }
        setFetchingAddedData(false)
    }

    useEffect(() => {
        getAddedData()
    }, [selectedUser])

    useEffect(() => {
        if (classEq.length > 0) {
            if (passing_year.length === 4) {
                if (completionStatus.length > 2) {
                    if (college_name.length > 2) {
                        if (college_address.length > 2) {
                            setIsValidated(true)
                        } else {
                            setIsValidated(false)
                        }
                    } else {
                        setIsValidated(false)
                    }
                } else {
                    setIsValidated(false)
                }
            } else {
                setIsValidated(false)
            }
        } else {
            setIsValidated(false)
        }
    }, [classEq, passing_year, completionStatus, college_name, college_address])


    const initiateSubmitHandler = async () => {
        setIsUpdating(true);
        const addEduQualiResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'addNew',
                    sectionType: 'educationQualification',
                    class: classEq,
                    passingYear: passing_year,
                    collegeName: college_name,
                    collegeAddress: college_address,
                    completionStatus: completionStatus
                })
            });

        if (!addEduQualiResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const addEduQualiRespo = await addEduQualiResponse.json();
            if (addEduQualiRespo.status === "success") {
                getAddedData();
                setClassEq('')
                setPassing_year('')
                setCollege_name('')
                setCollege_address('')
                setCompletionStatus('')
            } else {
                if (addEduQualiRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(addEduQualiRespo);
                }
            }
            console.log(addEduQualiRespo);
        }
        setIsUpdating(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType}
                </div>
                <div className={styles.bodyWrapper}>
                    {fetchingAddedData === true ?
                        <div>
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                        </div>
                        :
                        <div>
                            {addedData.length>0 ?
                                <div>
                                    {addedData.map((item, index) => (
                                        <div className={styles.eachQualificationsWrapper}
                                            key={index}
                                        >
                                            <div className={styles.classNameWrapper}
                                                title={item.class}
                                            >
                                                {/* {item.class} */}
                                                {/* {item.class.substring(0, 5)} */}
                                                {item.class.replace("Graduation", "UG").replace("Post-U", "P").replace("Year", "Y")}
                                            </div>
                                            <div className={styles.instiDetailsWrapper}>
                                                <div className={styles.institutionName}>{item.college_name}</div>
                                                <div className={styles.institutionAddress}>{item.college_address}</div>
                                                <div className={styles.institutionYear}>{item.passing_year}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div>
                                    No records found!
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.rightHeaderWrapper}>
                    Add a new Education Qualification
                </div>
                <div className={styles.rightBodyWrapper}>
                    <div className={styles.eachQuestionWrapper}>
                        <div className={styles.questionWrapper}>
                            1. Select a qualification
                        </div>
                        <div className={styles.eachAnswerWrapper}>
                            <select
                                onChange={(e) => setClassEq(e.target.value)}
                            >
                                <option>6th</option>
                                <option>7th</option>
                                <option>8th</option>
                                <option>9th</option>
                                <option>10th</option>
                                <option>11th</option>
                                <option>12th</option>
                                <option>1st Year Graduation</option>
                                <option>2nd Year Graduation</option>
                                <option>3rd Year Graduation</option>
                                <option>4th Year Graduation</option>
                                <option>5th Year Graduation</option>
                                <option>Graduation</option>
                                <option>1st Year Post-Graduation</option>
                                <option>2nd Year Post-Graduation</option>
                                <option>Post Graduation</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.eachQuestionWrapper}>
                        <div className={styles.questionWrapper}>
                            2. School / College Name
                        </div>
                        <div className={styles.eachAnswerWrapper}>
                            <input placeholder="GGS Inter College, Ghaziabad, Uttar Pradesh..."
                                onChange={(e) => setCollege_name(e.target.value)}
                                value={college_name}
                            />
                        </div>
                    </div>

                    <div className={styles.eachQuestionWrapper}>
                        <div className={styles.questionWrapper}>
                            3. School / College Address
                        </div>
                        <div className={styles.eachAnswerWrapper}>
                            <input placeholder="Thakurdwara, Ghaziabad, Uttar Pradesh."
                                onChange={(e) => setCollege_address(e.target.value)}
                                value={college_address}
                            />
                        </div>
                    </div>

                    <div className={styles.eachQuestionWrapper}>
                        <div className={styles.questionWrapper}>
                            4. Passing Year
                        </div>
                        <div className={styles.eachAnswerWrapper}>
                            <input placeholder="Enter passing out year..." type="number"
                                onChange={(e) => setPassing_year(e.target.value)}
                                value={passing_year}
                            />
                        </div>
                    </div>

                    <div className={styles.eachQuestionWrapper}>
                        <div className={styles.questionWrapper}>
                            5. Completion Status
                        </div>
                        <div className={styles.btnOptionsWrapper}>
                            <button
                                className={completionStatus === 'Pursuing' ? styles.btnOptionSelected : styles.btnOption}
                                onClick={() => setCompletionStatus('Pursuing')}
                            >
                                Pursuing
                            </button>
                            <button
                                className={completionStatus === 'Completed' ? styles.btnOptionSelected : styles.btnOption}
                                onClick={() => setCompletionStatus('Completed')}
                            >
                                Completed
                            </button>
                            <button
                                className={completionStatus === 'Dropped-Out' ? styles.btnOptionSelected : styles.btnOption}
                                onClick={() => setCompletionStatus('Dropped-Out')}
                            >
                                Dropped-Out
                            </button>
                        </div>
                    </div>




                </div>
                <div className={styles.rightFooterWrapper}>
                    {isUpdating === false ?
                        <div className={styles.rightFooterContainer}>
                            <button className={styles.secondaryBtn}>
                                Cancel
                            </button>
                            {isValidated === true ?
                                <button className={styles.primaryBtn}
                                    onClick={() => initiateSubmitHandler()}
                                >
                                    Save
                                </button>
                                :
                                <button className={styles.primaryBtnDull}>Save</button>
                            }
                        </div>
                        :
                        <div className={styles.rightFooterContainer}>
                            <p>Hold mah beer... </p>
                            <p>Let me just update this data...</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default RbEducationQualifications