import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import styles from './CAEJWMContactStatus.module.css'

const CAEJWMContactStatus = props => {
    const [isOpen, setIsOpen] = useState(false)
    const { isContactStatusActive, contactStatusToggler, excludeBeenContacted, excludeBeenContactedHandler, excludeBeenContactedJob, excludeBeenContactedJobHandler } = props

    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Contact Status
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Exclude candidates that has been contacted:
                    </div>
                    <div className={styles.filterContent}>
                        <div>
                            <div className={styles.eachMaritalStatus}
                                onClick={() => contactStatusToggler()}>
                                {isContactStatusActive === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} Exclude
                            </div>
                        </div>
                        <div className={styles.optionContainer}>
                            <div className={styles.forThisJob}>
                                <div>
                                    For
                                </div>
                                <div className={styles.jobTypeWrapper}>
                                    <select onChange={(e) => excludeBeenContactedJobHandler(e.target.value)} >
                                        <option value={'This'}>This Job</option>
                                        <option value={'Any'}>Any Job</option>
                                    </select>
                                </div>
                            </div>
                            <div className={styles.forThisJob}>
                                <div>
                                    In
                                </div>
                                <div className={styles.jobTypeWrapper}>
                                    <select onChange={(e) => excludeBeenContactedHandler(e.target.value)}>
                                        <option value={'P7D'}>last 7 days</option>
                                        <option value={'P1M'}>last 1 month</option>
                                        <option value={'P3M'}>last 3 months</option>
                                        <option value={'P6M'}>last 6 months</option>
                                        <option value={'P1Y'}>last 1 year</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMContactStatus