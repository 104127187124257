import React, { useState } from 'react'
import styles from './CAEJWMMaritalStatus.module.css'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

const CAEJWMMaritalStatus = (props) => {
    const { selectedMaritalStatus, maritalStatusToggler } = props
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Marital Status
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Include all profile with work domain at any level.
                    </div>
                    <div>
                        <div className={styles.eachMaritalStatus} onClick={() => maritalStatusToggler('MARRIED')}>
                            {selectedMaritalStatus?.includes('MARRIED') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} MARRIED
                        </div>
                        <div className={styles.eachMaritalStatus} onClick={() => maritalStatusToggler('UNMARRIED')}>
                            {selectedMaritalStatus?.includes('UNMARRIED') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} UNMARRIED
                        </div>
                        <div className={styles.eachMaritalStatus} onClick={() => maritalStatusToggler('DIVORCED')}>
                            {selectedMaritalStatus?.includes('DIVORCED') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} DIVORCED
                        </div>
                        <div className={styles.eachMaritalStatus} onClick={() => maritalStatusToggler('UNKNOWN')}>
                            {selectedMaritalStatus?.includes('UNKNOWN') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} UNKNOWN
                        </div>
                        <div className={styles.eachMaritalStatus} onClick={() => maritalStatusToggler('')}>
                            {selectedMaritalStatus?.includes('') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} Not Filled
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMMaritalStatus