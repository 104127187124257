import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ScheduleAcademyVisit.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ScheduleAcademyVisit = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, abortScheduling, leadIdentifier } = props
    const [dateTime, setDateTime] = useState('')
    const [comments, setComments] = useState('')
    const [isAdding, setIsAdding] = useState(false)
    const [historyData, setHistoryData] = useState([])
    const [isFetchingHistory, setIsFetchingHistory] = useState(false)

    const scheduleAcademyVisit = async () => {
        // console.log(dateTime, comments, leadIdentifier, authCtx.userIdentifier)
        if (dateTime !== '') {
            setIsAdding(true)
            const scheduleAcademyVisitResponse = await fetch(BASE_URL_API + "/scheduleAcademyVisit",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        executiveIdentifier: authCtx.userIdentifier,
                        dateTime: dateTime,
                        comments: comments,
                        leadIdentifier: leadIdentifier
                    })
                });

            if (!scheduleAcademyVisitResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const scheduleAcademyVisitRespo = await scheduleAcademyVisitResponse.json();
                if (scheduleAcademyVisitRespo.status === "success") {
                    toast.success(scheduleAcademyVisitRespo.message)
                    setDateTime('')
                    setComments('')
                    getScheduledVisitHistory()
                } else {
                    if (scheduleAcademyVisitRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        console.log(scheduleAcademyVisitRespo)
                    }
                }
            }
            setIsAdding(false)
        } else {
            alert("Please choose a proper time slot");
        }

    }

    const getScheduledVisitHistory = async () => {
        setIsFetchingHistory(true)
        const getScheduledVisitHistoryResponse = await fetch(BASE_URL_API + "/getScheduledVisitHistory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier
                })
            });

        if (!getScheduledVisitHistoryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getScheduledVisitHistoryRespo = await getScheduledVisitHistoryResponse.json()
            if (getScheduledVisitHistoryRespo.status === "success") {
                setHistoryData(getScheduledVisitHistoryRespo.response)
            } else {
                if (getScheduledVisitHistoryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setHistoryData([])
                }
            }
            console.log(getScheduledVisitHistoryRespo)
        }
        setIsFetchingHistory(false)
    }

    useEffect(() => {
        isOpen && getScheduledVisitHistory()
    }, [leadIdentifier, isOpen])


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainHeader}>
                    <button onClick={() => abortScheduling()} className={styles.closeBtn}>
                        Close
                    </button>
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.visitHistoryWrapper}>
                        {historyData.length > 0 ?
                            <div>
                                {isFetchingHistory===true ?
                                <div className={styles.vhwTitleLoading}>
                                    Hold on buddy! I'm looking for older visits.
                                </div>
                                :
                                <div className={styles.vhwTitle}>
                                    {historyData.length} scheduled visit(s) found!
                                </div>
                                }
                                <div className={styles.visitsWrapper}>
                                    {historyData.map((item, index) => (
                                        <div key={index} className={styles.eachVisitWrapper}>
                                            <div className={styles.visitTime}>
                                                {item?.visitTime}
                                            </div>
                                            <div className={styles.comments}>
                                                {item?.comments}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className={styles.noVisitScheduleHistory}>
                                Previously scheduled visits will appear here!
                            </div>
                        }
                    </div>
                    <div className={styles.scheduleNewVisit}>
                        <div className={styles.snvTitle}>
                            Schedule Academy Visit
                        </div>
                        <div className={styles.snvBody}>
                            <div className={styles.timePicker}>
                                <input type="datetime-local" onChange={(e) => setDateTime(e.target.value)} />
                            </div>
                            <div className={styles.commentSection}>
                                <textarea
                                    placeholder="Any remarks / comments.. "
                                    onChange={(e) => setComments(e.target.value)}
                                    value={comments}
                                ></textarea>
                            </div>
                            <div>
                                {dateTime !== '' &&
                                    (isAdding === false ?
                                        <button className={styles.scheduleit} onClick={() => scheduleAcademyVisit()}>Schedule it!</button> :
                                        <button className={styles.updatingBtn}>Updating...</button>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScheduleAcademyVisit