import React, { useContext, useEffect, useState } from 'react'
import styles from "../Styles/Content/PostJobs.module.css";
import {  FaChevronLeft, FaChevronRight } from "react-icons/fa";

import classNames from 'classnames';

import Collapsible from 'react-collapsible';
import { BASE_URL_API } from '../References/urls';

import {
    ShimmerThumbnail
} from "react-shimmer-effects";


import AuthContext from '../store/auth-context';
import StoreDetails from '../Components/Jobs/StoreDetails';
import JobDetails from '../Components/Jobs/JobDetails';
import EligibilityCriteria from '../Components/Jobs/EligibilityCriteria';
import JobBenefits from '../Components/Jobs/JobBenefits';
import JobPreview from '../Components/Jobs/JobPreview';
import MainLoader from '../Components/MainLoader';
import { useNavigate } from 'react-router';

const stepsData = [
    "Regarding Salon Details", "Regarding Job Details", "Regarding Eligibility Details", "Regarding Job Benefits Details", "Preview & Submit"
]

const PostJobs = () => {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [storeAdded, setStoreAdded] = useState(false);
    const [selectedStore, setSelectedStore] = useState("");
    const [selectedSalon, setSelectedSalon] = useState("");
    const [storesData, setStoresData] = useState([]);
    const navigate = useNavigate();
    const storesDataHandler = (items) => {
        setStoresData(items)
    }

    const [animate, setAnimate] = useState(false);
    const [step, setStep] = useState(1);

    const [jobTitle, setJobTitle] = useState("");
    const jobTitleHandler = (k) => {
        setJobTitle(k)
    }

    const [selectedJobType, setSelectedJobType] = useState("");
    const selectJobTypeHandler = (jobType) => setSelectedJobType(jobType)

    const [selectedJobCategory, setSelectedJobCategory] = useState("");
    const jobCategoryHandler = (key) => {
        key = JSON.stringify(key)
        if (selectedJobCategory == key) {
            setSelectedJobCategory("")
        } else {
            setSelectedJobCategory(key);
        }
    }

    const [numOfOpenings, setNumberOfOpenings] = useState(0);
    const numOfOpeningsHandler = (type) => {
        let p = numOfOpenings;
        type === "inc" ? p++ : p--;
        if (p < 0) {
            p = 0;
        } else if (p > 20) {
            p = 20;
        }
        setNumberOfOpenings(p);
    }


    const [jobDescription, setJobDescription] = useState('');
    const jobDescriptionHandler = (txt) => {
        setJobDescription(txt)
    }


    const selectStoreHandler = (k) => {
        setSelectedStore(k)
    }

    const selectSalonHandler = (key) => {
        setSelectedSalon(key)
    }

    const loaderHandler = (k) => {
        setIsLoading(k);
    }

    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        getLanguageOptions();
    }, [])

    const [selectedRequiredQuali, setSelectedRequiredQuali] = useState("");
    const [selectedGender, setSelectedGender] = useState("");
    const [minAge, setMinAge] = useState(14);
    const [maxAge, setMaxAge] = useState(40);
    const [fnaOffering, setFnaOffering] = useState("");
    const [requiredExperience, setRequiredExperience] = useState();

    const [selectedLanguage, setSelectedLanguage] = useState([]);

    const [selectedSkills, setSelectedSkills] = useState([]);
    const skillSetHandler = (key) => {
        key = JSON.stringify(key);
        if (selectedSkills.includes(key)) {
            setSelectedSkills(selectedSkills.filter((item) => item !== key));
        } else {
            setSelectedSkills([...selectedSkills, key]);
        }
    }

    const [minSalary, setMinSalary] = useState("");
    const [maxSalary, setMaxSalary] = useState("");
    const [incentive, setIncentive] = useState("");

    const moveToNextHandler = () => {
        setAnimate(true);
        setStep(step < stepsData.length ? step + 1 : 1);
        // setAnimate(false)
    }

    const moveToPrevHandler = () => {
        setAnimate(!true);
        setStep(step > 1 ? step - 1 : stepsData.length);
    }

    const jumpToStepHandler = (step) => setStep(step)

    const selectRequiredQualificationHandler = (key) => setSelectedRequiredQuali(key)
    const selectGenderHandler = (key) => setSelectedGender(key)

    const updateAgeHandler = (type, action) => {
        if (type === "minAge") {
            let p = minAge;
            if (action === "inc") {
                if (p >= maxAge) {
                    setMinAge(p);
                } else {
                    p++;
                    setMinAge(p > 60 ? 60 : p);
                }
            } else {
                p--;
                setMinAge(p < 14 ? 14 : p);
            }
        } else {
            let q = maxAge;
            if (action === "inc") {
                q++;
                setMaxAge(q > 60 ? 60 : q);
            } else {
                if (q <= minAge) {
                    setMaxAge(q);
                } else {
                    q--;
                    setMaxAge(q < 14 ? 14 : q);
                }
            }
        }

    }

    const fnaHandler = (key) => setFnaOffering(key)
    const experienceUpdateHandler = (key) => setRequiredExperience(key);


    const languageChooseHandler = (key) => {
        if (selectedLanguage.includes(key)) {
            setSelectedLanguage(selectedLanguage.filter((item) => item !== key));
        } else {
            setSelectedLanguage([...selectedLanguage, key]);
        }
    }


    const getLanguageOptions = async () => {
        const getLanguageOptionsResponse = await fetch(BASE_URL_API + "/getLanguageOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getLanguageOptionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getLanguageOptionsRespo = await getLanguageOptionsResponse.json();
            if (getLanguageOptionsRespo.status === "success") {
                setLanguageOptions(getLanguageOptionsRespo.response);
                console.log(getLanguageOptionsRespo);
            } else {
                if(getLanguageOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(getLanguageOptionsRespo);
                }
            }
        }
    }


    const updateSalaryHandler = (t, k) => {
        if (t === "minSalary") {
            if (minSalary > maxSalary) {
                // alert("Min salary cannot be higher than max salary");
                setMinSalary(k);
            } else {
                setMinSalary(k);
            }
        } else {
            if (maxSalary < minSalary) {
                // alert("Max salary cannot be lesser than min salary");
                // setMaxSalary(minSalary+20000)
                setMaxSalary(k)
            } else {
                setMaxSalary(k)
            }
        }
    }

    const incentiveUpdateHandler = (k) => {
        setIncentive(k);
    }

    const postNewJob = async () => {
        setIsLoading(true);
        let skillSetArr = [];
        let selectedSkillsArr = [];
        selectedSkills.map((item, index) => {
            skillSetArr.push(JSON.parse(item).ID);
            selectedSkillsArr.push(JSON.parse(item).skill);
        })

        console.log("this is skillset arr for IDs", skillSetArr)
        console.log("this is skillset arr for name", selectedSkillsArr)

        const postNewJobResponse = await fetch(BASE_URL_API + "/postNewJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    selectedSalon: selectedSalon,
                    jobTitle: jobTitle,
                    selectedJobType: selectedJobType,
                    selectedStore: selectedStore,
                    selectedJobCategory: JSON.parse(selectedJobCategory).ID,
                    selectedJobCategoryName: JSON.parse(selectedJobCategory).category_name,
                    skillSetId: skillSetArr,
                    skill: selectedSkillsArr,
                    experience: requiredExperience,
                    minSalary: minSalary,
                    maxSalary: maxSalary,
                    gender: selectedGender,
                    educationQualifications: selectedRequiredQuali,
                    numOfOpenings: numOfOpenings,
                    incentive: incentive,
                    fnaOffering: fnaOffering,
                    language: selectedLanguage,
                    minAge: minAge,
                    maxAge: maxAge,
                    description: jobDescription,
                    executiveIdentifier : authCtx.userIdentifier
                })
            })

        if (!postNewJobResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const postNewJobRespo = await postNewJobResponse.json();
            if(postNewJobRespo.status=="success") {
                console.log("I have successfully posted the job...")
                navigate("/manage-jobs")
            } else {
                alert(postNewJobRespo.response);
                console.log(postNewJobRespo.response);
            }
            console.log(postNewJobRespo);
        }

        setIsLoading(false);
    }

    const validateEntryFields = () => {
        if (selectedSalon === "" || selectedStore === "") {
            setStep(1);
        } else if (jobTitle === "" || selectedJobType === "" || numOfOpenings === "" || jobDescription === "" || selectedJobCategory === "" || selectedSkills.length < 1) {
            setStep(2);
        } else if (selectedRequiredQuali === "" || requiredExperience === "" || selectedGender === "" || minAge === "" || maxAge === "" || selectedLanguage.length < 1) {
            setStep(3)
        } else if (minSalary === "" || maxSalary === "" || incentive === "" || fnaOffering === "") {
            setStep(4)
        } else {
            postNewJob();
            console.log("All fine, I'm posting job now...")
        }
    }




    return (
        <div className={styles.wrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.sectionContainer}>
                        <div className={styles.stepCountSection}>
                            Step - {step}
                        </div>
                        <div className={styles.stepTitleSection}>
                            Fill the basic details about the job
                        </div>
                        <div className={styles.stepTimelineSection}>
                            {stepsData.map((item, index) => (
                                <div className={step === index + 1 ? styles.eachTimelineStep : styles.eachTimelineStepDull} onClick={() => jumpToStepHandler(index + 1)} key={"P" + index + "K"}>
                                    <div className={step === index + 1 ? styles.timelineStepCount : styles.timelineStepCountDull} >
                                        {index + 1}
                                    </div>
                                    <div className={styles.timelineStepTitle}>
                                        {item}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    {step === 1 &&
                        <StoreDetails
                            loaderHandler={loaderHandler}
                            selectedSalon={selectedSalon}
                            selectSalonHandler={selectSalonHandler}
                            selectedStore={selectedStore}
                            selectStoreHandler={selectStoreHandler}
                            storesData={storesData}
                            storesDataHandler={storesDataHandler}
                        />
                    }
                    {step === 2 &&
                        <JobDetails
                            jobTitle={jobTitle}
                            setJobTitle={jobTitleHandler}
                            selectedJobType={selectedJobType}
                            selectJobTypeHandler={selectJobTypeHandler}
                            selectedJobCategory={selectedJobCategory}
                            jobCategoryHandler={jobCategoryHandler}
                            selectedSkills={selectedSkills}
                            skillSetHandler={skillSetHandler}
                            numOfOpenings={numOfOpenings}
                            numOfOpeningsHandler={numOfOpeningsHandler}
                            jobDescription={jobDescription}
                            jobDescriptionHandler={jobDescriptionHandler}
                        />
                    }
                    {step === 3 &&
                        <EligibilityCriteria
                            selectedRequiredQuali={selectedRequiredQuali}
                            selectRequiredQualificationHandler={selectRequiredQualificationHandler}
                            requiredExperience={requiredExperience}
                            experienceUpdateHandler={experienceUpdateHandler}
                            selectedGender={selectedGender}
                            selectGenderHandler={selectGenderHandler}
                            updateAgeHandler={updateAgeHandler}
                            minAge={minAge}
                            maxAge={maxAge}
                            languageOptions={languageOptions}
                            selectedLanguage={selectedLanguage}
                            languageChooseHandler={languageChooseHandler}
                        />
                    }
                    {step === 4 &&
                        <JobBenefits
                            updateSalaryHandler={updateSalaryHandler}
                            minSalary={minSalary}
                            maxSalary={maxSalary}
                            incentive={incentive}
                            incentiveUpdateHandler={incentiveUpdateHandler}
                            fnaOffering={fnaOffering}
                            fnaHandler={fnaHandler}
                        />
                    }
                    {step === 5 &&
                        <JobPreview
                            jobTitle={jobTitle}
                            selectedSkills={selectedSkills}
                            selectedJobType={selectedJobType}
                            numOfOpenings={numOfOpenings}
                            jobDescription={jobDescription}
                            selectedRequiredQuali={selectedRequiredQuali}
                            requiredExperience={requiredExperience}
                            selectedGender={selectedGender}
                            minAge={minAge}
                            maxAge={maxAge}
                            selectedLanguage={selectedLanguage}
                            minSalary={minSalary}
                            maxSalary={maxSalary}
                            incentive={incentive}
                            fnaOffering={fnaOffering}
                            validateEntryFields={validateEntryFields}
                        />
                    }

                    <div className={styles.rcNavigationContainer}>
                        <button className={styles.moveToPrevBtn} onClick={() => moveToPrevHandler()}>
                            <FaChevronLeft />
                        </button>
                        <button className={styles.moveToNextBtn} onClick={() => moveToNextHandler()}>
                            <FaChevronRight />
                        </button>
                    </div>
                </div>
            </div>
            {isLoading &&
                <MainLoader />}
        </div >
    )
}

export default PostJobs