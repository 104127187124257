import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../References/urls';
import AuthContext from '../store/auth-context';
import styles from '../Styles/Content/ManageSkillSets.module.css'

import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { listItemSecondaryActionClasses } from '@mui/material';
import MainLoader from '../Components/MainLoader';
import AddNewSkill from '../Components/AddNewSkill';

const ManageSkillSets = props => {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const [skillsFor, setSkillsFor] = useState('female')
    const [skillsForMale, setSkillsForMale] = useState(null);
    const [skillsForFemale, setSkillsForFemale] = useState(null);
    const [skillsForNeutral, setSkillsForNeutral] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState(null);

    const [isVisible, setIsVisible] = useState(false)
    const isVisibleHandler = (t) => {
        setIsVisible(t);
    }
    const isLoadingHandler = (t) => {
        setIsLoading(t);
    }

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status == "success") {
                let p = getCategoryOptionsRespo.response;
                p.push({
                    "id": "None",
                    "category_name": "",
                    "isActive": 1,
                    "addedOn": ''
                })
                // setCategoryOptions(getCategoryOptionsRespo.response)
                setCategoryOptions(p)
            } else {
                if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getCategoryOptionsRespo);
                }
            }
        }
    }

    const getSkillsOptions = async () => {
        setIsLoading(true)
        const getSkillsOptionsResponse = await fetch(BASE_URL_API + "/getSkillsOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getSkillsOptionsResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const getSkillsOptionsRespo = await getSkillsOptionsResponse.json();
            if (getSkillsOptionsRespo.status == "success") {
                setSkillsForFemale(getSkillsOptionsRespo.response.forFemale);
                setSkillsForMale(getSkillsOptionsRespo.response.forMale);
                setSkillsForNeutral(getSkillsOptionsRespo.response.unsorted);
            } else {
                if (getSkillsOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getSkillsOptionsRespo);
                }
            }
            console.log(getSkillsOptionsRespo)
        }
        setIsLoading(false)
    }

    const updateSkillCategory = async (skillId, categoryId, category) => {
        setIsLoading(true);
        const updateSkillCategoryResponse = await fetch(BASE_URL_API + "/updateSkillCategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    skillId: skillId,
                    categoryId: categoryId,
                    category: category
                })
            });

        if (!updateSkillCategoryResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const updateSkillCategoryRespo = await updateSkillCategoryResponse.json();
            if (updateSkillCategoryRespo.status == "success") {
                getSkillsOptions();
            } else {

            }
            console.log(updateSkillCategoryRespo)
        }
        setIsLoading(false);

    }

    const updateSkillCategoryHandler = async (s, c) => {
        // console.log(s, c.ID, c.category_name)
        updateSkillCategory(s, c.ID, c.category_name);
    }

    const updateSkillFor = async (i, f) => {
        const updateSkillForResponse = await fetch(BASE_URL_API + "/updateSkillFor",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    ID: i,
                    for_gender: f
                })
            })

        if (!updateSkillForResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const updateSkillForRespo = await updateSkillForResponse.json();
            if (updateSkillForRespo.status == "success") {
                getSkillsOptions();
            } else {
                console.log(updateSkillForRespo)
            }
        }
    }

    const skillForUpdateHandler = async (i, f) => {
        updateSkillFor(i, f)
    }

    useEffect(() => {
        console.log("I'm fetching category options");
        getCategoryOptions();
    }, [])

    useEffect(() => {
        if (categoryOptions != null) {
            console.log("I am fetching skills!");
            getSkillsOptions();
        } else {
            console.log("I am not fetching skills!");
        }
    }, [categoryOptions])


    return (
        <div className={styles.mainContainer}>
            {isLoading &&
                <MainLoader />}

            <div className={styles.headerContainer}>
                <div>
                    Skill Sets Manager
                </div>
            </div>
            <AddNewSkill 
                setIsLoading={setIsLoading}
                isVisible={isVisible} 
                isVisibleHandler={isVisibleHandler} 
                categoryOptions={categoryOptions} 
                getCategoryOptions={getCategoryOptions}
            />
            <div className={styles.mainBodyContainer}>
                <div className={styles.actionContainer}>
                    <div>
                        <button
                            onClick={() => setSkillsFor('generic')}
                            className={skillsFor === "generic" ? styles.selectedSkillFor : styles.skillFor}
                        >
                            Generic Skills
                        </button>
                        <button
                            onClick={() => setSkillsFor('female')}
                            className={skillsFor === "female" ? styles.selectedSkillFor : styles.skillFor}
                        >
                            For Females Only
                        </button>
                        <button
                            onClick={() => setSkillsFor('male')}
                            className={skillsFor === "male" ? styles.selectedSkillFor : styles.skillFor}
                        >
                            For Males Only
                        </button>
                    </div>
                    <div>
                        <button onClick={() => setIsVisible(true)}>Add New Skill</button>
                    </div>
                </div>

                {categoryOptions != null &&
                    <>

                        {skillsFor == "generic" &&
                            <>
                                {/* {categoryOptions.map((item, index) => (

                    ))} */}
                                <div className={styles.tableContainer}>

                                    <Collapsible trigger={
                                        <div className={styles.eachFieldHeaderContainer}>
                                            <div className={styles.eachFieldHeaderTitle}>
                                                Generic <FaChevronDown />
                                            </div>
                                        </div>}
                                    >
                                        {skillsForNeutral !== null ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Skill</th>
                                                        <th>Skill For</th>
                                                        <th>Category</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {skillsForNeutral.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.skill}</td>
                                                            <td>
                                                                <select
                                                                    onChange={(e) => skillForUpdateHandler(item.ID, e.target.value)}
                                                                >
                                                                    <option value={0}>Select Gender</option>
                                                                    <option value={2}>Female</option>
                                                                    <option value={1}>Male</option>
                                                                </select>
                                                            </td>
                                                            <td>{item.category}</td>
                                                            <td>
                                                                Edit
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            :
                                            <div className={styles.noSkillsFound}>
                                                <center>No generic skills found!</center>
                                            </div>
                                        }
                                    </Collapsible>


                                </div>
                            </>
                        }

                        {skillsFor == "female" &&
                            <>
                                {categoryOptions.map((itemCat, indexCat) => (
                                    <Collapsible trigger={
                                        <div className={styles.eachFieldHeaderContainer}>
                                            <div className={styles.eachFieldHeaderTitle}>
                                                {itemCat.category_name !== "" ? itemCat.category_name : 'Un-Assigned'}  <FaChevronDown />
                                            </div>
                                        </div>}
                                    >
                                        <div className={styles.tableContainer} key={"AA" + indexCat}>
                                            {skillsForFemale !== null ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Skill</th>
                                                            <th>Skill For</th>
                                                            <th>Category</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {skillsForFemale.map((item, index) => (
                                                            item.category === itemCat.category_name &&
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.skill}</td>
                                                                <td>Female</td>
                                                                <td>
                                                                    <select
                                                                        onChange={(e) => updateSkillCategoryHandler(item.ID, JSON.parse(e.target.value))}
                                                                    >
                                                                        {categoryOptions.map((iC, inC) => (
                                                                            <option
                                                                                value={JSON.stringify(iC)}
                                                                                selected={iC.category_name == item.category && 'selected'}
                                                                            >
                                                                                {iC.category_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    Edit
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                :
                                                <div className={styles.noSkillsFound}>
                                                    <center>No skills found for female!</center>
                                                </div>
                                            }
                                        </div>
                                    </Collapsible>
                                ))}
                            </>

                        }

                        {skillsFor == "male" &&
                            <>
                                {categoryOptions.map((itemCat, indexCat) => (
                                    <Collapsible trigger={
                                        <div className={styles.eachFieldHeaderContainer}>
                                            <div className={styles.eachFieldHeaderTitle}>
                                                {itemCat.category_name !== "" ? itemCat.category_name : 'Un-Assigned'} <FaChevronDown />
                                            </div>
                                        </div>}
                                    >
                                        <div className={styles.tableContainer}>
                                            {skillsForFemale !== null ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Skill</th>
                                                            <th>Skill For</th>
                                                            <th>Category</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {skillsForMale.map((item, index) => (
                                                            item.category == itemCat.category_name &&
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.skill}</td>
                                                                <td>Male</td>
                                                                <td>
                                                                    <select
                                                                        // onChange={(e) => updateSkillCategory(item.ID, e.target.value.ID, e.target.value.category_name )}
                                                                        onChange={(e) => updateSkillCategoryHandler(item.ID, JSON.parse(e.target.value))}
                                                                    >
                                                                        {categoryOptions.map((iC, inC) => (
                                                                            <option
                                                                                value={JSON.stringify(iC)}
                                                                                categoryname={iC.category_name}
                                                                                selected={iC.category_name == item.category && 'selected'}
                                                                            >
                                                                                {iC.category_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    Edit
                                                                </td>
                                                            </tr>

                                                        )
                                                        )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div className={styles.noSkillsFound}>
                                                    <center>No skills found for male!</center>
                                                </div>
                                            }
                                        </div>
                                    </Collapsible>
                                ))}
                            </>
                        }

                    </>
                }
            </div>
        </div>
    )
}

export default ManageSkillSets