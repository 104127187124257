import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Partners/ManageStores.module.css'
import ManageStoreEachDetails from './ManageStoreEachDetails'
import ManageStoreHeader from './ManageStoreHeader'

const ManageStores = () => {
    const authCtx = useContext(AuthContext)
    const [storesList, setStoresList] = useState([])
    const storeListHandler = (k) => setStoresList(k)

    return (
        <div className={styles.mainWrapper}>
            <ManageStoreHeader 
                storeListHandler={storeListHandler}
                storesList={storesList}
            />
            <div className={styles.bodyWrapper}>
                <div className={styles.leftColWrapper}>
                    {storesList.map((item, index) => (
                        <Link to={"/manage-stores/" + item.store_id} key={index}>
                            <div className={styles.eachStoreWrapper}>
                                <div>
                                    <div>
                                        {item.company_name}
                                    </div>
                                    <div className={styles.address}>
                                        {item.address}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className={styles.rightColWrapper}>
                    Please select a store to start!..
                </div>
            </div>
        </div>
    )
}

export default ManageStores