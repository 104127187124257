import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context';
import styles from '../ManageDemographics/DgStates.module.css'

const DgStates = props => {
    const authCtx = useContext(AuthContext);
    let selectedCountry = props.selectedCountry;
    let selectedState = props.selectedState;
    const selectStateHandler = props.selectStateHandler;


    const [isFetchingStatesData, setIsFetchingStatesData] = useState(true)
    const [statesData, setStatesData] = useState([]);

    const [state, setState] = useState('');

    useEffect(() => {
        if(selectedCountry!=null) {
            dgGetStates();
        }
    }, [selectedCountry]);

    
    const dgGetStates = async () => {
        setIsFetchingStatesData(true);
        const dgGetStatesResponse = await fetch(BASE_URL_API + "/dgGetStates",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    countryIdentifier: selectedCountry.identifier
                })
            });

        if (!dgGetStatesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetStatesRespo = await dgGetStatesResponse.json();
            if (dgGetStatesRespo.status === 'success') {
                setStatesData(dgGetStatesRespo.response);
            } else {
                if (dgGetStatesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setStatesData([]);
                    console.log("Get state response : ",dgGetStatesRespo);
                }
            }
        }
        setIsFetchingStatesData(false)
    }

    const dgAddStates = async () => {
        setIsFetchingStatesData(true);
        const dgAddStatesResponse = await fetch(BASE_URL_API + "/dgAddStates",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    countryIdentifier: selectedCountry.identifier,
                    state: state
                })
            });

        if (!dgAddStatesResponse.ok) {
            console.log("Something went wrong: server Error!");
        } else {
            const dgAddStatesRespo = await dgAddStatesResponse.json();
            if (dgAddStatesRespo.status === 'success') {
                dgGetStates()
                setState('')
            } else {
                if (dgAddStatesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log("Add state response", dgAddStatesRespo);
                }
            }
        }
        setIsFetchingStatesData(false);
    }


    return (
        <div className={styles.mainWrapper}>
            {selectedCountry != null ?
                <div>
                    {isFetchingStatesData === false ?
                        statesData.length > 0 ?
                            <div>
                                <div className={styles.header}>
                                    States
                                </div>
                                <div className={styles.body}>
                                    {statesData.map((item, index) => (
                                        <div 
                                            className={selectedState===item ? styles.eachCardWrapperSelected : styles.eachCardWrapper}
                                            key={index}
                                            onClick={() => selectStateHandler(item)}
                                        >
                                            {item.state}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            onChange={(e) => setState(e.target.value)}
                                            value={state}
                                            placeholder="Please enter state name..."
                                        />
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <button
                                            onClick={() => dgAddStates()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={styles.header}>
                                    States
                                </div>
                                <div className={styles.body}>
                                    No state has been added for this country!
                                </div>
                                <div className={styles.footer}>
                                    <div>
                                        <input
                                            onChange={(e) => setState(e.target.value)}
                                            value={state}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => dgAddStates()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        :
                        <div>
                            <div className={styles.header}>
                                States
                            </div>
                            <div className={styles.body}>
                                Fetching states data ...
                            </div>
                            <div className={styles.footer}>
                            </div>
                        </div>
                    }
                </div>
                :
                <div>
                    <div className={styles.header}>
                        States
                    </div>
                    <div className={styles.body}>
                        Please select a country!
                    </div>
                    <div className={styles.footer}>
                    </div>
                </div>
            }
        </div>
    )
}

export default DgStates