import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './JTFilters.module.css'

const JTFilters = (props) => {
    const { isOpen, abort, applyFilters,
        categoryData, selectedCategory, selectCategoryHandler,
        openingStatus, openingStatusHandler,
        paidHiringStatus, togglePaidHiringStatus,
        isFetchingJobs
    } = props
    const authCtx = useContext(AuthContext)


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainContentHeader}>
                            <button onClick={() => abort()} >Close</button>
                            {isFetchingJobs === true ?
                                <button >Loading, please wait...</button>
                                :
                                <button onClick={() => applyFilters()}>Apply Filters</button>
                            }
                        </div>
                        <div>
                            <div>
                                <div>Current Status</div>
                                <div className={styles.currentStatusWrapper}>
                                    <button className={openingStatus === "ALL" ? styles.eachCategoryOptionSelected : styles.eachCategoryOption} onClick={() => openingStatusHandler('ALL')}  >All</button>
                                    <button className={openingStatus === "OPEN" ? styles.eachCategoryOptionSelected : styles.eachCategoryOption} onClick={() => openingStatusHandler('OPEN')}  >Open</button>
                                    <button className={openingStatus === "CLOSE" ? styles.eachCategoryOptionSelected : styles.eachCategoryOption} onClick={() => openingStatusHandler('CLOSE')}  >Closed</button>
                                </div>
                            </div>
                            <div className={styles.payingStatusWrapper} >
                                <div>Paying Status</div>
                                <div className={styles.payingStatusContainer}>
                                    <button className={paidHiringStatus === true ? styles.eachCategoryOptionSelected : styles.eachCategoryOption} onClick={() => togglePaidHiringStatus(true)} >Paid</button>
                                    <button className={paidHiringStatus === false ? styles.eachCategoryOptionSelected : styles.eachCategoryOption} onClick={() => togglePaidHiringStatus(false)} >Unpaid</button>
                                </div>
                            </div>
                            <div>
                                <div>
                                    Profiles
                                </div>
                                <div className={styles.profilesData}>
                                    {categoryData?.map((item, index) => (
                                        <div key={index} value={item?.ID}
                                            className={selectedCategory.includes(item?.ID) === true ? styles.eachCategoryOptionSelected : styles.eachCategoryOption}
                                            onClick={() => selectCategoryHandler(item?.ID)}
                                        >
                                            {item?.category_name}
                                        </div>
                                    ))}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JTFilters