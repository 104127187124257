import React, { useContext, useEffect, useState } from 'react'
import { FaList, FaLocationArrow } from 'react-icons/fa'
import { useParams } from 'react-router'
import FTCreateAcademicLocations from '../../Components/FastTrack/FTCreateAcademicLocations'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTEachPartner.module.css'

const FTEachPartner = () => {
    const { partnersIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('Details') // Locations

    const [isAddingNewLocation, setIsAddingNewLocation] = useState(false)
    const [locationsData, setLocationsData] = useState([])

    const ftgetAllPartnersLocation = async () => {
        const ftgetAllPartnersLocationResponse = await fetch(BASE_URL_API + "/ftgetAllPartnersLocation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    partnersIdentifier: partnersIdentifier
                })
            });

        if (!ftgetAllPartnersLocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const ftgetAllPartnersLocationRespo = await ftgetAllPartnersLocationResponse.json()
            if (ftgetAllPartnersLocationRespo.status === "success") {
                setLocationsData(ftgetAllPartnersLocationRespo.response)
            } else {
                if (ftgetAllPartnersLocationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setLocationsData([])
                }
            }
            console.log(ftgetAllPartnersLocationRespo)
        }
    }

    useEffect(() => {
        ftgetAllPartnersLocation()
    }, [partnersIdentifier])

    return (
        <div className={styles.mainWrapper}>
            {isAddingNewLocation && <FTCreateAcademicLocations
                isOpen={isAddingNewLocation}
                onClose={() => setIsAddingNewLocation(false)}
                partnersIdentifier={partnersIdentifier}
            />}
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.headerWrapper}>
                        <div className={styles.eachActionTab} onClick={() => setActiveTab('Details')}>
                            <FaList size={22} />
                        </div>
                        <div onClick={() => setActiveTab('Locations')}>
                            <FaLocationArrow size={22} />
                        </div>
                    </div>
                </div>
                <div className={styles.rightColWrapper}>
                    <div className={styles.pdetailWrapper}>
                        {partnersIdentifier}
                    <button onClick={() => setIsAddingNewLocation(true)}>Add Location</button>
                    </div>
                    {activeTab === "Locations" &&
                        <div>
                            <div>
                                {locationsData?.map((item, index) => (
                                    <div key={index} className={styles.eachLocationRow}>
                                        <div className={styles.locationTitle}>
                                            {item?.locationTitle}
                                        </div>
                                        <div className={styles.locationAddress}>
                                            {item?.locationAddress}
                                        </div>
                                        <div className={styles.locationIdentifier}>
                                            {item?.locationIdentifier}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FTEachPartner