import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTCreateAPlacementDrive.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FTCreateAPlacementDrive = (props) => {
    const { isOpen, onClose } = props
    const authCtx = useContext(AuthContext)
    const [partnersIdentifier, setPartnersIdentifier] = useState('')
    const [locationsData, setLocationsData] = useState([])
    const [locationIdentifier, setLocationIdentifier] = useState('')
    const [driveType, setDriveType] = useState('')
    const [driveStartingDate, setDriveStartingDate] = useState('')
    const [driveEndDate, setDriveEndDate] = useState('')
    const [isCreatingNow, setIsCreatingNow] = useState(false)

    const ftgetAllPartnersLocation = async () => {
        const ftgetAllPartnersLocationResponse = await fetch(BASE_URL_API+"/ftgetAllPartnersLocation",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                partnersIdentifier : partnersIdentifier
            })
        });

        if(!ftgetAllPartnersLocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const ftgetAllPartnersLocationRespo = await ftgetAllPartnersLocationResponse.json()
            if(ftgetAllPartnersLocationRespo.status==="success") {
                setLocationsData(ftgetAllPartnersLocationRespo.response)
            } else {
                if(ftgetAllPartnersLocationRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setLocationsData([])
                }
            }
            console.log(ftgetAllPartnersLocationRespo)
        }
    }

    useEffect(() => {
        ftgetAllPartnersLocation()
    }, [partnersIdentifier])
    

    const ftcreateAPlacementDrive = async () => {
        console.log(partnersIdentifier, locationIdentifier, driveType, driveStartingDate, driveEndDate)
        setIsCreatingNow(true)
        const ftcreateAPlacementDriveResponse = await fetch(BASE_URL_API+"/ftcreateAPlacementDrive",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token, 
                partnersIdentifier : partnersIdentifier, 
                locationIdentifier : locationIdentifier,
                driveType : driveType,
                driveStartingDate : driveStartingDate,
                driveEndDate : driveEndDate
            })
        });

        if(!ftcreateAPlacementDriveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const ftcreateAPlacementDriveRespo = await ftcreateAPlacementDriveResponse.json()
            if(ftcreateAPlacementDriveRespo.status==="success") {
                toast("Succesfully created the drive")
            } else {
                if(ftcreateAPlacementDriveRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(ftcreateAPlacementDriveRespo?.response)
                }
            }
            console.log(ftcreateAPlacementDriveRespo)
        }
        setIsCreatingNow(false)
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <button onClick={() => onClose()}>Close</button>
                            <div>
                                CreateAPlacementDrive
                            </div>
                        </div>
                        <div className={styles.formWrapper}>
                            <div className={styles.eachOptionWrapper}>
                                <select onChange={(e) => setPartnersIdentifier(e.target.value)}>
                                    <option value={``}>Select Partner</option>
                                    <option value={`FTP1722844792VLCCN`}>VLCC</option>
                                </select>
                            </div>
                            <div className={styles.eachOptionWrapper}>
                                <select onChange={(e) => setLocationIdentifier(e.target.value)}>
                                    <option>Select Location</option>
                                    {locationsData?.map((item, index) => (
                                        <option key={index} value={item?.locationIdentifier}>
                                            {item?.locationTitle}
                                            {item?.locationAddress}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.eachOptionWrapper}>
                                <div>
                                    <select onChange={(e) => setDriveType(e.target.value)}>
                                        <option value={``}>Select Drive Type</option>
                                        <option value={`Physical`}>Physical</option>
                                        <option value={`Online`}>Online</option>
                                    </select>
                                </div>
                            </div>

                            <div className={styles.eachOptionWrapper}>
                                <div className={styles.eachOptionContainer}>
                                    <div className={styles.eachOption}>
                                        <div>
                                            Please enter drive start date
                                        </div>
                                        <input type="date" value={driveStartingDate} onChange={(e) => setDriveStartingDate(e.target.value)} />
                                    </div>
                                    <div className={styles.eachOption}>
                                        <div>
                                            Please enter drive end date
                                        </div>
                                        <input type="date" value={driveEndDate} onChange={(e) => setDriveEndDate(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachOptionWrapper}>
                                {isCreatingNow===false ?
                                <div className={styles.eachOptionContainer}>
                                    <button onClick={() => ftcreateAPlacementDrive()} >Submit</button>
                                </div>
                                :
                                <div className={styles.eachOptionContainer}>
                                    <button >Creating now...</button>
                                </div>
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FTCreateAPlacementDrive