import { ChevronLeft } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SpaEachSuggestedResult from '../../Components/ManagePartnersAccounts/SpaEachSuggestedResult'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SearchPartnersAccount.module.css'

const SearchPartnersAccount = () => {
    const authCtx = useContext(AuthContext)
    const [searchTerm, setSearchTerm] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [results, setResults] = useState([])

    const searchTermHandler = (k) => {
        setSearchTerm(k)
        if (k.length > 0) {
            setIsSearching(true)
        } else {
            setIsSearching(false)
        }
    }

    const searchPartnersAccount = async () => {
        setIsSearching(true)
        const searchPartnersAccountResponse = await fetch(BASE_URL_API + "/searchPartnersAccount",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    searchTerm: searchTerm
                })
            });

        if (!searchPartnersAccountResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const searchPartnersAccountRespo = await searchPartnersAccountResponse.json();
            if (searchPartnersAccountRespo.status === "success") {
                if (searchPartnersAccountRespo.message === "no records found") {
                    setResults([])
                } else {
                    setResults(searchPartnersAccountRespo.response)
                }
            } else {
                if (searchPartnersAccountRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setResults([])
                }
            }

            console.log(searchPartnersAccountRespo)
        }
        setIsSearching(false)
    }


    useEffect(() => {
        if (searchTerm.length > 2) searchPartnersAccount()
    }, [searchTerm])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.goToBack}>
                    <Link to="/"><ChevronLeft /></Link>
                </div>
                <div className={styles.headerTitle}>
                    Create A New Account For Salon
                </div>
                <div className={styles.rightTitle}>

                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.searchBoxWrapper}>
                    <input
                        autoFocus={true}
                        placeholder="Search for a salon..."
                        className={isSearching === true ? styles.searchingBox : styles.searchBox}
                        onChange={(e) => searchTermHandler(e.target.value)}
                    />
                    {isSearching &&
                        showResults === false ?
                        <div className={styles.searchingWrapper}>
                            Searching...
                        </div>
                        :
                        <div>
                            <div className={styles.resultsCount}>
                                Showing {results?.length} result(s)...
                            </div>
                            <div className={styles.resultsWrapper}>
                                {results?.map((item, index) => (
                                    <SpaEachSuggestedResult key={index} item={item} />
                                ))}
                            </div>
                            <div className={styles.createNewBtn}>
                                <Link to="/manage-partners-account/create-new-account">Create New One!</Link>
                            </div>

                        </div>
                    }
                </div>



            </div>
        </div >
    )
}

export default SearchPartnersAccount