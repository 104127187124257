import React, { useContext, useEffect, useState } from 'react'
import { BiCheckSquare } from 'react-icons/bi'
import { BsCheckSquare, BsCheckSquareFill, BsSquare } from 'react-icons/bs'
import { FaArrowLeft, FaBackward, FaCheckCircle, FaCheckSquare } from 'react-icons/fa'
import { MdCheckBox } from 'react-icons/md'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SalonLeadsFeeder.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SalonLeadsFeeder = () => {
    const authCtx = useContext(AuthContext)
    const [phone, setPhone] = useState('')
    const [isCollected, setIsCollected] = useState(false)
    const [recentLeads, setRecentLeads] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [selectedLocations, setSelectedLocations] = useState([])
    const [recentLeadCollectingStatus, setRecentLeadCollectingStatus] = useState('NOT_COLLECTING') // COLLECTING, COLLECTED
    const [newLeadCreationInitiated, setNewLeadCreationInitiated] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [isCreatingLead, setIsCreatingLead] = useState(false)

    const phoneEnteredHandler = (k) => {
        setPhone(k)
        if (k.length === 10) {
            setIsCollected(true)
        } else {
            setIsCollected(false)
        }
    }


    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setCategories(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCategories([])
                }
            }
            console.log(getCategoryOptionsRespo)
        }
    }

    const lmGetAllLocations = async () => {
        const getAllLocationsResponse = await fetch(BASE_URL_API + "/lmGetAllLocations",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllLocationsRespo = await getAllLocationsResponse.json();
            if (getAllLocationsRespo.status === 'success') {
                setAllLocations(getAllLocationsRespo.response)
            } else {
                if (getAllLocationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllLocations([])
                }
            }
            console.log(getAllLocationsRespo)
        }
    }


    useEffect(() => {
        getCategoryOptions().then(() => lmGetAllLocations())
    }, [])

    const selectProfileCategoryHandler = k => {
        setSelectedCategories(selectedCategories.includes(k) ? selectedCategories.filter((item) => item.ID !== k.ID) : [...selectedCategories, k])
    }

    const selectLocationsHandler = k => {
        setSelectedLocations(selectedLocations.includes(k) ? selectedLocations.filter((item) => item.ID !== k.ID) : [...selectedLocations, k])
    }

    const createLeadHandler = () => {
        lmsCreateALead()
        console.log(selectedCategories, selectedLocations, authCtx.userIdentifier)
    }

    const lmsGetRecentLeads = async () => {
        setRecentLeadCollectingStatus('COLLECTING')
        const lmsGetRecentLeadsResponse = await fetch(BASE_URL_API + "/lmsGetRecentLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    phone: phone
                })
            });

        if (!lmsGetRecentLeadsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const lmsGetRecentLeadsRespo = await lmsGetRecentLeadsResponse.json()
            if (lmsGetRecentLeadsRespo.status === 'success') {
                console.log(lmsGetRecentLeadsRespo)
                setRecentLeads(lmsGetRecentLeadsRespo.response)
            } else {
                if (lmsGetRecentLeadsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else if (lmsGetRecentLeadsRespo.message === "no recent data found!") {
                    setRecentLeads([])
                } else {
                    console.log(lmsGetRecentLeadsRespo)
                }
            }
        }
        setRecentLeadCollectingStatus('COLLECTED')
    }

    useEffect(() => {
        if (phone.length === 10) {
            lmsGetRecentLeads()
        }
    }, [phone])

    const lmsCreateALead = async () => {
        setIsCreatingLead(true)
        const lmsCreateALeadResponse = await fetch(BASE_URL_API + "/lmsCreateALead",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    phone: phone,
                    profilesHiringFor: JSON.stringify(selectedCategories),
                    city: JSON.stringify(selectedLocations)
                })
            });

        if (!lmsCreateALeadResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const lmsCreateALeadRespo = await lmsCreateALeadResponse.json()
            if (lmsCreateALeadRespo.status === 'success') {
                console.log(lmsCreateALeadRespo)
                toast("Lead added successfully!")
                resetLeadFeeder()
            } else {
                if (lmsCreateALeadRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(lmsCreateALeadRespo)
                }
            }
        }
        setIsCreatingLead(false)
    }

    const resetLeadFeeder = () => {
        setRecentLeadCollectingStatus([])
        setPhone(' ')
        setSelectedCategories([])
        setSelectedLocations([])
        setRecentLeadCollectingStatus('NOT_COLLECTING')
        setNewLeadCreationInitiated(false)
        setActiveStep(0)
        setIsCreatingLead(false)
        setRecentLeads([])
        document.getElementById("slfInp").focus()
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainHeader}>
                Store Leads Feeder
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.eachColWrapper}>
                    <div className={styles.eachColContainer}>

                        <div className={styles.soHead}>
                            <div className={styles.soHeadContainer}>
                                <div className={styles.soHeadTitle}>
                                    Please enter store's phone number
                                </div>
                                <div className={styles.inputPhone}>
                                    <input 
                                        id="slfInp"
                                        value={phone}
                                        placeholder="Please enter 10 digit phone number.."
                                        type="number"
                                        autoFocus={true}
                                        onChange={(e) => phoneEnteredHandler(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        {isCollected === true &&
                            <div className={styles.soBody}>
                                <div className={styles.recentTitle}>
                                    {recentLeadCollectingStatus === "COLLECTING" ? "Collecting recent leads..." : "Recently created leads"}
                                </div>
                                {recentLeadCollectingStatus === "COLLECTING" ?
                                    <div>Collecting recent leads ...</div>
                                    :
                                    recentLeads.length > 0 ?
                                        <div className={styles.recentLeadsWrapper}>
                                            {recentLeads.map((item, index) => (
                                                <div className={styles.eachOlderLeadWrapper} key={index}>
                                                    <div>
                                                        <div className={styles.createdAgo}>
                                                            <div className={styles.createdAgoTag}>
                                                            {humanReadableTimePassed(item.addedOn)}...
                                                            </div>
                                                        </div>
                                                        <div className={styles.profileTitle}>
                                                            {JSON.parse(item.profilesHiringFor).map((itemSm, indexSm) => (
                                                                <span key={indexSm} >{itemSm.category_name}, </span>
                                                            ))}
                                                        </div>
                                                        <div className={styles.storeName}>
                                                            ---
                                                        </div>
                                                        <div className={styles.storeAddress}>
                                                            {JSON.parse(item.city).map((itemLo, indexLo) => (
                                                                <span key={indexLo}>{itemLo.location}, </span>
                                                            ))}
                                                        </div>
                                                        <div className={styles.statusWrapper}>
                                                            <div className={styles.eachStatusWrapper} >
                                                                <FaCheckCircle color="green" />&nbsp;Assigned
                                                            </div>
                                                            {/* <div className={styles.eachStatusWrapperInActive} >
                                            <FaCheckCircle color="#a4a4a4" />&nbsp;Lead Assigned
                                        </div> */}
                                                            <div className={styles.eachStatusWrapper} >
                                                                <FaCheckCircle color="green" />&nbsp;Opened
                                                            </div>
                                                            {/* <div className={styles.eachStatusWrapperInActive} >
                                            <FaCheckCircle color="#a4a4a4" />&nbsp;Lead Opened
                                        </div> */}
                                                            <div className={styles.eachStatusWrapper} >
                                                                <FaCheckCircle color="green" />&nbsp;Converted
                                                            </div>
                                                            {/* <div className={styles.eachStatusWrapperInActive} >
                                            <FaCheckCircle color="#a4a4a4" />&nbsp;Lead Converted
                                        </div> */}
                                                            {/* <div className={styles.eachStatusWrapper} >
                                            <FaCheckCircle color="green" />&nbsp;Job Posted
                                        </div> */}
                                                            <div className={styles.eachStatusWrapperInActive} >
                                                                <FaCheckCircle color="#a4a4a4" />&nbsp;Posted
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div className={styles.recentLeadsWrapper}>
                                            No recent leads found!
                                        </div>
                                }
                            </div>
                        }
                        {isCollected === true &&
                            <div className={styles.soFooter}>
                                <div className={styles.soFooterContainer}>
                                    <div className={styles.soFooterTitle}>
                                        No recent leads has been created for this number, you can create a lead
                                    </div>
                                    <button className={styles.createNewLeadBtn}
                                        onClick={() => {
                                            setNewLeadCreationInitiated(true)
                                            setActiveStep(1)
                                        }
                                        }
                                    >
                                        Create A New Lead
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                {newLeadCreationInitiated === true &&
                    <div className={styles.eachColWrapper, activeStep === 1 ? styles.selectProfileWrapperActive : styles.selectProfileWrapper}>
                        <div className={styles.headerSelectProfile}>
                            <div className={styles.backArrowWrapper}>
                                <button onClick={() => setActiveStep(0)} > <FaArrowLeft />&nbsp;&nbsp; Back</button>
                            </div>
                            <div className={styles.headerTitleTxt}>
                                Please select profile
                            </div>
                        </div>
                        <div className={styles.profileBodyWrapper}>
                            {categories.map((item, index) => (
                                <div key={item.ID} className={styles.eachProfileWrapper}
                                    onClick={() => selectProfileCategoryHandler(item)}
                                >
                                    <div className={styles.checkIcon}>
                                        {selectedCategories.includes(item) ?
                                            <BsCheckSquareFill size={20} color="green" />
                                            :
                                            <BsSquare size={20} color="#a5a5a5" />
                                        }
                                    </div>
                                    <div className={styles.checkIconWeb}>
                                        {selectedCategories.includes(item) ?
                                            <BsCheckSquareFill color="green" />
                                            :
                                            <BsSquare color="#a5a5a5" />
                                        }
                                    </div>
                                    <div>
                                        {item.category_name}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.profileFooterWrapper}>
                            <button onClick={() => setActiveStep(2)} className={styles.nextBtn} >Next</button>
                        </div>
                    </div>
                }

                {newLeadCreationInitiated === true &&
                    <div className={styles.eachColWrapperFinalStep, activeStep === 2 ? styles.eachColWrapperFinalStepMobActive : styles.eachColWrapperFinalStepMob}>
                        <div className={styles.headerSelectProfile}>
                            <div className={styles.backArrowWrapper}>
                                <button onClick={() => setActiveStep(1)}> <FaArrowLeft />&nbsp;&nbsp; Back</button>
                            </div>
                            <div className={styles.headerTitleTxt}>
                                Please select city
                            </div>
                        </div>
                        <div className={styles.locationsWrapper}>
                            {allLocations.sort(function (a, b) { return a.location.localeCompare(b.location) }).map((item, index) => (
                                <div key={index}>
                                    <button className={selectedLocations.includes(item) ? styles.eachLocationWrapperSelected : styles.eachLocationWrapper}
                                        onClick={() => selectLocationsHandler(item)}
                                    >
                                        {item.location}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className={styles.createNewLeadWrapper}>
                            {isCreatingLead === true ?
                                <button className={styles.createLeadBtn}
                                >
                                    Creating Lead...
                                </button>
                                :
                                <button className={styles.createLeadBtn}
                                    onClick={() => createLeadHandler()}
                                >
                                    Create Lead
                                </button>
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default SalonLeadsFeeder