const executives = [
    {
        "username": "navneetchandan",
        "userID": "999964N8406",
        "firstName": "Navneet",
        "middleName": "",
        "lastName": "Chandan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "stylelink",
        "userID": "999964S8406",
        "firstName": "Stylelink",
        "middleName": "",
        "lastName": "General",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam901",
        "userID": "LOKACITEAM901",
        "firstName": "Ayushi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam903",
        "userID": "LOKACITEAM903",
        "firstName": "Sapna",
        "middleName": "",
        "lastName": "Rawat",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam904",
        "userID": "LOKACITEAM904",
        "firstName": "Anusha",
        "middleName": "",
        "lastName": "Saxena",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam406",
        "userID": "LOKACITEAM406",
        "firstName": "Ankita",
        "middleName": "Avinash",
        "lastName": "Valecha",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam088",
        "userID": "LOKACITEAM088",
        "firstName": "Vishi",
        "middleName": "",
        "lastName": "Chaudhary",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam0069",
        "userID": "LOKACITEAM0069",
        "firstName": "Anuradha",
        "middleName": "",
        "lastName": "Srivastava",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam097",
        "userID": "LOKACITEAM097",
        "firstName": "Khyati",
        "middleName": "",
        "lastName": "Jain",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam094",
        "userID": "LOKACITEAM094",
        "firstName": "Vivek",
        "middleName": "",
        "lastName": "Chauhan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam0094",
        "userID": "LOKACITEAM0094",
        "firstName": "Sourabh",
        "middleName": "",
        "lastName": "Chauhan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam940",
        "userID": "LOKACITEAM940",
        "firstName": "Vanshika",
        "middleName": "",
        "lastName": "Baisoya",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam541",
        "userID": "LOKACITEAM541",
        "firstName": "Anuradha",
        "middleName": "",
        "lastName": "Mishra",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam315",
        "userID": "LOKACITEAM315",
        "firstName": "Raghav",
        "middleName": "",
        "lastName": "Gahlaut",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam220",
        "userID": "LOKACITEAM220",
        "firstName": "Chanchal",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam514",
        "userID": "LOKACITEAM514",
        "firstName": "Divyanshu",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam208",
        "userID": "LOKACITEAM208",
        "firstName": "Ekanshi",
        "middleName": "",
        "lastName": "Sharma",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam527",
        "userID": "LOKACITEAM527",
        "firstName": "Shivangi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam614",
        "userID": "LOKACITEAM614",
        "firstName": "Juhi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam034",
        "userID": "LOKACITEAM034",
        "firstName": "Neha",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam948",
        "userID": "LOKACITEAM948",
        "firstName": "Shraddha",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    }
]

export const executivesData = [
    {
        "username": "navneetchandan",
        "userID": "999964N8406",
        "firstName": "Navneet",
        "middleName": "",
        "lastName": "Chandan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "stylelink",
        "userID": "999964S8406",
        "firstName": "Stylelink",
        "middleName": "",
        "lastName": "General",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam901",
        "userID": "LOKACITEAM901",
        "firstName": "Ayushi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam903",
        "userID": "LOKACITEAM903",
        "firstName": "Sapna",
        "middleName": "",
        "lastName": "Rawat",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam904",
        "userID": "LOKACITEAM904",
        "firstName": "Anusha",
        "middleName": "",
        "lastName": "Saxena",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam406",
        "userID": "LOKACITEAM406",
        "firstName": "Ankita",
        "middleName": "Avinash",
        "lastName": "Valecha",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam088",
        "userID": "LOKACITEAM088",
        "firstName": "Vishi",
        "middleName": "",
        "lastName": "Chaudhary",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam0069",
        "userID": "LOKACITEAM0069",
        "firstName": "Anuradha",
        "middleName": "",
        "lastName": "Srivastava",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam097",
        "userID": "LOKACITEAM097",
        "firstName": "Khyati",
        "middleName": "",
        "lastName": "Jain",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam094",
        "userID": "LOKACITEAM094",
        "firstName": "Vivek",
        "middleName": "",
        "lastName": "Chauhan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam0094",
        "userID": "LOKACITEAM0094",
        "firstName": "Sourabh",
        "middleName": "",
        "lastName": "Chauhan",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam940",
        "userID": "LOKACITEAM940",
        "firstName": "Vanshika",
        "middleName": "",
        "lastName": "Baisoya",
        "isActive": "0",
        "isBlocked": "1"
    },
    {
        "username": "lokaciteam541",
        "userID": "LOKACITEAM541",
        "firstName": "Anuradha",
        "middleName": "",
        "lastName": "Mishra",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam315",
        "userID": "LOKACITEAM315",
        "firstName": "Raghav",
        "middleName": "",
        "lastName": "Gahlaut",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam220",
        "userID": "LOKACITEAM220",
        "firstName": "Chanchal",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam514",
        "userID": "LOKACITEAM514",
        "firstName": "Divyanshu",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam208",
        "userID": "LOKACITEAM208",
        "firstName": "Ekanshi",
        "middleName": "",
        "lastName": "Sharma",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam527",
        "userID": "LOKACITEAM527",
        "firstName": "Shivangi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam614",
        "userID": "LOKACITEAM614",
        "firstName": "Juhi",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam034",
        "userID": "LOKACITEAM034",
        "firstName": "Neha",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    },
    {
        "username": "lokaciteam948",
        "userID": "LOKACITEAM948",
        "firstName": "Shraddha",
        "middleName": "",
        "lastName": "",
        "isActive": "1",
        "isBlocked": "0"
    }
]

export const getExecutiveFullName = (userID) => {
    // Find the executive with the matching userID
    const executive = executives.find(exec => exec?.userID === userID);
    
    if (!executive) {
        return null; // Return null if the executive is not found
    }

    // Combine firstName, middleName, and lastName, filtering out any empty values
    const fullName = [executive?.firstName, executive?.middleName, executive?.lastName]
        .filter(name => name) // Remove empty strings or null values
        .join(' '); // Join the names with a space
    
    return fullName;
}