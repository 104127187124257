import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../../Helper/helpers'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from '../InRecruitmentDomain/UpdateCourseInterestLevel.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateCourseInterestLevel = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, abortCourseInterestLevelMission, stylistIdentifier } = props

    const [isUpdating, setIsUpdating] = useState(false)
    const [addedInterests, setAddedInterests] = useState([
        {
            category: 1,
            label: 'Beauty',
            learningType: 'Course',
            addedOn: '2023-08-01 20:14:43',
            addedBy: '99996N48406'
        },
        {
            category: 1,
            label: 'Beauty',
            learningType: 'Course',
            addedOn: '2023-08-01 20:14:43',
            addedBy: '99996N48406'
        },
    ])
    const [activeHistoryItem, setActiveHistoryItem] = useState(null)
    const [isMain, setIsMain] = useState(true)
    const [categories, setCategories] = useState([
        {
            category: 1,
            label: "Beauty"
        },
        {
            category: 2,
            label: "Hair"
        },
        {
            category: 3,
            label: "Make-up"
        },
        {
            category: 4,
            label: "Mehandi"
        },
        {
            category: 5,
            label: "Nail"
        },
        {
            category: 6,
            label: "Salon Management"
        },
    ])

    const [learningType, setLearningType] = useState('')
    const [selectedCategory, setSelectedCategory] = useState([])
    const selectCategoryHandler = (k) => {
        if (selectedCategory.includes(k)) {
            setSelectedCategory(selectedCategory.filter((item) => item?.category !== k?.category))
        } else {
            setSelectedCategory([...selectedCategory, k])
        }
    }

    const updateCourseInterestLevel = async () => {
        setIsUpdating(true)
        const updateCourseInterestLevelResponse = await fetch(BASE_URL_API + "/updateCourseInterestLevel",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier,
                    addedBy: authCtx.userIdentifier,
                    learningType: learningType,
                    category: selectedCategory
                })
            });

        if (!updateCourseInterestLevelResponse.ok) {
            console.log("Something went wrong, server error!");
        } else {
            const updateCourseInterestLevelRespo = await updateCourseInterestLevelResponse.json();
            if (updateCourseInterestLevelRespo.status === 'success') {
                setLearningType('')
                setSelectedCategory([])
                toast("Course interest updated successfully!")
                setIsMain(false)
            } else {
                if (updateCourseInterestLevelRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateCourseInterestLevelRespo)
        }
        setIsUpdating(false)
    }

    const getCourseInterestLevel = async () => {
        const getCourseInterestLevelResponse = await fetch(BASE_URL_API + "/getCourseInterestLevel",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getCourseInterestLevelResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCourseInterestLevelRespo = await getCourseInterestLevelResponse.json()
            if (getCourseInterestLevelRespo.status === 'success') {
                setAddedInterests(getCourseInterestLevelRespo.response)
            } else {
                if (getCourseInterestLevelRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAddedInterests([])
                }
            }
            console.log(getCourseInterestLevelRespo)
        }
    }

    useEffect(() => {
        if (!isMain) {
            getCourseInterestLevel()
        }
    }, [isMain, stylistIdentifier])

    window.onclick = event => event.target.id === "ucilMainContainer" ? abortCourseInterestLevelMission() : null


    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer} id="ucilMainContainer" >
                {isMain ?
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div className={styles.mainHeaderTitle}>
                                Update Candidate's Interest In Pursuing a Course
                            </div>
                            <div className={styles.mainHeaderAction}>
                                <div className={styles.lastUpdateMsg}>Last update 3 days ago</div>
                                <button className={styles.viewHistoryBtn} onClick={() => setIsMain(false)}>View History</button>
                            </div>
                        </div>
                        <div className={styles.mainBody}>
                            <div className={styles.btnWrapper}>
                                <button className={learningType === 'Course' ? styles.activeBtn : styles.defaultBtn}
                                    onClick={() => setLearningType('Course')}
                                >
                                    Course
                                </button>
                                <button className={learningType === 'Masterclass' ? styles.activeBtn : styles.defaultBtn}
                                    onClick={() => setLearningType('Masterclass')}
                                >
                                    Masterclass
                                </button>
                                <button className={learningType === 'Workshop' ? styles.activeBtn : styles.defaultBtn}
                                    onClick={() => setLearningType('Workshop')}
                                >
                                    Workshop
                                </button>
                            </div>
                            <div>
                                <div className={styles.mainTitle}>Select course category</div>
                                <div className={styles.optionsWrapper}>
                                    {categories.map((item, index) => (
                                        <div className={selectedCategory.includes(item) === true ? styles.eachCardSelected : styles.eachCard} key={index}
                                            onClick={() => selectCategoryHandler(item)}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className={styles.mainFooter}>
                            <div className={styles.actionBtn}>
                                <button className={styles.cancelBtn} onClick={() => abortCourseInterestLevelMission()} >Cancel</button>
                                {(selectedCategory.length > 0 && learningType !== '') &&
                                    <>
                                        {!isUpdating ?
                                            <button className={styles.submitBtn} onClick={() => updateCourseInterestLevel()} >
                                                Submit
                                            </button>
                                            :
                                            <span>Hold mah beers buddy, lemme update it!</span>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div className={styles.mainHeaderTitle}>
                                View Candidate's Interest In Pursuing a Course
                            </div>
                            <div className={styles.mainHeaderAction}>
                                <button className={styles.viewHistoryBtn} onClick={() => setIsMain(true)} >
                                    Add New Interest
                                </button>
                            </div>
                        </div>
                        <div className={styles.mainBody}>
                            {addedInterests.length > 0 ?
                                <div className={styles.historyItemNavWrapper}>
                                    {addedInterests?.map((item, index) => (
                                        <div key={index} className={styles.eachItemNav} onClick={() => setActiveHistoryItem(item)} >
                                            <div className={styles.einLeft}>
                                                <div className={styles.dateMonth}>
                                                    {item?.addedOn.substring(8, 10)}
                                                </div>
                                            </div>
                                            <div className={styles.einRight}>
                                                <div className={styles.timePassed}>
                                                    {humanReadableTimePassed(item?.addedOn)}
                                                </div>
                                                <div className={styles.label}>
                                                    {item?.categoryLabel}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className={styles.historyItemNavWrapper}>
                                    No past records available!
                                </div>
                            }
                            {addedInterests.length > 0 &&
                                <div className={styles.historyItemBodyWrapper}>
                                    <div>
                                        <div className={styles.summaryTxt}>
                                            Want to opt for {activeHistoryItem?.categoryLabel} {activeHistoryItem?.learningType}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.mainFooterHistory}>
                            <button className={styles.doneBtn} onClick={() => abortCourseInterestLevelMission()} >Done</button>
                        </div>
                    </div>

                }


            </div>
        </div>
    )
}

export default UpdateCourseInterestLevel