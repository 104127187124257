import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Content/Course/ManageCourse.module.css'
import { Link } from "react-router-dom";
import EachCourseManager from '../../Components/Course/EachCourseManager';
import MainLoader from '../../Components/MainLoader';

const ManageCourse = () => {
    let authCtx = useContext(AuthContext);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [courses, setCourses] = useState(null);
    const [courseId, setCourseId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleLoader = (st) => {
        setIsLoading(st);
    } 

    useEffect(() => {
        getAllCourses()
    }, [])

    const getAllCourses = async () => {
        handleLoader(true)
        const getAllCourseResponse = await fetch(BASE_URL_API + "/getAllCourses",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllCourseResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllCourseRespo = await getAllCourseResponse.json();
            if (getAllCourseRespo.status == "success") {
                setCourses(getAllCourseRespo.response);
            } else {
                alert("Something went wrong, please try again!");
                console.log(getAllCourseRespo);
            }
        }
        handleLoader(false)
    }

    

    return (
        <div className={styles.mainContainer}>
            
            <div className={styles.mainContent}>
                <div className={styles.leftContent}>
                    <div className={styles.courseCategory}>
                        <button className={selectedCategory == "All" ? styles.eachCategoryFilterSelected : styles.eachCategoryFilter}
                            onClick={() => setSelectedCategory(selectedCategory != "All" ? "All" : "")}
                        >
                            All
                        </button>
                        <button className={selectedCategory == "Makeup" ? styles.eachCategoryFilterSelected : styles.eachCategoryFilter}
                            onClick={() => setSelectedCategory(selectedCategory != "Makeup" ? "Makeup" : "")}
                        >
                            Makeup
                        </button>
                        <button className={selectedCategory == "Hair" ? styles.eachCategoryFilterSelected : styles.eachCategoryFilter}
                            onClick={() => setSelectedCategory(selectedCategory != "Hair" ? "Hair" : "")}
                        >
                            Hair
                        </button>
                        <button className={selectedCategory == "Beauty" ? styles.eachCategoryFilterSelected : styles.eachCategoryFilter}
                            onClick={() => setSelectedCategory(selectedCategory != "Beauty" ? "Beauty" : "")}
                        >
                            Beauty
                        </button>
                    </div>

                    <div className={styles.courseListingWrapper}>
                        {courses != null ?
                            <div>
                                {courses.map((item, index) => (
                                    <div 
                                        key={index}
                                        className={courseId==item.courseId ? styles.eachCourseSelected : styles.eachCourse} 
                                        onClick={() => setCourseId(item.courseId)}
                                    >
                                        {item.courseTitle}
                                    </div>
                                ))}
                            </div>
                            :
                            <div>
                                No course found!
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.rightContent}>

                    <Link to="/add-course" className={styles.addNewCourse}>
                        <button>Add New Course</button>
                    </Link>
                    <EachCourseManager courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />

                </div>
            </div>
            {isLoading && <MainLoader /> }
        </div>
    )
}

export default ManageCourse