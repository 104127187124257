import React, { useContext, useEffect, useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SLCProfileOptions.module.css'

const SLCProfileOptions = props => {
    const authCtx = useContext(AuthContext)
    const { isProfileOptionOpen, profiles, toggleProfileOptions, leadIdentifier, getJobDrafts } = props
    const [selectedProfile, setSelectedProfile] = useState('')
    const [numberOfOpenings, setNumberOfOpenings] = useState(1)

    useEffect(() => {
        setSelectedProfile('')
        setNumberOfOpenings(1)
    }, [isProfileOptionOpen])
    

    const initiateJobDraft = async () => {
        // console.log(leadIdentifier, numberOfOpenings, selectedProfile, authCtx.userIdentifier)
        const initiateJobDraftResponse = await fetch(BASE_URL_API+"/initiateJobDraft",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                leadIdentifier : leadIdentifier,
                numberOfOpenings : numberOfOpenings,
                jobCategoryId : selectedProfile.ID,
                jobCategoryName : selectedProfile.category_name,
                executiveIdentifier : authCtx.userIdentifier
            })
        });

        if(!initiateJobDraftResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const initiateJobDraftRespo = await initiateJobDraftResponse.json()
            if(initiateJobDraftRespo.status==="success") {
                getJobDrafts()
                toggleProfileOptions()
            } else {
                if(initiateJobDraftRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(initiateJobDraftRespo)
        }

    }


    if (!isProfileOptionOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.header}>
                        <div>
                            Select Profile
                        </div>
                        <button className={styles.cancelBtn} onClick={() => toggleProfileOptions()}>Cancel</button>
                    </div>
                    <div className={styles.optionsWrapper}>
                        {profiles.map((item, index) => (
                            <button key={index}
                                onClick={() => {
                                    selectedProfile !== item ? setSelectedProfile(item) : setSelectedProfile('')
                                }
                                }
                                className={selectedProfile !== item ? styles.profileBtn : styles.selectedProfileBtn}
                                autoFocus={true}
                            >
                                {item.category_name}
                            </button>
                        ))}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.eachCol}>
                            <div className={styles.openingCountWrapper}>
                                <button className={styles.cancelBtn} onClick={() => setNumberOfOpenings(numberOfOpenings =>  numberOfOpenings > 1 ? numberOfOpenings - 1 : 1  )} ><BiMinus /></button>
                                <button className={styles.cancelBtn} >{numberOfOpenings}</button>
                                <button className={styles.cancelBtn} onClick={() => setNumberOfOpenings(numberOfOpenings => numberOfOpenings + 1)} ><BiPlus /> </button>
                            </div>
                        </div>
                        <div className={styles.eachCol}>
                            <button className={selectedProfile === '' ? styles.doneBtnDefault : styles.doneBtnActive}
                                onClick={() => initiateJobDraft()}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SLCProfileOptions