import React from 'react'
import styles from '../Styles/Components/MainLoader.module.css';
import { Audio, Bars, BallTriangle, Circles, Grid, Hearts } from 'react-loader-spinner'

const MainLoader = () => {
    return (
        <div className={styles.mainLoader}>
            <Circles color="white" height={80} width={80} />
        </div>
    )
}

export default MainLoader