import React from 'react'
import { BsClock } from 'react-icons/bs'
import { FaClock, FaUserCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styles from '../Tasks/EachMiniLead.module.css'

const EachMiniLead = props => {
    const { item, isCurrentLead, updateCurrentLead } = props
    return (
        <Link to={`/tasks/lead/`+item.leadIdentifier}>
            <div className={isCurrentLead === true ? styles.eachLeadWrapperSelected : styles.eachLeadWrapper}
                onClick={() => updateCurrentLead(item)}
            >
                <div className={styles.elwUpper}>
                    <div>
                        <FaUserCircle size={36} />
                    </div>
                    <div className={styles.userDetails}>
                        <div className={styles.username}>
                            {item.firstName} {item.middleName} {item.lastName}
                        </div>
                        <div className={styles.userGender}>
                            {item.gender}
                        </div>
                    </div>
                </div>
                <div className={styles.elwLower}>
                    <div className={styles.ago}>
                        {/* <BsClock color="#00000068" size={12} />&nbsp; */}
                        {/* 4 days ago */}
                        {item.assignedOn}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default EachMiniLead