import React from 'react'
import styles from '../ManagePartnersAccounts/Summary.module.css'

const Summary = props => {
    let selectedStore = props.selectedStore;
    return (
        <div>
            Summary
            {selectedStore.store_id}
        </div>
    )
}

export default Summary