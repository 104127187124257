import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SpaEachSuggestedResult.module.css'

const SpaEachSuggestedResult = props => {
    const { item } = props
    const authCtx = useContext(AuthContext)
    const [isSetting, setIsSetting] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [vendorIdentifier, setVendorIdentifier] = useState('')

    const setVendorIdentifierNow = async (k) => {
        setIsSetting(true)
        const setVendorIdentifierResponse = await fetch(BASE_URL_API + "/setVendorIdentifier",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vender_email: k
                })
            });

        if (!setVendorIdentifierResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const setVendorIdentifierRespo = await setVendorIdentifierResponse.json()
            if (setVendorIdentifierRespo.status === "success") {
                setVendorIdentifier(setVendorIdentifierRespo.response)
                setIsSuccess(true)
            } else {
                if (setVendorIdentifierRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setIsSuccess(false)
                }
            }
            console.log(setVendorIdentifierRespo)
        }
        setIsSetting(false)
    }

    // console.log(item)

    return (
        <div>
            {
                item?.vendorIdentifier !== "" ?
                    <Link to={"/manage-partners-account/" + item?.vendorIdentifier}  >
                        <div className={styles.eachAccountWrapper}>
                            <div className={styles.companyName}>
                                {item?.company_name}
                            </div>
                            <div className={styles.address}>
                                {item?.search_result}
                            </div>
                        </div>
                    </Link>
                    :
                    <div className={styles.eachAccountWrapper}>
                        <div className={styles.companyName}>
                            {item?.company_name}
                        </div>
                        <div className={styles.address}>
                            {item?.search_result}
                        </div>
                        <div>
                            {isSetting === false ?
                                <div>
                                    {vendorIdentifier !== "" ?
                                        <Link to={"/manage-partners-account/" + vendorIdentifier} >Visit Partners Account </Link>
                                        :
                                        <button onClick={() => setVendorIdentifierNow(item?.vender_email)} >
                                            Set Identifier
                                        </button>
                                    }
                                </div>
                                :
                                <button>
                                    Updating...
                                </button>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default SpaEachSuggestedResult