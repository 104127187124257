import React, { useContext, useState } from 'react'
import Collapsible from 'react-collapsible';
import AddNewModule from '../../Components/Course/AddNewModule';
import AddNewSubModule from '../../Components/Course/AddNewSubModule';
import styles from '../../Styles/Content/Course/AddNewCourse.module.css';
import CourseContent from '../../Components/Course/CourseContent';
import WhatYouLearn from '../../Components/Course/WhatYouLearn';
import AuthContext from '../../store/auth-context';
import { BASE_URL_API } from '../../References/urls';
import { useNavigate } from 'react-router';

import { Audio, Bars, BallTriangle, Circles, Grid, Hearts } from 'react-loader-spinner'
import CourseBasicDetails from '../../Components/Course/CourseBasicDetails';
import CoursePricing from '../../Components/Course/CoursePricing';
import CourseAdministration from '../../Components/Course/CourseAdministration';

const AddNewCourse = () => {
    const authCtx = useContext(AuthContext);
    let navigate = useNavigate();
    authCtx.token == "" && navigate("/");
    const [isLoading, setIsLoading] = useState(false);
    const [steps, setSteps] = useState([
        {
            "step": "1",
            "stepName": "Basic Details",
            "id": "basicDetails"
        },
        {
            "step": "2",
            "stepName": "What you'll learn in this course?",
            "id": "whatYoullLearn"
        },
        {
            "step": "3",
            "stepName": "Modules in the course!",
            "id": "modules"
        },
        {
            "step": "4",
            "stepName": "Pricing",
            "id": "pricing"
        },
        {
            "step": "5",
            "stepName": "Admin/Marketing",
            "id": "adminMarketing"
        },
    ]);
    const [currentStep, setCurrentStep] = useState(1);
    const [courseId, setCourseId] = useState("");

    const handleLoader = (st) => {
        setIsLoading(st);
    }

    // console.log(courseSecondaryLanguage);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.leftContent}>
                    <div className={styles.titleContent}>
                        AddNewCourse
                    </div>
                    {steps.map((item, index) => (
                        courseId != "" ?
                            <div className={styles.eachStep} onClick={() => setCurrentStep(item.step)} key={index}>
                                <div className={styles.eachStepIcon}>
                                    <div className={styles.eachStepCountActive}>
                                        {index + 1}
                                    </div>
                                </div>
                                <div className={styles.eachStepTitleActive}>
                                    {item.stepName}
                                </div>
                            </div>
                            :
                            <div className={styles.eachStep} onClick={() => alert("First submit the basic details of a course")}>
                                <div className={styles.eachStepIcon}>
                                    <div className={styles.eachStepCount}>
                                        {index + 1}
                                    </div>
                                </div>
                                <div className={styles.eachStepTitle}>
                                    {item.stepName}
                                </div>
                            </div>

                    ))}
                </div>
                <div className={styles.rightContent}>
                    {currentStep == 1 && <CourseBasicDetails courseId={courseId} setIsLoading={handleLoader} setCourseIdHandler={setCourseId} />}
                    {currentStep == 2 && <WhatYouLearn courseId={courseId} setIsLoading={handleLoader} />}
                    {currentStep == 3 && <CourseContent courseId={courseId} setIsLoading={handleLoader} />}
                    {currentStep == 4 && <CoursePricing courseId={courseId} setIsLoading={handleLoader} />}
                    {currentStep == 5 && <CourseAdministration courseId={courseId} setIsLoading={handleLoader} />}
                </div>
            </div>

            {isLoading && <div className={styles.mainLoader}>
                <Circles color="white" height={80} width={80} />
            </div>}
        </div>
    )
}

export default AddNewCourse