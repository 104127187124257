import { Payment, PaymentOutlined } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { BiLeftArrow, BiRupee } from 'react-icons/bi'
import { BsArrowLeft, BsBookHalf, BsInfo } from 'react-icons/bs'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { MdNextPlan } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import EnquiryCounsellor from '../../Components/Academics/EnquiryHandler/EnquiryCounsellor'
import EnquiryCourses from '../../Components/Academics/EnquiryHandler/EnquiryCourses'
import EnquiryFeeNPaymentPlan from '../../Components/Academics/EnquiryHandler/EnquiryFeeNPaymentPlan'
import EnquiryInformation from '../../Components/Academics/EnquiryHandler/EnquiryInformation'
import EnquiryNextPlan from '../../Components/Academics/EnquiryHandler/EnquiryNextPlan'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AcademyEachEnquiry.module.css'
import { humanReadableTimePassed } from '../../Helper/helpers'

const AcademyEachEnquiry = () => {
    const params = useParams()
    const { visitorToken } = params
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('Info')
    const [allVisits, setAllVisits] = useState([])

    const getAllVisitsOfCandidate = async () => {
        const getAllVisitsOfCandidateResponse = await fetch(BASE_URL_API + "/acad-getVisitsForEnquiry",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    visitorToken: visitorToken
                })
            });

        if (!getAllVisitsOfCandidateResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllVisitsOfCandidateRespo = await getAllVisitsOfCandidateResponse.json();
            if (getAllVisitsOfCandidateRespo.status === "success") {
                setAllVisits(getAllVisitsOfCandidateRespo.response);
            } else {
                if (getAllVisitsOfCandidateRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllVisits([])
                    console.log(getAllVisitsOfCandidateRespo)
                }
            }
        }
    }

    useEffect(() => {
        getAllVisitsOfCandidate()
    }, [visitorToken])


    console.log(allVisits)
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeaderWrapper}>
                <div className={styles.leftArrow} >
                    <Link to="/academics/enquires">
                        <BsArrowLeft size={36} />
                    </Link>
                </div>
                <div>
                    {allVisits.map((item, index) => (
                        <button key={index} className={visitorToken===item?.visitorToken ? styles.activeVisit : styles.defVisit}>
                            <Link to={`/academics/enquires/` + item?.visitorToken}>{humanReadableTimePassed(item?.createdOn)}</Link>
                        </button>
                    ))}
                </div>
            </div>
            <div className={styles.colWrapper}>
                <div className={styles.actionCol}>
                    <div className={activeTab === "Info" ? styles.eachSectionWrapperActive : styles.eachSectionWrapper}>
                        <button onClick={() => setActiveTab("Info")}
                            title="Student Details"><BsInfo size={30} /> </button>
                    </div>
                    <div className={activeTab === "Counsellor" ? styles.eachSectionWrapperActive : styles.eachSectionWrapper}>
                        <button onClick={() => setActiveTab("Counsellor")}
                            title="Counsellor">
                            <FaChalkboardTeacher size={30} />
                        </button>
                    </div>
                    <div className={activeTab === "Course" ? styles.eachSectionWrapperActive : styles.eachSectionWrapper}>
                        <button onClick={() => setActiveTab("Course")}
                            title="Courses that student is enquired about!">
                            <BsBookHalf size={30} />
                        </button>
                    </div>
                    <div className={activeTab === "Fee" ? styles.eachSectionWrapperActive : styles.eachSectionWrapper}>
                        <button onClick={() => setActiveTab("Fee")}
                            title="Fee Structure">
                            <BiRupee size={30} />
                        </button>
                    </div>
                    <div className={activeTab === "NextPlan" ? styles.eachSectionWrapperActive : styles.eachSectionWrapper}>
                        <button onClick={() => setActiveTab("NextPlan")}
                            title="Plan of Action">
                            <MdNextPlan size={30} />
                        </button>
                    </div>
                </div>
                <div className={styles.activityCol}>
                    {activeTab === "Info" && <EnquiryInformation visitorToken={visitorToken} />}
                    {activeTab === "Counsellor" && <EnquiryCounsellor visitorToken={visitorToken} />}
                    {activeTab === "Course" && <EnquiryCourses visitorToken={visitorToken} />}
                    {activeTab === "Fee" && <EnquiryFeeNPaymentPlan visitorToken={visitorToken} />}
                    {activeTab === "NextPlan" && <EnquiryNextPlan visitorToken={visitorToken} />}
                </div>
            </div>
        </div>
    )
}

export default AcademyEachEnquiry