import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styles from './CampaignsCreate.module.css'
import { FaFolder } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import CampaignSettings from '../../Components/Campaigns/CampaignSettings';
import CommunicationSettings from '../../Components/Campaigns/CommunicationSettings';
import AudienceSettings from '../../Components/Campaigns/AudienceSettings';




const CampaignsCreate = () => {
    const [activeNav, setActiveNav] = useState('CAMPAIGN'); // CAMPAIGN, COMMUNICATION, AUDIENCE


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.navigatorWrapper}>
                    <div className={styles.leftColWrapper}>
                        <div className={styles.navigateBackWrapper}>
                            <Link to={`/campaigns`}>
                                <MdArrowBack size={26} />
                            </Link>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className={styles.centerWrapper}>
                        <div className={styles.eachNavOption}
                            onClick={() => setActiveNav("CAMPAIGN")}
                        >
                            <FaFolder size={18} />&nbsp; &nbsp;Campaign's Setting
                        </div>
                        <div className={styles.eachNavOption}
                            onClick={() => setActiveNav("COMMUNICATION")}
                        >
                            &nbsp;&nbsp;<MdMessage size={18} />&nbsp; &nbsp;Communications Setting
                        </div>
                        <div className={styles.eachNavOption}
                            onClick={() => setActiveNav("AUDIENCE")}
                        >
                            &nbsp;&nbsp;<IoIosPeople size={18} />&nbsp; &nbsp;Audience Setting
                        </div>

                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    {activeNav === 'CAMPAIGN' && <CampaignSettings />}
                    {activeNav === 'COMMUNICATION' && <CommunicationSettings />}
                    {activeNav === 'AUDIENCE' && <AudienceSettings />}
                </div>

            </div>
        </div>
    )
}

export default CampaignsCreate