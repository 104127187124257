import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from './CreateNewCourseInitiator.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

const CreateNewCourseInitiator = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, toggleCreateNew } = props;
    const [masterPin, setMasterPin] = useState('')
    const navigate = useNavigate()

    const createNewCourse = async () => {
        if(masterPin!=='') {
            const createNewCourseResponse = await fetch(BASE_URL_API+"/acad-createNewCourse",
            {
                method : "POST",
                body : JSON.stringify({
                    token : authCtx.token,
                    masterPin : masterPin,
                    executiveIdentifier : authCtx.userIdentifier
                })
            });
    
            if(!createNewCourseResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const createNewCourseRespo = await createNewCourseResponse.json();
                if(createNewCourseRespo.status==="success") {
                    navigate("/academics/courses/create/"+createNewCourseRespo?.message)
                } else {
                    if(createNewCourseRespo.message==="token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        toast(createNewCourseRespo.message)
                        console.log(createNewCourseRespo)
                    }
                }
            }
        } else {
            alert("Please enter master pin");
        }
    }

    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.popUpWrapper}>
                    <div className={styles.popUpContainer}>
                        <div>
                            <div className={styles.caption}>
                                Are you sure you want to create a new course?
                            </div>
                            <div className={styles.masterPinInput}>
                                <input 
                                    type="password" 
                                    placeholder="Please enter admin master pin!"  
                                    autoComplete={false}
                                    autoFocus={true}
                                    onChange={(e) => setMasterPin(e.target.value)}
                                    value={masterPin}
                                />
                            </div>
                        </div>
                        <div className={styles.actionBtnWrapper}>
                            <button className={styles.actionBtnCancel} onClick={() => toggleCreateNew()} >Cancel</button>
                            <button className={styles.actionBtnGoahead} onClick={() => createNewCourse()} >Go Ahead!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewCourseInitiator