import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Tasks/LeadsSummaryInTask.module.css'
import SearchIncomingLead from './SearchIncomingLead'

const LeadsSummaryInTask = props => {

    return (
        <div className={styles.summaryWrapper}>
            <div className={styles.leftWrapper}>
                <div>
                    <div>
                        {/* {availableLeads.length}  */}
                        Leads
                    </div>
                </div>
                <div>
                    -
                </div>
            </div>
            <div className={styles.rightWrapper}>
                <Link to="/tasks/lead/search">
                    <div className={styles.goToSearch}>
                        <div className={styles.goToSearchTitle}>
                            Incoming Call?
                        </div>
                        <div className={styles.goToSearchCaption}>
                            Search about the caller..
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default LeadsSummaryInTask