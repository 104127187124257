import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './BulkLeadPreviewAndUploader.module.css'

const BulkLeadPreviewAndUploader = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, previewToggler, csvData } = props
    const [validData, setValidData] = useState([])

    const [hasUploadingStarted, setHasUploadingStarted] = useState(false)
    const [batchProgressTracker, setBatchProgressTracker] = useState([])
    const [leadSource, setLeadSource] = useState('')

    // Specify the batch size
    const batchSize = 50; // Adjust based on your requirements
    // console.log(csvData)

    function sanitizePhoneNumber(phoneNumber) {
        // Remove non-digit characters from the phone number
        return phoneNumber.replace(/\D/g, '');
    }

    function isValidPhoneNumber(phoneNumber) {
        // Sanitize the phone number
        const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber);

        // Check if the sanitized phone number is a valid 10-digit number
        const isValidLength = sanitizedPhoneNumber.length === 10;
        const containsOnlyNumbers = /^\d+$/.test(sanitizedPhoneNumber);
        const startsWithValidDigit = /^[5-9]/.test(sanitizedPhoneNumber);

        return isValidLength && containsOnlyNumbers && startsWithValidDigit;
    }

    function sanitizeAndValidateData(data) {
        // Helper function to sanitize and validate city
        function sanitizeAndValidateCity(city) {
            const sanitizedCity = city.trim().replace(/[^A-Za-z\s]/g, ''); // Remove special characters
            const capitalizedCity = sanitizedCity.charAt(0).toUpperCase() + sanitizedCity.slice(1).toLowerCase();
            return capitalizedCity.length <= 120 ? capitalizedCity : 'UnIdentified';
        }

        // Helper function to sanitize and validate gender
        function sanitizeAndValidateGender(gender) {
            const sanitizedGender = gender.trim().toLowerCase();
            return ['male', 'female'].includes(sanitizedGender) ? sanitizedGender : null;
        }

        // Process each entry in the data array
        return data.map((entry) => {
            // Sanitize and validate fullName
            const sanitizedFullName = entry.fullName.trim().replace(/[^A-Za-z\s]/g, ''); // Remove special characters
            const capitalizedFullName = sanitizedFullName.charAt(0).toUpperCase() + sanitizedFullName.slice(1).toLowerCase();

            // Sanitize and validate city
            const sanitizedCity = sanitizeAndValidateCity(entry.city);

            // Sanitize and validate gender
            const sanitizedGender = sanitizeAndValidateGender(entry.gender);

            // Return the updated entry
            return {
                ...entry,
                fullName: capitalizedFullName,
                city: sanitizedCity,
                gender: sanitizedGender,
            };
        });
    }

    const filterData = () => {
        const validPhoneNumbers = csvData.filter((entry) => isValidPhoneNumber(entry.phoneNumber));
        const sanitizedData = sanitizeAndValidateData(validPhoneNumbers);
        setValidData(sanitizedData)
    }


    const processBatches = async (data, batchSize) => {
        // let's first validate the leadSource
        if (leadSource.trim() !== "" && leadSource !== "Select Lead Source") {
            setHasUploadingStarted(true)
            // console.log("Starting batch on ", data, batchSize)

            // Helper function to generate a unique identifier
            const generateId = () => Math.random().toString(36).substring(7);

            // Helper function to send a batch to the server
            const sendBatch = async (batch, batchId) => {
                try {
                    const batchStatus = {
                        batchId: batchId,
                        batchStarted: true,
                        batchCompleted: false,
                        batchResponse: null,
                    };

                    setBatchProgressTracker((prevProgress) => [...prevProgress, { ...batchStatus, batchId }]);



                    // console.log(batch)
                    const response = await fetch(BASE_URL_API + "/uploadCandidateLeadsInBulk",
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                token: authCtx.token,
                                executiveIdentifier: authCtx.userIdentifier,
                                leadSource: leadSource,
                                batchId: batchId,
                                phoneNumbers: batch
                            }),
                            // Modify based on your data structure
                        }
                    );

                    if (response.ok) {
                        const responseData = await response.json();
                        console.log(responseData); // Handle the response as needed
                        if (responseData.message === "token Expired, please login again!") {
                            authCtx.logout()
                        }

                        console.log(responseData)
                        // Update the correct batch status after receiving the response
                        setBatchProgressTracker((prevProgress) =>
                            prevProgress.map((prevBatch) =>
                                prevBatch.batchId === batchId ? { ...prevBatch, batchCompleted: true, batchResponse: responseData } : prevBatch
                            )
                        );

                    } else {
                        console.error('Error:', response.status, response.statusText);
                    }

                } catch (error) {
                    console.error('Error sending batch:', error);
                }
            };

            // Split data into batches and send each batch
            for (let i = 0; i < data.length; i += batchSize) {
                const batch = data.slice(i, i + batchSize);
                // console.log(i, batch)
                const batchId = generateId();
                await sendBatch(batch, batchId);
            }


        } else {
            alert("Please select a valid lead source")
        }


    }



    const initiateUploadNow = () => {
        // let's first filter out the data, and make uploadable data ready
        filterData()
    }

    useEffect(() => {
        if (isOpen === false) {
            setValidData([])
            setHasUploadingStarted(false)
            setBatchProgressTracker([])
        }
    }, [isOpen])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.leftBtn}>
                    <button onClick={() => previewToggler()}>Close</button>
                </div>
                <div>
                    Bulk Lead Preview And Uploader
                </div>
            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.fileName}>Filename</div>
                        <div className={styles.phoneNumber}>phoneNumber</div>
                        <div className={styles.fullName}>fullName</div>
                        <div className={styles.city}>city</div>
                        <div className={styles.gender}>gender</div>
                    </div>
                    <div className={styles.tableBody}>
                        {csvData?.map((item, index) => (
                            <div key={index} className={styles.eachRow}>
                                <div className={styles.fileNameTBody}>
                                    {item?.fileName}
                                </div>
                                <div className={styles.phoneNumberTBody}>
                                    {item?.phoneNumber}
                                </div>
                                <div className={styles.fullNameTBody}>
                                    {item?.fullName}
                                </div>
                                <div className={styles.cityTBody}>
                                    {item?.city}
                                </div>
                                <div className={styles.genderTBody}>
                                    {item?.gender}
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

                <div className={styles.actionsWrapper}>
                    <div className={styles.actionTitleWrapper}>
                        <div>
                            Actions!
                        </div>
                        <div>
                            <button onClick={() => initiateUploadNow()}>Validate Data</button>
                        </div>
                    </div>

                    <div className={styles.dataFilteringWrapper}>

                        <div className={styles.topWrapper}>
                            <div className={styles.eachBoxWrapper}>
                                Data received : {csvData?.length}
                            </div>
                            <div className={styles.eachBoxWrapper}>
                                Data Validated : {validData?.length}
                            </div>
                            <div className={styles.eachBoxWrapper}>
                                Invalid / Discarded Data : {csvData?.length - validData?.length}
                            </div>
                        </div>
                        <div className={styles.invalidDataContainer}>
                            <textarea
                                readOnly
                                value={
                                    csvData.filter(data => !Object.keys(data).every(key => validData.some(validEntry => validEntry['phoneNumber'] === data['phoneNumber']))).map((item, index) => (
                                        `\n${item.phoneNumber}`
                                    ))
                                }
                            >
                            </textarea>
                        </div>
                        <div className={styles.startBatchNStatusContainer}>
                            <div>
                                <select onChange={(e) => setLeadSource(e.target.value)}>
                                    <option value="">Select Lead Source</option>
                                    <option value='WORK_INDIA_BULK_DATA'>WORK_INDIA_BULK_DATA</option>
                                    <option value='APNA_JOB_BULK_DATA'>APNA_JOB_BULK_DATA</option>
                                    <option value='JOB_HAI_BULK_DATA'>JOB_HAI_BULK_DATA</option>
                                    <option value='INDEED_JOB_BULK_DATA'>INDEED_JOB_BULK_DATA</option>
                                    <option value='DIRECT_SOCIAL_GROUPS_BULK_DATA'>DIRECT_SOCIAL_GROUPS_BULK_DATA</option>
                                </select>
                            </div>
                            {hasUploadingStarted === false ?
                                <button onClick={() => processBatches(validData, batchSize)}>
                                    Upload Now
                                </button>
                                :
                                <span>Uploading in progress...</span>
                            }
                        </div>
                    </div>

                    <div className={styles.batchProgressWrapper}>
                        <div>
                            Batch Progress
                        </div>
                        <div className={styles.progressTrackerWrapper}>
                            {batchProgressTracker.map((batchStatus, index) => (
                                <div key={index} className={styles.eachBatchStatusWrapper}>
                                    <div className={styles.bsLeftCol}>
                                        <div>Batch {index + 1}:</div>
                                        <div>Batch ID: {batchStatus.batchId.toString()}</div>
                                        <div>Batch Started: {batchStatus.batchStarted.toString()}</div>
                                        <div>Batch Completed: {batchStatus.batchCompleted.toString()}</div>
                                    </div>
                                    <div className={styles.bsMiddleCol}>
                                        <div className={styles.servedContacts}>
                                            Served Contacts : {batchStatus?.batchResponse?.response?.servedContactsCount}
                                        </div>
                                        <div>
                                            Already Exists : {batchStatus?.batchResponse?.response?.alreadyExistsCount}
                                        </div>
                                        <div>
                                            New Contacts : {batchStatus?.batchResponse?.response?.newPhoneNumbersCount}
                                        </div>
                                    </div>
                                    <div className={styles.bsRightCol}>
                                        <textarea readOnly value={batchStatus?.batchResponse?.response?.alreadyExists?.map((item, index) => (
                                            `\n${item}`
                                        ))
                                        }></textarea>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default BulkLeadPreviewAndUploader