import React, { useContext, useEffect, useState } from 'react'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Analytics/AnalyticsUser.module.css'

const AnalyticsUser = () => {
    const authCtx = useContext(AuthContext)

    const [data, setData] = useState([
        {
            "category_name": "Page A",
            "uv": 4000,
            "percentageContribution": 2400,
            "count": 2400
        },
        {
            "category_name": "Page B",
            "uv": 3000,
            "percentageContribution": 1398,
            "count": 2210
        },
        {
            "category_name": "Page C",
            "uv": 2000,
            "percentageContribution": 9800,
            "count": 2290
        },
        {
            "category_name": "Page D",
            "uv": 2780,
            "percentageContribution": 3908,
            "count": 2000
        },
        {
            "category_name": "Page E",
            "uv": 1890,
            "percentageContribution": 4800,
            "count": 2181
        },
        {
            "category_name": "Page F",
            "uv": 2390,
            "percentageContribution": 3800,
            "count": 2500
        },
        {
            "category_name": "Page G",
            "uv": 3490,
            "percentageContribution": 4300,
            "count": 2100
        }
    ])

    const [info, setInfo] = useState(null)
    const [isCurrentLocationUpdated, setIsCurrentLocationUpdated] = useState('')
    const [categories, setCategories] = useState([])
    const [selectedProfileCategory, setSelectedProfileCategory] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getUserAnalysis = async () => {
        const getUserAnalysisResponse = await fetch(BASE_URL_API + "/sa-getUserAnalysis",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    isCurrentLocationUpdated: isCurrentLocationUpdated,
                    selectedProfileCategory: selectedProfileCategory,
                    searchKey: searchKey
                })
            });

        if (!getUserAnalysisResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getUserAnalysisRespo = await getUserAnalysisResponse.json();
            if (getUserAnalysisRespo.status === "success") {
                setInfo(getUserAnalysisRespo.response)
            } else {
                if (getUserAnalysisRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setInfo(null)
                }
            }
            // console.log(getUserAnalysisRespo)
        }
    }

    useEffect(() => {
        getUserAnalysis()
    }, [isCurrentLocationUpdated, selectedProfileCategory])

    useEffect(() => {
        if (searchKey.length > 2) {
            getUserAnalysis()
        }
    }, [searchKey])

    function convertStringToNumber(data) {
        const numericKeys = ["percentageContribution", "count"];
        data.forEach(item => numericKeys.forEach(key => item[key] = Number(item[key])));
        return data;
    }
    

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setCategories(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCategories([])
                }
            }
            console.log(getCategoryOptionsRespo)
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])

    const toggleProfileCategorySelection = (k) => {
        setSelectedProfileCategory(selectedProfileCategory.includes(k) ? selectedProfileCategory.filter((i) => i !== k) : [...selectedProfileCategory, k])
    }

    // console.log(selectedProfileCategory)

    const getProfileWiseVolume = async () => {
        const getProfileWiseVolumeResponse = await fetch(BASE_URL_API+"/sa-getProfileWiseVolume",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getProfileWiseVolumeResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getProfileWiseVolumeRespo = await getProfileWiseVolumeResponse.json()
            if(getProfileWiseVolumeRespo.status==="success") {
                setData(convertStringToNumber(getProfileWiseVolumeRespo.response))
            } else {
                if(getProfileWiseVolumeRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setData([])
                }
            }
            console.log(getProfileWiseVolumeRespo)
        }
    }

    useEffect(() => {
        getProfileWiseVolume()
    }, [])
    

    return (
        <div>
            <div>
                <div>
                    {categories.map((item, index) => (
                        <button key={index}
                            className={selectedProfileCategory.includes(item.ID) ? styles.selectedBtn : styles.normalBtn}
                            onClick={() => toggleProfileCategorySelection(item.ID)}
                        >
                            {item.category_name}
                        </button>
                    ))}
                </div>
                Understand your user statistics
                <div>
                    <button onClick={() => setIsCurrentLocationUpdated('')}>All</button>

                    <button onClick={() => setIsCurrentLocationUpdated("true")} >Updated Current Location</button>
                    <button onClick={() => setIsCurrentLocationUpdated("false")} >Not Updated Current Location</button>
                </div>
                <div>
                    <input onChange={(e) => setSearchKey(e.target.value)} />
                </div>
            </div>
            <div className={styles.graphWrapper}>
                {info?.vol}
                <ComposedChart width={1330} height={450} data={data}>
                    <XAxis dataKey="category_name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Area type="monotone" dataKey="count" fill="#8884d8" stroke="#8884d8" />
                    <Bar dataKey="percentageContribution" barSize={20} fill="#413ea0" />
                    <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                </ComposedChart>
            </div>
        </div>
    )
}

export default AnalyticsUser