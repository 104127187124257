import { ChevronLeft } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styles from './CreateNewAccount.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPincodeFromAddress, isValidEmail, isValidMobileNumber } from '../../Helper/helpers';
import { MdOutlineVerified, MdVerified } from 'react-icons/md';
import { AiOutlineVerified } from 'react-icons/ai';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import AddAuthPersonnelForPartnersAccount from '../../Components/Partners/AddAuthPersonnelForPartnersAccount';
import AccountExistanceValidatorViaContactDetails from '../../Components/Partners/AccountExistanceValidatorViaContactDetails';

const CreateNewAccount = () => {
    const { vendorIdentifier } = useParams()
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const [pdFiled, setPdFiled] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [pincode, setPincode] = useState('')
    const [isPrimaryValidated, setIsPrimaryValidated] = useState(false)
    const [isCompanyNameValid, setIsCompanyNameValid] = useState(false)
    const [isAddressValid, setIsAddressValid] = useState(false)
    const [isPhoneValid, setIsPhoneValid] = useState(false)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isPincodeValid, setIsPincodeValid] = useState(false)
    const [accountDetails, setAccountDetails] = useState(null)
    const [isCollectingAccountDetails, setIsCollectingAccountDetails] = useState(true)
    const [authPersonnelData, setAuthPersonnelData] = useState([])
    const [hasAlreadyExists, setHasAlreadyExists] = useState(false)
    const [existingResponse, setExistingResponse] = useState([])
    const [isCreatingAccount, setIsCreatingAccount] = useState(false)

    const createAccountHandler = () => {

    }

    const primarlyFormValidator = () => {
        if (companyName.length > 4) {
            if (address.length > 14) {
                if (phone.length === 10 && isValidMobileNumber(phone)) {
                    if (email.length > 5 && isValidEmail(email)) {
                        if (pincode.length === 6) {
                            setIsPrimaryValidated(true)
                        } else {
                            setIsPrimaryValidated(false)
                        }
                    } else {
                        setIsPrimaryValidated(false)
                    }
                } else {
                    setIsPrimaryValidated(false)
                }
            } else {
                setIsPrimaryValidated(false)
            }
        } else {
            setIsPrimaryValidated(false)
        }
    }

    useEffect(() => {
        primarlyFormValidator()
    }, [companyName, address, phone, email, pincode])

    useEffect(() => {
        companyName.length > 5 ? setIsCompanyNameValid(true) : setIsCompanyNameValid(false)
    }, [companyName])

    useEffect(() => {
        setPincode(getPincodeFromAddress(address) || " ")
        if (address.length > 10) {
            setIsAddressValid(true)
        }
    }, [address])


    useEffect(() => {
        if (phone.length === 10 && isValidMobileNumber(phone)) {
            checkPartnerAccountExistanceUsingCd()
            setIsPhoneValid(isValidMobileNumber(phone))
        } else {
            setIsPhoneValid(isValidMobileNumber(phone))
        }
    }, [phone])

    useEffect(() => {
        setIsEmailValid(isValidEmail(email))
    }, [email])

    useEffect(() => {
        if (isEmailValid) {
            checkPartnerAccountExistanceUsingCd()
        }
    }, [email, isEmailValid])


    useEffect(() => {
        setIsPincodeValid(pincode.length === 6 ? true : false)
    }, [pincode])


    const createPartnersNewAccount = async () => {
        setIsCreatingAccount(true)
        const createPartnersNewAccountResponse = await fetch(BASE_URL_API + "/createPartnersNewAccount",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    companyName: companyName,
                    address: address,
                    phone: phone,
                    email: email,
                    pincode: pincode,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!createPartnersNewAccountResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createPartnersNewAccountRespo = await createPartnersNewAccountResponse.json()
            if (createPartnersNewAccountRespo.status === "success") {
                navigate("/manage-partners-account/" + createPartnersNewAccountRespo.response)
            } else {
                if (createPartnersNewAccountRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createPartnersNewAccountRespo)
        }
        setIsCreatingAccount(false)
    }

    const getPartnersNewAccountDetails = async () => {
        setIsCollectingAccountDetails(true)
        const getPartnersNewAccountDetailsResponse = await fetch(BASE_URL_API + "/getPartnersNewAccountDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!getPartnersNewAccountDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPartnersNewAccountDetailsRespo = await getPartnersNewAccountDetailsResponse.json();
            if (getPartnersNewAccountDetailsRespo.status === "success") {
                setAccountDetails(getPartnersNewAccountDetailsRespo.response)
                setPdFiled(true)
            } else {
                if (getPartnersNewAccountDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else if (getPartnersNewAccountDetailsRespo.message === "invalid key details") {
                    setAccountDetails(null)
                    setPdFiled(false)
                } else {
                    setAccountDetails(null)
                    setPdFiled(false)
                }
            }

            console.log(getPartnersNewAccountDetailsRespo.response)
            setIsCollectingAccountDetails(false)
        }
    }

    useEffect(() => {
        if (vendorIdentifier !== undefined) getPartnersNewAccountDetails()
    }, [vendorIdentifier])


    const getAuthPersonnelDetails = async () => {
        const getAuthPersonnelDetailsResponse = await fetch(BASE_URL_API + "/getAuthPersonnelDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!getAuthPersonnelDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAuthPersonnelDetailsRespo = await getAuthPersonnelDetailsResponse.json();
            if (getAuthPersonnelDetailsRespo.status === 'success') {
                setAuthPersonnelData(getAuthPersonnelDetailsRespo.response)
            } else {
                if (getAuthPersonnelDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAuthPersonnelData([])
                }
            }
            console.log(getAuthPersonnelDetailsRespo.response)
        }
    }

    const checkPartnerAccountExistanceUsingCd = async () => {
        const checkPartnerAccountExistanceUsingCdResponse = await fetch(BASE_URL_API + "/checkPartnerAccountExistanceUsingCd",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    phone: phone,
                    email: email
                })
            });

        if (!checkPartnerAccountExistanceUsingCdResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const checkPartnerAccountExistanceUsingCdRespo = await checkPartnerAccountExistanceUsingCdResponse.json()
            if (checkPartnerAccountExistanceUsingCdRespo.status === "success") {
                if (checkPartnerAccountExistanceUsingCdRespo.message === "record_exists") {
                    setHasAlreadyExists(true)
                    setExistingResponse(checkPartnerAccountExistanceUsingCdRespo.response)
                } else {
                    setHasAlreadyExists(false)
                    setExistingResponse([])
                }
            } else {
                if (checkPartnerAccountExistanceUsingCdRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else if (checkPartnerAccountExistanceUsingCdRespo.message === "no_record_found") {
                    setHasAlreadyExists(false)
                    setIsPhoneValid(true)
                } else {
                    setHasAlreadyExists(false)
                    setIsPhoneValid(true)
                }
            }
            console.log(checkPartnerAccountExistanceUsingCdRespo)
        }

    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <AccountExistanceValidatorViaContactDetails isOpen={hasAlreadyExists} storesData={existingResponse} />
            <div className={styles.mainHeader}>
                <div className={styles.goToBack}>
                    <Link to="/manage-partners-account/search"><ChevronLeft /></Link>
                </div>
                <div className={styles.headerTitle}>
                    Create A New Account For Salon
                </div>
                <div className={styles.rightTitle}>

                </div>
            </div>
            <div className={styles.mainBody}>
                {vendorIdentifier === undefined || vendorIdentifier === '' ?
                    <div className={styles.leftColWrapper}>
                        <div className={styles.createForm}>
                            <div className={styles.cardHeader}>
                                Set preliminary account details
                            </div>
                            <div className={styles.fieldsWrapper}>
                                <div className={styles.eachFieldWrapper}>
                                    <input
                                        placeholder="Enter the salon name..."
                                        autoFocus={true}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        value={companyName}
                                    />
                                    {isCompanyNameValid === true ? <div className={styles.fieldIcon}><IoIosCheckmarkCircle color="green" />&nbsp;</div> : <div className={styles.fieldIcon}><IoIosCloseCircle color="red" />&nbsp;</div>}
                                </div>
                                <div className={styles.eachFieldWrapper}>
                                    <input placeholder="Enter the complete address"
                                        onChange={(e) => setAddress(e.target.value)}
                                        value={address}
                                    />
                                    {isAddressValid === true ? <div className={styles.fieldIcon}><IoIosCheckmarkCircle color="green" />&nbsp;</div> : <div className={styles.fieldIcon}><IoIosCloseCircle color="red" />&nbsp;</div>}
                                </div>
                                <div className={styles.threeInOne}>
                                    <div className={styles.eachFieldWrapper}>
                                        <input
                                            placeholder="Enter Phone number"
                                            type="number"
                                            onChange={(e) => setPhone(e.target.value)}
                                            onBlur={() => isValidMobileNumber(phone)}
                                            // onChange={(e) => setPhone(isValidMobileNumber(e.target.value))}
                                            value={phone}

                                        />
                                        {isPhoneValid === true ? <div className={styles.fieldIcon}><IoIosCheckmarkCircle color="green" />&nbsp;</div> : <div className={styles.fieldIcon}><IoIosCloseCircle color="red" />&nbsp;</div>}
                                    </div>
                                    <div className={styles.eachFieldWrapper}>
                                        <input placeholder="Enter E-mail"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                        {isEmailValid === true ? <div className={styles.fieldIcon}><IoIosCheckmarkCircle color="green" />&nbsp;</div> : <div className={styles.fieldIcon}><IoIosCloseCircle color="red" />&nbsp;</div>}
                                    </div>
                                    <div className={styles.eachFieldWrapper}>
                                        <input placeholder="Enter pincode"
                                            type="number"
                                            maxLength="6"
                                            onChange={(e) => setPincode(e.target.value)}
                                            value={pincode}
                                        />
                                        {isPincodeValid === true ? <div className={styles.fieldIcon}><IoIosCheckmarkCircle color="green" />&nbsp;</div> : <div className={styles.fieldIcon}><IoIosCloseCircle color="red" />&nbsp;</div>}
                                    </div>
                                </div>
                                <div className={styles.actionBtnWrapper}>
                                    {isPrimaryValidated === true ?
                                        <div>
                                            {isCreatingAccount === true ?
                                                <button className={styles.creatingNowBtn} >Creating account now...</button>
                                                :
                                                <button className={styles.createNowBtnActive} onClick={() => createPartnersNewAccount()} >Create</button>
                                            }
                                        </div>
                                        :
                                        <button className={styles.createNowBtn} onClick={() => toast("Please fill all the details!")} >Create</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {isCollectingAccountDetails === true ?
                            <div className={styles.leftColWrapper}>
                                <div className={styles.createForm}>
                                    <div className={styles.cardHeader}>
                                        Loading account details...
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={styles.leftColWrapper}>
                                <div className={styles.createForm}>
                                    <div className={styles.cardHeader}>
                                        Details
                                    </div>
                                    <div className={styles.fieldsWrapper}>
                                        <div className={styles.eachFieldWrapper}>
                                            <input
                                                readOnly={true}
                                                placeholder="Enter the salon name..."
                                                value={accountDetails?.company_name}
                                            />
                                        </div>
                                        <div className={styles.eachFieldWrapper}>
                                            <input
                                                readOnly={true}
                                                value={accountDetails?.address}
                                            />
                                        </div>

                                        <div className={styles.threeInOne}>
                                            <div className={styles.eachFieldWrapper}>
                                                <input
                                                    readOnly={true}
                                                    value={accountDetails?.vender_contact}
                                                />
                                            </div>
                                            <div className={styles.eachFieldWrapper}>
                                                <input
                                                    readOnly={true}
                                                    value={accountDetails?.vender_email}
                                                />
                                            </div>
                                            <div className={styles.eachFieldWrapper}>
                                                <input
                                                    readOnly={true}
                                                    value={accountDetails?.pincode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        Authorized personnel
                                    </div>
                                    <div>
                                        {authPersonnelData.map((item, index) => (
                                            <div key={index} className={styles.eachAuthPerson}>
                                                <div>
                                                    {item.firstName} &nbsp;
                                                    {item.designation}
                                                </div>
                                                <div>
                                                    <div>
                                                        {item.phone}
                                                    </div>
                                                    <div>
                                                        {item.whatsApp}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }

                {pdFiled &&
                    <AddAuthPersonnelForPartnersAccount vendorIdentifier={vendorIdentifier}
                        toast={toast}
                        getAuthPersonnelDetails={getAuthPersonnelDetails}
                    />
                }
            </div>
        </div >
    )
}

export default CreateNewAccount