import { StylesContext } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import CAEJShowJobDescription from '../CreateApplications/CAEJShowJobDescription'
import QuickPost from '../Jobs/PostJobs/QuickPost'
import styles from './MseJobs.module.css'
import MseJobsEachRow from './MseJobsEachRow'
import MseRepostJobConfirmation from './MseRepostJobConfirmation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MseJobs = (props) => {
    const { storeIdentifier } = props
    const authCtx = useContext(AuthContext)

    const [postedJobs, setPostedJobs] = useState([])

    const [isQuickPostActive, setIsQuickPostActive] = useState(false)
    const quickPostHandler = () => setIsQuickPostActive(false)

    const [isOpen, setIsOpen] = useState(false)
    const [jd, setJd] = useState(null)
    const viewJdHandler = (k) => {
        setIsOpen(true)
        setJd(k)
    }
    const closeHandler = () => setIsOpen(false)

    const [isReposting, setIsReposting] = useState(false)
    const [repostingItem, setRepostingItem] = useState(null)
    const handleRepostingJob = (k, i) => {
        setIsReposting(k)
        setRepostingItem(i)
    }
    

    const getAllJobsForEachStore = async () => {
        const getAllJobsForEachStoreResponse = await fetch(BASE_URL_API + "/getAllJobsForEachStore",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getAllJobsForEachStoreResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllJobsForEachStoreRespo = await getAllJobsForEachStoreResponse.json()
            if (getAllJobsForEachStoreRespo.status === "success") {
                setPostedJobs(getAllJobsForEachStoreRespo.response)
            } else {
                if (getAllJobsForEachStoreRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setPostedJobs([])
                }
            }
            console.log(getAllJobsForEachStoreRespo)
        }
    }


    useEffect(() => {
        getAllJobsForEachStore()
    }, [storeIdentifier])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <CAEJShowJobDescription isOpen={isOpen} jd={jd} closeHandler={closeHandler} />
            <MseRepostJobConfirmation isOpen={isReposting} item={repostingItem} handleRepostingJob={handleRepostingJob} onSuccess={getAllJobsForEachStore} />
            {isQuickPostActive && <QuickPost isOpen={isQuickPostActive} quickPostHandler={quickPostHandler} storeIdentifier={storeIdentifier} onSuccess={getAllJobsForEachStore} />}
            <div className={styles.mainContainer}>
                <div className={styles.quickPost}>
                    <button onClick={() => setIsQuickPostActive(true)}>Quick Post</button>
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.title}>
                            Job Title
                        </div>
                        <div className={styles.salaryRange}>
                            Salary Range
                        </div>
                        <div className={styles.currentStatus}>
                            Status
                        </div>
                        <div className={styles.postedOn}>
                            Posted On
                        </div>
                        <div className={styles.closingSummary}>
                            Closing Summary
                        </div>
                        <div className={styles.reasonOfClosing}>
                            Reason of Closing
                        </div>
                        <div className={styles.actions}>
                            Actions
                        </div>
                    </div>
                    <div className={styles.tableBody}>
                        {postedJobs?.map((item, index) => (
                            <MseJobsEachRow key={index} count={index} item={item} viewJdHandler={viewJdHandler} handleRepostingJob={handleRepostingJob} />
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MseJobs