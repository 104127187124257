import React, { useContext, useEffect, useState } from 'react'
import { FaCheck, FaChevronDown } from 'react-icons/fa'
import CAEJWMWorkDomain from './FilterSelectors/CAEJWMWorkDomain'
import styles from './CAEJWorkMode.module.css'
import CAEJWMGender from './FilterSelectors/CAEJWMGender'
import CAEJWMAgeRange from './FilterSelectors/CAEJWMAgeRange'
import CAEJWSalaryRange from './FilterSelectors/CAEJWSalaryRange'
import CAEJWMMaritalStatus from './FilterSelectors/CAEJWMMaritalStatus'
import CAEJWMContactStatus from './FilterSelectors/CAEJWMContactStatus'
import CAEJWMSkills from './FilterSelectors/CAEJWMSkills'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import CAEJWMDataCollectionType from './FilterSelectors/CAEJWMDataCollectionType'
import CAEJWMEachProfile from './EachProfile/CAEJWMEachProfile'
import CAEJWMRegistrationPeriod from './FilterSelectors/CAEJWMRegistrationPeriod'
import CAEJWMMiniResume from './EachProfile/CAEJWMMiniResume'

const CAEJWorkMode = props => {
    const { jobIdentifier } = props
    const authCtx = useContext(AuthContext)

    const [isLoadingData, setIsLoadingData] = useState(false)
    const [profiles, setProfiles] = useState([])
    const [domainsData, setDomainsData] = useState([])
    const [selectedDomain, setSelectedDomain] = useState([])
    const [selectedGender, setSelectedGender] = useState([])
    const [minAge, setMinAge] = useState('14')
    const [maxAge, setMaxAge] = useState('50')
    const [minSalary, setMinSalary] = useState('')
    const [maxSalary, setMaxSalary] = useState('')
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([])
    const [registrationPeriod, setRegistrationPeriod] = useState('Anytime')

    const [profileCollectionType, setProfileCollectionType] = useState('Distance')
    const [isContactStatusActive, setIsContactStatusActive] = useState(false)
    const [excludeBeenContacted, setExcludeBeenContacted] = useState('P7D')
    const [excludeBeenContactedJob, setExcludeBeenContactedJob] = useState("This")
    const [isRegistrationPeriodActive, setIsRegistrationPeriodActive] = useState(false)

    const [activeProfile, setActiveProfile] = useState(null)

    const domainToggler = (k) => {
        if (selectedDomain?.includes(k)) {
            setSelectedDomain(selectedDomain.filter((item) => item !== k))
        } else {
            if (selectedDomain.length < 5) {
                setSelectedDomain([...selectedDomain, k])
            } else {
                alert("You can only select 5 domains!")
            }
        }
    }

    const genderToggler = (k) => {
        if (selectedGender?.includes(k)) {
            setSelectedGender(selectedGender.filter((item) => item !== k))
        } else {
            setSelectedGender([...selectedGender, k])
        }
    }

    const ageHandler = (type, val) => {
        if (type === "minAge") { setMinAge(val) }
        if (type === "maxAge") { setMaxAge(val) }
    }

    const salaryHandler = (type, val) => {
        if (type === "minSalary") { setMinSalary(val) }
        if (type === "maxSalary") { setMaxSalary(val) }
    }

    const maritalStatusToggler = (k) => {
        if (selectedMaritalStatus?.includes(k)) {
            setSelectedMaritalStatus(selectedMaritalStatus.filter((item) => item !== k))
        } else {
            setSelectedMaritalStatus([...selectedMaritalStatus, k])
        }
    }

    const profileCollectionToggler = (k) => {
        setProfileCollectionType(k)
    }

    const contactStatusToggler = () => setIsContactStatusActive(!isContactStatusActive)
    const excludeBeenContactedHandler = (k) => setExcludeBeenContacted(k)
    const excludeBeenContactedJobHandler = (k) => setExcludeBeenContactedJob(k)
    const registrationPeriodToggler = () => setIsRegistrationPeriodActive(!isRegistrationPeriodActive)
    const registrationPeriodHandler = (k) => setRegistrationPeriod(k)
    const activeProfileToggler = (k) => setActiveProfile(k)

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json()
            if (getCategoryOptionsRespo.status === "success") {
                setDomainsData(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setDomainsData([])
                }
            }

            console.log(getCategoryOptionsRespo)
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])


    const getDatabaseSearch = async () => {
        setIsLoadingData(true)
        const getDatabaseSearchResponse = await fetch(BASE_URL_API + "/getDatabaseSearch",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier,
                    minSalary: minSalary,
                    maxSalary: maxSalary,
                    selectedGender: selectedGender,
                    selectedDomain: selectedDomain,
                    maritalStatus: selectedMaritalStatus,
                    minAge: minAge,
                    maxAge: maxAge,
                    isContactStatusActive: isContactStatusActive,
                    inLastDuration: excludeBeenContacted,
                    excludeBeenContactedJob: excludeBeenContactedJob,
                    isRegistrationPeriodActive: isRegistrationPeriodActive,
                    registrationPeriod: registrationPeriod
                })
            });

        if (!getDatabaseSearchResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getDatabaseSearchRespo = await getDatabaseSearchResponse.json()
            if (getDatabaseSearchRespo.status === "success") {
                setProfiles(getDatabaseSearchRespo.response)
            } else {
                if (getDatabaseSearchRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setProfiles([])
                }
            }
            console.log(getDatabaseSearchRespo)
        }
        setIsLoadingData(false)
    }





    return (
        <div className={styles.bodyContentWrapper}>
            <div className={styles.leftColWrapper}>
                <div className={styles.leftColContainer}>
                    <div className={styles.filterWrapper}>
                        <div className={styles.filterBody}>
                            <CAEJWMWorkDomain domainsData={domainsData} selectedDomain={selectedDomain} domainToggler={domainToggler} />
                            <CAEJWMDataCollectionType profileCollectionType={profileCollectionType} profileCollectionToggler={profileCollectionToggler} />
                            <CAEJWMGender selectedGender={selectedGender} genderToggler={genderToggler} />
                            <CAEJWMAgeRange minAge={minAge} maxAge={maxAge} ageHandler={ageHandler} />
                            <CAEJWSalaryRange minSalary={minSalary} maxSalary={maxSalary} salaryHandler={salaryHandler} />
                            <CAEJWMMaritalStatus selectedMaritalStatus={selectedMaritalStatus} maritalStatusToggler={maritalStatusToggler} />
                            <CAEJWMContactStatus isContactStatusActive={isContactStatusActive} contactStatusToggler={contactStatusToggler} excludeBeenContacted={excludeBeenContacted} excludeBeenContactedHandler={excludeBeenContactedHandler} excludeBeenContactedJob={excludeBeenContactedJob} excludeBeenContactedJobHandler={excludeBeenContactedJobHandler} />
                            {/* <CAEJWMRegistrationPeriod isRegistrationPeriodActive={isRegistrationPeriodActive} registrationPeriodToggler={registrationPeriodToggler} registrationPeriod={registrationPeriod} registrationPeriodHandler={registrationPeriodHandler} /> */}
                            {/* <CAEJWMSkills /> */}
                        </div>
                        <div className={styles.filterfooter}>
                            <button onClick={() => getDatabaseSearch()}>Get Data</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.rightColWrapper}>
                <div className={styles.profileResultsContainer}>
                    <div className={styles.rightColHeader}>
                        <div className={styles.rightColHeader}>
                            {profiles?.length} result(s) found!
                        </div>


                    </div>
                    <div className={styles.rightColBody}>
                        <div>
                            
                        </div>
                        

                        <div>
                            {isLoadingData === true ?
                                <div>
                                    Collecting data...
                                </div>
                                :
                                <div>
                                    {profiles?.map((item, index) => (
                                        <CAEJWMEachProfile key={index} item={item} activeProfile={activeProfile} activeProfileToggler={activeProfileToggler} />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.miniResumeWrapper}>
                    {activeProfile!==null && <CAEJWMMiniResume stylistIdentifier={activeProfile?.stylist_id} jobIdentifier={jobIdentifier}  activeProfile={activeProfile} />}
                </div>

            </div>

        </div>
    )
}

export default CAEJWorkMode