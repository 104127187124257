import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from './QPSelectProfile.module.css'

const QPSelectProfile = (props) => {
    const { selectedCategory, categoryOptionsData, selectCategoryHandler } = props
    const authCtx = useContext(AuthContext)
    const [searchKey, setSearchKey] = useState('')
    const [filteredResults, setFilteredResults] = useState([])

    // console.log("categoryOptionsData : ", categoryOptionsData)
    // console.log("filteredResults : ", filteredResults)

    const searchHandler = () => {
        if (searchKey !== '') {
            setFilteredResults(categoryOptionsData.filter((item) => item?.category_name?.toLowerCase().includes(searchKey.toLowerCase())))
        } else {
            setFilteredResults(categoryOptionsData);
        }
    }

    useEffect(() => {
        searchHandler()
    }, [searchKey])

    useEffect(() => {
        setFilteredResults(categoryOptionsData)
    }, [categoryOptionsData])
    


    // console.log("selectedCategory", selectedCategory)

    return (
        <div>
            <div>
                <div className={styles.sectionHeader}>
                    Please select a category to post a job!
                </div>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.searchBarWrapper}>
                            <input
                                autoFocus={true}
                                placeholder="Search for any profile... "
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </div>
                        <div className={styles.optionsWrapper}>
                            {filteredResults?.map((item, index) => (
                                <button className={selectedCategory?.ID===item?.ID ? styles.eachOptionSelected : styles.eachOption} 
                                    key={index}
                                    onClick={() => selectCategoryHandler(item)}
                                >
                                    {item?.category_name}
                                    {/* {JSON.stringify(item)} */}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QPSelectProfile