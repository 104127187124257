import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CampaignSettings.module.css'


const CampaignSettings = (props) => {
    const { campaignIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [campaignSettings, setCampaignSettings] = useState(null)

    const getCampaignSettings = async () => {
        const getCampaignSettingsResponse = await fetch(BASE_URL_API + "/getCampaignSettings",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    campaignIdentifier: campaignIdentifier
                })
            });

        if (!getCampaignSettingsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCampaignSettingsRespo = await getCampaignSettingsResponse.json()
            if (getCampaignSettingsRespo.status === "success") {
                setCampaignSettings(getCampaignSettingsRespo.response)
            } else {
                if (getCampaignSettingsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCampaignSettings(null)
                }
            }
            console.log(getCampaignSettingsRespo)
        }
    }

    useEffect(() => {
        // getCampaignSettings()
    }, [campaignIdentifier])




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <div>
                        <div>
                            <div>Campaign Name</div>
                            <div>
                                <input />
                            </div>
                        </div>

                        <div>
                            <div>Campaign Description</div>
                            <div>
                                <textarea />
                            </div>
                        </div>
                    </div>


                    <div>
                        <div>

                        </div>
                        <div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CampaignSettings