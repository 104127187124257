import React from 'react'
import styles from './CAEJShowJobDescription.module.css'

const CAEJShowJobDescription = props => {
    const { isOpen, jd, closeHandler } = props



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.headerWrapper}>
                        <button className={styles.closeBtn} onClick={() => closeHandler()} >Close</button>
                    </div>
                    <div className={styles.bodyWrapper}>
                        <div className={styles.fixedWrapper}>
                            <div className={styles.eachRequirement}>
                                <div className={styles.caption}>Gender</div>
                                <div className={styles.value}>{jd?.gender}</div>
                            </div>
                            <div className={styles.eachRequirement}>
                                <div className={styles.caption}>Experience</div>
                                <div className={styles.value}>{jd?.experience}</div>
                            </div>
                            <div className={styles.eachRequirement}>
                                <div className={styles.caption}>
                                    Education Qualifications
                                </div>
                                <div className={styles.value}>
                                    {jd?.education_qualifications}
                                </div>
                            </div>
                            <div className={styles.eachRequirement}>
                                <div className={styles.caption}>
                                    Accomodation
                                </div>
                                <div className={styles.value}>
                                    {jd?.accomodation}
                                </div>
                            </div>
                            <div className={styles.eachRequirementCoupled}>
                                <div>
                                    <div className={styles.caption}>
                                        Salary Min
                                    </div>
                                    <div className={styles.value}>
                                        {jd?.salary_min}
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.caption}>
                                        Salary Max
                                    </div>
                                    <div className={styles.value}>
                                        {jd?.salary_max}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.skillsWrapper}>
                            {jd?.skill?.split(",")?.map((itemSkill, indexSkill) => (
                                <div key={indexSkill} className={styles.eachSkill}>{itemSkill}</div>
                            ))}
                        </div>
                        <div className={styles.jobDescription}>
                            <div dangerouslySetInnerHTML={{ __html : jd?.description}}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CAEJShowJobDescription