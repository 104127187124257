import React, { useState } from 'react'
import styles from '../Admin/AdminNavigator.module.css'
import { MdHome, MdHomeRepairService, MdLogout, MdPages, MdSupervisorAccount } from 'react-icons/md'
import { Link } from 'react-router-dom'

const AdminNavigator = () => {
    const [isOpen, setIsOpen] = useState(false)

    window.onclick = function (event) {
        if (event.target.id === "AdminNavBox") {
            setIsOpen(false)
            // document.getElementById("AdminNavBox").style.animation = "slideRightToLeft .1s forwards";
        }

        // console.log(event.target)
    }

    return (
        <div className={styles.mainWrapper}>
            {isOpen === false ?
                <div className={styles.draggerWrapper} onClick={() => setIsOpen(true)} >

                </div>
                :
                <div className={styles.mainMenuWrapper}

                >
                    <div className={styles.mainMenuContainer}
                    >
                        <div className={styles.menuLeftTower}>
                            <div className={styles.menuColWrapper}>
                                <div className={styles.menuColHead}>
                                    <Link to="/" className={styles.eachOptionWrapper}>
                                        <div>
                                            <MdHome color="white" size={24} />
                                        </div>
                                        <div className={styles.iconCaption}>
                                            React
                                        </div>
                                    </Link>
                                </div>
                                <div className={styles.menuColBody}>
                                    <Link to="/control/home" className={styles.eachOptionWrapper}>
                                        <div>
                                            <MdHomeRepairService color="white" size={24} />
                                        </div>
                                        <div className={styles.iconCaption}>
                                            Admin Home
                                        </div>

                                    </Link>


                                    <Link to="/control/pages" className={styles.eachOptionWrapper}>
                                        <div>
                                            <MdPages color="white" size={24} />
                                        </div>
                                        <div className={styles.iconCaption}>
                                            Manage Pages
                                        </div>

                                    </Link>

                                    <Link to="/control/personnel" className={styles.eachOptionWrapper}>
                                        <div>
                                            <MdSupervisorAccount color="white" size={24} />
                                        </div>
                                        <div className={styles.iconCaption}>
                                            Manage Executives
                                        </div>
                                    </Link>


                                </div>
                                <div className={styles.menuColFooter}>
                                    <MdLogout color="white" size={22} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.menuRightTower}
                            id="AdminNavBox"
                        >
                            {/* hey */}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminNavigator