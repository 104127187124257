import React, { useContext, useEffect, useState } from 'react'
import PartnersIndividualLeadsDetails from '../../Components/Partners/PartnersIndividualLeadsDetails'
import PartnersLeadHeader from '../../Components/Partners/PartnersLeadHeader'
import PartnersLeadListView from '../../Components/Partners/PartnersLeadListView'
import PincodeHolder from '../../Components/Partners/PincodeHolder'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Partners/PartnersLeads.module.css'

const PartnersLeads = () => {
    const authCtx = useContext(AuthContext)
    const [sd, setSd] = useState([])
    const sdHandler = (k) => setSd(k)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <PartnersLeadHeader sdHandler={sdHandler} />
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.leftCol}>
                        <PartnersLeadListView sd={sd} />
                    </div>
                    <div className={styles.rightCol}>
                        <PartnersIndividualLeadsDetails />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnersLeads