import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Partners/PartnersLeadHeader.module.css'
import PincodeHolder from './PincodeHolder'

const PartnersLeadHeader = props => {
    const authCtx = useContext(AuthContext)
    const { sdHandler } = props
    const [io, setIo] = useState(false)
    const aio = () => setIo(!io)
    const [pc, setPc] = useState([])
    const pcHandler = (l) => {
        setPc(pc.includes(l) ? pc.filter((k) => k !== l) : [...pc, l]);
    }

    const csd = async () => {
        const csdR = await fetch(BASE_URL_API + "/getPartnersLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    pc: pc
                })
            });

        if (!csdR.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const csdRe = await csdR.json();
            if (csdRe.status === "success") {
                sdHandler(csdRe.response)
            } else {
                if (csdRe.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    sdHandler([])
                    console.log(csdRe)
                }
            }
        }
    }

    useEffect(() => {
        if (pc.length > 0) {
            csd()
        }
    }, [pc])

    return (
        <div>
            <PincodeHolder io={io} aio={aio} pcHandler={pcHandler} pc={pc} />
            <div>
                <div>
                    <button onClick={() => setIo(!io)} >Choose Pincode</button>
                    {pc.map((item, index) => (
                        <button key={index} onClick={() => pcHandler(item)} >
                            {item}
                        </button>
                    ))}
                </div>
            </div>
            <div>
                {/* More */}
            </div>
        </div>
    )
}

export default PartnersLeadHeader