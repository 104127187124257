import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './QPSkills.module.css'

const QPSkills = (props) => {
    const { selectedCategory, skillOptions, skills, skillsHandler, bulkSkillSelectorHandler, currentSlideHandler } = props
    const authCtx = useContext(AuthContext)
    const [visibleSkillsToMap, setVisibleSkillsToMap] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const [skillType, setSkillType] = useState('0') // MALE, FEMALE



    const visibleSkillsHandler = async () => {
        // Remove already selected items
        let filteredSkills = skillOptions.filter(skill =>
            !skills.some(selectedSkill =>
                selectedSkill?.ID === skill?.ID
            )
        );

        // If searchKey is provided and not blank, filter based on searchKey
        let visibleSkills;
        if (searchKey && searchKey.trim()) {
            visibleSkills = filteredSkills.filter(skill =>
                skill.skill.toLowerCase().includes(searchKey.toLowerCase()) ||
                skill.category.toLowerCase().includes(searchKey.toLowerCase())
            );
        } else {
            // If searchKey is blank, return all filtered skills
            visibleSkills = filteredSkills;
        }

        /// let's filter on the basis of skill for gender
        if(skillType!=='') {
            setVisibleSkillsToMap(visibleSkills.filter((item) => item?.for_gender===skillType))
        } else {
            setVisibleSkillsToMap(visibleSkills)
        }
        
        // return visibleSkills;
    }
    


    useEffect(() => {
        // console.log("Hitting it..")
        visibleSkillsHandler()
    }, [skills, skillOptions, searchKey, skillType])
    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.optionTitle}>
                        Skills - <span className={styles.smallTitleTxt}>We have added few skills in the upper green box based on the details you have filled, you are allowed to add/remove skills as per your requirements.</span>
                        <button className={styles.nextBtn} onClick={() => currentSlideHandler('Preview')}>Next</button>
                    </div>
                    <div className={styles.optionsWrapper}>
                        <div className={styles.selectedSkillsWrapper}>
                            {skills?.map((item, index) => (
                                <button key={index} className={styles.eachSkill}
                                    onClick={() => skillsHandler(item)}
                                    style={{backgroundColor : `${item?.for_gender==='1' ? `rgb(131, 191, 240)`: `${item?.for_gender==='2' ? `#da1884`: `salmon`}` }`}}
                                >
                                    {item?.skill}
                                </button>
                            ))}

                        </div>
                        <div className={styles.skillsWrapper}>
                            <div className={styles.skillsHeaderWrapper}>
                                <div className={styles.searchInput}>
                                    <input placeholder="Search for skill" autoFocus value={searchKey} onChange={(e) => setSearchKey(e.target.value)} /> 
                                    <span className={styles.showingResultsTxt}>{visibleSkillsToMap.length} results available</span>
                                </div>
                                <div>
                                    <button onClick={() => setSkillType('')} >All</button>
                                    <button onClick={() => setSkillType('2')} >Female</button>
                                    <button onClick={() => setSkillType('1')} >Male</button>
                                    <button onClick={() => setSkillType('0')} >Generic</button>
                                </div>
                            </div>
                            <div className={styles.skillsBodyWrapper}>
                                {visibleSkillsToMap?.map((item, index) => (
                                    <button key={index} className={styles.eachSkill}
                                        onClick={() => skillsHandler(item)}
                                        title={item?.category}
                                    >
                                        {item?.skill} - ({item?.for_gender})
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.nextBtnWrapper}>
                    <button onClick={() => currentSlideHandler('Preview')}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default QPSkills