import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAge, getHumanReadableExperienceDuration, humanReadableTimePassed } from '../../../Helper/helpers'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import PREachChat from '../../Jobs/ProfileRemarks/PREachChat'
import CAEJWMCandidatureStatus from './CAEJWMCandidatureStatus'
import styles from './CAEJWMMiniResume.module.css'

const CAEJWMMiniResume = (props) => {
    const authCtx = useContext(AuthContext)
    const { stylistIdentifier, activeProfile, jobIdentifier } = props
    const [miniResume, setMiniResume] = useState([])
    const [isGettingResume, setIsGettingResume] = useState(false)
    const [isCandidateStatusOpen, setIsCandidateStatusOpen] = useState(false)
    const candidatureStatusClosureHandler = () => setIsCandidateStatusOpen(false)

    const [displayStatus, setDisplayStatus] = useState('CONVERSATIONS') // RESUME
    const [remarks, setRemarks] = useState([])

    const getMiniResume = async () => {
        setIsGettingResume(true)
        const getMiniResumeResponse = await fetch(BASE_URL_API + "/getMiniResume",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getMiniResumeResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getMiniResumeRespo = await getMiniResumeResponse.json()
            if (getMiniResumeRespo.status === "success") {
                setMiniResume(getMiniResumeRespo.response)
            } else {
                if (getMiniResumeRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getMiniResumeRespo)
        }
        setIsGettingResume(false)
    }

    useEffect(() => {
        if(displayStatus==='RESUME') {
            getMiniResume()
        } else {
            getProfileRemarks()
        }
    }, [stylistIdentifier, displayStatus])

    const getProfileRemarks = async (k) => {
        // console.log("re-called this ")
        // let p = ['dil', 'diyan', 'gallan', 's'];
        // setRemarks(remarks => [...p, ...remarks])
        const getProfileRemarksResponse = await fetch(BASE_URL_API+"/getProfileRemarks",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                stylist_id : stylistIdentifier
            })
        });

        if(!getProfileRemarksResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getProfileRemarksRespo = await getProfileRemarksResponse.json();
            if(getProfileRemarksRespo.status==="success") {
                setRemarks(getProfileRemarksRespo.response);
            } else {
                if(getProfileRemarksRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setRemarks([])
                }
                console.log(getProfileRemarksRespo)
            }
        }
    }



    return (
        <div>
            {isCandidateStatusOpen && <CAEJWMCandidatureStatus isOpen={isCandidateStatusOpen} activeProfile={activeProfile} onClose={candidatureStatusClosureHandler} stylistIdentifier={stylistIdentifier} jobIdentifier={jobIdentifier} />}

            {displayStatus === 'CONVERSATIONS' ?
                <div className={styles.contentWrapper}>
                    <div className={styles.resumeSummaryWrapper}>
                        <div className={styles.topRow}>
                            <span className={styles.stylistName}>{activeProfile?.stylist_name}</span>,
                            <span className={styles.birthdate}>&nbsp;{getAge(activeProfile?.stylist_birthdate)} years old,</span>
                            <span className={styles.maritalStatus}>&nbsp;{activeProfile?.maritalStatus},</span>
                            <span className={styles.gender}>&nbsp;{activeProfile?.stylist_gender}</span>
                        </div>
                        <div className={styles.currentLocation}>
                            {activeProfile?.currentLocation}
                        </div>
                        <div className={styles.domainWrapper}>
                            <div className={styles.eachWorkDomain}>{activeProfile?.primaryWorkDomain}</div>
                            <div className={styles.eachWorkDomain}>{activeProfile?.secondaryWorkDomain}</div>
                            <div className={styles.eachWorkDomain}>{activeProfile?.tertiaryWorkDomain}</div>
                        </div>
                        <div className={styles.moreBoxes}>
                            <div>
                                ₹{activeProfile?.minimumSalaryExpectation}
                            </div>
                            <div>
                                {activeProfile?.registred_on}
                            </div>
                        </div>

                    </div>

                    <div className={styles.remarksWrapper}>
                        <div className={styles.remarksHeader}>
                            Recent conversations..
                        </div>

                        <div className={styles.remarksContainer}>
                            <PREachChat
                                remarks={remarks}
                                getProfileRemarks={getProfileRemarks}
                            />
                        </div>

                    </div>
                </div>
                :
                <div className={styles.contentWrapper}>
                    <div>
                        <div className={styles.topRow}>
                            <span className={styles.stylistName}>{activeProfile?.stylist_name}</span>,
                            <span className={styles.birthdate}>&nbsp;{getAge(activeProfile?.stylist_birthdate)} years old,</span>
                            <span className={styles.maritalStatus}>&nbsp;{activeProfile?.maritalStatus},</span>
                            <span className={styles.gender}>&nbsp;{activeProfile?.stylist_gender}</span>
                        </div>
                        <div className={styles.currentLocation}>
                            {activeProfile?.currentLocation}
                        </div>
                        <div className={styles.domainWrapper}>
                            <div className={styles.eachWorkDomain}>{activeProfile?.primaryWorkDomain}</div>
                            <div className={styles.eachWorkDomain}>{activeProfile?.secondaryWorkDomain}</div>
                            <div className={styles.eachWorkDomain}>{activeProfile?.tertiaryWorkDomain}</div>
                        </div>
                        <div className={styles.moreBoxes}>
                            <div>
                                ₹{activeProfile?.minimumSalaryExpectation}
                            </div>
                            <div>
                                {activeProfile?.registred_on}
                            </div>
                        </div>

                    </div>
                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentHeader}>
                                Skills
                            </div>
                            <div>
                                {isGettingResume === true ?
                                    <div>
                                        Please wait...
                                    </div>
                                    :
                                    <div className={styles.allSkillsWrapper}>
                                        {miniResume?.skills?.map((item, index) => (
                                            <span className={item?.brand_id === '1' ? styles.eachSkillMale : styles.eachSkillFemale} key={index}>{item?.skill}</span>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentHeader}>
                                Experience
                            </div>
                            <div>
                                {isGettingResume === true ?
                                    <div>
                                        Please wait...
                                    </div>
                                    :
                                    <div className={styles.experienceWrapper}>
                                        {miniResume?.experiences?.map((item, index) => (
                                            <div key={index} className={styles.eachExperience}>
                                                <div className={styles.frowWrapper}>
                                                    <div className={styles.storeName}>
                                                        {item?.salon_name}
                                                    </div>
                                                    <div className={styles.salaryWrapper}>
                                                        {item?.monthlySalary} - {item?.modeOfSalary}
                                                    </div>
                                                </div>
                                                <div className={styles.storeAddress}>
                                                    {item?.address}
                                                </div>
                                                <div className={styles.timeFrameWrapper}>
                                                    <div>
                                                        From {item?.start_date?.substring(0, 10)}
                                                    </div>
                                                    <div>
                                                        To {item?.end_date?.substring(0, 10)}
                                                    </div>
                                                    <div>
                                                        {getHumanReadableExperienceDuration(item?.start_date, item?.end_date)}
                                                    </div>
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.event_description}
                                                </div>
                                                <div className={styles.reasonOfLeaving}>
                                                    {item?.reasonOfLeaving}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentHeader}>
                                Certificates & Diplomas
                            </div>

                            <div>
                                {isGettingResume === true ?
                                    <div>
                                        Please wait...
                                    </div>
                                    :
                                    <div className={styles.experienceWrapper}>
                                        {miniResume?.certifications?.map((item, index) => (
                                            <div key={index} className={styles.eachCertiWrapper}>
                                                <div className={styles.courseSummaryLine}>
                                                    {item?.duration} {item?.programMode} {item?.programType}, completed on {item?.end_date}
                                                </div>
                                                <div className={styles.title}>
                                                    {item?.course_title}
                                                </div>
                                                <div>
                                                    <div>
                                                        {item?.course_academy}
                                                    </div>
                                                    <div className={styles.storeAddress}>
                                                        {item?.academy_address}
                                                    </div>
                                                </div>
                                                <div>
                                                    Fee Paid : {item?.fee}
                                                </div>
                                                <div>
                                                    {item?.description}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentHeader}>
                                Language Spoken
                            </div>
                            <div>
                                {isGettingResume === true ?
                                    <div>
                                        Please wait...
                                    </div>
                                    :
                                    <div className={styles.experienceWrapper}>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentHeader}>
                                Education
                            </div>
                            <div>
                                {isGettingResume === true ?
                                    <div>
                                        Please wait...
                                    </div>
                                    :
                                    <div className={styles.experienceWrapper}>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className={styles.actionsWrapper}>
                <div>
                    {displayStatus === 'RESUME' ?
                        <button onClick={() => setDisplayStatus('CONVERSATIONS')} >Show Recent Convo</button>
                        :
                        <button onClick={() => setDisplayStatus('RESUME')} >Show Mini Resume</button>
                    }
                </div>
                <div>
                    <button onClick={() => setIsCandidateStatusOpen(true)}>View Details</button>
                    <a target="_blank" href={`/manage-users/grow/${stylistIdentifier}`}>View Profile</a>
                </div>
            </div>
        </div>
    )
}

export default CAEJWMMiniResume