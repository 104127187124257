import React, { useContext, useEffect, useState } from 'react'
import AllotedJobEachRow from '../../Components/JobsAllocator/AllotedJobEachRow'
import { executivesData } from '../../Helper/executivesData'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AllocatedJobs.module.css'

const AllocatedJobs = () => {
    const authCtx = useContext(AuthContext)
    const [jobsData, setJobsData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [sortBy, setSortBy] = useState('Newest First')

    const [openingStatus, setOpeningStatus] = useState(null)
    const [isPaidHiring, setIsPaidHiring] = useState(null)

    const [selectedExecutive, setSelectedExecutive] = useState(authCtx.userIdentifier)

    const getAllotedJobsForTasks = async () => {
        const getAllotedJobsForTasksResponse = await fetch(BASE_URL_API + "/getAllotedJobsForTasks",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: selectedExecutive,
                    openingStatus: openingStatus,
                    isPaidHiring: isPaidHiring,
                    sortBy: sortBy
                })
            })

        if (!getAllotedJobsForTasksResponse.ok) {
            console.log("Something went wrong : server Error")
        } else {
            const getAllotedJobsForTasksRespo = await getAllotedJobsForTasksResponse.json()
            if (getAllotedJobsForTasksRespo.status === "success") {
                setJobsData(getAllotedJobsForTasksRespo.response)
                setFilteredData(getAllotedJobsForTasksRespo.response)
            } else {
                if (getAllotedJobsForTasksRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setJobsData([])
                    setFilteredData([])
                }
            }
        }
    }

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        getAllotedJobsForTasks()
    }, [])

    useEffect(() => {
        filterData(jobsData, searchKey)
    }, [searchKey])


    useEffect(() => {
        getAllotedJobsForTasks()
    }, [openingStatus, isPaidHiring, sortBy, selectedExecutive])

    const allJobSelector = () => {
        setIsPaidHiring(null)
        setOpeningStatus('All')
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div>
                    Hey Buddy, see all your alloted jobs here!
                </div>
                {authCtx?.userIdentifier === "999964N8406" &&
                    <div>
                        <select onChange={(e) => setSelectedExecutive(e.target.value)}
                            defaultValue={authCtx.userIdentifier}
                        >
                            <option>Select Executive</option>
                            {executivesData?.map((item, index) => (
                                <option key={index} value={item?.userID} >{item?.firstName} {item?.lastName}</option>
                            ))}
                        </select>
                    </div>
                }
            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.leftColTower}>
                    </div>

                </div>
                <div className={styles.rightColWrapper}>
                    <div className={styles.rightColContainer}>
                        <div className={styles.rccHeaderWrapper}>
                            <div className={styles.rccUpperHeader}>
                                <div className={styles.jobResults}>
                                    {filteredData?.length} / {jobsData?.length} jobs shown
                                </div>
                                <div className={styles.sortBy}>
                                    <div className={styles.sortByTitle}>Sort By</div>
                                    <div>
                                        <button className={sortBy === 'Newest First' ? styles.activeBtn : styles.defBtn}
                                            onClick={() => setSortBy('Newest First')}
                                        >
                                            Newest First
                                        </button>
                                        <button className={sortBy === 'Oldest First' ? styles.activeBtn : styles.defBtn}
                                            onClick={() => setSortBy('Oldest First')}
                                        >
                                            Oldest First
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.rccLowerHeader}>
                                <div className={styles.rccLeft}>
                                    <button className={openingStatus === "All" ? styles.activeBtn : styles.defBtn} onClick={() => allJobSelector()} >All Jobs</button>
                                    <button className={openingStatus === "Open" ? styles.activeBtn : styles.defBtn} onClick={() => setOpeningStatus('Open')} >Active Jobs</button>
                                    <button className={openingStatus === "Closed" ? styles.activeBtn : styles.defBtn} onClick={() => setOpeningStatus('Closed')} >Closed Jobs</button>
                                    <button className={isPaidHiring === true ? styles.activeBtn : styles.defBtn} onClick={() => setIsPaidHiring(!isPaidHiring)} >Paid Hiring</button>
                                </div>
                                <div className={styles.rccRight}>
                                    <input placeholder="Search..." onChange={(e) => setSearchKey(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.rccBodyWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.profile}>Profile</div>
                                <div className={styles.storeDetails}>Store Details</div>
                                <div className={styles.jobStatus}>Job Status</div>
                                <div className={styles.jobStatus}>Current Status</div>
                                <div className={styles.postedOn}>Posted On</div>
                                <div className={styles.postedOn}>Alloted On</div>
                                <div className={styles.action}>Action</div>
                            </div>
                            <div className={styles.tableRow}>
                                {filteredData?.map((item, index) => (
                                    <AllotedJobEachRow item={item} count={index} key={index} />
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllocatedJobs