import React, { useContext, useEffect, useState } from 'react'
import { BsBack } from 'react-icons/bs'
import { FaBackward } from 'react-icons/fa'
import { MdArrowBack } from 'react-icons/md'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './HireCandidate.module.css'

const HireCandidate = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, hireCandidateHandler, applicationIdentifier, getApplicationStatus } = props
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [salaryOffered, setSalaryOffered] = useState('')
    const [actionRemarks, setActionRemarks] = useState('')
    const [joiningDate, setJoiningDate] = useState('')
    const [isReadyToUpload, setIsReadyToUpload] = useState(false)




    const hireCandidate = async () => {
        // console.log(applicationIdentifier, authCtx.userIdentifier, salaryOffered, actionRemarks, joiningDate)

        setIsUpdatingStatus(true)
        const updateApplicationStatusResponse = await fetch(BASE_URL_API + "/updateApplicationStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationId: applicationIdentifier,
                    actionFor: "HIRED",
                    salaryOffered: salaryOffered,
                    actionRemarks: actionRemarks,
                    joiningDate: joiningDate
                })
            });

        if (!updateApplicationStatusResponse.ok) {
            console.log("Server Error : Something went wrong, please contact admin!");
        } else {
            const updateApplicationStatusRespo = await updateApplicationStatusResponse.json();
            if (updateApplicationStatusRespo.status === "success") {
                if (updateApplicationStatusRespo.message === "success") {
                    toast.success(updateApplicationStatusRespo.message);
                    getApplicationStatus();
                    hireCandidateHandler()
                } else {
                    toast.warn(updateApplicationStatusRespo.message);
                }
            } else {
                console.log(updateApplicationStatusRespo);
            }
        }
        setIsUpdatingStatus(false)
    }


    useEffect(() => {
        if (joiningDate !== "" && actionRemarks !== "" && salaryOffered !== "") {
            setIsReadyToUpload(true)
        } else {
            setIsReadyToUpload(false)
        }
    }, [joiningDate, actionRemarks, salaryOffered])





    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.leftWrapper}>
                            <div>
                                <div className={styles.backBtn}>
                                    <button onClick={() => hireCandidateHandler()}><MdArrowBack size={28} /></button>
                                </div>
                                <div>
                                    HireCandidate
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.rightWrapperContent}>

                                {/* {applicationIdentifier} */}
                                <div className={styles.sectionWrapper}>
                                    <div className={styles.sectionTitle}>Add Remarks</div>
                                    <div className={styles.sectionInput}>
                                        <textarea rows={6} onChange={(e) => setActionRemarks(e.target.value)} ></textarea>
                                    </div>
                                </div>

                                <div className={styles.twoInRowWrapper}>
                                    <div className={styles.joiningDate}>
                                        <div className={styles.sectionTitle}>
                                            Joining Date
                                        </div>
                                        <div className={styles.sectionInput}>
                                            <input type="date" onChange={(e) => setJoiningDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className={styles.salaryOffered}>
                                        <div className={styles.sectionTitle}>Salary Offered</div>
                                        <div className={styles.sectionInput}>
                                            <input type="number" onChange={(e) => setSalaryOffered(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.termsWrapper}>
                                    Updating candidate's status to HIRE will also add experience to the candidate's profile!
                                </div>

                                {isUpdatingStatus === false &&
                                    <div className={styles.twoInRowWrapper}>
                                        <div className={styles.cancelBtn}>
                                            <button onClick={() => hireCandidateHandler()}>Cancel</button>
                                        </div>
                                        {isReadyToUpload === false ?
                                            <div className={styles.submitBtnDull}>
                                                <button onClick={() => alert("Please fill all the details!")}>Fill Details</button>
                                            </div>
                                            :
                                            <div className={styles.submitBtn}>
                                                <button onClick={() => hireCandidate()}>submit</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default HireCandidate