import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FTCreateAPlacementDrive from '../../Components/FastTrack/FTCreateAPlacementDrive'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTAllPlacementDrives.module.css'

const FTAllPlacementDrives = () => {
    const authCtx = useContext(AuthContext)

    const [allPlacementDrives, setAllPlacementDrives] = useState([])
    const [isCreatingNew, setIsCreatingNew] = useState(false)

    const getAllPlacementDrives = async () => {
        const getAllPlacementDrivesResponse = await fetch(BASE_URL_API + "/getAllPlacementDrives",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllPlacementDrivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllPlacementDrivesRespo = await getAllPlacementDrivesResponse.json()
            if (getAllPlacementDrivesRespo.status === "success") {
                setAllPlacementDrives(getAllPlacementDrivesRespo.response)
            } else {
                if (getAllPlacementDrivesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllPlacementDrives([])
                }
            }
            console.log(getAllPlacementDrivesRespo)

        }
    }

    useEffect(() => {
        getAllPlacementDrives()
    }, [])



    return (
        <div className={styles.mainWrapper}>
            {isCreatingNew && <FTCreateAPlacementDrive isOpen={isCreatingNew} onClose={() => setIsCreatingNew(false)} />}
            <div className={styles.mainHeader}>
                <div>All Placements Drives</div>
                <div>
                    <button className={styles.createBtn} onClick={() => setIsCreatingNew(true)} >
                        Create Placement Drives
                    </button>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.tableHeader}>
                    <div className={styles.locationWrapper}>
                        Location Details
                    </div>
                    <div className={styles.driveType}>
                        Drive Type
                    </div>
                    <div className={styles.date}>
                        Start Date - End Date
                    </div>
                    <div className={styles.action}>
                        Action
                    </div>

                </div>
                <div>
                    <div className={styles.mainTableWrapper}>
                        {allPlacementDrives?.map((item, index) => (
                            <div key={index} className={styles.eachPlacementDrive} >
                                <div className={styles.locationWrapper}>
                                    <div className={styles.locationTitle}>{item?.locationTitle}</div>
                                    <div className={styles.locationAddress}>{item?.locationAddress}</div>
                                </div>
                                <div className={styles.driveType}>
                                    {item?.driveType}
                                </div>
                                <div className={styles.date}>
                                    {item?.driveStartingDate?.substr(0, 10)} -  {item?.driveEndDate?.substr(0, 10)}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/ft-placement-drive/${item?.driveIdentifier}`} className={styles.goToDriveBtn} >Go To Drive</Link>
                                </div>
                            </div>
                        ))}
                        <div className={styles.thatsAll}>
                            That's All!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FTAllPlacementDrives