import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible';
import { toast } from 'react-toastify';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import DateTimePickerUniversal from '../Generic/DateTimePickerUniversal';
import styles from '../ManageApplications/SlotSelector.module.css'

import { BiLoaderAlt } from 'react-icons/bi'
import { humanReadableTimePassed } from '../../Helper/helpers';


const SlotSelector = props => {
    const authCtx = useContext(AuthContext)
    let applicationIdentifier = props.applicationIdentifier;
    let slotFor = props.slotFor;
    let getApplicationStatus = props.getApplicationStatus;
    const [selectedSlot, setSelectedSlot] = useState('')
    const [slotRemarks, setSlotRemarks] = useState('')
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fetchingSlots, setFetchingSlots] = useState(false);
    const [bookedSlots, setBookedSlots] = useState([])
    const [pastSlotUpdated, setPastSlotUpdated] = useState(false);

    const [actionStatus, setActionStatus] = useState('')
    const [actionRemarks, setActionRemarks] = useState('')

    const selectedSlotToggle = k => {
        let bookableTime = k.replace("T", " ");
        bookableTime = bookableTime + ":00"
        setSelectedSlot(bookableTime)
    }

    const updateActionTaken = async (k) => {
        const updateActionTakenResponse = await fetch(BASE_URL_API + "/updateActionTaken",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationId: applicationIdentifier,
                    ID: k,
                    actionStatus: actionStatus,
                    actionRemarks: actionRemarks
                })
            });

        if (!updateActionTakenResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const updateActionTakenRespo = await updateActionTakenResponse.json();
            if (updateActionTakenRespo.status === "success") {
                fetchBookedDemoIntSlots();
            } else {
                if (updateActionTakenRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateActionTakenRespo)
        }
    }

    useEffect(() => {
        if (selectedSlot !== '') {
            if (slotRemarks.length > 2) {
                setIsBtnEnabled(true)
            } else {
                setIsBtnEnabled(false)
            }
        } else {
            setIsBtnEnabled(false)
        }
    }, [selectedSlot, slotRemarks])


    const bookInterviewSlot = async () => {
        if (applicationIdentifier !== '') {
            setIsUploading(true)
            const updateApplicationStatusResponse = await fetch(BASE_URL_API + "/updateApplicationStatus",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        applicationId: applicationIdentifier,
                        actionFor: slotFor,
                        timeSlot: selectedSlot,
                        slotRemarks: slotRemarks
                    })
                });

            if (!updateApplicationStatusResponse.ok) {
                console.log("Server Error : Something went wrong, please contact admin!");
            } else {
                const updateApplicationStatusRespo = await updateApplicationStatusResponse.json();
                if (updateApplicationStatusRespo.status == "success") {
                    if (updateApplicationStatusRespo.message == "Slot booked succesfully!") {
                        setSlotRemarks('');
                        setSelectedSlot('');
                        toast.success(updateApplicationStatusRespo.message);
                        fetchBookedDemoIntSlots();
                        getApplicationStatus()
                    } else {
                        if (updateApplicationStatusRespo.message === "token Expired, please login again!") {
                            authCtx.logout()
                        } else {
                            toast.warn(updateApplicationStatusRespo.message);
                        }
                    }
                } else {
                    console.log(updateApplicationStatusRespo);
                }
            }
            setIsUploading(false);
        } else {
            alert("Application Identifier missing : You may need to refresh the page. If the problem persist, contact admin!")
        }
    }

    const fetchBookedDemoIntSlots = async () => {
        setFetchingSlots(true);
        const fetchBookedDemoIntSlotsResponse = await fetch(BASE_URL_API + "/fetchBookedDemoIntSlots",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier,
                    slotFor: slotFor
                })
            });

        if (!fetchBookedDemoIntSlotsResponse.ok) {
            console.log("Something went wrong : server error!");
        } else {
            const fetchBookedDemoIntSlotsRespo = await fetchBookedDemoIntSlotsResponse.json();
            if (fetchBookedDemoIntSlotsRespo.status === "success") {
                setBookedSlots(fetchBookedDemoIntSlotsRespo.response);
            } else {
                if (fetchBookedDemoIntSlotsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    setBookedSlots([]);
                }
            }
            console.log(fetchBookedDemoIntSlotsRespo);
        }
        setFetchingSlots(false);
    }

    const validatePastSlotsUpdated = () => {
        if (bookedSlots.length > 0) {
            let isUpdatedAll = true;
            bookedSlots.map((item) => {
                if (item.actionStatus !== "") {

                } else {
                    isUpdatedAll = false;
                    return;
                }
            })
            if (isUpdatedAll) {
                setPastSlotUpdated(true)
            } else {
                setPastSlotUpdated(false);
            }
        } else {
            setPastSlotUpdated(true);
            return;
        }
    }

    useEffect(() => {
        fetchBookedDemoIntSlots();
    }, [applicationIdentifier])

    useEffect(() => {
        validatePastSlotsUpdated()
    }, [bookedSlots])


    return (
        <div className={styles.eachBoxWrapper}>
            <div className={styles.boxHeader}>
                {slotFor === "INTERVIEW_SLOT_BOOKED" ?
                    "Book Interview Slot / Schedule Interview"
                    :
                    "Book Demo Slot / Schedule Demo"
                }
            </div>
            <div className={styles.boxBody}>
                <div
                    className={styles.eachCollapsible}
                >
                    <Collapsible
                        trigger={
                            <div className={styles.captionLine}>
                                Please select a date and time for {slotFor === "INTERVIEW_SLOT_BOOKED" ? "interview" : "demo"}
                            </div>
                        }
                    >
                        {isUploading === true ?
                            <div className={styles.expandedContainerLoading}>
                                <div>
                                    <img src="https://pcdreams.com.sg/wp-content/uploads/2021/03/How-to-display-waiting-gifs-with-transparency-without-the-.gif" />
                                </div>
                                <div>
                                    Hold on!
                                    I'm just booking a time slot!
                                </div>
                            </div>
                            :
                            <div className={styles.expandedContainer}>
                                {pastSlotUpdated !== true ?
                                    <div>
                                        <div>
                                            To book a new slot!
                                        </div>
                                        <div>
                                            Please update the status of past slots first!
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <DateTimePickerUniversal
                                            selectedSlot={selectedSlot}
                                            selectedSlotToggle={selectedSlotToggle}
                                        />
                                        <div className={styles.inputTextArea}>
                                            <textarea placeholder="Add instructions or notes for the candidates...."
                                                onChange={(e) => setSlotRemarks(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className={styles.finalActionBtnsWrapper}>
                                            {isBtnEnabled === true ?
                                                <button onClick={() => bookInterviewSlot()}
                                                    className={styles.slotBookBtn}
                                                >
                                                    Submit
                                                </button>
                                                :
                                                <button onClick={() => alert("Please select time slot and add remarks!")}
                                                    className={styles.slotBookBtnDisable}
                                                >
                                                    Submit
                                                </button>
                                            }
                                        </div>

                                    </div>
                                }
                            </div>
                        }
                    </Collapsible>
                </div>

                {fetchingSlots === true ?
                    <div className={styles.letmefetchSlots}>
                        Alright, let me just fetch already booked slots!
                    </div>
                    :
                    bookedSlots.map((item, index) => (
                        <div
                            className={styles.eachCollapsible}
                            key={index}
                        >
                            <Collapsible
                                trigger={
                                    <div className={styles.captionLine}>
                                        A slot in the past has already been booked!
                                    </div>
                                }
                            >
                                <div className={styles.bookedSlotDetails}>
                                    <div className={styles.bookedTimeSlotsNotes}>
                                        <div className={styles.bookedDate}>
                                            <span className={styles.bookedDateCaption}
                                            >
                                                Date:
                                            </span>
                                            <span className={styles.bookedDateValue}>
                                                {item.timeSlot.split(" ")[0]}
                                            </span>
                                        </div>
                                        <div className={styles.bookedTime}>
                                            <span className={styles.bookedDateCaption}
                                            >
                                                Time:
                                            </span>
                                            <span className={styles.bookedDateValue}>
                                                {item.timeSlot.split(" ")[1]}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.bookedDetailsTitle}>Interview Instructions/Remarks : </div>
                                        <div className={styles.bookedSlotRemarks}>
                                            {item.slotRemarks}
                                        </div>
                                    </div>
                                    {item.actionStatus === '' ?
                                        <div className={styles.atMainWrapper}>
                                            <div className={styles.atMainTitle}>
                                                Action Taken
                                            </div>
                                            <div className={styles.atBody}>
                                                <select
                                                    onChange={(e) => setActionStatus(e.target.value)}
                                                    value={actionStatus}
                                                >
                                                    <option value="">Choose Option</option>
                                                    <option value="RESCHEDULED_BY_SALON">RESCHEDULED_BY_SALON</option>
                                                    <option value="RESCHEDULED_BY_CANDIDATE">RESCHEDULED_BY_CANDIDATE</option>
                                                    <option value="CANDIDATE_VISITED_INTERVIEW_DONE">CANDIDATE_VISITED_INTERVIEW_DONE</option>
                                                    <option value="CANDIDATE_VISITED_INTERVIEW_NOT_DONE">CANDIDATE_VISITED_INTERVIEW_NOT_DONE</option>
                                                    <option value="CANDIDATE_DID_NOT_VISIT">CANDIDATE_DID_NOT_VISIT</option>
                                                    <option value="INVALID_SLOT">INVALID_SLOT</option>
                                                </select>

                                                <div className={styles.atRemarks}>
                                                    <textarea placeholder="Add supporting remarks/notes..."
                                                        onChange={(e) => setActionRemarks(e.target.value)}
                                                        value={actionRemarks}
                                                    >

                                                    </textarea>
                                                </div>

                                                <div className={styles.atBtns}>
                                                    {actionRemarks.length > 4 ?
                                                        actionStatus !== '' ?
                                                            <button onClick={() => updateActionTaken(item.ID)}>Submit</button>
                                                            :
                                                            <div className={styles.warning}>Please select the status</div>
                                                        :
                                                        <div className={styles.warning}>
                                                            Add atleast {5 - actionRemarks.length} characters...
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={styles.atMainWrapper}>
                                            <div className={styles.atMainTitle}>
                                                Action Taken {humanReadableTimePassed(item.actionTakenOn)}
                                            </div>
                                            <div className={styles.atBody}>
                                                <div className={styles.actionStatusTaken}>
                                                    {item.actionStatus}
                                                </div>
                                                <div className={styles.actionRemarksTaken}>
                                                    {item.actionRemarks}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </Collapsible>
                        </div>
                    ))

                }


            </div>
            {/* <div className={styles.boxFooter}>
                Submit
            </div> */}
        </div>

    )
}

export default SlotSelector