import React, { useContext, useEffect, useState } from 'react'
import styles from '../../Styles/Components/Jobs/JobDetails.module.css'
import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import AuthContext from '../../store/auth-context';
import { BASE_URL_API } from '../../References/urls';


const JobDetails = props => {
    const authCtx = useContext(AuthContext);
    const [jobsCategoryData, setJobsCategoryData] = useState([])

    const selectedJobCategory = props.selectedJobCategory;
    const jobCategoryHandler = props.jobCategoryHandler;

    const selectedJobType = props.selectedJobType;
    const selectJobTypeHandler = props.selectJobTypeHandler;

    const numOfOpenings = props.numOfOpenings;
    const numOfOpeningsHandler = props.numOfOpeningsHandler;

    const jobTitle = props.jobTitle;
    const setJobTitle = props.setJobTitle;


    const selectedSkills = props.selectedSkills;
    const skillSetHandler = props.skillSetHandler;

    const [skillSetData, setSkillSetData] = useState(null);

    const jobDescription = props.jobDescription;
    const jobDescriptionHandler = props.jobDescriptionHandler;

    useEffect(() => {
        getSkillsOptions();
    }, []);

    useEffect(() => {
        getCategoryOptions();
    }, [skillSetData])


    const [jobTypeData, setJobTypeData] = useState([
        "Full-Time", "Part-Time", "Internships", "Contractual"
    ]);



    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });
        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setJobsCategoryData(getCategoryOptionsRespo.response);
            } else {
                if(getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    console.log("Category Response : ", getCategoryOptionsRespo)
                }
            }
        }
    }

    const getSkillsOptions = async () => {
        const getSkillsOptionsResponse = await fetch(BASE_URL_API + "/getSkillsOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });
        if (!getSkillsOptionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getSkillsOptionsRespo = await getSkillsOptionsResponse.json();
            if (getSkillsOptionsRespo.status === "success") {
                setSkillSetData(getSkillsOptionsRespo.response)
            } else {
                if(getSkillsOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log("Response is not success : ", getSkillsOptionsRespo);
                }
            }

        }
    }


    return (
        <div className={styles.rcMainBody}>
            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Select Job Category <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the job category in which you are posting job!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {jobsCategoryData.map((item, index) => (
                            <div className={selectedJobCategory === JSON.stringify(item) ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index}
                                onClick={() => jobCategoryHandler(item)}
                            >
                                <div className={styles.eachStoreTitle}>
                                    {item.category_name}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        {skillSetData != null ?
                            <div className={styles.eachFieldHeaderTitle}>
                                <span>Select Skills Sets ({selectedSkills.length}/{(skillSetData.forFemale.length + skillSetData.forMale.length + skillSetData.unsorted.length)}) </span>
                                <FaChevronDown />
                            </div>
                            :
                            <div className={styles.eachFieldHeaderTitle}>
                                <span>Select Skills Sets (--) </span>
                                <FaChevronDown />
                            </div>}
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the skillset that is required by the salon for the profile!
                    </div>
                    {skillSetData != null ?
                        <div className={styles.skillSetsContainer}>
                            <div className={styles.eachSkillCategory}>
                                <div>Generic</div>
                                {skillSetData.unsorted.map((item, index) => (
                                    <div className={selectedSkills.includes(JSON.stringify(item)) ? styles.eachSkillContainerSelected : styles.eachSkillContainer} key={index + "B"}
                                        onClick={() => skillSetHandler(item)}
                                    >
                                        <div className={styles.eachStoreTitle}>
                                            {item.skill}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.eachSkillCategory}>
                                <div>Female</div>
                                {skillSetData.forFemale.map((item, index) => (
                                    <div className={selectedSkills.includes(JSON.stringify(item)) ? styles.eachSkillContainerSelected : styles.eachSkillContainer} key={index + "B"}
                                        onClick={() => skillSetHandler(item)}
                                    >
                                        <div className={styles.eachStoreTitle}>
                                            {item.skill}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.eachSkillCategory}>
                                <div>Male</div>
                                {skillSetData.forMale.map((item, index) => (
                                    <div className={selectedSkills.includes(JSON.stringify(item)) ? styles.eachSkillContainerSelected : styles.eachSkillContainer} key={index + "B"}
                                        onClick={() => skillSetHandler(item)}
                                    >
                                        <div className={styles.eachStoreTitle}>
                                            {item.skill}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div>
                            Skill set data is null
                        </div>
                    }
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Job Type <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the job type!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {jobTypeData.map((item, index) => (
                            <div className={item === selectedJobType ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "C"}
                                onClick={() => selectJobTypeHandler(item)}
                            >
                                <div className={styles.eachStoreTitle}>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Openings <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please add number of Openings!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <div className={styles.numberOfOpeningsContainer}>
                            <button className={styles.decBtn} onClick={() => numOfOpeningsHandler("desc")}>-</button>
                            <button className={styles.count}>{numOfOpenings}</button>
                            <button className={styles.incBtn} onClick={() => numOfOpeningsHandler("inc")}>+</button>
                        </div>
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Job Title <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please enter the job title
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <input placeholder="Please enter the job title" className={styles.jobTitleInput}
                            onChange={(e) => setJobTitle(e.target.value)}
                            value={jobTitle}
                        ></input>
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Description <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please enter the job posting!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <CKEditor
                            editor={BalloonEditor}
                            data={jobDescription!="" ? jobDescription : "<h3><strong>Beautician Responsibilities:</strong></h3><ul><li>Shampooing, cutting, styling, and coloring hair, as well as massaging and treating scalps.</li><li>Applying makeup, performing facials, and recommending skincare therapies.</li><li>Performing manicures and pedicures, nail art, and color recommendations.</li><li>Performing permanent and temporary hair removals treatments, such as electrolysis, laser hair removal, and waxing.</li><li>Suggesting guidelines on personal skincare regimes.</li><li>Cross-selling beauty therapies and products.</li><li>Making appointments and documenting treatments.</li><li>Keeping your workstation and tools clean and sterile.</li><li>Taking stock and ensuring there are adequate supplies.</li></ul><h3><strong>Beautician Requirements:</strong></h3><ul><li>A certification in cosmetology, aesthetics, or related field.</li><li>A license in a cosmetology field.</li><li>Working knowledge of skin care therapies, hair removal techniques, and beauty products and trends.</li><li>Strong creative skills.</li><li>Excellent communication and interpersonal skills.</li><li>Steady and dexterous hands.</li></ul>"}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });

                            }}
                            onBlur={(event, editor) => {
                                // console.log('Blur.', editor);
                                const data = editor.getData();
                                jobDescriptionHandler(data)
                                // console.log(data);
                            }}
                            onFocus={(event, editor) => {
                                // console.log('Focus.', editor);
                            }}
                            className={styles.textAreaFull}

                        />
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export default JobDetails