import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/CourseBasicDetails.module.css'

const CourseBasicDetails = props => {
    const authCtx = useContext(AuthContext);
    let navigate = useNavigate();
    authCtx.token == "" && navigate("/");

    const [language, setLanguage] = useState([
        "HINDI", "ENGLISH", "TAMIL", 'TELUGU', 'PUNJABI'
    ]);

    const setIsLoading = props.setIsLoading;
    const [courseId, setCourseId] = useState(props.courseId);
    const setCourseIdHandler = props.setCourseIdHandler;

    const [courseCategory, setCourseCategory] = useState("");
    const [courseLevel, setCourseLevel] = useState("");
    const [courseLanguage, setCourseLanguage] = useState("");
    const [courseSecondaryLanguage, setCourseSecondaryLanguage] = useState("");
    const [courseTitle, setCourseTitle] = useState("");
    const [courseShortDescription, setCourseShortDescription] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    

    const courseSecondaryLanguageHandler = (i) => {
        if (courseSecondaryLanguage.includes(i)) {
            setCourseSecondaryLanguage(courseSecondaryLanguage.filter((item) => item != i))
        } else {
            setCourseSecondaryLanguage([...courseSecondaryLanguage, i])
        }
    }

    const createCourseHandler = () => {
        if (courseCategory != "") {
            if (courseLevel != "") {
                if (courseLanguage != "") {
                    if (courseTitle != "") {
                        if (courseShortDescription != "") {
                            if (courseDescription != "") {
                                createCourse();
                                // console.log(courseCategory, courseLevel, courseLanguage, courseSecondaryLanguage, courseTitle, courseShortDescription, courseDescription);
                            } else {
                                alert("Please enter the course description!");
                            }
                        } else {
                            alert("Please enter the course short description!");
                        }
                    } else {
                        alert("Please enter the course title!");
                    }
                } else {
                    alert("Please select course Language!");
                }
            } else {
                alert("Please select course Level!");
            }
        } else {
            alert("Please select course category!");
        }
    }

    const getCourseBasicDetails = async () => {
        setIsLoading(true);
        const courseBasicDetailsResponse = await fetch(BASE_URL_API + "/getCourseBasicDetails",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                courseId : courseId
            })
        });

        if(!courseBasicDetailsResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const courseBasicDetailsRespo = await courseBasicDetailsResponse.json();
            if(courseBasicDetailsRespo.status=="success") {
                setCourseTitle(courseBasicDetailsRespo.response.courseTitle)
                setCourseShortDescription(courseBasicDetailsRespo.response.courseShortDescription)
                setCourseDescription(courseBasicDetailsRespo.response.courseDescription)
                setCourseCategory(courseBasicDetailsRespo.response.courseCategory);
                setCourseLevel(courseBasicDetailsRespo.response.courseLevel);
                setCourseLanguage(courseBasicDetailsRespo.response.courseLanguage);
                setCourseSecondaryLanguage(courseBasicDetailsRespo.response.courseSecondaryLanguage);
                console.log("This is fetched course details : ",courseBasicDetailsRespo);

            } else {
                console.log(courseBasicDetailsRespo);
            }
        }
        setIsLoading(false);
    }

    const createCourse = async () => {
        setIsLoading(true);
        const createCourseResponse = await fetch(BASE_URL_API + "/createCourse",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseCategory: courseCategory,
                    courseLevel: courseLevel,
                    courseLanguage: courseLanguage,
                    courseSecondaryLanguage: courseSecondaryLanguage.toString(),
                    courseTitle: courseTitle,
                    courseShortDescription: courseShortDescription,
                    courseDescription: courseDescription
                })
            });

        if (!createCourseResponse.ok) {
            alert("Something went wrong : Internal Server error!");
        } else {
            const createCourseRespo = await createCourseResponse.json();
            if (createCourseRespo.status == "failed") {
                if (createCourseRespo.message == "token Expired, please login again!") {
                    alert("Invalid Session, Please login again");
                    navigate("/");
                } else {
                    console.log(createCourseRespo);
                }
            } else {
                console.log(createCourseRespo)
                if (createCourseRespo.message == "Course created successfully!") {
                    setCourseId(createCourseRespo.response);
                    setCourseIdHandler(createCourseRespo.response);
                    getCourseBasicDetails();
                }
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getCourseBasicDetails();
    }, [courseId])

    
    

    console.log("Course secondary languages", courseSecondaryLanguage);


    return (
        <section id="basicDetails">
            <div className={styles.sectionMainTitle}>
                <h3>Basic Details</h3>
            </div>
            {courseId == "" ?
                <div className={styles.sectionContent}>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Choose Course Category</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            <button onClick={() => setCourseCategory(courseCategory != "HAIR" ? "HAIR" : "")}
                                className={courseCategory != "HAIR" ? "" : styles.selectedCategory}
                            >
                                HAIR
                            </button>
                            <button onClick={() => setCourseCategory(courseCategory != "MAKEUP" ? "MAKEUP" : "")}
                                className={courseCategory != "MAKEUP" ? "" : styles.selectedCategory}
                            >
                                MAKEUP
                            </button>
                            <button onClick={() => setCourseCategory(courseCategory != "BEAUTY" ? "BEAUTY" : "")}
                                className={courseCategory != "BEAUTY" ? "" : styles.selectedCategory}
                            >
                                BEAUTY
                            </button>
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Choose Course Level</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            <button onClick={() => setCourseLevel(courseLevel != "BASIC" ? "BASIC" : "")}
                                className={courseLevel != "BASIC" ? "" : styles.selectedCategory}
                            >
                                BASIC
                            </button>
                            <button onClick={() => setCourseLevel(courseLevel != "ADVANCE" ? "ADVANCE" : "")}
                                className={courseLevel != "ADVANCE" ? "" : styles.selectedCategory}
                            >
                                ADVANCE
                            </button>
                            <button onClick={() => setCourseLevel(courseLevel != "MASTERY" ? "MASTERY" : "")}
                                className={courseLevel != "MASTERY" ? "" : styles.selectedCategory}
                            >
                                MASTERY
                            </button>
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Choose Course Primary Language</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {language.map((item, index) => (
                                <button onClick={() => setCourseLanguage(courseLanguage != item ? item : "")}
                                    className={courseLanguage != item ? "" : styles.selectedCategory}
                                    key={index + "PL"}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Choose Course Secondary Languages</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {language.map((item, index) => (
                                <button onClick={() => courseSecondaryLanguageHandler(item)}
                                    className={courseSecondaryLanguage.includes(item) ? styles.selectedCategory : ""}
                                    key={index + "CSL"}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Enter title</span>
                        </div>
                        <div className={styles.eachFieldInput}>
                            <input
                                placeholder="Please enter the course title..."
                                onChange={(e) => setCourseTitle(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Enter Short Description</span>
                        </div>
                        <div className={styles.eachFieldInput}>
                            <textarea
                                placeholder="Please enter the course short description..."
                                onChange={(e) => setCourseShortDescription(e.target.value)}
                                value={courseShortDescription}
                            ></textarea>
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Enter Description</span>
                        </div>
                        <div className={styles.eachFieldInput}>
                            <textarea
                                placeholder="Please enter the course description..."
                                onChange={(e) => setCourseDescription(e.target.value)}
                                value={courseDescription}
                            ></textarea>
                        </div>
                    </div>

                    <div className={styles.submitBtnContainer}>
                        <button onClick={() => createCourseHandler()}>Submit</button>
                    </div>
                </div>
                :
                <div className={styles.sectionContent}>
                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Category</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseCategory}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Level</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseLevel}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Primary Language</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseLanguage}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Secondary Language</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseSecondaryLanguage}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Title</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseTitle}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Short Description</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseShortDescription}
                        </div>
                    </div>

                    <div className={styles.eachField}>
                        <div className={styles.eachFieldTitle}>
                            <span>Course Description</span>
                        </div>
                        <div className={styles.eachFieldOptions}>
                            {courseDescription}
                        </div>
                    </div>

                </div>
            }
        </section>
    )
}

export default CourseBasicDetails