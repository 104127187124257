import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import SLCPreviewDraftEachRow from './SLCPreviewDraftEachRow'
import styles from './SLCPreviewDrafts.module.css'
import SLCPreviewJobDetailsAndDescription from './SLCPreviewJobDetailsAndDescription'

const SLCPreviewDrafts = ({ isOpen, leadIdentifier, togglePreviewDraft }) => {
    const authCtx = useContext(AuthContext)
    const [draftsData, setDraftsData] = useState([])
    const [isGettingDrafts, setIsGettingDrafts] = useState(false)
    const [isJDnDesOpen, setIsJDnDesOpen] = useState(false)
    const [activeDraft, setActiveDraft] = useState(null)

    const jdndespHandlerClose = () => {
        setActiveDraft(null)
        setIsJDnDesOpen(false)
    }

    const jdndespHandler = (k) => {
        setIsJDnDesOpen(true)
        setActiveDraft(k)
    }

    const getJobDrafts = async () => {
        setIsGettingDrafts(true)
        const getJobDraftsResponse = await fetch(BASE_URL_API + "/getJobDrafts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier
                })
            });

        if (!getJobDraftsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDraftsRespo = await getJobDraftsResponse.json()
            if (getJobDraftsRespo.status === "success") {
                setDraftsData(getJobDraftsRespo.response)
            } else {
                if (getJobDraftsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setDraftsData([])
                }
            }

            console.log(getJobDraftsRespo)
        }
        setIsGettingDrafts(false)
    }

    useEffect(() => {
        if (isOpen) {
            getJobDrafts()
        }
    }, [isOpen, leadIdentifier])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <SLCPreviewJobDetailsAndDescription isOpen={isJDnDesOpen} activeDraft={activeDraft} jdndespHandlerClose={jdndespHandlerClose}  />
            <div className={styles.headerWrapper}>
                <div>
                    <button onClick={() => togglePreviewDraft()} >Close</button>
                </div>
                <div>
                    <button>Read</button>
                </div>
            </div>
            <div>
                {isGettingDrafts === true ?
                    <div>
                        Loading data...
                    </div>
                    :
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableHeaderWrapper}>
                            <div className={styles.sn}>
                                S. No.
                            </div>
                            <div className={styles.jobTitle}>
                                Job Title
                            </div>
                            <div className={styles.jobCategory}>
                                Category
                            </div>
                            <div className={styles.storeDetails}>
                                Store Details
                            </div>
                            <div className={styles.numberOfOpenings}>
                                #
                            </div>
                            <div className={styles.salaryWrapper}>
                                <div>
                                    Salary Min
                                </div>
                                <div>
                                    Salary Max
                                </div>
                            </div>
                            <div className={styles.fna}>
                                Food & Accomo...
                            </div>
                            <div className={styles.ageRangeWrapper}>
                                Age Range
                            </div>
                            <div className={styles.actionWrapper}>
                                Action
                            </div>

                        </div>
                        <div>
                            {draftsData.length > 0 ?
                                <div>
                                    {draftsData.map((item, index) => (
                                        <SLCPreviewDraftEachRow key={index} count={index} item={item} jdndespHandler={jdndespHandler} jdndespHandlerClose={jdndespHandlerClose} />
                                    ))}
                                </div>
                                :
                                <div>
                                    No drafts found!
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}

export default SLCPreviewDrafts