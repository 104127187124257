import React, { useContext, useEffect, useState } from 'react'
import styles from '../ManageLeads/CourseLeadsDistributor.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { FaCheckSquare } from 'react-icons/fa';
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi';
import { humanReadableTimePassed } from '../../Helper/helpers';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';



const CourseLeadsDistributor = () => {
    const authCtx = useContext(AuthContext)

    const [allLeads, setAllLeads] = useState([])
    const [allExe, setAllExe] = useState([])

    const [selectedLeads, setSelectedLeads] = useState([])
    const [selectedExecutive, setSelectedExecutive] = useState(null)
    const [isAssigningLeads, setIsAssigningLeads] = useState(false)
    const toggleLeadsHandler = (k) => {
        if (selectedLeads.some(item => item?.phone === k?.phone)) {
            setSelectedLeads(selectedLeads.filter((item) => item?.phone !== k?.phone))
        } else {
            setSelectedLeads([...selectedLeads, k])
        }
    }

    const getCourseLeadsForDistribution = async () => {
        const getCourseLeadsForDistributionResponse = await fetch(BASE_URL_API + "/getCourseLeadsForDistribution",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCourseLeadsForDistributionResponse.ok) {
            console.log("Something went wrong : server error")
        } else {
            const getCourseLeadsForDistributionRespo = await getCourseLeadsForDistributionResponse.json()
            if (getCourseLeadsForDistributionRespo.status === "success") {
                setAllLeads(getCourseLeadsForDistributionRespo.response)
            } else {
                if (getCourseLeadsForDistributionRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllLeads([])
                }
            }
            console.log(getCourseLeadsForDistributionRespo)
        }
    }

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json();
            if (adminGetAllExecutivesRespo.status === 'success') {
                setAllExe(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllExe([])
                }
            }
        }

    }

    const leadAssignHanlder = async () => {
        if(selectedExecutive!==null) {
            setIsAssigningLeads(true)
            const adminAssignCourseLeadsResponse = await fetch(BASE_URL_API+"/adminAssignCourseLeads",
            {
                method : "POST",
                body : JSON.stringify({
                    token : authCtx.token,
                    executiveIdentifier : authCtx.userIdentifier,
                    assigningTo : selectedExecutive.userID,
                    leadsToBeAssigned : selectedLeads
                })
            });

            if(!adminAssignCourseLeadsResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const adminAssignCourseLeadsRespo = await adminAssignCourseLeadsResponse.json();
                if(adminAssignCourseLeadsRespo.status==="success") {
                    // console.log(adminAssignCourseLeadsRespo);
                    setSelectedLeads([])
                    toast("Successfully assigned the leads!")
                    getCourseLeadsForDistribution()
                } else {
                    if(adminAssignCourseLeadsRespo.message==="token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        if(adminAssignCourseLeadsRespo.response===409) {
                            toast(adminAssignCourseLeadsRespo.message)
                        } else {
                            console.log(adminAssignCourseLeadsRespo)
                        }
                    }
                }
            }
            setIsAssigningLeads(false)

        } else {
            alert("Dude, whom you want to assign the leads?")
            console.log(selectedLeads.length, selectedExecutive, authCtx.userIdentifier)
        }
    }

    useEffect(() => {
        adminGetAllExecutives().then(() => getCourseLeadsForDistribution())
    }, [])

    // console.log(selectedExecutive, allExe)

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainHeader}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                </div>
                <div>
                    Course Leads Distributor
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.filterWrapper}>
                    <div className={styles.fwLeft}>
                        <select>
                            <option>Select Source</option>
                            <option value="PROFILE_REMARKS">Profile Remarks</option>
                        </select>
                    </div>
                    <div className={styles.fwRight}>
                        <button>Get Data</button>
                        <button>Reload Data</button>
                    </div>

                </div>
                <div className={styles.mainContentWrapper}>
                    <div className={styles.mcwLeft}>
                        <div className={styles.tableHeader}>
                            <div className={styles.srCol}>#</div>
                            <div className={styles.phoneCol}>Phone</div>
                            <div className={styles.nameCol}>Name</div>
                            <div className={styles.dateCol}>Added On</div>
                            <div className={styles.sourceCol}>Source</div>
                        </div>
                        <div className={styles.tableRowsHolder}>
                            {allLeads.map((item, index) => (
                                <div className={styles.eachRow} key={index}
                                    onClick={() => toggleLeadsHandler(item)}
                                >
                                    <div className={styles.srCol}>{selectedLeads.some(k => k?.phone === item?.phone) ? <BiCheckboxChecked size={28} /> : <BiCheckbox size={28} />}</div>
                                    <div className={styles.phoneCol}>{item.phone}</div>
                                    <div className={styles.nameCol}>{item.fullName}</div>
                                    <div className={styles.dateCol}>{humanReadableTimePassed(item?.addedOn)}</div>
                                    <div className={styles.sourceCol}>{item.source}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className={styles.mcwRight}>
                        <div>
                            <div className={styles.selectedTxt}>
                                {selectedLeads.length}
                            </div>
                            <div className={styles.assigningWrapper}>
                                <div className={styles.awUpper}>
                                    <div className={styles.awuTitle}>Select Executive to Assign Leads</div>
                                    <div className={styles.selectWrapper}>
                                        <select
                                            className={styles.selectItem}
                                            onChange={(e) => setSelectedExecutive(JSON.parse(e.target.value))}
                                        >
                                            <option>Select Executive</option>
                                            {allExe.map((item, index) => (
                                                <option value={JSON.stringify(item)} key={index}>{item?.firstName} {item?.lastName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.assignNowBtn}>
                                    {selectedLeads.length > 0 &&
                                        (!isAssigningLeads ? 
                                            <button onClick={() => leadAssignHanlder()}>Assign {selectedLeads.length} lead(s) to {selectedExecutive?.firstName}</button>
                                            :
                                            <button>Hold On, assigning {selectedLeads.length} lead(s) to {selectedExecutive?.firstName}</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CourseLeadsDistributor