import React, { useContext, useEffect, useState } from 'react'
import { MdSend } from 'react-icons/md'
import { getExecutiveFullName } from '../../Helper/executivesData'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AllStoreConversations.module.css'

const AllStoreConversations = (props) => {
    const { isOpen, onClose, storeIdentifier, jobIdentifier } = props
    const [content, setContent] = useState('')
    const authCtx = useContext(AuthContext)
    const [isAdding, setIsAdding] = useState(false)
    const [allThreads, setAllThreads] = useState([])

    window.onclick = event => event.target.id === "ascmc" ? onClose() : null

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const addPartnersNotes = async () => {
        setIsAdding(true)
        const addPartnersNotesResponse = await fetch(BASE_URL_API + "/addPartnersNotes",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    storeIdentifier: storeIdentifier,
                    content: content,
                    jobIdentifier : jobIdentifier
                })
            });

        if (!addPartnersNotesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addPartnersNotesRespo = await addPartnersNotesResponse.json()
            if (addPartnersNotesRespo.status === "success") {
                setContent('')
                getPartnersNotes()
            } else {
                if (addPartnersNotesRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addPartnersNotesRespo)
        }
        setIsAdding(false)
    }

    const scrollToBottom = () => {
        const container = document.getElementById('txtContainer');
        container.scrollTop = container.scrollHeight;
    }

    const getPartnersNotes = async () => {
        const getPartnersNotesResponse = await fetch(BASE_URL_API + "/getPartnersNotes",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getPartnersNotesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPartnersNotesRespo = await getPartnersNotesResponse.json()
            if (getPartnersNotesRespo?.status === "success") {
                setAllThreads(getPartnersNotesRespo?.response)
            } else {
                if (getPartnersNotesRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllThreads([])
                }
            }
            console.log(getPartnersNotesRespo)
        }
    }

    useEffect(() => {
        getPartnersNotes()
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [allThreads])



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="ascmc">
                    <div className={styles.mainContent}>
                        <div className={styles.topHeader}>
                            <div className={styles.small}>
                                <button onClick={() => onClose()}>Close</button>
                            </div>
                            <div className={styles.big}>
                                All Store Conversations
                            </div>
                            <div className={styles.small}>

                            </div>
                        </div>
                        <div className={styles.messagesWrapper}>
                            <div className={styles.allThreadsWrapper} id="txtContainer">
                                {allThreads?.map((item, index) => (
                                    <div key={index} className={styles.eachContentWrapper}>
                                        <div className={styles.eachContent}>
                                            <div className={styles.content}>
                                                {item?.content}
                                            </div>
                                            <div className={styles.timeStamp}>
                                                <div>
                                                    {humanReadableTimePassed(item?.createdOn)}
                                                </div>
                                                <div className={styles.executiveIdentifier}>
                                                    {getExecutiveFullName(item?.executiveIdentifier)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.typeNewWrapper}>
                            <textarea placeholder="Type a message..."
                                value={content}
                                onChange={(e) => setContent(e.target.value)} ></textarea>
                            {isAdding === false &&
                                <button className={styles.sendBtnWrapper} onClick={() => addPartnersNotes()}>
                                    <MdSend size={20} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllStoreConversations