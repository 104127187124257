import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import styles from './CAEJWMGender.module.css'


const CAEJWMGender = (props) => {
    const { selectedGender, genderToggler } = props;
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Gender
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Include all profile with the gender:
                    </div>
                    <div>
                        <div className={styles.eachGender} onClick={() => genderToggler('Female')}>
                            {selectedGender?.includes('Female') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} Female
                        </div>
                        <div className={styles.eachGender} onClick={() => genderToggler('Male')}>
                            {selectedGender?.includes('Male') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} Male
                        </div>
                        <div className={styles.eachGender} onClick={() => genderToggler('LGBTQ+')}>
                            {selectedGender?.includes('LGBTQ+') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} LGBTQ+
                        </div>
                        <div className={styles.eachGender} onClick={() => genderToggler('Unknown')}>
                            {selectedGender?.includes('Unknown') === false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />} Unknown
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMGender