import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../ManageUsers/ManageUsersHome.module.css'

const ManageUsersHome = () => {
    const navOptions = [
        {
            "parentHeader": "Connect",
            "children": [
                {
                    "title": "Manage Users",
                    "navigateTo": "/manage-users/connect"
                }
            ]
        },
        {
            "parentHeader": "Learning",
            "children": [
                {
                    "title": "Manage Users",
                    "navigateTo": "/manage-users/connect"
                }
            ]
        },
        {
            "parentHeader": "Earn & Grow",
            "children": [
                {
                    "title": "Manage Users",
                    "navigateTo": "/manage-users/grow"
                }
            ]
        },
    ]

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                {navOptions.map((item, index) => (
                    <div key={index} className={styles.eachCardWrapper}>
                        <div className={styles.cardHeaderWrapper}>
                            {item.parentHeader}
                        </div>
                        <div>
                            {item.children.map((itemChild, indexChild) => (
                                <Link to={itemChild.navigateTo} key={indexChild}>
                                    <div className={styles.eachNavWrapper}>
                                        {itemChild.title}
                                    </div>
                                </Link>

                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ManageUsersHome