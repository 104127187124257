import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import DataSelector from "../../Components/ManageApplications/DataSelector";
import EachApplication from "../../Components/ManageApplications/EachApplication";
import EachApplicationStatusTimeline from "../../Components/ManageApplications/EachApplicationStatusTimeline";
import { humanReadableTimePassed } from "../../Helper/helpers";
import { BASE_URL_API } from "../../References/urls";
import AuthContext from "../../store/auth-context";
import styles from '../JobApplications/ManageApplications.module.css'


const ManageApplications = () => {
    const authCtx = useContext(AuthContext);
    const [selectedApplication, setSelectedApplication] = useState([]);
    const [isDataSelectorOpen, setIsDataSelectorOpen] = useState(false)
    const [applicationData, setApplicationData] = useState([])

    const filterWrapperToggler = () => setIsDataSelectorOpen(false)
    const appDataHandler = (k) => setApplicationData(k)
    const selectApplicationHandler = k => setSelectedApplication(k)


    console.log(applicationData)

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainHeaderWrapper}>
                <div>
                    <Link to="/">Back</Link>
                </div>
                <div>
                    <button onClick={() => setIsDataSelectorOpen(true)} >Select Application</button>
                </div>
            </div>
            <DataSelector
                isOpen={isDataSelectorOpen}
                filterWrapperToggler={filterWrapperToggler}
                appDataHandler={appDataHandler}
                selectedApplication={selectedApplication}
                selectApplicationHandler={selectApplicationHandler}
            />

            <div className={styles.mainBodyWrapper}>
                <div className={styles.leftPanel}>
                    {applicationData?.map((item, index) => (
                        <a
                            href={`/manage-application/` + item?.applicationId}
                            target="_blank"
                        >
                            <div className={styles.eachApp}>
                                <div className={styles.topRow}>
                                    <div>
                                        {item?.stylist_name}
                                    </div>
                                    <div className={styles.appliedSource}>
                                        {item?.applied_source}
                                    </div>
                                </div>
                                <div className={styles.companyName}>
                                    {item?.company_name}
                                </div>
                                <div title={item?.applied_on} className={styles.appliedOn}>
                                    Applied {humanReadableTimePassed(item?.applied_on)}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ManageApplications;