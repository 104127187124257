import { style } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { useParams } from 'react-router'
import PlanServices from '../../Components/Contracts/PlanServices'
import { convertToArray } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ContractGenerator.module.css'
import PreviewAgreement from './PreviewAgreement'

import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";
import StoreAndCitiesCovered from '../../Components/Contracts/StoreAndCitiesCovered'
import { toast, ToastContainer } from 'react-toastify'
import SelectVendor from '../../Components/Contracts/SelectVendor'




const ContractGenerator = () => {
    const { agreementIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [isCollecting, setIsCollecting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [profileOptions, setProfileOptions] = useState([])
    const [activeTab, setActiveTab] = useState('Membership')

    const [isPlanServicesOpen, setIsPlanServicesOpen] = useState(false)
    const toggleIsPlanServicesOpen = () => setIsPlanServicesOpen(false)
    const onPlanBenefitSuccessfullyUpdation = () => {
        setIsPlanServicesOpen(false)
        toast.success("Plan benefits successfully updated!")
    }

    const [isStoreOpen, setIsStoreOpen] = useState(false)
    const toggleStoreOpen = () => setIsStoreOpen(false)

    const [contractLevel, setContractLevel] = useState('')
    const [dateOfContract, setDateOfContract] = useState('')
    const [contractValidTill, setContractValidTill] = useState('')
    const [vendorIdentifier, setVendorIdentifier] = useState('')
    const [storeIdentifier, setStoreIdentifier] = useState('')
    const [secondPartyName, setSecondPartyName] = useState('')
    const [secondPartyAddress, setSecondPartyAddress] = useState('')
    const [paymentDueDate, setPaymentDueDate] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [servicesOffered, setServicesOffered] = useState([])
    const [hasMultipleInterviewLocations, setHasMultipleInterviewLocations] = useState('')
    const [interviewLocations, setInterviewLocations] = useState('')
    const [profilesCovered, setProfilesCovered] = useState([])
    const [firstPartyPOCName, setFirstPartyPOCName] = useState('')
    const [firstPartyPOCPhone, setFirstPartyPOCPhone] = useState('')
    const [firstPartyPOCEmail, setFirstPartyPOCEmail] = useState('')
    const [secondPartyPOCName, setSecondPartyPOCName] = useState('')
    const [secondPartyPOCPhone, setSecondPartyPOCPhone] = useState('')
    const [secondPartyPOCEmail, setSecondPartyPOCEmail] = useState('')
    const [firstPartyPOCDesignation, setFirstPartyPOCDesignation] = useState('')
    const [secondPartyPOCDesignation, setSecondPartyPOCDesignation] = useState('')
    const [olderBenefits, setOlderBenefits] = useState([])
    const [isSubscriptionFee, setIsSubscriptionFee] = useState(1)
    const [subscriptionFeeTerms, setSubscriptionFeeTerms] = useState(null)
    const [isHiringFee, setIsHiringFee] = useState(false)
    const [hiringFeeTerms, setHiringFeeTerms] = useState(null)
    const [storesCovered, setStoresCovered] = useState([])
    const [citiesCovered, setCitiesCovered] = useState([])


    const [isSelectVendorOpen, setIsSelectVendorOpen] = useState(false)
    const closeSelectVendor = () => setIsSelectVendorOpen(false)

    const isSubscriptionFeeToggler = () => {
        setSubscriptionFeeTerms(null)
        setIsSubscriptionFee(!isSubscriptionFee)
    }

    const isHiringFeeToggler = () => {
        setHiringFeeTerms(null)
        setIsHiringFee(!isHiringFee)
    }

    const updateContract = async () => {
        setIsUpdating(true)
        const updateContractResponse = await fetch(BASE_URL_API + "/updateAgreement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    dateOfContract: dateOfContract,
                    contractValidTill: contractValidTill,
                    contractLevel: contractLevel,
                    vendorIdentifier: vendorIdentifier,
                    storeIdentifier: storeIdentifier,
                    secondPartyName: secondPartyName,
                    secondPartyAddress: secondPartyAddress,
                    paymentDueDate: paymentDueDate,
                    selectedPlan: selectedPlan,
                    servicesOffered: servicesOffered,
                    storesCovered: storesCovered,
                    hasMultipleInterviewLocations: hasMultipleInterviewLocations,
                    interviewLocations: interviewLocations,
                    profilesCovered: profilesCovered,
                    firstPartyPOCName: firstPartyPOCName,
                    firstPartyPOCPhone: firstPartyPOCPhone,
                    firstPartyPOCEmail: firstPartyPOCEmail,
                    secondPartyPOCName: secondPartyPOCName,
                    secondPartyPOCPhone: secondPartyPOCPhone,
                    secondPartyPOCEmail: secondPartyPOCEmail,
                    firstPartyPOCDesignation: firstPartyPOCDesignation,
                    secondPartyPOCDesignation: secondPartyPOCDesignation,
                    isSubscriptionFee: isSubscriptionFee,
                    subscriptionFeeTerms: subscriptionFeeTerms,
                    isHiringFee: isHiringFee,
                    hiringFeeTerms: hiringFeeTerms
                })
            });

        if (!updateContractResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateContractRespo = await updateContractResponse.json()
            if (updateContractRespo.status === "success") {
                toast("Successfully updated the agreement!")
            } else {
                if (updateContractRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateContractRespo)
        }
        setIsUpdating(false)
    }


    const getAgreementDetails = async () => {
        setIsCollecting(true)
        const getAgreementDetailsResponse = await fetch(BASE_URL_API + "/getAgreementDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier
                })
            });

        if (!getAgreementDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAgreementDetailsRespo = await getAgreementDetailsResponse.json()
            if (getAgreementDetailsRespo.status === "success") {
                setContractLevel(getAgreementDetailsRespo?.response?.contractLevel)
                setDateOfContract(getAgreementDetailsRespo?.response?.dateOfContract.substr(0, 10))
                setContractValidTill(getAgreementDetailsRespo?.response?.contractValidTill.substr(0, 10))
                setVendorIdentifier(getAgreementDetailsRespo?.response?.vendorIdentifier)
                setStoreIdentifier(getAgreementDetailsRespo?.response?.storeIdentifier)
                setSecondPartyName(getAgreementDetailsRespo?.response?.secondPartyName)
                setSecondPartyAddress(getAgreementDetailsRespo?.response?.secondPartyAddress)
                setPaymentDueDate(getAgreementDetailsRespo?.response?.paymentDueDate?.substr(0, 10))
                setSelectedPlan(getAgreementDetailsRespo?.response?.selectedPlan)
                setServicesOffered(convertToArray(getAgreementDetailsRespo?.response?.servicesOffered))
                setHasMultipleInterviewLocations(getAgreementDetailsRespo?.response?.hasMultipleInterviewLocations)
                setInterviewLocations(getAgreementDetailsRespo?.response?.interviewLocations)
                setProfilesCovered(convertToArray(getAgreementDetailsRespo?.response?.profilesCovered))
                setOlderBenefits(convertToArray(getAgreementDetailsRespo?.response?.planBenefits))
                setFirstPartyPOCName(getAgreementDetailsRespo?.response?.firstPartyPOCName)
                setFirstPartyPOCPhone(getAgreementDetailsRespo?.response?.firstPartyPOCPhone)
                setFirstPartyPOCEmail(getAgreementDetailsRespo?.response?.firstPartyPOCEmail)
                setSecondPartyPOCName(getAgreementDetailsRespo?.response?.secondPartyPOCName)
                setSecondPartyPOCPhone(getAgreementDetailsRespo?.response?.secondPartyPOCPhone)
                setSecondPartyPOCEmail(getAgreementDetailsRespo?.response?.secondPartyPOCEmail)
                setFirstPartyPOCDesignation(getAgreementDetailsRespo?.response?.firstPartyPOCDesignation)
                setSecondPartyPOCDesignation(getAgreementDetailsRespo?.response?.secondPartyPOCDesignation)
                setIsSubscriptionFee(getAgreementDetailsRespo?.response?.isSubscriptionFee === "1" ? true : false)
                setSubscriptionFeeTerms(getAgreementDetailsRespo?.response?.subscriptionFeeTerms)
                setIsHiringFee(getAgreementDetailsRespo?.response?.isHiringFee === "1" ? true : false)
                setHiringFeeTerms(getAgreementDetailsRespo?.response?.hiringFeeTerms)
                setCitiesCovered(convertToArray(getAgreementDetailsRespo?.response?.citiesCovered))
                setStoresCovered(convertToArray(getAgreementDetailsRespo?.response?.storesCovered))
            } else {
                if (getAgreementDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
        }
        setIsCollecting(false)
    }


    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });
        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setProfileOptions(getCategoryOptionsRespo?.response)
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    console.log("Something went wrong!!!")
                }
            }
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])

    useEffect(() => {
        getAgreementDetails()
    }, [agreementIdentifier])

    const servicesOfferedHandler = (k) => {
        if (servicesOffered?.find(item => item?.ID === k?.ID)) {
            setServicesOffered(servicesOffered?.filter((item) => item?.ID !== k?.ID))
        } else {
            setServicesOffered([...servicesOffered, k])
        }
    }

    const profilesCoveredHandler = (k) => {
        if (profilesCovered?.find(item => item?.ID === k?.ID)) {
            setProfilesCovered(profilesCovered?.filter((item) => item?.ID !== k?.ID))
        } else {
            setProfilesCovered([...profilesCovered, k])
        }
    }

    const selectVendorHandler = (k) => {
        setVendorIdentifier(k?.vendorIdentifier)
        setCitiesCovered([])
        setStoresCovered([])
        closeSelectVendor()
    }

    const toggleProfileSelector = async () => {
        if (profilesCovered?.length === profileOptions?.length) {
            setProfilesCovered([])
        } else {
            setProfilesCovered(profileOptions)

        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                main header
            </div>
            <ToastContainer />

            {isSelectVendorOpen === true && <SelectVendor isOpen={isSelectVendorOpen} onClose={closeSelectVendor} onSelect={selectVendorHandler} />}
            <div className={styles.mainContainer}>
                <div className={styles.tabsLocator}>
                    <button className={activeTab === "Membership" ? styles.activeTab : styles.inactiveTab} onClick={() => setActiveTab('Membership')} >Basic Details</button>
                    <button className={activeTab === "Payment" ? styles.activeTab : styles.inactiveTab} onClick={() => setActiveTab('Payment')} >Plan & Payment</button>
                    <button className={activeTab === "Services" ? styles.activeTab : styles.inactiveTab} onClick={() => setActiveTab('Services')} >Services & Locations</button>
                </div>

                <div className={styles.mainContent}>
                    <div className={styles.stepsWrapper}>
                        {activeTab === "Membership" &&
                            <div className={styles.tabWrapper}>
                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Generate Contract At Level
                                    </div>
                                    <div className={styles.contractLevelWrapper}>
                                        <div className={styles.levelOptionsWrapper}>
                                            <button className={styles.levelType} onClick={() => setContractLevel('Account')} >
                                                Account
                                            </button>
                                            <button className={styles.levelType} onClick={() => setContractLevel('Store')} >
                                                Store
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Select {contractLevel === `Account` ? `An Account` : `A ${contractLevel}`}
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <input placeholder="Please search a vendor"
                                            onClick={() => setIsSelectVendorOpen(true)}
                                            value={vendorIdentifier}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Second Party Name
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <input
                                            onChange={(e) => setSecondPartyName(e.target.value)}
                                            value={secondPartyName}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Second Party Address
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <textarea
                                            value={secondPartyAddress}
                                            onChange={(e) => setSecondPartyAddress(e.target.value)}
                                            className={styles.secondPartyAddress}
                                        >

                                        </textarea>
                                    </div>
                                </div>


                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Primary POCs - First Party
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div>
                                            <input placeholder="Full Name" value={firstPartyPOCName} onChange={(e) => setFirstPartyPOCName(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="Phone number" value={firstPartyPOCPhone} onChange={(e) => setFirstPartyPOCPhone(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="E-mail" value={firstPartyPOCEmail} onChange={(e) => setFirstPartyPOCEmail(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="Designation" value={firstPartyPOCDesignation} onChange={(e) => setFirstPartyPOCDesignation(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Primary POCs - Second Party
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div>
                                            <input placeholder="Full Name" value={secondPartyPOCName} onChange={(e) => setSecondPartyPOCName(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="Phone number" value={secondPartyPOCPhone} onChange={(e) => setSecondPartyPOCPhone(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="E-mail" value={secondPartyPOCEmail} onChange={(e) => setSecondPartyPOCEmail(e.target.value)} />
                                        </div>
                                        <div>
                                            <input placeholder="Designation" value={secondPartyPOCDesignation} onChange={(e) => setSecondPartyPOCDesignation(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Auth Signatory - Second Party
                                    </div>
                                    <div className={styles.stepBodyWrapper}>

                                    </div>
                                </div>
                            </div>
                        }

                        {activeTab === "Payment" &&
                            <div className={styles.tabWrapper}>
                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Payment Due Date
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <input type="date" value={paymentDueDate}
                                            onChange={(e) => setPaymentDueDate(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Date of Agreement
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div className={styles.agreementTermWrapper}>

                                            <div className={styles.agreementTermStart}>
                                                <div className={styles.smallTxt}>Date Of Agreement</div>
                                                <input type="date" value={dateOfContract}
                                                    onChange={(e) => setDateOfContract(e.target.value)}
                                                />
                                            </div>

                                            <div className={styles.agreementTermEnd}>
                                                <div className={styles.smallTxt}>Valid Till</div>
                                                <div>
                                                    <input type="date" value={contractValidTill}
                                                        onChange={(e) => setContractValidTill(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Selected Plan
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div className={styles.planWrapper}>

                                            <button onClick={() => setSelectedPlan('Plus')}
                                                className={selectedPlan === 'Plus' ? styles.selectedPlan : styles.plan}
                                            >
                                                Plus
                                            </button>
                                            <button onClick={() => setSelectedPlan('Essential')}
                                                className={selectedPlan === 'Essential' ? styles.selectedPlan : styles.plan}
                                            >
                                                Essential
                                            </button>
                                            <button onClick={() => setSelectedPlan('Business')}
                                                className={selectedPlan === 'Business' ? styles.selectedPlan : styles.plan}
                                            >
                                                Business
                                            </button>
                                            <button onClick={() => setSelectedPlan('Enterprise')}
                                                className={selectedPlan === 'Enterprise' ? styles.selectedPlan : styles.plan}
                                            >
                                                Enterprise
                                            </button>
                                        </div>

                                        <div>
                                            Update Plan Services
                                            <button onClick={() => setIsPlanServicesOpen(true)} >Update Plan Services</button>
                                            {isPlanServicesOpen && <PlanServices
                                                isOpen={isPlanServicesOpen}
                                                agreementIdentifier={agreementIdentifier}
                                                closeSetup={toggleIsPlanServicesOpen}
                                                onSuccess={onPlanBenefitSuccessfullyUpdation}
                                                olderPlanBenefits={olderBenefits}
                                                getAgreementDetails={getAgreementDetails}
                                            />
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Payment Terms
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div>
                                            <div className={styles.eachTermHeader} onClick={() => isSubscriptionFeeToggler()}>
                                                <div >
                                                    {isSubscriptionFee === true ? <MdOutlineCheckBox size={20} /> : <MdOutlineCheckBoxOutlineBlank size={20} />}
                                                </div>
                                                <div>
                                                    Subscription Fee
                                                </div>
                                            </div>
                                            {isSubscriptionFee === true &&
                                                <div className={styles.termOptionWrapper}>
                                                    <div className={styles.eachTermWrapper} onClick={() => setSubscriptionFeeTerms("Every Month")} >
                                                        <div>
                                                            {subscriptionFeeTerms === "Every Month" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        <div>
                                                            Every Month
                                                        </div>
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setSubscriptionFeeTerms("Quarterly")}>
                                                        <div>
                                                            {subscriptionFeeTerms === "Quarterly" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        <div>
                                                            Quarterly
                                                        </div>
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setSubscriptionFeeTerms("Half-Yearly")}>
                                                        <div>
                                                            {subscriptionFeeTerms === "Half-Yearly" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Half-Yearly
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setSubscriptionFeeTerms("Annually")}>
                                                        <div>
                                                            {subscriptionFeeTerms === "Annually" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Annually
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div className={styles.eachTermHeader} onClick={() => isHiringFeeToggler()} >
                                                <div>
                                                    {isHiringFee === true ? <MdOutlineCheckBox size={20} /> : <MdOutlineCheckBoxOutlineBlank size={20} />}
                                                </div>
                                                <div>
                                                    Hiring Fee
                                                </div>
                                            </div>
                                            {isHiringFee &&
                                                <div className={styles.termOptionWrapper}>
                                                    <div className={styles.eachTermWrapper} onClick={() => setHiringFeeTerms("Per Hiring")} >
                                                        <div>
                                                            {hiringFeeTerms === "Per Hiring" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Per Hiring
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setHiringFeeTerms("Every Week")} >
                                                        <div>
                                                            {hiringFeeTerms === "Every Week" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Every Week
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setHiringFeeTerms("Every 15 Days")} >
                                                        <div>
                                                            {hiringFeeTerms === "Every 15 Days" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Every 15 Days
                                                    </div>
                                                    <div className={styles.eachTermWrapper} onClick={() => setHiringFeeTerms("Every Month")} >
                                                        <div>
                                                            {hiringFeeTerms === "Every Month" ? <MdOutlineRadioButtonChecked size={20} /> : <MdOutlineRadioButtonUnchecked size={20} />}
                                                        </div>
                                                        Every Month
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {activeTab === "Services" &&
                            <div className={styles.tabWrapper}>
                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        <div>
                                            Services Offered
                                        </div>
                                        <div onClick={() => toggleProfileSelector()}>
                                            {profilesCovered?.length === profileOptions?.length ?
                                                <MdOutlineCheckBox size={20} />
                                                :
                                                <MdOutlineCheckBoxOutlineBlank size={20} />
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div className={styles.allProfileWrapper}>
                                            {profileOptions?.map((item, index) => (
                                                <div key={index} onClick={() => profilesCoveredHandler(item)} className={styles.eachProfileWrapper} >
                                                    <div className={styles.checkBox}>
                                                        {profilesCovered.find(itemArr => itemArr?.ID === item?.ID) ?
                                                            <MdOutlineCheckBox size={20} />
                                                            :
                                                            <MdOutlineCheckBoxOutlineBlank size={20} />
                                                        }
                                                    </div>
                                                    <div className={styles.itemBox}>
                                                        &nbsp; {item?.category_name}
                                                    </div>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                </div>
                                <div className={styles.eachStepWrapper}>
                                    <div className={styles.stepHeaderWrapper}>
                                        Stores & Cities Covered
                                    </div>
                                    <div className={styles.stepBodyWrapper}>
                                        <div className={styles.storesCoveredWrapper}>

                                            <div>
                                                <button onClick={() => setIsStoreOpen(true)} >
                                                    Update Stores & Cities
                                                </button>
                                            </div>
                                            <div>
                                                <div>{storesCovered?.length} store(s) covered</div>
                                                <div>{citiesCovered?.length} cities covered</div>
                                            </div>
                                        </div>
                                    </div>
                                    {isStoreOpen === true && <StoreAndCitiesCovered isOpen={isStoreOpen} toggleStoreOpen={toggleStoreOpen} agreementIdentifier={agreementIdentifier} vendorIdentifier={vendorIdentifier} citiesCovered={citiesCovered} storesCovered={storesCovered} />}
                                </div>
                            </div>
                        }

                    </div>
                </div>

                {isPreviewOpen === true &&
                    <div className={styles.previewWrapper}>
                        <div className={styles.previewContainer}>
                            <div className={styles.blankWrapper}>
                                <button onClick={() => setIsPreviewOpen(false)} >Close</button>
                            </div>
                            <div className={styles.contractWrapper}>
                                <PreviewAgreement agreementIdentifier={agreementIdentifier} />
                            </div>

                        </div>
                    </div>
                }
            </div>

            <div className={styles.mainFooter}>
                <div className={styles.previewBtn}>
                    <button onClick={() => setIsPreviewOpen(true)}>Preview</button>
                </div>
                <div className={styles.updateBtn}>
                    {isUpdating !== true ?
                        <button onClick={() => updateContract()} >Update</button>
                        :
                        <button >Updating...</button>
                    }
                </div>
            </div>
        </div >
    )
}

export default ContractGenerator