import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './EnquiryCounsellor.module.css'

const EnquiryCounsellor = () => {
    const authCtx = useContext(AuthContext)
    const [candidateName, setCandidateName] = useState('Apple')
    const [profile, setProfile] = useState('यूनिसेक्स हेयर ड्रेसर')
    const [location, setLocation] = useState('सेक्टर 117, नोएडा')
    const [salary, setSalary] = useState('35000000')
    const [incentive, setIncentive] = useState('10 परसेंट')
    const [fna, setFna] = useState('2 टाइम खाना और रहना')
    const [joiningDate, setJoiningDate] = useState('20/12/2023')


    // const sendSms = async () => {
    //     const sendSmsResponse = await fetch(BASE_URL_API+"/sendOTPViaWhatsApp",
    //     {
    //         method : "POST",
    //         body : JSON.stringify({
    //             token : authCtx.token,
    //             phoneNumber : '919999648406',
    //             candidateName : candidateName,
    //             profile : profile,
    //             location : location,
    //             salary : salary,
    //             incentive : incentive,
    //             fna : fna,
    //             joiningDate : joiningDate
    //         })
    //     });
        
    //     if(!sendSmsResponse.ok) {
    //         console.log("Something went wrong : Server Error")
    //     } else {
    //         const sendSmsRespo = await sendSmsResponse.json();
    //         if(sendSmsRespo.status==="success") {

    //         } else {
    //             if(sendSmsRespo.message==='token Expired, please login again!') {
    //                 authCtx.logout()
    //             } else {

    //             }
    //         }
    //         console.log(sendSmsRespo)
    //     }
    // }

    // const sendSms = async () => {
    //     const sendSmsResponse = await fetch(BASE_URL_API+"/sendWhatsAppMessage",
    //     {
    //         method : "POST",
    //         body : JSON.stringify({
    //             token : authCtx.token,
    //             phoneNumber : '919999648406',
    //             candidateName : candidateName,
    //             profile : profile,
    //             location : location,
    //             salary : salary,
    //             incentive : incentive,
    //             fna : fna,
    //             joiningDate : joiningDate
    //         })
    //     });
        
    //     if(!sendSmsResponse.ok) {
    //         console.log("Something went wrong : Server Error")
    //     } else {
    //         const sendSmsRespo = await sendSmsResponse.json();
    //         if(sendSmsRespo.status==="success") {

    //         } else {
    //             if(sendSmsRespo.message==='token Expired, please login again!') {
    //                 authCtx.logout()
    //             } else {

    //             }
    //         }
    //         console.log(sendSmsRespo)
    //     }
    // }
    
    
    const sendSms = async () => {
        const sendSmsResponse = await fetch(BASE_URL_API+"/sendInterviewInvitationOnWhatsApp",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                phoneNumber : '919999648406',
                candidateName : candidateName,
                interviewSlot : '25th feb 2024, 12:30 PM',
                storeName : 'Lokaci - The Salon Company',
                storeAddress : 'Shop no. 101, eldeco, system 201307',
                contactDetails : '8448191901'
            })
        });
        
        if(!sendSmsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendSmsRespo = await sendSmsResponse.json();
            if(sendSmsRespo.status==="success") {

            } else {
                if(sendSmsRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(sendSmsRespo)
        }
    }
    
    const sendSmsSalon = async () => {
        const sendSmsResponse = await fetch(BASE_URL_API+"/sendInformativeMessageToSalon",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                phoneNumber : '919999648406',
                // phoneNumber : '918958120679',
                fullName : 'Lokaci - The Salon Company',
                // language : 'en_us',
                language : 'hi',
                bannerUrl : 'https://stylelink.s3.ap-south-1.amazonaws.com/for-communications/stylelink-salons/master-file-whatsapp-notification2.jpg'
            })
        });
        
        if(!sendSmsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendSmsRespo = await sendSmsResponse.json();
            if(sendSmsRespo.status==="success") {

            } else {
                if(sendSmsRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(sendSmsRespo)
        }
    }






    return (
        <div>
            <div>
                Please select the cousellor !
            </div>
            EnquiryCounsellor

            <button onClick={() => sendSms()}>Send Now</button>


            <div>
                <button onClick={() => sendSmsSalon()}>Send to Salons</button>
            </div>

        </div>
    )
}

export default EnquiryCounsellor