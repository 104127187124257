import React from 'react'
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers'
import styles from './EachClientRow.module.css'

const EachClientRow = (props) => {
    const { item, count, allExecutives } = props


    return (
        <div className={styles.eachClientRow}  >
            <div className={styles.sn}>{count + 1}</div>
            <div className={styles.companyName}>
                <div className={styles.companyNameTitle}>{item?.company_name}</div>
                <div className={styles.address}>{item?.address}</div>
            </div>
            <div className={styles.storesCount}>{item?.totalStoresCount}</div>
            <div className={styles.onboardedOn}>
                <span className={styles.created_at}>{humanReadableTimePassed(item?.created_at)}</span>
            </div>
            <div className={styles.assignedTo}>
                {item?.currentlyAssignedTo}
                <select>
                    {allExecutives?.map((itemExe, indexExe) => (
                        <option key={indexExe}>{itemExe?.firstName} {itemExe?.lastName}</option>
                    ))}
                </select>
            </div>
            <div className={styles.planType}>
                {item?.currentPlan}
            </div>
            <div className={styles.planActivatedOn}>
                {item?.currentPlanActivatedOn}
            </div>
            <div className={styles.actions}>Actions
                <Link to={`/manage-client/${item?.vendorIdentifier}`}>
                    Manage Client 
                </Link>
            </div>
        </div>
    )
}

export default EachClientRow