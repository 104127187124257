import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbCertiNDiplomasAddNew.module.css';
import RbAcademyInstiSelector from './RbAcademyInstiSelector';

const RbCertiNDiplomasAddNew = props => {
    const authCtx = useContext(AuthContext);
    let selectedUser = props.selectedUser
    const getAddedData = props.getAddedData

    const [submittingStatus, setSubmittingStatus] = useState('NOT_SUBMITTING'); // SUBMITTING, SUBMITTED, FAILED

    const certificateDurationOptions = [
        "1 Day",
        "2 Days",
        "3 Days",
        "4 Days",
        "5 Days",
        "6 Days",
        "10 Days",
        "1 Week",
        "2 Weeks",
        "3 Weeks",
        "4 Weeks",
        "5 Weeks",
        "6 Weeks",
        "7 Weeks",
        "8 Weeks",
        "9 Weeks",
        "10 Weeks",
        "11 Weeks",
        "12 Weeks",
        "1 Month",
        "2 Months",
        "3 Months",
        "4 Months",
        "5 Months",
        "6 Months",
        "7 Months",
        "8 Months",
        "9 Months",
        "10 Months",
        "11 Months",
        "1 Year",
        "2 Years",
        "3 Years",
        "4 Years",
        "5 Years",
        "6 Years",
    ]

    const [selectedAcademy, setSelectedAcademy] = useState('');
    const [course_title, setCourse_title] = useState('');
    const [course_academy, setCourse_academy] = useState('');
    const [academy_id, setAcademy_id] = useState('')
    const [academy_address, setAcademy_address] = useState('')
    const [end_date, setEnd_date] = useState('')
    const [duration, setDuration] = useState('')
    const [description, setDescription] = useState('')
    const [skills_learnt, setSkills_learnt] = useState('')
    const [gotCertificate, setGotCertificate] = useState(false)
    const [fee, setFee] = useState('')
    const [isValidated, setIsValidated] = useState(false)
    const [programMode, setProgramMode] = useState('')
    const [eventType, setEventType] = useState('')

    const selectAcademyHandler = (k) => {
        if (k !== '') {
            setSelectedAcademy(k);
            setCourse_academy(k.academy_name)
            setAcademy_address(k.academy_address)
            setAcademy_id(k.academy_id)
        } else {
            setSelectedAcademy('');
            setCourse_academy('')
            setAcademy_address('')
            setAcademy_id('')
        }

        // console.log(k);
    }

    useEffect(() => {
        if (course_title.length > 2) {
            if (course_academy.length > 2) {
                if (academy_address.length > 2) {
                    if (end_date.length > 2) {
                        if (duration.length > 2) {
                            if (description.length > 2) {
                                if (fee !== '') {
                                    if (eventType !== '') {
                                        if (programMode !== '') {
                                            setIsValidated(true);
                                        } else {
                                            setIsValidated(false);
                                        }
                                    } else {
                                        setIsValidated(false);
                                    }
                                } else {
                                    setIsValidated(false);
                                }
                            } else {
                                setIsValidated(false)
                            }
                        } else {
                            setIsValidated(false)
                        }
                    } else {
                        setIsValidated(false)
                    }
                } else {
                    setIsValidated(false)
                    console.log("academy address invalid")
                }
            } else {
                setIsValidated(false)
                console.log("course academy invalid")
            }
        } else {
            setIsValidated(false)
            console.log("course title invalid")
        }
    }, [course_title, course_academy, academy_address, end_date, duration, description, fee])


    const submitDetailsHandler = async () => {
        submitCertiHandler();
        // console.log(course_title, course_academy, academy_address, end_date, duration, description, fee)
    }


    const submitCertiHandler = async () => {
        setSubmittingStatus('SUBMITTING');
        const addCertiHandlerResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'addNew',
                    sectionType: 'certiDiploma',
                    course_title: course_title,
                    course_academy: course_academy,
                    academy_id: academy_id,
                    academy_address: academy_address,
                    end_date: end_date,
                    duration: duration,
                    description: description,
                    fee: fee,
                    programType: eventType,
                    programMode: programMode
                })
            });

        if (!addCertiHandlerResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const addCertiHandlerRespo = await addCertiHandlerResponse.json();
            if (addCertiHandlerRespo.status === 'success') {
                setSubmittingStatus('SUBMITTED')
                getAddedData();
                setSelectedAcademy('');
                setCourse_title('');
                setCourse_academy('');
                setAcademy_id('');
                setAcademy_address('');
                setEnd_date('');
                setDuration('');
                setDescription('');
                setGotCertificate(false);
                setFee('');
                setIsValidated(false);
                setProgramMode('');
                setEventType('');
            } else {
                if (addCertiHandlerRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    alert("Failed, please try again!")
                }
                setSubmittingStatus('FAILED')
            }
        }
    }

    if (submittingStatus === 'SUBMITTING') {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.rightHeaderWrapper}>
                    Adding a new {eventType} ...
                </div>
                <div className={styles.rightBodyWrapper}>
                    Hold On!..
                </div>
                <div className={styles.rightFooterWrapper}>
                    <div className={styles.rightFooterContainer}>
                        Hold on, I'm just adding the data...
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.rightHeaderWrapper}>
                Add a new certificate or diploma
            </div>
            <div className={styles.rightBodyWrapper}>
                <div className={styles.fieldsWrapper}>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Title
                            </div>
                            <div className={styles.subCaption}>
                                Enter the course/certificate/diploma/workshop title you have done?
                            </div>
                        </div>
                        <div className={styles.inputField}>
                            <input placeholder="Please enter your course name.."
                                onChange={(e) => setCourse_title(e.target.value)}
                                value={course_title}
                            />
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Program Type
                            </div>
                            <div className={styles.subCaption}>
                                Choose whether it was a course / workshop / diploma / master class?
                            </div>
                        </div>
                        <div className={styles.optionsWrapper}>
                            <button className={eventType === 'Course' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setEventType('Course')}
                            >
                                Course
                            </button>
                            <button className={eventType === 'Diploma' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setEventType('Diploma')}
                            >
                                Diploma
                            </button>
                            <button className={eventType === 'Master Class' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setEventType('Master Class')}
                            >
                                Master
                                Class</button>
                            <button className={eventType === 'Workshop' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setEventType('Workshop')}
                            >
                                Workshop
                            </button>
                        </div>

                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Program Type
                            </div>
                            <div className={styles.subCaption}>
                                Choose whether it was a online / offline event?
                            </div>
                        </div>
                        <div className={styles.optionsWrapper}>
                            <button className={programMode === 'Offline' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setProgramMode('Offline')}
                            >
                                Offline
                            </button>
                            <button className={programMode === 'Online' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => setProgramMode('Online')}
                            >
                                Online
                            </button>
                        </div>

                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <RbAcademyInstiSelector
                            selectedAcademy={selectedAcademy}
                            selectAcademyHandler={selectAcademyHandler}
                        />
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Institution Address
                            </div>
                            <div className={styles.subCaption}>
                                Enter the address of institution from where you have done course/certificate/diploma/workshop?
                            </div>
                        </div>
                        <div className={styles.inputField}>
                            <input
                                placeholder="Institution Address..."
                                onChange={(e) => setAcademy_address(e.target.value)}
                                value={academy_address}
                            />
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Duration of course / certification
                            </div>
                            <div className={styles.subCaption}>
                                Enter the name of institution from where you have done course/certificate/diploma/workshop?
                            </div>
                        </div>
                        <div className={styles.inputField}>
                            <select
                                onChange={(e) => setDuration(e.target.value)}
                            >
                                <option>Select Duration</option>
                                {certificateDurationOptions.map((item, index) => (
                                    <option key={index} >{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Course diploma certification date
                            </div>
                            <div className={styles.subCaption}>
                                Enter the name of institution from where you have done course/certificate/diploma/workshop?
                            </div>
                        </div>
                        <div className={styles.inputField}>
                            <input type="date" onChange={(e) => setEnd_date(e.target.value)} />
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Description
                            </div>
                            <div className={styles.subCaption}>
                                Enter the name of institution from where you have done course/certificate/diploma/workshop?
                            </div>
                        </div>
                        <div className={styles.inputField}>
                            <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            ></textarea>

                        </div>
                    </div>


                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                Have you received the certificate?
                            </div>
                            <div className={styles.inputField}>
                                <button className={gotCertificate === true ? styles.activateBtnWrapper : styles.btnWrapper}
                                    onClick={() => setGotCertificate(!gotCertificate)}
                                >
                                    Yes
                                </button>
                                <button className={gotCertificate === false ? styles.activateBtnWrapper : styles.btnWrapper}
                                    onClick={() => setGotCertificate(!gotCertificate)}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div>
                            <div className={styles.fieldTitle}>
                                How much the fee is/was?
                            </div>
                            <div className={styles.inputField}>
                                <input placeholder="please enter amount"
                                    onChange={(e) => setFee(e.target.value)}
                                    value={fee}
                                />
                            </div>
                        </div>
                    </div>




                </div>
            </div>
            <div className={styles.rightFooterWrapper}>
                <div className={styles.rightFooterContainer}>
                    <button className={styles.secondaryBtn}>Cancel</button>
                    {isValidated === true ?
                        <button className={styles.primaryBtn} onClick={() => submitDetailsHandler()} >Save</button>
                        :
                        <button className={styles.primaryBtnDull} >Save</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default RbCertiNDiplomasAddNew