import React, { useContext, useState } from 'react'
import Collapsible from 'react-collapsible'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/AddNewModule.module.css'

const AddNewModule = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");
    
    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const getCourseModules = props.getCourseModules;

    const [moduleTitle, setModuleTitle] = useState('');
    const [moduleSubTitle, setModuleSubTitle] = useState('');
    const [moduleOrder, setModuleOrder] = useState('');

    const [moduleOrderOptions, setModuleOrderOptions] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13, 14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50])

    const addCourseModule = async () => {
        setIsLoading(true);
        const addCourseModuleResponse = await fetch(BASE_URL_API+"/addCourseModule",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                courseId : courseId,
                moduleTitle : moduleTitle,
                moduleSubTitle : moduleSubTitle,
                moduleOrder : moduleOrder
            })
        });

        if(!addCourseModuleResponse.ok) {
            alert("Something went wrong : Server Error! Contact Admin!");
        } else {
            const addCourseModuleRespo = await addCourseModuleResponse.json();
            if(addCourseModuleRespo.status=="success") {
                getCourseModules();
                setModuleTitle('');
                setModuleSubTitle('');
                setModuleOrder('');
            } else {
                console.log(addCourseModuleRespo);
            }
        }
        setIsLoading(false);
    }

    return (
        <div>
            <div className={styles.eachModuleWrapper}>
                <Collapsible trigger={
                    <div className={styles.addNewModule}>
                        <h3>Add New Module</h3>
                    </div>
                }>
                    <div className={styles.addNewModuleContent}>
                        <div className={styles.addNewModuleEachField}>
                            <input placeholder="Enter module title"
                                onChange={(e) => setModuleTitle(e.target.value)}
                                value={moduleTitle}
                            >
                            </input>

                        </div>
                        <div className={styles.addNewModuleEachField}>
                            <input
                                placeholder="Module short description"
                                onChange={(e) => setModuleSubTitle(e.target.value)}
                                value={moduleSubTitle}
                            >
                            </input>
                        </div>
                        <div className={styles.addNewModuleEachField}>
                            <select onChange={(e) => setModuleOrder(e.target.value)}>
                                <option value="">Select Module No. </option>
                                {moduleOrderOptions.map((item, index) => (
                                    <option key={index + "OMG" + item} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        {moduleTitle.length > 0 && moduleSubTitle.length > 0 && moduleOrder!="" &&
                            <div className={styles.addNewModuleEachField}>
                                <button onClick={() => addCourseModule()} >submit</button>
                            </div>}
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export default AddNewModule