import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../ManageLeads/LeadsFeeder.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadLeadsInBulk from '../../Components/ManageLeads/UploadLeadsInBulk';


const LeadsFeeder = () => {
    const authCtx = useContext(AuthContext)
    const [isNumberValidated, setIsNumberValidated] = useState(false)
    const [allLocations, setAllLocations] = useState([])
    const [leadsSource, setLeadsSource] = useState(null)
    const [phone, setPhone] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [isValidating, setIsValidating] = useState(false)
    const [leadGeneratingStatus, setLeadGeneratingStatus] = useState('NOT_GENERATING') // GENERATING, GENERATED, GENERATION_FAILED

    const [leadsStatistics, setLeadsStatistics] = useState()
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [isUploadingBulk, setIsUploadingBulk] = useState(false)

    const [gender, setGender] = useState('Female')
    const [city, setCity] = useState('Select City')

    const [hasAlreadyRegistered, setHasAlreadyRegistered] = useState(false)

    const validatePhone = (k) => {
        return k.toString().substring(0, 10);
    }


    const phoneNumberHandler = (k) => {
        let rkey = k.trim()
        const parseNum = str => +str.replace(/[^.\d]/g, '')
        rkey = parseNum(rkey)
        setPhone(validatePhone(rkey))
    }

    const validateLead = async () => {
        setIsValidating(true)
        console.log("I'm validating the data..")
        const validateLeadResponse = await fetch(BASE_URL_API + "/lmValidateLead",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    phone: phone
                })
            });

        if (!validateLeadResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const validateLeadRespo = await validateLeadResponse.json();
            if (validateLeadRespo.status === 'success') {
                setIsNumberValidated(true)
                setHasAlreadyRegistered(false)
            } else {
                if (validateLeadRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else if (validateLeadRespo.message === 'already registered') {
                    setHasAlreadyRegistered(true)
                } else {
                    setHasAlreadyRegistered(false)
                }
                setIsNumberValidated(false)
            }
            console.log(validateLeadRespo)
        }
        setIsValidating(false)

    }

    const createLeadHandler = async () => {
        if (leadsSource !== null) {
            setLeadGeneratingStatus('GENERATING')
            const lmCreateLeadResponse = await fetch(BASE_URL_API + "/lmCreateLead",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        executiveIdentifier: authCtx.userIdentifier,
                        phone: phone,
                        firstName: firstName,
                        middleName: middleName,
                        lastName: lastName,
                        gender: gender,
                        city: city,
                        leadSource: leadsSource
                    })
                });

            if (!lmCreateLeadResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const lmCreateLeadRespo = await lmCreateLeadResponse.json();
                if (lmCreateLeadRespo.status === 'success') {
                    setLeadGeneratingStatus('GENERATED')
                    toast("Lead successfully added!")
                    setGender('')
                    setFirstName('')
                    setMiddleName('')
                    setLastName('')
                    setPhone('')
                } else {
                    if (lmCreateLeadRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        setLeadGeneratingStatus('GENERATION_FAILED')
                    }
                }
                console.log(lmCreateLeadRespo);
            }
        } else {
            alert("Please select a lead source!")
        }

    }

    const lmGetAddedLeadsStatistics = async () => {
        const lmGetAddedLeadsStatisticsResponse = await fetch(BASE_URL_API + "/lmGetAddedLeadsStatistics",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!lmGetAddedLeadsStatisticsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const lmGetAddedLeadsStatisticsRespo = await lmGetAddedLeadsStatisticsResponse.json();
            if (lmGetAddedLeadsStatisticsRespo.status === 'success') {
                setLeadsStatistics(lmGetAddedLeadsStatisticsRespo.message)


            } else {
                if (lmGetAddedLeadsStatisticsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(lmGetAddedLeadsStatisticsRespo)
                }
            }

        }
    }

    const lmGetAllLocations = async () => {
        const getAllLocationsResponse = await fetch(BASE_URL_API + "/lmGetAllLocations",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllLocationsRespo = await getAllLocationsResponse.json();
            if (getAllLocationsRespo.status === 'success') {
                setAllLocations(getAllLocationsRespo.response)
            } else {
                if (getAllLocationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllLocations([])
                }
            }
            console.log(getAllLocationsRespo)
        }
    }


    // hkj9sh 9 kdf 9--- 9  hj 648406lkh sdf089

    useEffect(() => {
        if (phone.length === 10) {
            validateLead()
        } else {
            setIsValidating(false)
            setIsNumberValidated(false)
            setHasAlreadyRegistered(false)
            setGender('')
            setFirstName('')
            setMiddleName('')
            setLastName('')
        }
    }, [phone])

    useEffect(() => {
        lmGetAllLocations()
        lmGetAddedLeadsStatistics()
    }, [])


    // console.log(leadsStatistics)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <ToastContainer />
                <UploadLeadsInBulk isOpen={isUploadingBulk} />
                <div className={styles.leftContainer}>
                    <div className={styles.leftHeader}>
                        Leads Feeder
                    </div>
                    <div className={styles.leftBody}>
                        <div>
                            <div>
                                Total Leads Added
                            </div>
                            <div>
                                {leadsStatistics}
                            </div>
                        </div>
                        <div>
                            <div className={styles.uploadLeadInBulkWrapper}>
                                <button onClick={() => setIsUploadingBulk(true)}>Upload In Bulk</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.rightHeader}>
                        Add A New Lead
                    </div>
                    <div className={styles.rightBody}>
                        <div className={styles.formWrapper}>

                            <div className={styles.leadSourceDropdown}>
                                <select onChange={(e) => setLeadsSource(e.target.value)}>
                                    <option value={null}>Select A Source</option>
                                    <option value="WORK_INDIA_BULK_DATA">WORK_INDIA_BULK_DATA</option>
                                    <option value="APNA_JOB_POSTS">APNA_JOB_POSTS</option>
                                    <option value="JOB_HAI_JOB_POSTS">JOB_HAI_JOB_POSTS</option>
                                    <option value="INDEED_JOB_POSTS">INDEED_JOB_POSTS</option>
                                    <option value="DIRECT_SOCIAL_GROUPS">DIRECT_SOCIAL_GROUPS</option>
                                </select>
                            </div>
                            <div className={styles.phoneInputWrapper}>
                                <input
                                    type="number"
                                    onChange={(e) => phoneNumberHandler(e.target.value)}
                                    value={phone}
                                    placeholder="Enter 10 digits phone number..."
                                />
                                <div className={styles.validatingLoader}>
                                    {isValidating &&
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'rgb(255, 255, 255)', display: 'block', shapeRendering: 'auto', animationPlayState: 'running', animationDelay: '0s' }} width="34px" height="34px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                            <circle cx="50" cy="50" fill="none" stroke="#788086" strokeWidth="10" r="31" strokeDasharray="146.08405839192537 50.69468613064179" style={{ animationPlayState: 'running', animationDelay: '0s' }}>
                                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.6711409395973155s" values="0 50 50;360 50 50" keyTimes="0;1" style={{ animationPlayState: 'running', animationDelay: '0s' }} />
                                            </circle>
                                        </svg>
                                    }
                                </div>
                            </div>
                            {hasAlreadyRegistered === true &&
                                <div>
                                    <div>
                                        Has Already been registered!
                                    </div>
                                </div>
                            }

                            {isNumberValidated === true &&
                                <div className={styles.restFormWrapper}>
                                    <div className={styles.nameWrapper}>
                                        <div className={styles.eachNameInput}>
                                            <input placeholder="First Name"
                                                autoFocus={true}
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.eachNameInput}>
                                            <input placeholder="Middle Name"
                                                value={middleName}
                                                onChange={(e) => setMiddleName(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.eachNameInput}>
                                            <input placeholder="Last Name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.genderWrapper}>
                                        <button className={gender === 'Female' ? styles.selectedGender : styles.eachGender}
                                            onClick={() => setGender('Female')}
                                        >
                                            Female
                                        </button>
                                        <button className={gender === 'Male' ? styles.selectedGender : styles.eachGender}
                                            onClick={() => setGender('Male')}
                                        >
                                            Male
                                        </button>
                                    </div>

                                    <div className={styles.cityWrapper}>
                                        <select onChange={(e) => setCity(e.target.value)} value={city}>
                                            <option>Select City</option>
                                            {allLocations.sort((a, b) => a.location.localeCompare(b.location)).map((item, index) => (
                                                <option key={index}>{item.location}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {city !== 'Select City' && gender !== '' && (firstName !== '' || lastName !== '' || middleName !== '') &&
                                        <div className={styles.actionWrapper}>
                                            {leadGeneratingStatus !== 'GENERATING' &&
                                                <button onClick={() => createLeadHandler()}>
                                                    Submit
                                                </button>
                                            }
                                        </div>
                                    }
                                </div>
                            }

                        </div>

                    </div>

                </div>
            </div>
        </div >
    )
}

export default LeadsFeeder