import React from 'react'
import { Link } from 'react-router-dom'
import styles from './EachAcademyEnquiryRow.module.css'

const EachAcademyEnquiryRow = props => {
    const { item, count } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.count}>{count+1}</div>
            <div className={styles.phone}>{item?.phone}</div>
            <div className={styles.fullName}>{item?.fullName}</div>
            <div className={styles.gender}>{item?.gender}</div>
            <div className={styles.fullAddress}>{item?.fullAddress}</div>
            <div className={styles.action}><Link to={item?.visitorToken}>Update</Link></div>
        </div>
    )
}

export default EachAcademyEnquiryRow