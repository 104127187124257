import React, { useState } from 'react'
import styles from './QPExperienceRequired.module.css'

const QPExperienceRequired = (props) => {
    const { experienceRequired, selectExperienceHandler } = props

    const experienceLists = ['Freshers', 'Less than 1 year', '1 Year and Above',
        '2 Years and Above', '3 Years and Above', '4 Years and Above',
        '5 Years and Above', '6 Years and Above', '7 Years and Above',
        '10 Years and Above']


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.stepTitle}>
                        Experience Required
                    </div>
                    <div className={styles.stepOptions}>
                        {experienceLists?.map((item, index) => (
                            <button key={index} 
                                className={experienceRequired===item ? styles.eachOptionSelected : styles.eachOption} 
                                onClick={() => selectExperienceHandler(item) }
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QPExperienceRequired