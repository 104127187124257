import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbCertiNDiplomas.module.css'
import EachDiplomaNCertiCard from './EachDiplomaNCertiCard';

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import RbCertiNDiplomasAddNew from './RbCertiNDiplomasAddNew';

const RbCertiNDiplomas = props => {
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    const authCtx = useContext(AuthContext);
    const [addedData, setAddedData] = useState([])
    const [fetchingAddedData, setFetchingAddedData] = useState(true)

    


    const getAddedData = async () => {
        setFetchingAddedData(true)
        const getAddedDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'certiDiploma'
                })
            });

        if (!getAddedDataResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getAddedDataRespo = await getAddedDataResponse.json();
            if (getAddedDataRespo.status === 'success') {
                if(getAddedDataRespo.message==='data not found!') {
                    setAddedData([]);
                } else {
                    setAddedData(getAddedDataRespo.response);
                }
            } else {
                if (getAddedDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getAddedDataRespo);
                }
            }
            // console.log(getAddedDataRespo);
        }
        setFetchingAddedData(false)
    }

    useEffect(() => {
        getAddedData()
    }, [selectedUser])

    
    // console.log(addedData)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType} - ({addedData.length}) item(s) found!
                </div>
                <div className={styles.bodyWrapper}>
                    {fetchingAddedData === true ?
                        <div>
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                        </div>
                        :
                        <div>
                            {addedData.length>0 ?
                                <div>
                                    {addedData.map((item, index) => (
                                        <EachDiplomaNCertiCard
                                            key={index}
                                            item={item}
                                            getAddedData={getAddedData}
                                        />
                                    ))}
                                </div>
                                :
                                <div>
                                    No records found!
                                </div>
                            }
                        </div>
                    }
                </div>


            </div>
            <div className={styles.rightWrapper}>
                <RbCertiNDiplomasAddNew 
                    selectedUser = {selectedUser}
                    getAddedData={getAddedData}
                />
            </div>
        </div>

    )
}

export default RbCertiNDiplomas