import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../LeadFilteration/LfHome.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LfHomeEachRow from '../../Components/LeadFilteration/LfHomeEachRow'

const LfHome = () => {
    const authCtx = useContext(AuthContext)
    const [allPersons, setAllPersons] = useState([])
    const [keywords, setKeywords] = useState('')
    const [dataVia, setDataVia] = useState('viaForm'); // viaProfile, viaRemarks
    const [dataViaForm, setDataViaForm] = useState([])

    const getEstimatedCourseLeads = async () => {
        const getEstimatedCourseLeadsResponse = await fetch(BASE_URL_API + "/getEstimatedCourseLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    keywords: keywords
                })
            });

        if (!getEstimatedCourseLeadsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEstimatedCourseLeadsRespo = await getEstimatedCourseLeadsResponse.json()
            if (getEstimatedCourseLeadsRespo.status === 'success') {
                setAllPersons(getEstimatedCourseLeadsRespo.response)
            } else {
                if (getEstimatedCourseLeadsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllPersons([])
                }
            }
            console.log(getEstimatedCourseLeadsRespo)
        }

    }



    useEffect(() => {
        if (keywords.length > 2) {
            getEstimatedCourseLeads()
        }
    }, [keywords])

    const getCourseLeadsFromEnrollmentForm = async () => {
        const getCourseLeadsFromEnrollmentFormResponse = await fetch(BASE_URL_API+"/getCourseLeadsFromEnrollmentForm",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getCourseLeadsFromEnrollmentFormResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCourseLeadsFromEnrollmentFormRespo = await getCourseLeadsFromEnrollmentFormResponse.json();
            if(getCourseLeadsFromEnrollmentFormRespo.status==="success") {
                setDataViaForm(getCourseLeadsFromEnrollmentFormRespo.response)
            } else {
                if(getCourseLeadsFromEnrollmentFormRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setDataViaForm([])
                }
            }
            console.log(getCourseLeadsFromEnrollmentFormRespo)
        }
    }

    useEffect(() => {
        dataVia==='viaForm' && getCourseLeadsFromEnrollmentForm()
    }, [dataVia])
    


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainHeader}>
                <div>
                    <button onClick={() => setDataVia('viaForm')}>Via Form</button>
                    <button onClick={() => setDataVia('viaProfile')}>Via Profile</button>
                    <button onClick={() => setDataVia('viaRemarks')}>Via Remarks</button>
                </div>
                <div>
                    <input
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder="Type keywords..."
                    />
                </div>
            </div>
            {dataVia === 'viaForm' &&
                <div className={styles.mainBody}>
                    <div className={styles.headerRow}>
                        <div className={styles.srCol}>#</div>
                        <div className={styles.uiCol}>Full Name</div>
                        <div className={styles.rmCol}>Address</div>
                        <div className={styles.dateCol}>Date</div>
                        <div className={styles.actionCol}>Action</div>
                    </div>
                    <div className={styles.bodyWrapper}>
                        {dataViaForm?.map((item, index) => (
                            <LfHomeEachRow
                                key={index}
                                item={item}
                                index={index}
                                enquireType="UNREGISTERED"
                            />
                        ))}
                    </div>
                </div>
            }

            {dataVia === 'viaRemarks' &&
                <div className={styles.mainBody}>
                    <div className={styles.headerRow}>
                        <div className={styles.srCol}>#</div>
                        <div className={styles.uiCol}>User ID</div>
                        <div className={styles.rmCol}>Remark</div>
                        <div className={styles.dateCol}>Date</div>
                        <div className={styles.actionCol}>Action</div>
                    </div>
                    <div className={styles.bodyWrapper}>
                        {allPersons.map((item, index) => (
                            <LfHomeEachRow
                                key={index}
                                item={item}
                                index={index}
                                enquireType="REGISTERED"
                            />
                        ))}
                    </div>
                </div>
            }

        </div>
    )
}

export default LfHome