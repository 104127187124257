import React from 'react'
import { IoAddSharp, IoRemoveSharp } from 'react-icons/io5';
import styles from '../ResumeBuilding/EachSkillWrapperCard.module.css'

const EachSkillWrapperCard = props => {
    let itemSkill = props.itemSkill;
    const toggleItemHandler = props.toggleItemHandler
    const itemsToBeAddedMale = props.itemsToBeAddedMale;
    const itemsToBeAddedFemale = props.itemsToBeAddedFemale;
    let selectedGenderInOptions = props.selectedGenderInOptions;


    return (
        <div className={styles.eachSkillWrapper}
            onClick={() => toggleItemHandler(itemSkill.ID)}
        >
            <div className={styles.skillTitle}>{itemSkill.skill}</div>
            {selectedGenderInOptions === 'Male' ?
                <div className={styles.removeBtn}>
                    {itemsToBeAddedMale.includes(itemSkill.ID) === false ?
                        <IoAddSharp />
                        :
                        <IoRemoveSharp />
                    }
                </div>
                :
                <div className={styles.removeBtn}>
                    {itemsToBeAddedFemale.includes(itemSkill.ID) === false ?
                        <IoAddSharp />
                        :
                        <IoRemoveSharp />
                    }
                </div>
            }
        </div>
    )
}

export default EachSkillWrapperCard