import React from 'react'
import { getAge, humanReadableTimePassed } from '../../Helper/helpers'
import styles from './EachTableRowInCASW.module.css'

const EachTableRowInCASW = props => {
    const { item, count } = props
    return (
        <div className={styles.eachRow}>
            <div className={styles.srno}>{count+1}</div>
            <div className={styles.fullname}>
                <div>{item?.stylist_name}</div>
                <div className={styles.smallTxt}> {getAge(item?.stylist_birthdate)} years old - {item?.stylist_gender}</div>
            </div>
            <div className={styles.phone}>{item?.phone}</div>
            <div className={styles.workDomains}>
                <div className={styles.primaryWorkDomain}>{item?.primaryWorkDomain}</div>
                <div className={styles.secondaryWorkDomain}>{item?.secondaryWorkDomain}</div>
                <div className={styles.tertiaryWorkDomain}>{item?.tertiaryWorkDomain}</div>
            </div>
            <div className={styles.minimumSalaryExpectation}>&#8377;{item?.minimumSalaryExpectation}</div>
            <div className={styles.lastContacted}>{humanReadableTimePassed(item?.actionOn)}</div>
            <div className={styles.status}>{item?.remarks}</div>
            <div className={styles.onStylelinkSince}>{humanReadableTimePassed(item?.onStylelinkSince)}</div>
            <div className={styles.action}>
                <a href={"https://admin.stylelink.in/manage-users/grow/"+item?.stylistIdentifier}
                    target="_blank"
                >
                    View Profile
                </a>
            </div>
        </div>
    )
}

export default EachTableRowInCASW