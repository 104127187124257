import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbPreferences.module.css'


import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import RbPreferencesAddNew from './RbPreferencesAddNew';

const RbPreferences = props => {
    let selectedProfileType = props.selectedProfileType;

    let selectedUser = props.selectedUser;
    const [addedData, setAddedData] = useState([])
    const authCtx = useContext(AuthContext)
    const [fetchingAddedData, setFetchingAddedData] = useState(true)


    const getAddedData = async () => {
        setFetchingAddedData(true)
        const getAddedDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'preferences'
                })
            });

        if (!getAddedDataResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getAddedDataRespo = await getAddedDataResponse.json();
            if (getAddedDataRespo.status === 'success') {
                setAddedData(getAddedDataRespo.response);
            } else {
                if (getAddedDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAddedData([])
                    console.log(getAddedDataRespo);
                }
            }
        }
        setFetchingAddedData(false)
    }

    useEffect(() => {
        getAddedData()
    }, [selectedUser])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType}
                </div>
                <div className={styles.bodyWrapper}>
                    <div>
                        <div>Location Preferences you have Added</div>

                        {fetchingAddedData === true ?
                            <div>
                                <ShimmerThumbnail height={160} className={styles.containerLoader} />
                                <ShimmerThumbnail height={160} className={styles.containerLoader} />
                                <ShimmerThumbnail height={160} className={styles.containerLoader} />
                                <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            </div>
                            :
                            <div>
                                {addedData?.length > 0 ?
                                    <div>
                                        {addedData.map((item, index) => (
                                            <div className={styles.eachCityWrapper} key={index}>
                                                <div className={styles.cityLeftWrapper}>
                                                    {item.location}
                                                </div>
                                                {item.valPreArea !== null &&
                                                    <div className={styles.cityRightWrapper}>
                                                        {item.valPreArea.map((itemArea, indexArea) => (
                                                            <div className={styles.areaName} key={indexArea}>
                                                                {itemArea}
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div>
                                        No records found!
                                    </div>
                                }
                            </div>
                        }

                    </div>


                </div>
            </div>
            <div className={styles.rightWrapper}>
                <RbPreferencesAddNew
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                    getAddedData={getAddedData}
                />
            </div>
        </div>
    )
}

export default RbPreferences