import React from 'react'
import styles from '../ManageJobs/EachJobInList.module.css'

const EachJobInList = props => {
    let item = props.item;
    const selectedJob = props.selectedJob;
    const fetchJobDetails = props.fetchJobDetails;

    // console.log(item)

    return (
        <div 
            className={selectedJob == item ? styles.eachJobNavSelected : styles.eachJobNav} key={item.job_id} 
            onClick={() => fetchJobDetails(item)}
        >
            <div className={styles.categoryContainer}>
                <div className={styles.categoryFc}>{item.job_category_name.substr(0, 1)}</div>
            </div>
            <div className={styles.eachJobNavRight}>
                <div>
                    {item.company_name}
                </div>
                <div className={styles.jobTitle}>{item.title}</div>
                <div className={styles.postedOn}>
                    <div>
                        <div>
                            {item.address}
                        </div>
                        {item.creation_date}
                    </div>
                    {item.isClosed === '0' ?
                        <div className={styles.jobStatusActive}>
                            Active
                        </div>
                        :
                        <div className={styles.jobStatusClosed}>
                            Closed
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EachJobInList