import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import ChooseFromOlderAudiences from './Audiences/ChooseFromOlderAudiences'
import CreateNewAudience from './Audiences/CreateNewAudience'
import styles from './AudienceSettings.module.css'

const AudienceSettings = () => {
    
    const [audienceSize, setAudienceSize] = useState('')
    const authCtx = useContext(AuthContext)
    const [audienceCreationMode, setAudienceCreationMode] = useState('NEW') // OLD

    const audienceSizeHandler = (k) => setAudienceSize(k)
   

    // console.log("selectedTables : ", selectedTables)





    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeaderWrapper}>
                        <div>
                            <button onClick={() => setAudienceCreationMode('OLD')}>Old</button>
                            <button onClick={() => setAudienceCreationMode('NEW')}>New</button>
                        </div>
                    </div>
                    {audienceCreationMode === 'OLD' && <ChooseFromOlderAudiences audienceSize={audienceSize} />}
                    {audienceCreationMode === 'NEW' && <CreateNewAudience audienceSize={audienceSize} />}

                    <div className={styles.leftColWrapper}>

                        

                        {/* you can add numbers manually also  */}

                        <div>

                        </div>


                        


                    </div>


                    <div className={styles.rightColWrapper}>
                        <div className={styles.estimatedCardWrapper}>
                            <div className={styles.estTitle}>Estimated Audience Size</div>
                            <div>
                                <div className={styles.audienceSizeVal}>
                                    {audienceSize}
                                </div>
                            </div>
                            <div >
                                <div className={styles.smallTxt}>
                                    Audience size based on the calculated data from selected tables, it automatically excludes the duplications.
                                </div>
                            </div>


                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default AudienceSettings