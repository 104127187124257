import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTCreateAcademicLocations.module.css'

const FTCreateAcademicLocations = (props) => {
    const { isOpen, onClose, partnersIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [locationTitle, setLocationTitle] = useState('')
    const [locationAddress, setLocationAddress] = useState('')
    const [city, setCity] = useState('')
    const [allLocations, setAllLocations] = useState([])
    const [isCreatingLocation, setIsCreatingLocation] = useState(false)

    const lmGetAllLocations = async () => {
        const lmGetAllLocationsResponse = await fetch(BASE_URL_API + "/lmGetAllLocations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!lmGetAllLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const lmGetAllLocationsRespo = await lmGetAllLocationsResponse.json()
            if (lmGetAllLocationsRespo.status === "success") {
                setAllLocations(lmGetAllLocationsRespo.response)
            } else {
                if (lmGetAllLocationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllLocations([])
                }
            }
            console.log(lmGetAllLocationsRespo)
        }
    }

    useEffect(() => {
        lmGetAllLocations()
    }, [])


    const ftcreatePartnersLocation = async () => {
        setIsCreatingLocation(true)
        const ftcreatePartnersLocationResponse = await fetch(BASE_URL_API + "/ftcreatePartnersLocation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    partnersIdentifier: partnersIdentifier,
                    locationTitle: locationTitle,
                    locationAddress: locationAddress,
                    city: city
                })
            });

        if (!ftcreatePartnersLocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const ftcreatePartnersLocationRespo = await ftcreatePartnersLocationResponse.json()
            if (ftcreatePartnersLocationRespo.status === "success") {
                toast("successfully created!")
            } else {
                if (ftcreatePartnersLocationRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(ftcreatePartnersLocationRespo?.message)
                }
            }
            console.log(ftcreatePartnersLocationRespo)
        }
        setIsCreatingLocation(false)
    }





    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div>
                            <button onClick={() => onClose()}>Close</button>
                        </div>
                        <div>
                            <div className={styles.formWrapper}>
                                <div className={styles.eachField}>
                                    <select onChange={(e) => setCity(e.target.value)}>
                                        <option>Select City</option>
                                        {allLocations.sort((a, b) => a.location.localeCompare(b.location))?.map((item, index) => (
                                            <option key={index}>{item?.location}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={styles.eachField}>
                                    <input placeholder="Enter location title..."
                                        value={locationTitle}
                                        onChange={(e) => setLocationTitle(e.target.value)}
                                    />
                                </div>
                                <div className={styles.eachField}>
                                    <textarea value={locationAddress} placeholder="Please enter address" onChange={(e) => setLocationAddress(e.target.value)} >

                                    </textarea>
                                </div>
                                {isCreatingLocation === false ?
                                    <div>
                                        <button onClick={() => ftcreatePartnersLocation()} >Submit</button>
                                    </div>
                                    :
                                    <div>
                                        <button>Creating now..</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FTCreateAcademicLocations