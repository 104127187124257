import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './StoreAndCitiesCovered.module.css'

import { toast, ToastContainer } from 'react-toastify'

const StoreAndCitiesCovered = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, toggleStoreOpen, vendorIdentifier, agreementIdentifier, storesCovered, citiesCovered } = props

    const [storeList, setStoreList] = useState([])
    const [storeListFiltered, setstoreListFiltered] = useState([])
    const [selectedStores, setSelectedStores] = useState(storesCovered)

    const [citiesList, setCitiesList] = useState([])
    const [citiesListFiltered, setCitiesListFiltered] = useState([])
    const [selectedCities, setSelectedCities] = useState(citiesCovered)

    const [isUpdating, setIsUpdating] = useState(false)

    const getStoreListing = async () => {
        const getStoreListingResponse = await fetch(BASE_URL_API + "/getStoreListing",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!getStoreListingResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreListingRespo = await getStoreListingResponse.json()
            if (getStoreListingRespo.status === "success") {
                setStoreList(getStoreListingRespo.response)
                setstoreListFiltered(getStoreListingRespo.response)
            } else {
                if (getStoreListingRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStoreList([])
                }
            }
            console.log(getStoreListingRespo)
        }

    }

    const lmGetAllLocations = async () => {
        const lmGetAllLocationsResponse = await fetch(BASE_URL_API + "/lmGetAllLocations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!lmGetAllLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const lmGetAllLocationsRespo = await lmGetAllLocationsResponse.json()
            if (lmGetAllLocationsRespo.status === "success") {
                setCitiesList(lmGetAllLocationsRespo.response)
                setCitiesListFiltered(lmGetAllLocationsRespo.response)
            } else {
                if (lmGetAllLocationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCitiesList([])
                }
            }
            console.log(lmGetAllLocationsRespo)
        }

    }

    const selectStoreHandler = (k) => {
        if (selectedStores.some(item => item?.store_id === k?.store_id)) {
            setSelectedStores(selectedStores.filter(itemFil => itemFil?.store_id !== k?.store_id))
        } else {
            setSelectedStores([...selectedStores, k])
        }
    }


    const selectCitiesHandler = (k) => {
        if (selectedCities.some(item => item?.ID === k?.ID)) {
            setSelectedCities(selectedCities.filter(itemFil => itemFil?.ID !== k?.ID))
        } else {
            setSelectedCities([...selectedCities, k])
        }
    }

    useEffect(() => {
        getStoreListing().then(() => lmGetAllLocations())
    }, [vendorIdentifier])


    function filterData(data, searchKey) {
        return data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        );
    }

    const searchCitiesHandler = (k) => {
        setCitiesListFiltered(filterData(citiesList, k))
    }

    const searchStoreHandler = (k) => {
        setstoreListFiltered(filterData(storeList, k))
    }

    const updateStoreAndCitiesCovered = async () => {
        setIsUpdating(true)
        const updateStoreAndCitiesCoveredResponse = await fetch(BASE_URL_API + "/updateStoreAndCitiesCovered",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier,
                    storesCovered: JSON.stringify(selectedStores),
                    citiesCovered: JSON.stringify(selectedCities)
                })
            });

        if (!updateStoreAndCitiesCoveredResponse.ok) {
            console.log(updateStoreAndCitiesCoveredResponse)
        } else {
            const updateStoreAndCitiesCoveredRespo = await updateStoreAndCitiesCoveredResponse.json()
            if (updateStoreAndCitiesCoveredRespo.status === "success") {
                toast("Successfully updated the Stores  & Cities")
                toggleStoreOpen()
            } else {
                if (updateStoreAndCitiesCoveredRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(updateStoreAndCitiesCoveredRespo)
                }
            }
        }
        setIsUpdating(false)
    }

    // console.log(selectedCities)
    const toggleCitiesSelector = () => {
        if (selectedCities?.length === citiesList?.length) {
            setSelectedCities([])
        } else {
            setSelectedCities(citiesList)
        }
    }
    
    const toggleStoresSelector = () => {
        if (selectedStores?.length === storeList?.length) {
            setSelectedStores([])
        } else {
            setSelectedStores(storeList)
        }
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeaderWrapper}>
                            <div>
                                Store And Cities Covered
                            </div>
                            <div>
                                <button onClick={() => toggleStoreOpen()}>Cancel </button>
                                {isUpdating === false ?
                                    <button onClick={() => updateStoreAndCitiesCovered()} >Update</button>
                                    :
                                    <button>Updating Now...</button>
                                }
                            </div>
                        </div>
                        <div className={styles.mainBodyWrapper}>
                            <div className={styles.mainBodyContainer}>

                                <div className={styles.citiesWrapper}>
                                    <div className={styles.titleWrapper}>
                                        <div className={styles.title}>
                                            Cities - ({selectedCities?.length}/{citiesList?.length})
                                        </div>
                                        <div onClick={() => toggleCitiesSelector()} className={styles.checkBoxWrapper} >
                                            {citiesList?.length === selectedCities?.length ?
                                                <MdOutlineCheckBox size={20} />
                                                :
                                                <MdOutlineCheckBoxOutlineBlank size={20} />
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.inputBoxWrapper}>
                                        <input placeholder="Search For a City" onChange={(e) => searchCitiesHandler(e.target.value)} />
                                    </div>
                                    <div className={styles.listOfCitiesWrapper}>
                                        {citiesListFiltered?.map((item, index) => (
                                            <div className={styles.eachStore} key={index} onClick={() => selectCitiesHandler(item)} >
                                                <div>
                                                    {selectedCities.some(itemSelected => itemSelected?.ID === item?.ID) ?
                                                        <MdOutlineCheckBox size={20} />
                                                        :
                                                        <MdOutlineCheckBoxOutlineBlank size={20} />
                                                    }
                                                </div>
                                                <div>
                                                    <div className={styles.companyName}>
                                                        {item?.location}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.storesWrapper}>
                                    <div className={styles.titleWrapper}>
                                        <div className={styles.title}>
                                            Stores ({selectedStores?.length} / {storeList?.length})
                                        </div>

                                        <div onClick={() => toggleStoresSelector()} className={styles.checkBoxWrapper} >
                                            {storeList?.length === selectedStores?.length ?
                                                <MdOutlineCheckBox size={20} />
                                                :
                                                <MdOutlineCheckBoxOutlineBlank size={20} />
                                            }
                                        </div>
                                    </div>

                                    <div className={styles.inputBoxWrapper}>
                                        <input placeholder="Search for a store" onChange={(e) => searchStoreHandler(e.target.value)} />
                                    </div>
                                    <div className={styles.listOfCitiesWrapper}>
                                        {storeListFiltered?.map((item, index) => (
                                            <div className={styles.eachStore} key={index} onClick={() => selectStoreHandler(item)} >
                                                <div>
                                                    {selectedStores.some(itemSelected => itemSelected?.store_id === item?.store_id) ?
                                                        <MdOutlineCheckBox size={20} />
                                                        :
                                                        <MdOutlineCheckBoxOutlineBlank size={20} />
                                                    }
                                                </div>
                                                <div className={styles.storeDetailsWrapper}>
                                                    <div className={styles.companyName}>
                                                        {item?.company_name}
                                                    </div>
                                                    <div className={styles.address}>
                                                        {item?.address}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StoreAndCitiesCovered