import React, { useContext, useState } from 'react'
import { FaChevronLeft, FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Tasks/SearchIncomingLead.module.css'

const SearchIncomingLead = () => {
    const authCtx = useContext(AuthContext)
    const [suggestedUsers, setSuggestedUsers] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const searchKeyHandler = async (k) => {
        setSearchKey(k)
        if (k.length > 4) {
            searchLead(k)
        } else {
            setSuggestedUsers([])
        }
    }

    const searchLead = async (k) => {
        const searchLeadResponse = await fetch(BASE_URL_API + "/adminSearchLead",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    leadIdentifier: k
                })
            });
        if (!searchLeadResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const searchLeadRespo = await searchLeadResponse.json();
            if (searchLeadRespo.status === 'success') {
                setSuggestedUsers(searchLeadRespo.response)
            } else {
                if (searchLeadRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setSuggestedUsers([])
                }
            }
            console.log(searchLeadRespo)
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.goToLeads}>
                    <FaChevronLeft /> &nbsp;
                    <Link to="/tasks">Go To Leads</Link>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.searchBoxWrapper}>
                    <div className={suggestedUsers.length > 0 ? styles.searchBoxContainerWithResults : styles.searchBoxContainer}>
                        <div className={styles.searchIcon}>
                            <FaSearch />
                        </div>
                        <div className={styles.searchInput}>
                            <input placeholder="Type 10 digits phone number..." onChange={(e) => searchKeyHandler(e.target.value)} autoFocus={true} ></input>
                        </div>
                    </div>
                    {suggestedUsers.length > 0 &&
                        <div className={styles.suggestedListContainer}
                            style={{ animation: '.25s cubic-bezier(0, 0.79, 0.78, 1.07) 0s 1 normal forwards running slideDown' }}
                        >
                            {suggestedUsers.map((item, index) => (
                                <Link to={"/tasks/lead/" + item.leadIdentifier}>
                                    <div className={styles.eachSuggestionWrapper} key={index}>
                                        <div className={styles.upperPart}>
                                            <div>
                                                {item.firstName} {item.middleName} {item.lastName}
                                            </div>
                                            <div>
                                                {item.leadIdentifier}
                                            </div>
                                        </div>
                                        <div className={styles.lowerPart}>
                                            <div className={styles.city}>
                                                {item.city}
                                            </div>
                                            <div className={styles.gender}>
                                                {item.gender}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default SearchIncomingLead