import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './CreateCoursePricing.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCoursePricing = props => {
    const { courseToken } = props
    const authCtx = useContext(AuthContext)
    const [mrp, setMrp] = useState(0)
    const [dp, setDp] = useState(0)
    const [lp, setLp] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const getAcadCoursePricing = async () => {
        const getAcadCoursePricingResponse = await fetch(BASE_URL_API + "/acad-getCoursePrice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseToken: courseToken
                })
            });

        if (!getAcadCoursePricingResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAcadCoursePricingRespo = await getAcadCoursePricingResponse.json()
            if (getAcadCoursePricingRespo.status === "success") {
                setMrp(getAcadCoursePricingRespo?.response?.mrp)
                setDp(getAcadCoursePricingRespo?.response?.dp)
                setLp(getAcadCoursePricingRespo?.response?.lp)
            } else {
                if (getAcadCoursePricingRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(getAcadCoursePricingRespo)
                }
            }
        }
    }

    const updateAcadCoursePricing = async () => {
        setIsLoading(true)
        const updateAcadCoursePricingResponse = await fetch(BASE_URL_API + "/acad-updateCoursePrice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    mrp: mrp,
                    dp: dp,
                    lp: lp,
                    courseToken: courseToken
                })
            });

        if (!updateAcadCoursePricingResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateAcadCoursePricingRespo = await updateAcadCoursePricingResponse.json()
            if (updateAcadCoursePricingRespo.status === "success") {
                toast("Price updated successfully!")
            } else {
                if (updateAcadCoursePricingRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast("Failed!! Price could not updated!")
                }
            }
            console.log(updateAcadCoursePricingRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getAcadCoursePricing()
    }, [courseToken])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mrpCard}>
                    <div>MRP</div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.rupeeIcon}>
                            &#8377;
                        </div>
                        <input onChange={(e) => setMrp(e.target.value)} value={mrp} autoFocus={true} onFocus={(e) => e.target.select()} />
                    </div>
                    <div className={styles.updateWrapper}>
                        Our opening price for this course!
                    </div>
                </div>
                <div className={styles.dpCard}>
                    <div>Discounted Price</div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.rupeeIcon}>
                            &#8377;
                        </div>
                        <input onChange={(e) => setDp(e.target.value)} value={dp} onFocus={(e) => e.target.select()} />
                    </div>
                    <div className={styles.updateWrapper}>
                        Our discounted price for this course! Above which competitors are selling similiar course.
                    </div>
                </div>
                <div className={styles.lpCard}>
                    <div>Lowest Price</div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.rupeeIcon}>
                            &#8377;
                        </div>
                        <input onChange={(e) => setLp(e.target.value)} value={lp} onFocus={(e) => e.target.select()} />
                    </div>
                    <div className={styles.updateWrapper}>
                        Lowest price, below which we can't sell our course!
                    </div>
                </div>
            </div>
            <div className={styles.updateBtnWrapper}>
                {isLoading === true ?
                    <button >Updating Price(s)...</button>
                    :
                    <button onClick={() => updateAcadCoursePricing()}>Update</button>
                }
            </div>
        </div>
    )
}

export default CreateCoursePricing