import React from 'react'
import styles from './ManageStoresFilter.module.css'

const ManageStoresFilter = (props) => {
    const { isOpen, onClose, hasCityUpdated, cityUpdatedHandler, isLoading } = props
    window.onclick = event => event.target.id === "mcmsfjhd" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="mcmsfjhd">
                    <div className={styles.mainContent}>
                        <div className={styles.header}>
                            <div>
                                <button onClick={() => onClose()}>Close</button>
                            </div>
                            <div>
                                {isLoading && <div>Please wait, loading data...</div>}
                            </div>
                        </div>
                        <div className={styles.body}>
                            <div>
                                <div className={styles.filterTitle}>
                                    Has city updated?
                                </div>
                                <div className={styles.btnWrapper}>
                                    <button className={hasCityUpdated === true ? styles.activeBtn : styles.defBtn} onClick={() => cityUpdatedHandler(true)}>Yes</button>
                                    <button className={hasCityUpdated === false ? styles.activeBtn : styles.defBtn} onClick={() => cityUpdatedHandler(false)}>No</button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.footer}>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageStoresFilter