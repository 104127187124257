import React, { useEffect, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { FaPlus, FaUserCircle, FaWhatsapp } from 'react-icons/fa'
import { MdPhone } from 'react-icons/md'
import styles from '../Tasks/EachLargeLead.module.css'

const EachLargeLead = props => {
    const { currentLead, updateLeadAction, startActionCreation, initiateProfileCreation } = props
    const [unmaskPhone, setUnmaskPhone] = useState(false)
    const leadPhone = currentLead.leadIdentifier

    useEffect(() => {
        setUnmaskPhone(false)
    }, [currentLead])

    const msgTxt = "Hi,%0AAre you looking for a *job* in a _salon or academy_?%0AWe help stylist find the best job in their nearby location in various profiles such as:%0A1. Makeup Artist%0A2. Nail  Artist%0A3. Gents Hairdresser%0A4. Unisex Hairdresser%0A5. Beautician%0A6. Salon Helper%0A7. Beauty Trainer%0A8. Salon Manager%0AIf interested, please call me!!"
    const waUrl = `https://api.whatsapp.com/send/?phone=91${leadPhone}&text=${msgTxt}&type=phone_number&app_absent=1`


    return (
        <div className={styles.leadProfileSummaryWrapper}>
            <div className={styles.lpswTop}>
                <div>
                    Lead Open since
                </div>
                <div className={styles.createProfileWrapper}>
                    <button onClick={() => initiateProfileCreation()}>Create Profile</button>
                </div>
            </div>
            <div className={styles.lpswUpper}>
                <div className={styles.lpswuProfile}>
                    <FaUserCircle size={60} />
                </div>
                <div className={styles.lpswuDetails}>
                    <div className={styles.stylistName}>
                        {currentLead.firstName} {currentLead.middleName} {currentLead.lastName}
                    </div>
                    <div className={styles.pocWrapper}>
                        {unmaskPhone === true ?
                            <div className={styles.phoneWrapper}>
                                +91-{currentLead.leadIdentifier}
                            </div>
                            :
                            <div className={styles.phoneWrapper}>
                                +91-XXXX-XX-XXXX
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.lpswuActions}>
                    <div className={styles.eachlpswua}>
                        <button>
                            <FaPlus size={16} color="#a4a4a4" />
                        </button>
                    </div>
                    <div className={styles.eachlpswua}>
                        <a href={waUrl} target="_blank">
                            <FaWhatsapp size={20} color="#a4a4a4" />
                        </a>
                    </div>
                    <div className={styles.eachlpswua}>
                        <button onClick={() => {
                            setUnmaskPhone(true)
                            updateLeadAction('NUMBER_DIALED', 'Executive clicked to see lead phone number.')
                            startActionCreation(true)
                        }
                        }
                        >
                            <MdPhone size={20} color="#a4a4a4" />
                        </button>
                    </div>
                    <div className={styles.eachlpswua}>
                        <button>
                            <BsThreeDots size={20} color="#a4a4a4" />
                        </button>
                    </div>

                </div>
            </div>
            <div className={styles.lpswLower}>
                <div className={styles.lpswlIndi}>
                    <div className={styles.lpswlIndiCaption}>
                        Location
                    </div>
                    <div className={styles.lpswlIndiValue}>
                        {currentLead.city}
                    </div>
                </div>
                <div className={styles.lpswlIndi}>
                    <div className={styles.lpswlIndiCaption}>
                        -
                    </div>
                    <div className={styles.lpswlIndiValue}>
                        -
                    </div>
                </div>
                <div className={styles.lpswlIndi}>
                    <div className={styles.lpswlIndiCaption}>
                        -
                    </div>
                    <div className={styles.lpswlIndiValue}>
                        -
                    </div>
                </div>
                <div className={styles.lpswlIndi}>
                    <div className={styles.lpswlIndiCaption}>
                        -
                    </div>
                    <div className={styles.lpswlIndiValue}>
                        -
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EachLargeLead