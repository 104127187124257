import React, { useContext, useEffect, useState } from 'react'
import { BsCheck } from 'react-icons/bs';
import { FaCross } from 'react-icons/fa';
import { FcCancel } from 'react-icons/fc';
import { humanReadableTimePassed, isValidMobileNumber, validatePhoneNumber } from '../../Helper/helpers';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from './MSPOCs.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosStar } from "react-icons/io";



const MSPOCs = props => {
    const { storeIdentifier } = props;
    const authCtx = useContext(AuthContext)
    // console.log(storeIdentifier)
    const [activeReq, setActiveReq] = useState('CREATE_NEW') // SHOW_ALL

    const [phoneNumber, setPhoneNumber] = useState('')
    const [personnelName, setPersonnelName] = useState('')
    const [gender, setGender] = useState('FEMALE')
    const [designation, setDesignation] = useState('') // OWNER
    const [isCreatingPoc, setIsCreatingPoc] = useState(false)
    const [storeData, setStoreData] = useState([])
    const [selectedPoc, setSelectedPoc] = useState(null)
    const [viData, setViData] = useState([])
    const [hasFieldValidated, setHasFieldValidated] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isSettingPrimary, setIsSettingPrimary] = useState(false)

    const getStorePocs = async () => {
        const getStorePocsResponse = await fetch(BASE_URL_API + "/getStorePocs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getStorePocsResponse.ok) {
            console.log("Something went wrong : Server error")
        } else {
            const getStorePocsRespo = await getStorePocsResponse.json()
            if (getStorePocsRespo.status === "success") {
                setStoreData(getStorePocsRespo.response)
            } else {
                if (getStorePocsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setViData(getStorePocsRespo.response)
                    setStoreData([])
                }
            }
            console.log(getStorePocsRespo.response)
        }
    }


    const createNewPoc = async () => {
        console.log(storeIdentifier, phoneNumber, personnelName, gender, designation)
        setIsCreatingPoc(true)
        const createNewStorePocResponse = await fetch(BASE_URL_API + "/createNewStorePoc",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier,
                    phoneNumber: phoneNumber,
                    personnelName: personnelName,
                    personnelGender: gender,
                    designation: designation
                })
            });

        if (!createNewStorePocResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewStorePocRespo = await createNewStorePocResponse.json()
            if (createNewStorePocRespo.status === "success") {
                getStorePocs()
                setPhoneNumber('')
                setDesignation('')
                setGender('')
                setPersonnelName('')
                toast("Successfully created!")
            } else {
                if (createNewStorePocRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else if (createNewStorePocRespo.message === "poc already exists") {
                    toast("Contact already exists!")
                } else {
                    toast(createNewStorePocRespo.message)
                }
            }
            console.log(createNewStorePocRespo)
        }
        setIsCreatingPoc(false)
    }

    const deleteStorePoc = async () => {
        setIsDeleting(true)
        const deleteStorePocResponse = await fetch(BASE_URL_API + "/deleteStorePoc",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    pocIdentifier: selectedPoc?.pocIdentifier,
                    executiveIdentifier: authCtx?.userIdentifier
                })
            });

        if (!deleteStorePocResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteStorePocRespo = await deleteStorePocResponse.json()
            if (deleteStorePocRespo.status === "success") {
                getStorePocs()
                setSelectedPoc(null)
            } else {
                if (deleteStorePocRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(deleteStorePocRespo)
        }
        setIsDeleting(false)
    }

    const setStorePocAsPrimary = async () => {
        setIsSettingPrimary(true)
        const setStorePocAsPrimaryResponse = await fetch(BASE_URL_API + "/setStorePocAsPrimary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    pocIdentifier: selectedPoc?.pocIdentifier,
                    executiveIdentifier: authCtx?.userIdentifier,
                    storeIdentifier : storeIdentifier
                })
            });

        if (!setStorePocAsPrimaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const setStorePocAsPrimaryRespo = await setStorePocAsPrimaryResponse.json()
            if (setStorePocAsPrimaryRespo.status === "success") {
                getStorePocs()
                setSelectedPoc(null)
                toast(setStorePocAsPrimaryRespo.message)
            } else {
                if (setStorePocAsPrimaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(setStorePocAsPrimaryRespo)
                }
            }
        }
        setIsSettingPrimary(false)
    }


    useEffect(() => {
        getStorePocs()
    }, [storeIdentifier])

    const validateNewField = () => {
        if (personnelName.length > 1) {
            if (isValidMobileNumber(phoneNumber)) {
                if (designation !== '') {
                    if (gender !== '') {
                        setHasFieldValidated(true)
                    } else {
                        setHasFieldValidated(false)
                    }
                } else {
                    setHasFieldValidated(false)
                }
            } else {
                setHasFieldValidated(false)
            }
        } else {
            setHasFieldValidated(false)
        }
    }

    useEffect(() => {
        validateNewField()
    }, [personnelName, phoneNumber, designation, gender])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.topWrapper}>
                        {storeData?.length} contact(s) available
                    </div>
                    <div className={styles.centerWrapper}>
                        <div className={styles.allPersonals}>
                            {storeData?.map((item, index) => (
                                <div className={styles.eachPersonnelWrapper} key={index}
                                    onClick={() => setSelectedPoc(item)}
                                >
                                    <div className={styles.pnRow}>
                                        <div className={styles.personnelName}>
                                            {item?.personnelName}
                                        </div>
                                        <div>
                                            <IoIosStar size={16} />
                                        </div>
                                    </div>
                                    <div className={styles.designation}>
                                        {item?.designation}
                                        {/* {JSON.stringify(item)} */}
                                    </div>
                                    <div>
                                        {item?.phoneNumber}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.footerWrapper}>
                        <button
                            onClick={() => {
                                setActiveReq("CREATE_NEW")
                                setSelectedPoc(null)
                            }}
                            className={styles.tabNav}
                        >
                            Create New
                        </button>
                    </div>
                </div>
                <div className={styles.rightColWrapper}>
                    {selectedPoc === null ?
                        <div className={styles.createNew}>
                            <div className={styles.title}>
                                Create a new
                            </div>
                            <div className={styles.formWrapper}>
                                <div>
                                    {viData?.identifiersData?.vendorIdentifier}
                                </div>
                                <div>
                                    {viData?.authorizedPersonnels?.map((item, index) => (
                                        <div key={index}>
                                            <div>
                                                {item?.firstName} {item?.middleName} {item?.lastName}
                                            </div>
                                            <div>
                                                {item?.phone}
                                            </div>
                                            <div>
                                                {item?.designation}
                                            </div>
                                            <div>
                                                {item?.gender}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.eachFieldTriple}>
                                        <div className={styles.sectionTitle}>
                                            Phone Number
                                        </div>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.phoneNumberWrapper}>
                                                <input value={phoneNumber} autoFocus={true} onChange={(e) => setPhoneNumber(e.target.value)} />
                                                {isValidMobileNumber(phoneNumber) === true ? <BsCheck size={24} color="green" /> : <FcCancel size={20} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachFieldTriple}>
                                        <div className={styles.sectionTitle}>
                                            Name
                                        </div>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.fullNameWrapper}>
                                                <input value={personnelName} onChange={(e) => setPersonnelName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.eachFieldDouble}>
                                        <div className={styles.sectionTitle}>
                                            Gender
                                        </div>
                                        <div className={styles.eachOptionsWrapper}>
                                            <button onClick={() => setGender("FEMALE")} className={gender === "FEMALE" ? styles.eachGenderActive : styles.eachGender} >Female</button>
                                            <button onClick={() => setGender("MALE")} className={gender === "MALE" ? styles.eachGenderActive : styles.eachGender} >Male</button>
                                            <button onClick={() => setGender("LGTBQ")} className={gender === "LGTBQ" ? styles.eachGenderActive : styles.eachGender} >LGTBQ</button>
                                            <button onClick={() => setGender("OTHER")} className={gender === "OTHER" ? styles.eachGenderActive : styles.eachGender} >Other</button>
                                        </div>
                                    </div>
                                    <div className={styles.eachFieldDouble}>
                                        <div className={styles.sectionTitle}>
                                            Designation
                                        </div>
                                        <div className={styles.eachOptionsWrapper}>
                                            <button onClick={() => setDesignation("Owner")} className={designation === "Owner" ? styles.eachDesignationActive : styles.eachDesignation}>Owner</button>
                                            <button onClick={() => setDesignation("Manager")} className={designation === "Manager" ? styles.eachDesignationActive : styles.eachDesignation}>Manager</button>
                                            <button onClick={() => setDesignation("HR")} className={designation === "HR" ? styles.eachDesignationActive : styles.eachDesignation}>HR</button>
                                            <button onClick={() => setDesignation("Staff")} className={designation === "Staff" ? styles.eachDesignationActive : styles.eachDesignation}>Staff</button>
                                            <button onClick={() => setDesignation("Other")} className={designation === "Other" ? styles.eachDesignationActive : styles.eachDesignation}>Other</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.actionWrapper}>
                                    {isCreatingPoc === true ?
                                        <button className={styles.createNow} >Creating...</button>
                                        :
                                        <div>
                                            {hasFieldValidated === true ?
                                                <button className={styles.createNow} onClick={() => createNewPoc()}>Create</button>
                                                :
                                                <button className={styles.createNow} onClick={() => alert("Please enter valid details")}>Create</button>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.detailsWrapper}>
                            <div className={styles.dwMainContent}>
                                <div className={styles.dwMainContentBox}>
                                    <div>
                                        {/* {selectedPoc?.isActive} */}
                                    </div>
                                    <div className={styles.phoneNumber}>
                                        {selectedPoc?.phoneNumber?.substr(0, 4)}
                                        -
                                        {selectedPoc?.phoneNumber?.substr(4, 4)}
                                        -
                                        {selectedPoc?.phoneNumber?.substr(8, 2)}
                                    </div>
                                    <div className={styles.threeInRow}>
                                        <div className={styles.personnelName}>
                                            {selectedPoc?.personnelName}
                                        </div>
                                        <div className={styles.personnelGender}>
                                            {selectedPoc?.personnelGender}
                                        </div>
                                        <div className={styles.designation}>
                                            {selectedPoc?.designation}
                                        </div>
                                    </div>
                                    <div className={styles.createdOn}>
                                        Added {humanReadableTimePassed(selectedPoc?.createdOn)}
                                    </div>
                                </div>

                                <div className={styles.actionEditWrapper}>
                                    {isSettingPrimary === true ?
                                        <button>Please wait...</button>
                                        :
                                        selectedPoc?.isPrimary === "1" ?
                                            <div className={styles.primaryTag}>Primary</div>
                                            :
                                            <button onClick={() => setStorePocAsPrimary()} >Set As Primary</button>
                                    }
                                    <button>De-Activate</button>
                                    {isDeleting === true ?
                                        <button className={styles.deleteBtn} >Deleting...</button>
                                        :
                                        <button className={styles.deleteBtn} onClick={() => deleteStorePoc()} >Delete</button>
                                    }
                                </div>
                            </div>
                            {/* {JSON.stringify(selectedPoc)} */}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MSPOCs