import React from 'react'
import styles from './AssignClients.module.css'

const AssignClients = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeaderWrapper}>
            </div>
            <div className={styles.mainBodyWrapper}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        AssignClients
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AssignClients