import React, { useContext, useEffect, useState } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import LocationsFilter from '../Jobs/LocationsFilter';
import MainLoader from '../MainLoader';
import styles from '../ManageJobs/JobSelector.module.css'
import EachJobCategoryOptionInFilters from './EachJobCategoryOptionInFilters';
import EachJobInList from './EachJobInList';

const JobSelector = props => {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [jobsData, setJobsData] = useState([]);
    const [isRoute, setIsRoute] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [orderBy, setOrderBy] = useState('NEWEST_FIRST');
    const [categoryOptionsData, setCategoryOptionsData] = useState([]);
    const [jobsFetchingStatus, setJobsFetchingStatus] = useState('FETCHING'); //FETCHED

    const selectedJob = props.selectedJob;
    const jobSelectHandler = props.jobSelectHandler;
    const [selectedJobCategories, setSelectedJobCategories] = useState([])

    const [selectedLocations, setSelectedLocations] = useState([])

    const selectLocationHandler = k => {
        setSelectedLocations(selectedLocations.includes(k) === true ? selectedLocations.filter((item) => item != k) : [...selectedLocations, k]);
    }



    const toggleCategorySelectionHandler = (k) => {
        if (selectedJobCategories.includes(k)) {
            setSelectedJobCategories(selectedJobCategories.filter((item) => item != k));
        } else {
            setSelectedJobCategories([...selectedJobCategories, k]);
        }
    }


    const showFilterOptions = () => {
        // document.getElementById("filtersPanelWrapper").style.display = 'block';
        document.getElementById("filtersPanelWrapper").style.animation = "slideRightToLeft 0.5s forwards";
    }


    window.onclick = function (event) {
        if (event.target.id == "filtersPanelWrapper") {
            // document.getElementById("filtersPanelWrapper").style.right = '100%';
            document.getElementById("filtersPanelWrapper").style.animation = "slideLeftToRight .1s forwards";
        }
    }

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong!");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status == "success") {
                setCategoryOptionsData(getCategoryOptionsRespo.response)
            } else {
                setCategoryOptionsData([])
            }
        }
    }


    const getAllJobs = async () => {
        setJobsFetchingStatus('FETCHING');
        const allJobsResponse = await fetch(BASE_URL_API + "/getAllJobs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    isRoute: isRoute,
                    isActive: isActive,
                    orderBy: orderBy,
                    jobCategories: selectedJobCategories,
                    locations: selectedLocations
                })
            });

        if (!allJobsResponse.ok) {
            console.log("Something went wrong while fetching the jobs");
        } else {
            const allJobsRespo = await allJobsResponse.json();
            if (allJobsRespo.status == 'success') {
                setJobsData(allJobsRespo.response);
            } else {
                if (allJobsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setJobsData([]);
                }
            }
        }
        setJobsFetchingStatus('FETCHED');
    }

    const fetchJobDetails = (x) => jobSelectHandler(x)

    useEffect(() => {
        getAllJobs();
    }, [isRoute, isActive, orderBy, selectedJobCategories, selectedLocations])

    useEffect(() => {
        getCategoryOptions()
    }, [])

    if (isLoading) {
        return (
            <div>
                <MainLoader />
            </div>
        )
    }




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.topHeader}>
                <div>
                    All
                </div>
                <div className={styles.showFilterBtn}
                    onClick={() => showFilterOptions()}
                >
                    Show Filter
                </div>
            </div>
            <div className={styles.loadingTxtWrapper}>
                <span className={styles.loadingTxt}>
                    {jobsFetchingStatus === 'FETCHING' ? 'Loading jobs...' :
                        jobsData.length + ' result(s) found!'
                    }
                </span>
            </div>
            <div className={styles.bodyWrapper}>
                <div>
                    <div className={styles.jobsListWrapper}>
                        {jobsFetchingStatus === 'FETCHING' ?
                            <div>
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                            </div>
                            :
                            jobsData.map((item, index) => (
                                <EachJobInList
                                    key={index}
                                    selectedJob={selectedJob}
                                    item={item}
                                    fetchJobDetails={fetchJobDetails}
                                />
                            ))
                        }

                    </div>

                </div>
            </div>

            <div className={styles.filtersPanelWrapper}
                id="filtersPanelWrapper"
            >
                <div className={styles.filtersContainer}>
                    <div className={styles.filtersColumns}>
                        <div className={styles.eachFilterColumn}>
                            <div className={styles.eachColTitle}>
                                Sort By
                            </div>
                            <div>
                                Salary, Posted Date, Closing
                            </div>
                        </div>

                        <div className={styles.eachFilterColumn}>
                            <div className={styles.eachColTitle}>
                                Current Status
                            </div>
                            <div>
                                <div className={styles.eachFilterOption}>
                                    <div className={styles.eachFilterTitle}>
                                        Route Updated
                                    </div>
                                    <div className={styles.eachFilterOptionBtns}>
                                        <button onClick={() => setIsRoute('UPDATED')}
                                            className={isRoute == 'UPDATED' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                        >
                                            Yes
                                        </button>
                                        <button onClick={() => setIsRoute('NOT_UPDATED')}
                                            className={isRoute == 'NOT_UPDATED' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.eachFilterOption}>
                                    <div className={styles.eachFilterTitle}>
                                        Active
                                    </div>
                                    <div className={styles.eachFilterOptionBtns}>
                                        <button onClick={() => setIsActive('ACTIVE')}
                                            className={isActive == 'ACTIVE' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                        >
                                            Yes
                                        </button>
                                        <button onClick={() => setIsActive('NOT_ACTIVE')}
                                            className={isActive == 'NOT_ACTIVE' ? styles.filterOptionBtnSelected : styles.filterOptionBtn}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>


                                <div>

                                    -
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachFilterColumn}>
                            <div className={styles.eachColTitle}>
                                Profile Category
                            </div>
                            <div>
                                {categoryOptionsData.map((item, index) => (
                                    <EachJobCategoryOptionInFilters
                                        key={index}
                                        item={item}
                                        toggleCategorySelectionHandler={toggleCategorySelectionHandler}
                                        selectedJobCategories={selectedJobCategories}
                                    />
                                ))}
                            </div>
                        </div>


                        <div className={styles.eachFilterColumn}>
                            <LocationsFilter
                                selectedLocations={selectedLocations}
                                selectLocationHandler={selectLocationHandler}
                            />
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobSelector