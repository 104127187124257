import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CreateANewContract.module.css'

const CreateANewContract = () => {
    const authCtx = useContext(AuthContext)
    const [isCreating, setIsCreating] = useState(false)
    const navigate  = useNavigate()

    const createNewAgreement = async () => {
        setIsCreating(true)
        const createNewAgreementResponse = await fetch(BASE_URL_API + "/createNewAgreement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier : authCtx.userIdentifier
                })
            });

        if (!createNewAgreementResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewAgreementRespo = await createNewAgreementResponse.json()
            if (createNewAgreementRespo.status === "success") {
                navigate(`/manage-service-agreement/${createNewAgreementRespo?.response?.agreementIdentifier}`)
            } else {
                if (createNewAgreementRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createNewAgreementRespo)
        }
        setIsCreating(false)
    }




    return (
        <div className={styles.mainWrapper}>

            <div className={styles.mainHeader}>
                <Link to={`/manage-service-agreement`} >View All Agreements</Link>
            </div>
            <div className={styles.mainBody}>
                {isCreating === false ?
                    <div className={styles.createNewAgreement}
                        onClick={() => createNewAgreement()}
                    >
                        Create A New Agreement
                    </div>

                    :
                    <div className={styles.createNewAgreement}>
                        Creating Now...
                    </div>
                }
            </div>
        </div>
    )
}

export default CreateANewContract