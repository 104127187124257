import React, { useContext, useEffect, useState } from 'react'
import { BsCheckSquare, BsCheckSquareFill } from 'react-icons/bs'
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../JobApplications/RelevantProfilesToJob.module.css'
import EachRelevantProfileToJob from './EachRelevantProfileToJob'
import RelevantProfilesToJobFilters from './RelevantProfilesToJobFilters'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RelevantProfilesToJob = props => {
    let selectedJob = props.selectedJob
    const { activeCandidate, activeCandidateHandler } = props;
    const authCtx = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false)
    const [isFinding, setIsFinding] = useState(false);
    const [profilesData, setProfilesData] = useState([1]);
    const [isApplying, setIsApplying] = useState(false);
    const [bulkPostingSuccessful, setBulkPostingSuccessful] = useState(false);


    // console.log(authCtx)

    const [selectedProfiles, setSelectedProfiles] = useState([])

    const toggleSelectedProfileHandler = (k) => {
        if (selectedProfiles.includes(k) === true) {
            setSelectedProfiles(selectedProfiles.filter((item) => item !== k))
        } else {
            setSelectedProfiles([...selectedProfiles, k]);
        }
    }

    const toggleOpener = (k) => {
        setIsOpen(k)
    }
    const isFindingToggle = (k) => {
        setIsFinding(k)
    }

    const toggleBulkPostingSuccessful = (k) => {
        setBulkPostingSuccessful(k)
    }

    const updateProfileDataHandler = (k) => {
        setProfilesData(k)
    }

    const bulkApply = () => {
        // console.log(selectedProfiles)
        applyNowForJobBulk();
    }

    const toggleAllSelector = () => {
        if (profilesData.length === selectedProfiles.length) {
            setSelectedProfiles([]);
        } else {
            setSelectedProfiles([]);
            setSelectedProfiles(profilesData)
        }
    }

    const applyNowForJobBulk = async () => {
        console.log("Applying for the job...");
        setIsApplying(true);
        const applyNowForJobBulkResponse = await fetch(BASE_URL_API + "/applyNowForJobBulk",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: selectedJob.job_id,
                    applicantsArr: selectedProfiles,
                    executiveIdentifier : authCtx.userIdentifier
                })
            });

        if (!applyNowForJobBulkResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const applyNowForJobBulkRespo = await applyNowForJobBulkResponse.json();
            if (applyNowForJobBulkRespo.status === "success") {
                setBulkPostingSuccessful(true);
                setSelectedProfiles([]);
                toast("Bulk posting succcessful. Go to applications page to check status!");
            } else {
                if (applyNowForJobBulkRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            }
            console.log(applyNowForJobBulkRespo)
        }
        setIsApplying(false);
    }

    useEffect(() => {
        setProfilesData([]);
        setSelectedProfiles([])
    }, [selectedJob])


    return (
        <div className={styles.mainWrapper}>
            <div className={isOpen === true ? styles.filterWrapperOpened : styles.filterWrapperClosed}>
                <RelevantProfilesToJobFilters
                    isOpen={isOpen}
                    toggleOpener={toggleOpener}
                    selectedJob={selectedJob}
                    profilesData={profilesData}
                    updateProfileDataHandler={updateProfileDataHandler}
                    isFinding={isFinding}
                    isFindingToggle={isFindingToggle}
                    bulkPostingSuccessful={bulkPostingSuccessful}
                    toggleBulkPostingSuccessful={toggleBulkPostingSuccessful}
                />
                <ToastContainer />
            </div>
            {isFinding === true ?
                <div className={styles.relevantProfilesWrapper}>
                    Hold On!
                    Finding the most relevant profiles!..
                </div>
                :
                <div className={isOpen === true ? styles.relevantProfilesWrapperSmall : styles.relevantProfilesWrapperLarge}>
                    {profilesData.length > 0 ?
                        <div className={styles.allProfilesWrapper}>
                            {profilesData.map((item, index) => (
                                <EachRelevantProfileToJob
                                    key={index}
                                    item={item}
                                    selectedProfiles={selectedProfiles}
                                    toggleSelectedProfileHandler={toggleSelectedProfileHandler}
                                    activeCandidate={activeCandidate}
                                    activeCandidateHandler = {activeCandidateHandler}
                                />
                            ))}
                        </div>
                        :
                        <div>
                            No relevant profile found for this job!
                        </div>
                    }
                </div>
            }
            <div className={styles.actionWrapper}>
                {selectedProfiles.length > 0 &&
                    <div className={styles.actionContainer}>
                        <div className={styles.leftSection}>
                            <button onClick={() => toggleAllSelector()}>
                                {profilesData.length === selectedProfiles.length ?
                                    <MdCheckBox size={24} />
                                    :
                                    <MdCheckBoxOutlineBlank size={24} color="#00000085" />
                                }
                            </button>
                            <div className={styles.selectedCount}>
                                {selectedProfiles.length} profiles selected
                            </div>
                        </div>
                        <div className={styles.bulkApplyBtn}>
                            {isApplying === true ?
                                <div>Hold on! I'm just bulk applying...</div>
                                :
                                <button onClick={() => bulkApply()}>
                                    Bulk Apply
                                </button>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default RelevantProfilesToJob