import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './CreateNewAudience.module.css'

const CreateNewAudience = (props) => {
    const { audienceSize, audienceSizeHandler } = props
    const authCtx = useContext(AuthContext)
    const [selectedTables, setSelectedTables] = useState([])

    const selectTablesHandler = k => {
        setSelectedTables(selectedTables.includes(k) === true ? selectedTables.filter((item) => item !== k) : [...selectedTables, k])
    }

    const estimateAudienceSize = async () => {
        const estimateAudienceSizeResponse = await fetch(BASE_URL_API + "/estimateAudienceSize",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    selectedTables: JSON.stringify(selectedTables)
                })
            });

        if (!estimateAudienceSizeResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const estimateAudienceSizeRespo = await estimateAudienceSizeResponse.json()
            if (estimateAudienceSizeRespo.status === "success") {
                audienceSizeHandler(estimateAudienceSizeRespo.response)
            } else {
                if (estimateAudienceSizeRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    audienceSizeHandler('')
                }
            }
            console.log(estimateAudienceSizeRespo)
        }
    }



    return (
        <div>
            <div>
                <div>
                    Create a new audience
                </div>
                <div>
                    <div className={styles.leftColContainer}>
                        <div className={styles.caption}>
                            Please select data tables.
                        </div>

                        <div className={styles.tablesWrapper}>
                            <div onClick={() => selectTablesHandler('Users')}
                                className={selectedTables.includes('Users') === true ? styles.eachTableSelected : styles.eachTable}
                            >
                                Users
                            </div>
                            <div onClick={() => selectTablesHandler('Stylist')}
                                className={selectedTables.includes('Stylist') === true ? styles.eachTableSelected : styles.eachTable}
                            >
                                Stylist
                            </div>
                            <div onClick={() => selectTablesHandler('LSM')}
                                className={selectedTables.includes('LSM') === true ? styles.eachTableSelected : styles.eachTable}
                            >
                                LSM Salon Data
                            </div>
                            <div onClick={() => selectTablesHandler('Vender')}
                                className={selectedTables.includes('Vender') === true ? styles.eachTableSelected : styles.eachTable}
                            >
                                Vender
                            </div>
                            <div onClick={() => selectTablesHandler('Recruit')}
                                className={selectedTables.includes('Recruit') === true ? styles.eachTableSelected : styles.eachTable}
                            >
                                Recruit Salon Raw Lead
                            </div>
                        </div>

                        <div>
                            <button onClick={() => estimateAudienceSize()}>Estimate Audience Size</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewAudience