import React, { useContext, useState } from 'react'
import styles from '../ResumeBuilding/EachDiplomaNCertiCard.module.css'
import { MdOutlineDelete } from "react-icons/md";
import { TbCertificate } from 'react-icons/tb';

import Modal from 'react-modal';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const EachDiplomaNCertiCard = props => {
    let item = props.item
    let getAddedData = props.getAddedData;
    const [isOpen, setIsOpen] = useState(false)
    const authCtx = useContext(AuthContext);
    const [isDeleting, setIsDeleting] = useState(false)

    function closeModal() {
        setIsOpen(false);
    }


    const deleteItemInitiator = async () => {
        setIsOpen(true)
    }

    const deleteItemNow = async () => {
        if (item.certification_id !== '') {
            setIsDeleting(true)
            const deleteItemNowResponse = await fetch(BASE_URL_API + "/addMoreSections",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        dataType: 'updateNow',
                        sectionType: 'certiDiploma',
                        actionType: 'delete',
                        certificationId: item.certification_id,
                        stylist_id: item.stylist_id
                    })
                });

            if (!deleteItemNowResponse.ok) {
                console.log("Something went wrong : server error!");
            } else {
                const deleteItemNowRespo = await deleteItemNowResponse.json();
                if (deleteItemNowRespo.status === "success") {
                    getAddedData();
                    setIsOpen(false);
                } else {
                    console.log(deleteItemNowRespo);
                }
            }
            setIsDeleting(false);
        } else {
            alert('Invalid certification Identifier : please contact admin!');
        }
    }


    return (
        <div className={styles.eachCardWrapper}
        >
            <div className={styles.upperSection}>
                <div className={styles.leftSectionWrapper}>
                    <div className={styles.durationWrapper}>
                        <div>
                            <TbCertificate
                                size={34}
                                color="#00000086"
                            />
                        </div>
                        <div className={styles.fieldStats}>
                            {item.duration}
                        </div>
                        <div className={styles.fieldCaption}>
                            Duration
                        </div>
                    </div>
                    <div className={styles.classWrapper}>
                        <div className={styles.fieldStats}>
                            {item.end_date}
                        </div>
                        <div className={styles.fieldCaption}>
                            Completed On
                        </div>
                    </div>
                </div>
                <div className={styles.rightSectionWrapper}>
                    <div className={styles.certiTitle}>
                        {item.course_title}
                    </div>
                    <div className={styles.academyTitle}>
                        {item.course_academy}
                    </div>
                    <div className={styles.academyAddress}>
                        {item.academy_address}
                    </div>
                    <div className={styles.captionWrapper}>
                        {item.description}
                    </div>
                    {item.className}
                </div>
            </div>
            <div className={styles.lowerSection}>
                <div className={styles.leftSectionWrapper}>
                    <MdOutlineDelete size={24}
                        onClick={() => deleteItemInitiator()}
                        className={styles.deleteBtnIcon}
                    />

                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        {isDeleting === true ?
                            <div className={styles.modalWrapper}>
                                <div className={styles.modalWrapperHeader}>
                                    Data is being deleted...
                                </div>
                                <div>
                                    Hold on a moment!..
                                </div>
                            </div>
                            :
                            <div className={styles.modalWrapper}>
                                <div className={styles.modalWrapperHeader}>
                                    Do you really want to delete this?
                                </div>
                                <div className={styles.actionBtnsWrapper}>
                                    <button onClick={closeModal}
                                        className={styles.cancelBtn}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => deleteItemNow()}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        }
                    </Modal>
                </div>
                <div className={styles.rightSectionWrapper}>
                    <button className={styles.editBtnWrapper}>
                        Edit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EachDiplomaNCertiCard