import React, { useState } from "react";

const AuthContext = React.createContext({
    token : localStorage.getItem("token"),
    isLoggedIn : false,
    userIdentifier : localStorage.getItem("userIdentifier"),
    login : (token) => {},
    logout : () => {}
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userIdentifier, setUserIdentifier] = useState(localStorage.getItem('userIdentifier'));
    
    const userIsLoggedIn = !!token;

    const loginHandler = (token, userIdentifier) => {
        localStorage.setItem("token", token);
        localStorage.setItem("userIdentifier", userIdentifier);
        setToken(token);
        setUserIdentifier(userIdentifier)
    }

    const logoutHandler = () => {
        setToken(null);
        setUserIdentifier(null)
        localStorage.removeItem("token");
        localStorage.removeItem("userIdentifier");
    }
    
    const contextValue = {
        token : token,
        isLoggedIn : userIsLoggedIn,
        userIdentifier : userIdentifier,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;