import React, { useState, useEffect, useContext } from 'react'
import { BsDot } from 'react-icons/bs'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './MsePreferredTimeSlots.module.css'

import { toast, ToastContainer } from 'react-toastify';
import { ShimmerThumbnail } from 'react-shimmer-effects'

const MsePreferredTimeSlots = props => {
    const authCtx = useContext(AuthContext)
    const { storeIdentifier } = props
    const [weekDays, setWeekDays] = useState([
        "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
    ]);

    const [isUpdating, setIsUpdating] = useState(false)
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [activeDay, setActiveDay] = useState("Mon");
    const [selectedSlots, setSelectedSlots] = useState({
        "Mon": [],
        "Tue": [],
        "Wed": [],
        "Thu": [],
        "Fri": [],
        "Sat": [],
        "Sun": []
    });
    const [slots] = useState([
        "06:00 - 06:29 AM", "06:30 - 06:59 AM", "07:00 - 07:29 AM", "07:30 - 07:59 AM", "08:00 - 08:29 AM", "08:30 - 08:59 AM",
        "09:00 - 09:29 AM", "09:30 - 10:59 AM", "10:00 - 10:29 AM", "10:30 - 10:59 AM", "11:00 - 11:29 AM", "11:30 - 11:59 PM",
        "12:00 - 12:29 PM", "12:30 - 12:59 PM", "01:00 - 01:29 PM", "01:30 - 01:59 PM", "02:00 - 02:29 PM", "02:30 - 02:59 PM",
        "03:00 - 03:29 PM", "03:30 - 03:59 PM", "04:00 - 04:29 PM", "04:30 - 04:59 PM", "05:00 - 05:29 PM", "05:30 - 05:59 PM",
        "06:00 - 06:29 PM", "06:30 - 06:59 PM", "07:00 - 07:29 PM", "07:30 - 07:59 PM", "08:00 - 08:29 PM", "08:30 - 08:59 PM",
        "09:00 - 09:29 PM", "09:30 - 09:59 PM", "10:00 - 10:29 PM", "10:30 - 10:59 PM", "11:00 - 11:29 PM", "11:30 - 11:59 PM", 
    ]);

    useEffect(() => {
        // Check if all slots are selected for the active day
        const allSlotsSelected = slots.every(slot => selectedSlots[activeDay].includes(slot));
        if (allSlotsSelected) {
            // If all slots are selected, highlight the "Any Time" button
            setSelectedTimeState(activeDay, 'any');
        } else {
            setSelectedTimeState(activeDay, 'none');
        }
    }, []); // Only trigger the effect when the selected slots for the active day change


    // Function to toggle selected slot for a specific day
    const toggleSlot = (day, slot) => {
        const isSelected = selectedSlots[day].includes(slot);
        if (isSelected) {
            setSelectedSlots(prevState => ({
                ...prevState,
                [day]: prevState[day].filter(selectedSlot => selectedSlot !== slot)
            }));
        } else {
            setSelectedSlots(prevState => ({
                ...prevState,
                [day]: [...prevState[day], slot]
            }));
        }
    };

    // Function to select all slots for a specific day
    const selectAllSlots = (day) => {
        setSelectedSlots(prevState => ({
            ...prevState,
            [day]: [...slots]
        }));
    };

    // Function to deselect all slots for a specific day
    const deselectAllSlots = (day) => {
        setSelectedSlots(prevState => ({
            ...prevState,
            [day]: []
        }));
    };

    // Function to set selected time state
    const setSelectedTimeState = (day, timeState) => {
        if (timeState === 'any') {
            selectAllSlots(day);
        } else if (timeState === 'none') {
            deselectAllSlots(day);
        }
    };

    const getStoreInterviewSlots = async () => {
        // console.log(selectedSlots)
        setIsFetchingData(true)
        const getStoreInterviewSlotsResponse = await fetch(BASE_URL_API + "/getStoreInterviewSlots",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getStoreInterviewSlotsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreInterviewSlotsRespo = await getStoreInterviewSlotsResponse.json();
            if (getStoreInterviewSlotsRespo.status === "success") {
                setSelectedSlots(JSON.parse(getStoreInterviewSlotsRespo?.response?.slots))
                // setSelectedSlots(getStoreInterviewSlotsRespo?.response?.slots)
            } else {
                if (getStoreInterviewSlotsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSelectedSlots({
                        "Mon": [],
                        "Tue": [],
                        "Wed": [],
                        "Thu": [],
                        "Fri": [],
                        "Sat": [],
                        "Sun": []
                    })
                }
            }
            // console.log(getStoreInterviewSlotsRespo?.response)
            // console.log(JSON.parse(getStoreInterviewSlotsRespo?.response?.slots))
        }
        setIsFetchingData(false)
    }

    const updateStoreInterviewSlots = async () => {
        setIsUpdating(true)
        const updateStoreInterviewSlotsResponse = await fetch(BASE_URL_API + "/updateStoreInterviewSlots",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    selectedSlots: JSON.stringify(selectedSlots)
                })
            });

        if (!updateStoreInterviewSlotsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateStoreInterviewSlotsRespo = await updateStoreInterviewSlotsResponse.json()
            if (updateStoreInterviewSlotsRespo.status === "success") {
                toast("Successfully updated the slots!")
            } else {
                if (updateStoreInterviewSlotsRespo.message === "token expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(updateStoreInterviewSlotsRespo.message)
                }
            }
            console.log(updateStoreInterviewSlotsRespo)
        }
        setIsUpdating(false)
    }

    useEffect(() => {
        getStoreInterviewSlots()
    }, [storeIdentifier])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <ToastContainer />
                <div className={styles.mainTitle}>
                    Store Preferred Interview Slots
                </div>
                {isFetchingData === false ?
                    <div className={styles.weekdaysWrapper}>
                        {weekDays.map((day, index) => (
                            <div key={index} className={activeDay === day ? styles.eachDayWrapperActive : styles.eachDayWrapper}
                                onClick={() => setActiveDay(day)}
                            >
                                <div className={styles.days}>
                                    {day}
                                </div>
                                <div className={styles.weekTxt}>
                                    <BsDot size={34} color={activeDay === day ? `#7F57D9` : `#444141`} /> Open
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className={styles.weekdaysWrapper}>
                        {weekDays.map((day, index) => (
                            <div key={index} className={styles.eachDayWrapper}>
                                <ShimmerThumbnail height={140} width={140} />
                            </div>
                        ))}
                    </div>
                }


                <div className={styles.slotsWrapper}>
                    <div className={styles.bulkSlotWrapper}>
                        <div className={styles.bulkSlotContainer}>
                            <button className={styles.noTimeBtn} onClick={() => deselectAllSlots(activeDay)}>No Time</button>
                            <button className={styles.anyTimeBtn} onClick={() => setSelectedTimeState(activeDay, 'any')}>Any Time</button>
                        </div>
                    </div>
                    {isFetchingData === false ?
                        <div className={styles.allSlotsWrapper}>
                            {slots.map((slot, index) => (
                                <div key={index} className={`${styles.eachSlotWrapper} ${selectedSlots[activeDay].includes(slot) ? styles.selectedSlot : ''}`}
                                    onClick={() => toggleSlot(activeDay, slot)}
                                >
                                    {slot}
                                </div>
                            ))}
                        </div>
                        :
                        <div className={styles.allSlotsWrapper}>
                            {slots.map((slot, index) => (
                                <div key={index} className={styles.eachSlotWrapper}>
                                    <ShimmerThumbnail height={90} width={300} />
                                </div>
                            ))}
                        </div>
                    }
                </div>

                {isFetchingData === false &&
                    <div className={styles.updateBtn}>
                        {isUpdating === true ?
                            <button>Updating slots...</button>
                            :
                            <button onClick={() => updateStoreInterviewSlots()} >Update</button>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default MsePreferredTimeSlots;
