import React from 'react'
import styles from './QPFnA.module.css'

const QPFnA = (props) => {
    const { fna, fnaHandler } = props
    const fnaOptions = ['None', '1 - time Food Only', '2 - time Food Only', 'Accomodation Only', '1 - time Food with Accomodation', '2 - time Food with Accomodation']
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.optionTitle}>
                        Food & Accomodation
                    </div>
                    <div className={styles.optionsWrapper}>
                        {fnaOptions?.map((item, index) => (
                            <button key={index}
                                className={fna === item ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => fnaHandler(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QPFnA