import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styles from './CAEJWMAgeRange.module.css'

const CAEJWMAgeRange = (props) => {
    const { minAge, maxAge, ageHandler } = props
    const [isOpen, setIsOpen] = useState(false)

    const ageRange = Array.from({ length: 65 }, (_, i) => 14 + i);


    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Age Range
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Include all profile within the age range.
                    </div>
                    <div className={styles.ageWrapper}>
                        <div>
                            <select value={minAge}
                                onChange={(e) => ageHandler('minAge', e.target.value)}
                            >
                                {ageRange?.map((item, index) => (
                                    <option key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <select value={maxAge}
                                onChange={(e) => ageHandler('maxAge', e.target.value)}
                            >
                                {ageRange?.map((item, index) => (
                                    <option key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMAgeRange