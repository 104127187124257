import React, { useContext, useEffect, useState } from 'react'
import AcademicHeader from '../../Components/Academics/AcademicHeader'
import EachAcademyEnquiryRow from '../../Components/Academics/EachAcademyEnquiryRow'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AcademyEnquiries.module.css'

const AcademyEnquiries = () => {
    const [enquires, setEnquires] = useState([])
    const authCtx = useContext(AuthContext)

    const getAcademyEnquiries = async () => {
        const getAcademyEnquiriesResponse = await fetch(BASE_URL_API+"/acad-getAcademyEnquires",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getAcademyEnquiriesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAcademyEnquiriesRespo = await getAcademyEnquiriesResponse.json()
            if(getAcademyEnquiriesRespo.status==="success") {
                setEnquires(getAcademyEnquiriesRespo.response)
            } else {
                if(getAcademyEnquiriesRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setEnquires([])
                }
            }
            console.log(getAcademyEnquiriesRespo)
        }
    }

    useEffect(() => {
        getAcademyEnquiries()
    }, [])
    




    return (
        <div className={styles.mainWrapper}>
            <AcademicHeader />
            <div className={styles.mainContainer}>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <div className={styles.count}>#</div>
                        <div className={styles.phone}>Phone</div>
                        <div className={styles.fullName}>Full Name</div>
                        <div className={styles.gender}>Gender</div>
                        <div className={styles.fullAddress}>Address</div>
                        <div className={styles.action}>Action</div>
                    </div>
                    <div className={styles.tableBody}>
                        {enquires?.map((item, index) => (
                            <EachAcademyEnquiryRow count={index} item={item} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcademyEnquiries