import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CAEJCurrentStatusToggler.module.css'

const CAEJCurrentStatusToggler = (props) => {
    const { isOpen, onClose, jobIdentifier , defaultStatus, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [statusToUpdate, setStatusToUpdate] = useState('')
    const [isCurrentlyUpdating, setIsCurrentlyUpdating] = useState(false)


    const toggleJobStatus = async () => {
        if (statusToUpdate !== '') {
            setIsCurrentlyUpdating(true)
            const toggleJobStatusResponse = await fetch(BASE_URL_API + "/toggleJobStatus",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        jobIdentifier: jobIdentifier,
                        statusToUpdate: statusToUpdate,
                        executiveIdentifier: authCtx.userIdentifier
                    })
                });

            if (!toggleJobStatusResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const toggleJobStatusRespo = await toggleJobStatusResponse.json()
                if (toggleJobStatusRespo.status === "success") {
                    toast("Status updated successfully!")
                    onSuccess()
                    onClose()
                } else {
                    if (toggleJobStatusRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        toast(toggleJobStatusRespo.message)
                    }
                }
                console.log(toggleJobStatusRespo)
            }
            setIsCurrentlyUpdating(false)
        } else {
            alert("Please select a valid status!")
        }
    }



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Update the job current status!
                        </div>
                        <div className={styles.optionsWrapper}>
                            <select onChange={(e) => setStatusToUpdate(e.target.value)} defaultValue={defaultStatus} >
                                <option value={``}>Select Status</option>
                                <option value={`WORKING`}>WORKING</option>
                                <option value={`NOT_ENOUGH_DATA`}>NOT_ENOUGH_DATA</option>
                                <option value={`SALON_NOT_RESPONDING`}>SALON_NOT_RESPONDING</option>
                                <option value={`INTERVIEW_SCHEDULED`}>INTERVIEW_SCHEDULED</option>
                                <option value={`CANDIDATE_HIRED`}>CANDIDATE_HIRED</option>
                                <option value={`NO_ONE_HIRED`}>NO_ONE_HIRED</option>
                                <option value={`ON_HOLD`}>ON_HOLD</option>
                            </select>
                        </div>
                        {isCurrentlyUpdating === false ?
                            <div className={styles.actionWrapper}>
                                <button className={styles.closeBtn} onClick={() => onClose()}>Close</button>
                                <button className={styles.updateBtn} onClick={() => toggleJobStatus()} >Done</button>
                            </div>
                            :
                            <div className={styles.actionWrapper}>
                                <button className={styles.closeBtn} onClick={() => alert("Please wait while I'm updating the status...")}>Close</button>
                                <button className={styles.updateBtn} >Updating...</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CAEJCurrentStatusToggler