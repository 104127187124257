import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import { Checkbox } from '@mui/material'
import React from 'react'
import { BiCheckboxChecked } from 'react-icons/bi'
import styles from './SLCEachSkill.module.css'

const SLCEachSkill = props => {
    const { isSelected, item, selectedSkills, selectSkillHandler } = props
    return (
        <div className={styles.mainWrapper} onClick={() => selectSkillHandler(item)}>
            <div>
                {selectedSkills.some(itemSelected => itemSelected.ID===item.ID)===true ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank /> }
            </div>
            <div>
                {item?.skill}
            </div>
        </div>
    )
}

export default SLCEachSkill