import React, { useContext, useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../JobApplications/CandidateApplicationActivitySummary.module.css'
import CandidateApplicationConditionalQuestionnaire from './CandidateApplicationConditionalQuestionnaire';

const CandidateApplicationActivitySummary = props => {
    const { activeCandidate, jobIdentifier, getApplicationCreationActivitySummary } = props;
    const authCtx = useContext(AuthContext)
    const [candiSum, setCandiSum] = useState([])

    const updateApplicationCreationActivity = async (action, remark) => {
        const uacare = await fetch(BASE_URL_API + "/updateApplicationCreationActivity",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    action: action,
                    remark: remark,
                    jobIdentifier: jobIdentifier,
                    stylistIdentifier: activeCandidate
                })
            });

        if (!uacare.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const uacar = await uacare.json();
            if (uacar.status === 'success') {
                getApplicationCreationActivitySummary()
            } else {
                if (uacar.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(uacar)
                }
            }
        }
    }

    const getApplicationCreationCandidateActivitySummary = async () => {
        const gaccasre = await fetch(BASE_URL_API + "/getApplicationCreationCandidateActivitySummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier,
                    stylistIdentifier: activeCandidate
                })
            });

        if (!gaccasre.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const gaccasr = await gaccasre.json();
            if (gaccasr.status === 'success') {
                setCandiSum(gaccasr.response)
            } else {
                if (gaccasr.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCandiSum([])
                }
            }
            console.log(gaccasr)
        }
    }

    useEffect(() => {
        activeCandidate !== null && getApplicationCreationCandidateActivitySummary()
        activeCandidate !== null && updateApplicationCreationActivity("VIEWED", "Executive opened candidate profile")
    }, [activeCandidate, jobIdentifier])

    useEffect(() => {
        activeCandidate === null && setCandiSum([])
    }, [activeCandidate])


    console.log(candiSum)

    return (
        <div className={styles.mainWrapper}>
            {activeCandidate === null ?
                <div className={styles.activityWrapper}>
                    <div className={styles.selectedACandidate}>
                        Please select a candiate to review profile!
                    </div>
                </div>
                :
                <div className={styles.activityWrapper}>

                    <div className={styles.summaryWrapper}>
                        <div>
                            {candiSum?.statsSummary?.map((item, index) => {
                                if (item.action === "VIEWED") {
                                    return (
                                        <div key={index}>
                                            {item?.total > 0 ?
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Profile has been for viewed for {item?.total} different jobs!
                                                </div>
                                                :
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Profile has never viewed before for any job!
                                                </div>
                                            }
                                        </div>
                                    )
                                } else if (item.action === "CONTACTED") {
                                    return (
                                        <div key={index}>
                                            {item?.total > 0 ?
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Profile has been for contacted for {item?.total} different jobs!
                                                </div>
                                                :
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Profile has never been contacted for any job!
                                                </div>
                                            }
                                        </div>
                                    )
                                } else if (item.action === "COULD_NOT_HAD_CONVERSATION") {
                                    return (
                                        <div key={index}>
                                            {item?.total &&
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Executive could not have the conversation for {item?.total} different jobs!
                                                </div>
                                            }
                                        </div>
                                    )
                                } else if (item.action === "PROFILE_NOT_CONSIDERED") {
                                    return (
                                        <div key={index}>
                                            {item?.total &&
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Candidate profile was not considered for {item?.total} different jobs!
                                                </div>
                                            }
                                        </div>
                                    )
                                } else if (item.action === "PROFILE_CONSIDERED") {
                                    return (
                                        <div key={index}>
                                            {item?.total &&
                                                <div className={styles.eachInfoTxt}>
                                                    <FaCheckCircle />&nbsp;Candidate profile was considered for {item?.total} different jobs!
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            }
                            )}
                        </div>

                        {(candiSum?.lastContactedForAny !== undefined || candiSum?.lastContactedForAny !== null || candiSum?.lastContactedForAny !== '') &&
                            <div className={styles.lastContactedForAny}>
                                {humanReadableTimePassed(candiSum?.lastContactedForAny)!=="InvalidTime" ? "The candidate was last contacted "+humanReadableTimePassed(candiSum?.lastContactedForAny)+" by stylelink for a job!" : "Candidated has never contacted by stylelink for any job!"}
                            </div>
                        }

                        <div className={styles.forThisJob}>
                            {candiSum?.isContactedForThis === true && candiSum?.candidateName + " has been contacted for this job on " + candiSum?.lastContactedForThis}
                        </div>

                    </div>


                    <div className={styles.questionnaireWrapper}>
                        <CandidateApplicationConditionalQuestionnaire
                            activeCandidate={activeCandidate}
                            jobIdentifier={jobIdentifier}
                            updateApplicationCreationActivity={updateApplicationCreationActivity}
                        />
                    </div>


                    <div className={styles.footer}>
                        <Link to={`/manage-users/grow/` + activeCandidate} target="_blank">
                            View Stylist's Complete Profile
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default CandidateApplicationActivitySummary