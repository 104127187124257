import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Partners/ManageStoreEach.module.css'
import ManageStoreEachDetails from './ManageStoreEachDetails'
import ManageStoreHeader from './ManageStoreHeader';
import MSPOCs from '../../Components/ManageStores/MSPOCs';
import MseConfig from '../../Components/ManageStores/MseConfig';
import MsePreferredTimeSlots from '../../Components/ManageStores/MsePreferredTimeSlots';
import MseJobs from '../../Components/ManageStores/MseJobs';
import AllStoreConversations from '../../Components/Conversations/AllStoreConversations';
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";


const ManageStoreEach = () => {
    const authCtx = useContext(AuthContext)
    const [storesList, setStoresList] = useState([])
    const storeListHandler = (k) => setStoresList(k)
    const params = useParams()
    const currentStoreIdentifier = params.storeIdentifier;
    const [activeTab, setActiveTab] = useState('LOCATION') // POC
    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)

    return (
        <div className={styles.mainWrapper}>
            {isScHolderOpen && <AllStoreConversations isOpen={isScHolderOpen} storeIdentifier={currentStoreIdentifier} onClose={onClosingScHolder} />}
            <div className={styles.rcwHeader}>
                <button className={activeTab === "LOCATION" ? styles.eachNavActive : styles.eachNav} onClick={() => setActiveTab('LOCATION')} >Location</button>
                <button className={activeTab === "POC" ? styles.eachNavActive : styles.eachNav} onClick={() => setActiveTab('POC')} >POCs</button>
                <button className={activeTab === "CONFIG" ? styles.eachNavActive : styles.eachNav} onClick={() => setActiveTab('CONFIG')} >Configurations</button>
                <button className={activeTab === "INTERVIEW_SLOTS" ? styles.eachNavActive : styles.eachNav} onClick={() => setActiveTab('INTERVIEW_SLOTS')} >Preferred Interview Slots</button>
                <button className={activeTab === "JOBS" ? styles.eachNavActive : styles.eachNav} onClick={() => setActiveTab('JOBS')} >JOBS</button>
                <button onClick={() => setIsScHolderOpen(true)}>
                    <IoChatbubbleEllipsesSharp />
                </button>
            </div>
            <div className={styles.rcwBody}>
                {activeTab === "LOCATION" && <ManageStoreEachDetails currentStoreIdentifier={currentStoreIdentifier} />}
                {activeTab === "POC" && <MSPOCs storeIdentifier={currentStoreIdentifier} />}
                {activeTab === "CONFIG" && <MseConfig storeIdentifier={currentStoreIdentifier} />}
                {activeTab === "INTERVIEW_SLOTS" && <MsePreferredTimeSlots storeIdentifier={currentStoreIdentifier} />}
                {activeTab === "JOBS" && <MseJobs storeIdentifier={currentStoreIdentifier} />}
            </div>
        </div>
    )
}

export default ManageStoreEach