import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../ManageDemographics/DgAreas.module.css'


const DgAreas = props => {
    const authCtx = useContext(AuthContext);
    let selectedCity = props.selectedCity;
    const [areasData, setAreasData] = useState([]);
    const [isFetchingAreasData, setIsFetchingAreasData] = useState(true)

    const [area, setArea] = useState('');

    const dgAddArea = async () => {
        setIsFetchingAreasData(true);
        const dgAddAreaResponse = await fetch(BASE_URL_API+"/dgAddAreas",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                cityIdentifier : selectedCity.cityIdentifier,
                area : area
            })
        });

        if(!dgAddAreaResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const dgAddAreaRespo = await dgAddAreaResponse.json();
            if(dgAddAreaRespo.status==="success") {
                setArea('')
                dgGetArea();
            } else {
                if (dgAddAreaRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(dgAddAreaRespo.response)
                }
            }
        }
    }

    const dgGetArea = async () => {
        setIsFetchingAreasData(true);
        const dgGetAreaResponse = await fetch(BASE_URL_API+"/dgGetAreas",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                cityIdentifier : selectedCity.cityIdentifier
            })
        });

        if(!dgGetAreaResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetAreaRespo = await dgGetAreaResponse.json();
            if(dgGetAreaRespo.status==="success") {
                setAreasData(dgGetAreaRespo.response)
            } else {
                if (dgGetAreaRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(dgGetAreaRespo.response)
                }
            }
        }
        setIsFetchingAreasData(false);

    }

    useEffect(() => {
        if(selectedCity!==null) {
            dgGetArea()
        }
    }, [selectedCity])
    

    return (
        <div className={styles.mainWrapper}>
            {selectedCity != null ?
                <div>
                    {isFetchingAreasData === false ?
                        areasData.length > 0 ?
                            <div>
                                <div className={styles.header}>
                                    Areas
                                </div>
                                <div className={styles.body}>
                                    {areasData.map((item, index) => (
                                        <div className={styles.eachCardWrapper}
                                            key={index}
                                        >
                                            {item.area}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            onChange={(e) => setArea(e.target.value)}
                                            value={area}
                                            placeholder="Please enter area name..."
                                        />
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <button
                                            onClick={() => dgAddArea()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={styles.header}>
                                    Areas
                                </div>
                                <div className={styles.body}>
                                    No area has been added for this state!
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            onChange={(e) => setArea(e.target.value)}
                                            value={area}
                                            placeholder="Please enter area name..."
                                        />
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <button
                                            onClick={() => dgAddArea()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        :
                        <div>
                            <div className={styles.header}>
                                Areas
                            </div>
                            <div className={styles.body}>
                                Fetching areas data ...
                            </div>
                            <div className={styles.footer}>
                            </div>
                        </div>
                    }
                </div>
                :
                <div>
                    <div className={styles.header}>
                        Areas
                    </div>
                    <div className={styles.body}>
                        Please select a city!
                    </div>
                    <div className={styles.footer}>
                    </div>
                </div>
            }
        </div>
    )
}

export default DgAreas