import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AepPageAccessManager from '../../Components/Admin/AepPageAccessManager'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Admin/AdminEachPersonnel.module.css'
import AdminNavigator from './AdminNavigator'

const AdminEachPersonnel = () => {

    const [activeTab, setActiveTab] = useState('Summary')
    



    return (
        <div className={styles.mainWrapper}>
            <AdminNavigator />
            <div className={styles.mainContainer}>
                <div className={styles.leftWrapper}>
                    <div className={styles.pageHeader}>
                        <div>
                            Control All Pages in Stylelink Admin
                        </div>
                        <div>
                            <button>
                                Add New Page
                            </button>
                        </div>
                    </div>

                    <div className={styles.navigatorSmall}>
                        <div className={styles.navigatorSmallContainer}>
                            <div onClick={() => setActiveTab('accessibility')}
                                className={activeTab==='accessibility' ? styles.eachActiveTab : styles.eachTab}
                            >
                                Accessibility
                            </div>
                            <div onClick={() => setActiveTab('summary')}
                                className={activeTab==='summary' ? styles.eachActiveTab : styles.eachTab}
                            >
                                Summary
                            </div>
                            <div onClick={() => setActiveTab('settings')}
                                className={activeTab==='settings' ? styles.eachActiveTab : styles.eachTab}
                            >
                                Settings
                            </div>
                        </div>
                    </div>

                    <div className={styles.leftPageWrapper}>
                        {activeTab==='accessibility' &&
                            <AepPageAccessManager />
                        }
                    </div>

                </div>
                <div className={styles.rightWrapper}>
                    Recent Access
                    Page Requests
                    Recent Activities
                </div>
            </div>
        </div>
    )
}

export default AdminEachPersonnel