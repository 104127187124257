import { DeleteOutlineSharp } from '@material-ui/icons'
import React from 'react'
import { MdVerifiedUser } from 'react-icons/md'
import styles from '../ResumeBuilding/WorkExperienceCard.module.css'


const WorkExperienceCard = props => {
    let item = props.item;
    const { editExperienceHandler } = props;



    return (
        <div className={styles.mainWrapper}>

            <div className={styles.cardHeader}>
                <div>
                    <div className={styles.storeName}>{item.salon_name}</div>
                    <div className={styles.storeAddress}>{item.address}</div>
                </div>
                {item.is_verified === "0" ?
                    <div className={styles.verificationTagWrapper}>
                        <div>
                            <MdVerifiedUser size={15} />
                        </div>
                        <div className={styles.verificationTag}>
                            Un-verified
                        </div>
                    </div>
                    :
                    <div className={styles.verificationTagWrapper}>
                        <div>
                            <MdVerifiedUser size={15} color="green" />
                        </div>
                        <div className={styles.verificationTagVerified}>
                            Verified
                        </div>
                    </div>

                }
            </div>

            <div className={styles.profileDetails}>
                <div className={styles.profileTitle}>
                    {item.designation}
                </div>
                <div className={styles.skillsWrapper}>
                    {/* Pedicure, Manicure, Bleach */}
                </div>
                <div className={styles.descriptionWrapper}>
                    {item.event_description}
                </div>

                <div>
                    <div className={styles.salaryWrapper}>
                        <div>
                            Salary : {item.monthlySalary}
                        </div>
                        <div>
                            <span className={styles.modeOfSalary}>
                                {item.modeOfSalary}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.fieldTitle}>
                            Reason of leaving :
                        </div>
                        <div className={styles.descriptionWrapper}>
                            {item.reasonOfLeaving}
                        </div>
                    </div>
                </div>

                <div className={styles.timelineWrapper}>
                    <div>
                        Start Date : {item.start_date.substr(0, 10)}
                    </div>
                    <div>
                        End Date : {item.end_date.substr(0, 10)}
                    </div>
                </div>
            </div>

            <div className={styles.footerWrapper}>
                <div>
                    <DeleteOutlineSharp />
                </div>
                <div className={styles.editBtnWrapper}>
                    <button onClick={() => editExperienceHandler(item)}>
                        Edit
                    </button>
                </div>
            </div>



        </div>
    )
}

export default WorkExperienceCard