import React, { useState } from 'react'
import { BsArrowLeft, BsBookHalf } from 'react-icons/bs'
import { MdBook } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import CreateCourseBasicDetails from '../../../Components/Course/Academics/CreateCourseBasicDetails'
import CreateCoursePricing from '../../../Components/Course/Academics/CreateCoursePricing'
import styles from './CreateAcademicCourse.module.css'

const CreateAcademicCourse = () => {
    const { courseToken } = useParams()
    const [activeNav, setActiveNav] = useState('Details')
    const [courseCode, setCourseCode] = useState('')
    const courseCodeHandler = (k) => setCourseCode(k)
    const [courseTitle, setCourseTitle] = useState('')
    const courseTitleHandler = (k) => setCourseTitle(k)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.upperHeader}>
                    <div className={styles.uhIcon}>
                        <Link to="/academics/courses" title="All Courses">
                            <BsArrowLeft />
                        </Link>
                    </div>
                    <div className={styles.uhTitle}>
                        <div className={styles.courseTitle}>
                            {courseCode}X : {courseTitle}
                        </div>
                    </div>
                </div>
                <div className={styles.lowerHeader}>
                    <button onClick={() => setActiveNav("Details")} className={activeNav === "Details" ? styles.activeNav : styles.defaultNav}>Basic Details</button>
                    <button onClick={() => setActiveNav("Modules")} className={activeNav === "Modules" ? styles.activeNav : styles.defaultNav}>Modules</button>
                    <button onClick={() => setActiveNav("Pricing")} className={activeNav === "Pricing" ? styles.activeNav : styles.defaultNav}>Pricing</button>
                </div>
            </div>
            <div className={styles.mainBody}>
                {activeNav === "Details" && <CreateCourseBasicDetails courseToken={courseToken} courseTitle={courseTitle} courseTitleHandler={courseTitleHandler} courseCodeHandler={courseCodeHandler} />}
                {activeNav === "Pricing" && <CreateCoursePricing courseToken={courseToken} />}
            </div>

        </div>
    )
}

export default CreateAcademicCourse