import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './CreateCourseBasicDetails.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCourseBasicDetails = props => {
    const authCtx = useContext(AuthContext)
    const { courseToken, courseTitle, courseTitleHandler, courseCodeHandler } = props
    const [department, setDepartment] = useState(null)
    const [courseType, setCourseType] = useState(null)
    const [courseLevel, setCourseLevel] = useState(null)
    const [tentativeDuration, setTentativeDuration] = useState("")
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        const values = [department?.deptCode, courseType?.courseTypeCode, courseLevel?.courseLevelCode];
        const result = values.filter(value => value !== undefined && value !== null).join("");

        courseCodeHandler(result)
    }, [department, courseType, courseLevel])

    const getAcadCourseBasicDetails = async () => {
        const getAcadCourseBasicDetailsResponse = await fetch(BASE_URL_API + "/acad-getAcadCourseBasicDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseToken: courseToken
                })
            });

        if (!getAcadCourseBasicDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAcadCourseBasicDetailsRespo = await getAcadCourseBasicDetailsResponse.json();
            if (getAcadCourseBasicDetailsRespo.status === "success") {
                courseTitleHandler(getAcadCourseBasicDetailsRespo?.response?.courseTitle)
                courseCodeHandler(getAcadCourseBasicDetailsRespo?.response?.courseCode)
                setDepartment({ "dept": getAcadCourseBasicDetailsRespo?.response?.dept, "deptCode": getAcadCourseBasicDetailsRespo?.response?.deptCode })
                setCourseType({ "courseType": getAcadCourseBasicDetailsRespo?.response?.courseType, "courseTypeCode": getAcadCourseBasicDetailsRespo?.response?.courseTypeCode })
                setCourseLevel({ "courseLevel": getAcadCourseBasicDetailsRespo?.response?.courseLevel, "courseLevelCode": getAcadCourseBasicDetailsRespo?.response?.courseLevelCode })
                setTentativeDuration(getAcadCourseBasicDetailsRespo?.response?.tentativeDuration)
            } else {
                if (getAcadCourseBasicDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAcadCourseBasicDetailsRespo)
        }
    }

    useEffect(() => {
        getAcadCourseBasicDetails()
    }, [courseToken])


    const updateAcadCourseBasicDetails = async () => {
        setIsUpdating(true)
        const updateAcadCourseBasicDetailsResponse = await fetch(BASE_URL_API + "/acad-updateAcadCourseBasicDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseToken: courseToken,
                    department: department?.dept,
                    departmentCode: department?.deptCode,
                    courseType: courseType?.courseType,
                    courseTypeCode: courseType?.courseTypeCode,
                    courseLevel: courseLevel?.courseLevel,
                    courseLevelCode: courseLevel?.courseLevelCode,
                    courseTitle: courseTitle,
                    tentativeDuration : tentativeDuration
                })
            });

        if (!updateAcadCourseBasicDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateAcadCourseBasicDetailsRespo = await updateAcadCourseBasicDetailsResponse.json()
            if (updateAcadCourseBasicDetailsRespo.status === "success") {
                toast("Successful updated course details!")
                getAcadCourseBasicDetails()
            } else {
                if (updateAcadCourseBasicDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateAcadCourseBasicDetailsRespo)
                }
            }
        }
        setIsUpdating(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.eachFieldWrapper}>
                    <div>Please select course department</div>
                    <div>
                        <div className={styles.rowOption}>
                            <button className={department?.deptCode === "CO" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "COSMETOLOGY", "deptCode": "CO" })}
                            >
                                Beauty</button>
                            <button className={department?.deptCode === "TR" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "TRICHOLOGY", "deptCode": "TR" })}
                            >
                                Hair</button>
                            <button className={department?.deptCode === "MU" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "MAKEUP", "deptCode": "MU" })}
                            >
                                Makeup</button>
                        </div>
                        <div className={styles.rowOption}>
                            <button className={department?.deptCode === "MA" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "MEHANDI", "deptCode": "MA" })}
                            >
                                Mehandi</button>
                            <button className={department?.deptCode === "NT" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "NAIL", "deptCode": "NT" })}
                            >
                                Nail</button>
                            <button className={department?.deptCode === "SM" ? styles.selectedBtn : styles.defaultBtn}
                                onClick={() => setDepartment({ "dept": "MANAGEMENT", "deptCode": "SM" })}
                            >
                                Salon Management</button>
                        </div>
                    </div>
                </div>

                <div className={styles.eachFieldWrapper}>
                    <div>Please select course type</div>
                    <div className={styles.rowOption}>
                        <button className={courseType?.courseTypeCode === "L" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseType({ "courseType": "LECTURE", "courseTypeCode": "L" })}
                        >
                            Lecture</button>
                        <button className={courseType?.courseTypeCode === "M" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseType({ "courseType": "MASTERCLASS", "courseTypeCode": "M" })}
                        >
                            Masterclass</button>
                        <button className={courseType?.courseTypeCode === "W" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseType({ "courseType": "WORKSHOP", "courseTypeCode": "W" })}
                        >
                            Workshop</button>
                    </div>
                </div>

                <div className={styles.eachFieldWrapper}>
                    <div>Please select course level</div>
                    <div className={styles.rowOption}>
                        <button className={courseLevel?.courseLevelCode === "10" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseLevel({ "courseLevel": "BEGINNER", "courseLevelCode": "10" })}
                        >
                            Beginner</button>
                        <button className={courseLevel?.courseLevelCode === "20" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseLevel({ "courseLevel": "INTERMEDIATE", "courseLevelCode": "20" })}
                        >
                            Intermediate</button>
                        <button className={courseLevel?.courseLevelCode === "30" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseLevel({ "courseLevel": "ADVANCE", "courseLevelCode": "30" })}
                        >
                            Advance</button>
                        <button className={courseLevel?.courseLevelCode === "50" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseLevel({ "courseLevel": "MASTERY", "courseLevelCode": "50" })}
                        >
                            Mastery</button>
                        <button className={courseLevel?.courseLevelCode === "70" ? styles.selectedBtn : styles.defaultBtn}
                            onClick={() => setCourseLevel({ "courseLevel": "PG", "courseLevelCode": "70" })}
                        >
                            Post Graduate</button>
                    </div>
                </div>

                <div className={styles.eachFieldWrapper}>
                    <div>Please enter course title</div>
                    <div className={styles.inputWrapper}>
                        <input
                            onChange={(e) => courseTitleHandler(e.target.value)}
                            placeholder="Please enter course title..."
                            value={courseTitle}
                        />
                    </div>
                </div>

                <div className={styles.eachFieldWrapper}>
                    <div>Tentative Duration (in days only)</div>
                    <div className={styles.inputWrapper}>
                        <input
                            onChange={(e) => setTentativeDuration(e.target.value)}
                            placeholder="Please enter tentative duration of the course..."
                            value={tentativeDuration}
                        />
                    </div>
                </div>

            </div>

            <div>
                {isUpdating === true ?
                    <button className={styles.updateBtn}>Updating Details...</button>
                    :
                    <button className={styles.updateBtn} onClick={() => updateAcadCourseBasicDetails()} >Update</button>
                }
            </div>

        </div>
    )
}

export default CreateCourseBasicDetails