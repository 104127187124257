import React from 'react'
import styles from './JAAllocatorMode.module.css'

const JAAllocatorMode = () => {
    return (
        <div>JAAllocatorMode
            <div>Recently Alloted Jobs</div>
                    <div>
                        <div>
                            <div>1</div>
                            <div>5 minutes ago</div>
                        </div>
                        <div>
                            <div>1</div>
                            <div>5 minutes ago</div>
                        </div>
                    </div>
        </div>
    )
}

export default JAAllocatorMode