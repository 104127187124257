import React, { useContext, useEffect, useState } from 'react'
import { BsInfo } from 'react-icons/bs'
import { FaCheck, FaCross } from 'react-icons/fa'
import { FcCancel } from 'react-icons/fc'
import { IoIosStar } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { convertTimeSlotToReadableForm } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SendInterviewDetails.module.css'

const SendInterviewDetails = props => {
    const { isOpen, applicationIdentifier, sendInterviewDetailsHandler } = props
    const authCtx = useContext(AuthContext)

    const [validatingPrereq, setValidatingPrereq] = useState(false)
    const [hasSlotBooked, setHasSlotBooked] = useState(false)
    const [candidateName, setCandidateName] = useState('')
    const [whatsAppNumber, setWhatsAppNumber] = useState('')
    const [interviewSlot, setInterviewSlot] = useState('')
    const [storeName, setStoreName] = useState('')
    const [storeAddress, setStoreAddress] = useState('')
    const [contactDetails, setContactDetails] = useState('')
    const [storeIdentifier, setStoreIdentifier] = useState('')
    const [isSendingSms, setIsSendingSms] = useState(false)
    const [pocsData, setPocsData] = useState([])

    const validatePhoneNumber = (number) => {
        // Check if the input is not a string or if it's an empty string
        if (typeof number !== 'string' || number.trim() === '') {
            return false;
        }

        // Trim whitespace and remove all non-digit characters
        const digitsOnly = number.trim().replace(/\D/g, "");

        // Check if the resulting string is empty or not 10 digits long
        if (digitsOnly.length !== 10) {
            return false;
        }

        // Check if the first digit is less than 5
        if (digitsOnly[0] < 5) {
            return false;
        }

        // Additional edge case handling
        // Ensure all digits are not the same
        const allSameDigits = /^\d{1}$/.test(digitsOnly.repeat(10));
        if (allSameDigits) {
            return false;
        }

        // Return true if all conditions are met
        return true;
    }


    const getInterviewDetailsToShare = async () => {
        setValidatingPrereq(true)
        const getInterviewDetailsToShareResponse = await fetch(BASE_URL_API + "/getInterviewDetailsToShare",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier
                })
            });

        if (!getInterviewDetailsToShareResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInterviewDetailsToShareRespo = await getInterviewDetailsToShareResponse.json()
            if (getInterviewDetailsToShareRespo.status === "success") {
                setHasSlotBooked(true)
                setCandidateName(getInterviewDetailsToShareRespo?.response?.stylist_name)
                setInterviewSlot(getInterviewDetailsToShareRespo?.response?.timeSlot)
                setWhatsAppNumber(getInterviewDetailsToShareRespo?.response?.stylist_contact)
                setStoreName(getInterviewDetailsToShareRespo?.response?.storeName)
                setStoreAddress(getInterviewDetailsToShareRespo?.response?.storeAddress)
                setContactDetails(getInterviewDetailsToShareRespo?.response?.storePhoneNumber)
                setStoreIdentifier(getInterviewDetailsToShareRespo?.response?.store_id)
                setPocsData(getInterviewDetailsToShareRespo?.response?.pocsData)
            } else {
                if (getInterviewDetailsToShareRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else if (getInterviewDetailsToShareRespo.message === "no_interview_slot_booked") {
                    setHasSlotBooked(false)
                }
            }
            console.log(getInterviewDetailsToShareRespo)
        }
        setValidatingPrereq(false)
    }

    useEffect(() => {
        if (isOpen) {
            getInterviewDetailsToShare()
        }
    }, [isOpen, applicationIdentifier])


    const sentInterviewDetailsNow = async () => {
        setIsSendingSms(true)
        const sendSmsResponse = await fetch(BASE_URL_API + "/sendInterviewInvitationOnWhatsApp",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    phoneNumber: `91${whatsAppNumber}`,
                    candidateName: candidateName,
                    interviewSlot: convertTimeSlotToReadableForm(interviewSlot)["en"],
                    storeName: storeName,
                    storeAddress: storeAddress,
                    contactDetails: contactDetails,
                    applicationIdentifier: applicationIdentifier
                })
            });

        if (!sendSmsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendSmsRespo = await sendSmsResponse.json();
            if (sendSmsRespo.status === "success") {
                toast("Successfully shared the interview details!")
            } else {
                if (sendSmsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    toast(sendSmsRespo.message)
                }
            }
            console.log(sendSmsRespo)
        }
        setIsSendingSms(false)
    }

    const validateAllFieldsForInterviewDetails = () => {
        if (candidateName.length > 1) {
            if (validatePhoneNumber(whatsAppNumber)) {
                if (convertTimeSlotToReadableForm(interviewSlot) !== "invalidTime") {
                    if (storeName !== "") {
                        if (storeAddress !== "") {
                            if (validatePhoneNumber(contactDetails)) {
                                if (applicationIdentifier !== "") {
                                    return true
                                } else {
                                    return "Please refresh it!"
                                }
                            } else {
                                return "invalid store contact details"
                            }
                        } else {
                            return "invalid store address"
                        }
                    } else {
                        return "Invalid store name"
                    }
                } else {
                    return "invalid time slot"
                }
            } else {
                return "invalid candidate whatsApp number"
            }
        } else {
            return "Invalid candidate Name"
        }
    }



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    {validatingPrereq === true ?
                        <div className={styles.loadingPreWrapper}>
                            <div className={styles.loadingTitle}>
                                Hold On!
                            </div>
                            <div className={styles.loaderGif}>
                                <img src="https://i.pinimg.com/originals/24/07/b5/2407b512f6f2eec61cd2f3136242a025.gif" />
                            </div>
                            <div className={styles.loadingSubTitle}>
                                Validating Pre-requisite...
                            </div>
                        </div>
                        :
                        hasSlotBooked === false ?
                            <div className={styles.noSlotBookedWrapper}>
                                <div className={styles.noSlotBookedContainer}>
                                    <div className={styles.nsbIcon}>
                                        <BsInfo size={50} color="red" />
                                    </div>
                                    <div className={styles.nsbTitle}>
                                        No Interview Slot Booked Yet
                                    </div>
                                    <div className={styles.nsbSubTitle}>
                                        In order to share interview details, you must book an interview slot first.
                                    </div>
                                    <div className={styles.bookInterviewSlot}>
                                        <button onClick={() => sendInterviewDetailsHandler()}>Book Interview Slot</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={styles.mainContent}>
                                <div className={styles.langOptionsWrapper}>
                                    <div className={styles.langOptionsContainer}>
                                        <button className={styles.activeLang}>English</button>
                                        <button className={styles.defLang}>Hindi</button>
                                    </div>
                                    <div className={styles.closeBtn}>
                                        <button onClick={() => sendInterviewDetailsHandler()} >Close</button>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.fieldsWrapper}>
                                        <div className={styles.eachTwoSection}>

                                            <div className={styles.eachSection}>
                                                <div className={styles.sectionTitle}>
                                                    Candidate Name
                                                </div>
                                                <div className={styles.sectionInput}>
                                                    <input value={candidateName} readOnly={true} />
                                                    <div className={styles.statusIcon}>
                                                        {candidateName === "" ? <span><FcCancel color="red" /></span> : <span><FaCheck color="green" /></span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.eachSection}>
                                                <div className={styles.sectionTitle}>
                                                    WhatsApp Number
                                                </div>
                                                <div className={styles.sectionInput}>
                                                    <input value={whatsAppNumber} readOnly={true} />
                                                    <div className={styles.statusIcon}>
                                                        {validatePhoneNumber(whatsAppNumber) === false ? <span><FcCancel color="red" /></span> : <span><FaCheck color="green" /></span>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={styles.eachSection}>
                                            <div className={styles.sectionTitle}>
                                                Interview Slot
                                            </div>
                                            <div className={styles.sectionInput}>
                                                <input value={convertTimeSlotToReadableForm(interviewSlot)["en"]} readOnly={true} />
                                                <div className={styles.statusIcon}>
                                                    {convertTimeSlotToReadableForm(interviewSlot) === "invalidTime" ?
                                                        <span>
                                                            <FcCancel color="red" />
                                                        </span>
                                                        :
                                                        <span>
                                                            <FaCheck color="green" />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.eachSection}>
                                            <div className={styles.sectionTitle}>
                                                Store Name
                                            </div>
                                            <div className={styles.sectionInput}>
                                                <input value={storeName} readOnly={true} />
                                                <div className={styles.statusIcon}>
                                                    {storeName === "" ?
                                                        <span>
                                                            <FcCancel color="red" />
                                                        </span>
                                                        :
                                                        <span>
                                                            <FaCheck color="green" />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.eachSection}>
                                            <div className={styles.sectionTitle}>
                                                Store Address
                                            </div>
                                            <div className={styles.sectionInput}>
                                                <input value={storeAddress} readOnly={true} />
                                                <div className={styles.statusIcon}>
                                                    {storeAddress === "" ?
                                                        <span>
                                                            <FcCancel color="red" />
                                                        </span>
                                                        :
                                                        <span>
                                                            <FaCheck color="green" />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.eachSection}>
                                            <div className={styles.sectionTitle}>
                                                Store Salon Contact Details
                                            </div>
                                            {/* <div className={styles.sectionInput}>
                                                <input value={contactDetails} readOnly={true} />
                                                <div className={styles.statusIcon}>
                                                    {validatePhoneNumber(contactDetails) === false ? <span><FcCancel /></span> : <span><FaCheck color="green" /></span>}
                                                </div>
                                            </div> */}


                                            <div className={styles.sectionInput}>
                                                <select onChange={(e) => setContactDetails(e.target.value)}
                                                    defaultValue={contactDetails}
                                                >
                                                    <option>Select POC</option>
                                                    {pocsData?.map((item, index) => (
                                                        <option value={item?.phoneNumber} key={index}>
                                                            {item?.personnelName} - ({item?.designation}) - {item?.phoneNumber} {item?.isPrimary==="1" && `(P)`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input value={contactDetails} readOnly={true} />
                                                <div className={styles.statusIcon}>
                                                    {validatePhoneNumber(contactDetails) === false ? <span><FcCancel /></span> : <span><FaCheck color="green" /></span>}
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div>
                                    {contactDetails === null ?
                                        <div className={styles.updateStorePocWrapper}>
                                            <div>Missing contact details</div>
                                            <div className={styles.updateStorePocBtn}>
                                                <Link to={"/manage-stores/" + storeIdentifier} >Update Store POCs </Link>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {isSendingSms === false ?
                                                <div className={styles.sendDetailsBtn}>
                                                    {validateAllFieldsForInterviewDetails() === true ?
                                                        <button onClick={() => sentInterviewDetailsNow()}>
                                                            Send Interview Details
                                                        </button>
                                                        :
                                                        <div>
                                                            {validateAllFieldsForInterviewDetails()}
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className={styles.sendingTxt}>
                                                    Sending Interview Details...
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                    }

                </div>
            </div>
        )
    }

}

export default SendInterviewDetails