import React, { useContext, useEffect, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ManageLocations.module.css'

import 'react-toastify/dist/ReactToastify.css';

const ManageLocations = () => {
    const authCtx = useContext(AuthContext)
    const [locations, setLocations] = useState([])
    const [activeLocation, setActiveLocation] = useState('')
    const [areas, setAreas] = useState([])
    const [isFormVisible, setIsFormVisible] = useState(false)
    const [area, setArea] = useState('')
    const [isAdding, setIsAdding] = useState(false)
    const [isFetchingAreas, setIsFetchingAreas] = useState(false)

    const adminGetOperatingLocations = async () => {
        const adminGetOperatingLocationsResponse = await fetch(BASE_URL_API + "/adminGetOperatingLocations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!adminGetOperatingLocationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetOperatingLocationsRespo = await adminGetOperatingLocationsResponse.json()
            if (adminGetOperatingLocationsRespo.status === 'success') {
                setLocations(adminGetOperatingLocationsRespo.response)
            } else {
                if (adminGetOperatingLocationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setLocations([])
                }
            }

            console.log(adminGetOperatingLocationsRespo)
        }
    }

    useEffect(() => {
        adminGetOperatingLocations()
    }, [])

    const adminGetOperatingAreas = async () => {
        setIsFetchingAreas(true)
        const adminGetOperatingAreasResponse = await fetch(BASE_URL_API + "/adminGetOperatingAreas",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    locationIdentifier: activeLocation
                })
            });

        if (!adminGetOperatingAreasResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetOperatingAreasRespo = await adminGetOperatingAreasResponse.json();
            if (adminGetOperatingAreasRespo.status === 'success') {
                setAreas(adminGetOperatingAreasRespo.response)
            } else {
                if (adminGetOperatingAreasRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAreas([])
                    console.log(adminGetOperatingAreasRespo)
                }
            }
        }
        setIsFetchingAreas(false)
    }

    useEffect(() => {
        adminGetOperatingAreas()
    }, [activeLocation])

    const adminUpdateOperatingAreas = async () => {
        if (area != '') {
            setIsAdding(true)
            const adminUpdateOperatingAreasResponse = await fetch(BASE_URL_API + "/adminUpdateOperatingAreas",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        location_id: activeLocation,
                        area: area
                    })
                });

            if (!adminUpdateOperatingAreasResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const adminUpdateOperatingAreasRespo = await adminUpdateOperatingAreasResponse.json()
                if (adminUpdateOperatingAreasRespo.status === "success") {
                    if (adminUpdateOperatingAreasRespo.response === "already added") {
                        toast.warning("Already added")
                        setArea('')
                    } else {
                        toast("Successfully added")
                        setArea('')
                    }
                } else {
                    if (adminUpdateOperatingAreasRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        toast.error("Something went wrong, updation failed");
                    }
                }
                console.log(adminUpdateOperatingAreasRespo)
            }
            setIsAdding(false)
        } else {
            alert("Enter area")
        }
    }


    return (
        <div className={styles.mainContainer}>
            <ToastContainer />
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks-router">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.upperSectionWrapper}>
                    <div className={styles.usTitle}>
                        All locations
                    </div>
                    <div className={styles.allLocations}>
                        {locations.map((item, index) => (
                            <div key={index}
                                className={activeLocation === item?.ID ? styles.eachLocationSelected : styles.eachLocation}
                                onClick={() => setActiveLocation(item?.ID)}>
                                {item.location}
                            </div>
                        ))}
                    </div>
                </div>

                {activeLocation !== '' ?
                    <div className={styles.lowerSectionWrapper}>
                        <div className={styles.lsHeader}>
                            <div className={styles.lswTxt}>
                                {areas?.length} areas have been added in this region! <span onClick={() => adminGetOperatingAreas()} className={styles.reloadBtn} >Reload</span>
                            </div>
                            <div className={styles.lswFormWrapper}>
                                {isFormVisible &&
                                    <div className={styles.areaInput}>
                                        <input placeholder="add a new area" autoFocus
                                            onChange={(e) => setArea(e.target.value)}
                                            value={area}
                                        />
                                    </div>
                                }
                                {isFormVisible === false ?
                                    <button onClick={() => setIsFormVisible(true)}>Add A New Area</button>
                                    :
                                    <div className={styles.actionBtn}>
                                        {isAdding === false &&
                                            <button onClick={() => adminUpdateOperatingAreas()} className={styles.submitBtn}>Add</button>
                                        }
                                        <button onClick={() => setIsFormVisible(false)}>Cancel</button>
                                    </div>
                                }
                            </div>
                        </div>

                        {isFetchingAreas === false ?
                            <div className={styles.areasWrapper}>
                                {areas?.map((item, index) => (
                                    <div key={index} className={styles.eachArea}>
                                        {item?.area}
                                    </div>
                                ))}
                            </div>
                            :
                            <div className={styles.areasWrapper}>
                                <div className={styles.selectLocationTxt}>
                                    Hold on dude, lemme just fetch areas...
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className={styles.lowerSectionWrapper}>
                        <div className={styles.selectLocationTxt}>
                            Please select a location to start!
                        </div>
                    </div>
                }

            </div>

        </div>
    )
}

export default ManageLocations