import React, { useContext, useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../ManageApplications/DataSelector.module.css'

const DataSelector = props => {
    const { isOpen, filterWrapperToggler, appDataHandler, selectedApplication, selectApplicationHandler } = props;
    const authCtx = useContext(AuthContext);
    const [sortingMethod, setSortingMethod] = useState('DATE_DESC');
    const [applicationsData, setApplicationsData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('')
    const [isFetching, setIsFetching] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState('ALL') // ALL, APPLIED, REJECTED, HIRED
    const [appliedSource, setAppliedSource] = useState('ALL') // ALL, WEB_ADMIN, MOBILE_APP
    const [filterMode, setFilterMode] = useState('FILTER') // FILTER SEARCH

    // const openFilterPanel = () => {
    // document.getElementById("filtersPanelWrapperJAS").style.animation = "slideRightToLeft 0.5s forwards";
    // }

    window.onclick = function (event) {
        if (event.target.id == "filtersPanelWrapperJAS") {
            filterWrapperToggler()
        }
    }

    const sortingHandler = (k) => {
        setSortingMethod(k)
    }


    const getAllApplications = async () => {
        setIsFetching(true);
        const getAllApplicationsResponse = await fetch(BASE_URL_API + "/getAllApplications",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sortBy: sortingMethod,
                    startDate: startDate,
                    endDate: endDate,
                    applicationStatus: applicationStatus,
                    appliedSource: appliedSource
                })
            });

        if (!getAllApplicationsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllApplicationsRespo = await getAllApplicationsResponse.json();
            if (getAllApplicationsRespo.status == "success") {
                appDataHandler(getAllApplicationsRespo.response);
            } else {
                if (getAllApplicationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(getAllApplicationsRespo);
                }
            }
        }
        setIsFetching(false);
    }

    useEffect(() => {
        getAllApplications()
    }, [sortingMethod, startDate, endDate, applicationStatus, appliedSource]);

    const clickApplicationHandler = (applicationId) => {
        selectApplicationHandler(applicationsData[applicationsData.findIndex(ele => ele.applicationId == applicationId)]);
    }

    const dateSelectHandler = (date, type) => {
        if (type === "START_DATE") {
            // let p = date+" 00:00:01"
            setStartDate(date)
        }
        if (type === "END_DATE") {
            // let p = date+" 23:59:59";
            setEndDate(date)
        }
    }

    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}
                id="filtersPanelWrapperJAS"
            >
                <div className={styles.filtersContainer}>
                    <div className={styles.fcHeader}>
                        {filterMode === "SEARCH" ?
                            <button onClick={() => setFilterMode("FILTER")} >Switch To Search Mode</button>
                            :
                            <button onClick={() => setFilterMode("SEARCH")} >Switch To Filter Mode</button>
                        }
                    </div>
                    {filterMode === "FILTER" ?
                        <div className={styles.fcBody}>
                            <div className={styles.sortingColWrapper}>
                                <div className={styles.colTitleWrapper}>
                                    Sort By
                                </div>
                                <div>
                                    <button className={sortingMethod === 'DATE_DESC' ? styles.eachOptionSelected : styles.eachOption}
                                        onClick={() => sortingHandler('DATE_DESC')}
                                    >
                                        Applied Date - Desc
                                    </button>
                                    <button className={sortingMethod === 'DATE_ASC' ? styles.eachOptionSelected : styles.eachOption}
                                        onClick={() => sortingHandler('DATE_ASC')}
                                    >
                                        Applied Date - Asc
                                    </button>
                                </div>
                            </div>
                            <div className={styles.filterColWrapper}>
                                <div className={styles.colTitleWrapper}>
                                    <div>
                                        Filters
                                    </div>
                                </div>
                                <div className={styles.dateFilterWrapper}>
                                    <div className={styles.eachFilterTitle}>
                                        Select Date Range
                                    </div>
                                    <div className={styles.eachFilterContentWrapper}>
                                        <div className={styles.efcwContainer}>
                                            <div className={styles.eachDateSelector}>
                                                <div>Start Date</div>
                                                <input type="date"
                                                    onChange={(e) => dateSelectHandler(e.target.value, "START_DATE")}
                                                    max={endDate}
                                                />
                                            </div>
                                            <div className={styles.eachDateSelector}>
                                                <div>End Date</div>
                                                <input type="date"
                                                    onChange={(e) => dateSelectHandler(e.target.value, "END_DATE")}
                                                    min={startDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.applicationStatusWrapper}>
                                    <div className={styles.aswTitle}>
                                        Application Status
                                    </div>
                                    <div className={styles.aswBody}>
                                        <div className={styles.aswButton}>
                                            <button className={applicationStatus === "ALL" ? styles.aswbActive : styles.aswbDefault}
                                                onClick={() => setApplicationStatus("ALL")}
                                            >
                                                All
                                            </button>
                                            <button className={applicationStatus === "APPLIED" ? styles.aswbActive : styles.aswbDefault}
                                                onClick={() => setApplicationStatus("APPLIED")}
                                            >
                                                Applied
                                            </button>
                                            <button className={applicationStatus === "REJECTED" ? styles.aswbActive : styles.aswbDefault}
                                                onClick={() => setApplicationStatus("REJECTED")}
                                            >
                                                Rejected
                                            </button>
                                            <button className={applicationStatus === "HIRED" ? styles.aswbActive : styles.aswbDefault}
                                                onClick={() => setApplicationStatus("HIRED")}
                                            >
                                                Hired
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.appliedSourceWrapper}>
                                    <div className={styles.aswTitle}>Applied Source</div>
                                    <div className={styles.aswBody}>
                                        <div>
                                            <button
                                                onClick={() => setAppliedSource("ALL")}
                                                className={appliedSource === "ALL" ? styles.aswbActive : styles.aswbDefault}
                                            >
                                                All
                                            </button>
                                            <button
                                                onClick={() => setAppliedSource("ADMIN")}
                                                className={appliedSource === "ADMIN" ? styles.aswbActive : styles.aswbDefault}
                                            >
                                                Admin
                                            </button>
                                            <button
                                                onClick={() => setAppliedSource("ANDROID_APP")}
                                                className={appliedSource === "ANDROID_APP" ? styles.aswbActive : styles.aswbDefault}
                                            >
                                                Mobile App
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <div className={styles.fcBody}>
                            <input placeholder="Search for any candidate, salon, store... " />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DataSelector