import React, { useContext, useState } from 'react'
import { MdSave } from 'react-icons/md'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import CourseLevelInterestForm from './CourseLevelInterestForm'
import styles from './UpdateLeadCourseLevelInterest.module.css'



const UpdateLeadCourseLevelInterest = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, leadIdentifier, abortInterestUpdation } = props
    const [activeProgram, setActiveProgram] = useState('')
    const [activeDomain, setActiveDomain] = useState('')

    


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                
                <div className={styles.mainHeader}>
                    <button className={styles.closeBtn} onClick={() => abortInterestUpdation()}>Close</button>
                    {/* <button className={styles.saveBtn}><MdSave color="white" />&nbsp; Save</button> */}
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.programTypes}>
                        <button onClick={() => setActiveProgram('Course')} className={activeProgram === 'Course' ? styles.activeProgram : styles.defaultBtn} >Course</button>
                        <button onClick={() => setActiveProgram('Masterclass')} className={activeProgram === 'Masterclass' ? styles.activeProgram : styles.defaultBtn} >Masterclass</button>
                        <button onClick={() => setActiveProgram('Workshop')} className={activeProgram === 'Workshop' ? styles.activeProgram : styles.defaultBtn} >Workshop</button>
                    </div>
                    <div className={styles.contentWrapper}>
                        {activeProgram === '' ?
                            <div className={styles.mainContent}>
                                Please select a program type first!
                            </div>
                            :
                            <div className={styles.mainContent}>
                                <div className={styles.leftCol}>
                                    <div className={styles.leftColTitle}>Select Course Domain</div>
                                    <div className={styles.coursesDomainList}>
                                        <div className={activeDomain === 'BEAUTY' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='BEAUTY' ? '' : 'BEAUTY')}
                                        >
                                            Beauty
                                        </div>
                                        <div className={activeDomain === 'HAIR' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='HAIR' ? '' : 'HAIR')}
                                        >
                                            Hair
                                        </div>
                                        <div className={activeDomain === 'MAKEUP' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='MAKEUP' ? '' : 'MAKEUP')}
                                        >
                                            Makeup
                                        </div>
                                        <div className={activeDomain === 'MEHANDI' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='MEHANDI' ? '' : 'MEHANDI')}
                                        >
                                            Mehandi
                                        </div>
                                        <div className={activeDomain === 'NAIL' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='NAIL' ? '' : 'NAIL')}
                                        >
                                            Nail
                                        </div>
                                        <div className={activeDomain === 'SALON_MANAGEMENT' ? styles.eachCourseDomainActive : styles.eachCourseDomain}
                                            onClick={() => setActiveDomain(activeDomain==='SALON_MANAGEMENT' ? '' : 'SALON_MANAGEMENT')}
                                        >
                                            Salon Management
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.rightCol}>
                                    {activeDomain !== '' ?
                                        <CourseLevelInterestForm leadIdentifier={leadIdentifier} activeProgram={activeProgram} activeDomain={activeDomain} />
                                        :
                                        <div className={styles.selectACourseDomainFirst}>Please select a course domain!</div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                UpdateLeadCourseLevelInterest
            </div>
        )
    }
}

export default UpdateLeadCourseLevelInterest