import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbLanguagesSpokenAddNew.module.css'

import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f1f1f1'
    },
};

const RbLanguagesSpokenAddNew = props => {
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    const getAddedData = props.getAddedData;
    const authCtx = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)

    const [availableLanguageOptions, setAvailableLanguageOptions] = useState([])
    const [selectedLang, setSelectedLang] = useState('')
    const [selectedLoU, setSelectedLoU] = useState('');
    const [addingStatus, setAddingStatus] = useState(false)

    const getLanguageOptions = async () => {
        const getLanguageOptionsResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'raw',
                    sectionType: 'language'
                })
            });

        if (!getLanguageOptionsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getLanguageOptionsRespo = await getLanguageOptionsResponse.json();
            if (getLanguageOptionsRespo.status === "success") {
                console.log(getLanguageOptionsRespo)
                setAvailableLanguageOptions(getLanguageOptionsRespo.response);
            } else {
                if (getLanguageOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getLanguageOptionsRespo);
                }
            }
        }
    }

    useEffect(() => {
        getLanguageOptions();
    }, [])

    function closeModal() {
        setIsOpen(false);
    }

    const addLanguageInitiator = (k) => {
        setSelectedLang(k);
        setIsOpen(true);
    }

    const addLanguageSubmit = async () => {
        setAddingStatus(true)
        const addLanguageResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'addNew',
                    sectionType: 'language',
                    language_id: selectedLang.language_id,
                    uL: selectedLoU,
                    selectedLangIcon: selectedLang.icon
                })
            });

        if (!addLanguageResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const addLanguageRespo = await addLanguageResponse.json();
            if (addLanguageRespo.status === "success") {
                setIsOpen(false)
                getAddedData();
            } else {
                if (addLanguageRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(addLanguageRespo);
                }
            }
            setAddingStatus(false)
        }
    }


    return (
        <div className={styles.rightWrapper}>
            <div className={styles.rightHeaderWrapper}>
                Add new {selectedProfileType}
            </div>
            <div className={styles.rightBodyWrapper}>
                <div className={styles.languagesOptionsAvailable}>
                    <div className={styles.languagesOptionsAvailableTitle}>
                        Languages to be Added!
                    </div>
                    <div className={styles.languagesOptionsWrapper}>
                        {availableLanguageOptions.map((item, index) => (
                            <div className={styles.eachLanguageOption}
                                key={index}
                                onClick={() => addLanguageInitiator(item)}
                            >
                                <div className={styles.languageOptionIcon}>
                                    <img
                                        src={item.icon}
                                    />
                                </div>
                                <div className={styles.languageOptionCaption}>{item.language_id}</div>
                            </div>
                        ))}
                    </div>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        {selectedLang !== '' ?
                            <div className={styles.modelContainer}>
                                <div className={styles.modelHeader}>
                                    Select level of understanding of {selectedLang.language_id}
                                </div>
                                <div className={styles.ulOptionsWrapper}>
                                    <div className={selectedLoU === 'Can Speak' ? styles.eachUlOptionsSelected : styles.eachUlOptions}
                                        onClick={() => setSelectedLoU('Can Speak')}
                                    >
                                        Can Speak
                                    </div>
                                    <div className={selectedLoU === 'Can Speak and read/write' ? styles.eachUlOptionsSelected : styles.eachUlOptions}
                                        onClick={() => setSelectedLoU('Can Speak and read/write')}
                                    >
                                        Can Speak and read/write
                                    </div>
                                    <div className={selectedLoU === 'Can speak a little' ? styles.eachUlOptionsSelected : styles.eachUlOptions}
                                        onClick={() => setSelectedLoU('Can speak a little')}
                                    >
                                        Can speak a little
                                    </div>
                                    <div className={selectedLoU === 'Fluent in speaking' ? styles.eachUlOptionsSelected : styles.eachUlOptions}
                                        onClick={() => setSelectedLoU('Fluent in speaking')}
                                    >
                                        Fluent in speaking
                                    </div>
                                </div>
                                {addingStatus === false ?
                                    <div className={styles.rightFooterContainer}>
                                        <button className={styles.secondaryBtn}
                                            onClick={() => setIsOpen(false)}
                                        >Cancel</button>
                                        {selectedLoU !== '' &&
                                            <button className={styles.primaryBtn}
                                                onClick={() => addLanguageSubmit()}
                                            >Save</button>
                                        }
                                    </div>
                                    :
                                    <div className={styles.rightFooterContainer}>
                                        Hold on! I'm just adding the data..
                                    </div>
                                }

                            </div>
                            :
                            <div className={styles.modelContainer}>
                                You haven't selected any language!
                            </div>
                        }

                    </Modal>
                </div>
            </div>
            <div className={styles.rightFooterWrapper}>
                <div className={styles.rightFooterContainer}>

                </div>
            </div>
        </div>
    )
}

export default RbLanguagesSpokenAddNew