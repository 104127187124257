import React, { useEffect, useState } from 'react'
import styles from './CourseLeadPrimaryQuestionnaire.module.css'

const CourseLeadPrimaryQuestionnaire = props => {
    const {updateLeadAction, abortActionCreation, leadIdentifier} = props;
    const questionnaire = [{
        id: 1,
        question: 'Has the call connected?',
        answerOptions: [
            { answer: 'Yes', nextQuestion: 2 },
            { answer: 'No', nextQuestion: 3 }
        ],
        answer: '',
    },
    {
        id: 2,
        question: 'Does this person belong to Industry?',
        answerOptions: [
            {
                answer: 'Yes',
                nextQuestion: 4,
            },
            {
                answer: 'No',
                nextQuestion: 7,
            },
        ],
        answer: '',
    },
    {
        id: 3,
        question: 'Why call couldn\'t be connected?',
        answerOptions: [
            {
                answer: 'Wrong Number',
                nextQuestion: 7,
            },
            {
                answer: 'Recharge Over',
                nextQuestion: 5,
            },
            {
                answer: 'Busy',
                nextQuestion: 5,
            },
            {
                answer: 'Could not have the conversation due to network issue.',
                nextQuestion: 5,
            },
        ],
        answer: '',
    },
    {
        id: 4,
        question: 'Currently looking for a course?',
        answerOptions: [
            {
                answer: 'Yes',
                nextQuestion: 6,
            },
            {
                answer: 'No',
                nextQuestion: 7,
            },
        ],
        answer: '',
    },
    {
        id: 5,
        question: 'Take further actions',
        answerOptions: [
            {
                answer: 'Re-schedule lead',
                nextQuestion: 5,
            },
            {
                answer: 'Skip lead',
                nextQuestion: 5,
            },
        ],
        answer: '',
    },
    {
        id: 6,
        question: 'Schedule Candidate\'s Visit to academy?',
        answerOptions: [
            {
                answer: 'Yes',
                nextQuestion: 6
            },
            {
                answer: 'No',
                nextQuestion: 7
            }
        ],
        answer: '',
    },
    {
        id: 7,
        question: 'Take further actions',
        answerOptions: [
            {
                answer: 'Skip lead',
                nextQuestion: 7
            }
        ]
    }
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isMovingBack, setIsMovingBack] = useState(false);


    const handleNext = () => {
        const currentQuestion = questionnaire[currentQuestionIndex];
        const answer = answers[answers.length - 1];
        // Check if an answer has been selected
        if (answer) {
            // Find the next question based on the answer selected
            const nextQuestion = currentQuestion.answerOptions.find(
                (option) => option.answer === answer.answer
            )?.nextQuestion;
            if (typeof nextQuestion !== 'undefined') {
                // Set the next question index
                const nextQuestionIndex = questionnaire.findIndex(
                    (question) => question.id === nextQuestion
                );
                // Check if the next question index was found
                if (nextQuestionIndex !== -1) {
                    setCurrentQuestionIndex(nextQuestionIndex);
                } else {
                    // If next question index was not found, set current question to end of questionnaire
                    setCurrentQuestionIndex(questionnaire.length - 1);
                }
            } else {
                // If next question is not defined, set current question to end of questionnaire
                setCurrentQuestionIndex(questionnaire.length - 1);
            }
        }
    };



    const handleAnswer = (answer) => {
        setIsMovingBack(false)

        // console.log("This is answer : ",answer)
        if (questionnaire[currentQuestionIndex].question === 'Has the call connected?') {
            if (answer === 'Yes') {
                updateLeadAction("CALL_CONNECTED", "Executive call connected with stylist")
            }
        }

        if (questionnaire[currentQuestionIndex].question === "Why call couldn't be connected?") {
            if (answer === 'Wrong Number') {
                updateLeadAction("CALL_NOT_CONNECTED", "Could not connect the call because of wrong number")
            } else {
                updateLeadAction("CALL_NOT_CONNECTED", answer)
            }
        }

        if (questionnaire[currentQuestionIndex].question === "Does this person belong to Industry?") {
            if (answer === 'Yes') {
                updateLeadAction("LEAD_VALIDATED", "Person belong to the beauty industry")
            } else if (answer === 'No') {
                updateLeadAction("INVALID_LEAD", 'Person does not belong to beauty industry')
            }
        }

        if (questionnaire[currentQuestionIndex].question === "Currently looking for a course?") {
            if (answer === 'Yes') {
                updateLeadAction("CURRENTLY_LOOKING_FOR_COURSE", "Executive verified that the candidate is currently looking for course")
            } else if (answer === 'No') {
                updateLeadAction("CURRENTLY_NOT_LOOKING_FOR_COURSE", 'Executive verified that the candidate is currently not looking for course')
            }
        }


        /// let's handle the edge cases like closing of questionnaire, initate profile 
        /// creation etc. on the basis of answers
        console.log(questionnaire[currentQuestionIndex], answer)
        if (questionnaire[currentQuestionIndex].question === "Schedule Candidate\'s Visit to academy?") {
            if (answer === 'Yes') {
                // initiateProfileCreation()
                abortActionCreation()
            }
        }

        if (questionnaire[currentQuestionIndex].question === 'Take further actions') {
            if (answer === 'Skip lead') {
                updateLeadAction("LEAD_SKIPPED", "Lead skipped by the executive")
            } else if (answer === 'Re-schedule lead') {
                updateLeadAction("LEAD_SKIPPED", "Lead re-scheduled by the executive")
            }
            abortActionCreation()
        }

        setAnswers((prevAnswers) => {
            const question = questionnaire[currentQuestionIndex];
            const questionId = question.id;
            const updatedAnswer = {
                answer,
                questionId,
                question: question.question
            };
            const existingAnswerIndex = prevAnswers.findIndex(
                (a) => a.questionId === questionId
            );
            let updatedAnswers;
            if (existingAnswerIndex !== -1) {
                // update existing answer if selected answer is different
                const existingAnswer = prevAnswers[existingAnswerIndex];
                if (existingAnswer.answer !== answer) {
                    updatedAnswers = [
                        ...prevAnswers.slice(0, existingAnswerIndex),
                        updatedAnswer,
                        ...prevAnswers.slice(existingAnswerIndex + 1),
                    ];
                } else {
                    // do nothing if selected answer is same as existing answer
                    updatedAnswers = prevAnswers;
                }
            } else {
                updatedAnswers = [...prevAnswers, updatedAnswer];
            }
            return updatedAnswers;
        })

    };


    const moveBack = (k) => {
        setIsMovingBack(true)
        let cqi = questionnaire.findIndex(el => el.id === answers[k].questionId);
        setCurrentQuestionIndex(cqi)
        let tempAnswers = answers;
        tempAnswers = tempAnswers.slice(0, k)
        setAnswers(tempAnswers)
    }


    useEffect(() => {
        if (answers.length > 0 && !isMovingBack) {
            handleNext();
        }
    }, [answers]);

    const renderQuestion = (question) => {
        return (
            <div key={question.id} className={styles.eachQuestionContainer} >
                <h3>{question.question}</h3>
                <div className={styles.optionsWrapper} >
                    {question.answerOptions.map((option) => (
                        <div key={option.answer} className={styles.callStatusAnswerOptions}>
                            <button onClick={() => handleAnswer(option.answer)}>
                                {option.answer}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderProgress = () => {
        const percent = ((currentQuestionIndex + 1) / questionnaire.length) * 100;
        return <p>Progress: {percent}%</p>;
    };


    return (
        <div className={styles.questionnaireWrapper}>
            <div className={styles.breadcrumbWrapper}>
                <div className={styles.leftBreadcrumb}>
                    {answers.map((item, index) => (
                        <button key={index} onClick={() => moveBack(index)} >{item.question} : {item.answer}</button>
                    ))}
                </div>
                <div>
                    <a href={`tel:` + leadIdentifier}>Dial</a>
                </div>
            </div>
            <div className={styles.eachQuestionWrapper}

            >
                <div>
                    {/* {renderProgress()} */}
                    {renderQuestion(questionnaire[currentQuestionIndex])}
                    <div>
                        <button onClick={handleNext} disabled={!answers[currentQuestionIndex]}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseLeadPrimaryQuestionnaire