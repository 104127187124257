import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import styles from '../Tasks/ActionWrapper.module.css'
import LeadPrimaryQuestionnaire from './LeadPrimaryQuestionnaire'

const ActionWrapper = props => {
    const { isOpen, abortActionCreation, initiateProfileCreation, updateLeadAction, leadIdentifier } = props

    if (!isOpen) {
        return null
    }
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="leadProfileCreationContainer"
                style={{ animation: `0.1s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
            >
                <div className={styles.closeBtn}>
                    <button onClick={() => abortActionCreation()}><MdClose size={24} /></button>
                </div>

                <div className={styles.creationForm}>
                    <LeadPrimaryQuestionnaire 
                        abortActionCreation={abortActionCreation}
                        initiateProfileCreation={initiateProfileCreation} 
                        updateLeadAction={updateLeadAction}
                        leadIdentifier={leadIdentifier}
                    />
                </div>
            </div>
        </div>
    )
}

export default ActionWrapper