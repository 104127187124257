import React, { useContext, useState } from 'react'
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { ToastContainer } from 'react-toastify';
import WorkshopRegistrationsAll from '../Components/Workshops/WorkshopRegistrationsAll';
import AuthContext from '../store/auth-context';
import styles from '../Content/ManageWorkshopRegistrations.module.css'
import MWRSuggestedList from '../Components/Workshops/MWRSuggestedList';
import MWRDetailedView from '../Components/Workshops/MWRDetailedView';


const ManageWorkshopRegistrations = props => {
    let authCtx = useContext(AuthContext);

    const [selectedWorkshop, setSelectedWorkshop] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [requestingMethod, setRequestingMethod] = useState('')

    const toggleSelectedWorkshop = k => {
        setSelectedWorkshop(k)
    }

    const toggleSelectedUser = k => {
        setSelectedUser(k)
    }

    const toggleRequestingMethod = k => {
        setRequestingMethod(k)
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftWrapper}>
                <ToastContainer />
                <MWRSuggestedList 
                    toggleSelectedWorkshop={toggleSelectedWorkshop}
                    toggleSelectedUser={toggleSelectedUser}
                    selectedWorkshop={selectedWorkshop}
                    selectedUser={selectedUser}
                    requestingMethod={requestingMethod}
                    toggleRequestingMethod={toggleRequestingMethod}
                />
            </div>


            <div className={styles.centerWrapper}>
                <MWRDetailedView
                    requestingMethod={requestingMethod}
                    selectedUser={selectedUser}
                    selectedWorkshop={selectedWorkshop}
                />
            </div>
        </div>
    )
}

export default ManageWorkshopRegistrations