import React from 'react'
import { BsCheck2All } from 'react-icons/bs';
import styles from '../ManageJobs/EachJobCategoryOptionInFilters.module.css'

const EachJobCategoryOptionInFilters = props => {
    let item = props.item
    const selectedJobCategories = props.selectedJobCategories;
    const toggleCategorySelectionHandler = props.toggleCategorySelectionHandler;
    return (
        <div className={styles.eachCategoryOption}
            onClick={() => toggleCategorySelectionHandler(item.ID)}
        >
            <div>
                {item.category_name}
            </div>
            <div>
                {selectedJobCategories.includes(item.ID) == true &&
                    <BsCheck2All />
                }
            </div>
        </div>
    )
}

export default EachJobCategoryOptionInFilters