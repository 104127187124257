import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { FaChevronRight } from 'react-icons/fa';
import styles from "../Styles/Content/CreateWorkshop.module.css";
import CountDownTimer from '../Components/CountDownTimer';
import { BASE_URL_API } from '../References/urls';
import { Link } from 'react-router-dom';
// import { FaRefresh } from '@fortawesome/free-solid-svg-icons';
import { GrRefresh } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import AuthContext from '../store/auth-context';
// import  { Redirect } from 'react-router-dom'




const CreateWorkshop = () => {
    const authCtx = useContext(AuthContext);
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingInstructors, setIsFetchingInstructors] = useState(true);
    const [workShopTitle, setWorkShopTitle] = useState("");
    const [workShopMode, setWorkShopMode] = useState("");
    const [workShopPlatform, setWorkShopPlatform] = useState("");

    const [workShopStartDate, setWorkShopStartDate] = useState(new Date())

    const [workShopDuration, setWorkShopDuration] = useState(10);
    const [workShopDurationFormat, setWorkShopDurationFormat] = useState("minutes");

    const [instructorLanguage, setInstructorLanguage] = useState("");
    const [workShopCategory, setWorkShopCategory] = useState("")
    const [feeType, setFeeType] = useState("")
    const [workShopOrgPrice, setWorkShopOrgPrice] = useState(1)
    const [workShopDisPrice, setWorkShopDisPrice] = useState(0)
    const [maxCapacity, setMaxCapacity] = useState(0);
    const [workShopDescription, setWorkShopDescription] = useState("");
    const [workShopCaption, setWorkShopCaption] = useState("")
    const [selectedInstructor, setSelectedInstructor] = useState("")

    const [instructorData, setInstructorData] = useState(null)

    const workShopTitleHandler = (val) => {
        setWorkShopTitle(val);
    }

    const workShopModeHandler = (val) => {
        setWorkShopMode(workShopMode === val ? "" : val);
    }

    const workShopPlatformHandler = (val) => {
        setWorkShopPlatform(workShopPlatform === val ? "" : val);
    }

    const [workShopKeyBenefitArr, setWorkShopKeyBenefitArr] = useState([
        {
            "benefitTxt": ""
        }]
    )

    const eachBenefitHandler = (i, v) => {
        let temp_state = [...workShopKeyBenefitArr];
        let temp_element = { ...temp_state[i] };
        temp_element.benefitTxt = v;
        temp_state[i] = temp_element;
        setWorkShopKeyBenefitArr(temp_state);
    }

    const addNewBenefitHandler = () => {
        setWorkShopKeyBenefitArr([...workShopKeyBenefitArr, { "benefitTxt": "" }])
    }

    const removeBenefitHandler = (i) => {
        setWorkShopKeyBenefitArr(workShopKeyBenefitArr.filter((item) => item != i))
    }

    const capacityHandler = (t) => {
        let c = maxCapacity;
        console.log(maxCapacity);
        if (t === "inc") {
            c++
        } else {
            c--
        }
        setMaxCapacity(c)
    }

    const submitWorkshopHandler = async () => {
        let p = new Date(workShopStartDate);
        let actualStartDate = p.getFullYear()+"-"+('0' + p.getMonth()).slice(-2)  +"-"+('0' + p.getDate()).slice(-2) + " "+ ('0' + p.getHours()).slice(-2) + ":" + ('0' + p.getMinutes()).slice(-2) + ":00"
        const createWorkshopResponse = await fetch(BASE_URL_API + "/createWorkshop",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    workshopTitle: workShopTitle,
                    workshopSubTitle: "workshopSubTitle",
                    endTime: "endTime",
                    workshopDescription: workShopDescription,
                    modeOfConduction: workShopMode,
                    platform: workShopPlatform,
                    platformAddress: "platformAddress",
                    platformPincode: "platformPincode",
                    instructorLanguage: instructorLanguage,
                    instructorID: selectedInstructor,
                    workshopCategory: workShopCategory,
                    feeType: feeType,
                    orgPrice: workShopOrgPrice,
                    discountedPrice: workShopDisPrice,
                    maxCapacityAllowed: maxCapacity,
                    workshopDuration: workShopDuration,
                    startingTime: actualStartDate,
                    offerCaption: workShopCaption,
                    workShopKeyBenefitArr: JSON.stringify(workShopKeyBenefitArr)
                })
            });

        if (!createWorkshopResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const createWorkshopRespo = await createWorkshopResponse.json();
            if (createWorkshopRespo.status === "success") {
                navigate("/workshops");
            } else {
                console.log(createWorkshopRespo);
            }
        }
    }

    const getInstructors = async () => {
        setIsFetchingInstructors(true);
        const getInstructorsResponse = await fetch(BASE_URL_API + "/getInstructors",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                })
            });

        if (!getInstructorsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getInstructorsRespo = await getInstructorsResponse.json();
            if (getInstructorsRespo.status === "success") {
                setInstructorData(getInstructorsRespo.response)
            } else {
                console.log(getInstructorsRespo)
            }
        }
        setIsFetchingInstructors(false);
    }

    useEffect(() => {
        setIsFetchingInstructors(true);
        getInstructors().then(() => setIsFetchingInstructors(false));
    }, [])
    // console.log(workShopDescription);

    // console.log(workShopKeyBenefitArr)

    return (
        <div className={styles.wrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.sectionHeader}>
                        <div className={styles.sectionTitle}>
                            Create Workshop
                        </div>
                    </div>
                    <div className={styles.sectionBody}>
                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Workshop Category
                            </div>
                            <div className={styles.eachRowBody}>
                                <button className={workShopCategory === "MAKEUP" ? styles.eachCategorySelected : styles.eachCategory}
                                    onClick={() => setWorkShopCategory(workShopCategory === "MAKEUP" ? "" : "MAKEUP")}
                                >
                                    MAKE-UP
                                </button>
                                <button className={workShopCategory === "HAIR" ? styles.eachCategorySelected : styles.eachCategory}
                                    onClick={() => setWorkShopCategory(workShopCategory === "HAIR" ? "" : "HAIR")}
                                >
                                    HAIR
                                </button>
                                <button className={workShopCategory === "BEAUTY" ? styles.eachCategorySelected : styles.eachCategory}
                                    onClick={() => setWorkShopCategory(workShopCategory === "BEAUTY" ? "" : "BEAUTY")}
                                >
                                    BEAUTY
                                </button>
                                <button className={workShopCategory === "CAREER" ? styles.eachCategorySelected : styles.eachCategory}
                                    onClick={() => setWorkShopCategory(workShopCategory === "CAREER" ? "" : "CAREER")}
                                >
                                    CAREER
                                </button>

                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Enter Workshop Title
                            </div>
                            <input className={styles.workShopTitle} placeholder="Enter workshop title"
                                onChange={(e) => workShopTitleHandler(e.target.value)}
                            ></input>
                        </div>
                        <div className={styles.eachRow}>
                            <div >
                                <div className={styles.eachRowTitle}>
                                    Select Mode of Workshop
                                </div>
                                <div className={styles.modeOfWorkshopContainer}>
                                    <button className={workShopMode === "Online" ? styles.modeOfWorkshopSelected : styles.modeOfWorkshop} onClick={() => workShopModeHandler("Online")}>
                                        Online
                                    </button>
                                    <button className={workShopMode === "Offline" ? styles.modeOfWorkshopSelected : styles.modeOfWorkshop} onClick={() => workShopModeHandler("Offline")}>
                                        Offline
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className={styles.eachRowTitle}>
                                    Select Platform
                                </div>
                                <div className={styles.platformContainer}>
                                    <button className={workShopPlatform === "Google Meet" ? styles.eachPlatformSelected : styles.eachPlatform} onClick={() => workShopPlatformHandler("Google Meet")}>
                                        Google Meet
                                    </button>
                                    <button className={workShopPlatform === "Zoom" ? styles.eachPlatformSelected : styles.eachPlatform} onClick={() => workShopPlatformHandler("Zoom")}>
                                        Zoom
                                    </button>
                                    <button className={workShopPlatform === "Instagram Live" ? styles.eachPlatformSelected : styles.eachPlatform} onClick={() => workShopPlatformHandler("Instagram Live")}>
                                        Instagram Live
                                    </button>
                                    <button className={workShopPlatform === "YouTube Live" ? styles.eachPlatformSelected : styles.eachPlatform} onClick={() => workShopPlatformHandler("YouTube Live")}>
                                        YouTube Live
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Workshop Details
                            </div>
                            <div className={styles.eachRowBody}>
                                <textarea onChange={(e) => setWorkShopDescription(e.target.value)}>
                                </textarea>
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Workshop Key Benefits
                            </div>
                            {workShopKeyBenefitArr !== null &&
                                <div className={styles.eachRowBody}>
                                    {workShopKeyBenefitArr.map((item, index) => (
                                        <div className={styles.eachBenefitContainer} key={index}>
                                            <input className={styles.eachBenefitRow} value={item.benefitTxt}
                                                onChange={(e) => eachBenefitHandler(index, e.target.value)}
                                            >
                                            </input>
                                            {index === (workShopKeyBenefitArr.length - 1) ?
                                                <button className={styles.addNewBenefitRow} onClick={() => addNewBenefitHandler()}>+</button>
                                                :
                                                <button className={styles.addNewBenefitRow} onClick={() => removeBenefitHandler(item)}>-</button>
                                            }
                                        </div>
                                    ))}
                                </div>}
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Workshop Date & Time
                            </div>
                            <div className={styles.eachRowBody}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="DateTimePicker"
                                        value={workShopStartDate}
                                        onChange={(newValue) => {
                                            setWorkShopStartDate(newValue);
                                        }}
                                        disablePast={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Duration of Workshop
                            </div>
                            {workShopDurationFormat === "minutes" ?
                                <div className={styles.eachRowBody}>
                                    <button className={styles.eachDecBtn} onClick={() => setWorkShopDuration(workShopDuration < 11 ? workShopDuration : workShopDuration - 5)}>-</button>
                                    {workShopDuration} <span className={styles.durationFormat} onClick={() => setWorkShopDurationFormat(workShopDurationFormat === "minutes" ? "hours" : "minutes")}>{workShopDurationFormat}</span>
                                    <button className={styles.eachIncBtn} onClick={() => setWorkShopDuration(workShopDuration > 120 ? workShopDuration : workShopDuration + 5)}>+</button>
                                </div>
                                :
                                <div className={styles.eachRowBody}>
                                    <button className={styles.eachDecBtn} onClick={() => setWorkShopDuration(workShopDuration < 1 ? workShopDuration : workShopDuration - 1)}>-</button>
                                    {workShopDuration} <span className={styles.durationFormat} onClick={() => setWorkShopDurationFormat(workShopDurationFormat === "minutes" ? "hours" : "minutes")}>{workShopDurationFormat}</span>
                                    <button className={styles.eachIncBtn} onClick={() => setWorkShopDuration(workShopDuration > 24 ? workShopDuration : workShopDuration + 1)}>+</button>
                                </div>
                            }
                        </div>


                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Instructor Language in the Workshop
                            </div>
                            <div className={styles.eachRowBody}>
                                <button className={instructorLanguage === "English" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "English" ? "" : "English")}>
                                    English
                                </button>
                                <button className={instructorLanguage === "Hindi" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Hindi" ? "" : "Hindi")}>
                                    Hindi
                                </button>
                                <button className={instructorLanguage === "Hindi-English" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Hindi-English" ? "" : "Hindi-English")}>
                                    Hindi - English
                                </button>
                                <button className={instructorLanguage === "Punjabi" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Punjabi" ? "" : "Punjabi")}>
                                    Punjabi
                                </button>
                                <button className={instructorLanguage === "Marathi" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Marathi" ? "" : "Marathi")}>
                                    Marathi
                                </button>
                                <button className={instructorLanguage === "Tamil" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Tamil" ? "" : "Tamil")}>
                                    Tamil
                                </button>
                                <button className={instructorLanguage === "Kannada" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Kannada" ? "" : "Kannada")}>
                                    Kannada
                                </button>
                                <button className={instructorLanguage === "Haryanvi" ? styles.eachInstructorLanguageSelected : styles.eachInstructorLanguage} onClick={() => setInstructorLanguage(instructorLanguage === "Haryanvi" ? "" : "Haryanvi")}>
                                    Haryanvi
                                </button>
                            </div>
                        </div>


                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Fee Type
                            </div>
                            <div className={styles.eachRowBody}>
                                <button className={feeType === "PAID" ? styles.eachCategorySelected : styles.eachCategory} onClick={() => setFeeType(feeType === "PAID" ? "" : "PAID")}>PAID</button>
                                <button className={feeType === "UNPAID" ? styles.eachCategorySelected : styles.eachCategory} onClick={() => setFeeType(feeType === "UNPAID" ? "" : "UNPAID")}>UNPAID</button>
                                {feeType === "PAID" &&
                                    <div className={styles.priceContainer}>
                                        <input placeholder="Original Price" type='number'
                                            onChange={(e) => setWorkShopOrgPrice(e.target.value)}
                                        >
                                        </input>
                                        <input placeholder="Discounted Price" type='number'
                                            onChange={(e) => setWorkShopDisPrice(e.target.value)}
                                        >
                                        </input>
                                        <span>{Math.ceil(((workShopOrgPrice - workShopDisPrice) / workShopOrgPrice) * 100)} %</span>
                                    </div>
                                }
                                {feeType === "UNPAID" &&
                                    <div className={styles.priceContainer}>
                                        <input placeholder="Original Price" type='number'
                                            onChange={(e) => setWorkShopOrgPrice(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Maximum Person Capacity
                            </div>
                            <div className={styles.eachRowBody}>
                                <button className={styles.eachDecBtn} onClick={() => setMaxCapacity(maxCapacity < 1 ? 0 : maxCapacity - 1)}>-</button>
                                <span>{maxCapacity}</span>
                                <button className={styles.eachIncBtn}
                                    onClick={() => capacityHandler("inc")}
                                >+</button>
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Offer Caption
                            </div>
                            <div className={styles.eachRowBody}>
                                <input placeholder="Add offer caption" className={styles.workShopTitle}
                                    onChange={(e) => setWorkShopCaption(e.target.value)}
                                ></input>
                            </div>
                        </div>

                        <div className={styles.eachRow}>
                            <div className={styles.eachRowTitle}>
                                Instructor Details
                                <div className={styles.chevronIcon}
                                    onClick={() => getInstructors()}
                                >
                                    <GrRefresh />
                                </div>
                            </div>
                            <div className={styles.eachRowBody}>
                                {isFetchingInstructors === false ?
                                    <div className={styles.instructorsWrapper}>
                                        {instructorData !== null &&
                                            instructorData.map((item, index) => (
                                                <button className={selectedInstructor === item.instructorID ? styles.eachInstructorContainerSelected : styles.eachInstructorContainer} onClick={() => setSelectedInstructor(selectedInstructor === item.instructorID ? "" : item.instructorID)}>
                                                    <div className={styles.instructorImageContainer}>
                                                        <img src={item.instructorProfilePic} className={styles.tinyInstructorImage} />
                                                    </div>
                                                    <div className={styles.instructorDetailsSection}>
                                                        <div>
                                                            <div className={styles.instructorName}>{item.instructorName}</div>
                                                            <div className={styles.instructorCategory}> {item.instructorCategory}</div>
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                </button>
                                            ))
                                        }
                                        <button onClick={() => navigate("/add-instructor")} className={styles.eachInstructorContainer}>
                                            <div className={styles.addNewInstructor}>
                                                Add New Instructor
                                            </div>
                                        </button>

                                    </div>
                                    :
                                    <div className={styles.loadingInstructorsContainer}>
                                        Fetching instructors, please wait...
                                    </div>
                                }
                            </div>
                        </div>



                    </div>
                </div>


                <div className={styles.rightContainer}>
                    <div className={styles.sectionHeader}>
                        <div className={styles.sectionTitle}>
                            Preview
                        </div>

                    </div>
                    <div className={styles.previewSectionBody}>
                        <div className={styles.previewContainer}>
                            <div className={styles.prTitleTimer}>
                                <div className={styles.prTitle}>
                                    {workShopTitle}
                                </div>
                                <div className={styles.timer}>
                                    {/* <CountDownTimer workShopStartDate={workShopStartDate} /> */}
                                </div>
                            </div>
                            {workShopMode !== "" &&
                                <div className={styles.prWorkShopMode}>
                                    {workShopMode} {workShopPlatform !== "" && `> ` + workShopPlatform}
                                </div>

                            }
                            {workShopKeyBenefitArr !== null &&
                                <div className={styles.prBenefitContainer}>
                                    {workShopKeyBenefitArr.map((item, index) => (
                                        item.benefitTxt !== "" &&
                                        <div className={styles.prEachBenefit} key={"P" + index}>
                                            <FaChevronRight fontSize={9} /> {item.benefitTxt}
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className={styles.prWorkshopDescription}>
                                {workShopDescription}
                            </div>
                            <div>{selectedInstructor}</div>
                        </div>
                    </div>
                    {selectedInstructor !== "" &&
                        <button className={styles.submitBtn}
                            onClick={() => submitWorkshopHandler()}
                        >
                            Submit
                        </button>
                    }
                </div>
            </div>
        </div >
    )
}


export default CreateWorkshop;