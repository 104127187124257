import React, { useContext, useEffect } from "react";
import { MdLogout, MdSearch } from "react-icons/md";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import styles from "././Styles/Home.module.css";
import { BASE_URL_API } from "./References/urls";
import AuthContext from "./store/auth-context";

const Home = () => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate()
    authCtx.token === "" && authCtx.logout();

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });
        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                console.log("Login Verified");
            } else {
                if (getCategoryOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    console.log("Something went wrong!!!")
                }
            }
        }
    }

    const options = [
        {
            "header": "Academics",
            "children": [
                {
                    "navigateTo": "academics/visitors",
                    "title": "Visitors"
                },
                {
                    "navigateTo": "academics/enquires",
                    "title": "Enquires"
                },
                {
                    "navigateTo": "academics/enquires/calendar",
                    "title": "Scheduled Visits"
                },
                {
                    "navigateTo": "academics/admissions",
                    "title": "Registrations / Admissions"
                },
            ]
        },
        {
            "header": "Applications",
            "children": [
                {
                    "navigateTo": "manage-applications",
                    "title": "Applications"
                },
                {
                    "navigateTo": "create-applications",
                    "title": "Create Applications"
                },
                {
                    "navigateTo": "application-calendar",
                    "title": "Application Calendar"
                },
            ]
        },
        {
            "header": "Campaigns",
            "children": [
                {
                    "navigateTo": "campaigns",
                    "title": "All Campaigns"
                }
            ]
        },
        {
            "header": "Fast Track",
            "children": [
                {
                    "navigateTo": "ft-all-partners",
                    "title": "All Academic Partners"
                },
                {
                    "navigateTo": "ft-placement-drives",
                    "title": "All Placement Drives"
                },
            ]
        },
        {
            "header": "Invoices",
            "children": [
                {
                    "navigateTo": "all-hiring-invoices",
                    "title": "Partners Invoices"
                },
                {
                    "navigateTo": "students-invoices",
                    "title": "Students Invoices"
                },
            ]
        },
        {
            "header": "Clients",
            "children": [
                {
                    "navigateTo": "assign-clients",
                    "title": "Assign Clients"
                },
                {
                    "navigateTo": "manage-clients-home",
                    "title": "Manage Clients"
                },
                {
                    "navigateTo": "manage-business-accounts",
                    "title": "Manage Business Account"
                },
                {
                    "navigateTo": "create-service-agreement",
                    "title": "Create Service Agreements"
                },
                {
                    "navigateTo": "manage-service-agreements",
                    "title": "Manage Service Agreements"
                }

            ]
        },
        {
            "header": "Courses",
            "children": [
                {
                    "navigateTo": "academics/courses",
                    "title": "Academic Courses"
                },
                {
                    "navigateTo": "manage-course",
                    "title": "Online Courses"
                }
            ]
        },
        {
            "header": "Cases",
            "children": [
                {
                    "navigateTo": "escalated-case/jobs/null",
                    "title": "Escalated Job Cases"
                }
            ]
        },
        {
            "header": "Jobs",
            "children": [
                {
                    "navigateTo": "post-job",
                    "title": "Post Job"
                },
                {
                    "navigateTo": "manage-jobs",
                    "title": "Manage Job"
                },
                {
                    "navigateTo": "jobs-allocator",
                    "title": "Allot Job"
                },
                {
                    "navigateTo": "jobs-tracker",
                    "title": "Jobs Tracker"
                },
            ]
        },
        {
            "header": "Instructors",
            "children": [
                {
                    "navigateTo": "instructors",
                    "title": "Manage Instructors"
                },
                {
                    "navigateTo": "add-instructor",
                    "title": "Add Instructor"
                }
            ]
        },
        {
            "header": "Partners",
            "children": [
                {
                    "navigateTo": "manage-partners-account/search",
                    "title": "Create New Account"
                },
                {
                    "navigateTo": "manage-partners-account",
                    "title": "Manage Partner's Account"
                },
                {
                    "navigateTo": "manage-stores",
                    "title": "Manage Stores"
                },
                {
                    "navigateTo": "salon-leads",
                    "title": "Salons Leads"
                }
            ]
        },
        {
            "header": "Posts",
            "children": [
                {
                    "navigateTo": "manage-posts",
                    "title": "Manage Posts"
                }
            ]
        },
        {
            "header": "Users",
            "children": [
                {
                    "navigateTo": "manage-users",
                    "title": "Manage Users"
                }
            ]
        },
        {
            "header": "Workshops",
            "children": [
                {
                    "navigateTo": "workshops",
                    "title": "Manage Workshops"
                },
                {
                    "navigateTo": "create-workshop",
                    "title": "Create Workshop"
                },
                {
                    "navigateTo": "workshop-registrations",
                    "title": "Workshop Registrations"
                },
                {
                    "navigateTo": "workshop-closure-report",
                    "title": "Update Closure Reports"
                }
            ]
        },
        {
            "header": "Others",
            "children": [
                {
                    "navigateTo": "manage-skillsets",
                    "title": "Manage Skill Sets"
                }
            ]
        },
        {
            "header": "Leads Manager",
            "children": [
                {
                    "navigateTo": "leads-feeder",
                    "title": "Leads Feeder"
                },
                {
                    "navigateTo": "leads-distributor",
                    "title": "Leads Distributor"
                },
                {
                    "navigateTo": "salon-leads-feeder",
                    "title": "Salon Leads Feeder"
                },
                {
                    "navigateTo": "salon-leads-distributor",
                    "title": "Salon Leads Distributor"
                },
                {
                    "navigateTo": "lead-filteration",
                    "title": "Course Leads Filteration"
                },
                {
                    "navigateTo": "course-leads-distributor",
                    "title": "Course Leads Distributor"
                },
            ]
        },
        {
            "header": "Administration",
            "children": [
                {
                    "navigateTo": "admin/manage-demographics",
                    "title": "Manage Demographics"
                },
                {
                    "navigateTo": "admin/manage-locations",
                    "title": "Manage Locations & Areas"
                },
            ]
        },
        {
            "header": "Raw Data",
            "children": [
                {
                    "navigateTo": "data/manage-academies",
                    "title": "Manage Academies Data"
                },
            ]
        },
    ]


    useEffect(() => {
        getCategoryOptions();
    }, [])


    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks-router">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div className={styles.headerNavigationRight}>
                    <Link to="/global-search" className={styles.gsIcon}>
                        <MdSearch size={26} />
                    </Link>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.optionContainer}>
                {options.map((item, index) => (
                    <div className={styles.eachOption} key={index + "BUS"}>
                        <div className={styles.eachOptionHeader}>
                            {item.header}
                        </div>
                        {item.children.map((itemChild, indexChild) => (
                            <Link to={itemChild.navigateTo} style={{ textDecoration: "none" }}
                                key={index + "O" + indexChild}
                            >
                                <div className={styles.optionChild} key={indexChild}>
                                    <div className={styles.optionIcon}>

                                    </div>
                                    <div className={styles.optionTitle}>
                                        {itemChild.title}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Home;