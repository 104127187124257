import React, { useState } from 'react'
import styles from './CommunicationSettings.module.css'

const CommunicationSettings = (props) => {
    const { campaignIdentifier } = props
    const [whatsAppNumbers, setWhatsAppNumbers] = useState([
        {
            phoneNumber: '8287098287',
            username: 'for_stylist'
        },
        {
            phoneNumber: '8383054040',
            username: 'for_salons'
        },
    ])

    const [messageTemplates, setMessageTemplates] = useState([
        {
            templateIdentifier: '1',
            templateContent: `hello from stylelink`
        },
        {
            templateIdentifier: '2',
            templateContent: `hello from stylelink`
        },
        {
            templateIdentifier: '3',
            templateContent: `hello from stylelink`
        },
        {
            templateIdentifier: '4',
            templateContent: `hello from stylelink`
        },
        {
            templateIdentifier: '5',
            templateContent: `hello from stylelink`
        },
    ])

    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>

                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentTitle}>
                                Please select whatsApp number you want to send messages from!
                            </div>
                            <div className={styles.numbersWrapper}>
                                {whatsAppNumbers?.map((item, index) => (
                                    <div className={selectedWhatsApp?.phoneNumber===item?.phoneNumber ? styles.eachNumberWrapperSelected : styles.eachNumberWrapper} 
                                        key={item} 
                                        onClick={() => setSelectedWhatsApp(item)}
                                    >
                                        <div>
                                            {item?.phoneNumber}
                                        </div>
                                        <div>
                                            {item?.username}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachContentWrapper}>
                        <div className={styles.eachContentContainer}>
                            <div className={styles.contentTitle}>
                                Please select the message template you want to send
                            </div>
                            <div className={styles.allTemplates}>
                                {messageTemplates?.map((item, index) => (
                                    <div className={selectedTemplate?.templateIdentifier===item?.templateIdentifier ? styles.eachCardTemplateSelected : styles.eachCardTemplate} key={index}
                                        onClick={() => setSelectedTemplate(item)}
                                    >
                                        All templates
                                        {item?.templateContent}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default CommunicationSettings