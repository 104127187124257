import React, { useContext, useEffect, useState } from 'react'
import { FaTelegram } from 'react-icons/fa';
import { replaceSpecialCharStr } from '../../Helper/helpers';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Partners/PartnersIndividualLeadsDetails.module.css'

const PartnersIndividualLeadsDetails = props => {
    const { storeIdentifier } = props;
    const authCtx = useContext(AuthContext)
    const [storeDetails, setStoreDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [activityData, setActivityData] = useState([])

    const getPartnersLeadsDetails = async () => {
        setIsLoading(true)
        const getPartnersLeadsDetailsResponse = await fetch(BASE_URL_API + "/getPartnersLeadsDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getPartnersLeadsDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPartnersLeadsDetailsRespo = await getPartnersLeadsDetailsResponse.json();
            if (getPartnersLeadsDetailsRespo.status === "success") {
                setStoreDetails(getPartnersLeadsDetailsRespo.response)
            } else {
                if (getPartnersLeadsDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStoreDetails(null)
                    console.log(getPartnersLeadsDetailsRespo)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getPartnersLeadsDetails()
    }, [storeIdentifier])



    if (storeIdentifier === undefined) {
        return (
            <div>
                No store has been selected!
            </div>
        )
    } else {
        if (storeDetails === null) {
            return (
                <div>
                    No details available!
                </div>
            )
        }
        return (
            <div className={styles.mainWrapper}>
                {isLoading ?
                    <div className={styles.header}></div>
                    :
                    <div className={styles.header}>
                        <div>
                            <div>
                                {replaceSpecialCharStr(storeDetails.company_name)}
                            </div>
                            <div>
                                {storeDetails.address}
                            </div>
                        </div>
                        <div>
                            <a href={"tel:" + storeDetails.mobile_primary} className={styles.callBtnPri} >{storeDetails.mobile_primary}</a>
                            {storeDetails.mobile_secondary !== "" && <a href={"tel:" + storeDetails.mobile_secondary} className={styles.callBtn} >{storeDetails.mobile_secondary}</a>}
                            {storeDetails.mobile_tertiary !== "" && <a href={"tel:" + storeDetails.mobile_tertiary} className={styles.callBtn} >{storeDetails.mobile_tertiary}</a>}
                        </div>
                    </div>
                }
                <div className={styles.body}>
                    <div className={styles.statusWrapper}>
                        -
                    </div>
                    <div className={styles.activitySummaryWrapper}>
                        <div className={styles.aswHeader}>
                            Recent actions/activities..
                        </div>
                        <div className={styles.eachInputBox}>
                            {activityData.map((item, index) => (
                                <div className={styles.eachMsg} key={index}>
                                    <div className={styles.eachMsgTxt}>
                                        Last contacted on 30th April'23, but
                                        could not have the conversation, due to network issue!
                                    </div>
                                    <div className={styles.eachMstTime}>
                                        10:00 P.M.
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className={styles.remarksSection}>
                            <textarea placeholder="Add any remarks/comments (visible to internal team only)"></textarea>
                            <button className={styles.submitRemarkBtn}>
                                <FaTelegram size={26} />
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default PartnersIndividualLeadsDetails