import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './MseRepostJobConfirmation.module.css'

const MseRepostJobConfirmation = (props) => {
    const { isOpen, handleRepostingJob, onSuccess, item } = props
    const authCtx = useContext(AuthContext)
    const [isReposting, setIsReposting] = useState(false)

    const repostJob = async () => {
        setIsReposting(true)
        const repostJobResponse = await fetch(BASE_URL_API+"/repostJob",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                jobIdentifier : item?.job_id
            })
        });

        if(!repostJobResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const repostJobRespo = await repostJobResponse.json()
            if(repostJobRespo.status==="success") {
                onSuccess()
                handleRepostingJob(false, null)
            } else {
                if(repostJobRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(repostJobRespo)
        }
        setIsReposting(false)
    }

    useEffect(() => {
        setIsReposting(false)
    }, [item])
    


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.headerWrapper}>
                        <div>
                            <button onClick={() => handleRepostingJob(false, null)}>Close</button>
                        </div>

                        <div>
                            {isReposting === true ?
                                <button>Reposting ...</button>
                                :
                                <button onClick={() => repostJob()}>Re-Post As A New Job</button>
                            }
                        </div>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div>
                            <div className={styles.parametersWrapper} >
                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        {item?.title}
                                    </div>
                                    <div className={styles.category}>
                                        {item?.job_category_name}
                                    </div>
                                </div>
                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Experience Required
                                    </div>
                                    <div className={styles.category}>
                                        {item?.experience}
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Salary Range
                                    </div>
                                    <div className={styles.category}>
                                        <div>
                                            {item?.salary_min}
                                        </div>
                                        <div>
                                            {item?.salary_max}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Who all can apply?
                                    </div>
                                    <div className={styles.category}>
                                        {item?.gender}
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Education Qualifications
                                    </div>
                                    <div className={styles.category}>
                                        {item?.education_qualifications}
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Food & Accomodation
                                    </div>
                                    <div className={styles.category}>
                                        {item?.accomodation}
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Language Spoken
                                    </div>
                                    <div className={styles.category}>
                                        {item?.language_spoken}
                                    </div>
                                </div>

                                <div className={styles.eachParamWrapper}>
                                    <div className={styles.title}>
                                        Age Range
                                    </div>
                                    <div className={styles.category}>
                                        <div>
                                            {item?.age_min}
                                        </div>
                                        <div>
                                            {item?.age_max}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div>

                                <div className={styles.skillsWrapper}>
                                    {item?.skill?.split(",").map((itemSkill, indexSkill) => (
                                        <span key={indexSkill} className={styles.eachSkill} >{itemSkill}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item?.description }} className={styles.description}></div>

                    </div>
                </div>
            </div>
        )
    }
}


export default MseRepostJobConfirmation