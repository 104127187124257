import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbLanguagesSpoken.module.css'

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import RbLanguagesSpokenAddNew from './RbLanguagesSpokenAddNew';

const RbLanguagesSpoken = props => {
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    const [addedData, setAddedData] = useState(null)
    const authCtx = useContext(AuthContext)
    const [fetchingAddedData, setFetchingAddedData] = useState(true)


    const getAddedData = async () => {
        setFetchingAddedData(true)
        const getAddedDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'language'
                })
            });

        if (!getAddedDataResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getAddedDataRespo = await getAddedDataResponse.json();
            if (getAddedDataRespo.status === 'success') {
                setAddedData(getAddedDataRespo.response);
            } else {
                if (getAddedDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getAddedDataRespo);
                }
            }
            console.log(getAddedDataRespo);
        }
        setFetchingAddedData(false)
    }

    useEffect(() => {
        getAddedData()
    }, [selectedUser])

 

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType}
                </div>
                <div className={styles.bodyWrapper}>
                    {fetchingAddedData === true ?
                        <div>
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                            <ShimmerThumbnail height={100} className={styles.containerLoader} />
                        </div>
                        :
                        <div>
                            {addedData !== null ?
                                <div>

                                    {addedData.map((item, index) => (
                                        <div key={index} className={styles.eachLanguageCard}>
                                            <div className={styles.leftContainer}>
                                                <img src={item.language_icon} className={styles.langIcon} />
                                            </div>
                                            <div className={styles.rightContainer}>
                                                <div className={styles.languageTitle}>{item.language_id}</div>
                                                <div className={styles.levelOfUnderstanding}>{item.understanding_level}</div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                :
                                <div>
                                    No records found!
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={styles.rightWrapper}>
                <RbLanguagesSpokenAddNew 
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                    getAddedData={getAddedData}
                />
            </div>
        </div>
    )
}

export default RbLanguagesSpoken