import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import QPExperienceRequired from './QPExperienceRequired'
import QPFnA from './QPFnA'
import QPJobTitle from './QPJobTitle'
import QPPreview from './QPPreview'
import QPQualificationRequired from './QPQualificationRequired'
import QPSalaryRange from './QPSalaryRange'
import QPSelectProfile from './QPSelectProfile'
import QPSkills from './QPSkills'
import QPSlider from './QPSlider'
import QPWhoCanApply from './QPWhoCanApply'
import styles from './QuickPost.module.css'

const QuickPost = (props) => {
    const { isOpen, quickPostHandler, storeIdentifier, onSuccess  } = props
    const authCtx = useContext(AuthContext)
    const [currentSlide, setCurrentSlide] = useState('Profile')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categoryOptionsData, setCategoryOptionsData] = useState([])
    
    const [experienceRequired, setExperienceRequired] = useState(null)
    const [startingSalary, setStartingSalary] = useState('')
    const [maximumSalary, setMaximumSalary] = useState('')
    const [whoCanApply, setWhoCanApply] = useState('')
    const [requiredQualifications, setRequiredQualifications] = useState('')
    const [fna, setFna] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [skills, setSkills] = useState([])
    const [skillOptions, setSkillOptions] = useState(null)


    const selectCategoryHandler = (k) => {
        setSelectedCategory(k)
        if(jobTitle==='') {
            setJobTitle(k?.category_name)
        }
        setCurrentSlide('Experience')
    }
    const selectExperienceHandler = (k) => {
        setExperienceRequired(k)
        setCurrentSlide('Salary')
    }

    const handleStartingSalary = (k) => {
        setStartingSalary(k)
    }

    const handleMaximumSalary = (k) => {
        setMaximumSalary(k)
    }


    const whoCanApplyHandler = (k) => {
        setWhoCanApply(k)
        setCurrentSlide('Qualification')
    }


    const requiredQualificationsHandler = (k) => {
        setRequiredQualifications(k)
        setCurrentSlide('FnA')
    }


    const fnaHandler = (k) => {
        setFna(k)
        setCurrentSlide('JobTitle')
    }

    const jobTitleHandler = (k) => {
        setJobTitle(k)
    }

    // const skillsHandler = (k) => {
    //     if(skills?.includes(k)===true) {
    //         setSkills(skills?.filter((item) => item !== k))
    //     } else {
    //         setSkills([...skills, k])
    //     }
    // }

    const skillsHandler = async (k) => {
        if (skills?.find((item) => item?.ID === k?.ID)) {
            setSkills(skills.filter((item) => item?.ID !== k?.ID))
        } else {
            setSkills([...skills, k])
        }
    }
    const bulkSkillSelectorHandler = (k) => setSkills(k)


    const currentSlideHandler = (k) => setCurrentSlide(k)


    /// let's bring all the skills here in the beginning... 
    const getSkillsOptions = async () => {
        const getSkillsOptionsResponse = await fetch(BASE_URL_API + "/getSkillsOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                })
            });

        if (!getSkillsOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getSkillsOptionsRespo = await getSkillsOptionsResponse.json()
            if (getSkillsOptionsRespo.status === "success") {
                let tempSkillOptions = getSkillsOptionsRespo.response
                let copiedArr = tempSkillOptions;
                let copiedArrMale = tempSkillOptions?.forMale || [];
                let copiedArrFemale = tempSkillOptions?.forFemale || [];
                let copiedArrUnsorted = tempSkillOptions?.unsorted || [];
                let allSkillsArr = copiedArrMale.concat(copiedArrFemale, copiedArrUnsorted);

                setSkillOptions(allSkillsArr)
            } else {
                if (getSkillsOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSkillOptions({})
                }
            }
            console.log(getSkillsOptionsRespo)
        }
    }


    useEffect(() => {
        getSkillsOptions()
    }, [])


  
    const bulkSelector = async () => {
        console.log("automatic selector hitting.. ")
        let p = skillOptions?.filter((item) => item?.categoryId===selectedCategory?.ID)
        console.log("let's print p here : ", p)
        bulkSkillSelectorHandler(p)
        console.log("done automatic selector...") // abhi karte hai...
    }

    useEffect(() => {
        bulkSelector()
    }, [selectedCategory])

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong!");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status == "success") {
                setCategoryOptionsData(getCategoryOptionsRespo.response)
            } else {
                setCategoryOptionsData([])
            }
            console.log(getCategoryOptionsRespo)
        }
    }

    useEffect(() => {
        getCategoryOptions()
    }, [])

    if(!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <button onClick={() => quickPostHandler()}>Close</button>
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.progressWrapper}>
                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Profile')} >
                            <div className={styles.eachCategoryCaption}>
                                Profile
                            </div>
                            <div className={styles.eachCategoryValue}>
                                {selectedCategory?.category_name}
                            </div>
                        </button>

                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Experience')} >
                            <div className={styles.eachCategoryCaption}>
                                Experience
                            </div>
                            <div className={styles.eachCategoryValue}>
                                {experienceRequired}
                            </div>
                        </button>


                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Salary')} >
                            <div className={styles.eachCategoryCaption}>
                                Salary Range
                            </div>
                            <div className={styles.eachCategoryValue}>
                                {startingSalary} - {maximumSalary}
                            </div>
                        </button>

                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('WhoCanApply')} >
                            <div className={styles.eachCategoryCaption}>Who all can apply?</div>
                            <div className={styles.eachCategoryValue}>
                                {whoCanApply}
                            </div>
                        </button>


                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Qualification')} >
                            <div className={styles.eachCategoryCaption}>Education Qualifications</div>
                            <div className={styles.eachCategoryValue}>
                                {requiredQualifications}
                            </div>
                        </button>

                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('FnA')} >
                            <div className={styles.eachCategoryCaption}>Food & Accomodation</div>
                            <div className={styles.eachCategoryValue}>
                                {fna}
                            </div>
                        </button>
                        
                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('JobTitle')} >
                            <div className={styles.eachCategoryCaption}>Job Title</div>
                            <div className={styles.eachCategoryValue}>
                                {jobTitle}
                            </div>
                        </button>
                        
                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Skills')} >
                            <div className={styles.eachCategoryCaption}>Skills</div>
                            <div className={styles.eachCategoryValue}>
                                {skills?.length} skills selected
                            </div>
                        </button>

                        <button className={styles.eachCategoryWrapper} onClick={() => setCurrentSlide('Preview')} >
                            <div className={styles.eachCategoryCaption}>Preview</div>
                            <div className={styles.eachCategoryValue}>
                                {/* {fna} */}
                            </div>
                        </button>


                    </div>
                    {/* <QPSlider slides={slides} /> */}

                    <div className={styles.contentWrapper}>
                        {currentSlide === 'Profile' && <QPSelectProfile selectedCategory={selectedCategory} selectCategoryHandler={selectCategoryHandler} categoryOptionsData={categoryOptionsData} />}
                        {currentSlide === 'Experience' && <QPExperienceRequired experienceRequired={experienceRequired} selectExperienceHandler={selectExperienceHandler} />}
                        {currentSlide === 'Salary' && <QPSalaryRange startingSalary={startingSalary} maximumSalary={maximumSalary} handleStartingSalary={handleStartingSalary} handleMaximumSalary={handleMaximumSalary} currentSlideHandler={currentSlideHandler} />}
                        {currentSlide === 'WhoCanApply' && <QPWhoCanApply whoCanApply={whoCanApply} whoCanApplyHandler={whoCanApplyHandler} />}
                        {currentSlide === 'Qualification' && <QPQualificationRequired requiredQualifications={requiredQualifications} requiredQualificationsHandler={requiredQualificationsHandler} />}
                        {currentSlide === 'FnA' && <QPFnA fna={fna} fnaHandler={fnaHandler} />}
                        {currentSlide === 'JobTitle' && <QPJobTitle jobTitle={jobTitle} jobTitleHandler={jobTitleHandler} currentSlideHandler={currentSlideHandler}  />}
                        {currentSlide === 'Skills' && <QPSkills selectedCategory={selectedCategory} skillOptions={skillOptions} skills={skills} skillsHandler={skillsHandler} bulkSkillSelectorHandler={bulkSkillSelectorHandler} currentSlideHandler={currentSlideHandler}  />}
                        {currentSlide === 'Preview' && <QPPreview
                            storeIdentifier={storeIdentifier}
                            selectedCategory={selectedCategory}
                            experienceRequired={experienceRequired}
                            startingSalary={startingSalary}
                            maximumSalary={maximumSalary}
                            whoCanApply={whoCanApply}
                            requiredQualifications={requiredQualifications}
                            fna={fna}
                            jobTitle={jobTitle}
                            skills={skills}
                            quickPostHandler={quickPostHandler}
                            onSuccess={onSuccess}
                        />}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default QuickPost