import React, { useContext, useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from './EnquiryInformation.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EnquiryInformation = props => {
    const { visitorToken } = props
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('PD'); // ED, FD

    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [fullAddress, setFullAddress] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)

    const getPdSummary = async () => {
        const getPdSummaryResponse = await fetch(BASE_URL_API+"/acad-getVisitorPersonalDetails",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                visitorToken : visitorToken
            })
        });

        if(!getPdSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPdSummaryRespo = await getPdSummaryResponse.json()
            if(getPdSummaryRespo.status==="success") {
                setFullName(getPdSummaryRespo.response?.fullName)
                setPhone(getPdSummaryRespo.response?.phone)
                setGender(getPdSummaryRespo.response?.gender)
                setDateOfBirth(getPdSummaryRespo.response?.dateOfBirth)
                setMaritalStatus(getPdSummaryRespo.response?.maritalStatus)
                setFullAddress(getPdSummaryRespo.response?.fullAddress)
            } else {
                if(getPdSummaryRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getPdSummaryRespo)
        }
    }

    useEffect(() => {
        getPdSummary()
    }, [visitorToken])
    
    const updatePdSummary = async () => {
        setIsUpdating(true)
        const updatePdSummaryResponse = await fetch(BASE_URL_API+"/acad-updateVisitorPersonalDetails",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                visitorToken : visitorToken,
                fullName : fullName,
                phone : phone,
                gender : gender,
                dateOfBirth : dateOfBirth,
                maritalStatus : maritalStatus,
                fullAddress : fullAddress
            })
        });

        if(!updatePdSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updatePdSummaryRespo = await updatePdSummaryResponse.json();
            if(updatePdSummaryRespo.status==="success") {
                toast("Details successfully updated!");
            } else {
                if(updatePdSummaryRespo.message ==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    
                }
            }
            console.log(updatePdSummaryRespo)
        }
        setIsUpdating(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.personalDetailsWrapper}>
                    {/* <div className={styles.dpWrapper}>
                        <FaUserCircle size={120} />
                    </div> */}
                    <ToastContainer />
                    <div className={styles.fieldsWrapper}>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Full Name</div>
                            <div className={styles.fieldInput}>
                                <input placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Phone Number</div>
                            <div className={styles.fieldInput}>
                                <input placeholder="10 digits phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Full Name</div>
                            <div className={styles.fieldInput}>
                                <button className={gender==="FEMALE" ? styles.activeBtn : styles.defBtn} onClick={() => setGender("FEMALE")} >Female</button>
                                <button className={gender==="MALE" ? styles.activeBtn : styles.defBtn} onClick={() => setGender("MALE")} >Male</button>
                                <button className={gender==="LGBTQ" ? styles.activeBtn : styles.defBtn} onClick={() => setGender("LGBTQ")} >LGBTQ+</button>
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Date of Birth</div>
                            <div className={styles.fieldInput}>
                                <input type="date" value={dateOfBirth.substring(0, 10)} onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Marital Status</div>
                            <div className={styles.fieldInput}>
                                <button className={maritalStatus==="MARRIED" ? styles.activeBtn : styles.defBtn} onClick={() => setMaritalStatus("MARRIED")} >Married</button>
                                <button className={maritalStatus==="UNMARRIED" ? styles.activeBtn : styles.defBtn} onClick={() => setMaritalStatus("UNMARRIED")} >Un-Married</button>
                                <button className={maritalStatus==="DIVORCED" ? styles.activeBtn : styles.defBtn} onClick={() => setMaritalStatus("DIVORCED")} >Divorced</button>
                                <button className={maritalStatus==="OTHERS" ? styles.activeBtn : styles.defBtn} onClick={() => setMaritalStatus("OTHERS")} >Others</button>
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <div className={styles.fieldCaption}>Full Address</div>
                            <div className={styles.fieldInput}>
                                <textarea value={fullAddress} onChange={(e) => setFullAddress(e.target.value)}></textarea>
                            </div>
                        </div>

                    </div>
                    <div className={styles.updateBtn}>
                        {isUpdating===false ? <button onClick={() => updatePdSummary()}>Update</button> : <button>Updating...</button>}
                    </div>
                </div>
                {/* <div className={styles.educationDetailsWrapper}>
                    IIT Delhi
                </div>
                <div className={styles.familyDetailsWrapper}>
                    6 members in Family
                </div> */}

            </div>
        </div>
    )
}

export default EnquiryInformation