import React, { useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Authorization/Login";
import UpdateClosureReport from "../Components/Workshops/UpdateClosureReport";
import AcademyEachEnquiry from "../Content/Academics/AcademyEachEnquiry";
import AcademyEnquiries from "../Content/Academics/AcademyEnquiries";
import AcademyNewVisitor from "../Content/Academics/AcademyNewVisitor";
import AcademyVisitors from "../Content/Academics/AcademyVisitors";
import AcademyVisitorsCalendar from "../Content/Academics/AcademyVisitorsCalendar";
import Admissions from "../Content/Academics/Admissions";
import AddInstructor from "../Content/AddInstructor";
import AdminEachPersonnel from "../Content/Admin/AdminEachPersonnel";
import AdminHome from "../Content/Admin/AdminHome";
import AdminPages from "../Content/Admin/AdminPages";
import AdminPersonnel from "../Content/Admin/AdminPersonnel";
import ManageDemographics from "../Content/Admin/ManageDemographics";
import ManageLocations from "../Content/Admin/ManageLocations";
import AnalyticsSummary from "../Content/Analytics/AnalyticsSummary";
import AnalyticsUser from "../Content/Analytics/AnalyticsUser";
import KPIs from "../Content/Analytics/KPIs";
import LeadsAnalytics from "../Content/Analytics/LeadsAnalytics";
import AppCalendarHolder from "../Content/ApplicationsCalendar/AppCalendarHolder";
import CampaignsCreate from "../Content/Campaigns/CampaignsCreate";
import CampaignsHome from "../Content/Campaigns/CampaignsHome";
import ContractGenerator from "../Content/Contracts/ContractGenerator";
import CreateANewContract from "../Content/Contracts/CreateANewContract";
import ViewAllContracts from "../Content/Contracts/ViewAllContracts";
import AllCourses from "../Content/Course/Academics/AllCourses";
import CreateAcademicCourse from "../Content/Course/Academics/CreateAcademicCourse";
import AddNewCourse from "../Content/Course/AddNewCourse";
import ManageCourse from "../Content/Course/ManageCourse";
import CreateWorkshop from "../Content/CreateWorkshop";
import EscalatedCaseJobs from "../Content/EscalatedCase/EscalatedCaseJobs";
import FTAllPartners from "../Content/FastTrack/FTAllPartners";
import FTAllPlacementDrives from "../Content/FastTrack/FTAllPlacementDrives";
import FTEachPartner from "../Content/FastTrack/FTEachPartner";
import FTEachPlacementDrive from "../Content/FastTrack/FTEachPlacementDrive";
import GSHome from "../Content/GlobalSearch/GSHome";
import GSResults from "../Content/GlobalSearch/GSResults";
import AllPartnersInvoices from "../Content/Invoices/AllPartnersInvoices";
import EachHiringInvoice from "../Content/Invoices/EachHiringInvoice";
import AllocatedJobs from "../Content/JobAllocator/AllocatedJobs";
import JobsAllocator from "../Content/JobAllocator/JobsAllocator";
import CreateApplicationForEachJob from "../Content/JobApplications/CreateApplicationForEachJob";
import CreateApplications from "../Content/JobApplications/CreateApplications";
import ManageApplications from "../Content/JobApplications/ManageApplications";
import ManageIndividualApplications from "../Content/JobApplications/ManageIndividualApplications";
import JobsTracker from "../Content/Jobs/JobsTracker";
import ManageEachJob from "../Content/Jobs/ManageEachJob";
import JobsFinder from "../Content/JobsFinder/JobsFinder";
import LfHome from "../Content/LeadFilteration/LfHome";
import AssignClients from "../Content/ManageClients/AssignClients";
import ManageAllBusinessAccount from "../Content/ManageClients/ManageAllBusinessAccount";
import ManageClientsHome from "../Content/ManageClients/ManageClientsHome";
import ManageEachClient from "../Content/ManageClients/ManageEachClient";
import ManageInstructors from "../Content/ManageInstructors";
import ManageJobs from "../Content/ManageJobs";
import CourseLeadsDistributor from "../Content/ManageLeads/CourseLeadsDistributor";
import LeadsDistributor from "../Content/ManageLeads/LeadsDistributor";
import LeadsFeeder from "../Content/ManageLeads/LeadsFeeder";
import SalonLeadsDistributor from "../Content/ManageLeads/SalonLeadsDistributor";
import SalonLeadsFeeder from "../Content/ManageLeads/SalonLeadsFeeder";
import ManageSkillSets from "../Content/ManageSkillSets";
import ResumeSubscriptionsHome from "../Content/ManageSubscriptions/ResumeSubscriptions/ResumeSubscriptionsHome";
import ManageUsersGrow from "../Content/ManageUsers/ManageUsersGrow";
import ManageUsersHome from "../Content/ManageUsers/ManageUsersHome";
import UserProfileGrow from "../Content/ManageUsers/UserProfileGrow";
import ManageWorkshopRegistrations from "../Content/ManageWorkshopRegistrations";
import ManageWorkshops from "../Content/ManageWorkshops";
import CreateNewAccount from "../Content/Partners/CreateNewAccount";
import ManagePartnersAccount from "../Content/Partners/ManagePartnersAccount";
import ManagePartnersAccountEach from "../Content/Partners/ManagePartnersAccountEach";
import ManageStoreEach from "../Content/Partners/ManageStoreEach";
import ManageStores from "../Content/Partners/ManageStores";
import PartnersIndividualLeads from "../Content/Partners/PartnersIndividualLeads";
import PartnersLeads from "../Content/Partners/PartnersLeads";
import SearchPartnersAccount from "../Content/Partners/SearchPartnersAccount";
import ExecutiveSummary from "../Content/PerformanceSummary/ExecutiveSummary";
import PostJobs from "../Content/PostJobs";
import RDMAcadHome from "../Content/RawData/ManageAcademies/RDMAcadHome";
import IndividualLead from "../Content/Tasks/IndividualLead";
import SearchIncomingLead from "../Content/Tasks/SearchIncomingLead";
import TasksHome from "../Content/Tasks/TasksHome";
import TasksRouter from "../Content/Tasks/TasksRouter";
import CourseLeadsConverter from "../Content/TasksCourse/CourseLeadsConverter";
import SalonLeadsConverter from "../Content/TasksSalon/SalonLeadsConverter";
import THome from "../Content/Trainings/THome";
import Home from "../Home";
import AuthContext from "../store/auth-context";
import Users from "../Users/Users";

export const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    let isLoggedIn = authCtx.isLoggedIn;

    console.log(authCtx)

    if(!isLoggedIn) {
        return(
            <Routes>
                <Route path="*" element={<Login />}></Route>
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
            </Routes>
        )
    } else if(isLoggedIn) {
        return(
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/manage-jobs" element={<ManageJobs/>}></Route>
                <Route path="/manage-applications" element={<ManageApplications />}></Route>
                {/* added on 25th Jan 2024 */}
                <Route path="/manage-application/:applicationIdentifier" element={<ManageIndividualApplications />}></Route>

                <Route path="/post-job" element={<PostJobs />}></Route>
                <Route path="/create-workshop" element={<CreateWorkshop />}></Route>
                <Route path="/add-instructor" element={<AddInstructor />}></Route>
                <Route path="/instructors" element={<ManageInstructors />}></Route>
                {/* <Route path="/manage-users" element={<Users />}></Route> */}
                <Route path="/manage-users" element={<ManageUsersHome />}></Route>
                <Route path="/workshops" element={<ManageWorkshops />}></Route>
                <Route path="/manage-course" element={<ManageCourse />}></Route>
                <Route path="/add-course" element={<AddNewCourse />}></Route>
                <Route path="/manage-skillsets" element={<ManageSkillSets />}></Route>
                <Route path="/workshop-registrations" element={<ManageWorkshopRegistrations />}></Route>
                <Route path="/workshop-closure-report" element={<UpdateClosureReport />}></Route>
                
                <Route path="/manage-partners-account" element={<ManagePartnersAccount />}></Route>
                <Route path="/manage-partners-account/:vendorIdentifier" element={<ManagePartnersAccountEach />}></Route>
                <Route path="/manage-partners-account/search" element={<SearchPartnersAccount />}></Route>
                <Route path="/manage-partners-account/create-new-account" element={<CreateNewAccount />}></Route>

                {/* commenting this as on 16th feb 2024, to introduce better version of this */}
                {/* <Route path="/manage-partners-account/:vendorIdentifier" element={<CreateNewAccount />}></Route>  */}



                <Route path="/manage-stores" element={<ManageStores />}></Route>
                <Route path="/manage-stores/:storeIdentifier" element={<ManageStoreEach />}></Route>
                <Route path="/salon-leads" element={<PartnersLeads />}></Route>
                <Route path="/salon-leads/:storeIdentifier" element={<PartnersIndividualLeads />}></Route>


                <Route path="/analytics/summary" element={<AnalyticsSummary />}></Route>

                {/* added on 6th May 2023 */}
                <Route path="/analytics/user" element={<AnalyticsUser />}></Route>  
                <Route path="/kpis" element={<KPIs />}></Route>


                <Route path="/escalated-case/jobs/:caseIdentifier" element={<EscalatedCaseJobs />}></Route>


                <Route path="/admin/manage-demographics" element={<ManageDemographics />}></Route>

                <Route path="/manage-users/grow" element={<ManageUsersGrow />}></Route>
                <Route path="/manage-users/grow/:userId" element={<UserProfileGrow />}></Route>


                <Route path="/data/home" element={<RDMAcadHome />}></Route>
                <Route path="/data/manage-academies" element={<RDMAcadHome />}></Route>

                <Route path="/create-applications" element={<CreateApplications />}></Route>
                <Route path="/application-calendar" element={<AppCalendarHolder />}></Route>


                <Route path="/jobs-finder" element={<JobsFinder />}></Route>
                <Route path="/jobs-finder/:stylistIdentifier" element={<JobsFinder />}></Route>





                <Route path="/control/home" element={<AdminHome />}></Route>
                <Route path="/control/pages" element={<AdminPages />}></Route>
                <Route path="/control/personnel" element={<AdminPersonnel />}></Route>
                <Route path="/control/personnel/:personnelIdentifier" element={<AdminEachPersonnel />}></Route>


                <Route path="/leads-feeder" element={<LeadsFeeder />}></Route>
                <Route path="/leads-distributor" element={<LeadsDistributor />}></Route>
                <Route path="/salon-leads-feeder" element={<SalonLeadsFeeder />}></Route>
                <Route path="/salon-leads-distributor" element={<SalonLeadsDistributor />}></Route>
                <Route path="/salon-leads-converter" element={<SalonLeadsConverter />}></Route>
                <Route path="/salon-leads-converter/:leadIdentifier" element={<SalonLeadsConverter />}></Route>

                {/* added this on 16th March 2024  */}
                <Route path="/jobs-tracker" element={<JobsTracker />} ></Route>

                <Route path="/performance-summary" element={<ExecutiveSummary />}></Route>
                
                {/* added on 9th october 2023 while course lead conversion introduced in tasks */}
                <Route path="/tasks-router" element={<TasksRouter />}></Route>
                {/* --------- */}
                <Route path="/tasks" element={<TasksHome />}></Route>
                <Route path="/tasks/lead/search" element={<SearchIncomingLead />}></Route>
                <Route path="/tasks/lead/:leadIdentifier" element={<IndividualLead />}></Route>
                
                
                <Route path="/training" element={<THome />}></Route>


                <Route path="/lead-filteration" element={<LfHome />}></Route>
                <Route path="/course-leads-distributor" element={<CourseLeadsDistributor />}></Route>
                <Route path="/course-leads-converter" element={<CourseLeadsConverter />}></Route>
                <Route path="/course-leads-converter/:leadIdentifier" element={<CourseLeadsConverter />}></Route>


                {/* added on 8th of November 2023  */}
                <Route path="admin/manage-locations" element={<ManageLocations />}></Route>


                {/* added on 29th of November 2023 */}
                <Route path="academics/visitors" element={<AcademyVisitors />} ></Route>
                <Route path="academics/visitors/new" element={<AcademyNewVisitor />} ></Route>

                {/* added on 5th of December 2023 */}
                <Route path="academics/admissions" element={<Admissions />} ></Route>
                
                {/* added on 6th of December 2023 */}
                <Route path="academics/enquires" element={<AcademyEnquiries />} ></Route>
                
                {/* added on 10th of December 2023 */}
                <Route path="academics/enquires/:visitorToken" element={<AcademyEachEnquiry />} ></Route>
                
                {/* added on 16th of December 2023 */}
                <Route path="academics/courses" element={<AllCourses />} ></Route>
                <Route path="academics/courses/create/:courseToken" element={<CreateAcademicCourse />} ></Route>
                <Route path="academics/enquires/calendar" element={<AcademyVisitorsCalendar />} ></Route>

                {/* added on 21st of December 2023 */}
                <Route path="jobs-allocator" element={<JobsAllocator />} ></Route>
                
                {/* added on 26th of December 2023 */}
                <Route path="create-applications/:jobIdentifier" element={<CreateApplicationForEachJob />} ></Route>
                <Route path="jobs-tasks" element={<AllocatedJobs />} ></Route>

                {/* introducing the super power of global search on 10th Feb 2024 */}
                <Route path="global-search" element={<GSHome />} ></Route>
                <Route path="global-search/:key" element={<GSResults />} ></Route>



                {/* introducing this on 29th March 2024 as we are on the verge of monetization */}
                <Route path="assign-clients" element={<AssignClients />} ></Route>
                <Route path="manage-clients-home" element={<ManageClientsHome />} ></Route>
                
                {/* introducing this on 17th August 2024  to finally share business account with partners */}
                <Route path="manage-client/:vendorIdentifier" element={<ManageEachClient />} ></Route>
                <Route path="manage-business-accounts" element={<ManageAllBusinessAccount />} ></Route>


                {/* introducing the analystics towards the leads of system today on 31st March 2024 */}
                <Route path="analytics-leads" element={<LeadsAnalytics />} ></Route>


                
                {/* introducing campaigns... super power is introduced today on 15th May 2024 */}
                <Route path="campaigns" element={<CampaignsHome />} ></Route>
                <Route path="campaign/:campaignIdentifier" element={<CampaignsCreate />} ></Route>

                {/* introducing resume subscription today on 17th May 2024, first step towards monetization.. */}
                <Route path="manage-subscriptions/resume/:stylistIdentifier" element={<ResumeSubscriptionsHome />} ></Route>


                {/* introducing the service contract feature today on 5th July 2024, as part of our monetization strategy... */}
                <Route path="create-service-agreement" element={<CreateANewContract />} ></Route>
                <Route path="manage-service-agreements" element={<ViewAllContracts />} ></Route>
                <Route path="manage-service-agreement/:agreementIdentifier" element={<ContractGenerator />} ></Route>


                {/* introducing this for the fasttrack services to manage it backend on 05th August 2024 */}
                <Route path="ft-placement-drives" element={<FTAllPlacementDrives />} ></Route>
                <Route path="ft-placement-drive/:driveIdentifier" element={<FTEachPlacementDrive />} ></Route>
                <Route path="ft-all-partners" element={<FTAllPartners />} ></Route>
                <Route path="ft-partner/:partnersIdentifier" element={<FTEachPartner />} ></Route>

                {/* introducing it today on 26th August 2024 to manage individual job  */}
                <Route path="manage-job/:jobIdentifier" element={<ManageEachJob />} ></Route>
                
                {/* introducing it today on 09th Septmeber 2024 to manage invoices automatically  */}
                <Route path="all-hiring-invoices" element={<AllPartnersInvoices />} ></Route>
                <Route path="hiring-invoice/:invoiceIdentifier" element={<EachHiringInvoice />} ></Route>

            </Routes>
        )
    } else {
        return(
            <Routes>
                <Route path="*" element={<Login />}></Route>
            </Routes>
        )
    }
}

