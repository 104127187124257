import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/CoursePricing.module.css'

const CoursePricing = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");

    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const [orgPrice, setOrgPrice] = useState(2);
    const [disPrice, setDisPrice] = useState(1);

    const orgPriceHandler = (val) => {
        if (val > -1) {
            if(val<400000) {
                setOrgPrice(val);
            } else {
                alert("Buddy, I appreciate confidence, but you can't really sell this course at "+val);                
            }
        } else {
            alert("Original Price should be positive!");
        }
    }

    const disPriceHandler = (val) => {
        if (val > -1) {
            setDisPrice(val)
        } else {
            alert("Discounted price cannot be negative");
        }
    }

    const updateCoursePrice = async () => {
        setIsLoading(true)
        const updateCoursePriceResponse = await fetch(BASE_URL_API + "/updateCoursePrice",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                courseId : courseId,
                orgPrice : orgPrice,
                disPrice : disPrice,
                currency : 'INR'
            })
        });

        if(!updateCoursePriceResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const updateCoursePriceRespo = await updateCoursePriceResponse.json();
            if(updateCoursePriceRespo.status=="success") {
                getCoursePrice();
            } else {

            }
            console.log(updateCoursePriceRespo);
        }
        setIsLoading(false)
    }

    const getCoursePrice = async () => {
        setIsLoading(true)
        const getCoursePriceResponse = await fetch(BASE_URL_API+"/getCoursePrice",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                courseId : courseId
            })
        });

        if(!getCoursePriceResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const getCoursePriceRespo = await getCoursePriceResponse.json();
            if(getCoursePriceRespo.status=="success") {
                setOrgPrice(getCoursePriceRespo.response.orgPrice);
                setDisPrice(getCoursePriceRespo.response.disPrice);
            } else {
                if(getCoursePriceRespo.response=="add price for the first time!") {
                    setOrgPrice('');
                    setDisPrice('');
                } else {
                    console.log(getCoursePriceRespo.message);
                }
            }
            console.log(getCoursePriceRespo);
        } 
        setIsLoading(false)
    }

    useEffect(() => {
        getCoursePrice();
    }, [courseId])
    

    const updatePriceHandler = () => {
        console.log(orgPrice, disPrice);
        if (parseFloat(orgPrice) > parseFloat(disPrice)) {
            if((((orgPrice - disPrice)/orgPrice)*100).toFixed(2)<90) {
                updateCoursePrice();
            } else {
                alert("I understand the right to education is brilliant, but are you sure you can offer more than 90% discount??")
            }
        } else {
            alert("Discounted price cannot be greater than org price!")
        }
    }


    return (
        <section className={styles.mainContainer}>
            <div className={styles.sectionMainTitle}>
                <h3>Course Pricing</h3>
            </div>
            <div className={styles.sectionContent}>
                {courseId}
                <div className={styles.eachField}>
                    <div className={styles.eachFieldTitle}>
                        <span>Enter Original Price</span>
                    </div>
                    <div className={styles.eachFieldInput}>
                        <input
                            placeholder="Please enter the course price..."
                            onChange={(e) => orgPriceHandler(e.target.value)}
                            maxLength={5}
                            type="number"
                            value={orgPrice}
                        />
                    </div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.eachFieldTitle}>
                        <span>Enter Discounted Price</span>
                    </div>
                    <div className={styles.eachFieldInput}>
                        <input
                            placeholder="Please enter the course discounted price..."
                            onChange={(e) => disPriceHandler(e.target.value)}
                            maxLength={5}
                            type="number"
                            value={disPrice}
                        />
                    </div>
                </div>
                {orgPrice!="" && disPrice!="" &&
                <div className={styles.eachFieldDiscount}>
                    You are offering {(((orgPrice - disPrice)/orgPrice)*100).toFixed(2)}% discount!
                </div>}
                <div className={styles.submitBtnContainer}>
                    <button onClick={() => updatePriceHandler()}>Submit</button>
                </div>
            </div>
        </section>
    )
}

export default CoursePricing