import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CreateCampaignPopUp from '../../Components/Campaigns/CreateCampaignPopUp'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CampaignsHome.module.css'

const CampaignsHome = () => {
    const authCtx = useContext(AuthContext)
    const [allCampaigns, setAllCampaigns] = useState([1, 2, 4, 5])
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const closePopUp = () => setIsPopUpOpen(false)

    const getAllCampaign = async () => {
        const getAllCampaignResponse = await fetch(BASE_URL_API + "/getAllCampaign",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!getAllCampaignResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllCampaignRespo = await getAllCampaignResponse.json()
            if (getAllCampaignRespo.status === "success") {
                setAllCampaigns(getAllCampaignRespo.response)
            } else {
                if (getAllCampaignRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllCampaigns([])
                }
            }
            console.log(getAllCampaignRespo)
        }
    }

    useEffect(() => {
        getAllCampaign()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <CreateCampaignPopUp isOpen={isPopUpOpen} closePopUp={closePopUp} />
            <div>

            </div>
            <div>
                <div className={styles.mainContainer}>
                    <div className={styles.leftColWrapper}>
                        <div className={styles.createNewCampaign}>
                            <button onClick={() => setIsPopUpOpen(true)}>Create New Campaign</button>
                        </div>
                        <div className={styles.campaignsList}>
                            {allCampaigns?.map((item, index) => (
                                <Link to={`/campaign/${item?.campaignIdentifier}`} key={index} >
                                    <div className={styles.eachCampaignWrapper}>
                                        <div className={styles.campaignIdentifier}>
                                            {item?.campaignIdentifier}
                                        </div>
                                        <div className={styles.campaignName}>
                                            {item?.campaignName}
                                        </div>
                                        <div className={styles.campaignDescription}>
                                            {item?.campaignDescription}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div>
                        Right Column
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignsHome