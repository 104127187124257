import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context';
import styles from '../ManageAcademies/RDMAcademiesAddNew.module.css'

const RDMAcademiesAddNew = props => {
    const authCtx = useContext(AuthContext);
    const [isValid, setIsValid] = useState(true)
    const [instiName, setInstiName] = useState('')
    const [instiAddress, setInstiAddress] = useState('');
    const [website, setWebsite] = useState('');
    const [logo, setLogo] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [isUploadingStatus, setIsUploadingStatus] = useState('NOT_UPLOADING');
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')

    const validator = k => {
        if (instiName.length > 2) {
            if (instiAddress.length > 2) {
                if (website.length > 6) {
                    if (logo.length > 5) {
                        if (city.length > 2) {
                            if (pincode.length > 4) {
                                if(country!=='Select' && country!=='') {
                                    if(state!=='Select' && state!=='') {
                                        setIsValid(true)
                                    } else {
                                        setIsValid(false)
                                    }
                                } else {
                                    setIsValid(false)
                                }
                            } else {
                                setIsValid(false)
                            }
                        } else {
                            setIsValid(false)
                        }
                    } else {
                        setIsValid(false)
                    }
                } else {
                    setIsValid(false)
                }
            } else {
                setIsValid(false)
            }
        } else {
            setIsValid(false)
        }
    }


    useEffect(() => {
        validator()
    }, [instiName, instiAddress, website, logo, city, pincode, country, state])


    const inputTypeHandler = (k, v) => {
        if (k === 'instiName') {
            setInstiName(v)
        }
        if (k === 'instiAddress') {
            setInstiAddress(v)
        }
        if (k === 'website') {
            let p = v.replace(/\s/g, '');
            setWebsite(p)
        }
        if (k === 'logo') {
            let p = v.replace(/\s/g, '');
            setLogo(p)
        }
        if (k === 'city') {
            setCity(v)
        }
        if (k === 'pincode') {
            let p = v.replace(/\s/g, '')
            setPincode(p)
        }
        if(k==='country') {
            let p = v.replace(/\s/g, '')
            setCountry(v)
        }
        if(k==='state') {
            let p = v.replace(/\s/g, '')
            setState(v)
        }
    }

    const addParentAcademy = async () => {
        setIsUploadingStatus('UPLOADING')
        const addParentAcademyResponse = await fetch(BASE_URL_API + "/addParentAcademy",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    name: instiName,
                    website: website,
                    hqAddress: instiAddress,
                    logo: logo,
                    country: country,
                    state: state,
                    city: city,
                    pincode: pincode
                })
            });

        if (!addParentAcademyResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const addParentAcademyRespo = await addParentAcademyResponse.json();
            if (addParentAcademyRespo.status === 'success') {
                setIsUploadingStatus('UPLOADED')
            } else {
                if(addParentAcademyRespo.message==='token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setIsUploadingStatus('FAILED')
                }
            }
            console.log(addParentAcademyRespo)
        }

    }




    return (
        <div className={styles.addNewBoxWrapper}>

            {isUploadingStatus === 'NOT_UPLOADING' ?
                <div className={styles.addNewBoxContainer}>
                    <div className={styles.addNewBoxContainerTitle}>
                        Add a new academy / institution
                    </div>
                    <div className={styles.addNewBoxContainerBody}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Academy / Insti. Name</div>
                            <div className={styles.inputBox}>
                                <input placeholder="Please enter academy name..."
                                    onChange={(e) => inputTypeHandler('instiName', e.target.value)}
                                    value={instiName}
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Pin Address</div>
                            <div className={styles.inputBox}>
                                <input placeholder="Please enter full address..."
                                    onChange={(e) => inputTypeHandler('instiAddress', e.target.value)}
                                    value={instiAddress}
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Country</div>
                            <div className={styles.inputBox}>
                                <select onChange={(e) => inputTypeHandler('country', e.target.value)}>
                                    <option value="Select">Select</option>
                                    <option value="INDIA">INDIA</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>State</div>
                            <div className={styles.inputBox}>
                                <select onChange={(e) => inputTypeHandler('state', e.target.value)}>
                                    <option value="Select">Select</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Madhya">Madhya Pradesh</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="West Bengal">West Bengal</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Orissa">Orissa</option>
                                </select>
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Website</div>
                            <div className={styles.inputBox}>
                                <input placeholder='https://stylelink.in'
                                    onChange={(e) => inputTypeHandler('website', e.target.value)}
                                    value={website}
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Logo URL</div>
                            <div className={styles.inputBox}>
                                <input placeholder='https://stylelink.in'
                                    onChange={(e) => inputTypeHandler('logo', e.target.value)}
                                    value={logo}
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>City</div>
                            <div className={styles.inputBox}>
                                <input placeholder='Delhi'
                                    onChange={(e) => inputTypeHandler('city', e.target.value)}
                                    value={city}
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Pincode</div>
                            <div className={styles.inputBox}>
                                <input placeholder='110016'
                                    onChange={(e) => inputTypeHandler('pincode', e.target.value)}
                                    value={pincode}
                                />
                            </div>
                        </div>


                    </div>
                    <div className={styles.addNewBoxContainerFooter}>
                        <div className={styles.btnContainer}>
                            <div className={styles.cancelBtn}>
                                Cancel
                            </div>
                            {isValid === true ?
                                <button className={styles.submitBtn}
                                    onClick={() => addParentAcademy()}
                                >
                                    Submit
                                </button>
                                :
                                <div className={styles.submitBtnDull}>
                                    Submit
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                isUploadingStatus === 'UPLOADING' ?
                    <div className={styles.addNewBoxContainer}>
                        <div className={styles.addNewBoxContainerTitle}>
                            Adding a new academy / institution ...
                        </div>
                        <div className={styles.addNewBoxContainerBody}>
                            <div className={styles.centerContent}>
                                Hold On!
                                We are just uploading the entered data!.
                            </div>

                        </div>
                        <div className={styles.addNewBoxContainerFooter}>

                        </div>
                    </div>
                    : isUploadingStatus === 'UPLOADED' ?
                        <div className={styles.addNewBoxContainer}>
                            <div className={styles.addNewBoxContainerTitle}>
                                Success
                            </div>
                            <div className={styles.addNewBoxContainerBody}>
                                <div className={styles.centerContent}>
                                    <p>
                                        Voila,
                                        It's just done!
                                    </p>
                                    <p>
                                        You can now search for {instiName}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.addNewBoxContainerFooter}>

                            </div>
                        </div>
                        : isUploadingStatus === 'FAILED' ?
                            <div className={styles.addNewBoxContainer}>
                                <div className={styles.addNewBoxContainerTitleRed}>
                                    Failed
                                </div>
                                <div className={styles.addNewBoxContainerBody}>
                                    <div className={styles.centerContent}>
                                        <p>
                                            Oops....
                                        </p>
                                        <p>
                                            Updation failed!
                                        </p>
                                        <p>
                                            Please try again!
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.addNewBoxContainerFooter}>

                                </div>
                            </div >
                            :
                            <div>
                                -
                            </div>

            }

        </div >
    )
}

export default RDMAcademiesAddNew