import React, { useState } from 'react'
import { useParams } from 'react-router'
import ManageBusinessAccount from '../../Components/ManageClients/ManageBusinessAccount'
import styles from './ManageEachClient.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageEachClient = () => {
    const { vendorIdentifier } = useParams()
    const [isCreateBusinessAccountOpen, setIsCreateBusinessAccountOpen] = useState(false)
    
    const toggleCreateBusinessAccount = () => setIsCreateBusinessAccountOpen(false)

    const onBusinessAccountCreation = () => {
        toggleCreateBusinessAccount()
        toast.success("Successfully created the business account!")
    }


    return (
        <div>
            <ToastContainer />
            <div>
                <div>
                    Header
                </div>
            </div>
            <div>
                <div>
                    What is a business account?
                    <p>
                        For all the salons to access https://business.stylelink.in in order to raise their
                        requirements, hire staff, check status, sign contract, make payments, invoices, they must
                        need a business account.
                    </p>
                    <p>
                        The account is created at vendor level and covers all other stores inside it.
                    </p>
                    <p>
                        The accessability can be managed from business account. 
                    </p>
                    <p>
                        This business account is different from vendor account that we create to post jobs and create stores.. 
                    </p>
                </div>
                {isCreateBusinessAccountOpen && <ManageBusinessAccount isOpen={isCreateBusinessAccountOpen} onClose={toggleCreateBusinessAccount} onSuccess={onBusinessAccountCreation} vendorIdentifier={vendorIdentifier} />}
                <button onClick={() => setIsCreateBusinessAccountOpen(true)} >Create Business Account</button>
            </div>
        </div>
    )
}

export default ManageEachClient