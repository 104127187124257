import { RefreshSharp } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Workshops/WorkshopRegistrationsAll.module.css'

const WorkshopRegistrationsAll = props => {
    let authCtx = useContext(AuthContext);
    let selectedWorkshop = props.selectedWorkshop;

    const [collectingRegistrations, setCollectingRegistrations] = useState(false);
    const [allRegistrations, setAllRegistrations] = useState([])

    const getWorkshopRegistrations = async () => {
        setCollectingRegistrations(true)
        const getWorkshopRegistrationsResponse = await fetch(BASE_URL_API + "/getWorkshopRegistrations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    workshopId: selectedWorkshop.workshopDetails.workshopID
                })
            });

        if (!getWorkshopRegistrationsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getWorkshopRegistrationsRespo = await getWorkshopRegistrationsResponse.json();
            if (getWorkshopRegistrationsRespo.status === "failed") {
                if (getWorkshopRegistrationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            } else {
                setAllRegistrations(getWorkshopRegistrationsRespo.response);
            }
            console.log(getWorkshopRegistrationsRespo);
        }
        setCollectingRegistrations(false)
    }

    useEffect(() => {
        if (selectedWorkshop !== null) {
            getWorkshopRegistrations();
        }
    }, [selectedWorkshop])


    if (selectedWorkshop === null) {
        return (
            <div>
                Please select a workshop!
            </div>
        )
    }


    // console.log(selectedWorkshop)
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.workshopDetailsSection}>

                {selectedWorkshop.workshopDetails.workshopID}
                {selectedWorkshop.workshopDetails.workshopTitle}

            </div>
            {collectingRegistrations === false ?
                <div className={styles.registrationsSection}>
                    <div className={styles.registrationsStatsSummary}>
                        <div>
                            {allRegistrations.length}(s) has registered so far!
                        </div>
                        {!collectingRegistrations ?
                            <div className={styles.reloadIconWrapper} onClick={() => getWorkshopRegistrations()}>
                                <RefreshSharp />
                            </div>
                            :
                            <div className={styles.reloadIconWrapper}>
                                <img src="https://admin.stylelink.in/assets/icons/refresh.gif" />
                            </div>
                        }
                    </div>
                    <div className={styles.registrationsData}>
                        {allRegistrations.length > 0 ?
                            <div className={styles.allRegistrationsWrapper}>
                                {allRegistrations.map((item, index) => (
                                    <div key={index}
                                        className={styles.eachRegistrationWrapper}

                                    >
                                        <div className={styles.leftCol}>
                                            {item.stylist_profile_image !== '' ?
                                                <img
                                                    className={styles.userProfileImage}
                                                    src={item.stylist_profile_image}
                                                />
                                                :
                                                <div className={styles.imgTitle}>
                                                    {item.stylist_name.substring(0, 1)}
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.rightCol}>
                                            <div className={styles.stylistName}>
                                                {item.stylist_name}
                                            </div>
                                            <div>
                                                <div>
                                                    {item.registred_on}
                                                </div>
                                                {/* {item.registrationID} */}
                                                <div>
                                                    {item.contact_number}
                                                </div>
                                            </div>
                                            <div className={styles.actionBtnsWrapper}>
                                                <div className={styles.sendWhatsAppBtn}>
                                                    <a href={`https://api.whatsapp.com/send/?app_absent=1&phone=91` + item.contact_number} target="_blank">Message On WhatsApp</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div>
                                No one has registered for this workshop yet!
                            </div>
                        }
                    </div>

                </div>
                :
                <div className={styles.registrationsSection}>
                    <div className={styles.registrationsStatsSummary}>
                        Hold On!
                    </div>
                    <div className={styles.registrationsData}>
                        I'm just finding all registrations!
                    </div>
                </div>
            }


        </div>
    )
}

export default WorkshopRegistrationsAll