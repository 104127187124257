import { faFilter } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useEffect, useState } from 'react'
import { BiShareAlt } from 'react-icons/bi'
import { FaFilter, FaMapMarker, FaMapMarkerAlt, FaSearch, FaSortAlphaUp, FaUser } from 'react-icons/fa'
import { MdNavigateNext, MdViewModule } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import JobDetailsForFinder from '../../Components/JobsFinder/JobDetailsForFinder'
import { getExecutiveFullName } from '../../Helper/executivesData'
import { humanReadableTimePassed, parseAddressToShortFormate } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../JobsFinder/JobsFinder.module.css'
import UserSelectorForJobsFinder from './UserSelectorForJobsFinder'

const JobsFinder = () => {
    const authCtx = useContext(AuthContext)
    const params = useParams()
    const stylistIdentifier = params.stylistIdentifier
    const [activeSection, setActiveSection] = useState('NEARBY_JOBS')
    const [activeToggleBar, setActiveToggleBar] = useState(1)
    const [openings, setOpenings] = useState([1, 2, 3, 4, 5, 6, 2, 3, 3, 3, 6, 3, 3, 3, 3, 4, 4, 4, 3])
    const [tempOpenings, setTempOpenings] = useState([1, 2, 3, 4, 5, 6])
    const [selectedJob, setSelectedJob] = useState(null)
    const [searchKey, setSearchKey] = useState('')
    const [afterSearchTemp, setAfterSearchTemp] = useState([1, 2, 3, 4])

    const [io, setIo] = useState(false)
    const abortUserSelector = () => setIo(false)
    const [userPInfos, setUserPInfos] = useState(null)
    const [isCollectingDetails, setIsCollectingDetails] = useState(false)


    const getStylistDetails = async () => {
        setIsCollectingDetails(true)
        const getStylistDetailsResponse = await fetch(BASE_URL_API + "/getStylistDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: stylistIdentifier
                })
            });

        if (!getStylistDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStylistDetailsRespo = await getStylistDetailsResponse.json();
            if (getStylistDetailsRespo.status === "success") {
                setUserPInfos(getStylistDetailsRespo.response)
            } else {
                if (getStylistDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
                setUserPInfos(null)
            }
            console.log(getStylistDetailsRespo)
        }
        setIsCollectingDetails(false)
    }


    useEffect(() => {
        stylistIdentifier && getStylistDetails()
    }, [stylistIdentifier])


    const getNearbyJobsForCandidate = async () => {
        const getNearbyJobsForCandidateResponse = await fetch(BASE_URL_API + "/getNearbyJobsForCandidate",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getNearbyJobsForCandidateResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getNearbyJobsForCandidateRespo = await getNearbyJobsForCandidateResponse.json()
            if (getNearbyJobsForCandidateRespo.status === "success") {
                setOpenings(getNearbyJobsForCandidateRespo?.response)
                setTempOpenings(getNearbyJobsForCandidateRespo?.response?.filter((item) => item?.hasAlreadyApplied === "No"))
                setAfterSearchTemp(getNearbyJobsForCandidateRespo?.response?.filter((item) => item?.hasAlreadyApplied === "No"))
            } else {
                if (getNearbyJobsForCandidateRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOpenings([])
                    setTempOpenings([])
                }
                // setUserPInfos(null)
            }
            console.log(getNearbyJobsForCandidateRespo)
        }
    }

    useEffect(() => {
        stylistIdentifier && getNearbyJobsForCandidate()
    }, [stylistIdentifier])

    useEffect(() => {
        if (activeSection === "NEARBY_JOBS") {
            setTempOpenings(openings.filter((item) => item?.hasAlreadyApplied === "No"))
            setAfterSearchTemp(openings.filter((item) => item?.hasAlreadyApplied === "No"))
        }
        if (activeSection === "APPLIED") {
            setTempOpenings(openings.filter((item) => item?.hasAlreadyApplied === "Yes"))
            setAfterSearchTemp(openings.filter((item) => item?.hasAlreadyApplied === "Yes"))
        }
    }, [activeSection])

    function filterData(data, searchKey) {
        return data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        );
    }

    useEffect(() => {
        setAfterSearchTemp(filterData(tempOpenings, searchKey))
    }, [searchKey])



    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.topHeaderWrapper}>
                    <div className={styles.mainToggleBar}>
                        <div className={styles.mainToggleContainer}>
                            <div className={activeToggleBar === 1 ? styles.eachToggleBarActive : styles.eachToggleBar}
                                onClick={() => setActiveToggleBar(1)}
                            >
                                1
                            </div>
                            <div className={activeToggleBar === 2 ? styles.eachToggleBarActive : styles.eachToggleBar}
                                onClick={() => setActiveToggleBar(2)}
                            >
                                2
                            </div>
                            <div className={activeToggleBar === 3 ? styles.eachToggleBarActive : styles.eachToggleBar}
                                onClick={() => setActiveToggleBar(3)}
                            >
                                3
                            </div>
                        </div>
                    </div>

                    <div className={styles.selectedUserWrapper}>
                        <div className={styles.selectedUserContainer}>
                            {isCollectingDetails === true ?
                                <div className={styles.leftUserContainer}>
                                    <div className={styles.imageContainer}>
                                    </div>
                                    <div>
                                        <div className={styles.username}>
                                            Loading...
                                        </div>
                                        <div className={styles.userPhone}>
                                            -
                                        </div>
                                    </div>
                                </div>
                                :
                                userPInfos !== null ?
                                    <div className={styles.leftUserContainer}>
                                        <div className={styles.imageContainer}>
                                            {userPInfos?.stylist_profile_image !== "" ?
                                                <img src={userPInfos?.stylist_profile_image} />
                                                :
                                                <FaUser size={20} />
                                            }
                                        </div>
                                        <div>
                                            <div className={styles.username}>
                                                {userPInfos.stylist_name}
                                            </div>
                                            <div className={styles.userPhone}>
                                                {userPInfos.contact_number}
                                            </div>
                                            <div className={styles.stylistAddress}>
                                                {userPInfos.currentLocation}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.leftUserContainer}>
                                        <div className={styles.imageContainer}>
                                            <FaUser size={20} />
                                            {/* <img /> */}
                                        </div>
                                        <div>
                                            <div className={styles.username}>
                                                ---
                                            </div>
                                            <div className={styles.userPhone}>
                                                +91-XXX-XXX-XXXX
                                            </div>
                                        </div>
                                    </div>
                            }

                            <div className={styles.rightUserContainer}>
                                <div className={styles.viewProfileBtn} title="View full profile">
                                    <a href={"/manage-users/grow/" + stylistIdentifier} target="_blank" >
                                        <MdViewModule color="#00000090" />
                                    </a>
                                </div>
                                <div>
                                    <button className={styles.searchBtnContainer} onClick={() => setIo(true)}>
                                        <FaSearch size={12} color="#00000090" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.filtersWrapper}>
                        <div className={styles.searchNLocationWrapper}>
                            <div className={styles.inputFieldWrapper}>
                                <div className={styles.inputFieldContainer}>
                                    <input placeholder="Search job..."
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        value={searchKey}
                                    />
                                </div>
                            </div>
                            <div className={styles.inputFieldWrapper}>
                                <div className={styles.inputFieldContainer}>
                                    <input placeholder="Location..." />
                                </div>
                            </div>
                        </div>
                        <div className={styles.sortNFilWrapper}>
                            <button className={styles.snfIcon}>
                                <FaSortAlphaUp />
                            </button>
                            <button className={styles.snfIcon}>
                                <FaFilter />
                            </button>
                        </div>
                    </div>

                    <div className={styles.sectionsWrapper}>
                        <div className={activeSection === "NEARBY_JOBS" ? styles.activeSection : styles.eachSection}
                            onClick={() => setActiveSection("NEARBY_JOBS")}
                        >
                            Nearby ({openings.filter((item) => item?.hasAlreadyApplied === "No").length})
                        </div>
                        <div className={activeSection === "BEST_MATCHES" ? styles.activeSection : styles.eachSection}
                            onClick={() => setActiveSection("BEST_MATCHES")}
                        >
                            Best Matches
                        </div>
                        <div className={activeSection === "APPLIED" ? styles.activeSection : styles.eachSection}
                            onClick={() => setActiveSection("APPLIED")}
                        >
                            Applied ({openings.filter((item) => item?.hasAlreadyApplied === "Yes").length})
                        </div>
                        <div className={activeSection === "BOOKMARKED" ? styles.activeSection : styles.eachSection}
                            onClick={() => setActiveSection("BOOKMARKED")}
                        >
                            Bookmarked
                        </div>
                    </div>

                    <UserSelectorForJobsFinder io={io} abortUserSelector={abortUserSelector} />
                </div>

                <div className={styles.leftBodyWrapper}>
                    <div className={styles.leftBodyContainer}>
                        {afterSearchTemp?.map((item, index) => (
                            <div className={`${selectedJob?.job_id === item?.job_id ? styles.eachOpeningWrapperSelected : styles.eachOpeningWrapper} ${item?.isPaidHiring === "1" ? styles.paid : ''}`}
                                key={index}
                                onClick={() => setSelectedJob(item)}

                            >
                                <div>
                                    <div className={styles.jobTitle}>
                                        <Link to={`/manage-job/${item?.job_id}`}
                                        >
                                            {item.title}
                                        </Link>
                                    </div>
                                    <div className={styles.storeName} title={item?.company_name}>
                                        {item?.company_name?.substring(0, 40)}...
                                    </div>
                                </div>
                                <div className={styles.rowWrapper}>
                                    <div className={styles.salaryRange}>
                                        &#8377;{item.salary_min}-{item.salary_max}/-
                                    </div>

                                    <div className={styles.minimumSalaryExpectation}>
                                        {item.minimumSalaryExpectation}
                                    </div>
                                </div>

                                <div className={styles.rowWrapper}>
                                    <div className={styles.location} title={item?.address}>
                                        <FaMapMarkerAlt size={10} />&nbsp;{parseAddressToShortFormate(item?.address)}
                                    </div>
                                </div>

                                <div className={styles.statsMark}>
                                    <div className={styles.postedAgo}>
                                        {item?.postedOn && humanReadableTimePassed(item?.postedOn)}
                                    </div>
                                    <div className={styles.postedAgo}>
                                        {parseFloat(item.distance)?.toFixed(1)} km
                                    </div>
                                </div>

                                <div className={styles.lastStatusWrapper}>
                                    <div className={styles.currentStatus}>
                                        {item?.currentStatus}
                                    </div>
                                    <div className={styles.lastAllotedTo}>
                                        {getExecutiveFullName(item?.lastAllotedTo)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.rightContainer}>
                <JobDetailsForFinder jobDetails={selectedJob} stylistIdentifier={stylistIdentifier} />
            </div>
        </div>
    )
}

export default JobsFinder