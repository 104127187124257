import React, { useContext, useEffect, useState } from 'react'
import DgAreas from '../../Components/ManageDemographics/DgAreas';
import DgCities from '../../Components/ManageDemographics/DgCities';
import DgStates from '../../Components/ManageDemographics/DgStates';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Admin/ManageDemographics.module.css'

const ManageDemographics = () => {
    const authCtx = useContext(AuthContext);
    const [countriesData, setCountriesData] = useState([
        {
            "country": 'India',
            "countryIdentifier": "IND"
        }
    ]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null);

    const selectStateHandler = (k) => {
        setSelectedState(k);
        setSelectedCity(null)
        setSelectedArea(null)
    }

    const selectCityHandler = (k) => {
        setSelectedCity(k);
        setSelectedArea(null)
    }

    const selectAreaHandler = (k) => {
        setSelectedArea(k)
    }

    const dgGetCountries = async () => {
        const dgGetCountriesResponse = await fetch(BASE_URL_API + "/dgGetCountries",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!dgGetCountriesResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const dgGetCountriesRespo = await dgGetCountriesResponse.json();
            if (dgGetCountriesRespo.status === 'success') {
                setCountriesData(dgGetCountriesRespo.response)
            } else {
                if (dgGetCountriesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(dgGetCountriesRespo);
                }
            }
        }
    }

    useEffect(() => {
        dgGetCountries()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.eachColWrapper}>
                    <div className={styles.header}>
                        Countries
                    </div>
                    <div className={styles.body}>
                        {countriesData.map((item, index) => (
                            <div 
                                className={selectedCountry===item ? styles.eachCardWrapperSelected : styles.eachCardWrapper}
                                key={index}
                                onClick={() => setSelectedCountry(item)}
                            >
                                {item.country}
                            </div>
                        ))}
                    </div>
                    <div className={styles.footer}>
                        {/* <div>
                            <input />
                        </div>
                        <div>
                            <button>Add</button>
                        </div> */}
                    </div>
                </div>

                <div className={styles.eachColWrapper}>
                    <DgStates
                        selectedCountry={selectedCountry}
                        selectStateHandler={selectStateHandler}
                        selectedState={selectedState}
                    />
                </div>
                <div className={styles.eachColWrapper}>
                    <DgCities
                        selectedState={selectedState}
                        selectCityHandler={selectCityHandler}
                        selectedCity={selectedCity}
                    />
                </div>
                <div className={styles.eachColWrapper}>
                    <DgAreas
                        selectedCity={selectedCity}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManageDemographics