import React, { useContext, useEffect, useState } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Jobs/LocationsFilter.module.css'

const LocationsFilter = props => {
    const authCtx = useContext(AuthContext);
    const selectedLocations = props.selectedLocations;
    const selectLocationHandler = props.selectLocationHandler;

    const [locationsData, setLocationsData] = useState([])
    const [statesData, setStatesData] = useState([])
    const [citiesData, setCitiesData] = useState([])

    const getDgData = async () => {
        const getDgDataResponse = await fetch(BASE_URL_API + "/getDgData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getDgDataResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getDgDataRespo = await getDgDataResponse.json();
            if(getDgDataRespo.status==='success') {
                setLocationsData(getDgDataRespo.response)
            } else {
                
                console.log(getDgDataRespo);
            }
        }
    }


    useEffect(() => {
        getDgData()
    }, [])

    useEffect(() => {
        let data = new Map();

        for (let obj of locationsData) {
            data.set(obj.stateIdentifier, obj);
        }

        let out = [...data.values()];

        // console.log("States data : ", out)
        setStatesData(out);

    }, [locationsData])

    useEffect(() => {
        let data = new Map();
        for (let obj of locationsData) {
            data.set(obj.cityIdentifier, obj);
        }
        let out = [...data.values()];

        // console.log("Cities data : ", out)
        setCitiesData(out);

    }, [statesData])

    

    return (
        <div className={styles.locationsWrapper}>
            <div className={styles.eachColTitle}>
                Location
            </div>
            <div>
                {statesData.map((item, index) => (
                    <div key={index}
                        className={styles.eachStateWrapper}
                    >
                        <div>
                            <div className={styles.stateTitleWrapper}>
                                {item.state}
                            </div>
                            <div className={styles.stateContentWrapper}>
                                {citiesData.filter((itemCities) => itemCities.stateIdentifier == item.stateIdentifier).map((itemCity, indexCity) => (
                                    <div className={styles.eachCityWrapper}
                                        key={indexCity+"P"+index}
                                    >
                                        <div className={styles.cityTitleWrapper}>
                                            {itemCity.city}
                                        </div>
                                        <div>
                                            <div>
                                                {locationsData.filter((itemAreas) => itemAreas.cityIdentifier === itemCity.cityIdentifier).map((itemArea, indexArea) => (
                                                    <div className={styles.eachAreaWrapper}
                                                        onClick={() => selectLocationHandler(itemArea.areaIdentifier)}
                                                        key={index+"SUPER"+itemArea.areaIdentifier+Math.random()}
                                                    >
                                                        <div>
                                                            {itemArea.area}
                                                        </div>
                                                        <div>
                                                            {selectedLocations.includes(itemArea.areaIdentifier) &&
                                                                <BsCheck2All size={14} />
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default LocationsFilter