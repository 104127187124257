import React, { useContext, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './JAAssignModeEachRow.module.css'

const JAAssignModeEachRow = props => {
    const authCtx = useContext(AuthContext)
    const { item, count, allExecutives, getRecentAllocatedJobs } = props
    const [allotedTo, setAllotedTo] = useState()

    const allotJobToExecutive = async (k) => {
        // console.log(k, item?.job_id, authCtx.userIdentifier)
        const allotJobToExecutiveResponse = await fetch(BASE_URL_API + "/allotJobToExecutive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    allotedTo: k,
                    jobIdentifier: item?.job_id,
                    allotedBy: authCtx.userIdentifier
                })
            });

        if (!allotJobToExecutiveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const allotJobToExecutiveRespo = await allotJobToExecutiveResponse.json()
            if (allotJobToExecutiveRespo.status === "success") {
                getRecentAllocatedJobs()
            } else {
                if (allotJobToExecutiveRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(allotJobToExecutiveRespo)
        }
    }

    return (
        <div className={`${styles.eachRowWrapper} ${item?.isPaidHiring==="1" ? styles.paid : ''}`}>
            <div className={styles.sn}>{count + 1}</div>
            <div className={styles.jobTitle}>
                <div className={styles.title}>{item?.title}</div>
                <div className={styles.categoryName}>{item?.job_category_name}</div>
            </div>
            <div className={styles.nameLocation}>
                <div className={styles.storeName}>{item?.company_name}</div>
                <div className={styles.address}>{item?.address}</div>
            </div>
            <div className={styles.postedOnRow}>{humanReadableTimePassed(item?.creation_date)}</div>
            <div className={styles.jobStatus}>
                {item?.isClosed === '0' ? <span className={styles.activeTag}>Active</span> : <span className={styles.closeTag}>Closed</span>}
            </div>
            <div className={styles.currentStatus}>
                <span className={styles.currentStatusTxt}>{item?.currentStatus === null ? "Posted" : item?.currentStatus}</span>
            </div>
            <div className={styles.allotedTo}>
                <select onChange={(e) => allotJobToExecutive(e.target.value)}>
                    <option value="SELECT">Select</option>
                    {allExecutives?.map((itemExe, indexExe) => (
                        <option value={itemExe?.userID} key={indexExe} selected={itemExe?.userID === item?.allotedTo ? "selected" : ""} >{itemExe?.firstName} {itemExe?.middleName} {itemExe?.lastName}</option>
                    ))}
                </select>
            </div>
            <div className={styles.allotedOn}>
                <span className={styles.postedOnRow}>
                    {humanReadableTimePassed(item?.allotedOn)}
                </span>
            </div>
            <div className={styles.manage}>Manage</div>
        </div>
    )
}

export default JAAssignModeEachRow