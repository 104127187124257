import React, { useContext, useEffect, useState } from 'react'
import styles from '../ResumeBuilding/RbPreferencesAddNewEachCard.module.css'
import Modal from 'react-modal';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import { IoCheckmarkDoneCircleOutline, IoCheckmarkDoneOutline } from 'react-icons/io5';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f1f1f1'
    },
};

const RbPreferencesAddNewEachCard = props => {
    let item = props.item;
    let selectedUser = props.selectedUser;
    const getAddedData = props.getAddedData;
    const authCtx = useContext(AuthContext)

    const [addedAreasForCity, setAddedAreasForCity] = useState([])
    const [allAreas, setAllAreas] = useState([])
    const [isFetching, setIsFetching] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    const initiateAreaSelector = () => {
        getAreaData()
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const getAreaData = async () => {
        setIsFetching(true)
        const getAreaDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'raw',
                    sectionType: 'preferences',
                    requestingFor: 'areas',
                    cityID: item.ID
                })
            });

        if (!getAreaDataResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAreaDataRespo = await getAreaDataResponse.json();
            if (getAreaDataRespo.status === 'success') {
                let temp = [];
                if (getAreaDataRespo.selectedData !== null) {
                    getAreaDataRespo.selectedData.map((item, index) => {
                        temp.push(item.ID);
                    })
                }
                setAddedAreasForCity(temp)
                setAllAreas(getAreaDataRespo.data)
            } else {
                if (getAreaDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getAreaDataRespo);
                }
            }
            setIsFetching(false)
        }
    }

    const toggleElement = async (k) => {
        if (addedAreasForCity.includes(k) === true) {
            setAddedAreasForCity(addedAreasForCity.filter((item) => item !== k))
        } else {
            setAddedAreasForCity([...addedAreasForCity, k]);
        }
    }

    const updateLocationPreferences = async () => {
        setIsUpdating(true)
        const updateLocationResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updateNow',
                    sectionType: 'preferences',
                    location_id: item.ID,
                    area_id: addedAreasForCity.join(',')
                })
            });

        if (!updateLocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateLocationRespo = await updateLocationResponse.json();
            if (updateLocationRespo.status === "success") {
                setIsOpen(false)
                getAddedData();
            } else {
                if (updateLocationRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateLocationRespo);
                }
            }
        }
        setIsUpdating(false)
    }

    const selectAlltoggleHandler = () => {
        if (addedAreasForCity.length === allAreas.length) {
            setAddedAreasForCity([])
        } else {
            let tmp = [];
            allAreas.map((item, index) => {
                tmp.push(item.ID);
            })
            setAddedAreasForCity(tmp)
        }
    }


    return (
        <div
            className={styles.eachCardWrapper}
        >
            <div className={styles.locationWrapper}>
                {item.location}
                <button onClick={() => initiateAreaSelector()}>+</button>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalHeader}>
                        <div className={styles.mainTitle}>
                            <div>
                                {item.location} - Added ({addedAreasForCity.length}) item(s)
                            </div>
                            <div>
                                {addedAreasForCity.length === allAreas.length ?
                                    <button className={styles.deSelectAllBtn}
                                        onClick={() => selectAlltoggleHandler()}
                                    >
                                        Remove All Areas
                                    </button>
                                    :
                                    <button className={styles.selectAllBtn}
                                        onClick={() => selectAlltoggleHandler()}
                                    >
                                        Select All Areas
                                    </button>
                                }

                            </div>
                        </div>

                        <div>
                            <button onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </div>
                    {isFetching === false ?
                        <div className={styles.modalBody}>
                            <div>
                                {allAreas.map((itemArea, index) => (
                                    <div className={styles.eachAreaOption}
                                        key={index}
                                        onClick={() => toggleElement(itemArea.ID)}
                                    >
                                        <div>
                                            {itemArea.area}
                                        </div>
                                        <div>
                                            {addedAreasForCity.includes(itemArea.ID) === true &&
                                                <IoCheckmarkDoneOutline
                                                />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={styles.modalBody}>
                            <div className={styles.loaderWrapper}>
                                <img src="https://media.tenor.com/6gHLhmwO87sAAAAj/gg.gif" />
                            </div>
                        </div>
                    }
                    <div className={styles.modalFooter}>
                        {isUpdating === false ?
                            <button className={styles.updateBtn}
                                onClick={() => updateLocationPreferences()}
                            >
                                Update
                            </button>
                            :
                            <div>
                                Updating the preferences...
                            </div>
                        }
                    </div>
                </div>


            </Modal>
        </div>
    )
}

export default RbPreferencesAddNewEachCard