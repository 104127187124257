import { Add, Save, SaveAlt } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import SLCEachSkillCol from './SLCEachSkillCol'
import styles from './SLCSkillsAndJD.module.css'

const SLCSkillsAndJD = props => {
    const { isSkillOpen, toggleSkillOpen, profiles, leadIdentifier, itemIdentifier } = props
    const authCtx = useContext(AuthContext)

    const [isSaving, setIsSaving] = useState(false)
    const [skills, setSkills] = useState([])
    const [selectedSkillsColumns, setSelectedSkillsColumns] = useState([])
    const [selectedSkills, setSelectedSkills] = useState([])
    const selectSkillHandler = (k) => {
        setSelectedSkills(selectedSkills.includes(k) === true ? selectedSkills.filter(item => item.ID !== k.ID) : [...selectedSkills, k])
    }
    const selectAllSkillsHandler = (category, gender) => {
        // console.log("Instructed to execute select all jobs : ", category, gender)
        let alreadySelected = [...selectedSkills]
        skills.filter(item => item.categoryId === category).map((item, index) => {
            if (item.for_gender === gender) {
                if (selectedSkills.some(itemIn => itemIn.ID === item.ID) !== true) {
                    alreadySelected.push(item)
                }
            }
        })
        console.log(selectedSkills.some(itemAl => itemAl.categoryId === category).length, "length")
        setSelectedSkills(alreadySelected)
    }
    const selectedSkillColManipulator = (k) => {
        setSelectedSkillsColumns([...selectedSkillsColumns, k])
    }
    const columnDataManipulator = (position, ID, name) => {
        const newData = [...selectedSkillsColumns]
        if (position !== '') {
            newData[position] = {
                ID: ID,
                category_name: name,
                isActive: "1",
                addedOn: "2023-01-06 12:04:09"
            };
        }
        setSelectedSkillsColumns(newData)
    }

    const columnDataDelete = (position) => {
        const updatedItems = [...selectedSkillsColumns]; // Create a copy of the array
        updatedItems.splice(position, 1);   // Remove the item at the specified index
        setSelectedSkillsColumns(updatedItems);         // Update the state
    }

    const slcGetSkills = async () => {
        const slcGetSkillsResponse = await fetch(BASE_URL_API + "/slcGetSkills",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!slcGetSkillsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const slcGetSkillsRespo = await slcGetSkillsResponse.json()
            if (slcGetSkillsRespo.status === 'success') {
                let data = slcGetSkillsRespo.response
                setSkills(data)
                console.log(data)
            } else {
                if (slcGetSkillsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setSkills([])
                }
            }
        }
    }

    useEffect(() => {
        if (isSkillOpen) {
            slcGetSkills()
        }
    }, [itemIdentifier])


    const getJobDraftSkills = async () => {
        const getJobDraftSkillsResponse = await fetch(BASE_URL_API + "/getJobDraftSkills",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier,
                    itemIdentifier: itemIdentifier
                })
            });

        if (!getJobDraftSkillsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDraftSkillsRespo = await getJobDraftSkillsResponse.json()
            console.log(getJobDraftSkillsRespo)
            if (getJobDraftSkillsRespo.status === "success") {
                if (getJobDraftSkillsRespo?.response !== '') {
                    setSelectedSkills(JSON.parse(getJobDraftSkillsRespo?.response))
                    columnDataManipulator()
                    slcGetSkills()
                } else {
                    slcGetSkills()
                }
            } else {
                if (getJobDraftSkillsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSelectedSkills([])
                }
            }
        }
    }


    const updateJobDraftSkills = async () => {
        setIsSaving(true)
        const updateJobDraftSkillsResponse = await fetch(BASE_URL_API + "/updateJobDraftSkills",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier,
                    itemIdentifier: itemIdentifier,
                    skill: JSON.stringify(selectedSkills)
                })
            });

        if (!updateJobDraftSkillsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateJobDraftSkillsRespo = await updateJobDraftSkillsResponse.json()
            if (updateJobDraftSkillsRespo.status === "success") {
                toggleSkillOpen()
            } else {
                if (updateJobDraftSkillsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateJobDraftSkillsRespo)
        }
        setIsSaving(false)
    }

    useEffect(() => {
        if (isSkillOpen) {
            getJobDraftSkills()
        }
    }, [itemIdentifier, isSkillOpen])


    // console.log(skills)
    // console.log("profiles", profiles)
    // console.log("selectedSkills", selectedSkills)
    // console.log("selectedSkillsColumns", selectedSkillsColumns)




    if (!isSkillOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div>
                            <button onClick={() => toggleSkillOpen()} >Close</button>
                        </div>
                        {isSaving === false ?
                            <div className={styles.saveBtn} onClick={() => updateJobDraftSkills()}>
                                <button><Save />&nbsp;Save</button>
                            </div>
                            :
                            <div className={styles.saveBtn}>
                                <button>Saving...</button>
                            </div>
                        }
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.leftCol}>
                            {selectedSkillsColumns.map((item, index) => (
                                <SLCEachSkillCol
                                    key={index}
                                    position={index}
                                    columnData={item}
                                    columnDataManipulator={columnDataManipulator}
                                    columnDataDelete={columnDataDelete}
                                    skills={skills}
                                    profiles={profiles}
                                    selectedSkills={selectedSkills}
                                    selectSkillHandler={selectSkillHandler}
                                    selectAllSkillsHandler={selectAllSkillsHandler}
                                />
                            ))}
                            <div>
                                <button onClick={() => selectedSkillColManipulator({ ID: "", category_name: "", addedOn: "", isActive: "" })}><Add /></button>
                            </div>
                        </div>
                        <div className={styles.rightCol}>
                            <div>
                                {selectedSkills?.map((item, index) => (
                                    <div key={index}>
                                        {item?.skill}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SLCSkillsAndJD