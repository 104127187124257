import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import { useNavigate } from 'react-router';
import AuthContext from '../../store/auth-context';
import AddNewSubModule from './AddNewSubModule';

import styles from '../../Styles/Components/Course/EachCourseModule.module.css'
import { BASE_URL_API } from '../../References/urls';
import { FaPlayCircle } from 'react-icons/fa';


const EachCourseModule = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");

    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const moduleId = props.moduleId;
    const item = props.item;

    const [subModules, setSubModules] = useState(null);

    const getSubModules = async () => {
        setIsLoading(true);
        const getSubModulesResponse = await fetch(BASE_URL_API + "/getSubModules",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId,
                    moduleId: moduleId
                })
            });

        if (!getSubModulesResponse.ok) {
            alert("Something went wrong : Server Error");
        } else {
            const getSubModulesRespo = await getSubModulesResponse.json();
            if (getSubModulesRespo.status == "success") {
                setSubModules(getSubModulesRespo.response);
            } else {
                console.log(getSubModulesRespo);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getSubModules();
    }, [])

    return (
        <div className={styles.eachModuleWrapper}>
            <Collapsible trigger={
                <div className={styles.eachModuleHeader}>
                    <h3>Module - {item.moduleOrder} {item.moduleTitle} </h3>
                </div>
            }>
                {subModules != null &&
                    subModules.map((item, index) => (
                        <div className={styles.eachSubModule}>
                            <span className={styles.subModuleType}>
                                {item.subModuleType=="VIDEO" && 
                                <FaPlayCircle />}
                            </span>
                            <h5>{item.subModuleOrder} {item.subModuleTitle}</h5>
                            <p>{item.subModuleSubTitle}</p>
                        </div>
                    ))
                }
                <AddNewSubModule
                    courseId={courseId}
                    setIsLoading={setIsLoading}
                    moduleId={item.moduleId}
                    getSubModules={getSubModules}
                />
            </Collapsible>
        </div>
    )
}

export default EachCourseModule