import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styles from './CAEJWMDataCollectionType.module.css'

const CAEJWMDataCollectionType = (props) => {
    const { profileCollectionType, profileCollectionToggler } = props
    const [isOpen, setIsOpen] = useState(false)
    

    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Profile collection Type
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Get profiles on the basis of : 
                    </div>
                    <div>
                        <div className={profileCollectionType==="Distance" ? styles.eachCollectionTypeActive : styles.eachCollectionType}
                            onClick={() => profileCollectionToggler('Distance')}
                        >
                            Distance
                        </div>
                        <div className={profileCollectionType==="Location Preferences" ? styles.eachCollectionTypeActive : styles.eachCollectionType}
                            onClick={() => profileCollectionToggler('Location Preferences')}
                        >
                            Location Preferences
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMDataCollectionType