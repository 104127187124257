import React, { useState } from 'react'
import { FaBox, FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import styles from './CAEJWMWorkDomain.module.css'

const CAEJWMWorkDomain = (props) => {
    const { domainsData, selectedDomain, domainToggler } = props
    const [isOpen, setIsOpen] = useState(false)


    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Work Domain ({selectedDomain?.length} / {domainsData?.length})
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Include all profile with work domain at any level.
                    </div>
                    <div>
                        {domainsData?.map((item, index) => (
                            <div className={styles.eachProfileWrapper} key={index} onClick={() => domainToggler(item?.ID)} >
                                {selectedDomain?.includes(item?.ID)===false ? <MdCheckBoxOutlineBlank size={22} /> : <MdCheckBox size={22} />}
                                &nbsp;{item?.category_name}
                            </div>
                        ))}
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWMWorkDomain