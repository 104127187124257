import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../References/urls';
import AuthContext from '../store/auth-context';

import styles from "../Styles/Content/ManageWorkshops.module.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
  ShimmerCategoryList,
} from "react-shimmer-effects";
import { FaChevronRight } from 'react-icons/fa';

const ManageWorkshops = () => {
  let authCtx = useContext(AuthContext);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const [workshopsData, setWorkshopsData] = useState(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  useEffect(() => {
    getAllWorkshops();
  }, [])

  const getAllWorkshops = async () => {
    setIsStatusUpdating(true);
    const workshopsResponse = await fetch(BASE_URL_API + "/getAllWorkshops",
      {
        method: "POST",
        body: JSON.stringify({
          token: authCtx.token
        })
      });

    if (!workshopsResponse.ok) {
      console.log("Something went wrong!");
    } else {
      const workshopsRespo = await workshopsResponse.json();
      if (workshopsRespo.status === "success") {
        setWorkshopsData(workshopsRespo.response);
      } else {
        console.log(workshopsRespo);
      }
      // console.log(workshopsRespo);
    }
    setIsStatusUpdating(false);
  }

  console.log(workshopsData)

  const deleteHandler = async () => {
    setIsStatusUpdating(true);
    const deleteWorkshopResponse = await fetch(BASE_URL_API + "/deleteWorkshops",
      {
        method: "POST",
        body: JSON.stringify({
          token: authCtx.token,
          workshopID: selectedWorkshop.workshopDetails.workshopID
        })
      });

    if (!deleteWorkshopResponse.ok) {
      console.log("Something went wrong!");
    } else {
      const deleteWorkshopRespo = await deleteWorkshopResponse.json();
      if (deleteWorkshopRespo.status === "success") {
        toast.success("Workshop successfully deleted!");
        getAllWorkshops();
        setSelectedWorkshop(null);
      } else {
        toast.warn("Workshop deletion failed!");
        console.log(deleteWorkshopRespo);
      }
    }
    setIsStatusUpdating(false)
  }

  const activateHandler = async () => {
    setIsStatusUpdating(true);
    const toggleActivateWorkshopResponse = await fetch(BASE_URL_API + "/toggleActivateWorkshop",
      {
        method: "POST",
        body: JSON.stringify({
          token: authCtx.token,
          workshopID: selectedWorkshop.workshopDetails.workshopID
        })
      });

    if (!toggleActivateWorkshopResponse.ok) {
      console.log("Something went wrong : Server Error!");
    } else {
      const toggleActivateWorkshopRespo = await toggleActivateWorkshopResponse.json();
      if (toggleActivateWorkshopRespo.status === "success") {
        toast.success("Workshop status successfully updated!");
        getAllWorkshops();
      } else {
        console.log(toggleActivateWorkshopRespo);
        toast.warn("Workshop status updation failed!");
      }
    }
    setIsStatusUpdating(false);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <ToastContainer />
        {workshopsData !== null ?
          <div>
            <div className={styles.foundCount}>{workshopsData.length} workshop(s) found!</div>
            {isStatusUpdating &&
              <div className={styles.listLoader}>
                It's loading...
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
                <div className={styles.eachLoader}>
                  <ShimmerThumbnail height={40} width={300} className="m-0" rounded />
                </div>
              </div>
            }
            {workshopsData.map((item, index) => (
              <div
                key={Math.random()}
                className={selectedWorkshop === item ? styles.eachWorkshopContainerSelected : styles.eachWorkshopContainer}
                onClick={() => setSelectedWorkshop(item)}
              >
                <div className={styles.workshopCategory}>{item.workshopDetails.workshopCategory}</div>
                <div className={styles.startDate}>{item.workshopDetails.startingTime}</div>
                {item.workshopDetails.workshopTitle}
                <div>
                  {item.workshopDetails.instructorName}
                </div>
              </div>
            ))}


          </div>
          :
          <div>
            no records found!
          </div>
        }
      </div>
      <div className={styles.rightContainer}>
        Workshop Details
        {selectedWorkshop ?
          <div className={styles.workshopContainer}>
            <div className={styles.workshopTitle}>
              {selectedWorkshop.workshopDetails.workshopTitle}
            </div>
            <div className={styles.workshopDescription}>
              {selectedWorkshop.workshopDetails.workshopDescription}
            </div>

            <div className={styles.workshopBenefitContainer}>
              Benefits of Attending this workshop:
              {selectedWorkshop.workshopBenefits.map((itemBen, indexBen) => (
                <div className={styles.eachBenefit} key={indexBen}>
                  <FaChevronRight size={12} /> {itemBen.benefit}
                </div>
              ))}
            </div>
            <div className={styles.locationDetails}>
              <div>
                Mode of Conduction : {selectedWorkshop.workshopDetails.modeOfConduction}
              </div>
              <div>
                Platform : {selectedWorkshop.workshopDetails.platform}
              </div>
              <div>
                Language : {selectedWorkshop.workshopDetails.instructorLanguage}
              </div>
            </div>
            <div className={styles.instructorDetails}>
              <div>About Instructor</div>
              <div className={styles.instructorImgDet}>
                <div>
                  <img src={selectedWorkshop.workshopDetails.instructorProfilePic} className={styles.instructorImage} />
                </div>
                <div>
                  <div>
                    {selectedWorkshop.workshopDetails.instructorName}
                  </div>
                  <div className={styles.instructorDescription}>
                    {selectedWorkshop.workshopDetails.instructorDescription}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.paymentDetails}>
              <div>
                Fee Type :
                {selectedWorkshop.workshopDetails.feeType}
              </div>
              <div>
                Org Price : {selectedWorkshop.workshopDetails.orgPrice}
              </div>
              <div>
                Discounted Price : {selectedWorkshop.workshopDetails.discountedPrice}
              </div>

            </div>

            <div className={styles.durationContainer}>
              <div>Starting On : {selectedWorkshop.workshopDetails.startingTime} </div>
              <div>Duration : {selectedWorkshop.workshopDetails.workshopDuration} </div>
            </div>



            {
              !isStatusUpdating &&
              <div className={styles.actionContainer}>
                {/* <button>Edit</button> */}
                <button onClick={() => activateHandler()}>{selectedWorkshop.workshopDetails.isActive === '1' ? "De-Activate" : "Activate"}</button>
                <button onClick={() => deleteHandler()}>Delete</button>
              </div>}
          </div>
          :
          <div className={styles.workshopContainer}>
            <div className={styles.selectWorkshopMsg}>
              Please select a workshop to view details!
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default ManageWorkshops