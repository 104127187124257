import React, { useState } from 'react'
import styles from '../../Styles/Components/Course/EachCourseManager.module.css';
import CourseAdministration from './CourseAdministration';
import CourseBasicDetails from './CourseBasicDetails';
import CourseContent from './CourseContent';
import CoursePricing from './CoursePricing';
import WhatYouLearn from './WhatYouLearn';

const EachCourseManager = props => {
    const [steps, setSteps] = useState([
        {
            "step": "1",
            "stepName": "Basic Details",
            "id": "basicDetails"
        },
        {
            "step": "2",
            "stepName": "What you'll learn in this course?",
            "id": "whatYoullLearn"
        },
        {
            "step": "3",
            "stepName": "Modules in the course!",
            "id": "modules"
        },
        {
            "step": "4",
            "stepName": "Pricing",
            "id": "pricing"
        },
        {
            "step": "5",
            "stepName": "Admin/Marketing",
            "id": "adminMarketing"
        },
    ]);

    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const [currentStep, setCurrentStep] = useState();

    const handleLoader = (st) => {
        setIsLoading(st);
    }

    const getAllCourses = props.getAllCourses;

    return (
        <div>
            <section className={styles.sectionHeader}>
                {steps.map((item, index) => (
                    <button
                        className={currentStep == item.step ? styles.stepButtonSelected : styles.stepButton}
                        onClick={() => setCurrentStep(item.step)}
                        key={index}
                    >
                        {item.stepName}
                    </button>
                ))}
            </section>
            {courseId != "" ?
                <>
                    {currentStep == 1 && <CourseBasicDetails courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />}
                    {currentStep == 2 && <WhatYouLearn courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />}
                    {currentStep == 3 && <CourseContent courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />}
                    {currentStep == 4 && <CoursePricing courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />}
                    {currentStep == 5 && <CourseAdministration courseId={courseId} setIsLoading={handleLoader} getAllCourses={getAllCourses} />}
                </>
                :
                <div className={styles.noCourseSelected}>
                    Please select a course to start!
                </div>
            }
            {/* EachCourseManager
            {courseId} */}
        </div>
    )
}

export default EachCourseManager