import React, { useContext, useEffect, useState } from 'react'
import styles from '../InRecruitmentDomain/UpdateJobUrgencyLevel.module.css'

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';

const UpdateJobUrgencyLevel = props => {
    const authCtx = useContext(AuthContext)
    const { isOpen, abortUrgencyLevelMission, stylistIdentifier } = props
    const [cases, setCases] = useState([])
    const [isNewCase, setIsNewCase] = useState(true)
    const [activeCase, setActiveCase] = useState(null)
    const [isCollectingInfo, setIsCollectingInfo] = useState(true)
    const [sub, setSub] = useState('')
    const [remark, setRemark] = useState('')
    const [isCollectingCases, setIsCollectingCases] = useState(false)
    const [isEscalatingCase, setIsEscalatingCase] = useState(false)
    const [caseThreads, setCaseThreads] = useState([])

    window.onclick = function (event) {
        if (event.target.id === "jobUrgencyLevel") {
            abortUrgencyLevelMission();
        }
    }

    const activeCaseHandler = async (k) => {
        setIsNewCase(false)
        setActiveCase(k)

        getEscalatedCaseInfo(k)
    }
    
    const getEscalatedCasesList = async (k) => {
        setIsCollectingCases(true)
        const getEscalatedCasesListResponse = await fetch(BASE_URL_API + "/getEscalatedCasesList",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getEscalatedCasesListResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEscalatedCasesListRespo = await getEscalatedCasesListResponse.json();
            if (getEscalatedCasesListRespo.status === "success") {
                setCases(getEscalatedCasesListRespo.response)
            } else {
                if (getEscalatedCasesListRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCases([])
                }
            }
            console.log(getEscalatedCasesListRespo)
        }
        setIsCollectingCases(false)
    }

    const getEscalatedCaseInfo = async (k) => {
        setIsCollectingInfo(true)
        const getEscalatedCaseInfoResponse = await fetch(BASE_URL_API + "/getEscalatedCaseInfo",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    caseIdentifier: k
                })
            });

        if (!getEscalatedCaseInfoResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEscalatedCaseInfoRespo = await getEscalatedCaseInfoResponse.json();
            if (getEscalatedCaseInfoRespo.status === "success") {
                setCaseThreads(getEscalatedCaseInfoRespo.response)
            } else {
                if (getEscalatedCaseInfoRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCaseThreads([])
                }
            }
            console.log(getEscalatedCaseInfoRespo)
        }

        setIsCollectingInfo(false)
    }

    const escalateNewCase = async () => {
        setIsEscalatingCase(true)
        const escalateNewCaseResponse = await fetch(BASE_URL_API + "/escalateNewCase",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    stylistIdentifier: stylistIdentifier,
                    subject: sub,
                    remark: remark
                })
            });

        if (!escalateNewCaseResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const escalateNewCaseRespo = await escalateNewCaseResponse.json();
            if (escalateNewCaseRespo.status === "success") {
                // setSub('')
                // setRemark('')
                setIsNewCase(false)
                getEscalatedCasesList().then(() => activeCaseHandler(escalateNewCaseRespo.response))
            } else {
                if (escalateNewCaseRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(escalateNewCaseRespo)
                }
            }
        }
        setIsEscalatingCase(false)
    }

    useEffect(() => {
        if(isOpen) {
            getEscalatedCasesList()
        }
    }, [stylistIdentifier, isOpen])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="jobUrgencyLevel">
                <div className={styles.bodyContainer}>
                    <div className={styles.leftContainer}>
                        <div className={styles.caseListHeader}>
                            All Cases
                        </div>
                        <div className={styles.caseListBody}>
                            {cases.map((item, index) => (
                                <div key={index}
                                    className={styles.eachCaseWrapper}
                                    onClick={() => activeCaseHandler(item.caseIdentifier)}
                                >
                                    <div className={activeCase === item.caseIdentifier ? styles.eachCaseContainerSelected : styles.eachCaseContainer}>
                                        <div className={styles.caseType}>
                                            {item?.subject.replaceAll("_", " ")}
                                        </div>
                                        <div className={styles.caseTxt}>
                                            {item?.caseIdentifier}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.createNewCase}>
                            <button onClick={() => setIsNewCase(true)}>
                                Create A New Case +
                            </button>
                        </div>
                    </div>
                    {isNewCase === false ?
                        isCollectingInfo === true ?
                            <div className={styles.rightContainer}>
                                <div className={styles.rightContainerHeader}>
                                    Hold On! I'm collecting case summary....
                                </div>
                                <div className={styles.statusWrapper}>
                                    <div className={styles.outgoingMsgWrapper}>
                                        <div className={styles.outgoingMsgContainer}>
                                            <ShimmerThumbnail
                                                height={80}
                                                className={styles.outgoingMsgTxtLoader}
                                            />
                                            <div className={styles.outgoingMsgTime}>
                                                <ShimmerThumbnail
                                                    height={8}
                                                    width={200}
                                                    className={styles.outgoingMsgTime}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.incomingMsgWrapper}>
                                        <div className={styles.incomingMsgContainer}>
                                            <ShimmerThumbnail
                                                height={100}
                                                className={styles.incomingMsgTxtLoader}
                                            />
                                            <div className={styles.incomingMsgTime}>
                                                <ShimmerThumbnail
                                                    height={8}
                                                    width={200}
                                                    className={styles.incomingMsgTime}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={styles.rightContainer}>
                                <div className={styles.rightContainerHeader}>
                                    <div className={styles.rcTitle}>
                                        Case active since
                                    </div>

                                </div>
                                <div className={styles.statusWrapper}>
                                    {caseThreads.map((item, index) => (
                                        <div
                                            key={index}
                                            className={item.msgType === "OUTGOING" ? styles.outgoingMsgWrapper : styles.incomingMsgWrapper}
                                        >
                                            <div className={item.msgType === "OUTGOING" ? styles.outgoingMsgContainer : styles.incomingMsgContainer}>
                                                <div className={item.msgType === "OUTGOING" ? styles.outgoingMsgTxt : styles.incomingMsgTxt}>
                                                    {item.remarks}
                                                </div>
                                                <div className={item.msgType === "OUTGOING" ? styles.outgoingMsgTime : styles.incomingMsgTime}>
                                                    {item.addedOn} by {item.actionBy}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        :
                        <div className={styles.rightContainer}>
                            <div className={styles.rightContainerHeader}>
                                <div className={styles.rcTitle}>
                                    Creating a new case
                                </div>
                                <div>

                                </div>
                            </div>
                            <div className={styles.newCaseWrapper}>
                                <div className={styles.reason}>
                                    <select onChange={(e) => setSub(e.target.value)}>
                                        <option value="">Choose Case Type</option>
                                        <option value="URGENTLY_NEED_JOB">Urgently looking for a job</option>
                                    </select>
                                </div>
                                <div className={styles.textForm}>
                                    <textarea
                                        onChange={(e) => setRemark(e.target.value)}
                                        placeholder="Add anything that supports case request (visible only to internal team)..">
                                    </textarea>
                                </div>
                                {sub !== "" &&
                                    <div className={styles.addCaseBtnWrapper}>
                                        {isEscalatingCase === true ?
                                            <button className={styles.addCaseBtn}>
                                                Creating...
                                            </button>
                                            :
                                            <button className={styles.addCaseBtn} onClick={() => escalateNewCase()}>
                                                Add
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default UpdateJobUrgencyLevel