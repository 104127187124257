import React, { useContext, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './RejectApplication.module.css'


const RejectApplication = props => {
    const { isOpen, rejectApplicationHandler, applicationIdentifier, getApplicationStatus } = props
    const authCtx = useContext(AuthContext)
    const [rejectionReason, setRejectionReason] = useState('')
    const [rejectionSubReason, setRejectionSubReason] = useState('')
    const [rejectionRemarks, setRejectionRemarks] = useState('')
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

    console.log(applicationIdentifier, isOpen)

    const rejectionReasonHandler = (k) => {
        setRejectionReason(k)
        setRejectionSubReason('')
    }
    const rejectApplication = async () => {
        setIsUpdatingStatus(true)
        const updateApplicationStatusResponse = await fetch(BASE_URL_API + "/updateApplicationStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationId: applicationIdentifier,
                    actionFor: "REJECT_APPLICATION",
                    actionStatus: rejectionReason,
                    rejectionSubReason : rejectionSubReason,
                    actionRemarks: rejectionRemarks
                })
            });

        if (!updateApplicationStatusResponse.ok) {
            console.log("Server Error : Something went wrong, please contact admin!");
        } else {
            const updateApplicationStatusRespo = await updateApplicationStatusResponse.json();
            if (updateApplicationStatusRespo.status == "success") {
                if (updateApplicationStatusRespo.message == "Rejection status updated succesfully!") {
                    toast.success(updateApplicationStatusRespo.message);
                    getApplicationStatus()
                } else {
                    toast.warn(updateApplicationStatusRespo.message);
                }
            } else {
                console.log(updateApplicationStatusRespo);
            }
        }
        setIsUpdatingStatus(false)
    }

    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.fbwHeader}>
                            <button className={styles.cancelBtn} onClick={() => rejectApplicationHandler()} >Cancel</button>
                            <div>
                                Are you sure you want to reject the application?
                            </div>
                        </div>
                        <div className={styles.fbwBody}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.rejectionOptionsWrapper}>
                                    <div className={rejectionReason === "SALON_REJECTED_APPLICATION" ? styles.fbwFieldEachOptionSelected : styles.fbwFieldEachOption} onClick={() => rejectionReasonHandler('SALON_REJECTED_APPLICATION')}>
                                        <div className={styles.fbwfeoTitle}>
                                            Salon Rejected Application
                                        </div>
                                        <div className={styles.fbwfeoSubTitle}>
                                            Select this if candidate visited the salon, get interviewed but rejected by the salon due to various reasons.
                                        </div>
                                    </div>
                                    <div className={rejectionReason === "STYLELINK_REJECTED_APPLICATION" ? styles.fbwFieldEachOptionSelected : styles.fbwFieldEachOption} onClick={() => rejectionReasonHandler('STYLELINK_REJECTED_APPLICATION')}>
                                        <div className={styles.fbwfeoTitle}>
                                            Stylelink Rejected Application
                                        </div>
                                        <div className={styles.fbwfeoSubTitle}>
                                            Select this if stylelink rejecting the candidate's application due to various reasons.
                                        </div>
                                    </div>
                                    <div className={rejectionReason === "CANDIDATE_WITHDRAWN_APPLICATION" ? styles.fbwFieldEachOptionSelected : styles.fbwFieldEachOption} onClick={() => rejectionReasonHandler('CANDIDATE_WITHDRAWN_APPLICATION')}>
                                        <div className={styles.fbwfeoTitle}>
                                            Candidate Withdrawn Application
                                        </div>
                                        <div className={styles.fbwfeoSubTitle}>
                                            Select this if candidates has withdrawn applications due to various reason.
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.subOptionsWrapper}>
                                    {rejectionReason === 'SALON_REJECTED_APPLICATION' &&
                                        <div className={styles.fbwFieldSubOptions}>
                                            <div className={rejectionSubReason === "DUE_TO_LESS_EXPERIENCE" ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason("DUE_TO_LESS_EXPERIENCE")}>
                                                Due to Less Experience
                                            </div>
                                            <div className={rejectionSubReason === "DUE_TO_LACK_OF_PROFESSIONALISM" ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason("DUE_TO_LACK_OF_PROFESSIONALISM")}>
                                                Due to Lack of Professionalism
                                            </div>
                                            <div className={rejectionSubReason === "DUE_TO_RELIGIOUS_DISCRIMINATION" ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason("DUE_TO_RELIGIOUS_DISCRIMINATION")}>
                                                Due to Religious Discrimination
                                            </div>
                                        </div>
                                    }
                                    {rejectionReason === 'STYLELINK_REJECTED_APPLICATION' &&
                                        <div className={styles.fbwFieldSubOptions}>
                                            <div className={rejectionSubReason === 'DID_NOT_APPEAR_FOR_THE_INTERVIEW' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('DID_NOT_APPEAR_FOR_THE_INTERVIEW')}>
                                                Did not appear for the interview
                                            </div>
                                            <div className={rejectionSubReason === 'COULD_NOT_CONNECT_THE_CANDIDATE' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('COULD_NOT_CONNECT_THE_CANDIDATE')}>
                                                Could not connect the candidate
                                            </div>
                                            <div className={rejectionSubReason === 'SALON_STOPPED_RESPONDING' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('SALON_STOPPED_RESPONDING')}>
                                                Salon stopped responding
                                            </div>
                                            <div className={rejectionSubReason === 'JOB_ALREADY_CLOSED' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('JOB_ALREADY_CLOSED')}>
                                                Job already closed
                                            </div>
                                        </div>
                                    }
                                    {rejectionReason === 'CANDIDATE_WITHDRAWN_APPLICATION' &&
                                        <div className={styles.fbwFieldSubOptions}>
                                            <div className={rejectionSubReason === 'LESS_SALARY_OFFERED' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('LESS_SALARY_OFFERED')}>
                                                Less Salary Offered
                                            </div>
                                            <div className={rejectionSubReason === 'DISTANCE_ISSUE' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('DISTANCE_ISSUE')}>
                                                Distance Issue
                                            </div>
                                            <div className={rejectionSubReason === 'FOUND_SALON_OF_LOW_STANDARD' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('FOUND_SALON_OF_LOW_STANDARD')}>
                                                Found Salon of Low Standard
                                            </div>
                                            <div className={rejectionSubReason === 'ALREADY_GOT_JOB_FROM_OTHER_SOURCES' ? styles.eachSubOptionSelected : styles.eachSubOption} onClick={() => setRejectionSubReason('ALREADY_GOT_JOB_FROM_OTHER_SOURCES')}>
                                                Already got job from other sources
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <div className={styles.addRemarksWrapper}>
                                    <textarea placeholder="Add remarks"
                                        onChange={(e) => setRejectionRemarks(e.target.value)}
                                        value={rejectionRemarks}
                                    >
                                    </textarea>
                                </div>
                                <div className={styles.fbwField}>
                                    {(rejectionReason !== '' && rejectionSubReason !== '') ?
                                        rejectionRemarks.length > 10 ?
                                            <button className={styles.rejectBtn}
                                                onClick={() => rejectApplication()}
                                            >
                                                Reject
                                            </button>
                                            :
                                            <div className={styles.warning}>
                                                Please enter some remarks..
                                            </div>
                                        :
                                        <div className={styles.warning}>
                                            Select a rejection option properly.
                                        </div>
                                    }
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default RejectApplication