import React from 'react'
import styles from './QPSalaryRange.module.css'

const QPSalaryRange = (props) => {
    const { startingSalary, maximumSalary, handleStartingSalary, handleMaximumSalary, currentSlideHandler } = props


    return (
        <div>
            <div className={styles.mainContent}>
                <div className={styles.optionTitle}>
                    Experience Required
                </div>
                <div className={styles.optionsWrapper}>
                    <div className={styles.eachSalaryFieldWrapper}>
                        <div className={styles.eachTitle}>Starting Salary</div>
                        <div className={styles.eachSalaryField}>
                            <input autoFocus
                                type="number"
                                value={startingSalary}
                                onChange={(e) => handleStartingSalary(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.eachSalaryFieldWrapper}>
                        <div className={styles.eachTitle}>Lowest Salary</div>
                        <div className={styles.eachSalaryField}>
                            <input
                                type="number"
                                value={maximumSalary}
                                onChange={(e) => handleMaximumSalary(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.nextBtnWrapper}>
                <button onClick={() => currentSlideHandler('WhoCanApply')}>Next</button>
            </div>
        </div>
    )
}

export default QPSalaryRange