import React from 'react'
import styles from './TEachAudioCard.module.css'

const TEachAudioCard = (props) => {
    const { item } = props
    return (
        <div className={styles.mainContainer}>
            <div>
                <div>{item?.count}</div>
                <div>
                    Receiver Type : {item?.type}
                </div>
                <div>
                    <ul>
                        {item?.languageTags?.map((item, index) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>

                <div>
                    Call Receiver : {item?.receiverType}
                </div>
            </div>
            <div className={styles.audioPlayer}>
                <audio controls>
                    <source src={item?.src} type="audio/wav" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
    )
}

export default TEachAudioCard