import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../ManagePartnersAccounts/MpaStoreDetails.module.css'

const MpaStoreDetails = props => {
    const getStoreListing = props.getStoreListing;
    const authCtx = useContext(AuthContext);
    let selectedStore = props.selectedStore;
    const [statesData, setStatesData] = useState([]);
    const [citiesData, setCitiesData] = useState([]);
    const [areasData, setAreasData] = useState([]);

    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");


    const dgGetStates = async () => {
        const dgGetStatesResponse = await fetch(BASE_URL_API + "/dgGetStates",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    countryIdentifier: 'IND'
                })
            });

        if (!dgGetStatesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetStatesRespo = await dgGetStatesResponse.json();
            if (dgGetStatesRespo.status === 'success') {
                setStatesData(dgGetStatesRespo.response);
            } else {
                if(dgGetStatesRespo.message=='token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(dgGetStatesRespo);
                }
            }
        }
    }

    const dgGetCities = async (k) => {
        const dgGetCitiesResponse = await fetch(BASE_URL_API + "/dgGetCities",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stateIdentifier: k
                })
            });

        if (!dgGetCitiesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetCitiesRespo = await dgGetCitiesResponse.json();
            if (dgGetCitiesRespo.status == "success") {
                setCitiesData(dgGetCitiesRespo.response);
            } else {
                if(dgGetCitiesRespo.message=='token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log("Cities response : ", dgGetCitiesRespo);
                }
            }
        }
    }

    const dgGetAreas = async (k) => {
        const dgGetAreasResponse = await fetch(BASE_URL_API + "/dgGetAreas",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cityIdentifier: k
                })
            });

        if (!dgGetAreasResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetAreasRespo = await dgGetAreasResponse.json();
            if (dgGetAreasRespo.status === 'success') {
                setAreasData(dgGetAreasRespo.response)
            } else {
                console.log(dgGetAreasRespo)
            }
        }
    }

    const upaDemographics = async (type, identifier, label) => {
        const upaDemographicsResponse = await fetch(BASE_URL_API+"/upa-demographics",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                demograpyType : type,
                identifier : identifier,
                label : label,
                store_id : selectedStore.store_id
            })
        });

        if(!upaDemographicsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const upaDemographicsRespo = await upaDemographicsResponse.json();
            if(upaDemographicsRespo.status==="success") {
                getStoreListing()
            } else {
                if(upaDemographicsRespo.message=='token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(upaDemographicsRespo); 
                }
            }
        }
    }

    useEffect(() => {
        dgGetStates()
    }, [])

    useEffect(() => {
        if (selectedState !== "") {
            dgGetCities(selectedState.stateIdentifier)
        }
    }, [selectedState])

    useEffect(() => {
        if (selectedCity !== "") {
            dgGetAreas(selectedCity.cityIdentifier)
        }
    }, [selectedCity])

    useEffect(() => {
        if(selectedStore.dgStateIdentifier!=="") {
            dgGetCities(selectedStore.dgStateIdentifier)
        }
        if(selectedStore.dgCityIdentifier!=="") {
            dgGetAreas(selectedStore.dgCityIdentifier);
        }
    }, [selectedStore])

    const selectStateHandler = (k) => {
        setSelectedState(JSON.parse(k));
        setSelectedCity("");
        upaDemographics('state', JSON.parse(k).stateIdentifier, JSON.parse(k).state)
    }

    const selectCityHandler = (k) => {
        setSelectedCity(JSON.parse(k));
        upaDemographics('city', JSON.parse(k).cityIdentifier, JSON.parse(k).city)
    }

    const selectAreaHandler = (k) => {
        upaDemographics('area', JSON.parse(k).areaIdentifier, JSON.parse(k).area)
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    StoreDetails
                </div>
                <div className={styles.mainContent}>

                    <div className={styles.eachRowWrapper}>
                        <div className={styles.eachParameterWrapper}>
                            <div className={styles.paramTitle}>
                                Select State
                            </div>
                            <div className={styles.paramDropdown}>
                                <select
                                    onChange={(e) => selectStateHandler(e.target.value)}
                                >
                                    <option value="">Select State</option>
                                    {statesData.map((item, index) => (
                                        <option key={index} value={JSON.stringify(item)} selected={item.stateIdentifier===selectedStore.dgStateIdentifier ? true : false} >{item.state}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={styles.eachParameterWrapper}>
                            <div className={styles.paramTitle}>
                                Select City
                            </div>
                            <div className={styles.paramDropdown}>
                                <select
                                    onChange={(e) => selectCityHandler(e.target.value)}
                                >
                                    <option value="" >Select city</option>
                                    {citiesData.map((item, index) => (
                                        <option key={index} value={JSON.stringify(item)} selected={item.cityIdentifier===selectedStore.dgCityIdentifier ? true : false} >{item.city}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={styles.eachParameterWrapper}>
                            <div className={styles.paramTitle}>
                                Select Area
                            </div>
                            <div className={styles.paramDropdown}>
                                <select
                                    onChange={(e) => selectAreaHandler(e.target.value)}
                                >
                                    <option value="">Select Area</option>
                                    {areasData.map((item, index) => (
                                        <option key={index} value={JSON.stringify(item)} selected={item.areaIdentifier===selectedStore.dgAreaIdentifier ? true : false} >{item.area}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default MpaStoreDetails