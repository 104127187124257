import React, { useContext, useEffect, useState } from 'react'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context';
import styles from '../Analytics/AnalyticsSummary.module.css'

const AnalyticsSummary = () => {
    const authCtx = useContext(AuthContext);
    const [data, setData] = useState([])
    const getProfileWiseAnalysis = async () => {
        const getProfileWiseAnalysisResponse = await fetch(BASE_URL_API + "/sa-getProfileWiseAnalysis",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getProfileWiseAnalysisResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getProfileWiseAnalysisRespo = await getProfileWiseAnalysisResponse.json();
            if(getProfileWiseAnalysisRespo.status!=="failed") {
                console.log(getProfileWiseAnalysisRespo)
                setData(getProfileWiseAnalysisRespo.response)
            } else {
                if(getProfileWiseAnalysisRespo.message=='token Expired, please login again!') {
                    authCtx.logout();
                }
            }
        }
    }

    useEffect(() => {
        getProfileWiseAnalysis()
    }, [])

    // const data = [
    //     {
    //         "name": "Page A",
    //         "uv": 4000,
    //         "pv": 2400
    //     },
    //     {
    //         "name": "Page B",
    //         "uv": 3000,
    //         "pv": 1398
    //     },
    //     {
    //         "name": "Page C",
    //         "uv": 2000,
    //         "pv": 9800
    //     },
    //     {
    //         "name": "Page D",
    //         "uv": 2780,
    //         "pv": 3908
    //     },
    //     {
    //         "name": "Page E",
    //         "uv": 1890,
    //         "pv": 4800
    //     },
    //     {
    //         "name": "Page F",
    //         "uv": 2390,
    //         "pv": 3800
    //     },
    //     {
    //         "name": "Page G",
    //         "uv": 3490,
    //         "pv": 4300
    //     }
    // ]

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <div>
                        <div>
                            <div>
                                Master Summary
                            </div>
                            {/* <div>Exclusive available for C-level executives only!</div> */}
                        </div>
                        <div>
                            <div>
                                <div>
                                    Partners Summary
                                </div>
                                <div>
                                    Openings Summary
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.rightContainer}>
                    <ComposedChart width={1400} height={250} data={data}
                    >
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                        <XAxis dataKey="categoryTitle" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="minSalary" fill="#8884d8" stroke="#8884d8" />
                        <Area type="monotone" dataKey="maxSalary" fill="#da1884" stroke="#8884d8" />
                        <Bar dataKey="minOfminSalary" barSize={15} fill="#413ea0" />
                        <Bar dataKey="maxOfminSalary" barSize={15} fill="#ffcb08" />
                        <Bar dataKey="minOfmaxSalary" barSize={15} fill="#413ea0" />
                        <Bar dataKey="maxOfmaxSalary" barSize={15} fill="#ffcb08" />
                        <Line type="monotone" dataKey="avgSalary" stroke="#ff7300" />
                    </ComposedChart>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsSummary