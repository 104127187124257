import React from 'react'
import styles from './QPQualificationRequired.module.css'

const QPQualificationRequired = (props) => {
    const { requiredQualifications, requiredQualificationsHandler } = props
    const qualificationsOption = ['Not Required', '10th', '12th', 'Diploma', 'Graduation', 'Post-graduation']

    console.log(requiredQualifications)

    return (
        <div>
            <div>
                <div>
                    <div className={styles.optionTitle}>
                        Select Required Education Qualifications
                    </div>
                    <div className={styles.optionsWrapper}>
                        {qualificationsOption?.map((item, index) => (
                            <button className={requiredQualifications===item ? styles.eachOptionSelected : styles.eachOption} key={index}  
                                onClick={() => requiredQualificationsHandler(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QPQualificationRequired