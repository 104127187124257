import React, { useContext } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './MseConfig.module.css'

import { toast, ToastContainer } from 'react-toastify';

const MseConfig = props => {
    const { storeIdentifier } = props
    const authCtx = useContext(AuthContext)

    const setVendorIdentifierForStores = async () => {
        const setVendorIdentifierForStoresResponse = await fetch(BASE_URL_API + "/setVendorIdentifierForStores",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!setVendorIdentifierForStoresResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const setVendorIdentifierForStoresRespo = await setVendorIdentifierForStoresResponse.json()
            if (setVendorIdentifierForStoresRespo.status === "success") {
                toast("Successfully updated!")
            } else {
                if (setVendorIdentifierForStoresRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else if (setVendorIdentifierForStoresRespo?.message === "already updated the vendor identifier") {
                    toast("Identifier already mapped!")
                } else {
                    console.log(setVendorIdentifierForStoresRespo)
                }
            }
            console.log(setVendorIdentifierForStoresRespo)
        }
    }

    return (
        <div>
            <ToastContainer />
            <div>
                {storeIdentifier}
            </div>
            <div>
                <button onClick={() => setVendorIdentifierForStores()}>
                    Map Vendor Account
                </button>
                MseConfig
            </div>
        </div>
    )
}

export default MseConfig