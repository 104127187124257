import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Partners/PartnersLeadListEachLead.module.css'

const PartnersLeadListEachLead = props => {
    const { item } = props
    const decodeCompanyName = (k) => {
        let str = k.replaceAll("+", " ")
        str = str.replaceAll("%26", "&")
        str = str.replaceAll("%27", "'")
        str = str.replaceAll("%40", "@")
        str = str.replaceAll("%2C", ",")

        return str;
    }
    return (
        <div className={styles.eachCompany}>
            <Link to={"/salon-leads/" + item.salon_id}>
                <div>
                    {decodeCompanyName(item.company_name)}
                </div>
                <div className={styles.address}>
                    {item.address}
                </div>
            </Link>
        </div>
    )
}

export default PartnersLeadListEachLead