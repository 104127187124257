import React, { useContext, useEffect, useState } from 'react'
import { IoLocationSharp } from 'react-icons/io5';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbPersonalInformations.module.css'
import RbPICurrentLocationNCordi from './RbPICurrentLocationNCordi';
import RbPIExpectedSalary from './RbPIExpectedSalary';
import RbPIProfileDomain from './RbPIProfileDomain';


import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import PRMainWrapper from '../ProfileRemarks/PRMainWrapper';
import { FaWhatsapp } from 'react-icons/fa';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RbPersonalInformations = props => {
    let selectedUser = props.selectedUser;
    // console.log("I'm printing selected user : ", selectedUser)
    let selectedProfileType = props.selectedProfileType;
    const authCtx = useContext(AuthContext)
    const [maritalStatus, setMaritalStatus] = useState(selectedUser.maritalStatus)
    const [userPersonalDetails, setUserPersonalDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sendingWelcomeMessage, setSendingWelcomeMessage] = useState(false)

    const leadPhone = userPersonalDetails?.contact_number
    const msgTxt = "Hi,%0A%0AWe have noted down your requirements and will schedule your interview with the salon matching your skill set.%0A%0AYou can also, apply for the jobs using this link:%0Ahttps://play.google.com/store/apps/details?id=in.stylelink %0A%0AThanks and Regards%0A%0ATeam Stylelink"
    const waUrl = `https://api.whatsapp.com/send/?phone=91${leadPhone}&text=${msgTxt}&type=phone_number&app_absent=1`

    const getUserPersonalInformations = async () => {
        setIsLoading(true);
        const getUserPersonalInformationsResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'personalInformations'
                })
            });

        if (!getUserPersonalInformationsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getUserPersonalInformationsRespo = await getUserPersonalInformationsResponse.json();
            if (getUserPersonalInformationsRespo.status === 'success') {
                setUserPersonalDetails(getUserPersonalInformationsRespo.response);
            } else {
                if (getUserPersonalInformationsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setUserPersonalDetails(null);
                }
            }
            console.log(getUserPersonalInformationsRespo);
            setIsLoading(false)
        }
    }

    const updateMaritalStatus = async (k) => {
        const updateMaritalStatusResponse = await fetch(BASE_URL_API + "/updateMaritalStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    maritalStatus: k,
                    stylist_id: selectedUser.stylist_id
                })
            });
        if (!updateMaritalStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateMaritalStatusRespo = await updateMaritalStatusResponse.json();
            if (updateMaritalStatusRespo.status === "success") {
                console.log(updateMaritalStatusRespo)
            } else {
                if (updateMaritalStatusRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateMaritalStatusRespo);
                }
            }
        }
    }


    const updateMaritalStatusHandler = (k) => {
        setMaritalStatus(k)
        updateMaritalStatus(k)
        console.log(k);
    }

    useEffect(() => {
        if (selectedUser !== '') {
            getUserPersonalInformations()
        }
    }, [selectedUser])


    const updateGenderHandler = async (k) => {
        // stylist_gender
        // userPersonalDetails
        setUserPersonalDetails((prevUserData) => ({
            ...prevUserData,
            stylist_gender: k,
        }));
        const updateCandidateGenderResponse = await fetch(BASE_URL_API + "/updateCandidateGender",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: userPersonalDetails?.stylist_id,
                    stylist_gender: k
                })
            });
        if (!updateCandidateGenderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCandidateGenderRespo = await updateCandidateGenderResponse.json();
            if (updateCandidateGenderRespo.status === "success") {
                toast("Gender updated successfully")
            } else {
                if (updateCandidateGenderRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateCandidateGenderRespo)
                }
            }
        }

    }

    const sendOnboardingMessageToCandidates = async (lang) => {
        setSendingWelcomeMessage(true)
        const sendOnboardingMessageToCandidatesResponse = await fetch(BASE_URL_API + "/sendOnboardingMessageToCandidates",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    phoneNumber: selectedUser?.contact_number,
                    fullName: selectedUser?.stylist_name,
                    language: lang
                })
            });

        if (!sendOnboardingMessageToCandidatesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendOnboardingMessageToCandidatesRespo = await sendOnboardingMessageToCandidatesResponse.json()
            if (sendOnboardingMessageToCandidatesRespo.status === "success") {
                toast("Message sent successfully")
            } else {
                if (sendOnboardingMessageToCandidatesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast("Could not send the message!")
                    console.log(sendOnboardingMessageToCandidatesRespo)
                }
            }
        }
        setSendingWelcomeMessage(false)
    }


    if (isLoading) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.leftWrapper}>
                    <div className={styles.headerWrapper}>
                        {selectedProfileType}
                    </div>
                    <div className={styles.bodyWrapper}>
                        <ShimmerThumbnail height={100} className={styles.containerLoader} />
                        <ShimmerThumbnail height={100} className={styles.containerLoader} />
                        <ShimmerThumbnail height={100} className={styles.containerLoader} />
                        <ShimmerThumbnail height={100} className={styles.containerLoader} />
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.rightHeaderWrapper}>
                        Add new {selectedProfileType}
                    </div>
                    <div className={styles.rightBodyWrapper}>
                    </div>
                    <div className={styles.rightFooterWrapper}>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType}
                </div>
                <div className={styles.bodyWrapper}>
                    {userPersonalDetails !== null ?
                        <div className={styles.bodyContentWrapper}>
                            <div className={styles.imageDescriptionRow}>
                                <div className={styles.userImage}>
                                    <img src={userPersonalDetails.stylist_profile_image !== '' ? userPersonalDetails.stylist_profile_image : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} />
                                </div>
                                <div className={styles.userContent}>
                                    <div className={styles.descriptionWrapper}>
                                        {userPersonalDetails.stylist_description !== '' ? userPersonalDetails.stylist_description : <div className={styles.emptyDescription}>Stylist has not updated the description yet..</div>}
                                    </div>
                                    <div className={styles.tagsWrapper}>
                                        <div className={styles.eachTag}>
                                            {userPersonalDetails.stylist_username}
                                        </div>
                                        <div className={styles.eachTag}>
                                            {userPersonalDetails.contact_number}
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.genderContainer}>
                                            <button
                                                onClick={() => updateGenderHandler("FEMALE")}
                                                className={userPersonalDetails?.stylist_gender === "FEMALE" ? styles.activeGen : styles.defGen} >
                                                Female
                                            </button>
                                            <button
                                                onClick={() => updateGenderHandler("MALE")}
                                                className={userPersonalDetails?.stylist_gender === "MALE" ? styles.activeGen : styles.defGen} >
                                                Male
                                            </button>
                                            <button
                                                onClick={() => updateGenderHandler("LGBTQ+")}
                                                className={userPersonalDetails?.stylist_gender === "LGBTQ+" ? styles.activeGen : styles.defGen} >
                                                LGBTQ+
                                            </button>
                                            <button
                                                onClick={() => updateGenderHandler("")}
                                                className={userPersonalDetails?.stylist_gender === "" ? styles.activeGen : styles.defGen} >
                                                Unknown
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {selectedUser.stylist_address !== '' &&
                                    <div className={styles.locationWrapper}>
                                        <IoLocationSharp />
                                        {userPersonalDetails.stylist_address}
                                    </div>
                                }
                                <RbPICurrentLocationNCordi
                                    selectedUser={userPersonalDetails}
                                />
                                <div className={styles.maritalStatusWrapper}>
                                    <div className={styles.maritalStatusHeader}>Marital Status</div>
                                    <div className={styles.maritalStatusBody}>
                                        <button className={maritalStatus === 'MARRIED' ? styles.maritalBtnSelected : styles.maritalBtn}
                                            onClick={() => updateMaritalStatusHandler('MARRIED')}
                                        >
                                            Married
                                        </button>
                                        <button className={maritalStatus === 'UNMARRIED' ? styles.maritalBtnSelected : styles.maritalBtn}
                                            onClick={() => updateMaritalStatusHandler('UNMARRIED')}
                                        >
                                            Un-married
                                        </button>
                                        <button className={maritalStatus === 'DIVORCED' ? styles.maritalBtnSelected : styles.maritalBtn}
                                            onClick={() => updateMaritalStatusHandler('DIVORCED')}
                                        >
                                            Divorced
                                        </button>
                                        <button className={maritalStatus === 'OTHER' ? styles.maritalBtnSelected : styles.maritalBtn}
                                            onClick={() => updateMaritalStatusHandler('OTHER')}
                                        >
                                            Other
                                        </button>
                                        <button className={maritalStatus === 'UNKNOWN' || maritalStatus === '' ? styles.maritalBtnSelected : styles.maritalBtn}
                                            onClick={() => updateMaritalStatusHandler('UNKNOWN')}
                                        >
                                            Unknown
                                        </button>
                                    </div>

                                </div>

                            </div>

                            <RbPIProfileDomain
                                selectedUser={userPersonalDetails}
                            />
                            <RbPIExpectedSalary
                                selectedUser={userPersonalDetails}
                            />
                        </div>
                        :
                        <div className={styles.bodyContentWrapper}>
                            Could not fetch the data!
                        </div>
                    }
                </div>
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.rightHeaderWrapper}>
                    Little More
                </div>
                <div className={styles.rightBodyWrapper}>
                    <div className={styles.registeredWrapper}>
                        <div className={styles.joinedOnWrapper}>
                            <div className={styles.joinedOnTitle}>
                                Joined Stylelink On {userPersonalDetails.registred_on}
                            </div>
                            <div className={styles.joinedVia}>
                                Via {userPersonalDetails.registeredVia !== '' ? userPersonalDetails.registeredVia : 'mobile app'}
                            </div>
                        </div>

                        <div className={styles.sendBtnWrapper}>
                            {sendingWelcomeMessage === false ?
                                <button onClick={() => sendOnboardingMessageToCandidates('en_us')} className={styles.eachBtn}>
                                    Send Message in English
                                </button>
                                :
                                <button className={styles.eachBtn}>
                                    Sending Message...
                                </button>
                            }
                            {sendingWelcomeMessage === false ?
                                <button onClick={() => sendOnboardingMessageToCandidates('hi')} className={styles.eachBtn}>
                                    हिंदी में वेलकम मैसेज भेजें
                                </button>
                                :
                                <button className={styles.eachBtn}>
                                    वेलकम मैसेज भेजा जा रहा है
                                </button>
                            }

                            {/* this is been obsolated by the introduction of meta whatsapp api, we are removing it on 20th March 2024 */}
                            {/* <a href={waUrl} target="_blank" ><FaWhatsapp color="green" size={24} /></a> */}
                        </div>
                    </div>


                    <PRMainWrapper
                        user={userPersonalDetails}
                    />
                </div>
                <div className={styles.rightFooterWrapper}>
                    <div className={styles.rightFooterContainer}>
                        {/* <button className={styles.secondaryBtn}>Cancel</button>
                        <button className={styles.primaryBtn}>Save</button> */}
                    </div>
                </div>
            </div>

        </div>

    )
}

export default RbPersonalInformations