import React, { useContext, useEffect, useState } from 'react'
import { BsAlignStart, BsArrowLeft, BsFilter, BsPlus, BsSearch, BsToggleOn } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import CreateNewCourseInitiator from '../../../Components/Course/Academics/CreateNewCourseInitiator'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './AllCourses.module.css'

const AllCourses = () => {
    const authCtx = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)
    const toggleCreateNew = () => setIsOpen(false)
    const [allCourses, setAllCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getAllAcadCourses = async () => {
        setIsLoading(true)
        const getAllAcadCoursesResponse = await fetch(BASE_URL_API + "/acad-getAllCourses",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllAcadCoursesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllAcadCoursesRespo = await getAllAcadCoursesResponse.json()
            if (getAllAcadCoursesRespo.status === "success") {
                setAllCourses(getAllAcadCoursesRespo.response)
            } else {
                if (getAllAcadCoursesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllCourses([])
                }
            }
            console.log(getAllAcadCoursesRespo)
        }
        setIsLoading(false)
    }


    useEffect(() => {
        getAllAcadCourses()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <CreateNewCourseInitiator isOpen={isOpen} toggleCreateNew={toggleCreateNew} />
            <div className={styles.mainHeader}>
                <div className={styles.headerContainer}>
                    <div className={styles.actionWrapper}>
                        <Link to="/" className={styles.eachBtn}>
                            <BsArrowLeft size={18} />
                        </Link>

                        <button className={styles.eachBtn}>
                            Get All Courses
                        </button>
                        <button className={styles.eachBtn}>
                            <BsSearch size={14} />
                        </button>
                        <button className={styles.eachBtn}>
                            <BsFilter size={20} />
                        </button>

                    </div>
                    <div className={styles.rightWrapper}>
                        <button className={styles.createBtn} onClick={() => setIsOpen(true)}>
                            Create <BsPlus />
                        </button>
                    </div>
                </div>
            </div>
            {isLoading === true &&
                <div className={styles.tableLoader}>
                    <div className={styles.tlContainer}>Hold On! Collecting details...</div>
                </div>
            }
            <div className={styles.mainBody}>
                <div className={styles.tableHeaderWrapper}>
                    <div className={styles.courseCode}>Code</div>
                    <div className={styles.courseTitle}>Course Title</div>
                    <div className={styles.mrp}>MRP</div>
                    <div className={styles.dp}>DP</div>
                    <div className={styles.lp}>LP</div>
                    <div>Duration</div>
                    <div>Next Batch</div>
                    <div className={styles.action}>Action</div>
                </div>
                <div className={styles.tableBodyWrapper}>
                    {allCourses.map((item, index) => (
                        <div key={index} className={styles.eachRow}>
                            <div className={styles.courseCode}>{item?.courseCode}</div>
                            <div className={styles.courseTitleRow}>{item?.courseTitle}</div>
                            <div className={styles.mrp}>{item?.mrp}</div>
                            <div className={styles.dp}>{item?.dp}</div>
                            <div className={styles.lp}>{item?.lp}</div>
                            <div>{item?.tentativeDuration}</div>
                            <div>{item?.lp}</div>
                            <div className={styles.action}>
                                <Link to={`create/` + item?.courseToken}>Update</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AllCourses