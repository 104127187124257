import { SendSharp } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from '../ProfileRemarks/PRMainWrapper.module.css'
import PREachChat from './PREachChat'

const PRMainWrapper = props => {
    let user = props.user;
    const authCtx = useContext(AuthContext)
    const [remarks, setRemarks] = useState([])
    const [curVal, setCurVal] = useState('');
    const [isAdding, setIsAdding] = useState(false);

    const getProfileRemarks = async (k) => {
        // console.log("re-called this ")
        // let p = ['dil', 'diyan', 'gallan', 's'];
        // setRemarks(remarks => [...p, ...remarks])
        const getProfileRemarksResponse = await fetch(BASE_URL_API+"/getProfileRemarks",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                stylist_id : user.stylist_id
            })
        });

        if(!getProfileRemarksResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getProfileRemarksRespo = await getProfileRemarksResponse.json();
            if(getProfileRemarksRespo.status==="success") {
                setRemarks(getProfileRemarksRespo.response);
            } else {
                if(getProfileRemarksRespo.message==='token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setRemarks([])
                }
                console.log(getProfileRemarksRespo)
            }
        }
    }

    const addProfileRemarks = async k => {
        setIsAdding(true)
        const addProfileRemarksResponse = await fetch(BASE_URL_API+"/addProfileRemarks",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                stylist_id : user.stylist_id,
                content : curVal,
                addedByIdentifier : authCtx.userIdentifier
            })
        });

        if(!addProfileRemarksResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const addProfileRemarksRespo = await addProfileRemarksResponse.json();
            if(addProfileRemarksRespo.status==="success") {
                setCurVal('');
                getProfileRemarks();
            } else {

            }
            console.log(addProfileRemarksRespo)
        }
        setIsAdding(false)
    }

    useEffect(() => {
        getProfileRemarks()
    }, [user])
    

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.headerTitle}>
                    Added Notes / Remarks By Stylelink Team
                </div>
                <div className={styles.actionWrapper}>
                    <div>
                        .
                    </div>
                    <div className={styles.refreshBtnWrapper}>
                        <button>
                            Refresh
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.mainBody}>
                <PREachChat
                    remarks={remarks}
                    getProfileRemarks={getProfileRemarks}
                />
            </div>

            <div className={styles.mainFooter}>
                <div className={styles.footerContainer}>
                    <div className={styles.textAreaWrapper}>
                        <textarea placeholder="Add any thing helpful... "
                            onChange={(e) => setCurVal(e.target.value)}
                            value={curVal}
                        ></textarea>
                    </div>
                    <div className={styles.sendAction}>
                        {curVal==='' ?
                            <button
                                onClick={() => alert("Add some text first...")}
                            >
                                <SendSharp color="red" />
                            </button>
                            :
                            isAdding===false &&
                            <button
                                onClick={() => addProfileRemarks()}
                            >
                                <SendSharp />
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PRMainWrapper