import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Jobs/StoreDetails.module.css'
import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
} from "react-shimmer-effects";
import { Link } from 'react-router-dom';


const StoreDetails = props => {
    const authCtx = useContext(AuthContext);
    // const [selectedSalon, setSelectedSalon] = useState("");
    const storesData = props.storesData;
    const storesDataHandler = props.storesDataHandler;

    const loaderHandler = props.loaderHandler;
    const selectedSalon = props.selectedSalon;
    const selectSalonHandler = props.selectSalonHandler;
    const selectedStore = props.selectedStore;
    const selectStoreHandler = props.selectStoreHandler;


    const [isExtraFields, setIsExtraFields] = useState(false);
    const [storeName, setStoreName] = useState("");
    const [storeAddress, setStoreAddress] = useState("");
    const [storeAdded, setStoreAdded] = useState(false);

    const searchSalonHandler = (key) => {
        if (key.length > 2) {
            getSalonSuggestions(key);
        }
    }

    const [isSearching, setIsSearching] = useState();
    const [isSalonSelecting, setIsSalonSelecting] = useState(false);
    const [salonSuggestionData, setSalonSuggestionData] = useState([])

    const getSalonSuggestions = async (key) => {
        setIsSalonSelecting(true)
        setIsSearching(true);
        const getSalonSuggestionsResponse = await fetch(BASE_URL_API + "/getSalonSuggestions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    searchKey: key
                })
            });

        if (!getSalonSuggestionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getSalonSuggestionsRespo = await getSalonSuggestionsResponse.json();
            if (getSalonSuggestionsRespo.status === "success") {
                setSalonSuggestionData(getSalonSuggestionsRespo.response);
            } else {

            }
            console.log(getSalonSuggestionsRespo)
        }
        setIsSearching(false)
    }

    const getStoreListing = async (key) => {
        if (key != "") {
            loaderHandler(true)
            const getStoreListingResponse = await fetch(BASE_URL_API + "/getStoreListing",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        vendorIdentifier: key
                    })
                });

            if (!getStoreListingResponse.ok) {
                console.log("Something went wrong : Server Error");
            } else {
                const getStoreListingRespo = await getStoreListingResponse.json();
                if (getStoreListingRespo.status === "success") {
                    if (getStoreListingRespo.response !== null) {
                        storesDataHandler(getStoreListingRespo.response);
                        console.log("Some store data found : ");
                    } else {
                        storesDataHandler(null)
                        console.log("No store data found : ", getStoreListingRespo)
                    }
                } else {
                    storesDataHandler(null)
                    console.log(getStoreListingRespo);
                }
            }
            loaderHandler(false)
        } else {
            console.log("No salon selected, please selected the salon first!");
        }

    }



    const addANewStoreHandler = async (r) => {
        if (r === "first") {
            loaderHandler(true);
            const addNewStoreResponse = await fetch(BASE_URL_API + "/addNewStore",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        vender_email: selectedSalon
                    })
                });

            if (!addNewStoreResponse.ok) {
                console.log("Something went wrong : Server Error");
            } else {
                const addNewStoreRespo = await addNewStoreResponse.json();
                if(addNewStoreRespo.status==="success") {
                    console.log("Successfully updated the first store!");
                } else {
                    console.log(addNewStoreRespo);
                }
            }
            getStoreListing(selectedSalon);
            setStoreAdded(!storeAdded);
            // loaderHandler(false);
        } else if (r === "second") {
            setIsExtraFields(true);
        } else if (r === "secondSubmit") {
            loaderHandler(true);
            const addNewStoreResponse = await fetch(BASE_URL_API + "/addNewStore",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        vender_email: selectedSalon,
                        company_name: storeName,
                        address: storeAddress
                    })
                });

            if (!addNewStoreResponse.ok) {
                console.log("Something went wrong : Server Error");
            } else {
                const addNewStoreRespo = await addNewStoreResponse.json();
                console.log(addNewStoreRespo);
            }
            getStoreListing(selectedSalon);
            setStoreAdded(!storeAdded);
            setIsExtraFields(false);
            setStoreName("");
            setStoreAddress("");
            loaderHandler(false);
        }
        console.log(selectedSalon)
    }

    useEffect(() => {
        getStoreListing(selectedSalon);
        setIsSalonSelecting(false);
    }, [selectedSalon])

    console.log("this is selected salon : ",selectedSalon)

    return (
        <div className={styles.rcMainBody}>
            {selectedSalon === "" ?
                <input
                    className={styles.searchSalonInput}
                    placeholder="Search for salon name/number/email"
                    onChange={(e) => searchSalonHandler(e.target.value)}
                ></input>
                :
                <div
                    className={styles.searchSalonInput}
                    onClick={() => selectSalonHandler("")}
                >
                    {selectedSalon}
                </div>
            }
            {isSalonSelecting &&
                <div className={styles.suggestionSalonContainer}>
                    {isSearching === true ?
                        <>
                            {/* <ShimmerCircularImage size={300} /> */}
                            <ShimmerThumbnail height={300} width={1400} className="m-0" rounded />
                        </>
                        :
                        <>
                            {salonSuggestionData != null &&
                                <>
                                    <div className={styles.suggestionResultsFound}>
                                        {salonSuggestionData.length} result(s) found!
                                    </div>
                                    {salonSuggestionData.map((item, index) => (
                                        <div className={styles.eachSuggestionContainer} key={index + "OO"}
                                            onClick={() => selectSalonHandler(item.vendorIdentifier)}
                                        >
                                            <div className={styles.eachSuggestionCompanyName}>
                                                {item.company_name}
                                            </div>
                                            <div className={styles.eachSuggestionTable}>
                                                <div className={styles.eachSuggestionTableFieldEmail}>
                                                    {item.vender_email}
                                                </div>
                                                <div className={styles.eachSuggestionTableField}>
                                                    {item.vender_contact}
                                                </div>
                                                <div className={styles.eachSuggestionTableField}>
                                                    {item.forService}
                                                </div>
                                            </div>
                                            <div className={styles.suggestionAddress}>
                                                {item.address}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                    }
                </div>
            }

            {selectedSalon != "" ?
                <div className={styles.eachFieldContainer}>
                    <Collapsible trigger={
                        <div className={styles.eachFieldHeaderContainer}>
                            <div className={styles.eachFieldHeaderTitle}>
                                Store Location <FaChevronDown />
                            </div>
                        </div>}
                    >
                        <div className={styles.eachFieldHeaderSubTitle}>
                            Please select the store for which you are posting job!
                        </div>
                        {storesData != null ?
                            <div className={styles.storeLocationContainer}>
                                {storesData.map((item, index) => (
                                    <div className={selectedStore === item.store_id ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={item.store_id} onClick={() => selectStoreHandler(item.store_id)}>
                                        <div className={styles.eachStoreTitle}>
                                            {item.company_name}
                                        </div>
                                        <div className={styles.eachStoreAddress}>
                                            {item.address}
                                        </div>
                                    </div>
                                ))}
                                {isExtraFields ?
                                    <div className={styles.eachStoreContainerAdd}>
                                        <div className={styles.newStoreExtraFields}>
                                            <input placeholder="Enter the store name" onChange={(e) => setStoreName(e.target.value)} value={storeName}></input>  <br></br>
                                            <input placeholder="Enter the store address" onChange={(e) => setStoreAddress(e.target.value)} value={storeAddress}></input>
                                            <div className={styles.actionContainerExtraFields}>
                                                <button className={styles.actionBtnCancel} onClick={() => setIsExtraFields(false)}>Cancel</button>
                                                <button className={styles.actionBtnSubmit} onClick={() => addANewStoreHandler("secondSubmit")}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Link 
                                        to={`/manage-partners-account/${selectedSalon}`}
                                        className={styles.eachStoreContainerAdd}
                                    >
                                        Add a New store!
                                    </Link>
                                }
                            </div>
                            :
                            <div className={styles.eachFieldBodyContainer}>
                                {selectedSalon !== "" ?
                                    <Link
                                        to={`/manage-partners-account/${selectedSalon}`} 
                                        className={styles.addNewStore} 
                                    >
                                        Add First store!
                                    </Link>
                                    :
                                    <p className={styles.centerText}>Please select the salon first...</p>
                                }
                            </div>
                        }
                    </Collapsible>
                </div>
                :
                <div>

                </div>
            }
        </div>
    )
}

export default StoreDetails