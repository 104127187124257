import React, { useState } from 'react'
import CreateNewInvoicePopUp from '../../Components/Invoices/CreateNewInvoicePopUp'
import styles from './AllPartnersInvoices.module.css'

const AllPartnersInvoices = () => {
    const [createNewInvoiceOpen, setCreateNewInvoiceOpen] = useState(false)
    return (
        <div className={styles.mainWrapper}>
            {createNewInvoiceOpen && <CreateNewInvoicePopUp isOpen={createNewInvoiceOpen} onClose={() => setCreateNewInvoiceOpen(false)} />}
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.topRow}>
                        <div>All Sales Invoices</div>
                        <div className={styles.dneWrapper}>
                            <div>
                                <input type="date" />
                                <input type="date" />
                            </div>
                            <div>
                                <select>
                                    <option>Select</option>
                                    <option></option>
                                </select>
                            </div>
                            <div className={styles.createNew}>
                                <button onClick={() => setCreateNewInvoiceOpen(true)}>Create New +</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.secRow}>
                        <div>
                            <div>Revenue</div>
                            <div className={styles.revenue}>₹50,003/-</div>
                        </div>

                        <div className={styles.smallCardWrapper}>
                            <div className={styles.eachCardWrapper}>
                                <div>Total Invoice</div>
                                <div>-</div>
                            </div>
                            <div className={styles.eachCardWrapper}>
                                <div>Outstanding</div>
                                <div>-</div>
                            </div>
                            <div className={styles.eachCardWrapper}>
                                <div>Overdue</div>
                                <div>-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div>#</div>
                        <div>Invoice ID.</div>
                        <div>Vendor Name</div>
                        <div>Store Name</div>
                        <div>Amount</div>
                        <div>Current Status</div>
                    </div>
                    <div>

                    AllPartnersInvoices
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPartnersInvoices