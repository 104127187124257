import React from 'react'
import styles from './QPWhoCanApply.module.css'

const QPWhoCanApply = (props) => {
    const { whoCanApply, whoCanApplyHandler } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainTitle}>Who all can apply?</div>
                    <div className={styles.optionsWrapper}>
                        <button 
                            onClick={() => whoCanApplyHandler('Female Only')}
                            className={whoCanApply==="Female Only" ? styles.selectedOption : styles.option }
                        >
                                Female Only
                        </button>
                        <button 
                            onClick={() => whoCanApplyHandler('Male Only')}
                            className={whoCanApply==="Male Only" ? styles.selectedOption : styles.option }
                        >
                                Male Only
                        </button>
                        <button 
                            onClick={() => whoCanApplyHandler('Anyone')}
                            className={whoCanApply==="Anyone" ? styles.selectedOption : styles.option }
                        >
                                Anyone
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QPWhoCanApply