import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicHeader from '../../Components/Academics/AcademicHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AcademyNewVisitor.module.css'

const AcademyNewVisitor = () => {
    const authCtx = useContext(AuthContext)
    const [phone, setPhone] = useState('')
    const [fullName, setFullName] = useState('')
    const [fullAddress, setFullAddress] = useState('')
    const [gender, setGender] = useState('')
    const [visitPurpose, setVisitPurpose] = useState('')
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [formSubmittingStatus, setFormSubmittingStatus] = useState('NOT_SUBMITTING') // SUBMITTING, SUBMITTED, SUBMISSION_FAILED

    const addANewVisitor = async () => {
        // console.log(phone, fullName, fullAddress, gender, visitPurpose)
        setFormSubmittingStatus("SUBMITTING")
        const addANewVisitorResponse = await fetch(BASE_URL_API + "/acad-addANewVisitor",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx.userIdentifier,
                    phone: phone,
                    fullName: fullName,
                    fullAddress: fullAddress,
                    gender: gender,
                    visitPurpose: visitPurpose
                })
            });

        if (!addANewVisitorResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addANewVisitorRespo = await addANewVisitorResponse.json()
            if (addANewVisitorRespo.status === "success") {
                setFormSubmittingStatus("SUBMITTED")
            } else {
                if (addANewVisitorRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    if (addANewVisitorRespo.message === "updation failed") {
                        setFormSubmittingStatus("SUBMISSION_FAILED")
                    } else {
                    }
                }
            }
            console.log(addANewVisitorRespo)
        }
    }

    useEffect(() => {
        if (phone.length === 10) {
            if (fullName.length > 1) {
                if (fullAddress.length > 3) {
                    if (gender !== '') {
                        if (visitPurpose !== '') {
                            setIsFormValidated(true)
                        } else {
                            setIsFormValidated(false)
                        }
                    } else {
                        setIsFormValidated(false)
                    }
                } else {
                    setIsFormValidated(false)
                }
            } else {
                setIsFormValidated(false)
            }
        } else {
            setIsFormValidated(false)
        }
    }, [phone, fullName, fullAddress, gender, visitPurpose])


    const clearForm = () => {
        setPhone("")
        setFullName("")
        setFullAddress("")
        setGender("")
        setVisitPurpose("")
    }


    return (
        <div className={styles.mainWrapper}>
            <AcademicHeader />
            <div className={styles.mainBody}>
                {formSubmittingStatus === 'NOT_SUBMITTING' ?
                    <div className={styles.formWrapper}>
                        <div className={styles.eachField}>
                            <div className={styles.caption}>
                                Phone Number {phone.length !== 10 && <span className={styles.errorTxt}>Please enter a 10 digit phone number</span>}
                            </div>
                            <div className={styles.field}>
                                <input
                                    placeholder="Please enter a 10 digit phone number..." autoFocus={true} type="number"
                                    onChange={(e) => setPhone(e.target.value)} value={phone}
                                    maxLength={10}
                                />
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.caption}>
                                Full Name
                            </div>
                            <div className={styles.field}>
                                <input placeholder="Full Name" onChange={(e) => setFullName(e.target.value)} value={fullName} />
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.caption}>
                                Full Address
                            </div>
                            <div className={styles.field}>
                                <input placeholder="Enter full address..." onChange={(e) => setFullAddress(e.target.value)} value={fullAddress} />
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.caption}>
                                Gender
                            </div>
                            <div className={styles.field}>
                                <button className={gender === "MALE" ? styles.activeBtn : styles.defaultBtn} onClick={() => setGender("MALE")} >Male</button>
                                <button className={gender === "FEMALE" ? styles.activeBtn : styles.defaultBtn} onClick={() => setGender("FEMALE")} >Female</button>
                                <button className={gender === "LGBTQ+" ? styles.activeBtn : styles.defaultBtn} onClick={() => setGender("LGBTQ+")} >LGBTQ+</button>
                                <button className={gender === "UNKNOWN" ? styles.activeBtn : styles.defaultBtn} onClick={() => setGender("UNKNOWN")} >Unknown</button>
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.caption}>
                                Purpose of Visit
                            </div>
                            <div className={styles.field}>
                                <button className={visitPurpose === "COURSE_ENQUIRY" ? styles.activeBtn : styles.defaultBtn} onClick={() => setVisitPurpose("COURSE_ENQUIRY")} >Course Enquiry</button>
                                <button className={visitPurpose === "FACULTY_INTERVIEW" ? styles.activeBtn : styles.defaultBtn} onClick={() => setVisitPurpose("FACULTY_INTERVIEW")} >Faculty Interview</button>
                            </div>
                        </div>

                        <div className={styles.submitBtnWrapper}>
                            {isFormValidated === true ?
                                <button className={styles.activeSubmitBtn} onClick={() => addANewVisitor()}>Submit</button>
                                :
                                <button className={styles.defaultSubmitBtn} onClick={() => alert("Please fill all the details correctly.")}>Submit</button>
                            }
                        </div>
                    </div>
                    :
                    formSubmittingStatus === "SUBMITTING" ?
                        <div className={styles.formWrapperStatus}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/loader-fast-trans.gif" />
                            <div>
                                Hold On!
                            </div>
                            <div>
                                I'm submitting your details!
                            </div>
                        </div>
                        : formSubmittingStatus === "SUBMITTED" ?
                            <div className={styles.formWrapperStatus}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/tick-check.webp" />
                                <div>
                                    Thank you for sharing details!
                                </div>
                                <div className={styles.addNewVisit}>
                                    <button onClick={() => {
                                        clearForm()
                                        setFormSubmittingStatus("NOT_SUBMITTING")
                                    }}>Add A New Visit</button>
                                </div>
                            </div>
                            :
                            <div className={styles.formWrapperStatus}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/failed-icon.png" />
                                <div>
                                    Submission Failed!
                                </div>
                                <div>
                                    Could not update the details, please try again!
                                </div>
                                <button className={styles.tryAgain} onClick={() => setFormSubmittingStatus("NOT_SUBMITTING")}>Try Again!</button>
                            </div>
                }
            </div>
        </div>
    )
}

export default AcademyNewVisitor