import React, { useContext, useEffect, useState } from 'react'
import { FaHome } from 'react-icons/fa'
import { MdHome } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './GSHome.module.css'

const GSHome = () => {
    const [key, setKey] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [suggestedData, setSuggestedData] = useState([])
    const [fetchingSuggestions, setFetchingSuggestions] = useState(false)
    const authCtx = useContext(AuthContext)

    const globalSearchSuggestions = async () => {
        setFetchingSuggestions(true)
        const globalSearchSuggestionsResponse = await fetch(BASE_URL_API + "/globalSearchSuggestions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    key: key
                })
            });

        if (!globalSearchSuggestionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const globalSearchSuggestionsRespo = await globalSearchSuggestionsResponse.json()
            if (globalSearchSuggestionsRespo.status === "success") {
                setSuggestedData(globalSearchSuggestionsRespo.response)
            } else {
                if (globalSearchSuggestionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setSuggestedData([])
                }
            }
        }
        setFetchingSuggestions(false)
    }

    useEffect(() => {
        if (key.length > 4) {
            setIsSearching(true)
            globalSearchSuggestions()
        } else {
            setIsSearching(false)
        }
    }, [key])

    console.log(suggestedData)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.contentWrapper}>
                <div className={isSearching === false ? styles.searchWrapper : styles.searchWrapperSearching} >
                    <input placeholder="Search for anything using phone number.. " autoFocus={true}
                        onChange={(e) => setKey(e.target.value)}
                    />
                    {/* <Link to={"/global-search/" + key}>Search</Link> */}
                </div>
                <div
                    className={`${styles.suggestedWrapper} ${isSearching ? styles.suggestedWrapperEntering : ''
                        }`}
                >
                    {fetchingSuggestions === true ?
                        <div className={styles.searching}>
                            Searching for best results...
                        </div>
                        :
                        <div>
                            {suggestedData.length > 0 ?
                                <div>
                                    {suggestedData?.map((item, index) => (
                                        <Link to={`/global-search/` + item?.phone} key={index}>
                                            <div className={styles.eachItem}>
                                                {item?.phone}
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                                :
                                <div className={styles.noResultsFound}>
                                    No results found!
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={styles.quickNav}>
                <Link to="/">
                    <div>
                        <FaHome size={30} color="#00000070" />
                    </div>
                    <div className={styles.navTitle}>
                        Home
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default GSHome