import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './LeadsAnalytics.module.css'

const LeadsAnalytics = () => {
    const authCtx = useContext(AuthContext)

    const [isGettingLeadSources, setIsGettingLeadSources] = useState(false)
    const [leadSources, setLeadSources] = useState([])

    const getAllStylistLeadSources = async () => {
        setIsGettingLeadSources(true)
        const getAllStylistLeadSourcesResponse = await fetch(BASE_URL_API + "/getAllStylistLeadSources",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx.userIdentifier
                })
            });

        if (!getAllStylistLeadSourcesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllStylistLeadSourcesRespo = await getAllStylistLeadSourcesResponse.json()
            if (getAllStylistLeadSourcesRespo.status === "success") {
                setLeadSources(getAllStylistLeadSourcesRespo.response)
            } else {
                if (getAllStylistLeadSourcesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setLeadSources([])
                }
            }
            console.log(getAllStylistLeadSourcesRespo)
        }
        setIsGettingLeadSources(false)
    }

    useEffect(() => {
        getAllStylistLeadSources()
    }, [])

    const getRate = (nr, dr) => {
        return parseFloat(parseFloat(parseFloat(nr) / parseFloat(dr)) * 100).toFixed(2)
    }


    if (authCtx.userIdentifier !== "999964N8406") {
        return (
            <div>
                Buddy, you are not authorized for this!
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>

            <div>
                <div>
                    LeadsAnalytics
                </div>
                <div>
                    {isGettingLeadSources === true ?
                        <div>
                            Hold on! Collecting leads....
                        </div>
                        :
                        <div>
                            <div className={styles.leadSourceContainer}>
                                {leadSources?.map((item, index) => (
                                    <div key={index} className={styles.eachLeadSource}>
                                        <div>
                                            <div className={styles.leadVolume}>
                                                {item?.totalLeads}
                                            </div>
                                            <div className={styles.leadSource}>
                                                {item?.leadSource !== '' ? item?.leadSource : 'UnIdentifier Sources'}
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                Leads Assigned - {item?.totalAssigned} - {getRate(item?.totalAssigned, item?.totalLeads)}%
                                            </div>

                                            <div>
                                                Leads Invalid - {item?.totalInvalid} - {getRate(item?.totalInvalid, item?.totalAssigned)}%
                                            </div>

                                            <div>
                                                Leads Converted - {item?.convertedLeads} - {getRate(item?.convertedLeads, item?.totalAssigned)}%
                                            </div>
                                        </div>
                                        <div>
                                            Missing leads
                                            {parseFloat(item?.totalLeads) - (parseFloat(item?.totalInvalid) + parseFloat(item?.convertedLeads))}
                                            -
                                            {getRate(parseFloat(parseFloat(item?.totalLeads) - (parseFloat(item?.totalInvalid) + parseFloat(item?.convertedLeads))), item?.totalLeads)}%
                                        </div>
                                        <div>
                                            Paid per lead - {11700/11000}
                                        
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </div>

            </div>


        </div>
    )
}

export default LeadsAnalytics