import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_API } from "../References/urls";
import AuthContext from "../store/auth-context";
import styles from "../Authorization/Login.module.css";


const Login = () => {
    const authCtx = useContext(AuthContext);

    const [showAnswer, setShowAnswer] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isValidating, setIsValidating] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [validatingStatus, setValidatingStatus] = useState('')

    const authHandler = async () => {
        setIsValidating(true)
        const authResponse = await fetch(BASE_URL_API + "/auth",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

        if (!authResponse.ok) {
            console.log("something went wrong : Server Error");
        } else {
            const authRespo = await authResponse.json();
            if (authRespo.status === "success") {
                setValidatingStatus('')
                authCtx.login(authRespo.authToken, authRespo.userIdentifier)
            } else {
                if (authRespo.message === "You've been abandoned from logging into the stylelink. Please contact your manager!") {
                    setValidatingStatus('abandoned')
                } else {
                    setValidatingStatus('')
                    alert("Invalid credentials!");
                }
                console.log(authRespo);
            }
        }
        setIsValidating(false)
    }

    useEffect(() => {
        function handleKeyPress(e) {
            if (e.key === 'Enter') {
                authHandler()
            }
        }

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.loginContainer}>
                    <div className={styles.loginHeader}>
                        Welcome to Stylelink
                    </div>
                    <div className={styles.loginBody}>
                        <div className={styles.lineOne}>
                            Buckle Up Buddy,
                        </div>
                        <div className={styles.lineTwo}>
                            We are building a realm,
                            <br></br>
                            opening multiverse doors into new dimensions.
                        </div>
                        <div className={styles.lineThree}>
                            It might take 14,000,605 efforts ;p, (give or take)
                            <div className={styles.results}>It's gonna give livelihood to millions & their families.</div>
                        </div>
                        <div className={styles.lineFour}>
                            And, we will do it.
                        </div>
                        <div className={styles.curiosBtnContainer}>
                            <span className={styles.curiosBtn} onClick={() => setShowAnswer(!showAnswer)} >
                                Curios How?
                            </span>
                        </div>
                        {showAnswer &&
                            <div className={styles.togetherLine}>
                                Like the old man says, <b>"Together"! </b>
                            </div>
                        }

                    </div>
                </div>
                <div className={styles.sidePanel}>
                    {validatingStatus === "abandoned" &&
                        <div className={styles.errorMsg}>
                            <div className={styles.errorMsgTitle}>
                                You've been abandoned from logging into the stylelink.
                            </div>
                            <div className={styles.errorMsgSubTitle}>
                                Please contact your manager!
                            </div>
                        </div>
                    }
                    <div className={styles.loginBoxContainer}>
                        <div className={styles.eachField}>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="Please enter username..."
                                    autoFocus={true}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className={styles.eachFieldLabel}>
                                Username
                            </div>
                        </div>
                        <div className={styles.eachField}>
                            <div className={styles.eachFieldInput}>
                                <input placeholder="Please enter password..."
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className={styles.eachFieldLabel}>
                                Passcode
                            </div>
                        </div>
                        {
                            isValidating === true ?
                                <div className={styles.loginBtn}>Validating...</div>
                                :
                                <button className={styles.loginBtn} onClick={() => authHandler()} >Login</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;