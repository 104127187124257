import { standardJobDescriptions } from "./jarvis-jr";

// Dictionary of keywords to handle misspellings

const keywords = {
    "beautician": "Beautician",
    "beutician": "Beautician",
    "beutyician": "Beautician",
    "beutiican": "Beautician",
    "buetishan": "Beautician",
    "byutician": "Beautician",
    "bayutician": "Beautician",
    "buetycian": "Beautician",
    "beaution": "Beautician",
    "byutishon": "Beautician",
    "beauty advisor": "Beauty Advisor",
    "beauty adviser": "Beauty Advisor",
    "beuty advisor": "Beauty Advisor",
    "beauti advisor": "Beauty Advisor",
    "bauty adwiser": "Beauty Advisor",
    "byuty adviser": "Beauty Advisor",
    "bayuty advisor": "Beauty Advisor",
    "buety adwiser": "Beauty Advisor",
    "beauty advisar": "Beauty Advisor",
    "bauty adwisar": "Beauty Advisor",
    "beauty consultant": "Beauty Consultant",
    "beuty consultent": "Beauty Consultant",
    "beauty consulant": "Beauty Consultant",
    "beauti consultan": "Beauty Consultant",
    "bauty konsultan": "Beauty Consultant",
    "byuty consultent": "Beauty Consultant",
    "bayuty consultent": "Beauty Consultant",
    "buety consultan": "Beauty Consultant",
    "beauty counsultant": "Beauty Consultant",
    "bauty konsultant": "Beauty Consultant",
    "beauty trainer": "Beauty Trainer",
    "beauty trainner": "Beauty Trainer",
    "beuty trainor": "Beauty Trainer",
    "beauti triner": "Beauty Trainer",
    "bauty trener": "Beauty Trainer",
    "byuty trainor": "Beauty Trainer",
    "bayuty trainor": "Beauty Trainer",
    "buety trainor": "Beauty Trainer",
    "beauty tainer": "Beauty Trainer",
    "bauty trinar": "Beauty Trainer",

    "female hair dresser": "Female Hair Dresser",
    "femail hair dresser": "Female Hair Dresser",
    "femal hair dresser": "Female Hair Dresser",
    "female hair dreser": "Female Hair Dresser",
    "phemale hair dresser": "Female Hair Dresser",
    "femal hare dresser": "Female Hair Dresser",
    "femal heer dresser": "Female Hair Dresser",
    "phemal hair dresser": "Female Hair Dresser",
    
    "female hair stylist": "Female Hair Stylist",
    "femail hair stylist": "Female Hair Stylist",
    "female hair stylst": "Female Hair Stylist",
    "femal hare stylist": "Female Hair Stylist",
    "femal hair stylist": "Female Hair Stylist",
    "femal heer stilist": "Female Hair Stylist",
    "phemale hair stylist": "Female Hair Stylist",

    "femal haer dresser": "Female Hair Dresser",
    "fimale hair dressar": "Female Hair Dresser",
    "fimail hair dreser": "Female Hair Dresser",

    "femal haer styler": "Female Hair Stylist",
    "fimale hair stylist": "Female Hair Stylist",
    "fimail hair stylis": "Female Hair Stylist",


    "mehandi artist": "Mehandi Artist",
    "mehandi artis": "Mehandi Artist",
    "mehandi artit": "Mehandi Artist",
    "mehandi artiste": "Mehandi Artist",
    
    "helper": "Helper",
    "helpar": "Helper",
    "maneger": "Manager",
    "managir": "Manager",
    "menejer": "Manager",
    "manejar": "Manager",
    
    "receptionist": "Receptionist",
    "receptionis": "Receptionist",
    
    
    "pedicurist": "Pedicurist",
    "pedicurest": "Pedicurist",
    "pedicur": "Pedicurist",
    "pedicuris": "Pedicurist",
    "pedycurest": "Pedicurist",
    
    
    "massage therepist": "Massage Therapist",
    "massaage therapist": "Massage Therapist",
    "massage therapist": "Massage Therapist",
    "massag therapis": "Massage Therapist",
    "massaj therapist": "Massage Therapist",
    "massge therapist": "Massage Therapist",
    "massag therapist": "Massage Therapist",
    "massaj theropist": "Massage Therapist",
    "massaje therapist": "Massage Therapist",
    
    "phemal hair stylis": "Female Hair Stylist",
    "mak-up artis": "Make-up Artist",
    
    "mehendi artiste": "Mehandi Artist",
    "mehendi artust": "Mehandi Artist",
    "mehend artist": "Mehandi Artist",
    "manicur": "Manicurist",
    "manicurust": "Manicurist",
    
    "beutty advicer": "Beauty Advisor",
    "beautyy consoltant": "Beauty Consultant",
    "beutty trener": "Beauty Trainer",
    "massaj terapist": "Massage Therapist",
    "nail arte trainar": "Nail Art Trainer",
    "pedicurust": "Pedicurist",
    "pedycarist": "Pedicurist",
    "pedeicurst": "Pedicurist",
    
    "resaptionist": "Receptionist",
    "receptionistt": "Receptionist",
    "receptioniste": "Receptionist",
    "receptioniest": "Receptionist",
    "receptionits": "Receptionist",
    "receptiosnst" : "Receptionist",
    
    "hair trainer": "Hair Trainer",
    "hair trainner": "Hair Trainer",
    "hair trainor": "Hair Trainer",
    "hair triner": "Hair Trainer",
    "hare trainor": "Hair Trainer",
    "heer trener": "Hair Trainer",
    "hair trener": "Hair Trainer",
    "haer trinar": "Hair Trainer",
    "hair treinar": "Hair Trainer",
    "hair trenar": "Hair Trainer",
   
    "nail art trainer": "Nail Art Trainer",
    "nael art trainner": "Nail Art Trainer",
    "nail art trainor": "Nail Art Trainer",
    "nail art triner": "Nail Art Trainer",
    "nael art trainor": "Nail Art Trainer",
    "nail art trener": "Nail Art Trainer",
    "nael art trener": "Nail Art Trainer",
    "nail arte trinar": "Nail Art Trainer",
    "nail art trainar": "Nail Art Trainer",
    
    "nail art technician": "Nail Art Technician",
    "nail artist": "Nail Art Technician",
    "nael art technician": "Nail Art Technician",
    "nail art tecnition": "Nail Art Technician",
    "nail art tecnician": "Nail Art Technician",
    "nael art techician": "Nail Art Technician",
    "nael art technitian": "Nail Art Technician",
    "nael art technician": "Nail Art Technician",
    "nail arte technician": "Nail Art Technician",
    "nail art technitian": "Nail Art Technician",
    "Nail Art / Technician": "Nail Art Technician",
   
    "manicurist": "Manicurist",
    "manicuris": "Manicurist",
    "manicurest": "Manicurist",
    "manycurest": "Manicurist",
    "menicurist": "Manicurist",
    "manycarist": "Manicurist",
    "manicuriste": "Manicurist",
   
    "Gents Hairdresser / Hairstylist" : "Gents Hair Dresser",
    "Gents Hair dresser / Hairstylist" : "Gents Hair Dresser",
    "Gents Hair dresser" : "Gents Hair Dresser",
    "Gents HairDresser" : "Gents Hair Dresser",
    "Gents Hairdresser / Hairstylist" : "Gents Hair Dresser",
    "Gents hair dresser" : "Gents Hair Dresser",

    "manager": "Manager",
    "manger": "Manager",
    "manajer": "Manager",
    "managar": "Manager",
    "managear": "Manager",
    "maniger": "Manager",
    "makeup artist": "Make-up Artist",
    "make-up artist": "Make-up Artist",
    "makup artist": "Make-up Artist",
    "make-up artis": "Make-up Artist",
    "make-up artest": "Make-up Artist",
    "makeeup artist": "Make-up Artist",
    "make-up artiste": "Make-up Artist",
    "makup artiste": "Make-up Artist",

    "makeup trainer": "Make-up Trainer",
    "make-up trainner": "Make-up Trainer",
    "makup trainor": "Make-up Trainer",
    "make-up triner": "Make-up Trainer",
    "mak-up trainor": "Make-up Trainer",
    "make-up trener": "Make-up Trainer",
    "makeeup trener": "Make-up Trainer",
    "mak-up trinar": "Make-up Trainer",
    "make-up trainar": "Make-up Trainer",
    "makup trenar": "Make-up Trainer",

    "unisex hair dresser": "Unisex Hair Dresser",
    "unisex hair stylist": "Unisex Hair Stylist",
    "unisex hare dresser": "Unisex Hair Dresser",
    "unisex hare stylist": "Unisex Hair Stylist",
    "unisex hair dressor": "Unisex Hair Dresser",
    "unisex hair stylis": "Unisex Hair Stylist",
    "unisex hair dreser": "Unisex Hair Dresser",
    "unisex hair stylit": "Unisex Hair Stylist",
    "unisex hair dressr": "Unisex Hair Dresser",
    "unisex hair stylst": "Unisex Hair Stylist",
    "unisex heer dresser": "Unisex Hair Dresser",
    "unisex heer stilist": "Unisex Hair Stylist",
    "unisex hair dressar": "Unisex Hair Dresser",
    "unisex hair stylar": "Unisex Hair Stylist",
    "unisex hair dresor": "Unisex Hair Dresser",
    "unisex haer dresser": "Unisex Hair Dresser",
    "unisex haer styler": "Unisex Hair Stylist",
    "unixex hair dressar": "Unisex Hair Dresser",
    "unisx hair dressar": "Unisex Hair Dresser",
    "unisx hair stylis": "Unisex Hair Stylist",
};




// Function to generate job description based on parameters
export const generateJobDescription = (jobTitle, jobType, experience, gender, minAge, maxAge, languageSpoken, educationQualification, salaryMin, salaryMax, incentive, foodAndAccomodation, requiredSkills) => {
    // Initialize an empty description string
    let description = "";

    // Split the job title by commas to handle multiple profiles
    const profiles = jobTitle?.split(", ");

    // Loop through each profile and generate the description
    profiles?.forEach(profile => {
        // Convert profile to lowercase and handle misspellings
        const formattedProfile = formatJobTitle(profile);

        // Find the standard job description for the provided profile
        const standardJob = standardJobDescriptions?.find(job => formattedProfile?.includes(job?.jobTitle?.toLowerCase()));
        if (!standardJob) {
            description += `<p>Job description not found for ${profile}.</p>`;
            return;
        }

        // Customize the standard job description based on parameters for the current profile
        description += `<div>${standardJob.description}</div>`;
        description += `<p style={{ text-transform : uppercase }}><strong>${profile} job description summary:</strong></p>`;
        description += `<ol>`;
        description += `<li>${experience==='Freshers' ? `Freshers can also apply for this job.`:  `You must be having ${experience} of experience.</li>`}`;
        description += `<li>${gender} of age between ${minAge} to ${maxAge} years are eligible for this job.</li>`;
        description += `<li>Candidates who speaks ${languageSpoken.join(", ")} are preferred!</li>`;
        description += `<li>Education Qualification: ${educationQualification}</li>`;

        description += `</ol>`; // Add newline between profiles

        // Group skills based on gender preference
        const groupedSkills = groupSkillsByGender(requiredSkills);

        // Generate skill description for the current profile
        if (groupedSkills?.length > 0) {
            description += `<strong>Skills Required:</strong>`;
            description += `<ul>`;
            groupedSkills.forEach(group => {
                const genderDescription = getGenderDescription(group.gender);
                description += `<li>${group.skills.join(", ")} ${genderDescription}</li>`;
            });
            description += `</ul>`;
        }

        description += `<strong>Salary, Incentives, and Perks:</strong>`;
        description += `<ul>`;
        description += `<li>Salary Range: ₹${salaryMin} - ₹${salaryMax}</li>`;
        description += `<li>Incentive: ${incentive !== '' ? incentive : `No incentive.`}</li>`;
        description += `<li>Food & Accommodation: ${foodAndAccomodation}</li>`;
        description += `</ul>`



    });

    return description;
}

// Function to format job title by converting to lowercase and handling misspellings
const formatJobTitle = (jobTitle) => {
    let formattedTitle = jobTitle.toLowerCase();
    // Replace misspelled keywords with correct ones
    for (const keyword in keywords) {
        formattedTitle = formattedTitle.replace(keyword, keywords[keyword].toLowerCase());
    }
    return formattedTitle;
}

// Function to group skills based on gender preference
const groupSkillsByGender = (requiredSkills) => {
    const groups = [];
    // Group skills based on gender preference
    requiredSkills.forEach(skill => {
        const existingGroup = groups.find(group => group.gender === skill.for_gender);
        if (existingGroup) {
            existingGroup.skills.push(skill.skill);
        } else {
            groups.push({ gender: skill.for_gender, skills: [skill.skill] });
        }
    });
    return groups;
}

// Function to get gender description based on for_gender value
const getGenderDescription = (forGender) => {
    switch (forGender) {
        case "1":
            return "for Male clients only.";
        case "2":
            return "for Female clients only.";
        default:
            return "for both Male and Female clients.";
    }
}
