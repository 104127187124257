import React, { useContext, useEffect, useState } from "react";
import styles from '../Components/EachJob.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import { BASE_URL_API } from "../References/urls";
import AuthContext from "../store/auth-context";

import Modal from 'react-modal';
import { sanitizeRoute } from "../Helper/helpers";
import MainLoader from "./MainLoader";
import { Base64 } from "js-base64";

import { useNavigate } from "react-router";
import EachJobHeader from "./ManageJobs/EachJobHeader";
import EachStoreAuthPersonnelDetails from "./Jobs/EachStoreAuthPersonnelDetails";
import EachJobDetails from "./ManageJobs/EachJobDetails";



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#dfdfdf'
    },
    overlay: {
        backgroundColor: 'rgb(0 0 0 / 52%)'
    }
};


const EachJob = props => {
    const authCtx = useContext(AuthContext);
    const getAllJobs = props.getAllJobs;
    let navigate = useNavigate()
    const jobData = props.jobData != null ? JSON.parse(props.jobData) : ""
    
    
    const [jobRoute, setJobRoute] = useState('');

    const [modalIsOpen, setIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [closeJobModal, setCloseJobModal] = useState(false);
    const [reasonOfJobClosure, setReasonOfJobClosure] = useState('')
    const [closingNotes, setClosingNotes] = useState('')
    const [activeTab, setActiveTab] = useState('JD') // JD
    const activeTabHandler = (k) => {
        setActiveTab(k)
    }
    // console.log(jobData);

    if (jobData == "") {
        return (
            <div className={styles.noJobSelected}>
                Please select a job to start!
            </div>
        )
    }

    let subtitle;


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const jobRouteHandler = (val) => {
        setJobRoute(sanitizeRoute(val));
    }

    const updateJobRoute = async () => {
        if (jobRoute.length > 2) {
            if (jobData.job_id != "") {
                setIsLoading(true);
                const updateJobRouteResponse = await fetch(BASE_URL_API + "/updateJobRoute",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            jobId: jobData.job_id,
                            jobRoute: jobRoute
                        })
                    });

                if (!updateJobRouteResponse.ok) {
                    alert("Something went wrong : Server Error!");
                } else {
                    const updateJobRouteRespo = await updateJobRouteResponse.json();
                    if (updateJobRouteRespo.status == "success") {
                        toast("Route updated successfully!");
                        closeModal();
                    } else {
                        toast("Route updation failed, please try again!");
                        console.log(updateJobRouteRespo);
                    }
                }
                setIsLoading(false)
            } else {
                alert("Invalid Job Id, Please try again!");
                console.log(jobData.job_id, jobRoute)
            }
        } else {
            alert("Invalid Job Route, please enter a valid route!");
            console.log(jobData.job_id, jobRoute)
        }
    }

    const closeJobHandler = () => {
        setCloseJobModal(true);
    }
    const closeJob = async () => {
        if (reasonOfJobClosure !== '') {
            setIsLoading(true);
            const closeJobResponse = await fetch(BASE_URL_API + "/toggleCOJob",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        jobId: jobData.job_id,
                        reasonOfClosing: reasonOfJobClosure,
                        closingNotes: closingNotes
                    })
                });

            if (!closeJobResponse.ok) {
                alert("Something went wrong : Server Error!");
            } else {
                const closeJobRespo = await closeJobResponse.json();
                if (closeJobRespo.status == "success") {
                    toast("Job successfully closed!!");
                } else {
                    toast("Failed, Please refresh and try again!");
                    console.log(closeJobRespo);
                }
                setCloseJobModal(false);
            }
            setIsLoading(false);
        } else {
            alert("Please select a valid reason to close the job!")
        }
    }


    return (
        <div>
            {isLoading && <MainLoader />}
            <ToastContainer />
            <div className={styles.upperSection}>
                <EachJobHeader
                    jobData={jobData}
                    getAllJobs={getAllJobs}
                    activeTab={activeTab}
                    activeTabHandler={activeTabHandler}
                />
            </div>
            <div className={styles.centerSection}>
                {activeTab==="JD" && <EachJobDetails jobData={jobData} />}
                {activeTab==="CD" && <EachStoreAuthPersonnelDetails storeIdentifier={jobData?.store_id} />}
            </div>
            <div className={styles.lowerSection}>
                <div className={styles.actionsContainer}>
                    <div className={styles.eachAction}>
                        Edit Job
                    </div>
                    <div className={styles.eachAction}
                        onClick={() => openModal()}
                    >
                        Update Route
                    </div>
                    <div className={styles.eachAction}>
                        Approve Job
                    </div>
                    {jobData.isClosed == '1' ?
                        <div className={styles.eachActionOpen}
                            onClick={() => closeJobHandler()}
                        >
                            Re-open Job
                        </div>
                        :
                        <div className={styles.eachActionClose}
                            onClick={() => closeJobHandler()}
                        >
                            Close Job
                        </div>}

                    <div className={styles.eachAction}>
                        Delete Job
                    </div>

                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>Update Job Route</div>
                <div className={styles.eachEditingField}>
                    <div>{jobRoute}</div>
                    <input
                        value={jobRoute}
                        placeholder="Enter the job route..."
                        onChange={(e) => jobRouteHandler(e.target.value)}
                    >
                    </input>
                    <div className={styles.actionBtns}>
                        <button onClick={() => closeModal()}>close</button>
                        <button onClick={() => updateJobRoute()}>Update Route</button>
                    </div>
                </div>
            </Modal>

            {closeJobModal &&
                <Modal
                    isOpen={closeJobModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={() => setCloseJobModal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        <div className={styles.reasonOfClosingWrapper}>
                            <select onChange={(e) => setReasonOfJobClosure(e.target.value)}>
                                <option value="">Select reason of closing this job!</option>
                                <option value="WRONG_JOB_POSTED">Wrong job posted</option>
                                <option value="HIRING_CLOSED_BY_STYLELINK">Hiring closed by stylelink</option>
                                <option value="HIRING_CLOSED_BY_OWNER">Hiring closed by owner</option>
                                <option value="COULD_NOT_FIND_THE_RIGHT_CANDIDATE">Could not find the right candidate</option>
                            </select>
                        </div>

                        <div className={styles.closingNotesWrapper}>
                            <textarea
                                placeholder="Please enter the closing notes..."
                                onChange={(e) => setClosingNotes(e.target.value)}
                            >

                            </textarea>
                        </div>

                        <div>Do you really want to {jobData.isClosed == '1' ? 're-open' : 'close'} this job?</div>
                        <div className={styles.eachEditingField}>
                            <div className={styles.actionBtnsBig}>
                                <button onClick={() => setCloseJobModal(false)}>No</button>
                                <button onClick={() => closeJob()}>Yes</button>
                            </div>
                        </div>
                    </div>
                </Modal>}

        </div>
    )
}

export default EachJob;