import { useEffect, useState } from "react";
import styles from '../JobApplications/CandidateApplicationConditionalQuestionnaire.module.css'

const questions = [{
    id: 1, text: "Have you contacted the candidate for this job?", options: [{ text: "Yes", nextQuestion: 2, },],
},
{
    id: 2,
    text: "Did you had the conversation with the candidate?",
    options: [
        {
            text: "Yes",
            nextQuestion: 4,
        },
        {
            text: "No",
            nextQuestion: 3,
        },
    ],
},
{
    id: 3,
    text: "Why could you not have the conversation with the candidate?",
    options: [
        {
            text: "Busy",
            nextQuestion: 4,
        },
        {
            text: "Wrong number",
            nextQuestion: 4,
        },
        {
            text: "Call disconnected",
            nextQuestion: 4,
        },
    ],
},
{
    id: 4,
    text: "Are you considering the candidate for this job?",
    options: [
        {
            text: "Yes",
            nextQuestion: 6,
        },
        {
            text: "No",
            nextQuestion: 5,
        },
    ],
},
{
    id: 5,
    text: "Why are you not considering the candidate for this job?",
    // options: [],
},
{
    id: 6,
    text: "Thank you for your response.",
    options: [],
},
];

const CandidateApplicationConditionalQuestionnaire = props => {
    const { activeCandidate, jobIdentifier, updateApplicationCreationActivity } = props
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        if (activeCandidate !== null) {
            setCurrentQuestionIndex(0)
            setAnswers([])
        }
    }, [activeCandidate, jobIdentifier])


    const currentQuestion = questions[currentQuestionIndex];
    const [reason, setReason] = useState('')

    const breadcrumbItems = questions
        .filter((item) => item.id <= currentQuestion.id)
        .map((item) => ({ id: item.id, text: item.text }));

    const handleAnswerClick = (answer) => {
        const newAnswer = { question: currentQuestion, answer };

        if (currentQuestion.text === "Have you contacted the candidate for this job?") {
            updateApplicationCreationActivity("CONTACTED", "Executive tried contacted the stylist for this profile.")
        } else if (currentQuestion.text === "Did you had the conversation with the candidate?") {
            if (answer.text === "Yes") {
                updateApplicationCreationActivity("HAD_CONVERSATION", "Executive had a conversation with the candidate.")
            }
        } else if (currentQuestion.text === "Why could you not have the conversation with the candidate?") {
            updateApplicationCreationActivity("COULD_NOT_HAD_CONVERSATION", answer.text)
        } else if (currentQuestion.text === "Are you considering the candidate for this job?") {
            if (answer.text === "Yes") {
                updateApplicationCreationActivity("PROFILE_CONSIDERED", "Candidate profile considered for this job.")
            }
        } else if (currentQuestion.text === "Why are you not considering the candidate for this job?") {
            updateApplicationCreationActivity("PROFILE_NOT_CONSIDERED", answer.text)
        }

        const updatedAnswers = [...answers];
        const existingAnswerIndex = updatedAnswers.findIndex(
            (answer) => answer.question.id === currentQuestion.id
        );
        if (existingAnswerIndex >= 0) {
            updatedAnswers[existingAnswerIndex] = newAnswer;
        } else {
            updatedAnswers.push(newAnswer);
        }
        setAnswers(updatedAnswers);
        if (answer.nextQuestion) {
            setCurrentQuestionIndex(answer.nextQuestion - 1);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handleBreadcrumbClick = (questionId) => {
        setCurrentQuestionIndex(questionId - 1);
    };

    const handleTextareaSubmit = () => {
        handleAnswerClick({ text: reason });
    };


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.breadCrumbWrapper}>
                {breadcrumbItems.map((item, index) => (
                    <div key={item.id}>
                        {index !== breadcrumbItems.length - 1 ? (
                            <button onClick={() => handleBreadcrumbClick(item.id)} className={styles.navBtn}>
                                {item.id}
                            </button>
                        ) : (
                            <span className={styles.navBtnDis}>{item.id}</span>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.questionsWrapper}>
                <div className={styles.question}>{currentQuestion.text}</div>
                {currentQuestion.options ? (
                    <div className={currentQuestion.options?.length > 2 ? styles.answersOptionWrapperCol : styles.answersOptionWrapperRow}>
                        {currentQuestion.options.map((option, index) => (
                            <div key={index} >
                                <button onClick={() => handleAnswerClick(option)}
                                    className={styles.optionBtn}
                                >
                                    {option.text}
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <textarea name="answer" rows="4" cols="50" onChange={(e) => setReason(e.target.value)} />
                        <br />
                        <button onClick={() => handleTextareaSubmit()} >Submit</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CandidateApplicationConditionalQuestionnaire;
