import React, { useContext, useEffect, useState } from 'react'
import { MdCall } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SalonLeadsDistributor.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SalonLeadsDistributor = () => {
    const authCtx = useContext(AuthContext)
    const [allLeads, setAllLeads] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [selectedLeads, setSelectedLeads] = useState([])
    const [allExe, setAllExe] = useState([])
    const [assignTo, setAssignTo] = useState('')
    const [isAssigning, setIsAssigning] = useState(false)

    const getSalonLeadsForDistribution = async () => {
        const getSalonLeadsForDistributionResponse = await fetch(BASE_URL_API + "/getSalonLeadsForDistribution",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!getSalonLeadsForDistributionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getSalonLeadsForDistributionRespo = await getSalonLeadsForDistributionResponse.json()
            if (getSalonLeadsForDistributionRespo.status === "success") {
                setAllLeads(getSalonLeadsForDistributionRespo.response)
                setFilteredData(getSalonLeadsForDistributionRespo.response)
            } else {
                if (getSalonLeadsForDistributionRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllLeads([])
                    setFilteredData([])
                }
            }
            console.log(getSalonLeadsForDistributionRespo)

        }

    }

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json();
            if (adminGetAllExecutivesRespo.status === 'success') {
                setAllExe(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllExe([])
                }
            }
        }

    }


    useEffect(() => {
        getSalonLeadsForDistribution().then(adminGetAllExecutives())
    }, [])

    useEffect(() => {
        setFilteredData(allLeads.filter(
            (item) =>
                item.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.profilesHiringFor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.addedBy?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.addedOn?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        )
    }, [searchTerm])


    const leadSelectorHandler = k => {
        setSelectedLeads(selectedLeads.includes(k) === true ? selectedLeads.filter((item) => item.ID !== k.ID) : [...selectedLeads, k])
    }

    function addInBulk(k) {
        setSelectedLeads(prevSelectedLeads => {
            // Remove any extra items beyond k
            const newSelectedLeads = prevSelectedLeads.slice(0, k);

            // Create a set of phones for the items already in selectedLeads
            const selectedPhones = new Set(newSelectedLeads.map(item => item.ID));

            // Add up to k new items from allLeads
            for (const item of filteredData) {
                if (!selectedPhones.has(item.ID)) {
                    newSelectedLeads.push(item);
                    selectedPhones.add(item.ID);
                    if (newSelectedLeads.length >= k) {
                        break;
                    }
                }
            }

            return newSelectedLeads;
        });
    }

    const assignNowHandler = async () => {
        setIsAssigning(true)
        console.log(authCtx.userIdentifier, selectedLeads, assignTo)
        const adminAssignSalonLeadsResponse = await fetch(BASE_URL_API + "/adminAssignSalonLeads",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    assigningTo: assignTo,
                    leadsToBeAssigned: selectedLeads,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminAssignSalonLeadsResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const adminAssignSalonLeadsRespo = await adminAssignSalonLeadsResponse.json()
            if (adminAssignSalonLeadsRespo.status === "success") {
                console.log(adminAssignSalonLeadsRespo)
                getSalonLeadsForDistribution()
                setSelectedLeads([])
                toast("Leads successfully assigned!");
            } else {
                if (adminAssignSalonLeadsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(adminAssignSalonLeadsRespo)
                }
            }
        }
        setIsAssigning(false)
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainHeader}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                </div>
                <div>
                    Salon Leads Distributor
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.filterWrapper}>
                    <div className={styles.searchBox}>
                        <input placeholder="Search for lead(s)..." onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                </div>
                <div className={styles.leadsWrapper}>
                    <div className={styles.leadsCol}>
                        <div className={styles.leadsSelectorWrapper}>
                            <div>
                                {searchTerm.length > 0 && filteredData.length + " / "} {allLeads.length} lead(s) available
                            </div>
                            <div className={styles.applyBulkBtns}>
                                <button onClick={() => setSelectedLeads([])}>Remove All</button>
                                <button onClick={() => addInBulk(5)}>All 5 leads</button>
                                <button onClick={() => addInBulk(10)}>All 10 leads</button>
                                <button onClick={() => addInBulk(15)}>All 15 leads</button>
                                <button onClick={() => addInBulk(20)}>All 20 leads</button>
                            </div>
                        </div>
                        <div className={styles.allLeadsWrapper}>
                            {filteredData?.map((item, index) => (
                                <div key={index} className={selectedLeads.includes(item) === true ? styles.eachLeadWrapperSelected : styles.eachLeadWrapper}
                                    onClick={() => leadSelectorHandler(item)}
                                >
                                    <div>
                                        <div className={styles.addedBy}>
                                            Added {humanReadableTimePassed(item?.addedOn)} by {item?.addedBy}
                                        </div>
                                        <div className={styles.phone}>
                                            <MdCall />&nbsp;{item.phone}
                                        </div>

                                        <div className={styles.twoColWrapper}>
                                            <div className={styles.elwProfilesWrapper}>
                                                {JSON.parse(item?.profilesHiringFor)?.map((itemProfiles, indexProfiles) => (
                                                    <div key={indexProfiles} className={styles.eachProfile}>{itemProfiles.category_name}</div>
                                                ))}
                                            </div>
                                            <div className={styles.elwCitiesWrapper}>
                                                {JSON.parse(item?.city)?.map((itemCity, indexCity) => (
                                                    <div key={indexCity} className={styles.eachCity}>{itemCity.location}</div>
                                                ))}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.assigningCol}>
                        <div className={styles.leadsSelectedWrapper}>
                            <div className={styles.selectedCount}>
                                {selectedLeads.length} leads selected
                            </div>

                            <div className={styles.assignToWrapper}>
                                <div>
                                    Assign To
                                </div>
                                <div className={styles.assignDropdown}>
                                    <select onChange={(e) => setAssignTo(e.target.value)}>
                                        <option value="">Select Executive</option>
                                        {allExe.map((item, index) => (
                                            <option key={index} value={item.userID} >{item.firstName}</option>
                                        ))}
                                    </select>
                                </div>
                                {selectedLeads.length > 0 && assignTo !== "" &&
                                    <div className={styles.assignNowBtn}>
                                        {isAssigning !== true && <button onClick={() => assignNowHandler()} >Assign Now</button>}
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default SalonLeadsDistributor