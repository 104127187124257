import React, { useState } from 'react'
import styles from '../../Styles/Components/Jobs/EligibilityCriteria.module.css'
import Collapsible from 'react-collapsible';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const EligibilityCriteria = props => {
    const [requiredQualificationsData, setRequiredQualificationsData] = useState([
        "Not Required", "10th", "12th", "Diploma", "Graduation", "Post-graduation"
    ]);
    const [experienceOptionsData, setExperienceOptionsData] = useState([
        "Freshers", "Less than 1 year", "1 Year and Above", "2 Years and Above", "3 Years and Above", "4 Years and Above", "5 Years and Above", "6 Years and Above", "7 Years and Above", "10 Years and Above"
    ])
    
    const [genderOptionsData, setGenderOptionsData] = useState([
        "Male Only", "Female Only", "Anyone"
    ])

    const selectedRequiredQuali = props.selectedRequiredQuali;
    const selectRequiredQualificationHandler = props.selectRequiredQualificationHandler
    const requiredExperience = props.requiredExperience;
    const experienceUpdateHandler = props.experienceUpdateHandler;
    const selectedGender = props.selectedGender;
    const selectGenderHandler = props.selectGenderHandler;
    const updateAgeHandler = props.updateAgeHandler;
    const minAge = props.minAge;
    const maxAge = props.maxAge;
    const languageOptions = props.languageOptions;
    const selectedLanguage = props.selectedLanguage;
    const languageChooseHandler = props.languageChooseHandler;

    return (
        <div className={styles.rcMainBody}>
            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Qualifications <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the required education qualification!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {requiredQualificationsData.map((item, index) => (
                            <div className={selectedRequiredQuali === item ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "C"} onClick={() => selectRequiredQualificationHandler(item)} >
                                <div className={styles.eachStoreTitle}>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Experience <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select how much years of experience you are looking for in a candidate...
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {experienceOptionsData.map((item, index) => (
                            <div className={requiredExperience === item ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "C"} onClick={() => experienceUpdateHandler(item)} >
                                <div className={styles.eachStoreTitle}>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Gender <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Select the gender who all can apply for this profile
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <div className={styles.eachFieldOptionContainer}>
                            {genderOptionsData.map((item, index) => (
                                <button className={selectedGender === item ? styles.eachFieldOptionSelected : styles.eachFieldOption} onClick={() => selectGenderHandler(item)} key={index}>
                                    {item}
                                </button>
                            ))}
                        </div>

                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Age <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Select the age range..
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        <div className={styles.eachFieldOptionContainer}>
                            <div>
                                <div className={styles.ageCaption}>
                                    Min Age
                                </div>
                                <div className={styles.numberOfOpeningsContainer}>
                                    <button className={styles.decBtn} onClick={() => updateAgeHandler("minAge", "desc")}>-</button>
                                    <button className={styles.count}>{minAge}</button>
                                    <button className={styles.incBtn} onClick={() => updateAgeHandler("minAge", "inc")}>+</button>
                                </div>
                            </div>
                            <div>
                                <div className={styles.ageCaption}>
                                    Max Age
                                </div>
                                <div className={styles.numberOfOpeningsContainer}>
                                    <button className={styles.decBtn} onClick={() => updateAgeHandler("maxAge", "desc")}>-</button>
                                    <button className={styles.count}>{maxAge}</button>
                                    <button className={styles.incBtn} onClick={() => updateAgeHandler("maxAge", "inc")}>+</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Collapsible>
            </div>

            <div className={styles.eachFieldContainer}>
                <Collapsible trigger={
                    <div className={styles.eachFieldHeaderContainer}>
                        <div className={styles.eachFieldHeaderTitle}>
                            Language Spoken <FaChevronDown />
                        </div>
                    </div>}
                >
                    <div className={styles.eachFieldHeaderSubTitle}>
                        Please select the language that the candidate should be familiar with!
                    </div>
                    <div className={styles.eachFieldBodyContainer}>
                        {languageOptions.map((item, index) => (
                            Array.isArray(selectedLanguage) === true ? (
                                <div className={selectedLanguage.includes(item.language_id) ? styles.eachStoreContainerSelected : styles.eachStoreContainer} key={index + "D"}
                                    onClick={() => languageChooseHandler(item.language_id)}
                                >
                                    <div className={styles.eachStoreTitle}>
                                        {item.language_id}
                                    </div>
                                </div>
                            )
                                :
                                (
                                    <div>
                                        ---- amazing ----
                                    </div>
                                )
                        ))}
                    </div>
                </Collapsible>
            </div>


        </div>
    )
}

export default EligibilityCriteria