import { Delete, DeleteOutline, Done, Save } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { BsSubtract } from 'react-icons/bs'
import { minMaxAgeHandler, sanitizeJobTitle } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SLCHiringForProfile.module.css'
import SLCProfileOptions from './SLCProfileOptions'
import SLCSkillsAndJD from './SLCSkillsAndJD'

const SLCHiringForProfile = props => {
    const authCtx = useContext(AuthContext)
    const { leadIdentifier } = props
    const [activeStore, setActiveStore] = useState('')
    const [profiles, setProfiles] = useState([])
    const [isProfileOptionOpen, setIsProfileOptionOpen] = useState(false)
    const toggleProfileOptions = () => setIsProfileOptionOpen(false)
    const [isFetchingDraftedProfiles, setIsFetchingDraftedProfiles] = useState(false)
    const [draftedProfiles, setDraftedProfiles] = useState([]) // list of the profiles created/saved as draft
    const [activeDraftedProfile, setActiveDraftedProfile] = useState(null)
    const [languageOptions, setLanguageOptions] = useState([])

    const [isSkillOpen, setIsSkillOpen] = useState(false)
    const toggleSkillOpen = () => setIsSkillOpen(false)

    const [jobTitle, setJobTitle] = useState('')
    const [experience, setExperience] = useState('')
    const [educationQualification, setEducationQualification] = useState('')
    const [jobType, setJobType] = useState('Full-Time')
    const [gender, setGender] = useState('')
    const [incentive, setIncentive] = useState('')
    const [minAge, setMinAge] = useState(14)
    const [maxAge, setMaxAge] = useState(45)
    const [salaryMin, setSalaryMin] = useState('')
    const [salaryMax, setSalaryMax] = useState('')
    const [languageSpoken, setLanguageSpoken] = useState([])
    const [fna, setFna] = useState('None')

    const [isCollectingDraftDetails, setIsCollectingDraftDetails] = useState(false)
    const [draftDetails, setDraftDetails] = useState(null)

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json()
            if (getCategoryOptionsRespo.status === "success") {
                setProfiles(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setProfiles([])
                }
            }
        }
    }

    const getLanguageOptions = async () => {
        const getLanguageOptionsResponse = await fetch(BASE_URL_API + "/getLanguageOptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getLanguageOptionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getLanguageOptionsRespo = await getLanguageOptionsResponse.json();
            if (getLanguageOptionsRespo.status === "success") {
                setLanguageOptions(getLanguageOptionsRespo.response);
            } else {
                if (getLanguageOptionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(getLanguageOptionsRespo);
                }
            }
        }
    }

    // console.log(activeDraftedProfile)
    // https://cdn.ecosmetics.com/2020/05/ku-loader.gif
    const getJobDrafts = async () => {
        setIsFetchingDraftedProfiles(true)
        const getJobDraftsResponse = await fetch(BASE_URL_API + "/getJobDrafts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier
                })
            });

        if (!getJobDraftsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDraftsRespo = await getJobDraftsResponse.json()
            if (getJobDraftsRespo.status === 'success') {
                setDraftedProfiles(getJobDraftsRespo.response)
            } else {
                if (getJobDraftsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setDraftedProfiles([])
                }
            }
            // console.log("job drafts ", getJobDraftsRespo.response)
        }
        setIsFetchingDraftedProfiles(false)
    }

    const getJobDraftDetails = async () => {
        setIsCollectingDraftDetails(true)
        const getJobDraftDetailsResponse = await fetch(BASE_URL_API + "/getJobDraftDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    itemIdentifier: activeDraftedProfile?.itemIdentifier
                })
            });

        if (!getJobDraftDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDraftDetailsRespo = await getJobDraftDetailsResponse.json();
            if (getJobDraftDetailsRespo.status === "success") {
                setDraftDetails(getJobDraftDetailsRespo.response)
                setJobTitle(getJobDraftDetailsRespo?.response?.title)
                setJobType(getJobDraftDetailsRespo?.response?.openingType)
                setExperience(getJobDraftDetailsRespo?.response?.experience)
                setGender(getJobDraftDetailsRespo?.response?.gender)
                setMinAge(getJobDraftDetailsRespo?.response?.ageMin==='0' ? 18 : parseInt(getJobDraftDetailsRespo?.response?.ageMin))
                setMaxAge(getJobDraftDetailsRespo?.response?.ageMax==='0' ? 45 : parseInt(getJobDraftDetailsRespo?.response?.ageMax))
                setIncentive(getJobDraftDetailsRespo?.response?.incentives)
                setSalaryMin(getJobDraftDetailsRespo?.response?.salaryMin)
                setSalaryMax(getJobDraftDetailsRespo?.response?.salaryMax)
                setFna(getJobDraftDetailsRespo?.response?.accomodation)
                setLanguageSpoken(getJobDraftDetailsRespo?.response?.languageSpoken!=="" ? getJobDraftDetailsRespo?.response?.languageSpoken?.split(",") : [])
            } else {
                if (getJobDraftDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setDraftDetails(null)
                }
            }
            // console.log(getJobDraftDetailsRespo)
        }
        setIsCollectingDraftDetails(false)
    }

    const languageSpokenHandler = (k) => {
        if(k!=='') {
            setLanguageSpoken(languageSpoken.includes(k) ? (languageSpoken.filter(item => item !== k)) : [...languageSpoken, k])
            console.log(k)
        }
    }

    useEffect(() => {
        getCategoryOptions().then(() => getLanguageOptions())
    }, [])

    useEffect(() => {
        getJobDrafts()
    }, [leadIdentifier])

    useEffect(() => {
        activeDraftedProfile!== null && getJobDraftDetails()
    }, [activeDraftedProfile?.itemIdentifier])

    const updateJobDraft = async () => {
        const updateJobDraftResponse = await fetch(BASE_URL_API+"/updateJobDraft",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                leadIdentifier : leadIdentifier,
                itemIdentifier : activeDraftedProfile?.itemIdentifier,
                jobType : jobType,
                title : jobTitle,
                experience : experience,
                salaryMin : salaryMin,
                salaryMax : salaryMax,
                gender : gender,
                educationQualifications : educationQualification,
                accomodation : fna,
                ageMin : minAge,
                ageMax : maxAge,
                incentives : incentive,
                languageSpoken : languageSpoken?.join(",")
            })
        });

        if(!updateJobDraftResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateJobDraftRespo = await updateJobDraftResponse.json()
            if(updateJobDraftRespo.status==="success") {

            } else {
                if(updateJobDraftRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateJobDraftRespo)
        }
    }
    
    console.log("this is active ", leadIdentifier)
    console.log("this is active profile ", activeDraftedProfile)


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.storeWrapper}>
                <button className={activeStore === 'DEFAULT' ? styles.eachStoreItemActive : styles.eachStoreItem}
                    onClick={() => setActiveStore('DEFAULT')}
                >
                    UnAssigned
                </button>
                <button className={activeStore === 'STORE1' ? styles.eachStoreItemActive : styles.eachStoreItem}
                    onClick={() => setActiveStore('STORE1')}
                >
                    Store - 1
                </button>
                <button className={activeStore === 'STORE2' ? styles.eachStoreItemActive : styles.eachStoreItem}
                    onClick={() => setActiveStore('STORE2')}
                >
                    Store - 2
                </button>
            </div>
            <div className={styles.profilesWrapper}>
                <SLCProfileOptions
                    leadIdentifier={leadIdentifier}
                    profiles={profiles}
                    isProfileOptionOpen={isProfileOptionOpen}
                    toggleProfileOptions={toggleProfileOptions}
                    getJobDrafts={getJobDrafts}
                />
                <div className={styles.createdProfiles}>
                    {draftedProfiles.length > 0 ?
                        draftedProfiles.map((item, index) => (
                            <button key={index} className={activeDraftedProfile?.itemIdentifier === item?.itemIdentifier ? styles.addedProfileActive : styles.addedProfile}
                                onClick={() => setActiveDraftedProfile(item)}
                            >
                                {item?.jobCategoryName} ({item?.numberOfOpenings})
                            </button>
                        ))
                        :
                        <div className={styles.notAddedAnyProfileTxt}>You haven't added any job profile yet.</div>
                    }
                </div>
                <div className={styles.addProfileBtn}>
                    <button onClick={() => setIsProfileOptionOpen(true)}>Add Profile +</button>
                </div>
            </div>
            {draftedProfiles.length > 0 ?
                <>
                    {activeDraftedProfile === null ?
                        <div className={styles.bodyWrapper}>
                            <div className={styles.leftColumn}>
                                <div className={styles.pleaseSelectProfileTxt}>
                                    Please select a job profile to get draft details.
                                </div>
                            </div>
                            <div className={styles.rightColumn}>
                            </div>
                        </div>
                        :
                        <>
                            {isCollectingDraftDetails === false ?
                                <div className={styles.bodyWrapper}>
                                    <div className={styles.leftColumn}>

                                        <div className={styles.jobTitleRow}>
                                            <div className={styles.twoInARow}>
                                                <div>
                                                    <div className={styles.sectionTitle}>
                                                        Job Title
                                                    </div>
                                                    <div className={styles.inputWrapper}>
                                                        <input
                                                            className={styles.jobTitle}
                                                            placeholder="Please enter job title..."
                                                            autoFocus={true}
                                                            onChange={(e) => setJobTitle(sanitizeJobTitle(e.target.value))}
                                                            value={jobTitle}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={styles.sectionTitle}>
                                                        Job Type
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => setJobType("Full-Time")}
                                                            className={jobType === "Full-Time" ? styles.activeBtnJt : styles.defaultBtnJt}
                                                        >
                                                            Full-Time
                                                        </button>
                                                        <button
                                                            onClick={() => setJobType("Part-Time")}
                                                            className={jobType === "Part-Time" ? styles.activeBtnJt : styles.defaultBtnJt}
                                                        >
                                                            Part-Time
                                                        </button>
                                                        <button
                                                            onClick={() => setJobType("Internship")}
                                                            className={jobType === "Internship" ? styles.activeBtnJt : styles.defaultBtnJt}
                                                        >
                                                            Internship
                                                        </button>
                                                        <button
                                                            onClick={() => setJobType("Contractual")}
                                                            className={jobType === "Contractual" ? styles.activeBtnJt : styles.defaultBtnJt}
                                                        >
                                                            Contractual
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.experienceRow}>
                                            <div className={styles.sectionTitle}>
                                                Experience
                                            </div>
                                            <div>
                                                <button className={experience === 'Freshers' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('Freshers')}
                                                >
                                                    Freshers
                                                </button>
                                                <button className={experience === 'Less than 1 year' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('Less than 1 year')}
                                                >
                                                    Less than 1 year
                                                </button>
                                                <button className={experience === '1 Year and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('1 Year and Above')}
                                                >
                                                    1 Year and Above
                                                </button>
                                                <button className={experience === '2 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('2 Years and Above')}
                                                >
                                                    2 Years and Above
                                                </button>
                                                <button className={experience === '3 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('3 Years and Above')}
                                                >
                                                    3 Years and Above
                                                </button>
                                                <button className={experience === '4 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('4 Years and Above')}
                                                >
                                                    4 Years and Above
                                                </button>
                                                <button className={experience === '5 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('5 Years and Above')}
                                                >
                                                    5 Years and Above
                                                </button>
                                                <button className={experience === '6 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('6 Years and Above')}
                                                >
                                                    6 Years and Above
                                                </button>
                                                <button className={experience === '7 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('7 Years and Above')}
                                                >
                                                    7 Years and Above
                                                </button>
                                                <button className={experience === '10 Years and Above' ? styles.activeBtnExp : styles.defaultBtn}
                                                    onClick={() => setExperience('10 Years and Above')}
                                                >
                                                    10 Years and Above
                                                </button>
                                            </div>
                                        </div>

                                        <div className={styles.genderRow}>
                                            <div className={styles.twoInARow}>
                                                <div className={styles.genderSection}>
                                                    <div className={styles.sectionTitle}>
                                                        Who can apply for the job? Gender
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => setGender("Male Only")}
                                                            className={gender === 'Male Only' ? styles.activeBtnGen : styles.defaultBtnGen}>Male Only
                                                        </button>
                                                        <button
                                                            onClick={() => setGender("Female Only")}
                                                            className={gender === 'Female Only' ? styles.activeBtnGen : styles.defaultBtnGen}>Female Only
                                                        </button>
                                                        <button
                                                            onClick={() => setGender("Anyone")}
                                                            className={gender === 'Anyone' ? styles.activeBtnGen : styles.defaultBtnGen}>Anyone
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={styles.ageSection}>
                                                    <div className={styles.sectionTitle}>
                                                        Age
                                                    </div>
                                                    <div className={styles.ageRow}>
                                                        <div className={styles.eachAgeRow}>
                                                            <div className={styles.subTitle}>
                                                                Min Age
                                                            </div>
                                                            <div className={styles.addOnBtn}>
                                                                <div className={styles.ageCounterBtn}>
                                                                    <button onClick={() => setMinAge(minMaxAgeHandler(minAge, maxAge, "dec", "minAge"))}>-</button>
                                                                </div>
                                                                <div className={styles.ageCounterBtn}>{minAge}</div>
                                                                <div className={styles.ageCounterBtn}>
                                                                    <button onClick={() => setMinAge(minMaxAgeHandler(minAge, maxAge, "inc", "minAge"))}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.eachAgeRow}>
                                                            <div className={styles.subTitle}>
                                                                Max Age
                                                            </div>
                                                            <div className={styles.addOnBtn}>
                                                                <div className={styles.ageCounterBtn}>
                                                                    <button onClick={() => setMaxAge(minMaxAgeHandler(minAge, maxAge, "dec", "maxAge"))}>-</button>
                                                                </div>
                                                                <div className={styles.ageCounterBtn}>{maxAge}</div>
                                                                <div className={styles.ageCounterBtn}>
                                                                    <button onClick={() => setMaxAge(minMaxAgeHandler(minAge, maxAge, "inc", "maxAge"))} >+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.languageRow}>
                                            <div className={styles.sectionTitle}>
                                                Language Spoken
                                            </div>
                                            <div>
                                                {languageOptions.map((item, index) => (
                                                    <button key={index} className={languageSpoken.includes(item?.language_id) ? styles.activeBtnLang : styles.defaultBtn}
                                                        onClick={() => languageSpokenHandler(item?.language_id)}
                                                    >
                                                        {item?.language_id}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>

                                        <div className={styles.educationRow}>
                                            <div className={styles.sectionTitle}>
                                                Education Qualifications
                                            </div>
                                            <div>
                                                <button onClick={() => setEducationQualification("Not Required")}
                                                    className={educationQualification === "Not Required" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    Not Required
                                                </button>
                                                <button onClick={() => setEducationQualification("10th")}
                                                    className={educationQualification === "10th" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    10th
                                                </button>
                                                <button onClick={() => setEducationQualification("12th")}
                                                    className={educationQualification === "12th" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    12th
                                                </button>
                                                <button onClick={() => setEducationQualification("Diploma")}
                                                    className={educationQualification === "Diploma" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    Diploma
                                                </button>
                                                <button onClick={() => setEducationQualification("Graduation")}
                                                    className={educationQualification === "Graduation" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    Graduation
                                                </button>
                                                <button onClick={() => setEducationQualification("Post-graduation")}
                                                    className={educationQualification === "Post-graduation" ? styles.activeBtnEq : styles.defaultBtnEq}
                                                >
                                                    Post-graduation
                                                </button>
                                            </div>

                                        </div>

                                        <div className={styles.salaryIncentiveRow}>
                                            <div className={styles.twoInARow}>
                                                <div>
                                                    <div className={styles.sectionTitle}>
                                                        Salary
                                                    </div>
                                                    <div className={styles.salaryRow}>
                                                        <div className={styles.eachSalaryCard}>
                                                            <div className={styles.subTitle}>Minimum Salary</div>
                                                            <input className={styles.salaryInput} value={salaryMin} onChange={(e) => setSalaryMin(e.target.value)} placeholder="Enter minimum salary..." />
                                                        </div>
                                                        <div className={styles.eachSalaryCard}>
                                                            <div className={styles.subTitle}>Maximum Salary</div>
                                                            <input className={styles.salaryInput} value={salaryMax} onChange={(e) => setSalaryMax(e.target.value)} placeholder="Enter maximum salary..." />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className={styles.sectionTitle}>
                                                        Incentive
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => setIncentive(1)}
                                                            className={incentive === 1 ? styles.activeBtnInc : styles.defaultBtnInc}
                                                        >
                                                            Yes
                                                        </button>
                                                        <button
                                                            onClick={() => setIncentive(0)}
                                                            className={incentive === 0 ? styles.activeBtnInc : styles.defaultBtnInc}
                                                        >
                                                            No
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className={styles.fnaRow}>
                                            <div className={styles.sectionTitle}>
                                                Food & Accomodation
                                            </div>
                                            <div>
                                                <button className={fna === 'None' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('None')}
                                                >
                                                    None
                                                </button>
                                                <button className={fna === '1 - time Food Only' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('1 - time Food Only')}
                                                >
                                                    1 - time Food Only
                                                </button>
                                                <button className={fna === '2 - time Food Only' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('2 - time Food Only')}
                                                >
                                                    2 - time Food Only
                                                </button>
                                                <button className={fna === 'Accomodation Only' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('Accomodation Only')}
                                                >
                                                    Accomodation Only
                                                </button>
                                                <button className={fna === '1 - time Food with Accomodation' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('1 - time Food with Accomodation')}
                                                >
                                                    1 - time Food with Accomodation
                                                </button>
                                                <button className={fna === '2 - time Food with Accomodation' ? styles.activeBtnLang : styles.defaultBtn}
                                                    onClick={() => setFna('2 - time Food with Accomodation')}
                                                >
                                                    2 - time Food with Accomodation
                                                </button>
                                            </div>
                                        </div>

                                        <div className={styles.skillRow}>
                                            <SLCSkillsAndJD 
                                                isSkillOpen={isSkillOpen} 
                                                toggleSkillOpen={toggleSkillOpen} 
                                                profiles={profiles} 
                                                leadIdentifier={activeDraftedProfile?.leadIdentifier}
                                                itemIdentifier={activeDraftedProfile?.itemIdentifier}
                                            />
                                            <div>
                                                <button onClick={() => setIsSkillOpen(true)} >Set Skills & Create JD</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.rightColumn}>
                                        <button className={styles.saveBtn} onClick={() => updateJobDraft()}>
                                            <Save size={24} />
                                        </button>
                                        <button className={styles.doneBtn}>
                                            <Done size={24} />
                                        </button>
                                        <button className={styles.deleteBtn}>
                                            <DeleteOutline size={24} />
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className={styles.bodyWrapper}>
                                    <div className={styles.leftColumn}>
                                        Hold On!
                                        I'm collecting draft details
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
                :


                <div className={styles.bodyWrapper}>
                    <div className={styles.leftColumn}>
                    </div>
                    <div className={styles.rightColumn}>
                    </div>
                </div>


            }
        </div>
    )
}

export default SLCHiringForProfile