import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './MpaStores.module.css'

const MpaStores = props => {
    const { vendorIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [isFetchingStores, setIsFetchingStores] = useState(false)
    const [stores, setStores] = useState([])
    const [storeName, setStoreName] = useState('')
    const [storeAddress, setStoreAddress] = useState('')
    const [isCreatingStore, setIsCreatingStore] = useState(false)

    const getAllVendorsStoreList = async () => {
        setIsFetchingStores(true)
        const getAllVendorsStoreListResponse = await fetch(BASE_URL_API + "/getAllVendorsStoreList",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!getAllVendorsStoreListResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllVendorsStoreListRespo = await getAllVendorsStoreListResponse.json()
            if (getAllVendorsStoreListRespo.status === "success") {
                setStores(getAllVendorsStoreListRespo.response)
            } else {
                if (getAllVendorsStoreListRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStores([])
                }
            }

            console.log(getAllVendorsStoreListRespo)
        }
        setIsFetchingStores(false)
    }

    useEffect(() => {
        getAllVendorsStoreList()
    }, [vendorIdentifier])

    const createNewStore = async () => {
        setIsCreatingStore(true)
        const createNewStoreResponse = await fetch(BASE_URL_API + "/createNewStore",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier,
                    storeName: storeName,
                    storeAddress: storeAddress,
                    createdBy : authCtx.userIdentifier
                })
            });

        if (!createNewStoreResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewStoreRespo = await createNewStoreResponse.json()
            if (createNewStoreRespo.status === "success") {
                setStoreName('')
                setStoreAddress('')
                getAllVendorsStoreList()
                toast("Successfully created the store!")
            } else {
                if (createNewStoreRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(createNewStoreRespo.message)
                }
            }
            console.log(createNewStoreRespo)
        }
        setIsCreatingStore(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.leftWrapper}>
                {isFetchingStores === true ?
                    <div>
                        Fetching stores.. .
                    </div>
                    :
                    <div >
                        <div className={styles.lwHeader}>
                            {stores?.length} stores available!
                        </div>
                        <div className={styles.lwBody}>
                            {stores?.length > 0 ?
                                <div >
                                    {stores?.map((item, index) => (
                                        <Link to={`/manage-stores/${item?.store_id}`} key={index}>
                                            <div className={styles.eachStoreWrapper}>
                                                <div>
                                                    {item?.company_name}
                                                </div>
                                                <div className={styles.storeAddress}>
                                                    {item?.address}
                                                </div>
                                                {item?.store_id}
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                                :
                                <div>
                                    No stores found!
                                </div>
                            }
                        </div>
                        <div className={styles.lwFooter}>
                            <button>Create A New Store</button>
                        </div>

                    </div>
                }
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.formWrapper}>

                    <div className={styles.formHeader}>
                        Create A New Store
                    </div>
                    <div className={styles.formContainer}>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Please enter store name</div>
                            <div className={styles.fieldBox}>
                                <input 
                                    onChange={(e) => setStoreName(e.target.value)}
                                    className={styles.storeName} autoFocus={true} 
                                    placeholder="Please enter the complete store name" 
                                />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.fieldCaption}>Please enter store address</div>
                            <div className={styles.fieldBox}>
                                <input 
                                    onChange={(e) => setStoreAddress(e.target.value)}
                                    placeholder="Please enter the complete store address" 
                                />
                            </div>
                        </div>

                        <div>
                            {isCreatingStore === true ?
                                <button>Hold On! Creating now...</button>
                                :
                                <div className={styles.actionBtnWrapper}>
                                    {storeName.length > 3 && storeAddress.length > 10 ?
                                        <button onClick={() => createNewStore()} className={styles.createNewActive}>Create Now!</button>
                                        :
                                        <button onClick={() => alert("Please fill all the details..")} className={styles.createNewDull}>Create Now</button>
                                    }
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MpaStores