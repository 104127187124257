import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../JobApplications/RelevantProfilesToJobFilters.module.css'


const RelevantProfilesToJobFilters = props => {
    const authCtx = useContext(AuthContext);
    let isOpen = props.isOpen;
    const toggleOpener = props.toggleOpener;
    let selectedJob = props.selectedJob;
    const isFinding = props.isFinding;
    const isFindingToggle = props.isFindingToggle;
    const updateProfileDataHandler = props.updateProfileDataHandler;
    const profilesData = props.profilesData;
    const bulkPostingSuccessful = props.bulkPostingSuccessful;
    const toggleBulkPostingSuccessful = props.toggleBulkPostingSuccessful;
    const [minSalary, setMinSalary] = useState(selectedJob.salary_min)
    const [maxSalary, setMaxSalary] = useState(selectedJob.salary_max)
    const [minAge, setMinAge] = useState(selectedJob.age_min)
    const [maxAge, setMaxAge] = useState(selectedJob.age_max)

    const counting = Array.from({ length: 67 }, (_, index) => index + 10);

    const [sortBy, setSortBy] = useState('DISTANCE')

    const salaryCustomHandler = (k, v) => {
        if (k === "min") {
            setMinSalary(v)
        }
        if (k === "max") {
            setMaxSalary(v)
        }
    }

    const customAgeHandler = (k, v) => {
        if(k==="minAge") {
            setMinAge(v)
        }
        if(k==="maxAge") {
            setMaxAge(v)
        }
    }

    // console.log(selectedJob)

    const getRelevantProfilesToJob = async () => {
        isFindingToggle(true)
        const getRelevantProfilesToJobResponse = await fetch(BASE_URL_API + "/getRelevantProfilesToJob",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: selectedJob.job_id,
                    sortBy: sortBy,
                    minAge : minAge,
                    maxAge : maxAge,
                    minSalary: minSalary,
                    maxSalary: maxSalary
                })
            });

        if (!getRelevantProfilesToJobResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getRelevantProfilesToJobRespo = await getRelevantProfilesToJobResponse.json();
            if (getRelevantProfilesToJobRespo.status === 'success') {
                if (getRelevantProfilesToJobRespo.response !== null) {
                    updateProfileDataHandler(getRelevantProfilesToJobRespo.response)
                } else {
                    updateProfileDataHandler([]);
                }
                toggleBulkPostingSuccessful(false);
            } else {
                if (getRelevantProfilesToJobRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(getRelevantProfilesToJobRespo)
                }
            }

        }
        isFindingToggle(false)
    }

    useEffect(() => {
        if (bulkPostingSuccessful) {
            getRelevantProfilesToJob();
        }
    }, [bulkPostingSuccessful])

    useEffect(() => {
        getRelevantProfilesToJob();
    }, [sortBy])

    useEffect(() => {
        setMinSalary(selectedJob.salary_min)
        setMaxSalary(selectedJob.salary_max)
        setMinAge(selectedJob.age_min)
        setMaxAge(selectedJob.age_max)
    }, [selectedJob])



    return (
        <div>
            <Collapsible
                trigger={
                    <div className={styles.filterHeader}>
                        <div>
                            {profilesData.length} profiles found!
                        </div>
                        <button className={styles.showHideBtn}>
                            {isOpen === true ? "Hide " : "Show "} Filters
                        </button>
                    </div>
                }
                onOpening={() => toggleOpener(true)}
                onClosing={() => toggleOpener(false)}
            >
                <div className={styles.collapsibleContentWrapper}>
                    <div className={styles.leftContainer}>
                        <div className={styles.filtersContainer}>
                            <div className={styles.twoInOne}>
                                <div className={styles.ageFilter}>
                                    <div>
                                        <div className={styles.ageTitle}>
                                            Min Age
                                        </div>
                                        <select value={minAge}
                                            onChange={(e) => customAgeHandler("minAge", e.target.value) }
                                        >
                                            {counting.map((item, index) => (
                                                <option key={index}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <div className={styles.ageTitle}>
                                            Max Age
                                        </div>
                                        <select value={maxAge} 
                                            onChange={(e) => customAgeHandler("maxAge", e.target.value) } 
                                        >
                                            {counting.map((item, index) => (
                                                <option key={index}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.genderFilter}>
                                    <div className={styles.genderTitle}>
                                        Who can apply
                                    </div>
                                    <div className={styles.genderValue}>
                                        <button>
                                            {selectedJob.gender}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.salaryFilter}>
                                <div className={styles.eachSalary}>
                                    <div className={styles.salaryTitle}>
                                        Salary Min - &#8377;{selectedJob.salary_min}
                                    </div>
                                    <input placeholder="enter min salary" value={minSalary} onChange={(e) => salaryCustomHandler("min", e.target.value)} />
                                </div>
                                <div className={styles.eachSalary}>
                                    <div className={styles.salaryTitle}>
                                        Salary Max - &#8377;{selectedJob.salary_max}
                                    </div>
                                    <input placeholder="enter max salary" value={maxSalary} onChange={(e) => salaryCustomHandler("max", e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.getDataBtnWrapper}>
                            <button onClick={() => getRelevantProfilesToJob()}>Get Data</button>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <button onClick={() => setSortBy('DISTANCE_NEAREST_FIRST')}
                            className={sortBy === 'DISTANCE_NEAREST_FIRST' ? styles.sortingBtnSelected : styles.sortingBtn}
                        >
                            Distance
                        </button>
                        <button onClick={() => setSortBy('SALARY_EXPECTATION_HIGHEST_FIRST')}
                            className={sortBy === 'SALARY_EXPECTATION_HIGHEST_FIRST' ? styles.sortingBtnSelected : styles.sortingBtn}
                        >
                            Salary Expectation (Higher)
                        </button>
                        <button onClick={() => setSortBy('SALARY_EXPECTATION_LOWEST_FIRST')}
                            className={sortBy === 'SALARY_EXPECTATION_LOWEST_FIRST' ? styles.sortingBtnSelected : styles.sortingBtn}
                        >
                            Salary Expectation (Lower)
                        </button>
                    </div>
                </div>
            </Collapsible>
        </div>
    )
}

export default RelevantProfilesToJobFilters