import React from 'react'
import { getAge, humanReadableTimePassed } from '../../../Helper/helpers'
import styles from './CAEJWMEachProfile.module.css'

const CAEJWMEachProfile = (props) => {
    const { item, activeProfile, activeProfileToggler } = props
    return (
        <div className={styles.eachProfileWrapper} onClick={() => activeProfileToggler(item)}>
            <div>
                <span className={styles.stylist_name}>{item?.stylist_name}, </span>
                <span className={styles.stylist_gender}>{item?.stylist_gender}, {item?.maritalStatus}, {getAge(item?.stylist_birthdate)} years old</span>
            </div>
            <div className={styles.registeredTxt}>
                Registered {humanReadableTimePassed(item?.registred_on)} on stylelink!
            </div>
            <div>
                {item?.contact_number}
            </div>
            <div>
                {item?.currentLocation}
            </div>
            <div className={styles.domainWrapper}>
                <div className={styles.eachDomain}>
                    {item?.primaryWorkDomain}
                </div>
                {item?.secondaryWorkDomain &&
                    <div className={styles.eachDomain}>
                        {item?.secondaryWorkDomain}
                    </div>
                }
                {item?.tertiaryWorkDomain &&
                    <div className={styles.eachDomain}>
                        {item?.tertiaryWorkDomain}
                    </div>
                }
            </div>
            <div>
                {parseFloat(item?.distance).toFixed(2)} kms
            </div>
            <div>
                ₹{item?.minimumSalaryExpectation}
            </div>
        </div>
    )
}

export default CAEJWMEachProfile