import React, { useContext, useEffect, useState } from 'react'
import styles from '../Analytics/PlotDataForStylistRegistration.module.css'
import { Area, AreaChart, Bar, BarChart, Brush, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';

const { innerWidth, height } = window;

const PlotDataForStylistRegistration = () => {
    const authCtx = useContext(AuthContext)

    const [data, setData] = useState([])
    const [chartType, setChartType] = useState('TOTAL_REGISTRATION')

    const kpisForRecruitmentStylistPlot = async () => {
        const kpisForRecruitmentStylistPlotResponse = await fetch(BASE_URL_API + "/kpisForRecruitmentStylistPlot",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!kpisForRecruitmentStylistPlotResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const kpisForRecruitmentStylistPlotRespo = await kpisForRecruitmentStylistPlotResponse.json();
            if (kpisForRecruitmentStylistPlotRespo.status === "success") {
                // setData(kpisForRecruitmentStylistPlotRespo.response.slice(-7))
                setData(kpisForRecruitmentStylistPlotRespo.response)
            } else {
                if (kpisForRecruitmentStylistPlotRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setData([])
                }
            }

            // console.log(kpisForRecruitmentStylistPlotRespo)
        }
    }

    useEffect(() => {
        kpisForRecruitmentStylistPlot()
    }, [])

    console.log(data)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <div className={styles.mainTitleWrapper}>
                        <div>
                            Stylist Registration Summary
                        </div>
                    </div>


                    <ResponsiveContainer width="100%" height={350}>
                        <AreaChart
                            width={500}
                            height={350}
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {chartType === "TOTAL_REGISTRATION" &&
                                <Area type="monotone" dataKey="total_registered" stackId="1" stroke="#8884d8" fill="#8884d8" />
                            }
                            {chartType === "GROWTH_RATE" &&
                                <Area type="monotone" dataKey="total_growth_rate" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                            }
                            {
                                chartType === "REGISTRATION_BREAKDOWN" &&
                                <>
                                    <Area type="monotone" dataKey="registered_via_blank" stackId="1" stroke="#ffc658" fill="#ffc658" />
                                    <Area type="monotone" dataKey="registered_via_admin" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                </>
                            }
                            <Brush />
                        </AreaChart>
                    </ResponsiveContainer>

                </div>
                <div className={styles.operatingWrapper}>
                    <button className={chartType==="TOTAL_REGISTRATION" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("TOTAL_REGISTRATION")}>Show total</button>
                    <button className={chartType==="REGISTRATION_BREAKDOWN" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("REGISTRATION_BREAKDOWN")}>Show Break-down</button>
                    <button className={chartType==="GROWTH_RATE" ? styles.activeBtn : styles.defBtn} onClick={() => setChartType("GROWTH_RATE")}>Show Growth Rate</button>
                </div>

            </div>
        </div>
    )
}

export default PlotDataForStylistRegistration