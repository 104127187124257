import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../ManageDemographics/DgCities.module.css'

const DgCities = props => {
    const authCtx = useContext(AuthContext);
    let selectedState = props.selectedState;
    const selectCityHandler = props.selectCityHandler;
    const selectedCity = props.selectedCity;

    const [isFetchingCitiesData, setIsFetchingCitiesData] = useState(true)
    const [citiesData, setCitiesData] = useState([])

    const [city, setCity] = useState('')

    const dgAddCity = async () => {
        setIsFetchingCitiesData(true);
        const dgAddCityResponse = await fetch(BASE_URL_API+"/dgAddCities",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                stateIdentifier : selectedState.stateIdentifier,
                city : city
            })
        });

        if(!dgAddCityResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgAddCityRespo = await dgAddCityResponse.json();
            if(dgAddCityRespo.status==="success") {
                dgGetCities();
            } else {
                if (dgAddCityRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(dgAddCityRespo.response)
                }
            }
        }
    }

    const dgGetCities = async () => {
        setIsFetchingCitiesData(true)
        const dgGetCitiesResponse = await fetch(BASE_URL_API + "/dgGetCities",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stateIdentifier: selectedState.stateIdentifier
                })
            });

        if (!dgGetCitiesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const dgGetCitiesRespo = await dgGetCitiesResponse.json();
            if (dgGetCitiesRespo.status === 'success') {
                setCitiesData(dgGetCitiesRespo.response);
            } else {
                if (dgGetCitiesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setCitiesData([]);
                    console.log("Get cities response : ", dgGetCitiesRespo);
                }
            }
        }
        setIsFetchingCitiesData(false)
    }

    useEffect(() => {
        if (selectedState !== null) {
            dgGetCities();
        }
    }, [selectedState])


    console.log(selectedCity)

    return (
        <div className={styles.mainWrapper}>
            {selectedState != null ?
                <div>
                    {isFetchingCitiesData === false ?
                        citiesData.length > 0 ?
                            <div>
                                <div className={styles.header}>
                                    Cities
                                </div>
                                <div className={styles.body}>
                                    {citiesData.map((item, index) => (
                                        <div className={selectedCity===item ? styles.eachCardWrapperSelected : styles.eachCardWrapper}
                                            key={index}
                                            onClick={() => selectCityHandler(item)}
                                        >
                                            {item.city}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            onChange={(e) => setCity(e.target.value)}
                                            value={city}
                                            placeholder="Please enter city name..."
                                        />
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <button
                                            onClick={() => dgAddCity()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={styles.header}>
                                    Cities
                                </div>
                                <div className={styles.body}>
                                    No city has been added for this state!
                                </div>
                                <div className={styles.footer}>
                                    <div className={styles.inputWrapper}>
                                        <input
                                            onChange={(e) => setCity(e.target.value)}
                                            value={city}
                                            placeholder="Please enter city name..."
                                        />
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <button
                                            onClick={() => dgAddCity()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        :
                        <div>
                            <div className={styles.header}>
                                Cities
                            </div>
                            <div className={styles.body}>
                                Fetching cities data ...
                            </div>
                            <div className={styles.footer}>
                            </div>
                        </div>
                    }
                </div>
                :
                <div>
                    <div className={styles.header}>
                        Cities
                    </div>
                    <div className={styles.body}>
                        Please select a state!
                    </div>
                    <div className={styles.footer}>
                    </div>
                </div>
            }
        </div>
    )
}

export default DgCities