import React, { useContext, useEffect, useState } from 'react'
import { FaMoneyBill, FaMoneyBillAlt, FaTelegramPlane } from 'react-icons/fa'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getAge, humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../EscalatedCase/EscalatedCaseJobs.module.css'

const EscalatedCaseJobs = () => {
    const authCtx = useContext(AuthContext)
    const params = useParams()
    const activeCaseIdentifer = params.caseIdentifier

    const [cases, setCases] = useState([])
    const [fetchingCaseData, setFetchingCaseData] = useState(false)
    const [activeCaseData, setActiveCaseData] = useState(null)

    const [response, setResponse] = useState('')
    const getEscalatedCaseForAdmin = async () => {
        const gecfaResponse = await fetch(BASE_URL_API + "/getEscalatedCaseForAdmin",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!gecfaResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const gecfaRespo = await gecfaResponse.json()
            if (gecfaRespo.status === "success") {
                setCases(gecfaRespo.response)
            } else {
                if (gecfaRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCases([])
                }
            }
            console.log(gecfaRespo)
        }
    }

    useEffect(() => {
        getEscalatedCaseForAdmin()
    }, [])

    const getEscalatedCaseInfo = async () => {
        setFetchingCaseData(true)
        const getEscalatedCaseInfoResponse = await fetch(BASE_URL_API + "/getEscalatedCaseInfo",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    caseIdentifier: activeCaseIdentifer
                })
            });

        if (!getEscalatedCaseInfoResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEscalatedCaseInfoRespo = await getEscalatedCaseInfoResponse.json();
            if (getEscalatedCaseInfoRespo.status === "success") {
                setActiveCaseData(getEscalatedCaseInfoRespo.response)
            } else {
                if (getEscalatedCaseInfoRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setActiveCaseData(null)
                }
            }
            console.log("escalated case info ", getEscalatedCaseInfoRespo)
        }
        setFetchingCaseData(false)
    }

    useEffect(() => {
        if (activeCaseIdentifer !== null) {
            getEscalatedCaseInfo()
        }
    }, [activeCaseIdentifer])


    const sendResponseHandler = async () => {
        const temp = { ID: "x", caseIdentifier: activeCaseIdentifer, remarks: response, addedOn: "2023-05-04 23:23:00", msgType: "INCOMING" }
        setActiveCaseData([...activeCaseData, temp])
        addEscalatedCaseResponse()
    }

    const addEscalatedCaseResponse = async () => {
        const aecrResponse = await fetch(BASE_URL_API + "/addEscalatedCaseResponse",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    caseIdentifier: activeCaseIdentifer,
                    remarks: response,
                    msgType: "INCOMING",
                    subject: "URGENTLY_NEED_JOB"
                })
            });

        if (!aecrResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const aecrRespo = await aecrResponse.json();
            if (aecrRespo.status === "success") {
                setResponse("")
                getEscalatedCaseInfo()
            } else {
                if (aecrRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(aecrRespo);
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.mhTitle}>
                    <Link to="/">Home</Link>
                </div>
                <div className={styles.mhTitle}>
                    View All Escalated Cases
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.leftCol}>
                    <div className={styles.caseListFilter}>

                    </div>
                    <div className={styles.caseListBody}>
                        {cases.map((item, index) => (
                            <div className={activeCaseIdentifer === item.caseIdentifier ? styles.eachCaseWrapperSelected : styles.eachCaseWrapper}>
                                <Link to={"/escalated-case/jobs/" + item.caseIdentifier} key={index} >
                                    <div>
                                        <div className={styles.caseIdentifier}>
                                            {item.caseIdentifier}
                                        </div>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.name}>
                                                {item.stylist_name}
                                            </div>
                                            <div className={styles.age}>
                                                ({getAge(item.stylist_birthdate)} yo)
                                            </div>
                                            <div className={styles.maritalStatus}>
                                                {item.maritalStatus},
                                            </div>
                                            <div className={styles.gender}>
                                                {item.stylist_gender}
                                            </div>

                                        </div>
                                        <div className={styles.address}>
                                            {item.currentLocation}
                                        </div>
                                        <div>
                                            <span className={styles.primaryWorkDomain}>
                                                {item.primaryWorkDomain}
                                            </span>
                                            {item.secondaryWorkDomain &&
                                                <span className={styles.secondaryWorkDomain}>
                                                    {item.secondaryWorkDomain}
                                                </span>
                                            }
                                            {item.tertiaryWorkDomain &&
                                                <span className={styles.tertiaryWorkDomain}>
                                                    {item.tertiaryWorkDomain}
                                                </span>
                                            }
                                        </div>
                                        <div className={styles.salaryExpectation}>
                                            &#8377;{item.minimumSalaryExpectation}
                                        </div>
                                    </div>

                                    <div className={styles.eachRow}>
                                        <div className={styles.eachCaseLeft}>
                                            <div className={styles.timeStampOn}>
                                                On Stylelink since : {item.profileCreatedOn.substring(0, 10)}
                                            </div>
                                        </div>
                                        <div className={styles.eachCaseRight}>
                                            <div className={styles.timeStamp}>
                                                {humanReadableTimePassed(item.addedOn)}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.rightCol}>
                    {activeCaseIdentifer === "null" ?
                        <div className={styles.centerCol}>
                            <div className={styles.noCaseSelected}>
                                Please select a case to start..
                            </div>
                        </div>
                        :
                        fetchingCaseData === true ?
                            <div className={styles.centerCol}>
                                <div className={styles.noCaseSelected}>
                                    Hold On..
                                    I'm collecting case infos...
                                </div>
                            </div>
                            :
                            activeCaseData === null ?
                                <div className={styles.centerCol}>
                                    <div className={styles.noCaseSelected}>
                                        No relevant data available
                                    </div>
                                </div>
                                :
                                <div className={styles.centerCol}>
                                    <div className={styles.ccuContainer}>
                                        <div className={styles.fullHead}>
                                            <div>
                                                --
                                            </div>
                                            <div className={styles.viewCompleteProfile}>
                                                <Link target="_blank" to={"/manage-users/grow/" + activeCaseData?.[0]?.stylistIdentifier} >
                                                    <button>View Complete Profile</button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div>
                                            <a href={"/jobs-finder/" + activeCaseData?.[0]?.stylistIdentifier} target="_blank">
                                                <button>Find Jobs For candidate</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                    }


                    <div className={styles.extremeRightCol}>
                        {fetchingCaseData === true ?
                            <div className={styles.chatWrapper}>
                                <div className={styles.chatContainer}>
                                    <div className={styles.eachInMsgWrapper}>
                                        <div className={styles.eachInMsgContainer}>
                                            <div className={styles.msgInTxt}>

                                            </div>
                                            <div className={styles.timeLoader}>
                                                -
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachInMsgWrapper}>
                                        <div className={styles.eachInMsgContainer}>
                                            <div className={styles.msgInTxt}>
                                                -
                                            </div>
                                            <div className={styles.timeLoader}>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachOutMsgWrapper}>
                                        <div className={styles.eachOutMsgContainer}>
                                            <div className={styles.msgOutTxtLoader}>
                                                This case escalated sections is designed to
                                                support candidates looking for jobs actively.
                                            </div>
                                            <div className={styles.timeLoader}>
                                                always together by admin
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.eachInMsgWrapper}>
                                        <div className={styles.eachInMsgContainer}>
                                            <div className={styles.msgInTxt}>
                                                -
                                            </div>
                                            <div className={styles.timeLoader}>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.eachOutMsgWrapper}>
                                        <div className={styles.eachOutMsgContainer}>
                                            <div className={styles.msgOutTxtLoader}>
                                                The idea behind this is to make them place immediately
                                                so that they won't suffer in their day-to-day livelihoods.
                                                here, you can reply...
                                            </div>
                                            <div className={styles.timeLoader}>
                                                always together by admin
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className={styles.composeWrapper}>

                                </div>
                            </div>
                            :
                            <div className={styles.chatWrapper}>
                                <div className={styles.chatContainer}>
                                    {activeCaseData?.map((item, index) => (
                                        <div className={item.msgType === "INCOMING" ? styles.eachOutMsgWrapper : styles.eachInMsgWrapper} key={index}>
                                            <div className={item.msgType === "INCOMING" ? styles.eachOutMsgContainer : styles.eachInMsgContainer}>
                                                <div className={item.msgType === "INCOMING" ? styles.msgOutTxt : styles.msgInTxt}>
                                                    {item.remarks}
                                                </div>
                                                <div className={styles.time}>
                                                    {humanReadableTimePassed(item.addedOn)} by {item.actionBy}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.composeWrapper}>
                                    <textarea placeholder="type here..."
                                        onChange={(e) => setResponse(e.target.value)}
                                        value={response}
                                    >

                                    </textarea>
                                    <div className={styles.sendBtn}>
                                        <button onClick={() => sendResponseHandler()}>
                                            <FaTelegramPlane size={25} color="#da1884" />
                                        </button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>


                </div>
            </div>
        </div>
    )
}

export default EscalatedCaseJobs