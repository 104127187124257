import React, { useContext, useEffect, useState } from 'react'
import { IoRemoveSharp } from 'react-icons/io5';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbSkillAddNewHolder.module.css'
import EachSkillToBeAddedWrapper from './EachSkillToBeAddedWrapper';

const RbSkillAddNewHolder = props => {
  let selectedUser = props.selectedUser;
  const getAddedData = props.getAddedData;
  const [fetchingRawData, setFetchingRawData] = useState(true)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedGenderInOptions, setSelectedGenderInOptions] = useState('Male')
  const [skillsForMaleOptions, setSkillsForMaleOptions] = useState([])
  const [skillsForFemaleOptions, setSkillsForFemaleOptions] = useState([])
  const authCtx = useContext(AuthContext)

  const [itemsToBeAddedMale, setItemsToBeAddedMale] = useState([])
  const [itemsToBeAddedFemale, setItemsToBeAddedFemale] = useState([])

  const [uploadingStatus, setUploadingStatus] = useState('NOT_UPLOADING'); // UPLOADING, DONE, FAILED
  const [uploadingStatusMessage, setUploadingStatusMessage] = useState(["Let's go!"]);

  const toggleItemHandler = (k) => {
    if (selectedGenderInOptions === 'Male') {
      if (itemsToBeAddedMale.includes(k) === true) {
        setItemsToBeAddedMale(itemsToBeAddedMale.filter((items) => items !== k));
      } else {
        setItemsToBeAddedMale([...itemsToBeAddedMale, k]);
      }
    } else if (selectedGenderInOptions === 'Female') {
      if (itemsToBeAddedFemale.includes(k) === true) {
        setItemsToBeAddedFemale(itemsToBeAddedFemale.filter((items) => items !== k));
      } else {
        setItemsToBeAddedFemale([...itemsToBeAddedFemale, k]);
      }
    }
  }

  const getCategoryOptions = async () => {
    setFetchingRawData(true)
    const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
      {
        method: 'POST',
        body: JSON.stringify({
          token: authCtx.token
        })
      });

    if (!getCategoryOptionsResponse.ok) {
      console.log("Something went wrong : Server Error")
    } else {
      const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
      if (getCategoryOptionsRespo.status === 'success') {
        setCategoryOptions(getCategoryOptionsRespo.response);
      } else {
        if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
          authCtx.logout();
        } else {
          console.log(getCategoryOptionsRespo);
        }
      }
      setFetchingRawData(false)
    }
  }

  useEffect(() => {
    getCategoryOptions();
  }, [])


  const getRawData = async () => {
    setFetchingRawData(true);
    const getRawDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
      {
        method: 'POST',
        body: JSON.stringify({
          token: authCtx.token,
          stylist_id: selectedUser.stylist_id,
          dataType: 'raw',
          sectionType: 'skills'
        })
      });

    if (!getRawDataResponse.ok) {
      console.log("Something went wrong : Server Error!");
    } else {
      const getRawDataRespo = await getRawDataResponse.json();
      if (getRawDataRespo.status == 'success') {
        setSkillsForMaleOptions(getRawDataRespo.skillResMale);
        setSkillsForFemaleOptions(getRawDataRespo.skillResFemale);

      } else {
        console.log(getRawDataRespo)
      }
    }
    setFetchingRawData(false)
  }

  useEffect(() => {
    getRawData()
  }, [selectedUser])


  const submitSkillsHandler = () => {
    setUploadingStatus('UPLOADING');
    addSkills('1', itemsToBeAddedMale).then(() => addSkills('2', itemsToBeAddedFemale)).finally(() => setUploadingStatus('NOT_UPLOADING'));
  }


  const addSkills = async (brand_id, skill_id) => {
    if (brand_id === '1') {
      document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Collecting all skills to be uploaded for male</div>`

      if (skill_id.length > 0) {
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Found ` + skill_id.length + `+ skills for male to be upload...</div>`
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Alright, I'm uploading skills for male now...</div>`
        const addSkillsResponse = await fetch(BASE_URL_API + "/addMoreSections",
          {
            method: 'POST',
            body: JSON.stringify({
              token: authCtx.token,
              stylist_id: selectedUser.stylist_id,
              dataType: 'addNew',
              sectionType: 'skills',
              brand_id: brand_id,
              skill_id: JSON.stringify(skill_id)
            })
          });

        if (!addSkillsResponse.ok) {
          console.log("Something went wrong : server error!");
        } else {
          const addSkillsRespo = await addSkillsResponse.json();
          if (addSkillsRespo.status === 'success') {
            document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: green; font-weight: 600">Successfully uploaded the skills for male...</div>`
            setItemsToBeAddedMale([])
            document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Now, I'm ready to upload skills for female</div>`
          } else {
            console.log(addSkillsRespo);
          }
        }
      } else {
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: red; font-weight: 600">Umff.. I haven't found any skills for male to be uploaded... </div>`
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Going for female skills now...</div>`
      }


    } else if (brand_id === '2') {
      document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: grey; font-weight: 600">Hold on, just collecting all the skills to be uploaded for female</div>`

      if (skill_id.length > 0) {
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Found ` + skill_id.length + `+ skills for female to be upload...</div>`
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin: 10px 10px; color: blue; font-weight: 600">Alright, I'm uploading skills for female now...</div>`
        const addSkillsResponse = await fetch(BASE_URL_API + "/addMoreSections",
          {
            method: 'POST',
            body: JSON.stringify({
              token: authCtx.token,
              stylist_id: selectedUser.stylist_id,
              dataType: 'addNew',
              sectionType: 'skills',
              brand_id: brand_id,
              skill_id: JSON.stringify(skill_id)
            })
          });

        if (!addSkillsResponse.ok) {
          console.log("Something went wrong : server error!");
        } else {
          const addSkillsRespo = await addSkillsResponse.json();
          if (addSkillsRespo.status === 'success') {
            document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style="margin : 10px; color: green; font-weight : 600">Successfully uploaded the skills for female...</div>`;
            setUploadingStatus('DONE')
            setItemsToBeAddedFemale([])
            getAddedData().then(() => getRawData());
          } else {
            setUploadingStatus('NOT_UPLOADING');
            console.log(addSkillsRespo);
          }
        }
      } else {
        document.getElementById("uploadingStatusMessageSkills").innerHTML += `<div style='margin : 10px; color: red; font-weight : 600'>Umff.. I have not found any skills for female to be uploaded...</div>`
        getAddedData().then(() => getRawData());
      }
    }
  }


  return (
    <>
      <div className={styles.rightHeaderWrapper}>
        Add a new skill
      </div>
      <div className={styles.rightBodyWrapper}>
        <div className={styles.toggleSwitchWrapperInOptions}>
          <button
            onClick={() => setSelectedGenderInOptions('Generic')}
          >
            Generic Skills (0)
          </button>
          <button className={selectedGenderInOptions === 'Male' ? styles.toggleBtnSelected : styles.toggleBtn}
            onClick={() => setSelectedGenderInOptions('Male')}
          >
            Skills For Male ({skillsForMaleOptions.length})
          </button>
          <button className={selectedGenderInOptions === 'Female' ? styles.toggleBtnSelected : styles.toggleBtn}
            onClick={() => setSelectedGenderInOptions('Female')}
          >
            Skills For Female ({skillsForFemaleOptions.length})
          </button>
        </div>

        {fetchingRawData === true ?
          <div>
            Fetching skills options...
          </div>
          :
          <div>

            <div className={uploadingStatus === 'UPLOADING' ? styles.uploadingStatusWrapperShow : styles.uploadingStatusWrapperHide}>
              <div>The skills are being uploaded...</div>
              <div id="uploadingStatusMessageSkills">
                <div>
                  Let's go!
                </div>
              </div>
            </div>

            <div className={uploadingStatus === 'UPLOADING' ? styles.skillsWrapperHide : styles.skillsWrapperShow}>
              {categoryOptions.map((item, index) =>
              (
                <EachSkillToBeAddedWrapper
                  item={item}
                  key={index}
                  selectedGenderInOptions={selectedGenderInOptions}
                  skillsForMaleOptions={skillsForMaleOptions}
                  skillsForFemaleOptions={skillsForFemaleOptions}
                  toggleItemHandler={toggleItemHandler}
                  itemsToBeAddedMale={itemsToBeAddedMale}
                  itemsToBeAddedFemale={itemsToBeAddedFemale}
                />
              )

              )}
            </div>

          </div>
        }

      </div>
      <div className={styles.rightFooterWrapper}>
        <div className={styles.rightFooterContainer}>
          <button className={styles.secondaryBtn}>Cancel</button>
          {uploadingStatus === 'UPLOADING' ?
            <div className={styles.primaryBtn} >Are being uploaded...</div>
            :
            uploadingStatus === 'NOT_UPLOADING' ?
              <button className={styles.primaryBtn} onClick={() => submitSkillsHandler()} >Save</button>
              :
              uploadingStatus === 'DONE' ?
                <div>Successfully updated!</div>
                :
                <div>-</div>
          }
        </div>
      </div>
    </>


  )
}

export default RbSkillAddNewHolder