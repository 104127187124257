import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './EachMiniCourseLead.module.css'

const EachMiniCourseLead = props => {
    const { leadIdentifier, item, currentLeadHandler } = props

    useEffect(() => {
        if(leadIdentifier===item.leadIdentifier) {
            currentLeadHandler(item)
        }
    }, [leadIdentifier])
    
    return (
        <Link to={`/course-leads-converter/` + item?.leadIdentifier} 
            className={leadIdentifier===item.leadIdentifier ? styles.eachLeadWrapperActive : styles.eachLeadWrapper} 
        >
            <div className={styles.fullName}>
                {item?.fullName.trim()} - {item?.gender}
            </div>
            <div className={styles.location}>
                {item?.location}
            </div>
            <div className={styles.assignedOn}>
                {item?.assignedOn}
            </div>
        </Link>
    )
}

export default EachMiniCourseLead