import React, { useContext, useEffect, useState } from 'react'
import { MdCheckCircle, MdCheckCircleOutline } from 'react-icons/md'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './JobDetailsForFinder.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5'
import AllStoreConversations from '../Conversations/AllStoreConversations'

const JobDetailsForFinder = props => {
    const authCtx = useContext(AuthContext)
    const { jobDetails, stylistIdentifier } = props
    const [details, setDetails] = useState(null)
    const [skillAnalyzerData, setSkillAnalyzerData] = useState([])
    const [isApplying, setIsApplying] = useState(false)
    const [appliedResponse, setAppliedResponse] = useState(null)

    const [isScHolderOpen, setIsScHolderOpen] = useState(false)
    const onClosingScHolder = () => setIsScHolderOpen(false)


    const getOpeningDetailsForJobFinder = async () => {
        const getOpeningDetailsForJobFinderResponse = await fetch(BASE_URL_API + "/getOpeningDetailsForJobFinder",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobDetails?.job_id,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getOpeningDetailsForJobFinderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getOpeningDetailsForJobFinderRespo = await getOpeningDetailsForJobFinderResponse.json()
            if (getOpeningDetailsForJobFinderRespo.status === "success") {
                setDetails(getOpeningDetailsForJobFinderRespo?.response?.JobDetails)
                setSkillAnalyzerData(getOpeningDetailsForJobFinderRespo?.response?.skillAnalyzerResponse)
            } else {
                if (getOpeningDetailsForJobFinderRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setDetails(null)
                }
            }
            console.log(getOpeningDetailsForJobFinderRespo)
        }
    }


    useEffect(() => {
        jobDetails !== null && getOpeningDetailsForJobFinder()
        setAppliedResponse(null)
    }, [jobDetails?.job_id])

    const applyNow = async () => {
        setIsApplying(true)
        const applyNowForJobIndividualResponse = await fetch(BASE_URL_API + "/applyNowForJobIndividual",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier,
                    jobIdentifier: jobDetails?.job_id,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!applyNowForJobIndividualResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const applyNowForJobIndividualRespo = await applyNowForJobIndividualResponse.json()
            if (applyNowForJobIndividualRespo.status === "success") {
                toast("Successfully created the application!")
                setAppliedResponse(applyNowForJobIndividualRespo.response)
            } else {
                if (applyNowForJobIndividualRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    if (applyNowForJobIndividualRespo.message === "already applied") {
                        toast("Already applied for the job!")
                    } else {
                        console.log(applyNowForJobIndividualRespo)
                    }
                }
            }
            console.log(applyNowForJobIndividualRespo)
        }
        setIsApplying(false)
    }

    return (
        <div className={styles.mainWrapper}>
            {isScHolderOpen && <AllStoreConversations
                isOpen={isScHolderOpen} 
                storeIdentifier={details?.store_id} 
                onClose={onClosingScHolder} 
                jobIdentifier={details?.job_id}
            />}
            {jobDetails === null ?
                <div className={styles.header}>Please select a job to start!</div>
                :
                <div className={styles.header}>
                    <div>
                        <div className={styles.topRowWrapper}>
                            <div>
                                {details?.title}
                                {details?.opening_type}
                            </div>
                            <div className={styles.ageExpWrapper}>
                                <div className={styles.fieldWrapper}>
                                    <div className={styles.fieldCaption}>
                                        Age :
                                    </div>
                                    <div className={styles.fieldData}>
                                        <div>{details?.age_min}</div> -
                                        <div>{details?.age_max}</div>
                                    </div>
                                </div>
                                <div className={styles.fieldWrapper}>
                                    <div className={styles.fieldCaption}>
                                        Experience :
                                    </div>
                                    <div className={styles.fieldData}>
                                        {details?.experience}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.fnaLangWrapper}>
                            <div className={styles.fnlEachWrapper}>
                                <div className={styles.fnlCaption}>Incentives</div>
                                <div className={styles.fnlValue}>{details?.incentives}</div>
                            </div>
                            <div className={styles.fnlEachWrapper}>
                                <div className={styles.fnlCaption}>Food & Accomodation</div>
                                <div className={styles.fnlValue}>
                                    {details?.accomodation}
                                </div>
                            </div>
                            <div className={styles.fnlEachWrapper}>
                                <div className={styles.fnlCaption}>Language Spoken</div>
                                <div className={styles.fnlValue}>
                                    {details?.language_spoken}
                                </div>
                            </div>
                            <div className={styles.fnlEachWrapper}>
                                <div className={styles.fnlCaption}>Education Qualifications</div>
                                <div className={styles.fnlValue}>
                                    {details?.education_qualifications}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            }
            {jobDetails === null ?
                <div className={styles.bodyUnloaded}>Please select a job to start!</div>
                :
                <div className={styles.body}>
                    {/* {JSON.stringify(details)} */}
                    <div className={styles.skillWrapper}>
                        <div className={styles.visualSkillWrapper}>
                            <div>
                                <div className={styles.val}>{parseFloat(((skillAnalyzerData?.skillAnalyzer?.filter((item) => item.doesExist === true)?.length) / (skillAnalyzerData?.skillAnalyzer?.length)) * 100).toFixed(1)}%</div>
                                <div className={styles.valCap}>Required Skills Matches</div>
                            </div>
                            <div>
                                <div className={styles.val}>{skillAnalyzerData?.skillAnalyzer?.filter((item) => item.doesExist === false)?.length}/{skillAnalyzerData?.skillAnalyzer?.length}</div>
                                <div className={styles.valCap}>Skills that candidate don't know</div>
                            </div>
                        </div>
                        <div className={styles.skillAnalyzerWrapper}>
                            {/* {details?.skill} */}
                            {skillAnalyzerData?.skillAnalyzer?.map((item, index) => (
                                <div key={index} className={styles.eachSkill}>
                                    {item?.skill} - {item.doesExist ? <MdCheckCircle /> : <MdCheckCircleOutline color="#dfdfdf" />}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.jdWrapper}>
                        <div dangerouslySetInnerHTML={{ __html: details?.description }} />
                    </div>
                </div>
            }
            {jobDetails === null ?
                <div className={styles.footer}></div>
                :
                <div className={styles.footer}>
                    {jobDetails?.hasAlreadyApplied === "No" ?
                        <div className={styles.applicationActionBtn}>
                            <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px' }}
                                onClick={() => setIsScHolderOpen(true)}
                                title="Open all conversation related to this store"
                            >
                                <IoChatbubbleEllipsesSharp size={20} color="white" />
                            </button>
                            <button className={styles.bookmark}>
                                Bookmark
                            </button>
                            {isApplying === true ?
                                <button className={styles.applyNow} >
                                    Applying...
                                </button>
                                :
                                appliedResponse !== null ?
                                    <Link to={`/manage-application/${appliedResponse?.applicationIdentifier}`}>
                                        View Application
                                    </Link>
                                    :
                                    <button className={styles.applyNow} onClick={() => applyNow()}>
                                        Apply Now
                                    </button>
                            }
                        </div>
                        :
                        <div className={styles.actionBtns}>
                            <button style={{ backgroundColor: '#A89BF1', border: 'none', borderRadius: '4px', height: '40px', width: '40px' }}
                                onClick={() => setIsScHolderOpen(true)}
                                title="Open all conversation related to this store"
                            >
                                <IoChatbubbleEllipsesSharp size={20} color="white" />
                            </button>
                            <button className={styles.bookmark}>
                                Bookmark
                            </button>
                            {jobDetails?.applicationId && (
                                <Link to={`/manage-application/${jobDetails?.applicationId}`}>
                                    View Application
                                </Link>
                            )}

                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default JobDetailsForFinder