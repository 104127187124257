import React from 'react'
import { Link } from 'react-router-dom'
import styles from './AccountExistanceValidatorViaContactDetails.module.css'

const AccountExistanceValidatorViaContactDetails = props => {
    const { isOpen, storesData } = props

    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.contentHeader}>
                            <div className={styles.iconWrapper}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/already-exists-icon.png" />
                            </div>
                            <div className={styles.contentTitle}>
                                Already Exists!
                            </div>
                            <div className={styles.contentSubTitle}>
                                {storesData?.length} account(s) with the same credentials already exists.
                            </div>
                        </div>
                        <div className={styles.visitAccountWrapper}>
                            {storesData?.map((item, index) => (
                                <Link key={index} to={`/manage-partners-account/${item?.vendorIdentifier}`}>
                                    <div className={styles.eachAccountWrapper}>
                                        <div>
                                            {item?.storeName}
                                        </div>
                                        <div className={styles.address}>
                                            {item?.address}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AccountExistanceValidatorViaContactDetails