import React from 'react'
import styles from './MembershipTables.module.css'

const MembershipTables = (props) => {
    const { plan, planBenefits } = props

    return (

        <div>
            <table>
                <thead>
                    <tr>
                        <th>Plan Purchased</th>
                        <th>{plan}</th>
                    </tr>
                </thead>
                <tbody>
                    {planBenefits?.map((item, index) => (
                        <tr key={index} >
                            <td>
                                {item?.title}
                            </td>
                            <td>
                                {item?.value}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )






}

export default MembershipTables