import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CreateCampaignPopUp.module.css'

const CreateCampaignPopUp = (props) => {
    const { isOpen, closePopUp } = props
    const [campaignName, setCampaignName] = useState('')
    const [campaignDescription, setCampaignDescription] = useState('')
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [isCreating, setIsCreating] = useState(false)

    const createCampaign = async () => {
        setIsCreating(true)
        const createCampaignResponse = await fetch(BASE_URL_API + "/createCampaign",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    campaignName: campaignName,
                    campaignDescription : campaignDescription
                })
            });

        if (!createCampaignResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createCampaignRespo = await createCampaignResponse.json()
            if (createCampaignRespo.status === "success") {
                navigate(`/campaign/${createCampaignRespo?.response}`)
            } else {
                if (createCampaignRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createCampaignRespo)
        }
        setIsCreating(false)
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    {isCreating === true &&
                        <div className={styles.mainLoaderWrapper}>
                            <div className={styles.imageLoader}>
                                <img src="https://stylelink.s3.ap-south-1.amazonaws.com/loaders/loader-fast-trans.gif" />
                            </div>
                            <div className={styles.loaderTxt}>
                                Loading...
                            </div>
                        </div>
                    }
                    <div className={styles.mainContent}>
                        <div className={styles.headerWrapper}>
                            Create A New Campaign
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.fieldWrapper}>
                                <div className={styles.fieldCaption}>
                                    <div className={styles.fieldCaptionTitle}>Enter Campaign Name</div>
                                </div>
                                <div>
                                    <input autoFocus
                                        placeholder="Please enter a campaign name of your choice..."
                                        onChange={(e) => setCampaignName(e.target.value)}
                                        value={campaignName}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldWrapper}>
                                <textarea placeholder="Write description for your reference"
                                    onChange={(e) => setCampaignDescription(e.target.value)}
                                    value={campaignDescription}
                                ></textarea>
                            </div>
                        </div>
                        <div className={styles.actionWrapper}>
                            <button className={styles.cancel} onClick={() => closePopUp()}>Cancel</button>
                            {campaignName.length > 3 ?
                                <button className={styles.create}
                                    onClick={() => createCampaign()}
                                >
                                    Create
                                </button>
                                :
                                <button className={styles.createDull}>Create</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateCampaignPopUp