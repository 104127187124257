import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import AddNewModule from './AddNewModule'
import AddNewSubModule from './AddNewSubModule'
import styles from '../../Styles/Components/Course/CourseContent.module.css'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import { useNavigate } from 'react-router'
import EachCourseModule from './EachCourseModule'

const CourseContent = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");

    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const [courseModules, setCourseModules] = useState(null);

    const getCourseModules = async () => {
        setIsLoading(true);
        console.log("I'm fetching modules now");
        const getCourseModulesResponse = await fetch(BASE_URL_API+ "/getCourseModule",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                courseId : courseId
            })
        });

        if(!getCourseModulesResponse.ok) {
            alert("Something went wrong : Server Error!");
            // navigate("/");
        } else {
            const getCourseModulesRespo = await getCourseModulesResponse.json();
            if(getCourseModulesRespo.status=="success") {
                setCourseModules(getCourseModulesRespo.response);
                console.log(getCourseModulesRespo)
            } else {
                console.log(getCourseModulesRespo)
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getCourseModules();
    }, [courseId])
    
    
    return (
        <section className={styles.mainContainer}>
            <div className={styles.sectionMainTitle}>
                <h3>Course Content</h3>
            </div>
            <div className={styles.sectionContent}>
                {courseModules != null ?
                    <div>
                        {courseModules.map((item, index) => (
                            <EachCourseModule 
                                courseId={courseId} 
                                setIsLoading={setIsLoading} moduleId={item.moduleId} 
                                key={index}
                                item={item}
                            />
                        ))}

                        <AddNewModule courseId={courseId} setIsLoading={setIsLoading} getCourseModules={getCourseModules} />
                    </div>
                    :
                    <div>
                        <div className={styles.havenotAddedModule}>
                            You haven't added any module for this course!
                        </div>
                        <AddNewModule courseId={courseId} setIsLoading={setIsLoading} getCourseModules={getCourseModules} />
                    </div>
                }
            </div>

        </section>
    )
}

export default CourseContent