import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/WhatYouLearn.module.css'

const WhatYouLearn = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");

    const courseId = props.courseId;
    const setIsLoading = props.setIsLoading;
    const [wylLearnEntered, setWylLearnEntered] = useState([""]);
    const [recordsUpdated, setRecordsUpdated] = useState(null);

    const addNewRowHandler = () => {
        setWylLearnEntered([...wylLearnEntered, ""]);
    }

    const removeRowHandler = (indexr) => {
        setWylLearnEntered((p) => p.filter((p, index) => indexr !== index))
    }

    const wylEntryHandler = (e, index) => {
        const newEntry = [...wylLearnEntered];
        newEntry[index] = e.target.value;
        setWylLearnEntered(newEntry)
    }

    const getWyl = async () => {
        setIsLoading(true);
        const getWylResponse = await fetch(BASE_URL_API + "/getWyl",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId
                })
            });

        if (!getWylResponse.ok) {
            alert("Something went wrong: Server Error!");
        } else {
            const getWylRespo = await getWylResponse.json();
            if (getWylRespo.status == "success") {
                if (getWylRespo.message == "data found") {
                    setRecordsUpdated(getWylRespo.response);
                } else {
                    setRecordsUpdated(null);
                }
                console.log(getWylRespo)
            } else {
                alert("Unable to fetch wyl", getWylRespo.status);
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getWyl()
    }, [courseId])


    const createWyl = async () => {
        setIsLoading(true);
        const submitWylResponse = await fetch(BASE_URL_API + "/createWyl",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    courseId: courseId,
                    wylLearnEntered: wylLearnEntered
                })
            });

        console.log(wylLearnEntered)
        if (!submitWylResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const submitWylRespo = await submitWylResponse.json();
            if (submitWylRespo.status == "success") {
                getWyl();
            } else {
                if (submitWylRespo.message == "token Expired, please login again!") {
                    navigate("/");
                } else {
                    getWyl();
                    console.log(submitWylRespo.response)
                }
            }
        }
        setIsLoading(false);
    }

    return (
        <section className={styles.mainContainer}>
            <div className={styles.sectionMainTitle}>
                <h3>What you'll learn?</h3>
            </div>
            {recordsUpdated === null ?
                <div className={styles.sectionContent}>
                    {wylLearnEntered != null &&
                        wylLearnEntered.map((item, index) => (
                            <div className={styles.wylEach} key={index}>
                                <button onClick={() => removeRowHandler(index)}> - </button>
                                <input
                                    onChange={(e) => wylEntryHandler(e, index)}
                                    value={item}

                                />
                                <button onClick={() => addNewRowHandler()}> + </button>
                            </div>
                        ))
                    }
                    <div className={styles.submitBtnContainer}>
                        <button onClick={() => createWyl()}>Submit</button>
                    </div>
                </div>
                :
                <div className={styles.sectionContent}>
                    {recordsUpdated != null &&
                        recordsUpdated.map((item, index) => (
                            <div className={styles.wylEach} key={index + "UPDATED"}>
                                <div className={styles.wylEachLearning}>
                                {item.learning}
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </section>
    )
}

export default WhatYouLearn