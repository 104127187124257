import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../JobApplications/CreateApplicationActivityStats.module.css'
import CandidateApplicationActivitySummary from './CandidateApplicationActivitySummary';
import CreateApplicationSummaryWorkspace from './CreateApplicationSummaryWorkspace';

const CreateApplicationActivityStats = props => {
    const { selectedJob, activeCandidate } = props;
    const authCtx = useContext(AuthContext)

    const [statsData, setStatsData] = useState([])
    const [secondaryStats, setSecondaryStats] = useState([])
    const [caswOpen, setCaswOpen] = useState(false)

    const caswToggleHandler = () => {
        setCaswOpen(!caswOpen)
    }

    const getApplicationsSummaryForJob = async () => {
        const getApplicationsSummaryForJobResponse = await fetch(BASE_URL_API + "/getApplicationsSummaryForJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: selectedJob.job_id
                })
            });

        if (!getApplicationsSummaryForJobResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationsSummaryForJobRespo = await getApplicationsSummaryForJobResponse.json();
            if (getApplicationsSummaryForJobRespo.status === "success") {
                setStatsData(getApplicationsSummaryForJobRespo.response)
            } else {
                if (getApplicationsSummaryForJobRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setStatsData([])
                }
            }
            console.log(getApplicationsSummaryForJobRespo)
        }
    }

    const getApplicationCreationActivitySummary = async () => {
        const gacasre = await fetch(BASE_URL_API+"/getApplicationCreationActivitySummary",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                jobIdentifier : selectedJob.job_id
            })
        });

        if(!gacasre.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const gacasr = await gacasre.json();
            if(gacasr.status==='success') {
                setSecondaryStats(gacasr.response)
            } else {
                if(gacasr.message ==='token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setSecondaryStats([])
                }
            }
            console.log(gacasr)
        }
    }

    useEffect(() => {
        selectedJob !== null && getApplicationsSummaryForJob().then(() => getApplicationCreationActivitySummary())
    }, [selectedJob])



    if (selectedJob === null) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.statsWrapper}>
                    <div className={styles.statsWrapperHeader}>
                        Applications Summary
                    </div>
                    <div className={styles.eachStatsRowJobNotSelected}>
                        <div>
                            Application summary will appear here.
                        </div>
                        <div>
                            <div>
                                Please select a job
                            </div>
                            <div>
                                to know details
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.activityWrapper}>

                </div>
            </div>
        )
    }
    return (
        <div className={styles.mainWrapper}>
            <CreateApplicationSummaryWorkspace 
                isOpen={caswOpen} caswToggleHandler={caswToggleHandler} 
                jobIdentifier={selectedJob?.job_id}
                selectedJob={selectedJob}
            />
            <div className={styles.statsWrapper}>
                <div className={styles.statsWrapperHeader}>
                    <div>
                        Applications Summary
                    </div>
                    <div>
                        <button onClick={() => setCaswOpen(true)}>Hey Jarvis</button>
                    </div>
                </div>

                <div className={styles.tacWrapper}>
                    <div className={styles.tacHeader}>
                        Total Application(s) Created
                    </div>

                    <div className={styles.eachStatsRow}>
                        {statsData?.map((item, index) => (
                            <div key={index} className={styles.eachPriStat}>
                                <div className={styles.countTxt}>
                                    {item?.totalApplications}
                                </div>
                                <div className={styles.actionTxt}>
                                    {item?.applied_source==="WEB_ADMIN" ? "React Panel" : item.applied_source}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.stacWrapper}>
                    <div className={styles.eachStatsRow}>
                        {secondaryStats?.map((item, index) => (
                            <div className={styles.eachSecondaryStat} key={index}>
                                <div className={styles.countTxt}>
                                    {item?.total}
                                </div>
                                <div className={styles.actionTxt}>
                                    {item?.action==="COULD_NOT_HAD_CONVERSATION" ? "No conversation" : (item.action==="PROFILE_NOT_CONSIDERED" ? "Not Considered" : (item.action ==="PROFILE_CONSIDERED" ? "Profile Considered" : item.action)  ) }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <CandidateApplicationActivitySummary 
                jobIdentifier={selectedJob.job_id}
                activeCandidate={activeCandidate}
                getApplicationCreationActivitySummary={getApplicationCreationActivitySummary}
            />
        </div>
    )
}

export default CreateApplicationActivityStats