import React from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import styles from './AcadNewVisitorEachRow.module.css'

const AcadNewVisitorEachRow = props => {
    const { item, count } = props
    return (
        <div className={styles.eachRowWrapper}>
            <div className={styles.srn}>{count+1}</div>
            <div className={styles.fullName}>
                {item?.fullName}
                <div className={styles.fullAddress}>
                    {item?.fullAddress}
                </div>
            </div>
            <div className={styles.phone}>{item?.phone}</div>
            <div className={styles.visitedOn}>{humanReadableTimePassed(item?.createdOn)}</div>
            <div className={styles.visitPurpose}>{item?.visitPurpose}</div>
        </div>
    )
}

export default AcadNewVisitorEachRow