import React, { useContext } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import AuthContext from '../../store/auth-context'
import styles from './AcademicHeader.module.css'

const AcademicHeader = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div className={styles.header}>
            <div className={styles.headerNavigation}>
                <Link to="/">
                    <div className={styles.eachHeaderNavigation}>
                        Home
                    </div>
                </Link>
                <Link to="/academics/admissions">
                    <div className={styles.eachHeaderNavigation}>
                        Admissions
                    </div>
                </Link>
                <Link to="/academics/enquires">
                    <div className={styles.eachHeaderNavigation}>
                        Enquires
                    </div>
                </Link>
                <Link to="/academics/visitors">
                    <div className={styles.eachHeaderNavigation}>
                        Visitors
                    </div>
                </Link>
            </div>
            <div>
                <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                    <MdLogout />
                </button>
            </div>
        </div>
    )
}

export default AcademicHeader