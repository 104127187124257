import React, { useContext, useEffect, useState } from 'react'
import { Area, Bar, BarChart, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../ManagePartnersAccounts/MpaOpeningsDetails.module.css'


const MpaOpeningsDetails = props => {
    const authCtx = useContext(AuthContext);
    let selectedStore = props.selectedStore;
    const [viewType, setViewType] = useState('summary');
    const [summarisedResponse, setSummarisedResponse] = useState(null);

    const [summarisedOpeningStatus, setSummarisedOpeningStatus] = useState('FETCHING'); //FETCHING, FETCHED, FAILED;

    const getSummarisedOpeningDetails = async () => {
        setSummarisedOpeningStatus('FETCHING');
        const getSummarisedOpeningDetailsResponse = await fetch(BASE_URL_API + "/getSummarisedOpeningDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: selectedStore.store_id
                })
            });

        if (!getSummarisedOpeningDetailsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getSummarisedOpeningDetailsRespo = await getSummarisedOpeningDetailsResponse.json();
            console.log(getSummarisedOpeningDetailsRespo);
            setSummarisedResponse(getSummarisedOpeningDetailsRespo.response);
        }
        setSummarisedOpeningStatus('FETCHED');
    }


    useEffect(() => {
        getSummarisedOpeningDetails();
    }, [selectedStore])

    // const data = [
    //     {
    //         "name": "Page A",
    //         "uv": 4000,
    //         "pv": 2400
    //     },
    //     {
    //         "name": "Page B",
    //         "uv": 3000,
    //         "pv": 1398
    //     },
    //     {
    //         "name": "Page C",
    //         "uv": 2000,
    //         "pv": 9800
    //     },
    //     {
    //         "name": "Page D",
    //         "uv": 2780,
    //         "pv": 3908
    //     },
    //     {
    //         "name": "Page E",
    //         "uv": 1890,
    //         "pv": 4800
    //     },
    //     {
    //         "name": "Page F",
    //         "uv": 2390,
    //         "pv": 3800
    //     },
    //     {
    //         "name": "Page G",
    //         "uv": 3490,
    //         "pv": 4300
    //     }
    // ]


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.navigationsContainer}>
                    <div className={styles.eachBtnView}>
                        <div className={viewType === 'summary' ? styles.eachNavigationContainerSelected : styles.eachNavigationContainer}
                            onClick={() => setViewType('summary')}
                        >
                            Summary
                        </div>
                        <div className={viewType === 'detailed' ? styles.eachNavigationContainerSelected : styles.eachNavigationContainer}
                            onClick={() => setViewType('detailed')}
                        >
                            Detailed
                        </div>

                    </div>
                </div>
            </div>
            {viewType === "summary" ?
                <div>
                    {summarisedOpeningStatus == 'FETCHING' ?

                        <div>
                            fetching...
                        </div>
                        :
                        <div>

                            <div className={styles.headerTitle}>
                                Summarised View
                            </div>
                            <div className={styles.cardHolders}>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        {summarisedResponse.allOpenings}
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Posted Jobs
                                    </div>
                                </div>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        {summarisedResponse.liveOpenings}
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Live
                                    </div>
                                </div>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        {summarisedResponse.closedOpenings}
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Closed
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cardHolders}>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        4
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Views
                                    </div>
                                </div>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        4
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Applications Received
                                    </div>
                                </div>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.statsCount}>
                                        4
                                    </div>
                                    <div className={styles.statsCaption}>
                                        Hired
                                    </div>
                                </div>
                            </div>

                            <div>

                                {/* <BarChart width={730} height={250} data={summarisedResponse.data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="pv" fill="#8884d8" />
                                    <Bar dataKey="uv" fill="#82ca9d" />
                                </BarChart> */}

                                <ComposedChart width={730} height={250} data={summarisedResponse.data}>
                                    <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Area type="monotone" dataKey="avgSalary" fill="#8884d8" stroke="#8884d8" />
                                    <Bar dataKey="minSalary" barSize={30} fill="#413ea0" />
                                    <Bar dataKey="maxSalary" barSize={30} fill="#ffcb08" />
                                    <Line type="monotone" dataKey="avgSalary" stroke="#ff7300" />
                                </ComposedChart>

                            </div>

                        </div>
                    }
                </div>
                :
                <div>
                    Detailed view
                </div>
            }
        </div>
    )
}

export default MpaOpeningsDetails