import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './EditWorkExperience.module.css'

const EditWorkExperience = props => {
    const authCtx = useContext(AuthContext)
    const { item, isEditingExperience, editExperienceToggle, getExperiences, toast } = props
    const [experience_id, setExperience_id] = useState('')
    const [salon_name, setSalon_name] = useState('')
    const [address, setAddress] = useState('')
    const [designation, setDesignation] = useState('')
    const [event_description, setEvent_description] = useState('')
    const [monthlySalary, setMonthlySalary] = useState()
    const [modeOfSalary, setModeOfSalary] = useState('')
    const [start_date, setStart_date] = useState('')
    const [end_date, setEnd_date] = useState('')
    const [reasonOfLeaving, setReasonOfLeaving] = useState('')
    const [isupdating, setIsupdating] = useState(false)

    window.onclick = function (event) {
        if (event.target.id === "editExpWrapper") {
            editExperienceToggle()
        }
    }

    const salonNameHandler = (k) => setSalon_name(k)
    const addressHandler = k => setAddress(k)
    const designationHandler = k => setDesignation(k)
    const event_descriptionHandler = k => setEvent_description(k)
    const monthlySalaryHandler = k => setMonthlySalary(k)
    const modeOfSalaryHandler = k => setModeOfSalary(modeOfSalary === k ? '' : k)
    const start_dateHandler = k => setStart_date(k)
    const end_dateHandler = k => setEnd_date(k)
    const reasonOfLeavingHandler = k => setReasonOfLeaving(k)

    useEffect(() => {
        if (item !== "") {
            setExperience_id(item?.experience_id)
            setSalon_name(item?.salon_name)
            setAddress(item?.address)
            setDesignation(item?.designation)
            setEvent_description(item?.event_description)
            setMonthlySalary(item?.monthlySalary)
            setModeOfSalary(item?.modeOfSalary)
            setStart_date(item?.start_date)
            setEnd_date(item?.end_date)
            setReasonOfLeaving(item?.reasonOfLeaving)
        }
    }, [item])


    const convertToDDMMYY = k => {
        if (k !== "" && k !== undefined) {
            let p = k?.split(" ")
            let date = p[0]
            let fullDate = date.split("-")
            return fullDate[0] + "-" + fullDate[1] + "-" + fullDate[2]
        }
    }

    const updateExperienceHandler = async () => {
        // console.log("started updating...")
        setIsupdating(true)
        // console.log(experience_id, salon_name, address, designation, event_description, monthlySalary, modeOfSalary, start_date, end_date, reasonOfLeaving)
        const updateExperienceResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: item.stylist_id,
                    dataType: 'updateNow',
                    sectionType: 'experience',
                    actionType: 'update',

                    experienceId: experience_id,
                    designation: designation,
                    salon_name: salon_name,
                    start_date: start_date,
                    end_date: end_date,
                    address: address,
                    event_description: event_description,
                    monthlySalary: monthlySalary,
                    modeOfSalary: modeOfSalary,
                    reasonOfLeaving: reasonOfLeaving
                })
            });

        if (!updateExperienceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateExperienceRespo = await updateExperienceResponse.json();
            if (updateExperienceRespo.status === 'success') {
                toast("Experience successfully edited!")
                getExperiences()
                editExperienceToggle()
            } else {
                if (updateExperienceRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateExperienceRespo)
                }
            }
            setIsupdating(false)
        }
    }


    if (isEditingExperience !== true) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            {isupdating &&
                <div className={styles.loaderWrapper}>
                    <div className={styles.holdForAMoment}>
                        Hold for a moment!!
                    </div>
                    <div>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/gif.png" />
                    </div>
                </div>
            }

            <div className={styles.mainContainer}
                id="editExpWrapper"
            >
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        Edit Candidate experience {experience_id}
                    </div>
                    <div className={styles.mainBody}>


                        <div className={styles.oneInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>
                                    Salon Name
                                </div>
                                <div className={styles.fieldContentWrapper}>
                                    <input
                                        placeholder="Please enter salon/institution/company name.."
                                        value={salon_name}
                                        autoFocus={true}
                                        autoCapitalize={true}
                                        onChange={(e) => salonNameHandler(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.oneInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>
                                    Salon Address
                                </div>
                                <div className={styles.fieldContentWrapper}>
                                    <input
                                        placeholder="Please Salon/Insti/Company Address..."
                                        value={address}
                                        onChange={(e) => addressHandler(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.oneInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Profile</div>
                                <div className={styles.fieldContentWrapper}>
                                    <input
                                        placeholder="Please enter your work profile..."
                                        value={designation}
                                        onChange={(e) => designationHandler(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.oneInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Description about work</div>
                                <div className={styles.fieldContentWrapper}>
                                    <textarea
                                        placeholder="please add candidate's work description.."
                                        onChange={(e) => event_descriptionHandler(e.target.value)}
                                        value={event_description}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div className={styles.twoInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Salary</div>
                                <div className={styles.fieldContentWrapper}>
                                    <input placeholder="Salary..."
                                        value={monthlySalary}
                                        onChange={(e) => monthlySalaryHandler(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Mode of Payment</div>
                                <div className={styles.fieldContentWrapper}>
                                    <button onClick={() => modeOfSalaryHandler('Online')}
                                        className={modeOfSalary === 'Online' ? styles.active : styles.notActive}
                                    >
                                        Online
                                    </button>
                                    <button onClick={() => modeOfSalaryHandler('Cash')}
                                        className={modeOfSalary === 'Cash' ? styles.active : styles.notActive}
                                    >
                                        Cash
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.twoInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Start Date</div>

                                <div className={styles.fieldContentWrapper}>
                                    <input type="date"
                                        value={convertToDDMMYY(start_date)}
                                        onChange={(e) => start_dateHandler(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>End Date</div>
                                <div className={styles.fieldContentWrapper}>
                                    <input
                                        type="date"
                                        value={convertToDDMMYY(end_date)}
                                        onChange={(e) => end_dateHandler(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.oneInOne}>
                            <div className={styles.eachFieldWrapper}>
                                <div className={styles.caption}>Reason of Leaving</div>
                                <div className={styles.fieldContentWrapper}>
                                    <textarea
                                        placeholder="Reason of leaving... "
                                        value={reasonOfLeaving}
                                        onChange={(e) => setReasonOfLeaving(e.target.value)}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.mainFooter}>
                        <div className={styles.mainFooterContainer}>
                            <div className={styles.eachBtnWrapper}>
                                <button className={styles.closeBtn} onClick={() => editExperienceToggle()} >Cancel</button>
                            </div>
                            <div className={styles.eachBtnWrapper}>
                                <button className={styles.updateBtn} onDoubleClick={() => updateExperienceHandler()} >Double Click To Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditWorkExperience