import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './UpdateJobPaidStatus.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const UpdateJobPaidStatus = (props) => {
    const { isOpen, onClose, jobIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [hasAlreadyUpdated, setHasAlreadyUpdated] = useState(false)
    const [jobNature, setJobNature] = useState(null)
    const [amount, setAmount] = useState(2500)
    const [remarks, setRemarks] = useState('The salon refused to pay the said amount, hence closing the job.')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [updatedResponse, setUpdatedResponse] = useState(null)

    const resetFields = () => {
        setJobNature(null)
    }

    useEffect(() => {
        isOpen === true && resetFields()
    }, [isOpen])

    const getJobPayingStatus = async () => {
        setIsLoading(true)
        const getJobPayingStatusResponse = await fetch(BASE_URL_API + "/getJobPayingStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier
                })
            });

        if (!getJobPayingStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobPayingStatusRespo = await getJobPayingStatusResponse.json()
            if (getJobPayingStatusRespo.status === "success") {
                setHasAlreadyUpdated(getJobPayingStatusRespo?.response?.isPaidHiring === null ? false : true)
                setUpdatedResponse(getJobPayingStatusRespo?.response)
            } else {
                if (getJobPayingStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setUpdatedResponse(null)
                }
            }
            console.log(getJobPayingStatusRespo)
        }
        setIsLoading(false)
    }

    const updateJobPayingStatus = async () => {
        setIsUpdating(true)
        let payload = JSON.stringify({})
        if (jobNature === 'PAID') {
            payload = JSON.stringify({
                token: authCtx.token,
                jobIdentifier: jobIdentifier,
                jobNature: jobNature,
                amount: amount,
                executiveIdentifier: authCtx.userIdentifier
            })
        } else {
            payload = JSON.stringify({
                token: authCtx.token,
                jobIdentifier: jobIdentifier,
                jobNature: jobNature,
                remarks: remarks,
                executiveIdentifier: authCtx.userIdentifier
            })
        }

        const updateJobPayingStatusResponse = await fetch(BASE_URL_API + "/updateJobPayingStatus",
            {
                method: "POST",
                body: payload
            });

        if (!updateJobPayingStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateJobPayingStatusRespo = await updateJobPayingStatusResponse.json()
            if (updateJobPayingStatusRespo.status === "success") {
                toast("Successfully update the pay status")
                getJobPayingStatus()
            } else {
                if (updateJobPayingStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateJobPayingStatusRespo)
        }
        setIsUpdating(false)
    }

    useEffect(() => {
        getJobPayingStatus()
    }, [])



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.headerWrapper}>
                            Update Job Paid Status
                        </div>
                        {isLoading === false ?
                            <div className={styles.bodyWrapper}>
                                {hasAlreadyUpdated === false &&
                                    <div className={styles.formWrapper}>
                                        <div className={styles.selectNatureWrapper}>
                                            <div>Please select the nature of Job</div>
                                            <div className={styles.selectNatureBtnsWrapper}>
                                                <button onClick={() => setJobNature('PAID')} className={jobNature === "PAID" ? styles.activeBtn : styles.inActiveBtn} >Paid</button>
                                                <button onClick={() => setJobNature('UNPAID')} className={jobNature === "UNPAID" ? styles.activeBtn : styles.inActiveBtn} >Un-paid</button>
                                            </div>
                                        </div>
                                        <div className={styles.selectRemarkWrapper}>
                                            {jobNature === 'PAID' &&
                                                <div className={styles.paidRemarkWrapper}>
                                                    <div className={styles.hiringTxt}>
                                                        Hiring Fee!
                                                    </div>
                                                    <div className={styles.prwInput}>
                                                        <input placeholder="Please enter the amount..." value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                    </div>
                                                </div>
                                            }
                                            {jobNature === 'UNPAID' &&
                                                <div className={styles.paidRemarkWrapper}>
                                                    <textarea autoFocus={true} value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder="Enter remarks..." ></textarea>
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.actionWrapperUn}>
                                            {jobNature !== null &&
                                                <div className={styles.updateBtnWrapper} >
                                                    {isUpdating === false ?
                                                        <button onClick={() => updateJobPayingStatus()} >Update</button>
                                                        :
                                                        <button >Updating...</button>
                                                    }
                                                </div>
                                            }
                                            <div className={styles.closeBtnWrapper}>
                                                <button onClick={() => onClose()}>
                                                    Close
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                }

                                {hasAlreadyUpdated === true &&
                                    <div className={styles.hasAlreadyUpdatedWrapper}>
                                        {updatedResponse?.isPaidHiring === "1" ?
                                            <div>
                                                <div className={styles.paidImg}>
                                                    <img src="https://cdn.dribbble.com/users/1751799/screenshots/5512482/media/1cbd3594bb5e8d90924a105d4aae924c.gif" />
                                                </div>
                                                <div className={styles.mainTxt}>
                                                    Damn!
                                                </div>
                                                <div>
                                                    <div className={styles.paidOne}>
                                                        it's a paid one,
                                                    </div>
                                                    <div className={styles.confirmedByTxt}>
                                                        Confirmed by {updatedResponse?.paidHiringMarkedBy} on {updatedResponse?.paidHiringMarkedOn}
                                                    </div>
                                                </div>
                                                <div className={styles.letsEarn}>
                                                    let's earn {updatedResponse?.hiringPrice} rupees
                                                </div>

                                                <div className={styles.actionWrapper}>
                                                    <div className={styles.changeItBtn}>
                                                        <button onClick={() => setHasAlreadyUpdated(false)} >Change it!</button>
                                                    </div>
                                                    <div className={styles.createAppBtn}>
                                                        <Link to={`/create-applications/${jobIdentifier}`}>Create Applications!</Link>
                                                    </div>
                                                </div>
                                                <div onClick={() => onClose()} className={styles.close}>
                                                    Close
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className={styles.unpaidImg}>
                                                    <img src="https://media.tenor.com/0t1tbXP8VJIAAAAi/no-emotiguy.gif" />
                                                </div>
                                                <div className={styles.mainTxt}>
                                                    Ooo..
                                                </div>
                                                <div className={styles.paidOne}>
                                                    it's a not the paid one,
                                                </div>
                                                <div className={styles.letsEarn}>
                                                    {updatedResponse?.paidHiringRemarks}
                                                </div>
                                                <div className={styles.actionWrapper}>
                                                    <div className={styles.changeItBtn}>
                                                        <button onClick={() => setHasAlreadyUpdated(false)} >Change it!</button>
                                                    </div>
                                                    <div className={styles.createAppBtn}>
                                                        <Link to={`/create-applications/${jobIdentifier}`}>Create Applications!</Link>
                                                    </div>
                                                </div>
                                                <div onClick={() => onClose()} className={styles.close} >
                                                    Close
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <div className={styles.bodyWrapper}>
                                <div className={styles.loadingStatus}>
                                Loading Status...
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default UpdateJobPaidStatus