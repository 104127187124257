import React, { useContext, useEffect, useState } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import ResumeBuildingHome from '../../Components/Jobs/ResumeBuilding/ResumeBuildingHome'
import ApplicationStatusSummary from '../../Components/ManageUser/InRecruitmentDomain/ApplicationStatusSummary'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../ManageUsers/ManageUsersGrow.module.css'
import SearchUserBar from './SearchUserBar'

const ManageUsersGrow = () => {
    const authCtx = useContext(AuthContext);
    const [isSummary, setIsSummary] = useState(true)

    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
    const [isLookingForJob, setIsLookingForJob] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)
    const selectUserHandlerMain = (k) => {
        setSelectedUser(k)
    }


    const [selectedApplicationStat, setSelectedApplicationStat] = useState('')
    const applicationStatTypeHandler = async (k) => {
        setSelectedApplicationStat(k)
        setSelectedProfileType(null)
        setIsSummary(false)
    }

    const optionsData = [
        {
            "icon": <FaUser />,
            "title": 'Personal Information',
            "key": '1'
        },
        {
            "icon": <FaUser />,
            "title": 'Education Qualifications',
            "key": '2'
        },
        {
            "icon": <FaUser />,
            "title": 'Skills',
            "key": '3'
        },
        {
            "icon": <FaUser />,
            "title": 'Certificates / Diplomas',
            "key": '4'
        },
        {
            "icon": <FaUser />,
            "title": 'Language Spoken',
            "key": '5'
        },
        {
            "icon": <FaUser />,
            "title": 'Preferences',
            "key": '6'
        },
        {
            "icon": <FaUser />,
            "title": 'Experiences',
            "key": '7'
        },
    ]

    const [selectedProfileType, setSelectedProfileType] = useState(null)
    const setSelectedProfileTypeHandler = async (k) => {
        setSelectedApplicationStat(null)
        setIsSummary(false)
        setSelectedProfileType(k)
    }

    const setLookingForJobHandler = async () => {
        setIsLookingForJob(!isLookingForJob)
    }

    const setCurrentWorkingHandler = async () => {
        setIsCurrentlyWorking(!isCurrentlyWorking)
    }

    const getWorkingStatus = async () => {
        const getWorkingStatusResponse = await fetch(BASE_URL_API + "/getWorkingStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id
                })
            });

        if (!getWorkingStatusResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getWorkingStatusRespo = await getWorkingStatusResponse.json();
            if (getWorkingStatusRespo.status === "success") {
                console.log(getWorkingStatusRespo);
                setIsLookingForJob(getWorkingStatusRespo.isLookingForJob === '1' ? true : false)
                setIsCurrentlyWorking(getWorkingStatusRespo.isCurrentlyWorking === '1' ? true : false)
            } else {
                if (getWorkingStatusRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();

                } else {
                    console.log(getWorkingStatusRespo);
                }
            }
        }
    }

    useEffect(() => {
        if (selectedUser !== null) {
            getWorkingStatus()
        }
    }, [selectedUser])


    const toggleWorkingStatus = async () => {
        setIsUpdating(true);
        const toggleWorkingStatusResponse = await fetch(BASE_URL_API + "/toggleWorkingStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    isCurrentlyWorking: isCurrentlyWorking,
                    isLookingForJob: isLookingForJob
                })
            });

        if (!toggleWorkingStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const toggleWorkingStatusRespo = await toggleWorkingStatusResponse.json();
            if (toggleWorkingStatusRespo.status === "success") {
                console.log(toggleWorkingStatusRespo)
            } else {
                if (toggleWorkingStatusRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(toggleWorkingStatusRespo);
                }
            }
            setIsUpdating(false);
        }


    }

    useEffect(() => {
        if (selectedUser !== null) {
            toggleWorkingStatus()
        }
    }, [isLookingForJob, isCurrentlyWorking])


    // console.log(isLookingForJob, isCurrentlyWorking)
    console.log(selectedUser)

    return (
        <div className={styles.mainContainer}>
            <SearchUserBar
                selectedUser={selectedUser}
                selectUserHandlerMain={selectUserHandlerMain}
            />
        </div>
    )
}

export default ManageUsersGrow