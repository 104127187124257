import React from 'react'
import styles from './PlotData.module.css'
import { Area, Bar, BarChart, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';

const { innerWidth, height} = window;

const PlotData = () => {

    // console.log(innerWidth)

    const data = [
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400
        },
        {
            "name": "Page B",
            "uv": 3000,
            "pv": 14398
        },
        {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800
        },
        {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908
        },
        {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800
        },
        {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800
        },
        {
            "name": "Page G",
            "uv": 893490,
            "pv": 4300
        }
    ]


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    PlotData

                </div>
                <div>

                    <ComposedChart
                        width={innerWidth - 280}
                        height={350} 
                        data={data}
                        className={styles.mainChartWrapper}
                    >
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="uv" fill="#8884d8" stroke="#8884d8" />
                        <Bar dataKey="pv" barSize={30} fill="#413ea0" />
                        <Bar dataKey="maxSalary" barSize={30} fill="#ffcb08" />
                        <Line type="monotone" dataKey="avgSalary" stroke="#ff7300" />
                    </ComposedChart>
                </div>
            </div>
        </div>
    )
}

export default PlotData