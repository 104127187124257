import React, { useContext, useEffect, useState } from 'react'
import styles from '../Partners/ManagePartnersAccount.module.css'

import { IoSettings } from 'react-icons/io5';
import { FaExchangeAlt } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';

import {
    ShimmerThumbnail
} from "react-shimmer-effects";
import AccountDetailsWrapper from './AccountDetailsWrapper';


const ManagePartnersAccount = () => {
    let authCtx = useContext(AuthContext);
    const [accountSelectionStatus, setAccountSelectionStatus] = useState('NOT_SELECTED'); //NOT_SELECTED, SELECTION_INITIATED, SELECTED, SELECTING, 
    const [suggestedSalons, setSuggestedSalons] = useState([]);
    const [isSuggestionListFetching, setIsSuggestionListFetching] = useState(false);
    const [fetchingStoreDetails, setFetchingStoreDetails] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [storeData, setStoreData] = useState([])
    const [selectedStore, setSelectedStore] = useState(null)

    // console.log(selectedAccount)

    const selectAccountHandler = (k) => {
        setSelectedAccount(k);
        setAccountSelectionStatus('SELECTED');
    }

    const initiateSuggestionHandler = k => {
        if (k.length > 3) {
            getSalonSuggestions(k)
        }
    }

    const getSalonSuggestions = async (k) => {
        setIsSuggestionListFetching(true)
        const getSalonSuggestionsResponse = await fetch(BASE_URL_API + "/getSalonSuggestions",
            {
                method: "POST",
                body: JSON.stringify({
                    searchKey: k,
                    token: authCtx.token
                })
            });

        if (!getSalonSuggestionsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getSalonSuggestionsRespo = await getSalonSuggestionsResponse.json();
            if (getSalonSuggestionsRespo.status === "failed") {
                if (getSalonSuggestionsRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            } else {
                if (getSalonSuggestionsRespo.message === "No results found") {
                    setSuggestedSalons([]);
                } else {
                    setSuggestedSalons(getSalonSuggestionsRespo.response)
                }
            }
            console.log(getSalonSuggestionsRespo)
        }
        setIsSuggestionListFetching(false)
    }


    const getStoreListing = async () => {
        setFetchingStoreDetails(true)
        const getStoreListingResponse = await fetch(BASE_URL_API + "/getStoreListing",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: selectedAccount?.vendorIdentifier
                })
            });

        if (!getStoreListingResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getStoreListingRespo = await getStoreListingResponse.json();
            setStoreData(getStoreListingRespo?.response);
            console.log("this is store data fetched : ", getStoreListingRespo)
        }
        setFetchingStoreDetails(false)
    }

    useEffect(() => {
        if (selectedAccount !== null) {
            getStoreListing()
        }
    }, [selectedAccount])

    console.log("Store Data : ", storeData)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.firstTitle}>
                        <IoSettings />&nbsp;&nbsp;Manage Partner's Account
                    </div>
                    <div className={styles.storeHeaderWrapper}>
                        <div className={styles.storeHeaderContainer}>
                            {accountSelectionStatus === 'SELECTED' ?
                                <div className={styles.selectedStoreWrapper}>
                                    <div>
                                        <div className={styles.selectedStoreTitle}>
                                            {selectedAccount?.company_name}
                                        </div>
                                        <div className={styles.selectedStoreAddress}>
                                            {selectedAccount?.address}
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setAccountSelectionStatus('SELECTION_INITIATED')}
                                    >
                                        <FaExchangeAlt />
                                    </div>

                                </div>
                                :
                                <div className={styles.selectingStoreWrapper}>
                                    {accountSelectionStatus === 'SELECTION_INITIATED' ?
                                        <div className={styles.searchAccountWrapper}>
                                            <div className={styles.searchBarWrapper}>
                                                <input placeholder="Search via name, number, id..."
                                                    onChange={(e) => initiateSuggestionHandler(e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.salonSuggestionsWrapper}>
                                                {isSuggestionListFetching === true ?
                                                    <div>
                                                        Fetching salon data...
                                                    </div>
                                                    :
                                                    suggestedSalons?.map((item, index) => (
                                                        <div className={styles.eachSuggestedSalon}
                                                            onClick={() => selectAccountHandler(item)}
                                                            key={index}
                                                        >
                                                            <div>
                                                                <div>
                                                                    {item?.company_name}
                                                                </div>
                                                                <div className={styles.addressTxt}>
                                                                    {item?.address}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}

                                            </div>
                                        </div>
                                        :
                                        <div
                                            style={styles.selectedAccountWrapper}
                                            onClick={() => setAccountSelectionStatus('SELECTION_INITIATED')}
                                        >
                                            <div className={styles.titleBigTxt}>
                                                Select Store
                                            </div>
                                            <div className={styles.titleSmallTxt}>
                                                Please select a store to manage
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    {fetchingStoreDetails === true ?
                        <div>
                            <div className={styles.storesListingWrapper}>
                                <div className={styles.storesListingHeader}>
                                    Fetching Stores... <span className={styles.activeStoreCount}>-</span>
                                </div>
                                <div>
                                    <div className={styles.eachLoadingStoreWrapper}>
                                        <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                    </div>
                                    <div className={styles.eachLoadingStoreWrapper}>
                                        <ShimmerThumbnail height={55} className={styles.eachLoaderStrip} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <div>
                            {storeData !== null ?
                                <div className={styles.storesListingWrapper}>
                                    <div className={styles.storesListingHeader}>
                                        Stores <span className={styles.activeStoreCount}>{storeData !== null && storeData?.length}</span>
                                    </div>
                                    <div>
                                        {storeData?.map((item, index) => (
                                            <div className={item?.store_id===(selectedStore!==null && selectedStore?.store_id) ? styles.eachStoreWrapperSelected : styles.eachStoreWrapper} key={index}
                                                onClick={() => setSelectedStore(item)}
                                            >
                                                <div className={styles.storeNameTxt}>
                                                    {item?.company_name}
                                                </div>
                                                <div className={styles.addressTxt}>
                                                    {item?.address}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                :
                                <div className={styles.storesListingWrapper}>
                                    <div className={styles.storesListingHeader}>
                                        Stores <span className={styles.activeStoreCount}>0</span>
                                    </div>
                                    <div>
                                        <p>No store added!</p>
                                    </div>

                                </div>
                            }
                        </div>
                    }

                </div>
                <div className={styles.rightContainer}>
                    <AccountDetailsWrapper 
                        selectedStore={selectedStore}
                        getStoreListing={getStoreListing}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManagePartnersAccount