import React, { useContext, useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ApplicationJourneyTracker.module.css'

const ApplicationJourneyTracker = (props) => {
    const authCtx = useContext(AuthContext)
    const { selectedApplication, isTrackerActivated, ajtCloser } = props
    window.onclick = event => event.target.id === "ajtContainer" ? ajtCloser() : null
    const [timelineResponse, setTimelineResponse] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getApplicationStatusForTracker = async () => {
        setIsLoading(true)
        const getApplicationStatusForTrackerResponse = await fetch(BASE_URL_API + "/getApplicationStatusForTracker",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationId: selectedApplication
                })
            });
        if (!getApplicationStatusForTrackerResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationStatusForTrackerRespo = await getApplicationStatusForTrackerResponse.json();
            if (getApplicationStatusForTrackerRespo.status === "success") {
                setTimelineResponse(getApplicationStatusForTrackerRespo.response)
            } else {
                if (getApplicationStatusForTrackerRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setTimelineResponse([])
                }
            }
            console.log(getApplicationStatusForTrackerRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (isTrackerActivated) getApplicationStatusForTracker()
    }, [selectedApplication])


    if (isTrackerActivated === false) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="ajtContainer">
                    <div className={styles.mainContent}>
                        <div className={styles.ajtHeader}>
                            <div className={styles.ajtHeaderTitle}>
                                <div>
                                    <button className={styles.backBtn} onClick={() => ajtCloser()} ><MdArrowBack /></button>
                                    Application Journey Tracker
                                </div>
                                <div className={styles.viewApplication}>
                                    <a href={"/manage-application/"+selectedApplication} target="_blank" >View Application</a>
                                </div>
                            </div>
                            <div className={styles.ajtHeaderCaption}>
                                {isLoading === true ?
                                    <div>
                                        Candidate for Salon
                                    </div>
                                    :
                                    <div>
                                        {timelineResponse[0]?.stylist_name} for {timelineResponse[0]?.company_name}
                                    </div>
                                }
                            </div>
                        </div>
                        {isLoading === true ?
                            <div>
                                <div>
                                    Hold On!
                                </div>
                                <div>
                                    I'm gathering information..
                                </div>
                            </div>
                            :
                            <div className={styles.timelineWrapper}>
                                <div className={styles.eachItemWrapper}>
                                    <div className={styles.upperCaption}>
                                        {timelineResponse[0]?.registeredVia === "" ? "By Own" : "By " + timelineResponse[0]?.registeredVia}
                                    </div>
                                    <div className={styles.applicationStatusOnboarded}>
                                        On-boarded
                                    </div>
                                    <div className={styles.lowerCaption}>
                                        <div>
                                            {timelineResponse[0]?.onboardingDate}
                                        </div>
                                        <div>
                                            {humanReadableTimePassed(timelineResponse[0]?.onboardingDate)}
                                        </div>
                                    </div>
                                </div>

                                {timelineResponse.map((item, index) => (
                                    item?.applicationStatus === "INTERVIEW_SLOT_BOOKED" ?
                                        <>
                                            <div key={index + "A"} className={styles.eachItemWrapper}>
                                                <div className={styles.upperCaption}>
                                                    <div>
                                                        Interview slot booked&nbsp;
                                                        {item?.applicationCreatingExecutiveIdentifier === "" ? "by Own" : "by " + item?.executiveName} with remarks
                                                    </div>
                                                    <div className={styles.slotRemarks}>{item?.slotRemarks}</div>
                                                </div>
                                                <div className={styles.applicationStatus}>
                                                    {item.applicationStatus}
                                                </div>
                                                <div className={styles.lowerCaption}>
                                                    <div>
                                                        {item.updatedOn}
                                                    </div>
                                                    <div>
                                                        {humanReadableTimePassed(item?.updatedOn)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div key={index} className={styles.eachItemWrapper}>
                                                <div className={styles.upperCaption}>
                                                    <div>
                                                        Slot Booked For {item.timeSlot}
                                                    </div>
                                                </div>
                                                <div className={styles.applicationStatus}>
                                                    DEMO DAY
                                                </div>
                                                <div className={styles.lowerCaption}>
                                                    <div>
                                                        {item?.updatedOn}
                                                    </div>
                                                    <div>
                                                        {humanReadableTimePassed(item?.updatedOn)}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {item?.applicationStatus === "REJECTED" ?
                                                <>
                                                    {item?.actionStatus === "CANDIDATE_WITHDRAWN_APPLICATION" &&
                                                        <div key={index + "R"} className={styles.eachItemWrapper}>
                                                            <div className={styles.upperCaption}>
                                                                <div>
                                                                    {item?.actionRemarks}
                                                                </div>
                                                            </div>
                                                            <div className={styles.applicationStatusRejected}>
                                                                INTERVIEW MISSED
                                                            </div>
                                                            <div className={styles.lowerCaption}>
                                                                <div>
                                                                    {item.updatedOn}
                                                                </div>
                                                                <div>
                                                                    {humanReadableTimePassed(item?.updatedOn)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div key={index + "A"} className={styles.eachItemWrapper}>
                                                        <div className={styles.upperCaption}>
                                                            <div>
                                                                {item?.company_name}
                                                                {item?.applicationCreatingExecutiveIdentifier === "" ? "By Own" : "By " + item?.executiveName}
                                                            </div>
                                                        </div>
                                                        <div className={styles.applicationStatusRejected}>
                                                            {item.applicationStatus}
                                                        </div>
                                                        <div className={styles.lowerCaption}>
                                                            <div>
                                                                {item.updatedOn}
                                                            </div>
                                                            <div>
                                                                {humanReadableTimePassed(item?.updatedOn)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {item?.applicationStatus === "HIRED" ?
                                                        <>
                                                            <div key={index + "AJD"} className={styles.eachItemWrapper}>
                                                                <div className={styles.upperCaption}>
                                                                    <div>
                                                                        {item?.company_name}
                                                                        {item?.applicationCreatingExecutiveIdentifier === "" ? "By Own" : "By " + item?.executiveName}
                                                                    </div>
                                                                </div>
                                                                <div className={styles.applicationStatusHired}>
                                                                    {item.applicationStatus}
                                                                </div>
                                                                <div className={styles.lowerCaption}>
                                                                    <div>
                                                                        {item.updatedOn}
                                                                    </div>
                                                                    <div>
                                                                        {humanReadableTimePassed(item?.updatedOn)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div key={index + "AJ"} className={styles.eachItemWrapper}>
                                                                <div className={styles.upperCaption}>
                                                                    <div>
                                                                        {item?.actionRemarks}
                                                                        {item?.salaryOffered}
                                                                    </div>
                                                                </div>
                                                                <div className={styles.applicationStatusJoined}>
                                                                    JOINED
                                                                </div>
                                                                <div className={styles.lowerCaption}>
                                                                    <div>
                                                                        {item?.joiningDate}
                                                                    </div>
                                                                    <div>
                                                                        {humanReadableTimePassed(item?.joiningDate)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                        :

                                                        <div key={index + "A"} className={styles.eachItemWrapper}>
                                                            <div className={styles.upperCaption}>
                                                                <div>
                                                                    {item?.applicationStatus === "APPLIED" && "Applied via " + item?.applied_source}&nbsp;
                                                                    {item?.applicationCreatingExecutiveIdentifier === "" ? "By Own" : "By " + item?.executiveName}
                                                                </div>
                                                            </div>
                                                            <div className={item?.applicationStatus === "APPLIED" ? styles.applicationStatusApplied : (item?.applicationStatus === "HIRED" ? styles.applicationStatusHired : styles.applicationStatus)}>
                                                                {item.applicationStatus}
                                                            </div>
                                                            <div className={styles.lowerCaption}>
                                                                <div>
                                                                    {item.updatedOn}
                                                                </div>
                                                                <div>
                                                                    {humanReadableTimePassed(item?.updatedOn)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }

                                                </>
                                            }

                                        </>
                                ))}
                            </div>
                        }
                        <div className={styles.ajtFooter}>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ApplicationJourneyTracker