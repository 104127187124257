import React from 'react'
import AcademicHeader from '../../Components/Academics/AcademicHeader'
import styles from './Admissions.module.css'

const Admissions = () => {
    return (
        <div className={styles.mainWrapper}>
            <AcademicHeader />
            Admissions

        </div>
    )
}

export default Admissions