import React, { useContext, useEffect, useState } from 'react'
import styles from '../ManageAcademies/RDMAcademiesEachParent.module.css'
import Collapsible from 'react-collapsible';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';

const RDMAcademiesEachParent = props => {
    let item = props.item;
    const authCtx = useContext(AuthContext);
    const [showAddNew, setShowAddNew] = useState(false)
    const [fetchingChilds, setFetchingChilds] = useState(false)
    const [childAcademies, setChildAcademies] = useState([])
    const [isValid, setIsValid] = useState(false)
    const getChildAcademiesHandler = () => {
        getChildAcademies()
    }
    const [academyName, setAcademyName] = useState('');
    const [academyAddress, setAcademyAddress] = useState('');
    const [ac_city, setAc_city] = useState('');
    const [ac_pincode, setAc_pincode] = useState('');
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')


    const getChildAcademies = async () => {
        setFetchingChilds(true)
        const getChildAcademiesResponse = await fetch(BASE_URL_API + "/getChildAcademies",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    firmIdentifier: item.firmIdentifier
                })
            });

        if (!getChildAcademiesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getChildAcademiesRespo = await getChildAcademiesResponse.json();
            if (getChildAcademiesRespo.status === 'success') {
                setChildAcademies(getChildAcademiesRespo.response)
            } else {
                if (getChildAcademiesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setChildAcademies([]);
                }
            }
            setFetchingChilds(false)
            console.log(getChildAcademiesRespo)
        }
    }

    const enterInputHandler = (k, v) => {
        if (k === 'academyName') {
            setAcademyName(v);
        }
        if (k === 'academyAddress') {
            setAcademyAddress(v)
        }
        if (k === 'ac_city') {
            setAc_city(v)
        }
        if (k === 'ac_pincode') {
            setAc_pincode(v)
        }
        if (k === 'country') {
            setCountry(v)
        }
        if (k === 'state') {
            setState(v)
        }
    }

    const validator = () => {
        if (academyName.length > 2) {
            if (academyAddress.length > 8) {
                if (ac_city.length > 2) {
                    if (ac_pincode.length > 4) {
                        if (country.length !== "Select Country") {
                            if (state.length !== "Select State") {
                                setIsValid(true)
                            } else {
                                setIsValid(false)
                            }
                        } else {
                            setIsValid(false)
                        }
                    } else {
                        setIsValid(false)
                    }
                } else {
                    setIsValid(false)
                }
            } else {
                setIsValid(false)
            }
        } else {
            setIsValid(false)
        }
    }


    useEffect(() => {
        validator()
    }, [academyName, academyAddress, ac_city, ac_pincode, country, state])




    const addNewBranch = async () => {
        console.log(item.firmIdentifier, academyName, academyAddress, ac_city, ac_pincode, country, state)
        const addNewBranchResponse = await fetch(BASE_URL_API + "/addNewAcademyBranch",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    parentFirmId: item.firmIdentifier,
                    academy_name: academyName,
                    academy_address: academyAddress,
                    ac_city: ac_city,
                    ac_pincode: ac_pincode,
                    country: country,
                    state: state
                })
            });

        if (!addNewBranchResponse.ok) {
            console.log("Something went wrong : server Error");
        } else {
            const addNewBranchRespo = await addNewBranchResponse.json();
            if (addNewBranchRespo.status === 'success') {
                getChildAcademies();
                setShowAddNew(false);
            } else {
                console.log(addNewBranchRespo)
            }
        }
    }


    return (
        <Collapsible trigger={
            <div className={styles.headerWrapper}>
                <div>
                    <div className={styles.name}>
                        {item.name}
                    </div>
                    <div className={styles.address}>
                        {item.hqAddress}
                    </div>
                </div>
                <div>

                </div>
            </div>}
            onOpen={() => getChildAcademiesHandler()}
        >
            <div className={styles.mainContainer}>
                {fetchingChilds === true ?
                    <div className={styles.notFound}>
                        Fetching branches of {item.name}
                    </div>
                    :
                    childAcademies.length > 0 ?
                        <div>
                            {childAcademies.map((item, index) => (
                                <div key={index} className={styles.eachBranchWrapper}>
                                    <div>
                                        {item.academy_name}
                                    </div>
                                    <div className={styles.address}>
                                        {item.academy_address}
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className={styles.notFound}>
                            No branch found for this brand!
                        </div>
                }

                {showAddNew === false ?
                    <div className={styles.addNewBtnWrapper}>
                        <button onClick={() => setShowAddNew(true)}>
                            Add A Branch
                        </button>
                    </div>
                    :
                    <div className={styles.addNewBranchWrapper}>
                        <div className={styles.eachInput}>
                            <input placeholder="Add branch name..."
                                onChange={(e) => enterInputHandler('academyName', e.target.value)}
                                value={academyName}
                            />
                        </div>
                        <div className={styles.eachInput}>
                            <input placeholder="Add location..."
                                onChange={(e) => enterInputHandler('academyAddress', e.target.value)}
                                value={academyAddress}
                            />
                        </div>
                        <div className={styles.eachInput}>
                            <input placeholder="City"
                                onChange={(e) => enterInputHandler('ac_city', e.target.value)}
                                value={ac_city}
                            />
                        </div>
                        <div className={styles.eachInput}>
                            <input placeholder="Pincode"
                                onChange={(e) => enterInputHandler('ac_pincode', e.target.value)}
                                value={ac_pincode}
                            />
                        </div>
                        <div className={styles.eachInput}>
                            <select
                                onChange={(e) => enterInputHandler('country', e.target.value)}
                            >
                                <option value="Select Country">Select Country</option>
                                <option value="INDIA">INDIA</option>
                                <option value="UAE">UAE</option>
                            </select>
                        </div>
                        <div className={styles.eachInput}>
                            <select
                                onChange={(e) => enterInputHandler('state', e.target.value)}
                            >
                                <option value="Select State">Select State</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Goa">Goa</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Madhya">Madhya Pradesh</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="West Bengal">West Bengal</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Orissa">Orissa</option>
                            </select>
                        </div>
                        <div className={styles.actionBtnContainer}>
                            <button className={styles.cancelBtn} onClick={() => setShowAddNew(false)} >Cancel</button>
                            {isValid === true ?
                                <button className={styles.submitBtn} onClick={() => addNewBranch()} >Submit</button>
                                :
                                <button className={styles.submitBtnDull}>Submit</button>
                            }
                        </div>
                    </div>
                }

            </div>
        </Collapsible>
    )
}

export default RDMAcademiesEachParent