import React from 'react'
import styles from '../ResumeBuilding/ResumeBuildingHome.module.css'
import RbCertiNDiplomas from './RbCertiNDiplomas';
import RbEducationQualifications from './RbEducationQualifications';
import RbExperience from './RbExperience';
import RbLanguagesSpoken from './RbLanguagesSpoken';
import RbPersonalInformations from './RbPersonalInformations';
import RbPreferences from './RbPreferences';
import RbSkills from './RbSkills';

const ResumeBuildingHome = props => {
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    return (
        <div className={styles.mainWrapper}>
            {selectedProfileType === 'Personal Information' && 
                <RbPersonalInformations 
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }

            {selectedProfileType === 'Education Qualifications' &&
                <RbEducationQualifications
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
            {selectedProfileType === 'Skills' &&
                <RbSkills
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
            {selectedProfileType === 'Certificates / Diplomas' &&
                <RbCertiNDiplomas
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
            {selectedProfileType === 'Language Spoken' &&
                <RbLanguagesSpoken
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
            {selectedProfileType === 'Preferences' &&
                <RbPreferences
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
            {selectedProfileType === 'Experiences' &&
                <RbExperience
                    selectedProfileType={selectedProfileType}
                    selectedUser={selectedUser}
                />
            }
        </div>
    )
}

export default ResumeBuildingHome