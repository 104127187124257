import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicHeader from '../../Components/Academics/AcademicHeader'
import AcadNewVisitorEachRow from '../../Components/Academics/AcadNewVisitorEachRow'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AcademyVisitors.module.css'

const AcademyVisitors = () => {
    const authCtx = useContext(AuthContext)
    const [allVisitors, setAllVisitors] = useState([])
    
    const getAcademyVisitors = async () => {
        const getAcademyVisitorsResponse = await fetch(BASE_URL_API+"/acad-getAcademyVisitors",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getAcademyVisitorsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAcademyVisitorsRespo = await getAcademyVisitorsResponse.json()
            if(getAcademyVisitorsRespo.status==="success") {
                setAllVisitors(getAcademyVisitorsRespo.response)
            } else {
                if(getAcademyVisitorsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAcademyVisitorsRespo)
        }
    }

    useEffect(() => {
        getAcademyVisitors()
    }, [])
    




    return (
        <div className={styles.mainWrapper}>
            <AcademicHeader />
            <div>
                <div className={styles.tableHeader}>
                    <div className={styles.srn}>#</div>
                    <div className={styles.fullName}>Full Name</div>
                    <div className={styles.phone}>Phone</div>
                    <div className={styles.visitedOn}>Visited On</div>
                    <div className={styles.visitPurpose}>Visit Purpose</div>
                </div>
                <div className={styles.tableBody}>
                    {allVisitors.map((item, index) => (
                        <AcadNewVisitorEachRow key={index} count={index} item={item} />
                    ))}
                </div>
            </div>

            <div className={styles.newVisitWrapper}>
                <Link to="new">New Visit + </Link>
            </div>
        </div>
    )
}

export default AcademyVisitors