import React, { useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import styles from './ChooseFromOlderAudiences.module.css'

const ChooseFromOlderAudiences = () => {
    const [audiencesData, setAudiencesData] = useState([1, 2, 3, 4, 5,])
    const [selectedAudience, setSelectedAudience] = useState([])

    const toggleAudienceSelector = (k) => {
        if (selectedAudience?.includes(k)) {
            setSelectedAudience(selectedAudience?.filter((item) => item !== k))
        } else {
            setSelectedAudience([...selectedAudience, k])
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    Choose from the older audiences
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.selectAudiencesWrapper}>
                        <div className={styles.selectAudienceContainer}>
                            <div className={styles.searchWrapper}>
                                <input placeholder="Search for a audience" />
                            </div>
                            <div className={styles.searchResultsWrapper}>
                                {audiencesData?.map((item, index) => (
                                    <div key={index} className={styles.eachAudienceWrapper}
                                        onClick={() => toggleAudienceSelector(item)}
                                    >
                                        <div className={styles.iconWrapper}>
                                            {selectedAudience?.includes(item) === true ?
                                                <MdCheckBox size={24} />
                                                :
                                                <MdCheckBoxOutlineBlank size={24} />
                                            }
                                        </div>
                                        <div>
                                            {item}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>


                    <div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ChooseFromOlderAudiences