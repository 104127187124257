import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SalonLeadsConverter.module.css'
import { useParams } from 'react-router'
import SLCLeadsDetails from '../../Components/TasksSalon/SLCLeadsDetails'
import SLCHiringForProfile from '../../Components/TasksSalon/SLCHiringForProfile'
import SLCPreviewDrafts from '../../Components/TasksSalon/SLCPreviewDrafts'

const SalonLeadsConverter = () => {
    const { leadIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('Details')
    const [allLeads, setAllLeads] = useState([])

    const [previewDrafts, setPreviewDrafts] = useState(false)
    const togglePreviewDraft = () => setPreviewDrafts(false)

    const adminGetSalonLeadsForTask = async () => {
        const adminGetSalonLeadsForTaskResponse = await fetch(BASE_URL_API + "/adminGetSalonLeadsForTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetSalonLeadsForTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetSalonLeadsForTaskRespo = await adminGetSalonLeadsForTaskResponse.json();
            if (adminGetSalonLeadsForTaskRespo.status === "success") {
                setAllLeads(adminGetSalonLeadsForTaskRespo.response)
            } else {
                if (adminGetSalonLeadsForTaskRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllLeads([])
                }
            }
            console.log(adminGetSalonLeadsForTaskRespo)
        }
    }

    useEffect(() => {
        adminGetSalonLeadsForTask()
    }, [])




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.leadsWrapper}>
                    {allLeads.map((item, index) => (
                        <Link to={`/salon-leads-converter/${item.leadIdentifier}`} className={leadIdentifier === item.leadIdentifier ? styles.eachActiveLeadWrapper : styles.eachLeadWrapper} key={index}>
                            <div>
                                <div className={styles.phone}>
                                    {item.phone}
                                </div>
                                <div className={styles.assignedOn}>{humanReadableTimePassed(item.assignedOn)}</div>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className={styles.tabWrapper}>
                    <div className={styles.leftTabWrapper}>
                        <button className={activeTab === "Details" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                            onClick={() => setActiveTab("Details")}
                        >
                            Lead Details
                        </button>
                        <button className={activeTab === "StoreMapping" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                            onClick={() => setActiveTab("StoreMapping")}
                        >
                            Store Mapping
                        </button>
                        <button className={activeTab === "Profile" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                            onClick={() => setActiveTab("Profile")}
                        >
                            Hiring For Profiles
                        </button>
                    </div>
                    <div className={styles.rightTabWrapper}>
                        <button className={styles.previewDraftBtn} onClick={() => setPreviewDrafts(true)} >Preview Drafts</button>
                    </div>
                </div>
                <div className={styles.tabBody}>
                    {activeTab === "Details" && <SLCLeadsDetails leadIdentifier={leadIdentifier} />}
                    {activeTab === "Profile" && <SLCHiringForProfile leadIdentifier={leadIdentifier} />}

                    {previewDrafts === true && <SLCPreviewDrafts isOpen={previewDrafts} togglePreviewDraft={togglePreviewDraft} leadIdentifier={leadIdentifier} />}
                </div>
            </div>
        </div>
    )
}

export default SalonLeadsConverter