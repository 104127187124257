import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CreateNewInvoicePopUp.module.css'

const CreateNewInvoicePopUp = (props) => {
    const authCtx = useContext(AuthContext)
    const nav = useNavigate()
    const {isOpen, onClose } = props 
    const [isCreating, setIsCreating] = useState(false)
    const initiateNewInvoice = async () => {
        setIsCreating(true)
        const initiateNewInvoiceResponse = await fetch(BASE_URL_API+"/initiateNewInvoice",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token, 
                executiveIdentifier : authCtx.userIdentifier
            })
        });

        if(!initiateNewInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const initiateNewInvoiceRespo = await initiateNewInvoiceResponse.json()
            if(initiateNewInvoiceRespo.status==="success") {
                nav(`/hiring-invoice/${initiateNewInvoiceRespo?.response?.invoiceIdentifier}`)
            } else {
                if(initiateNewInvoiceRespo?.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    
                }
            }
            setIsCreating(false)
            console.log(initiateNewInvoiceRespo)
        }
    }

    window.onclick = event => event.target.id === "cnipopid" ? onClose() : null

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    if(!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="cnipopid">
                    <div className={styles.mainContent}>
                        <div>
    
                            Create New
                        </div>
                        <div className={styles.createNow}>
                            {isCreating === false ?
                                <button onClick={() => initiateNewInvoice()} >Create Now</button>
                                :
                                <button>Creating...</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateNewInvoicePopUp