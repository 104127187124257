import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbPIProfileDomain.module.css';

const RbPIProfileDomain = props => {
    let selectedUser = props.selectedUser
    const authCtx = useContext(AuthContext)

    const [canUpdate, setCanUpdate] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([])

    const [primaryWorkDomainIdentifier, setPrimaryWorkDomainIdentifier] = useState(selectedUser.primaryWorkDomainIdentifier)
    const [primaryWorkDomain, setPrimaryWorkDomain] = useState(selectedUser.primaryWorkDomain)

    const [secondaryWorkDomainIdentifier, setSecondaryWorkDomainIdentifier] = useState(selectedUser.secondaryWorkDomainIdentifier)
    const [secondaryWorkDomain, setSecondaryWorkDomain] = useState(selectedUser.secondaryWorkDomain)

    const [tertiaryWorkDomainIdentifier, setTertiaryWorkDomainIdentifier] = useState(selectedUser.tertiaryWorkDomainIdentifier)
    const [tertiaryWorkDomain, setTertiaryWorkDomain] = useState(selectedUser.tertiaryWorkDomain)

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : server error!");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === "success") {
                setCategoryOptions(getCategoryOptionsRespo.response)
            } else {
                if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getCategoryOptionsRespo);
                }
            }
        }

    }

    useEffect(() => {
        getCategoryOptions()
    }, [])

    const workDomainUpdateHandler = (k, t) => {
        setCanUpdate(true);
        let v = JSON.parse(t);
        if (k === 'primary') {
            setPrimaryWorkDomain(v.category_name)
            setPrimaryWorkDomainIdentifier(v.ID)
        }
        if (k === 'secondary') {
            setSecondaryWorkDomain(v.category_name)
            setSecondaryWorkDomainIdentifier(v.ID)
        }
        if (k === 'tertiary') {
            setTertiaryWorkDomain(v.category_name)
            setTertiaryWorkDomainIdentifier(v.ID)
        }
    }

    const updateWorkDomain = async () => {
        const updateWorkDomainResponse = await fetch(BASE_URL_API + "/updateWorkDomain",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    primaryWorkDomain: primaryWorkDomain,
                    primaryWorkDomainIdentifier: primaryWorkDomainIdentifier,
                    secondaryWorkDomain: secondaryWorkDomain,
                    secondaryWorkDomainIdentifier: secondaryWorkDomainIdentifier,
                    tertiaryWorkDomain: tertiaryWorkDomain,
                    tertiaryWorkDomainIdentifier: tertiaryWorkDomainIdentifier
                })
            });

        if (!updateWorkDomainResponse.ok) {
            console.log("something went wrong : Server error")
        } else {
            const updateWorkDomainRespo = await updateWorkDomainResponse.json();
            if (updateWorkDomainRespo.status === 'success') {
                console.log(updateWorkDomainRespo);
            } else {
                if (updateWorkDomainRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(updateWorkDomainRespo);
                }
            }
        }
    }

    useEffect(() => {
        if(canUpdate) {
            if (primaryWorkDomain !== '' || secondaryWorkDomain !== '' || tertiaryWorkDomain !== '') {
                updateWorkDomain()
            }
        }
    }, [primaryWorkDomain, secondaryWorkDomain, tertiaryWorkDomain])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>Profile domain</div>
            <div className={styles.mainBody}>

                <div className={styles.eachDomainWrapper}>
                    <div className={styles.eachDomainWrapperTitle}>Primary Work Domain</div>
                    <div className={styles.eachDomainWrapperBody}>
                        <select
                            onChange={(e) => workDomainUpdateHandler('primary', e.target.value)}
                        >
                            <option>Select Primary Profile Domain</option>
                            {categoryOptions.map((item, index) => (
                                <option
                                    value={JSON.stringify(item)}
                                    key={index}
                                    selected={primaryWorkDomainIdentifier === item.ID ? "selected" : ""}
                                >
                                    {item.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={styles.eachDomainWrapper}>
                    <div className={styles.eachDomainWrapperTitle}>Secondary Work Domain</div>
                    <div className={styles.eachDomainWrapperBody}>
                        <select
                            onChange={(e) => workDomainUpdateHandler('secondary', e.target.value)}
                        >
                            <option>Select Secondary Profile Domain</option>
                            {categoryOptions.map((item, index) => (
                                <option
                                    value={JSON.stringify(item)}
                                    key={index}
                                    selected={secondaryWorkDomainIdentifier === item.ID ? "selected" : ""}
                                >
                                    {item.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={styles.eachDomainWrapper}>
                    <div className={styles.eachDomainWrapperTitle}>Tertiary Work Domain</div>
                    <div className={styles.eachDomainWrapperBody}>
                        <select
                            onChange={(e) => workDomainUpdateHandler('tertiary', e.target.value)}
                        >
                            <option>Select Tertiary Profile Domain</option>
                            {categoryOptions.map((item, index) => (
                                <option
                                    value={JSON.stringify(item)}
                                    key={index}
                                    selected={tertiaryWorkDomainIdentifier === item.ID ? "selected" : ""}
                                >
                                    {item.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default RbPIProfileDomain