import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Admin/AepPageAccessManager.module.css'

const AepPageAccessManager = () => {

    const authCtx = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)
    // const closeAddingModal = () => setIsOpen(false)

    const [allPages, setAllPages] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(20);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ column: null, order: null });

    const filteredData = allPages
        .filter(
            (item) =>
                item.username?.toLowerCase().includes(search.toLowerCase()) ||
                item.userID?.toLowerCase().includes(search.toLowerCase()) ||
                // (item.lastVisit && new Date(item.lastVisit).toString().toLowerCase().includes(search.toLowerCase()))
                (item.createdOn && item.createdOn.toLowerCase().includes(search.toLowerCase()))
        )
        .sort((a, b) => {
            let valueA = a[sort.column];
            let valueB = b[sort.column];

            if (typeof valueA === 'string') {
                valueA = valueA?.toLowerCase();
            }

            if (typeof valueB === 'string') {
                valueB = valueB?.toLowerCase();
            }

            valueA = isNaN(Date.parse(valueA)) ? valueA : new Date(valueA);
            valueB = isNaN(Date.parse(valueB)) ? valueB : new Date(valueB);

            valueA = isNaN(parseFloat(valueA)) ? valueA : parseFloat(valueA);
            valueB = isNaN(parseFloat(valueB)) ? valueB : parseFloat(valueB);

            if (sort.order === 'asc') {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPages = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const handleSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sort.column === column) {
            setSort({
                column,
                order: sort.order === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSort({
                column,
                order: 'asc',
            });
        }
    };

    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API + "/adminGetAllExecutives",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json();
            if (adminGetAllExecutivesRespo.status === 'success') {
                setAllPages(adminGetAllExecutivesRespo.response)
            } else {
                if (adminGetAllExecutivesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(adminGetAllExecutivesRespo)
        }
    }

    useEffect(() => {
        // adminGetAllExecutives();
    }, [])


    return (
        <div className={styles.allowedPageWrapper}>
            <div className={styles.tableTitleWrapper}>
                <div className={styles.tableTitleTxt}>
                    All Executives
                </div>
                <div className={styles.searchBoxWrapper}>
                    <input
                        type="text"
                        placeholder="Search for any page...."
                        value={search}
                        onChange={handleSearch}
                        className={styles.searchBar}
                    />
                </div>
            </div>

            <div className={styles.tableMainWrapper}>
                <table className={styles.tableWrapper}>
                    <thead className={styles.tableHeader}>
                        <tr>
                            <th onClick={() => handleSort('username')}>Identifier</th>
                            <th onClick={() => handleSort('userID')}>User ID</th>
                            <th onClick={() => handleSort('total_bookings')}>Bookings</th>
                            <th onClick={() => handleSort('total_spent')}>Spent</th>
                            <th onClick={() => handleSort('lastVisit')}>Last Visit</th>
                            <th onClick={() => handleSort('firstVisit')}>First Visit</th>
                            <th onClick={() => handleSort('firstVisit')}>Login Allowed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPages.map((item, index) => (
                            <tr key={index}>
                                <td>{item.username}</td>
                                <td>
                                    <Link to={item.userID}>
                                        {item.userID}
                                    </Link>
                                </td>
                                <td></td>
                                <td></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                <div className={styles.paginationWrapper}>
                    <div className={styles.paginationContainer}>
                        {[...Array(Math.ceil(filteredData.length / postsPerPage))].map(
                            (page, i) => (
                                <div
                                    key={i}
                                    className={currentPage === i + 1 ? styles.eachActivePageCount : styles.eachPageCount}
                                    onClick={() => paginate(i + 1)}
                                >
                                    {i + 1}
                                </div>
                            )
                        )}
                    </div>
                </div>



            </div>
        </div>
    )
}

export default AepPageAccessManager