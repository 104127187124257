import React, { useContext, useEffect, useState } from 'react'
import { isValidMobileNumber } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AddAuthPersonnelForPartnersAccount.module.css'

const AddAuthPersonnelForPartnersAccount = props => {
    const { vendorIdentifier, toast, getAuthPersonnelDetails } = props
    const authCtx = useContext(AuthContext)

    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [designation, setDesignation] = useState('')
    const [phone, setPhone] = useState('')
    const [whatsApp, setWhatsApp] = useState('')
    const [alternatePhone, setAlternatePhone] = useState('')
    const [isFormValidated, setIsFormValidated] = useState(false)

    const addAuthPersonHandler = async () => {
        const addAuthPersonnelDetailsResponse = await fetch(BASE_URL_API + "/addAuthPersonnelDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    vendorIdentifier: vendorIdentifier,
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    gender: gender,
                    designation: designation,
                    phone: phone,
                    whatsApp: whatsApp,
                    altPhone: alternatePhone
                })
            });
        if (!addAuthPersonnelDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addAuthPersonnelDetailsRespo = await addAuthPersonnelDetailsResponse.json()
            if (addAuthPersonnelDetailsRespo.status === "success") {
                toast("Successfully created!")
                setFirstName('')
                setMiddleName('')
                setLastName('')
                setGender('')
                setDesignation('')
                setPhone('')
                setWhatsApp('')
                setAlternatePhone('')
                getAuthPersonnelDetails()
            } else {
                if (addAuthPersonnelDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else if(addAuthPersonnelDetailsRespo.message==="already added") {
                    toast("Authorized personnel already added!")
                } else {
                    console.log(addAuthPersonnelDetailsRespo)
                }
            }
        }
    }

    const formValidator = () => {
        if (firstName.length > 1) {
            if (gender !== '') {
                if (designation !== '') {
                    if (isValidMobileNumber(phone)) {
                        setIsFormValidated(true)
                    } else {
                        setIsFormValidated(false)
                    }
                } else {
                    setIsFormValidated(false)
                }
            } else {
                setIsFormValidated(false)
            }
        } else {
            setIsFormValidated(false)
        }
    }

    useEffect(() => {
        formValidator()
    }, [firstName, gender, designation, phone])


    return (
        <div>
            <div className={styles.authPersonnelDetailsWrapper}>
                <div className={styles.cardHeader}>
                    Set Authorized Personnel Details
                </div>

                <div className={styles.authPersonnelDetailsBody}>
                    <div className={styles.threeInOne}>
                        <div className={styles.eachFieldWrapper}>
                            <input placeholder="Enter first name.." autoFocus={true} autoCapitalize="true"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                            />
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <input placeholder="Enter middle name.."
                                onChange={(e) => setMiddleName(e.target.value)}
                                value={middleName}
                            />
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <input placeholder="Enter last name.."
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                        </div>
                    </div>
                    <div className={styles.twoInOne}>
                        <div className={styles.eachFieldWrapper}>
                            <select 
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <option value=''>Select Gender</option>
                                <option value='Female' >Female</option>
                                <option value='Male' >Male</option>
                                <option value='Transgender' >Transgender</option>
                                <option value='Others' >Others</option>
                            </select>
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <select 
                                onChange={(e) => setDesignation(e.target.value)}
                            >
                                <option value=''>Select Designation</option>
                                <option value='Owner'>Owner</option>
                                <option value='Manager'>Manager</option>
                                <option value='Staff'>Staff</option>
                                <option value='HR'>HR</option>
                                <option value='Broker'>Broker / Third Party</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className={styles.fieldWithLabel}>
                            <div className={styles.fwlLabel}>
                                Phone
                            </div>
                            <div className={styles.fwlField}>
                                <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.fieldWithLabel}>
                            <div className={styles.fwlLabel}>
                                WhatsApp
                            </div>
                            <div className={styles.fwlField}>
                                <input type="number" value={whatsApp} onChange={(e) => setWhatsApp(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.fieldWithLabel}>
                            <div className={styles.fwlLabel}>
                                Alternate Phone
                            </div>
                            <div className={styles.fwlField}>
                                <input type="number" value={alternatePhone} onChange={(e) => setAlternatePhone(e.target.value)} />
                            </div>
                        </div>



                    </div>

                    <div className={styles.updatePDWrapper}>
                        {isFormValidated === true ?
                            <button className={styles.updatePersonnelDetailsActive}
                                onClick={() => addAuthPersonHandler()}
                            >
                                Update
                            </button>
                            :
                            <button className={styles.updatePersonnelDetails}

                            >
                                Update
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAuthPersonnelForPartnersAccount