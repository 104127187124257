import React from 'react'
import styles from '../Workshops/MWRDetailedView.module.css'
import WorkshopRegistrationsAll from './WorkshopRegistrationsAll';

const MWRDetailedView = props => {
    const requestingMethod = props.requestingMethod;
    const selectedUser = props.selectedUser;
    const selectedWorkshop = props.selectedWorkshop;

    console.log(selectedWorkshop)

    return (
        <div className={styles.mainWrapper}>
            {requestingMethod === 'WORKSHOPS' &&
                <WorkshopRegistrationsAll
                    selectedWorkshop={selectedWorkshop}
                />
            }

            {requestingMethod === 'REGISTRATIONS' &&
                <div className={styles.selectedUserDetails}>
                    <div className={styles.eachRow}>
                        <div>
                            Stylist Name:
                        </div>
                        <div>
                            {selectedUser.stylist_name}
                        </div>
                    </div>
                    <div className={styles.eachRow}>
                        <div>
                            Phone:
                        </div>
                        <div>
                            {selectedUser.contact_number}
                        </div>
                    </div>
                    <div className={styles.eachRow}>
                        <div>
                            Workshop ID:
                        </div>
                        <div>
                            {selectedUser.workshopID}
                        </div>
                    </div>
                    <div className={styles.eachRow}>
                        <div>
                            Instructor Name:
                        </div>
                        <div>
                            {selectedUser.instructorName}
                        </div>
                    </div>
                    <div className={styles.eachRow}>
                        <div>
                            Workshop Title
                        </div>
                        <div>
                            {selectedUser.workshopTitle}
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default MWRDetailedView