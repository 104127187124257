import React from 'react'
import { IoRemoveSharp } from 'react-icons/io5';
import styles from '../ResumeBuilding/EachSkillToBeAddedWrapper.module.css'
import Collapsible from 'react-collapsible';
import { FaChevronDown } from 'react-icons/fa';
import EachSkillWrapperCard from './EachSkillWrapperCard';

const EachSkillToBeAddedWrapper = props => {
    let item = props.item;
    let selectedGenderInOptions = props.selectedGenderInOptions;
    let skillsForMaleOptions = props.skillsForMaleOptions;
    let skillsForFemaleOptions = props.skillsForFemaleOptions;
    const toggleItemHandler = props.toggleItemHandler;
    const itemsToBeAddedMale = props.itemsToBeAddedMale;
    const itemsToBeAddedFemale = props.itemsToBeAddedFemale;

    return (
        <div className={styles.eachSkillWrapper}>
            <Collapsible trigger={
                <div className={styles.eachFieldHeaderContainer}
                >
                    <div className={styles.eachFieldHeaderTitle}>
                        {item.category_name} <FaChevronDown />
                    </div>
                </div>}
            >
                {selectedGenderInOptions === 'Male' ?
                    <>
                        {skillsForMaleOptions.filter((itemS) => itemS.categoryId === item.ID).map((itemSkill, indexSkill) => (
                            <EachSkillWrapperCard
                                itemSkill={itemSkill}
                                key={indexSkill}
                                itemsToBeAddedMale={itemsToBeAddedMale}
                                itemsToBeAddedFemale={itemsToBeAddedFemale}
                                toggleItemHandler={toggleItemHandler}
                                selectedGenderInOptions={selectedGenderInOptions}
                            />
                        ))}
                    </>
                    : selectedGenderInOptions === 'Female' ?
                        <>
                            {skillsForFemaleOptions.filter((itemS) => itemS.categoryId === item.ID).map((itemSkill, indexSkill) => (
                                <EachSkillWrapperCard
                                    itemSkill={itemSkill}
                                    key={indexSkill}
                                    itemsToBeAddedMale={itemsToBeAddedMale}
                                    itemsToBeAddedFemale={itemsToBeAddedFemale}
                                    toggleItemHandler={toggleItemHandler}
                                    selectedGenderInOptions={selectedGenderInOptions}
                                />
                            ))}
                        </>
                        :
                        <>

                        </>
                }
            </Collapsible>
        </div >
    )
}

export default EachSkillToBeAddedWrapper