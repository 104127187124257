import React from 'react'
import styles from './SLCPreviewDraftEachRow.module.css'

const SLCPreviewDraftEachRow = (props) => {
    const { item, count, jdndespHandler } = props

    return (
        <div className={styles.rowWrapper}>
            <div className={styles.sn}>
                {count + 1}
            </div>
            <div className={styles.jobTitle}>
                {item?.title}
            </div>
            <div className={styles.jobCategory}>
                {item?.jobCategoryName}
            </div>
            <div className={styles.storeDetails}>

            </div>
            <div className={styles.numberOfOpenings}>
                {item?.numberOfOpenings}
            </div>
            <div className={styles.salaryWrapper}>
                <div>
                    ₹{item?.salaryMin}
                </div>
                <div>
                    ₹{item?.salaryMax}
                </div>
            </div>
            <div className={styles.fna}>
                {item?.accomodation}
            </div>
            <div className={styles.ageRangeWrapper}>
                {item?.ageMin} - {item?.ageMax} years
            </div>

            <div className={styles.actionWrapper}>
                <button onClick={() => jdndespHandler(item)} >Preview</button>
                <button>Post</button>
            </div>
            {/* {JSON.stringify(item)} */}

        </div>
    )
}

export default SLCPreviewDraftEachRow