import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../store/auth-context'
import styles from './QPPreview.module.css'
import { generateJobDescription } from '../../../Helper/generateJobDescriptions'
import { BASE_URL_API } from '../../../References/urls'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QPPreview = (props) => {
    const authCtx = useContext(AuthContext)
    const { storeIdentifier, selectedCategory, experienceRequired, startingSalary, maximumSalary, whoCanApply, requiredQualifications, fna, jobTitle, skills, quickPostHandler, onSuccess } = props

    const [isLoading, setIsLoading] = useState(false)
    const [jobDescription, setJobDescription] = useState('');
    const jobDescriptionHandler = (txt) => {
        setJobDescription(txt)
    }

    useEffect(() => {
        setJobDescription(generateJobDescription(jobTitle, 'Full-Time', experienceRequired, whoCanApply, 16, 40, ['Hindi', 'English'], requiredQualifications, startingSalary, maximumSalary, '', fna, skills))
    }, [])



    const postNewJob = async () => {
        setIsLoading(true);
        let skillSetArr = [];
        let selectedSkillsArr = [];
        skills.map((item, index) => {
            skillSetArr.push(item?.ID);
            selectedSkillsArr.push(item?.skill);
        })

        const postNewJobResponse = await fetch(BASE_URL_API + "/postNewJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobTitle: jobTitle,
                    selectedJobType: 'Full-Time',
                    selectedStore: storeIdentifier,
                    selectedJobCategory: selectedCategory?.ID,
                    selectedJobCategoryName: selectedCategory?.category_name,
                    skillSetId: skillSetArr,
                    skill: selectedSkillsArr,
                    experience: experienceRequired,
                    minSalary: startingSalary,
                    maxSalary: maximumSalary,
                    gender: whoCanApply,
                    educationQualifications: requiredQualifications,
                    numOfOpenings: 1,
                    incentive: 'NA',
                    fnaOffering: fna,
                    language: ['Hindi', 'English'],
                    minAge: 16,
                    maxAge: 40,
                    description: jobDescription,
                    executiveIdentifier : authCtx.userIdentifier
                })
            })

        if (!postNewJobResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const postNewJobRespo = await postNewJobResponse.json();
            if(postNewJobRespo.status==="success") {
                console.log("I have successfully posted the job...")
                toast("Successfully posted the job!")
                try {
                    onSuccess()
                } catch (error) {
                    console.log("No on success event served!")                    
                }
                quickPostHandler()
            } else {
                alert(postNewJobRespo.response);
                console.log(postNewJobRespo.response);
            }
            console.log(postNewJobRespo);
        }

        setIsLoading(false);
    }

    const validateEntryFields = () => {
        let skillSetArr = [];
        let selectedSkillsArr = [];
        skills.map((item, index) => {
            skillSetArr.push(item?.ID);
            selectedSkillsArr.push(item?.skill);
        })
        console.log(jobTitle, 'Full-Time', storeIdentifier, selectedCategory?.ID, selectedCategory?.category_name, skillSetArr, selectedSkillsArr, experienceRequired, startingSalary, maximumSalary, whoCanApply, requiredQualifications, 1, 'None', fna, ['Hindi', 'English'], 16, 40, jobDescription)
        // console.log("this is job description : ", generateJobDescription(jobTitle, 'Full-Time', experienceRequired, whoCanApply, 16, 40, ['Hindi', 'English'], requiredQualifications, startingSalary, maximumSalary, '5 %', fna, skills))
        if (storeIdentifier === "") {
            alert('Missing store details');
        } else if (jobTitle === "" || jobDescription === "" || skills.length < 1) {
            alert('missing job details');
        } else if (requiredQualifications === "" || experienceRequired === "" || whoCanApply === "") {
            alert('missing qualifications')
        } else if (startingSalary === "" || maximumSalary === "" || fna === "") {
            alert('missing salary details')
        } else {
            postNewJob();
            console.log("All fine, I'm posting job now...")
        }
    }




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.postNowWrapper}>
                    {isLoading === true ?
                        <button>Please wait, posting job!</button>
                        :
                        <button onClick={() => validateEntryFields()} >Post Now</button>
                    }
                </div>

                <div>
                    <CKEditor
                        editor={BalloonEditor}
                        data={jobDescription != "" ? jobDescription : "<h3><strong>Beautician Responsibilities:</strong></h3><ul><li>Shampooing, cutting, styling, and coloring hair, as well as massaging and treating scalps.</li><li>Applying makeup, performing facials, and recommending skincare therapies.</li><li>Performing manicures and pedicures, nail art, and color recommendations.</li><li>Performing permanent and temporary hair removals treatments, such as electrolysis, laser hair removal, and waxing.</li><li>Suggesting guidelines on personal skincare regimes.</li><li>Cross-selling beauty therapies and products.</li><li>Making appointments and documenting treatments.</li><li>Keeping your workstation and tools clean and sterile.</li><li>Taking stock and ensuring there are adequate supplies.</li></ul><h3><strong>Beautician Requirements:</strong></h3><ul><li>A certification in cosmetology, aesthetics, or related field.</li><li>A license in a cosmetology field.</li><li>Working knowledge of skin care therapies, hair removal techniques, and beauty products and trends.</li><li>Strong creative skills.</li><li>Excellent communication and interpersonal skills.</li><li>Steady and dexterous hands.</li></ul>"}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            // console.log({ event, editor, data });

                        }}
                        onBlur={(event, editor) => {
                            // console.log('Blur.', editor);
                            const data = editor.getData();
                            jobDescriptionHandler(data)
                            // console.log(data);
                        }}
                        onFocus={(event, editor) => {
                            // console.log('Focus.', editor);
                        }}
                        className={styles.textAreaFull}

                    />
                </div>
            </div>

        </div>
    )
}

export default QPPreview