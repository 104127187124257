import { PinDrop } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { BiRightArrow } from 'react-icons/bi'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './SLCLeadsDetails.module.css'

const SLCLeadsDetails = props => {
    const { leadIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [leadDetails, setLeadDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getLeadDetailsAtSLC = async () => {
        setIsLoading(true)
        const getLeadDetailsAtSLCResponse = await fetch(BASE_URL_API + "/getLeadDetailsAtSLC",
            {
                method: "POST",
                body: JSON.stringify({
                    method: "POST",
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    leadIdentifier: leadIdentifier
                })
            });

        if (!getLeadDetailsAtSLCResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getLeadDetailsAtSLCRespo = await getLeadDetailsAtSLCResponse.json();
            if (getLeadDetailsAtSLCRespo.status === "success") {
                setLeadDetails(getLeadDetailsAtSLCRespo.response)
            } else {
                if (getLeadDetailsAtSLCRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setLeadDetails(null)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getLeadDetailsAtSLC()
    }, [leadIdentifier])

    console.log(leadDetails)


    return (
        <div>
            {isLoading === true ?
                <div>
                    Loading...
                </div>
                :
                leadDetails === null ?
                    <div>
                        No details available
                    </div>
                    :
                    <div className={styles.leadDetailsWrapper}>
                        <div>
                            {/* {leadIdentifier} */}
                        </div>
                        <div className={styles.ldwEachCard}>
                            <div className={styles.ldwecTitle}>
                                Hiring For
                            </div>
                            <div className={styles.profiles}>
                                {JSON.parse(leadDetails["profilesHiringFor"])?.map((item, index) => (
                                    <div key={index} className={styles.eachProfile}>
                                        <BiRightArrow color="#00000085" size={16} /> {item.category_name}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.ldwEachCard}>
                            <div className={styles.ldwecTitle}>Location(s)</div>
                            <div className={styles.locations}>
                                {JSON.parse(leadDetails["city"])?.map((item, index) => (
                                    <div key={index} className={styles.eachLocation}>
                                        <PinDrop color="#00000085" size={16} /> {item.location}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default SLCLeadsDetails