import React, { useEffect, useRef, useState } from 'react';
import BulkLeadPreviewAndUploader from './BulkLeadPreviewAndUploader';
import styles from './UploadLeadsInBulk.module.css';

const MAX_FILES = 100;

const UploadLeadsInBulk = (props) => {
    const { isOpen } = props;
    const fileInputRef = useRef(null);
    const [csvDataRecorded, setCsvDataRecorded] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [hasReadData, setHasReadData] = useState(false)
    const [nowPreviewing, setNowPreviewing] = useState(false)
    const previewToggler = () => setNowPreviewing(false)

    const initiateFileSelector = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        // Convert the FileList to an array and update the state
        setSelectedFiles(Array.from(files));
        setHasReadData(false)
    };

    const uploadSheet = () => {
        // Filter out duplicates by checking the name property
        const uniqueFiles = selectedFiles.filter(
            (file) => !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        );

        // Use FileReader to read the content of each file
        const filesWithContent = [];

        const readFileContent = (file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (event) => resolve({ name: file.name, content: event.target.result });
                reader.readAsText(file);
            });
        };

        const readFilesPromises = uniqueFiles.map((file) => readFileContent(file));

        Promise.all(readFilesPromises)
            .then((filesContent) => {
                // Add only files with valid content to the uploadedFiles state
                setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...filesContent]);
            })
            .catch((error) => {
                console.error('Error reading file content:', error.message);
            });

        setSelectedFiles([]);
        // Reset the file input
        fileInputRef.current.value = '';
        setHasReadData(false)
    };


    const removeFile = (fileName) => {
        setUploadedFiles((prevUploadedFiles) =>
            prevUploadedFiles.filter((file) => file.name !== fileName)
        );
        setHasReadData(false)
    };

    const readCsvFiles = async () => {
        try {
            const csvData = [];

            for (const uploadedFile of uploadedFiles) {
                let text = '';

                if (uploadedFile && uploadedFile.content instanceof Blob) {
                    text = await new Response(uploadedFile.content).text();
                } else if (typeof uploadedFile.content === 'string') {
                    text = uploadedFile.content;
                } else {
                    console.error(`Invalid content for file ${uploadedFile.name}`);
                    continue;  // Skip to the next file
                }

                // console.log(`Text content of ${uploadedFile.name}:`, text);

                const rows = text.split(/\r\n|\n/);

                // Assuming the first row contains headers
                const headers = rows[0].split(',').map((header) => header.trim().toLowerCase());
                // console.log(`Headers for ${uploadedFile.name}:`, headers);

                for (let i = 1; i < rows.length; i++) {
                    const values = rows[i].split(',');

                    if (values.length >= 3) {
                        const rowData = {
                            fileName: uploadedFile.name,
                            phoneNumber: values[headers.indexOf('phonenumber')] ? values[headers.indexOf('phonenumber')].trim() : '',
                            fullName: values[headers.indexOf('fullname')] ? values[headers.indexOf('fullname')].trim() : '',
                            city: values[headers.indexOf('city')] ? values[headers.indexOf('city')].trim() : '',
                            gender : values[headers.indexOf('gender')] ? values[headers.indexOf('gender')].trim() : ''
                        };

                        csvData.push(rowData);
                    }
                }
            }

            setHasReadData(true)
            // console.log('CSV Data:', csvData);
            setCsvDataRecorded(csvData)
        } catch (error) {
            console.error('Error reading CSV files:', error.message);
        }
    };

    useEffect(() => {
        if(hasReadData===false) {
            setCsvDataRecorded([])
        }
    }, [hasReadData])

    // console.log(csvDataRecorded)


    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <BulkLeadPreviewAndUploader isOpen={nowPreviewing} previewToggler={previewToggler} csvData={csvDataRecorded} />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainBody}>
                            <div className={styles.filePickerWrapper}>
                                <div className={styles.filePickerContainer}
                                    onClick={() => initiateFileSelector()}
                                >
                                    <div className={styles.filePickerHeader}>
                                        <div>Upload Data in Bulk</div>
                                        <div className={styles.subTxt}>via CSV file</div>
                                    </div>
                                    <div className={styles.uploadIcon}>
                                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/upload-icon.png" />
                                    </div>
                                    <div>
                                        Pick file(s)
                                    </div>
                                    <div className={styles.fileUploadInput}>
                                        <input type="file" multiple accept=".csv" onChange={handleFileChange} ref={fileInputRef} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.readerWrapper}>
                                {selectedFiles?.length > 0 &&
                                    <div className={styles.selectFileWrapper}>
                                        <div>
                                            <div className={styles.sfwTitle}>
                                                Selected Files :
                                            </div>
                                            <div className={styles.selectedFilesListWrapper}>
                                                <ul>
                                                    {selectedFiles.map((item, index) => (
                                                        <li key={index} className={styles.eachSelectedFile} >
                                                            {item?.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={styles.uploadBtnContainer}>
                                            <button onClick={uploadSheet}>UPLOAD</button>
                                        </div>
                                    </div>
                                }

                                {uploadedFiles?.length > 0 &&
                                    <div>
                                        <div className={styles.usTitle}>
                                            Uploaded Sheets - {uploadedFiles?.length}
                                        </div>
                                        <div className={styles.uploadedSheetWrapper}>
                                            {uploadedFiles.map((sheet) => (
                                                <div key={sheet.name} title={sheet?.name} className={styles.eachUploadedSheetWrapper}>
                                                    <div className={styles.sheetNameBox}>
                                                        {sheet.name}
                                                    </div>
                                                    <div className={styles.removeBtnWrapper}>
                                                        <button onClick={() => removeFile(sheet.name)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className={styles.dataActionWrapper}>
                                            <div className={hasReadData === false ? styles.eachBtnWrapperActive : styles.eachBtnWrapper}>
                                                <button disabled={hasReadData===true && true} onClick={() => readCsvFiles()}>
                                                    Read Data
                                                </button>
                                            </div>
                                            <div className={hasReadData === true ? styles.eachBtnWrapperActive : styles.eachBtnWrapper}>
                                                <button onClick={() => setNowPreviewing(true)}>
                                                    Preview Data
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default UploadLeadsInBulk;
