import React, { useContext, useEffect, useState } from 'react'
import styles from './JobsAllocator.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsGraphUp, BsListTask } from 'react-icons/bs';
import JAAssignMode from '../../Components/JobsAllocator/JAAssignMode';
import JAAllocatorMode from '../../Components/JobsAllocator/JAAllocatorMode';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';

const JobsAllocator = () => {
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('ASSIGN')

    const [allExecutives, setAllExecutives] = useState([])
    
    const adminGetAllExecutives = async () => {
        const adminGetAllExecutivesResponse = await fetch(BASE_URL_API+"/adminGetAllExecutives",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });
        if(!adminGetAllExecutivesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetAllExecutivesRespo = await adminGetAllExecutivesResponse.json()
            if(adminGetAllExecutivesRespo.status==="success") {
                setAllExecutives(adminGetAllExecutivesRespo.response)
            } else {
                if(adminGetAllExecutivesRespo.message==="token Expired, please login again!") {
                
                } else {
                    setAllExecutives([])
                }
            }
            console.log(adminGetAllExecutivesRespo)
        }
    }
    useEffect(() => {
        adminGetAllExecutives()
    }, [])
    

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>

            </div>
            <div className={styles.mainBody}>
                <div className={styles.navWrapper}>
                    <div className={styles.navContainer}>
                        <div className={styles.taskTitle}>
                            Task <br></br> Allocator
                        </div>
                        <div>

                            <div className={activeTab === "ASSIGN" ? styles.eachNavSelected : styles.eachNav} onClick={() => setActiveTab("ASSIGN")} >
                                <div>
                                    <BsListTask size={24} color={activeTab === "ASSIGN" ? "#623AEA" : "black"} />
                                </div>
                                <div className={styles.caption}>
                                    Assign <br></br> Mode
                                </div>
                            </div>

                            <div className={activeTab === "ANALYSIS" ? styles.eachNavSelected : styles.eachNav} onClick={() => setActiveTab("ANALYSIS")} >
                                <div>
                                    <BsGraphUp size={24} color={activeTab === "ANALYSIS" ? "#623AEA" : "black"} />
                                </div>
                                <div className={styles.caption}>
                                    Analysis <br></br> Mode
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className={styles.bodyWrapper}>
                    {activeTab==="ASSIGN" && <JAAssignMode allExecutives={allExecutives} />}
                    {activeTab==="ANALYSIS" && <JAAllocatorMode allExecutives={allExecutives} />}
                </div>

            </div>
        </div>
    )
}

export default JobsAllocator