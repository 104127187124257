import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_API } from "../References/urls";
import styles from "../Styles/Content/ManageInstructors.module.css";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { ShimmerButton } from "react-shimmer-effects";
import AuthContext from "../store/auth-context";

const ManageInstructors = props => {
    const authCtx = useContext(AuthContext)
    const [editing, setEditing] = useState(false);
    const [instructorsData, setInstructorsData] = useState(null);

    const [selectedInstructor, setSelectedInstructor] = useState("");
    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const notify = (msg) => toast(msg);

    const selectInstructorHandler = (i) => {
        if (i === selectedInstructor) {
            setEditing(false);
            setSelectedInstructor("");
        } else {
            setEditing(true)
            setSelectedInstructor(i);
        }
    }

    const getInstructors = async () => {
        const getInstructorsResponse = await fetch(BASE_URL_API + "/getInstructors",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token
                })
            });

        if (!getInstructorsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getInstructorsRespo = await getInstructorsResponse.json();
            if (getInstructorsRespo.status === "success") {
                setInstructorsData(getInstructorsRespo.response)
            } else {
                if(getInstructorsRespo.message=="No records found!") {
                    setInstructorsData(null);
                } else {
                    console.log(getInstructorsRespo)
                }
            }
        }
    }


    useEffect(() => {
        getInstructors();
    }, [])

    const updateStatusHandler = async () => {
        setIsUpdating(true)
        const updateStatusResponse = await fetch(BASE_URL_API + "/updateInstructorStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    instructorID: selectedInstructor.instructorID
                })
            });

        if (!updateStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateStatusRespo = await updateStatusResponse.json();
            if (updateStatusRespo.status === "success") {
                toast.success("Successfully Updated")
                setSelectedInstructor((pre) => { return ({ ...pre, ["isActive"]: selectedInstructor.isActive == 1 ? 0 : 1 }) });
                getInstructors();
            } else {
                toast.warn("Updation failed");
                // console.log(updateStatusRespo);
            }
        }
        setIsUpdating(false)
    }

    const deleteProfileHandler = async () => {
        setIsDeleting(true);
        const deleteInstructorResponse = await fetch(BASE_URL_API + "/deleteInstructor",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    instructorID: selectedInstructor.instructorID
                })
            });

        if (!deleteInstructorResponse.ok) {
            toast.error("Something went wrong : Server Error! Please refresh page & try again!")
        } else {
            const deleteInstructorRespo = await deleteInstructorResponse.json();
            if (deleteInstructorRespo.status === "success") {
                setEditing(false)
                toast.success("Profile successfully deleted!");
                getInstructors();
            } else {
                toast.warn("Instructor profile deletion failed, please try again!");
            }
        }
        setIsDeleting(false);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={editing === true ? styles.leftContainerEditing : styles.leftContainer}>
                {instructorsData !== null &&
                    <div className={styles.instructorsWrapper}>
                        {instructorsData.map((item, index) => (
                            <div className={styles.eachInstructorContainer} onClick={() => selectInstructorHandler(item)} key={index}>
                                <div className={styles.instructorProfilePic}>
                                    <img src={item.instructorProfilePic} />
                                </div>
                                <div className={styles.instructorDetails}>
                                    {item.instructorName}
                                    <div>
                                        {item.instructorCategory}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div className={styles.instructorsWrapper}>
                    <div className={styles.eachInstructorContainer}>
                        <Link to="/add-instructor">
                            Add New Instructor
                        </Link>
                    </div>
                </div>
            </div>
            <div className={editing === true ? styles.rightContainerEditing : styles.rightContainer}>
                <div className={styles.rightContainerWrapper}>
                    <div>
                        <img src={selectedInstructor.instructorProfilePic} className={styles.bigProfilePic} />
                    </div>
                    <div className={styles.instructorCategory}>
                        <div className={styles.smallTag}>
                            {selectedInstructor.instructorGender}
                        </div>
                        <div className={styles.smallTagCat}>
                            {selectedInstructor.instructorCategory}
                        </div>
                    </div>
                    <div className={styles.bigInstructorName}>
                        {selectedInstructor.instructorName}
                    </div>
                    <div className={styles.instructorDescription}>
                        {selectedInstructor.instructorDescription}
                    </div>

                    <div className={styles.otherDetails}>
                        {/* hi */}
                    </div>

                </div>
                <ToastContainer />
                <div className={styles.rightContainerFooter}>
                    <div className={styles.actionContainer}>
                        {selectedInstructor.isActive == 0 ?
                            <>
                                {isUpdating ?
                                    <ShimmerButton className={styles.activeBtn} />
                                    :
                                    <button className={styles.activeBtn} onClick={() => updateStatusHandler()}>Make Active</button>
                                }
                            </>
                            :
                            <>
                                {isUpdating ?
                                    <ShimmerButton className={styles.deActiveBtn} />
                                    :
                                    <button className={styles.deActiveBtn} onClick={() => updateStatusHandler()}>Make De-active</button>
                                }
                            </>
                        }

                        {isDeleting ?
                            <ShimmerButton />
                            :
                            <button className={styles.deleteBtn} onClick={() => deleteProfileHandler()}>
                                Delete Profile
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageInstructors;