import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls'
import AuthContext from '../../../store/auth-context'
import styles from './JTApplicationsData.module.css'
import JTApplicationsDataEachRow from './JTApplicationsDataEachRow'

const JTApplicationsData = props => {
    const { isOpen, viewApplicationHandler, jobIdentifier } = props
    const authCtx = useContext(AuthContext)

    const [applications, setApplications] = useState([])

    const getApplicationsForEachJob = async () => {
        console.log("Collecting applications")
        const getApplicationsForEachJobResponse = await fetch(BASE_URL_API + "/getApplicationsForEachJob",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier
                })
            });

        if (!getApplicationsForEachJobResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationsForEachJobRespo = await getApplicationsForEachJobResponse.json()
            if (getApplicationsForEachJobRespo.status === "success") {
                setApplications(getApplicationsForEachJobRespo.response)
            } else {
                if (getApplicationsForEachJobRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setApplications([])
                }
            }
            console.log(getApplicationsForEachJobRespo)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getApplicationsForEachJob()
        }
    }, [jobIdentifier, isOpen])



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainHeaderWrapper}>
                        <button onClick={() => viewApplicationHandler(false)}>
                            Close
                        </button>
                        {jobIdentifier}
                    </div>

                    <div className={styles.mainBodyWrapper}>
                        <div className={styles.tableHeaderWrapper}>
                            <div className={styles.sn}>
                                #
                            </div>
                            <div className={styles.cn}>
                                Candidate Name
                            </div>
                            <div className={styles.phone}>
                                Phone
                            </div>
                            <div className={styles.gender}>
                                Gender
                            </div>
                            <div className={styles.appliedOn}>
                                Applied On
                            </div>
                            <div className={styles.appliedBy}>
                                Applied By
                            </div>
                            <div className={styles.currentStatus}>
                                Current Status
                            </div>
                            <div className={styles.currentStatus}>
                                Actions
                            </div>
                        </div>
                        <div className={styles.tableBodyWrapper}>
                            {applications?.length > 0 ?
                                <div>
                                    {applications?.map((item, index) => (
                                        <JTApplicationsDataEachRow key={index} item={item} index={index} />
                                    ))}
                                </div>
                                :
                                <div>
                                    No records found!
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JTApplicationsData