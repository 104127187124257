import React, { useContext, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../LeadFilteration/LfHomeEachRow.module.css'
import { ToastContainer, toast } from 'react-toastify';

const LfHomeEachRow = ({ item, index, enquireType }) => {
    const authCtx = useContext(AuthContext)
    const [isAdding, setIsAdding] = useState(false)
    const [hasAdded, setHasAdded] = useState(false)
    const [hasAlreadyAdded, setHasAlreadyAdded] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const addToLeadHandler = async (k, fullName, location) => {
        // console.log(k, authCtx)
        setIsAdding(true)
        if (enquireType === "UNREGISTERED") {
            const addToCourseLeadResponse = await fetch(BASE_URL_API + "/addToCourseLeadUnregistered",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        executiveIdentifier: authCtx.userIdentifier,
                        phone: k,
                        fullName :fullName,
                        location : location
                    })
                });

            if (!addToCourseLeadResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const addToCourseLeadRespo = await addToCourseLeadResponse.json();
                if (addToCourseLeadRespo.status === "success") {
                    toast("Successfully added to the row!");
                    setHasAdded(true)
                } else {
                    if (addToCourseLeadRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        if(addToCourseLeadRespo.message==="lead has already been added") {
                            setHasAlreadyAdded(true)
                        } else {
                            console.log(addToCourseLeadRespo)
                        }
                    }
                }
            }


        } else {
            const addToCourseLeadResponse = await fetch(BASE_URL_API + "/addToCourseLead",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        executiveIdentifier: authCtx.userIdentifier,
                        stylistIdentifier: k
                    })
                });

            if (!addToCourseLeadResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const addToCourseLeadRespo = await addToCourseLeadResponse.json();
                if (addToCourseLeadRespo.status === "success") {
                    toast("Successfully added to the row!");
                    setHasAdded(true)
                } else {
                    if (addToCourseLeadRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        if(addToCourseLeadRespo.message==="lead has already been added") {
                            setHasAlreadyAdded(true)
                        } else {
                            console.log(addToCourseLeadRespo)
                        }
                    }
                }
            }

        }

        setIsAdding(false)
    }


    return (
        <>
            {enquireType === "UNREGISTERED" ?
                <div className={isActive === true ? styles.eachRowActive : styles.eachRow} onClick={() => setIsActive(!isActive)}>
                    <div className={styles.srCol}>{index + 1}</div>
                    <div className={styles.uiCol}>
                        <div>
                            {item?.fullName}
                        </div>
                        <div className={styles.phoneNumber}>
                            {item?.phoneNumber}
                        </div>
                    </div>
                    <div className={styles.rmCol}>{item?.address}</div>
                    <div className={styles.dateCol}>{humanReadableTimePassed(item.addedOn)}</div>
                    <div className={styles.actionCol}>
                        {!isAdding ?
                            hasAdded ? "Added" : ( hasAlreadyAdded===true ? "Already added!" : <button onClick={() => addToLeadHandler(item?.phoneNumber, item?.fullName, item?.address)}>Add To Lead</button>)
                            :
                            <button>Loading...</button>
                        }
                    </div>
                </div>
                :
                <div className={isActive === true ? styles.eachRowActive : styles.eachRow} onClick={() => setIsActive(!isActive)}>
                    <div className={styles.srCol}>{index + 1}</div>
                    <div className={styles.uiCol}>{item.stylistIdentifier}</div>
                    <div className={styles.rmCol}>{item.content}</div>
                    <div className={styles.dateCol}>{humanReadableTimePassed(item.addedOn)}</div>
                    <div className={styles.actionCol}>
                        {!isAdding ?
                            hasAdded ? "Added" : ( hasAlreadyAdded===true ? "Already added!" : <button onClick={() => addToLeadHandler(item.stylistIdentifier)}>Add To Lead</button>)
                            :
                            <button>Loading...</button>
                        }
                    </div>
                </div>
            }

        </>
    )
}

export default LfHomeEachRow