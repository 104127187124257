import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './FTAllPartners.module.css'

const FTAllPartners = () => {
    const authCtx = useContext(AuthContext)
    const [allPartners, setAllPartners] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const ftgetAllAcademicPartners = async () => {
        setIsFetching(true)
        const ftgetAllAcademicPartnersResponse = await fetch(BASE_URL_API + "/ftgetAllAcademicPartners",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!ftgetAllAcademicPartnersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const ftgetAllAcademicPartnersRespo = await ftgetAllAcademicPartnersResponse.json()
            if (ftgetAllAcademicPartnersRespo.status === "success") {
                setAllPartners(ftgetAllAcademicPartnersRespo.response)
            } else {
                if (ftgetAllAcademicPartnersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllPartners([])
                }
            }
            console.log(ftgetAllAcademicPartnersRespo)
        }
        setIsFetching(false)
    }

    useEffect(() => {
        ftgetAllAcademicPartners()
    }, [])


    return (
        <div>
            <div>
                <div>
                    {isFetching === true ?
                        <div>
                            Please wait, fetching all partners.....
                        </div>
                        :
                        <div>
                            <div className={styles.eachRowWrapper}>
                                <div className={styles.brandName}>Brand Name</div>
                                <div className={styles.firmName}>Firm</div>
                                <div className={styles.officialAddress}>Official Address</div>
                                <div className={styles.actionWrapper}>Action</div>
                            </div>
                            {allPartners?.map((item, index) => (
                                <div key={index} className={styles.eachRowWrapper}>
                                    <div className={styles.brandName}>
                                        {item?.brandName}
                                    </div>
                                    <div className={styles.firmName}>
                                        {item?.firmName}
                                    </div>
                                    <div className={styles.officialAddress}>
                                        {item?.officialAddress}
                                    </div>
                                    <div className={styles.actionWrapper}>
                                        <Link to={`/ft-partner/${item?.partnersIdentifier}`}>
                                            <button>Go To Partner's Account</button>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FTAllPartners