import React, { useContext, useEffect, useState } from 'react'
import { IoRemoveSharp } from 'react-icons/io5';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbSkills.module.css'


import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import EachSkillToBeAddedWrapper from './EachSkillToBeAddedWrapper';
import RbSkillAddNewHolder from './RbSkillAddNewHolder';

const RbSkills = props => {
    let selectedProfileType = props.selectedProfileType;
    const [selectedGender, setSelectedGender] = useState('Male')

    const [skillsDataMale, setSkillsDataMale] = useState([])
    const [skillsDataFemale, setSkillsDataFemale] = useState([])




    let selectedUser = props.selectedUser;
    const authCtx = useContext(AuthContext)
    const [fetchingAddedData, setFetchingAddedData] = useState(true)


    const getAddedData = async () => {
        setFetchingAddedData(true)
        const getAddedDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'skills'
                })
            });

        if (!getAddedDataResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getAddedDataRespo = await getAddedDataResponse.json();
            if (getAddedDataRespo.status === 'success') {
                setSkillsDataMale(getAddedDataRespo.skillResultsMale);
                setSkillsDataFemale(getAddedDataRespo.skillResultsFemale);
            } else {
                if (getAddedDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getAddedDataRespo);
                }
            }
            // console.log(getAddedDataRespo);
        }
        setFetchingAddedData(false)
    }

    useEffect(() => {
        getAddedData()
    }, [selectedUser])





    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType}
                </div>
                <div className={styles.bodyWrapper}>
                    <div className={styles.toggleSwitchWrapper}>
                        <button className={selectedGender === 'Male' ? styles.toggleBtnSelected : styles.toggleBtn}
                            onClick={() => setSelectedGender('Male')}
                        >
                            Skills For Male ({skillsDataMale !== null && skillsDataMale.length})
                        </button>
                        <button className={selectedGender === 'Female' ? styles.toggleBtnSelected : styles.toggleBtn}
                            onClick={() => setSelectedGender('Female')}
                        >
                            Skills For Female ({skillsDataFemale !== null && skillsDataFemale.length})
                        </button>
                    </div>

                    {fetchingAddedData === true ?
                        <div className={styles.loadersWrapper}>
                            <ShimmerThumbnail height={35} className={styles.containerLoader} />
                            <ShimmerThumbnail height={35} className={styles.containerLoader} />
                            <ShimmerThumbnail height={35} className={styles.containerLoader} />
                            <ShimmerThumbnail height={35} className={styles.containerLoader} />
                        </div>
                        :
                        <div>
                            {selectedGender === 'Male' &&
                                <div className={styles.skillsWrapper}>
                                    {skillsDataMale === null ?
                                        <div>
                                            No skills added!
                                        </div>
                                        :
                                        <>
                                            {skillsDataMale.map((item, index) => (
                                                <div className={styles.eachSkillWrapper} key={item.skill_uniq_id}>
                                                    <div>{item.skill}</div>
                                                    <div className={styles.removeBtn}>
                                                        <IoRemoveSharp />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                            }
                            {selectedGender === 'Female' &&
                                <div className={styles.skillsWrapper}>
                                    {skillsDataFemale === null ?
                                        <div>
                                            No skills added!
                                        </div>
                                        :
                                        <>
                                            {skillsDataFemale.map((item, index) => (
                                                <div className={styles.eachSkillWrapper} key={index + "FEMALE"}>
                                                    <div>{item.skill}</div>
                                                    <div className={styles.removeBtn}>
                                                        <IoRemoveSharp />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>
            <div className={styles.rightWrapper}>
                <RbSkillAddNewHolder
                    selectedUser={selectedUser}
                    getAddedData={getAddedData}
                />
            </div>
        </div>

    )
}

export default RbSkills