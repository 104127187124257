import React, { useContext, useEffect, useState } from 'react'
import PlotDataForStylistPlacements from '../../Components/Analytics/PlotDataForStylistPlacements'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../Analytics/KPIs.module.css'
import PlotData from './PlotData'
import PlotDataForStylistRegistration from './PlotDataForStylistRegistration'


const KPIs = () => {
    const authCtx = useContext(AuthContext)
    const [secondaryVisible, setSecondaryVisible] = useState(false)
    const [primaryKpis, setPrimaryKpis] = useState(null)
    const [secondaryKpis, setSecondaryKpis] = useState(null)
    const [timeFrame, setTimeFrame] = useState("Today")
    const [plotType, setPlotType] = useState("")

    const getPrimaryKpisRecruitment = async () => {
        const getPrimaryKpisRecruitmentResponse = await fetch(BASE_URL_API + "/kpisForRecruitment",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    timeFrame: timeFrame
                })
            });

        if (!getPrimaryKpisRecruitmentResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getPrimaryKpisRecruitmentRespo = await getPrimaryKpisRecruitmentResponse.json();
            if (getPrimaryKpisRecruitmentRespo.status === "success") {
                setPrimaryKpis(getPrimaryKpisRecruitmentRespo.response)
            } else {
                if (getPrimaryKpisRecruitmentRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setPrimaryKpis(null)
                }
            }
            console.log(getPrimaryKpisRecruitmentRespo)
        }
    }

    useEffect(() => {
        getPrimaryKpisRecruitment()
    }, [timeFrame])

    const getKpisForRecruitmentSecondary = async () => {
        const getKpisForRecruitmentSecondaryResponse = await fetch(BASE_URL_API + "/kpisForRecruitmentSecondary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    timeFrame: timeFrame
                })
            });

        if (!getKpisForRecruitmentSecondaryResponse.ok) {

        } else {
            const getKpisForRecruitmentSecondaryRespo = await getKpisForRecruitmentSecondaryResponse.json();
            if (getKpisForRecruitmentSecondaryRespo.status === "success") {
                setSecondaryKpis(getKpisForRecruitmentSecondaryRespo.response)
            } else {
                if (getKpisForRecruitmentSecondaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    setSecondaryKpis(null)
                    console.log(getKpisForRecruitmentSecondaryRespo)
                }
            }
        }
    }

    useEffect(() => {
        getKpisForRecruitmentSecondary()
    }, [timeFrame])

    

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div></div>
                <div>
                    <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} >
                        <option>Today</option>
                        <option>Yesterday</option>
                        <option>This Week</option>
                        <option>Last Week</option>
                        <option>This Month</option>
                        <option>Last Month</option>
                        <option>This Year</option>
                        <option>Last Year</option>
                        <option>Lifetime</option>
                    </select>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.mainTitle}>
                    <div>
                        KPIs
                    </div>
                    <div className={styles.mainTitleCaption}>
                        &nbsp;-&nbsp;Understand how you are executing!
                    </div>
                </div>
                <div className={styles.priIndicatorsSections}>
                    <div className={styles.indicatorSectionTitle}>
                        {secondaryVisible === true && "Primary"} Indicators
                    </div>

                    <div className={styles.priIndicators}>
                        <div className={styles.eachPriIndicator} onClick={() => setPlotType("STYLIST_PLACEMENTS")} >
                            <div>{primaryKpis?.hired}</div>
                            <div>Placements</div>
                        </div>
                        <div className={styles.eachPriIndicator} onClick={() => setPlotType("STYLIST_REGISTRATION")}>
                            <div>{primaryKpis?.totalUsers}</div>
                            <div>Stylists</div>
                        </div>
                        <div className={styles.eachPriIndicator} onClick={() => setPlotType("JOBS")}>
                            <div>{primaryKpis?.totalOpenings}</div>
                            <div>Jobs</div>
                        </div>
                    </div>

                    <div className={styles.toggleIndiBtn}>
                        <button className={styles.toggleBtn} onClick={() => setSecondaryVisible(!secondaryVisible)}>Show Secondary Indicators</button>
                    </div>

                </div>

                {/* <PlotData /> */}

                {plotType==="STYLIST_REGISTRATION" && <PlotDataForStylistRegistration />}
                {plotType==="STYLIST_PLACEMENTS" && <PlotDataForStylistPlacements />}

                {secondaryVisible &&
                    <div className={styles.secondaryWrapper}>
                        <div className={styles.secondaryMainTitle}>Secondary Indicators</div>
                        <div className={styles.secondaryMainBody}>
                            <div className={styles.eachColSecInd}>
                                {secondaryKpis?.map((item, index) => {
                                    if (item.applicationStatus === "APPLIED") {
                                        return (
                                            <div className={styles.eachSecIndicator} key={index}>
                                                <div>
                                                    {item.volume}
                                                </div>
                                                <div>
                                                    {item.applicationStatus}
                                                </div>
                                            </div>
                                        )
                                    } else if (item.applicationStatus === "INTERVIEW_SLOT_BOOKED") {
                                        return (
                                            <div className={styles.eachSecIndicator} key={index}>
                                                <div>
                                                    {item.volume}
                                                </div>
                                                <div>
                                                    Interviews Scheduled
                                                </div>
                                            </div>
                                        )
                                    } else if (item.applicationStatus === "HIRED") {
                                        return (
                                            <div className={styles.eachSecIndicator} key={index}>
                                                <div>
                                                    {item.volume}
                                                </div>
                                                <div>
                                                    {item.applicationStatus}
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                )}
                            </div>
                            <div className={styles.eachColSecInd}>

                            </div>
                            <div className={styles.eachColSecInd}>

                            </div>

                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default KPIs