import React, { useState } from 'react'
import styles from './CourseLevelInterestForm.module.css'
import EachCourseLevelInterestForm from './EachCourseLevelInterestForm'

const CourseLevelInterestForm = props => {
    const {leadIdentifier,  activeProgram, activeDomain } = props
    const [scaleHolder, setScaleHolder] = useState([
        {
            level : 'BASIC',
            scale : null
        },
        {
            level : 'INTERMEDIATE', 
            scale : null 
        },
        {
            level : 'ADVANCE', 
            scale : null 
        }
    ])
   
    const updateLevelHandler = (level, scale) => setScaleHolder(prevState => prevState.map(item => (item.level === level ? { ...item, scale } : item)));
    
    console.log(scaleHolder)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.levelOfKnowledge}>
                <EachCourseLevelInterestForm leadIdentifier={leadIdentifier} activeProgram={activeProgram} activeDomain={activeDomain} level="BASIC" scaleHolder={scaleHolder} updateLevelHandler={updateLevelHandler} />
                <EachCourseLevelInterestForm leadIdentifier={leadIdentifier} activeProgram={activeProgram} activeDomain={activeDomain} level="INTERMEDIATE" scaleHolder={scaleHolder} updateLevelHandler={updateLevelHandler} />
                <EachCourseLevelInterestForm leadIdentifier={leadIdentifier} activeProgram={activeProgram} activeDomain={activeDomain} level="ADVANCE" scaleHolder={scaleHolder} updateLevelHandler={updateLevelHandler} />
            </div>
        </div>
    )
}

export default CourseLevelInterestForm