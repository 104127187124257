import React, { useContext, useEffect, useState } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { IoMdArrowBack } from 'react-icons/io'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import ResumeBuildingHome from '../../Components/Jobs/ResumeBuilding/ResumeBuildingHome'
import ApplicationStatusSummary from '../../Components/ManageUser/InRecruitmentDomain/ApplicationStatusSummary'
import ImageUploader from '../../Components/ManageUser/InRecruitmentDomain/ImageUploader'
import UpdateCourseInterestLevel from '../../Components/ManageUser/InRecruitmentDomain/UpdateCourseInterestLevel'
import UpdateJobUrgencyLevel from '../../Components/ManageUser/InRecruitmentDomain/UpdateJobUrgencyLevel'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../ManageUsers/UserProfileGrow.module.css'

const UserProfileGrow = () => {
    const authCtx = useContext(AuthContext);
    let params = useParams();
    const [selectedUser, setSelectedUser] = useState(null)
    // const selectedUser = { stylist_id : params.userId }

    const [courseInterestLevelOpen, setCourseInterestLevelOpen] = useState(false)
    const abortCourseInterestLevelMission = () => setCourseInterestLevelOpen(false)

    const [urgencyLevelOpen, setUrgencyLevelOpen] = useState(false)
    const abortUrgencyLevelMission = () => { setUrgencyLevelOpen(false) }

    const [selectedProfileType, setSelectedProfileType] = useState(null)
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(null);
    const [isLookingForJob, setIsLookingForJob] = useState(null);
    const [isSummary, setIsSummary] = useState(true)
    const [isUpdating, setIsUpdating] = useState(false)
    const [selectedApplicationStat, setSelectedApplicationStat] = useState('')
    const applicationStatTypeHandler = async (k) => {
        setSelectedApplicationStat(k)
        setSelectedProfileType(null)
        setIsSummary(false)
    }
    const [collectingWorkingStatus, setCollectingWorkingStatus] = useState(false)

    const [isUploadingPic, setIsUploadingPic] = useState(false)
    const toggleOpen = () => setIsUploadingPic(false)

    const optionsData = [
        {
            "icon": <FaUser />,
            "title": 'Personal Information',
            "key": '1'
        },
        {
            "icon": <FaUser />,
            "title": 'Education Qualifications',
            "key": '2'
        },
        {
            "icon": <FaUser />,
            "title": 'Skills',
            "key": '3'
        },
        {
            "icon": <FaUser />,
            "title": 'Certificates / Diplomas',
            "key": '4'
        },
        {
            "icon": <FaUser />,
            "title": 'Language Spoken',
            "key": '5'
        },
        {
            "icon": <FaUser />,
            "title": 'Preferences',
            "key": '6'
        },
        {
            "icon": <FaUser />,
            "title": 'Experiences',
            "key": '7'
        },
    ]

    const setSelectedProfileTypeHandler = async (k) => {
        setSelectedApplicationStat(null)
        setIsSummary(false)
        setSelectedProfileType(k)
    }

    const setLookingForJobHandler = async () => {
        setIsLookingForJob(!isLookingForJob)
    }
    const setCurrentWorkingHandler = async () => {
        setIsCurrentlyWorking(!isCurrentlyWorking)
    }

    const getStylistDetails = async () => {
        const getStylistDetailsResponse = await fetch(BASE_URL_API + "/getStylistDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: params.userId
                })
            });

        if (!getStylistDetailsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getStylistDetailsRespo = await getStylistDetailsResponse.json();
            if (getStylistDetailsRespo.status === 'success') {
                setSelectedUser(getStylistDetailsRespo.response)
            } else {
                if (getStylistDetailsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setSelectedUser(null)
                }
            }
            console.log(getStylistDetailsRespo)
        }
    }

    useEffect(() => {
        getStylistDetails()
    }, [params.userId])

    const getWorkingStatus = async () => {
        setCollectingWorkingStatus(true)
        const getWorkingStatusResponse = await fetch(BASE_URL_API + "/getWorkingStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id
                })
            });

        if (!getWorkingStatusResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getWorkingStatusRespo = await getWorkingStatusResponse.json();
            if (getWorkingStatusRespo.status === "success") {
                console.log(getWorkingStatusRespo);
                setIsLookingForJob(getWorkingStatusRespo?.isLookingForJob)
                setIsCurrentlyWorking(getWorkingStatusRespo?.isCurrentlyWorking)
            } else {
                if (getWorkingStatusRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();

                } else {
                    console.log(getWorkingStatusRespo);
                }
            }
        }
        setCollectingWorkingStatus(false)
    }

    useEffect(() => {
        if (selectedUser !== null) {
            getWorkingStatus()
        }
    }, [selectedUser])



    const toggleWorkingStatus = async (fieldType, fieldValue) => {
        // console.log(fieldType, fieldValue)
        setIsUpdating(true);
        const toggleWorkingStatusResponse = await fetch(BASE_URL_API + "/toggleWorkingStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    fieldType: fieldType,
                    fieldValue: fieldValue
                })
            });

        if (!toggleWorkingStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const toggleWorkingStatusRespo = await toggleWorkingStatusResponse.json();
            if (toggleWorkingStatusRespo.status === "success") {
                console.log(toggleWorkingStatusRespo)
                getWorkingStatus()
            } else {
                if (toggleWorkingStatusRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(toggleWorkingStatusRespo);
                }
            }
            setIsUpdating(false);
        }
    }

    const onImgUploadSuccess = () => {
        getStylistDetails()
        toast("Image uploaded successfully!")
    }

    console.log("isCurrentlyWorking : " + isCurrentlyWorking, "isLookingForJob : " + isLookingForJob)

    return (
        <div className={styles.mainContainer}>
            <ToastContainer  />
            <div className={styles.leftContainer}>
                {selectedUser !== null &&
                    <div className={styles.userBoxWrapper}>
                        <div className={styles.upperSectionWrapper}>
                            <div className={styles.backNavHeader}>
                                <div className={styles.backNavTitle}>Manage User Account</div>
                            </div>
                            <div className={styles.selectedUserWrapper}>
                                <div className={styles.userIconWrapper}>
                                    {selectedUser.stylist_profile_image !== '' ?
                                        <img src={selectedUser.stylist_profile_image} />
                                        :
                                        <FaUser size={26} />
                                    }
                                </div>
                                <div className={styles.selectedUserDetails}>
                                    <div className={styles.stylistName}>
                                        {selectedUser.stylist_name}
                                    </div>
                                    <div className={styles.stylistUsername}>
                                        {selectedUser.stylist_username}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                

                {selectedUser !== null &&
                    <div className={styles.leftMenuWrapper}>
                        {/* <ApplicationStatusSummary
                            selectedUser={selectedUser}
                            applicationStatTypeHandler={applicationStatTypeHandler}
                        /> */}

                        <div className={styles.updateLevelWrapper}>
                            <div className={styles.courseInterestLevel}
                                onClick={() => setCourseInterestLevelOpen(true)}
                            >
                                Update Course Interest Level
                            </div>
                            <div onClick={() => setUrgencyLevelOpen(true)}
                                className={styles.jobUrgencyLevel}
                            >
                                Update Job Urgency Level
                            </div>
                            <UpdateJobUrgencyLevel
                                isOpen={urgencyLevelOpen}
                                abortUrgencyLevelMission={abortUrgencyLevelMission}
                                stylistIdentifier={params.userId}
                            />
                            <UpdateCourseInterestLevel
                                isOpen={courseInterestLevelOpen}
                                abortCourseInterestLevelMission={abortCourseInterestLevelMission}
                                stylistIdentifier={params.userId}
                            />
                        </div>


                        <div className={styles.optionsWrapper}>
                            {optionsData.map((item, index) => (
                                <div className={styles.eachOptionWrapper} key={index}
                                    onClick={() => setSelectedProfileTypeHandler(item.title)}
                                >
                                    <div className={styles.optionLeftWrapper}>
                                        <div className={styles.optionIcon}>
                                            {item.icon}
                                        </div>
                                        <div className={styles.optionTxt}>
                                            {item.title}
                                        </div>
                                    </div>
                                    <div>
                                        <BsChevronRight />
                                    </div>
                                </div>
                            ))}

                        </div>

                        {isUpdating === false &&
                            <div className={styles.actionBtnWrapper}>
                                <div className={styles.actionBtnContainer}>
                                    <div className={styles.actionBtnCaption}>
                                        Actively looking for job?
                                    </div>
                                    {collectingWorkingStatus !== true &&
                                        <div>
                                            <button className={isLookingForJob === '1' ? styles.activeBtn : styles.defaultBtn} onClick={() => toggleWorkingStatus('isLookingForJob', '1')}>Yes</button>
                                            <button className={isLookingForJob === '0' ? styles.activeBtn : styles.defaultBtn} onClick={() => toggleWorkingStatus('isLookingForJob', '0')}>No</button>
                                        </div>
                                    }
                                </div>
                                <div className={styles.actionBtnContainer}>
                                    <div className={styles.actionBtnCaption}>
                                        Currently Working?
                                    </div>
                                    {collectingWorkingStatus !== true &&
                                        <div>
                                            <button className={isCurrentlyWorking === '1' ? styles.activeBtn : styles.defaultBtn} onClick={() => toggleWorkingStatus('isCurrentlyWorking', '1')}>Yes</button>
                                            <button className={isCurrentlyWorking === '0' ? styles.activeBtn : styles.defaultBtn} onClick={() => toggleWorkingStatus('isCurrentlyWorking', '0')}>No</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        <div className={styles.findJobsWrapper}>
                            <Link to={"/jobs-finder/" + params?.userId}>Find Jobs For Candidate</Link>
                        </div>
                    </div>
                }
            </div>

            <div className={styles.rightContainer}>
                <div>
                    {selectedProfileType === null ?
                        <div className={styles.activateResume}>
                            <Link to={`/manage-subscriptions/resume/${params?.userId}`}>Activate Resume</Link>
                        </div>
                        :
                        <div>
                            <ResumeBuildingHome
                                selectedUser={selectedUser}
                                selectedProfileType={selectedProfileType}
                            />
                        </div>
                    }
                </div>

                    <button onClick={() => setIsUploadingPic(true)}>Change Profile Pic</button>

                <div>
                    <ImageUploader 
                        isOpen={isUploadingPic} 
                        toggleOpen={toggleOpen} 
                        stylistIdentifier={params?.userId} 
                        onSuccess={onImgUploadSuccess}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserProfileGrow