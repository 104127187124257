import React, { useContext, useEffect, useState } from 'react'
import { BsArrowLeft } from "react-icons/bs";
import CandidateApplicationConditionalQuestionnaire from '../../../Content/JobApplications/CandidateApplicationConditionalQuestionnaire';
import { getAge, humanReadableTimePassed } from '../../../Helper/helpers';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from './CAEJWMCandidatureStatus.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CAEJWMCandidatureStatus = (props) => {
    const { stylistIdentifier, isOpen, onClose, jobIdentifier, activeProfile } = props
    const authCtx = useContext(AuthContext)
    const [statusData, setStatusData] = useState([])
    const [isApplying, setIsApplying] = useState(false)


    const [selectedActivityType, setSelectedActivityType] = useState('')

    const getCandidateApplicationRecords = async () => {
        const getCandidateApplicationRecordsResponse = await fetch(BASE_URL_API + "/getCandidateApplicationRecords",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!getCandidateApplicationRecordsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCandidateApplicationRecordsRespo = await getCandidateApplicationRecordsResponse.json()
            if (getCandidateApplicationRecordsRespo.status === "success") {
                setStatusData(getCandidateApplicationRecordsRespo.response)
            } else {
                if (getCandidateApplicationRecordsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStatusData([])
                }
            }
            console.log(getCandidateApplicationRecordsRespo)
        }
    }

    const applyNowForThisJob = async () => {
        setIsApplying(true)
        const applyNowForJobIndividualResponse = await fetch(BASE_URL_API + "/applyNowForJobIndividual",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    stylistIdentifier: stylistIdentifier,
                    jobIdentifier: jobIdentifier,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!applyNowForJobIndividualResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const applyNowForJobIndividualRespo = await applyNowForJobIndividualResponse.json()
            if (applyNowForJobIndividualRespo.status === "success") {
                toast("Successfully applied to this job!")
            } else {
                if (applyNowForJobIndividualRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(applyNowForJobIndividualRespo.message)
                }
            }
            console.log(applyNowForJobIndividualRespo)
        }
        setIsApplying(false)
    }


    useEffect(() => {
        getCandidateApplicationRecords()
    }, [stylistIdentifier])



    const updateApplicationCreationActivity = async (action, remark) => {
        const uacare = await fetch(BASE_URL_API + "/updateApplicationCreationActivity",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    action: action,
                    remark: remark,
                    jobIdentifier: jobIdentifier,
                    stylistIdentifier: stylistIdentifier
                })
            });

        if (!uacare.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const uacar = await uacare.json();
            if (uacar.status === 'success') {
                // getApplicationCreationActivitySummary()
            } else {
                if (uacar.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(uacar)
                }
            }
        }
    }


    useEffect(() => {
        isOpen && updateApplicationCreationActivity("VIEWED", "Executive opened candidate profile")
    }, [stylistIdentifier])



    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div className={styles.mainHeaderContainer}>
                            <div className={styles.leftHeaderWrapper}>
                                <div className={styles.backBtnWrapper} onClick={() => onClose()} >
                                    <BsArrowLeft size={24} />
                                </div>
                                <div className={styles.basicDetailsWrapper}>
                                    <div className={styles.profileWrapper}>
                                        <span className={styles.stylistName}>{activeProfile?.stylist_name}</span>,
                                        <span className={styles.birthdate}>&nbsp;{getAge(activeProfile?.stylist_birthdate)} years old,</span>
                                        <span className={styles.maritalStatus}>&nbsp;{activeProfile?.maritalStatus},</span>
                                        <span className={styles.gender}>&nbsp;{activeProfile?.stylist_gender}</span>
                                    </div>
                                    <div className={styles.currentLocation}>
                                        {activeProfile?.currentLocation}
                                    </div>
                                </div>
                                <div className={styles.basicDetailsWrapper}>
                                    <div>
                                        {activeProfile?.primaryWorkDomain} &nbsp;
                                        {activeProfile?.secondaryWorkDomain} &nbsp;
                                        {activeProfile?.tertiaryWorkDomain}
                                    </div>
                                    <div className={styles.currentLocation}>
                                        Minimum Salary Expectation : {activeProfile?.minimumSalaryExpectation}
                                    </div>
                                </div>
                                <div className={styles.registred}>
                                    Registered {humanReadableTimePassed(activeProfile?.registred_on)}.
                                </div>

                            </div>

                            <div className={styles.rightHeaderWrapper}>
                                {isApplying === false ?
                                    <>
                                        {statusData?.applications?.some((item) => item?.job_id === jobIdentifier) === true ?
                                            <button>Already Applied</button>
                                            :
                                            <button onClick={() => applyNowForThisJob()}>Apply Now</button>
                                        }
                                    </>
                                    :
                                    <button >Applying...</button>
                                }
                            </div>

                        </div>
                    </div>
                    <div className={styles.bodyWrapper}>
                        <div className={styles.bodyContainer}>
                            <div className={styles.eachColWrapper}>
                                <div className={styles.recentApplicationStatusHeaderTxt}>
                                    <div>
                                        Recent Application Status
                                    </div>
                                    <div className={styles.allStatuses}>
                                        <div className={styles.eachTagWrapper}>
                                            &nbsp;Applied {statusData?.applications?.length} times
                                        </div>
                                        <div className={styles.eachTagWrapper}>
                                            &nbsp;Rejected {statusData?.applications?.filter((item) => item?.is_rejected === "1")?.length} times
                                        </div>
                                        <div className={styles.eachTagWrapper}>
                                            &nbsp;Hired {statusData?.applications?.filter((item) => item?.is_hired === "1")?.length} times
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.statusWrapper}>
                                    {statusData?.applications?.map((item, index) => (
                                        <div key={index} className={styles.eachAppStatus}>
                                            <div >
                                                <div className={styles.topRowWrapper}>
                                                    <div className={styles.storeName}>
                                                        <a target="_blank" href={`/manage-stores/${item?.store_id}`}>
                                                            {item?.company_name}
                                                        </a>
                                                    </div>
                                                    <div className={styles.appliedOn} title={item?.applied_on}>
                                                        {humanReadableTimePassed(item?.applied_on)}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div>
                                                        {JSON.parse(item?.statusDetails)?.map((itemStatus, indexStatus) => (
                                                            <div key={indexStatus} className={styles.eachStatusWrapper} style={{ backgroundColor: `${itemStatus?.applicationStatus === 'REJECTED' && `#ff030329`}` }}>
                                                                <div >
                                                                    <div className={styles.topRowWrapper}>
                                                                        <div className={styles.applicationStatus} >
                                                                            {itemStatus?.applicationStatus}
                                                                        </div>
                                                                        <div className={styles.date}>
                                                                            {humanReadableTimePassed(itemStatus?.updatedOn)}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={styles.actionStatus}>
                                                                            {itemStatus?.actionStatus}
                                                                        </div>
                                                                        <div className={styles.actionRemarks}>
                                                                            {itemStatus?.actionRemarks}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <a target="_blank" className={styles.viewApplication} href={`/manage-application/${item?.applicationId}`}>View Application</a>
                                            </div>
                                            {/* {JSON.stringify(item)} */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.eachColWrapper}>
                                <div className={styles.lastConnectionHeaderTxt}>
                                    Last connection status for any job
                                </div>
                                <div className={styles.lastConnectionSummaryWrapper}>
                                    <div className={styles.allStats}>
                                        <div className={styles.statusSummaryRowWrapper}>
                                            <div onClick={() => setSelectedActivityType('VIEWED')}
                                                className={styles.eachOptionWrapper}
                                            >
                                                <div>
                                                    {statusData?.appActivity?.filter((item) => item?.action === 'VIEWED')?.length}
                                                </div>
                                                <div className={styles.optionCaption}>
                                                    Viewed
                                                </div>
                                            </div>
                                            <div onClick={() => setSelectedActivityType('CONTACTED')}
                                                className={styles.eachOptionWrapper}
                                            >
                                                <div>
                                                    {statusData?.appActivity?.filter((item) => item?.action === 'CONTACTED')?.length}
                                                </div>
                                                <div className={styles.optionCaption}>
                                                    Contacted
                                                </div>
                                            </div>
                                            <div onClick={() => setSelectedActivityType('HAD_CONVERSATION')}
                                                className={styles.eachOptionWrapper}
                                            >
                                                <div>
                                                    {statusData?.appActivity?.filter((item) => item?.action === 'HAD_CONVERSATION')?.length}
                                                </div>
                                                <div className={styles.optionCaption}>
                                                    Had Conversation
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.statusSummaryRowWrapper}>
                                            <div onClick={() => setSelectedActivityType('PROFILE_CONSIDERED')}
                                                className={styles.eachOptionWrapper}
                                            >
                                                <div>
                                                    {statusData?.appActivity?.filter((item) => item?.action === 'PROFILE_CONSIDERED')?.length}
                                                </div>
                                                <div className={styles.optionCaption}>
                                                    Profile Considered
                                                </div>
                                            </div>
                                            <div onClick={() => setSelectedActivityType('COULD_NOT_HAD_CONVERSATION')}
                                                className={styles.eachOptionWrapper}
                                            >
                                                <div>
                                                    {statusData?.appActivity?.filter((item) => item?.action === 'COULD_NOT_HAD_CONVERSATION')?.length}
                                                </div>
                                                <div className={styles.optionCaption}>
                                                    Could not had Conversation
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={styles.appActivitiesWrapper}>
                                    {statusData?.appActivity
                                        ?.filter((itemF) => !selectedActivityType || itemF?.action === selectedActivityType)
                                        .map((item, index) => (
                                            <div className={styles.eachAppActivityWrapper} key={index}>
                                                <div className={styles.oneLinerWrapper}>
                                                    <div className={styles.oneLinerTxt}>
                                                        <div>
                                                            {item?.action} for
                                                        </div>
                                                        <div>
                                                            <a href={`/create-applications/${item?.jobIdentifier}`} target="_blank"> {item?.title} </a> job
                                                        </div>
                                                        by {item?.firstName} {item?.lastName}
                                                    </div>
                                                    <div className={styles.actionOn}>
                                                        {humanReadableTimePassed(item?.actionOn)}
                                                    </div>
                                                </div>
                                                <div className={styles.remarksSmall}>
                                                    {item?.remarks}
                                                </div>
                                            </div>
                                        ))}

                                </div>
                            </div>
                            <div className={styles.eachColWrapper}>
                                <div className={styles.recentApplicationStatusHeaderTxt}>
                                    Update Calling Status
                                </div>
                                <CandidateApplicationConditionalQuestionnaire
                                    activeCandidate={stylistIdentifier}
                                    jobIdentifier={jobIdentifier}
                                    updateApplicationCreationActivity={updateApplicationCreationActivity}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default CAEJWMCandidatureStatus