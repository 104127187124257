import React from 'react'
import styles from '../../Styles/Components/Jobs/JobPreview.module.css'

const JobPreview = props => {
    const jobTitle = props.jobTitle
    const selectedSkills = props.selectedSkills
    const selectedJobType = props.selectedJobType
    const numOfOpenings = props.numOfOpenings
    const jobDescription = props.jobDescription
    const selectedRequiredQuali = props.selectedRequiredQuali;
    const requiredExperience = props.requiredExperience;
    const selectedGender = props.selectedGender;
    const minAge = props.minAge;
    const maxAge = props.maxAge;
    const selectedLanguage = props.selectedLanguage;


    const minSalary = props.minSalary
    const maxSalary = props.maxSalary
    const incentive = props.incentive
    const fnaOffering = props.fnaOffering

    const validateEntryFields = props.validateEntryFields
    

    return (
        <div className={styles.rcMainBody}>
            <div className={styles.eachFieldContainer}>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Job Title : </span>
                    {jobTitle}
                </div>
                <div>
                    {/* Job Category : {selectedJobCategory.category_name} */}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>
                        Skills Set : 
                    </span>
                    {selectedSkills.map((item, index) => (
                        JSON.parse(item).skill + ", "
                    ))}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Job Type : </span>
                    {selectedJobType}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Number of Openings : </span>
                    {numOfOpenings}
                </div>
                <div className={styles.pvEachField}>
                    <div className={styles.pvEachFieldTitle}>Job Description : </div>
                    <div className={styles.pvJd} dangerouslySetInnerHTML={{ __html: jobDescription }}>
                        {/* <textarea disabled>
                                                    {jobDescription}
                                                     </textarea> */}
                    </div>
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Qualifications : </span>
                    {selectedRequiredQuali}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Experience : </span>
                    {requiredExperience}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Gender : </span>
                    {selectedGender}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Age : </span>
                    {minAge} - {maxAge}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Language Spoken : </span>
                    {selectedLanguage}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Salary : </span>
                    {minSalary} - {maxSalary}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Incentives : </span>
                    {incentive}
                </div>
                <div className={styles.pvEachField}>
                    <span className={styles.pvEachFieldTitle}>Food & Accomodation : </span>
                    {fnaOffering}
                </div>
                <div className={styles.actionContainer}>
                    <button onClick={() => validateEntryFields()}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default JobPreview