import React, { useContext, useEffect, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from '../TasksCourse/CourseLeadsConverter.module.css'
import { useParams } from 'react-router'
import { BsPhone } from 'react-icons/bs'
import { FcPhone } from 'react-icons/fc'
import CourseActionWrapper from '../../Components/TasksCourse/CourseActionWrapper'
import EachMiniCourseLead from '../../Components/TasksCourse/EachMiniCourseLead'
import ScheduleAcademyVisit from '../../Components/TasksCourse/ScheduleAcademyVisit'
import UpdateLeadCourseLevelInterest from '../../Components/TasksCourse/UpdateLeadCourseLevelInterest'

const CourseLeadsConverter = () => {
    const { leadIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [currentLead, setCurrentLead] = useState('')
    const [availableLeads, setAvailableLeads] = useState([])
    const [isActionInitiatorOn, setIsActionInitiatorOn] = useState(false)
    const abortActionCreation = () => setIsActionInitiatorOn(false)

    const [scheduleAcademyVisitInitiated, setScheduleAcademyVisitInitiated] = useState(false)
    const abortScheduling = () => setScheduleAcademyVisitInitiated(false)

    const [isUlcliOpen, setIsUlcliOpen] = useState(false)
    const abortInterestUpdation = () => setIsUlcliOpen(false)

    const currentLeadHandler = (k) => {
        setCurrentLead(k)
    }

    const adminGetLeadsForCourseTask = async () => {
        const adminGetLeadsForCourseTaskResponse = await fetch(BASE_URL_API + "/adminGetLeadsForCourseTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetLeadsForCourseTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const adminGetLeadsForCourseTaskRespo = await adminGetLeadsForCourseTaskResponse.json()
            if (adminGetLeadsForCourseTaskRespo.status === "success") {
                setAvailableLeads(adminGetLeadsForCourseTaskRespo.response)
            } else {
                if (adminGetLeadsForCourseTaskRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAvailableLeads([])
                }
            }
            console.log(adminGetLeadsForCourseTaskRespo)

        }
    }

    const updateLeadAction = async (action, actionDetails) => {


        const updateLeadActionResponse = await fetch(BASE_URL_API + "/updateCourseLeadAction",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: currentLead.leadIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    action: action,
                    actionDetails: actionDetails
                })
            });

        if (!updateLeadActionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateLeadActionRespo = await updateLeadActionResponse.json();
            if (updateLeadActionRespo.status === 'success') {
                if (action === "LEAD_SKIPPED") {
                    setCurrentLead('')
                    adminGetLeadsForCourseTask()
                }
            } else {
                if (updateLeadActionRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateLeadActionRespo);
        }


    }

    useEffect(() => {
        adminGetLeadsForCourseTask()
    }, [])


    return (
        <div>
            <CourseActionWrapper
                updateLeadAction={updateLeadAction}
                leadIdentifier={leadIdentifier}
                abortActionCreation={abortActionCreation}
                isOpen={isActionInitiatorOn}
            />
            <ScheduleAcademyVisit 
                isOpen={scheduleAcademyVisitInitiated}
                abortScheduling={abortScheduling}
                leadIdentifier={leadIdentifier}
            />
            <UpdateLeadCourseLevelInterest 
                isOpen={isUlcliOpen}
                leadIdentifier={leadIdentifier}
                abortInterestUpdation={abortInterestUpdation}
            />
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.filterWrapper}>
                    <div className={styles.leftFilWrap}>
                        <button className={styles.sortBtn}>Sorting</button>
                        <button className={styles.filterBtn}>Filter</button>
                    </div>
                    <div className={styles.rightFilWrap}>
                        <button className={styles.incomingBtn}>Incoming Call?</button>
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.leadListCol}>
                        {availableLeads.length > 0 ?
                            availableLeads.map((item, index) => (
                                <EachMiniCourseLead leadIdentifier={leadIdentifier} item={item} key={index} currentLeadHandler={currentLeadHandler} />
                            ))
                            :
                            <div className={styles.noLeadsAvailable}>
                                No leads available
                            </div>
                        }
                    </div>
                    {leadIdentifier !== undefined || currentLead !== '' ?
                        <div className={styles.rightCol}>
                            <div className={styles.detailsWrapper}>
                                <div className={styles.personalInfoWrapper}>
                                    <div className={styles.topCardWrapper}>
                                        <div className={styles.tcwUpperRow}>
                                            Lead openend since
                                        </div>
                                        <div className={styles.tcwLowerRow}>
                                            <div className={styles.tagsRow}>
                                                {currentLead?.stylistIdentifier === '' ?
                                                    <div className={styles.notOnStylelink}>
                                                        Not on stylelink
                                                    </div>
                                                    :
                                                    <div className={styles.alreadyOnStylelink}>
                                                        Already on stylelink
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.nameLocWrapper}>
                                                <div className={styles.leadName}>
                                                    {currentLead?.fullName}
                                                </div>
                                                <div className={styles.location}>
                                                    {currentLead?.location}
                                                </div>
                                            </div>
                                            <div className={styles.phoneWrapper}>
                                                <div className={styles.phoneMask}>+91-88XX-XXX-XXX</div>
                                                {/* <a href={`tel:+$leadIdentifier`} >Call</a> */}
                                                <div>
                                                    {currentLead !== '' &&
                                                        <button className={styles.dialInitBtn} onClick={() => setIsActionInitiatorOn(true)} >
                                                            <FcPhone size={24} />
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.historyWrapper}>
                                    Lead history will appear here..
                                </div>
                            </div>
                            <div className={styles.actionStackCol}>
                                <div className={styles.eachActionWrapper}>
                                    <button className={styles.scheduleVisitBtn} 
                                        onClick={() => setScheduleAcademyVisitInitiated(true)} 
                                    >
                                        Schedule Visit
                                    </button>
                                </div>
                                <div className={styles.eachActionWrapper}>
                                    <button className={styles.shareCatalogueBtn}>Share Catalogue</button>
                                </div>
                                <div className={styles.eachActionWrapper}>
                                    <button className={styles.courseInterestBtn} onClick={() => setIsUlcliOpen(true)} >
                                        Update Interest
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.rightCol}>
                            <div className={styles.selectLeadToStart}>
                                Please select a lead to start!
                            </div>
                        </div>
                    }
                </div>


            </div>
        </div>
    )
}

export default CourseLeadsConverter