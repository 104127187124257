import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbPreferencesAddNew.module.css'
import RbPreferencesAddNewEachCard from './RbPreferencesAddNewEachCard';

const RbPreferencesAddNew = props => {
    let selectedUser = props.selectedUser
    const getAddedData = props.getAddedData
    const authCtx = useContext(AuthContext)
    let selectedProfileType = props.selectedProfileType
    const [citiesOptions, setCitiesOptions] = useState([])


    const getLocationsData = async () => {
        const getLocationsDataResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'raw',
                    sectionType: 'preferences',
                    requestingFor: 'cities',
                })
            });

        if (!getLocationsDataResponse.ok) {
            console.log("Something went wrong : Server Error!")
        } else {
            const getLocationsDataRespo = await getLocationsDataResponse.json();
            if (getLocationsDataRespo.status === "success") {
                setCitiesOptions(getLocationsDataRespo.response)
                // console.log(getLocationsDataRespo)
            } else {
                if (getLocationsDataRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getLocationsDataRespo);
                }
            }
        }
    }

    useEffect(() => {
        getLocationsData()
    }, [])




    return (
        <div className={styles.rightWrapper}>
            <div className={styles.rightHeaderWrapper}>
                Add new {selectedProfileType}
            </div>
            <div className={styles.rightBodyWrapper}>
                <div className={styles.citiesWrapper}>
                    {citiesOptions.map((item, index) => (
                        <RbPreferencesAddNewEachCard 
                            item={item}
                            key={index}
                            selectedUser={selectedUser}
                            getAddedData={getAddedData}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.rightFooterWrapper}>
                <div className={styles.rightFooterContainer}>
                    {/* <button className={styles.secondaryBtn}>Cancel</button>
                    <button className={styles.primaryBtn}>Save</button> */}
                </div>
            </div>
        </div>
    )
}

export default RbPreferencesAddNew