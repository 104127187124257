export const standardJobDescriptions = [
    {
        "jobTitle": "Beautician",
        "description": `As a <strong>Beautician</strong>, you will be responsible for providing a range of beauty services to clients, including skincare treatments, hair removal, and nail care. 
            You will consult with clients to understand their needs and preferences, recommend appropriate treatments, 
                and ensure their satisfaction with the services provided.
                <ul>
                <li>
                Discussion with clients about their desired beauty services and any skin concerns they might have.
                </li>
                <li>
                Provide a variety of facials, cleanups, bleaching, d-tan treatments, and other face services based on client needs.
                </li>
                <li>
                Offer manicures, pedicures, and other nail services to keep hands and feet looking beautiful.
                </li>
                <li>
                Perform waxing, threading, and other hair removal techniques with care and precision.
                </li>
                <li>
                Provide relaxing massages and other pampering treatments.
                </li>
                <li>
                Recommend personalized skincare routines and services to help clients achieve their goals.
                </li>
                <li>
                Stay updated on the latest beauty trends and products.
                </li>
                <li>
                Sanitize your workspace and tools before and after each client.
                </li>
                <li>
                Recommend additional salon services that clients might enjoy.
                </li>
                </ul>
                `
    },
    {
        "jobTitle": "Beauty Advisor / Consultant",
        "description": `As a Beauty Advisor/Consultant, you will assist customers in selecting beauty 
                products and provide expert advice on skincare, makeup, and other beauty-related concerns. 
                You will stay updated on the latest beauty trends and products, build rapport with customers, and drive sales through excellent customer service.
                Makeup magic: Show clients how to use makeup by demonstrating different techniques.
                Product pro: Recommend beauty products that match each client's needs and preferences.
                Knowledgeable expert: Explain product ingredients, application methods, and prices clearly to clients.
                Career guidance: Share your knowledge by recommending relevant courses offered by the academy (if applicable).
                Inspiring mentor: Offer career advice to students who are passionate about beauty (if applicable).
                Event organizer: Plan and manage career counseling events for students (if applicable).`
    },
    {
        "jobTitle": "Beauty Trainer",
        "description": `As a Beauty Trainer, you will conduct training sessions for beauty professionals to enhance their 
                skills and knowledge in various beauty treatments and techniques. You will develop training materials, demonstrate procedures, 
                and provide constructive feedback to trainees to help them improve their performance.
                        Mastery of the Craft: Possess in-depth knowledge of makeup and hairstyling techniques, from basic to advanced.
                        Teaching Expertise: Effectively explain concepts in a creative and engaging way, fostering a positive learning environment.
                        Curriculum Development: Create and update course schedules, training plans, and theory notes for students.
                        Motivational Mentor: Guide and inspire students to become exceptional makeup artists and hairstylists.
                        Trendsetter: Stay up-to-date on the latest trends in makeup, hairstyling, and beauty products, and integrate this knowledge into your teaching.
                        Technical Skills: Demonstrate proper makeup application, hairstyling techniques, and the use of beauty tools and equipment.
                        Student Success: Evaluate student progress, provide constructive feedback, and answer their questions.
                        Safety First: Uphold hygiene standards, follow health and safety regulations, and ensure a safe learning environment.`
    },
    {
        "jobTitle": "Eyelash Trainer",
        "description": "As an Eyelash Trainer, you will specialize in training beauty professionals in the art of eyelash extensions. You will teach techniques for applying extensions, selecting the right styles for clients, and maintaining lash health. You will also provide guidance on safety practices and client consultation."
    },
    {
        "jobTitle": "Gents Hairdresser / Hairstylist",
        "description": `As a <strong>Gents Hairdresser / Hair Stylist</strong>, you will cater to male clients, providing a range of 
            hair services such as haircuts, styling, and grooming treatments. 
            You will stay updated on current trends in men's hairstyles, offer personalized recommendations to clients, 
            and ensure a high level of customer satisfaction.
            <ul>
            <li>
            <strong>Consult with clients:</strong> Talk to clients about their desired haircut, beard style, and what they're looking for.
            </li>
            <li>
            <strong>Hairstyle mastery: </strong>Deliver a range of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li>
            <strong>Shampoo and style:</strong> Wash, condition, and blow-dry hair for a perfect finish.
            </li>
            <li>
            <strong>Color expertise:</strong> Mix and apply hair color, offering services like highlights, lowlights, or full color changes.
            </li>
            <li>
            <strong>Transformations:</strong> Provide additional services like beard trims, shaves, coloring, hair extensions, and chemical treatments (perms, straightening, etc.).
            </li>
            <li>
            <strong>Tool master: </strong> Use various styling tools like clippers, trimmers, straighteners, and blow dryers to achieve the client's vision.
            </li>
            <li>
            <strong>Cleanliness is key: </strong>Sanitize your workspace and tools before and after each client.
            </li>
            <li>
            <strong>Stay on top of trends:</strong> Keep up-to-date with the latest men's hairstyles and products.
            </li>
            <li>
            <strong>Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li>
            </ul>`
    },
    {
        "jobTitle": "Gents Hair Dresser",
        "description": `As a <strong>Gents Hairdresser / Hair Stylist</strong>, you will cater to male clients, providing a range of 
            hair services such as haircuts, styling, and grooming treatments. 
            You will stay updated on current trends in men's hairstyles, offer personalized recommendations to clients, 
            and ensure a high level of customer satisfaction.
            <ul>
            <li>
            <strong>Consult with clients:</strong> Talk to clients about their desired haircut, beard style, and what they're looking for.
            </li>
            <li>
            <strong>Hairstyle mastery: </strong>Deliver a range of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li>
            <strong>Shampoo and style:</strong> Wash, condition, and blow-dry hair for a perfect finish.
            </li>
            <li>
            <strong>Color expertise:</strong> Mix and apply hair color, offering services like highlights, lowlights, or full color changes.
            </li>
            <li>
            <strong>Transformations:</strong> Provide additional services like beard trims, shaves, coloring, hair extensions, and chemical treatments (perms, straightening, etc.).
            </li>
            <li>
            <strong>Tool master: </strong> Use various styling tools like clippers, trimmers, straighteners, and blow dryers to achieve the client's vision.
            </li>
            <li>
            <strong>Cleanliness is key: </strong>Sanitize your workspace and tools before and after each client.
            </li>
            <li>
            <strong>Stay on top of trends:</strong> Keep up-to-date with the latest men's hairstyles and products.
            </li>
            <li>
            <strong>Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li>
            </ul>`
    },
    {
        "jobTitle": "Female Hairdresser / Hairstylist",
        "description": `As a <strong>Female Hairdresser / Hair Stylist </strong>, you will specialize in providing hair services to female clients, 
            including cutting, coloring, styling, and treatments. 
            You will consult with clients to understand their preferences, recommend suitable hairstyles, and 
            deliver exceptional service to enhance their beauty.
            <ul>
            <li>
            <strong>Consult with clients:</strong> Chat with clients about their desired hairstyle, hair concerns, and what kind of look they're going for.
            </li>
            <li>
            <strong>Hairstyle expertise:</strong> Provide a variety of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li>
            <strong>Shampoo and style:</strong> Wash, condition, and blow-dry hair to perfection.
            </li>
            <li>
            <strong>Color magic:</strong> Mix and apply hair color for beautiful results, from simple highlights to complex coloring techniques.
            </li>
            <li>
            <strong>Hair transformation:</strong> Offer services like hair extensions, chemical straightening, perms, and other hair treatments.
            </li>
            <li>
            <strong>Tool mastery:</strong> Use a variety of styling tools like straighteners, curlers, and blow dryers to achieve the desired look.
            </li>
            <li>
            <strong>Hygiene matters:</strong> Sanitize your workspace and tools before and after each client.
            </li>
            <li>
            <strong>Stay on top of trends:</strong> Keep up-to-date with the latest hairstyles and products.
            </li>
            <li>
            <strong>Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li>
            </ul>
            `
    },
    {
        "jobTitle": "Female Hair Dresser",
        "description": `As a <strong>Female Hairdresser / Hair Stylist </strong>, you will specialize in providing hair services to female clients, 
            including cutting, coloring, styling, and treatments. 
            You will consult with clients to understand their preferences, recommend suitable hairstyles, and 
            deliver exceptional service to enhance their beauty.
            <ul>
            <li>
            <strong>Consult with clients:</strong> Chat with clients about their desired hairstyle, hair concerns, and what kind of look they're going for.
            </li>
            <li>
            <strong>Hairstyle expertise:</strong> Provide a variety of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li>
            <strong>Shampoo and style:</strong> Wash, condition, and blow-dry hair to perfection.
            </li>
            <li>
            <strong>Color magic:</strong> Mix and apply hair color for beautiful results, from simple highlights to complex coloring techniques.
            </li>
            <li>
            <strong>Hair transformation:</strong> Offer services like hair extensions, chemical straightening, perms, and other hair treatments.
            </li>
            <li>
            <strong>Tool mastery:</strong> Use a variety of styling tools like straighteners, curlers, and blow dryers to achieve the desired look.
            </li>
            <li>
            <strong>Hygiene matters:</strong> Sanitize your workspace and tools before and after each client.
            </li>
            <li>
            <strong>Stay on top of trends:</strong> Keep up-to-date with the latest hairstyles and products.
            </li>
            <li>
            <strong>Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li>
            </ul>
            `
    },
    {
        "jobTitle": "Unisex Hairdresser / Hairstylist",
        "description": `As a <strong>Unisex Hairdresser/Hair Stylist</strong>, you will cater to clients of all genders, 
            offering a wide range of hair services tailored to their needs. 
            You will demonstrate proficiency in various hair techniques, maintain a clean and organized workspace, 
            and ensure a welcoming environment for all clients.
<ul>
            <li><strong>
            Consult with clients:</strong> Chat with clients about their desired hairstyle, hair concerns, and what kind of look they're going for.
            </li>
            <li><strong>
            Hairstyle expertise:</strong> Provide a variety of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li><strong>
            Shampoo and style:</strong> Wash, condition, and blow-dry hair to perfection.
            </li>
            <li><strong>
            Color magic:</strong> Mix and apply hair color for beautiful results, from simple highlights to complex coloring techniques.
            </li>
            <li><strong>
            Hair transformation:</strong> Offer services like hair extensions, chemical straightening, perms, and other hair treatments.
            </li>
            <li><strong>
            Beard care:</strong> Provide beard trims, shaves, and coloring for male clients.
            </li>
            <li><strong>
            Tool mastery:</strong> Use a variety of styling tools like straighteners, curlers, and blow dryers to achieve the desired look.
            </li>
            <li><strong>
            Hygiene matters:</strong> Sanitize your workspace and tools before and after each client.
            </li>
            <li><strong>
            Stay on top of trends:</strong> Keep up-to-date with the latest hairstyles and products.
            </li>
            <li><strong>
            Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li >
            </ul>
            `
    },
    {
        "jobTitle": "Unisex Hair Dresser",
        "description": `As a <strong>Unisex Hairdresser/Hair Stylist</strong>, you will cater to clients of all genders, 
            offering a wide range of hair services tailored to their needs. 
            You will demonstrate proficiency in various hair techniques, maintain a clean and organized workspace, 
            and ensure a welcoming environment for all clients.
<ul>
            <li><strong>
            Consult with clients:</strong> Chat with clients about their desired hairstyle, hair concerns, and what kind of look they're going for.
            </li>
            <li><strong>
            Hairstyle expertise:</strong> Provide a variety of haircuts and styles, from basic trims to advanced techniques.
            </li>
            <li><strong>
            Shampoo and style:</strong> Wash, condition, and blow-dry hair to perfection.
            </li>
            <li><strong>
            Color magic:</strong> Mix and apply hair color for beautiful results, from simple highlights to complex coloring techniques.
            </li>
            <li><strong>
            Hair transformation:</strong> Offer services like hair extensions, chemical straightening, perms, and other hair treatments.
            </li>
            <li><strong>
            Beard care:</strong> Provide beard trims, shaves, and coloring for male clients.
            </li>
            <li><strong>
            Tool mastery:</strong> Use a variety of styling tools like straighteners, curlers, and blow dryers to achieve the desired look.
            </li>
            <li><strong>
            Hygiene matters:</strong> Sanitize your workspace and tools before and after each client.
            </li>
            <li><strong>
            Stay on top of trends:</strong> Keep up-to-date with the latest hairstyles and products.
            </li>
            <li><strong>
            Recommend extras:</strong> Suggest additional services clients might be interested in.
            </li >
            </ul>
            `
    },
    {
        "jobTitle": "Hair Trainer",
        "description": "As a Hair Trainer, you will be responsible for training aspiring hairdressers and stylists in the latest hair techniques and trends. You will develop curriculum materials, conduct practical demonstrations, and assess trainees' skills to ensure they meet industry standards."
    },
    {
        "jobTitle": "Helper",
        "description": `As a <strong>Helper</strong>, you will provide assistance to beauty professionals in carrying out their duties, 
                including preparing equipment and materials, maintaining cleanliness in the salon, and ensuring the comfort of clients. 
                You will support the team in delivering efficient and high-quality service.
                <li>
                    <strong>Support the team:</strong> Assist stylists and beauticians with their everyday tasks during client services.
                </li>
                <li>
                    <strong>Basic beauty pro:</strong> Perform basic services like waxing, threading, facials, cleanups, hair washing, and head massages (depending on your training).
                </li>
                <li>
                    <strong>Warm welcome:</strong> Greet clients warmly and professionally, making them feel comfortable.
                </li>
                <li>
                    <strong>Cleanliness champion:</strong> Ensure workstations are clean and all equipment is sanitized before, during, and after each client.
                </li>
                <li>
                    <strong>Hospitality hero:</strong> Offer refreshments like snacks and beverages to clients while they wait.
                </li>
                <li>
                    <strong>Tool master:</strong> Assist stylists and beauticians by keeping them well-stocked with the products and tools they need for each service.
                </li>`
    },
    {
        "jobTitle": "Make-up Artist",
        "description": `As a <strong>Make-up Artist</strong>, you will specialize in enhancing clients' facial features through skilled makeup application. 
                You will create customized looks for various occasions, such as weddings, parties, and photo shoots, 
                while ensuring the use of high-quality products and techniques.
                <ul>
                <li>
                    <strong>Consult with clients:</strong> Chat with clients about their desired makeup look, event, and any skin concerns.
                </li>
                <li>
                    <strong>Makeup magic:</strong> Apply makeup to enhance clients' natural beauty, offering a variety of styles like basic, party, glamorous, bridal, and even HD or airbrush techniques.
                </li>
                <li>
                    <strong>Prep like a pro:</strong> Set up your workstation with proper lighting to create the perfect makeup environment.
                </li>
                <li>
                    <strong>Client recommendations:</strong> Advise clients on makeup and skincare to achieve their desired look.
                </li>
                <li>
                    <strong>Active listener:</strong> Carefully listen to client needs and preferences to customize their makeup experience.
                </li>
                <li>
                    <strong>Hygiene first:</strong> Maintain a clean and sanitized workspace for client safety and comfort.
                </li>
                <li>
                    <strong>Organized and prepared:</strong> Ensure your makeup kit is well-stocked and ready for each client.
                </li>
                <li>
                    <strong>Eye for detail:</strong> Consider clients' outfits, skin tones, and facial features when recommending makeup styles.
                </li>
                <li>
                    <strong>Lash and lens application:</strong> Apply false lashes and lenses if needed to complete the client's desired look.
                </li>
                </ul>
                `
    },
    {
        "jobTitle": "Make-up Trainer",
        "description": "As a <strong>Make-up Trainer</strong>, you will conduct training sessions to educate aspiring makeup artists on techniques for creating flawless makeup looks. You will cover topics such as color theory, application methods, and product knowledge, and provide hands-on guidance to trainees to develop their skills."
    },
    {
        "jobTitle": "Manager",
        "description": `As a <strong>Manager</strong>, you will oversee the operations of the beauty salon, 
                including staff management, customer service, inventory management, and financial administration. 
                You will lead by example, foster a positive work environment, and ensure that the salon operates efficiently to meet business goals.
                Be the salon's captain: Oversee day-to-day operations, ensuring everything runs smoothly.
                <ul>
                <li>
                    <strong>Build your team:</strong> Hire and train talented stylists and staff to provide excellent service.
                </li>
                <li>
                    <strong>Inventory maestro:</strong> Manage salon supplies, products, payments, and overall financial operations.
                </li>
                <li>
                    <strong>Marketing whiz:</strong> Develop and implement marketing strategies to attract new clients and retain existing ones.
                </li>
                <li>
                    <strong>Communication pro:</strong> Clearly communicate with staff and clients to foster a positive work environment.
                </li>
                <li>
                    <strong>Conflict resolution expert:</strong> Address any salon conflicts professionally to ensure exceptional customer service.
                </li>
                <li>
                    <strong>Hygiene champion:</strong> Maintain a clean and sanitized salon environment according to safety standards.
                </li>
                <li>
                    <strong>Record-keeping master:</strong> Maintain accurate business records like daily services and monthly revenue reports.
                </li>
                <li>
                    <strong>Financial responsibility:</strong> Handle client payments, record transactions, and manage salon finances.
                </li>
                <li>
                    <strong>Stock up:</strong> Order necessary products and tools to keep the salon inventory well-stocked.
                </li>
                </ul>`
    },
    {
        "jobTitle": "Manicurist",
        "description": `As a Manicurist, you will specialize in providing nail care services, including manicures, pedicures, nail art, and treatments. 
            You will maintain a clean and sanitary work environment, stay updated on the latest nail trends and techniques, and provide 
            personalized service to clients.
            Chat with clients about what they want for their nails - shape, length, polish color.
            Give great manicures: Trim, shape, buff, and polish nails to perfection.
            Make feet feel amazing: Soak, clean, and massage feet to leave them relaxed and pampered.
            Recommend extras: Suggest things clients might like to add on, like treatments for weak nails.
            Keep things clean: Sanitize your workspace and tools after each client.`
    },
    {
        "jobTitle": "Massage Therapist",
        "description": `As a Massage Therapist, you will provide therapeutic massage treatments to clients 
            to promote relaxation, relieve tension, and improve overall well-being. 
            You will assess clients' needs, recommend appropriate massage techniques, and deliver professional and attentive service in a calming environment.
            Experienced Professional: Proven work experience as a spa therapist with expertise in massage techniques and face/body therapies.
            Sales Savvy: Sales experience is a considered asset.
            Client Focus: Possess excellent communication and customer service skills to create a relaxing and enjoyable spa experience.
            Treatment Expertise: Deliver a variety of spa services (body treatments, massages) safely and comfortably.
            Hygiene and Safety: Maintain the highest hygiene standards and adhere to health and safety regulations.
            Qualifications: Hold a degree or current license in aesthetics or physiotherapy (or equivalent).`
    },
    {
        "jobTitle": "Mehandi Artist",
        "description": "As a Mehandi Artist, you will specialize in the traditional art of applying intricate henna designs to clients' hands and feet for various cultural and celebratory occasions. You will demonstrate creativity, attention to detail, and cultural sensitivity in your designs, ensuring client satisfaction."
    },
    {
        "jobTitle": "Nail Art / Technician",
        "description": `As a <strong>Nail Art Technician</strong>, you will create stunning nail art designs to enhance clients' 
            manicures and pedicures. You will possess artistic flair, precision, and creativity in your work, using a variety of 
            techniques and tools to produce unique and eye-catching nail designs.
            <div>
            <ul>
            <li><strong>Nail Artist:</strong> Create Stunning Nails for Every Client!</li>
            <li>
                <strong>Consult with clients:</strong> Discuss their desired nail look, including length, shape, and any nail art preferences (gel, acrylic, 3D).
            </li>
            <li>
            <strong>Nail transformations:</strong> Perform manicures and pedicures, from basic shaping and polish to advanced techniques like extensions and intricate nail art.
            </li>
            <li>
            <strong>Master of design:</strong> Use your creativity and skills to bring clients' nail art ideas to life.
            </li>
            <li>
            <strong>Relaxing touch:</strong> Pamper clients with a soothing foot soak and massage during pedicures.
            </li>
            <li>
            <strong>Hygiene champion:</strong> Maintain a clean and sanitized workstation to ensure client safety.
            </li>
            <li>
            <strong>Expert recommendations:</strong> Advise clients on nail care and suggest additional services to address any concerns.
            </li>
            <li>
            <strong>Color perfectionist:</strong> Apply nail polish flawlessly for a smooth, long-lasting finish.
            </li>
            <li>
            <strong>Organized artist:</strong> Keep your workspace and tools neat and organized after each client.
            </li>
            <li>
            <strong>Removal pro:</strong> Safely remove existing nail polish, extensions, or permanent nail treatments.
            </li>
            </ul>
            </div>`
    },
    {
        "jobTitle": "Nail Art Technician",
        "description": `As a <strong>Nail Art Technician</strong>, you will create stunning nail art designs to enhance clients' 
            manicures and pedicures. You will possess artistic flair, precision, and creativity in your work, using a variety of 
            techniques and tools to produce unique and eye-catching nail designs.
            <div>
            <ul>
            <li><strong>Nail Artist:</strong> Create Stunning Nails for Every Client!</li>
            <li>
                <strong>Consult with clients:</strong> Discuss their desired nail look, including length, shape, and any nail art preferences (gel, acrylic, 3D).
            </li>
            <li>
            <strong>Nail transformations:</strong> Perform manicures and pedicures, from basic shaping and polish to advanced techniques like extensions and intricate nail art.
            </li>
            <li>
            <strong>Master of design:</strong> Use your creativity and skills to bring clients' nail art ideas to life.
            </li>
            <li>
            <strong>Relaxing touch:</strong> Pamper clients with a soothing foot soak and massage during pedicures.
            </li>
            <li>
            <strong>Hygiene champion:</strong> Maintain a clean and sanitized workstation to ensure client safety.
            </li>
            <li>
            <strong>Expert recommendations:</strong> Advise clients on nail care and suggest additional services to address any concerns.
            </li>
            <li>
            <strong>Color perfectionist:</strong> Apply nail polish flawlessly for a smooth, long-lasting finish.
            </li>
            <li>
            <strong>Organized artist:</strong> Keep your workspace and tools neat and organized after each client.
            </li>
            <li>
            <strong>Removal pro:</strong> Safely remove existing nail polish, extensions, or permanent nail treatments.
            </li>
            </ul>
            </div>`
    },
    {
        "jobTitle": "Nail Art Trainer",
        "description": `As a Nail Art Trainer, you will train aspiring nail technicians in the art of creating 
                intricate nail art designs. You will provide instruction on design techniques,
             product usage, and salon safety practices, fostering the development of students' creativity and technical skills.
             Nail Care Master: Possess in-depth knowledge and practical skills in various nail care techniques.
                Curriculum Guru: Develop and update training materials and curriculum to equip students with the latest knowledge.
                Safety Champion: Conduct live demonstrations emphasizing hygiene and safety practices in the nail care industry.
                Skill Evaluator: Assess student proficiency in nail care techniques and provide constructive feedback.
                Trend Tracker: Stay up-to-date on the latest industry trends and integrate them into your teaching.
                Product Knowledge: Familiarize trainees with various nail care products and equipment.
                Engaging Educator: Create a positive and motivational learning environment for students.
                Regulation Compliance: Ensure a safe learning environment by maintaining regulatory compliance.
                Communication Pro: Effectively communicate with trainees, colleagues, and management.`
    },
    {
        "jobTitle": "Pedicurist",
        "description": `As a <strong>Pedicurist</strong>, you will specialize in providing foot care services, 
            including pedicures, foot massages, and treatments. You will ensure the cleanliness and hygiene of your workspace, 
            recommend appropriate foot care products, and deliver personalized service to enhance clients' comfort and well-being.
            Chat with clients about how they want their feet pampered (nail shape, polish color, etc.).
            <li>
                <strong>Give amazing pedicures:</strong> Trim, shape, buff, and polish toenails for a beautiful finish.
            </li>
            <li>
                <strong>Soak away the stress:</strong> Relax clients with a warm foot soak, then remove dirt and dead skin.
            </li>
            <li>
                <strong>Foot massage magic:</strong> Provide a relaxing foot massage using proper techniques.
            </li>
            <li>
                <strong>Recommend extras:</strong> Suggest additional services clients might enjoy, like treatments for dry feet.
            </li>
            <li>
                <strong>Cleanliness is key:</strong> Sanitize your workspace and tools thoroughly after each client.
            </li>
        `
    },
    {
        "jobTitle": "Receptionist",
        "description": "As a <strong>Receptionist</strong>, you will be the first point of contact for clients visiting the salon, providing a warm welcome, managing appointments, and assisting with inquiries. You will maintain a professional and organized front desk area, handle administrative tasks, and ensure a positive experience for clients."
    }
]




export const skills = [
    {
        "categoryId": "",
        "skill": "Chemical Work",
        "ID": "4",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Body Polishing",
        "ID": "10",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Cross Selling",
        "ID": "11",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Mehandi",
        "ID": "16",
        "category": "",
        "for_gender": "2"
    },
    {
        "categoryId": "",
        "skill": "Airbrush",
        "ID": "17",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Product Knolwedge",
        "ID": "18",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Basic Haircut",
        "ID": "19",
        "category": "",
        "for_gender": "1"
    },
    {
        "categoryId": "",
        "skill": "Hair Spa",
        "ID": "21",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Advance Hairstyling",
        "ID": "22",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Pigmentation Removal",
        "ID": "27",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Perming",
        "ID": "29",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Deep Conditioning",
        "ID": "30",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Highlighting",
        "ID": "31",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Scalp Treatment",
        "ID": "32",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Head Massage",
        "ID": "35",
        "category": "",
        "for_gender": "1"
    },
    {
        "categoryId": "",
        "skill": "Head Wash",
        "ID": "39",
        "category": "",
        "for_gender": "2"
    },
    {
        "categoryId": "",
        "skill": "Removing Dead Skin",
        "ID": "40",
        "category": "",
        "for_gender": "1"
    },
    {
        "categoryId": "",
        "skill": "Light Massage",
        "ID": "41",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Polishing",
        "ID": "42",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Anti-Tan Pedicure",
        "ID": "43",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Crystal Spa",
        "ID": "44",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Nail Cliping",
        "ID": "45",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Cleaning Nails",
        "ID": "46",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Cutting Nails",
        "ID": "48",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "French Manicure",
        "ID": "49",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Gel Manicure",
        "ID": "50",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Neck Massage",
        "ID": "51",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Good Product Knowledge",
        "ID": "53",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Application Of Nail Polish",
        "ID": "56",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Callus Removing",
        "ID": "57",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Cuticle Grooming",
        "ID": "58",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Silk Strenghtner",
        "ID": "64",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Marketing",
        "ID": "67",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Build Clientele",
        "ID": "68",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Recruiting",
        "ID": "69",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Client Dealing Retention",
        "ID": "70",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Inventory Management",
        "ID": "71",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Excellent Communication Skills",
        "ID": "72",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Fluent in English",
        "ID": "73",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Eyelash Extention",
        "ID": "74",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Hair Wash",
        "ID": "75",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Friendly Attitude",
        "ID": "76",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Good Communication Skills",
        "ID": "78",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Microsoft Office",
        "ID": "79",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Basic Computer",
        "ID": "80",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Appointment Handeling",
        "ID": "81",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Cash Handeling",
        "ID": "82",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Customer Handeling",
        "ID": "83",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Admin work",
        "ID": "84",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Presentation Conducting",
        "ID": "86",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "",
        "skill": "Presentable",
        "ID": "87",
        "category": "",
        "for_gender": "0"
    },
    {
        "categoryId": "1",
        "skill": "Manicure",
        "ID": "2",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "D-Tan",
        "ID": "3",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Bleach",
        "ID": "5",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Facial",
        "ID": "6",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Waxing",
        "ID": "7",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Threading",
        "ID": "8",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Cleanup",
        "ID": "9",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Facial",
        "ID": "89",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Bleach",
        "ID": "90",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Hydra Facial",
        "ID": "240",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Machine Facial",
        "ID": "241",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Beauty Councelor",
        "ID": "242",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "D-Tan",
        "ID": "247",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Clean-up",
        "ID": "248",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Hydra Facial",
        "ID": "249",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Machine Facial",
        "ID": "250",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Waxing",
        "ID": "251",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Bikini Waxing",
        "ID": "252",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Threading",
        "ID": "255",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "1",
        "skill": "Body Polishing",
        "ID": "262",
        "category": "Beautician",
        "for_gender": "1"
    },
    {
        "categoryId": "1",
        "skill": "Body Polishing",
        "ID": "263",
        "category": "Beautician",
        "for_gender": "2"
    },
    {
        "categoryId": "10",
        "skill": "Hospitality",
        "ID": "274",
        "category": "Receptionist",
        "for_gender": "1"
    },
    {
        "categoryId": "10",
        "skill": "Hospitality",
        "ID": "275",
        "category": "Receptionist",
        "for_gender": "2"
    },
    {
        "categoryId": "11",
        "skill": "Client Handling",
        "ID": "271",
        "category": "Manager",
        "for_gender": "1"
    },
    {
        "categoryId": "11",
        "skill": "Client Handling",
        "ID": "276",
        "category": "Manager",
        "for_gender": "2"
    },
    {
        "categoryId": "11",
        "skill": "Billing",
        "ID": "277",
        "category": "Manager",
        "for_gender": "2"
    },
    {
        "categoryId": "11",
        "skill": "Billing",
        "ID": "278",
        "category": "Manager",
        "for_gender": "1"
    },
    {
        "categoryId": "11",
        "skill": "Inventory Manager",
        "ID": "279",
        "category": "Manager",
        "for_gender": "1"
    },
    {
        "categoryId": "11",
        "skill": "Inventory Manager",
        "ID": "280",
        "category": "Manager",
        "for_gender": "2"
    },
    {
        "categoryId": "11",
        "skill": "Staff Management",
        "ID": "281",
        "category": "Manager",
        "for_gender": "2"
    },
    {
        "categoryId": "11",
        "skill": "Staff Management",
        "ID": "282",
        "category": "Manager",
        "for_gender": "1"
    },
    {
        "categoryId": "11",
        "skill": "Staff Recruitment",
        "ID": "283",
        "category": "Manager",
        "for_gender": "1"
    },
    {
        "categoryId": "11",
        "skill": "Staff Recruitment",
        "ID": "284",
        "category": "Manager",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Makeup Theory",
        "ID": "137",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Makeup Theory",
        "ID": "138",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Time Management",
        "ID": "139",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Time Management",
        "ID": "140",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Problem Solving",
        "ID": "141",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Problem Solving",
        "ID": "142",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Management",
        "ID": "143",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Management",
        "ID": "144",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Organizational ",
        "ID": "145",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Organizational ",
        "ID": "146",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Public Speaking",
        "ID": "147",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Public Speaking",
        "ID": "148",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Creativity",
        "ID": "149",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Creativity",
        "ID": "150",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Excellent Presentation",
        "ID": "151",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Excellent Presentation",
        "ID": "152",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Makeup Demo Teaching",
        "ID": "153",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Makeup Demo Teaching",
        "ID": "154",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Advance Makeup",
        "ID": "229",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Advance Makeup",
        "ID": "230",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Bridal Makeup",
        "ID": "231",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Bridal Makeup",
        "ID": "232",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Eye Makeup",
        "ID": "233",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "Eye Makeup",
        "ID": "234",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Fantasy Makeup",
        "ID": "235",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Fantasy Makeup",
        "ID": "236",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "HD Makeup",
        "ID": "237",
        "category": "Make-up Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "12",
        "skill": "HD Makeup",
        "ID": "238",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "12",
        "skill": "Occasion Makeup",
        "ID": "239",
        "category": "Make-up Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "13",
        "skill": "Skin Theory",
        "ID": "269",
        "category": "Beauty Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "13",
        "skill": "Skin Theory",
        "ID": "270",
        "category": "Beauty Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Creativity",
        "ID": "155",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Creativity",
        "ID": "156",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Excellent Presentation",
        "ID": "157",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Excellent Presentation",
        "ID": "158",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Hair Styling Teaching",
        "ID": "159",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Hair Styling Teaching",
        "ID": "160",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Chemical Work Teaching",
        "ID": "161",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Chemical Work Teaching",
        "ID": "162",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Hair Theory",
        "ID": "163",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Hair Theory",
        "ID": "164",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Management",
        "ID": "165",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Management",
        "ID": "166",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Organizational",
        "ID": "167",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Organizational",
        "ID": "168",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Problem Solving",
        "ID": "169",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Problem Solving",
        "ID": "170",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Public Speaking",
        "ID": "171",
        "category": "Hair Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "14",
        "skill": "Public Speaking",
        "ID": "172",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "14",
        "skill": "Time Management",
        "ID": "173",
        "category": "Hair Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "15",
        "skill": "Client consultation",
        "ID": "264",
        "category": "Beauty Advisor / Consultant",
        "for_gender": "2"
    },
    {
        "categoryId": "15",
        "skill": "Client consultation",
        "ID": "265",
        "category": "Beauty Advisor / Consultant",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Massage Consultation",
        "ID": "184",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Massage Consultation",
        "ID": "185",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Regular Massage Therapies",
        "ID": "186",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Regular Massage Therapies",
        "ID": "187",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Advance Massage Therapies",
        "ID": "188",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Advance Massage Therapies",
        "ID": "189",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Wellness Therapies",
        "ID": "190",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Wellness Therapies",
        "ID": "191",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Swedish Massage",
        "ID": "192",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Swedish Massage",
        "ID": "193",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Hot stone Massage",
        "ID": "194",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Hot stone Massage",
        "ID": "195",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Aromatherapy massage",
        "ID": "196",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Aromatherapy massage",
        "ID": "197",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Deep tissue massage",
        "ID": "198",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Deep tissue massage",
        "ID": "199",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Sports massage",
        "ID": "200",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Sports massage",
        "ID": "201",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Basic massage",
        "ID": "202",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Head massage",
        "ID": "203",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Neck massage",
        "ID": "204",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Body massage",
        "ID": "205",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Body massage",
        "ID": "206",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Neck massage",
        "ID": "207",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Head massage",
        "ID": "208",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Trigger point massage",
        "ID": "209",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Trigger point massage",
        "ID": "210",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Reflexology",
        "ID": "211",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Reflexology",
        "ID": "212",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Shiatsu massage",
        "ID": "213",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Shiatsu massage",
        "ID": "214",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Prenatal massage",
        "ID": "215",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "16",
        "skill": "Prenatal massage",
        "ID": "216",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Myofascial release therapy",
        "ID": "217",
        "category": "Massage Therapist",
        "for_gender": "1"
    },
    {
        "categoryId": "16",
        "skill": "Myofascial release therapy",
        "ID": "218",
        "category": "Massage Therapist",
        "for_gender": "2"
    },
    {
        "categoryId": "17",
        "skill": "Regular Haircut",
        "ID": "243",
        "category": "Gents Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "17",
        "skill": "Advance Haircut",
        "ID": "244",
        "category": "Gents Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "17",
        "skill": "Trimming",
        "ID": "266",
        "category": "Gents Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "17",
        "skill": "Shaving",
        "ID": "267",
        "category": "Gents Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "18",
        "skill": "Basic Nail Application",
        "ID": "289",
        "category": "Nail Art Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "18",
        "skill": "Basic Nail Application",
        "ID": "290",
        "category": "Nail Art Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "19",
        "skill": "Eyelash Application",
        "ID": "291",
        "category": "Eyelash Trainer",
        "for_gender": "1"
    },
    {
        "categoryId": "19",
        "skill": "Eyelash Application",
        "ID": "292",
        "category": "Eyelash Trainer",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Bridal Makeup",
        "ID": "12",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Party Makeup",
        "ID": "13",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Eye Makeup",
        "ID": "14",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Eyelash",
        "ID": "15",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Small Jwellery Work",
        "ID": "65",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Advance Makeup",
        "ID": "85",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Airbrush Makeup",
        "ID": "268",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "HD Makeup",
        "ID": "219",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "HD Makeup",
        "ID": "220",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Fantasy Makeup",
        "ID": "221",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Fantasy Makeup",
        "ID": "222",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Prosthetic Makeup",
        "ID": "223",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Prosthetic Makeup",
        "ID": "224",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Occasion Makeup",
        "ID": "225",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Occasion Makeup",
        "ID": "226",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Advance Makeup",
        "ID": "228",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Airbrush Makeup",
        "ID": "285",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Airbrush Makeup",
        "ID": "286",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Basic Makeup",
        "ID": "287",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "2",
        "skill": "Basic Makeup",
        "ID": "288",
        "category": "Makeup Artist",
        "for_gender": "2"
    },
    {
        "categoryId": "2",
        "skill": "Groom Make-up",
        "ID": "298",
        "category": "Makeup Artist",
        "for_gender": "1"
    },
    {
        "categoryId": "20",
        "skill": "Basic Haircut",
        "ID": "299",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Advance Haircut",
        "ID": "300",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Smoothening",
        "ID": "301",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Straightening",
        "ID": "302",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Rebonding",
        "ID": "303",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Keratin",
        "ID": "304",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Botox",
        "ID": "305",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Nano Plastia",
        "ID": "306",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Olaplex",
        "ID": "307",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "20",
        "skill": "Hair Spa",
        "ID": "308",
        "category": "Female Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Basic Hair Styling",
        "ID": "1",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Keratin",
        "ID": "20",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Bridal Hairstyling",
        "ID": "23",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Root Touchup",
        "ID": "24",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Blow Dry",
        "ID": "25",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Curling",
        "ID": "28",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Advance Haircut",
        "ID": "33",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Coloring",
        "ID": "34",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Shaving",
        "ID": "36",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Beard Trim",
        "ID": "37",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Shampoo",
        "ID": "38",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Treatment",
        "ID": "52",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Rebonding",
        "ID": "77",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Styling",
        "ID": "88",
        "category": "Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Advance Haircut",
        "ID": "245",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Basic Haircut",
        "ID": "253",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Advance Hairstyling",
        "ID": "254",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Spa",
        "ID": "293",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Spa",
        "ID": "294",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Root Touch-up",
        "ID": "295",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "3",
        "skill": "Hair Coloring",
        "ID": "296",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "2"
    },
    {
        "categoryId": "3",
        "skill": "Hair Coloring",
        "ID": "297",
        "category": "Unisex Hairdresser / Hairstylist",
        "for_gender": "1"
    },
    {
        "categoryId": "4",
        "skill": "Basic Manicure",
        "ID": "256",
        "category": "Manicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "4",
        "skill": "French Manicure",
        "ID": "257",
        "category": "Manicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "4",
        "skill": "Crystal Manicure",
        "ID": "258",
        "category": "Manicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "4",
        "skill": "Crystal Manicure",
        "ID": "259",
        "category": "Manicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "4",
        "skill": "French Manicure",
        "ID": "260",
        "category": "Manicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "4",
        "skill": "Basic Manicure",
        "ID": "261",
        "category": "Manicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Pedicuring",
        "ID": "26",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Pedicuring",
        "ID": "95",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Shaping Feet Nails",
        "ID": "96",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Cleaning Nails",
        "ID": "97",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Nails Cutting",
        "ID": "98",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Feet Massage",
        "ID": "99",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Nail Clipping",
        "ID": "100",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Dead Skin Removing",
        "ID": "101",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Nail Paints",
        "ID": "102",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "French Nail Paint",
        "ID": "103",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Shatter Nail Paint",
        "ID": "104",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "French Pedicure",
        "ID": "105",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Permanent Nail Paint",
        "ID": "106",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Permanent Nail Paint Removal",
        "ID": "107",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Cleaning Nails",
        "ID": "108",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Dead Skin Removing",
        "ID": "109",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Feet Massage",
        "ID": "110",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "French Pedicure",
        "ID": "112",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Nail Clipping",
        "ID": "113",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Anti-Tan Pedicure",
        "ID": "115",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Anti-Tan Pedicure",
        "ID": "116",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Nails Cutting",
        "ID": "117",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Nail Buffing",
        "ID": "122",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "5",
        "skill": "Nail Buffing",
        "ID": "123",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Feet Mask",
        "ID": "124",
        "category": "Pedicurist",
        "for_gender": "2"
    },
    {
        "categoryId": "5",
        "skill": "Feet Mask",
        "ID": "125",
        "category": "Pedicurist",
        "for_gender": "1"
    },
    {
        "categoryId": "6",
        "skill": "Shaping Nails",
        "ID": "47",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Acrylic Nails Work",
        "ID": "54",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Advance Nail Techniques",
        "ID": "55",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Gel Nail Work",
        "ID": "60",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Nail Art",
        "ID": "61",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Nail Painting",
        "ID": "62",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Synthetic Nail Treatments",
        "ID": "66",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "6",
        "skill": "Gel Nail Paint Application",
        "ID": "272",
        "category": "Nail Art / Technician",
        "for_gender": "1"
    },
    {
        "categoryId": "6",
        "skill": "Gel Nail Paint Application",
        "ID": "273",
        "category": "Nail Art / Technician",
        "for_gender": "2"
    },
    {
        "categoryId": "7",
        "skill": "Haircut",
        "ID": "136",
        "category": "Coloring / Shampoo",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Client Handling",
        "ID": "126",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Basic Salon Knowledge",
        "ID": "127",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Dusting",
        "ID": "128",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Customer Service",
        "ID": "129",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Beard Trimming",
        "ID": "130",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Shaving",
        "ID": "131",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Basic Beauty Knowledge",
        "ID": "132",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Dusting",
        "ID": "133",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Client Handling",
        "ID": "134",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Basic Products Knowledge",
        "ID": "135",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Hygiene Maintenance",
        "ID": "174",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Hygiene Maintenance",
        "ID": "175",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Cleaning Workstation",
        "ID": "176",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Cleaning Workstation",
        "ID": "177",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Cleaning Equipment's",
        "ID": "178",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Cleaning Equipment's",
        "ID": "179",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Assisting",
        "ID": "180",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Assisting",
        "ID": "181",
        "category": "Helper",
        "for_gender": "1"
    },
    {
        "categoryId": "9",
        "skill": "Hospitality",
        "ID": "182",
        "category": "Helper",
        "for_gender": "2"
    },
    {
        "categoryId": "9",
        "skill": "Hospitality",
        "ID": "183",
        "category": "Helper",
        "for_gender": "1"
    }
]