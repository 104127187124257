import React from 'react'
import SlotSelector from './SlotSelector'
import styles from './SlotSelectorWrapper.module.css'

const SlotSelectorWrapper = props => {
    const { isOpen, slotWrapperToggler, getApplicationStatus, applicationIdentifier } = props


    window.onclick = function (event) {
        if (event.target.id == "sswinEachAppStatus") {
            slotWrapperToggler()
        }
    }

    if(!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="sswinEachAppStatus">
                <div className={styles.mainContent}>
                    <SlotSelector 
                        slotFor="INTERVIEW_SLOT_BOOKED"
                        applicationIdentifier={applicationIdentifier}
                        getApplicationStatus={getApplicationStatus}
                    />
                </div>
            </div>
        </div>
    )
}

export default SlotSelectorWrapper