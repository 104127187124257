import React from 'react'
import styles from '../Admin/AdminAddNewPage.module.css'

const AdminAddNewPage = props => {
    const isOpen = props.isOpen;
    const closeAddingModal = props.closeAddingModal;

    window.onclick = function (event) {
        if (event.target.id === "adminAddNewPageModal") {
            closeAddingModal()
            // document.getElementById("AdminNavBox").style.animation = "slideRightToLeft .1s forwards";
        }

    }

    if(!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}
                id="adminAddNewPageModal"
            >
                <div className={styles.mainBox}>
                    AdminAddNewPage
                </div>
            </div>
        </div>
    )
}

export default AdminAddNewPage