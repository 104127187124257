import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import MpaOpeningsDetails from '../../Components/ManagePartnersAccounts/MpaOpeningsDetails';
import MpaStoreDetails from '../../Components/ManagePartnersAccounts/MpaStoreDetails';
import Summary from '../../Components/ManagePartnersAccounts/Summary';
import styles from '../Partners/AccountDetailsWrapper.module.css'

const AccountDetailsWrapper = props => {
    const getStoreListing = props.getStoreListing;
    let selectedStore = props.selectedStore;
    const [headerNavigations, setHeaderNavigations] = useState([
        {
            "title": "Summary",
            "key": "summary",
        },
        {
            "title": "Store Details",
            "key": "storeDetails"
        },
        {
            "title": "Opening Details",
            "key": "openingDetails"
        },
        {
            "title": "Partnership Details",
            "key": "partnershipDetails"
        },
    ])

    const [selectedTab, setSelectedTab] = useState(null);

    return (
        <div className={styles.mainWrapper}>
            {selectedStore !== null ?
                <div>
                    <div className={styles.headerNavigator}>
                        <div className={styles.navigationsWrapper}>
                            {
                                headerNavigations?.map((item, index) => (
                                    <div className={styles.eachNavOption}
                                        key={index}
                                        onClick={() => setSelectedTab(item)}
                                    >
                                        {item?.title}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {selectedTab !== null ?
                        <div>
                            {selectedTab.key === 'summary' &&
                                <Summary
                                    selectedStore={selectedStore}
                                />
                            }
                            {selectedTab.key === 'storeDetails' &&
                                <MpaStoreDetails
                                    selectedStore={selectedStore}
                                    getStoreListing={getStoreListing}
                                />
                            }
                            {selectedTab.key === 'openingDetails' &&
                                <MpaOpeningsDetails
                                    selectedStore={selectedStore}
                                />
                            }
                            {selectedTab.key === 'partnershipDetails' &&
                                <Summary
                                    selectedStore={selectedStore}
                                />
                            }
                        </div>
                        :
                        <div className={styles.noStoreSelected}>
                            <div>
                                Please select a tab to start!
                            </div>
                            <div>
                                <Link to='/manage-partners-account/create-new-account' >Create New Account</Link>
                            </div>
                        </div>
                    }

                </div>
                :
                <div className={styles.noStoreSelected}>
                    <div>
                        Please select a tab to start!
                    </div>
                    <div>
                        <Link to='/manage-partners-account/create-new-account' >Create New Account</Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default AccountDetailsWrapper