import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styles from './CAEJWSalaryRange.module.css'

const CAEJWSalaryRange = (props) => {
    const { minSalary, maxSalary, salaryHandler } = props
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={styles.eachFilterWrapper}>
            <div className={styles.filterHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.filterTitle}>
                    Salary Range
                </div>
                <div>
                    {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                </div>
            </div>
            {isOpen &&
                <div className={styles.filterBody}>
                    <div className={styles.filterCaption}>
                        Include all profile within the following age range.
                    </div>
                    <div className={styles.salaryWrapper}>
                        <div className={styles.eachSalaryInput}>
                            <input placeholder="Minimum salary" placeholder={minSalary} 
                                onChange={(e) => salaryHandler('minSalary', e.target.value)} 
                            />
                        </div>
                        <div className={styles.eachSalaryInput}>
                            <input placeholder="Maximum salary" placeholder={maxSalary} 
                                onChange={(e) => salaryHandler('maxSalary', e.target.value)} 
                            />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CAEJWSalaryRange