import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../ResumeBuilding/RbPICurrentLocationNCordi.module.css'
import Geocode from "react-geocode";

import { usePlacesWidget } from "react-google-autocomplete";
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RbPICurrentLocationNCordi = props => {
    const authCtx = useContext(AuthContext)
    const [suggestions, setSuggestions] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isChanging, setIsChanging] = useState(false)
    let selectedUser = props.selectedUser;

    Geocode.setApiKey("AIzaSyB8zi0FbQWcuOpXbyy2OCpvMSkZbyv2A5U");
    // Geocode.setApiKey("AIzaSyAbDzX2KUBl86bSYZ_tWUQLgLlK1DVQLj0");
    Geocode.setLanguage("en");
    Geocode.setRegion("in");
    Geocode.setLocationType("ROOFTOP");

    const getCordinates = (k) => {
        setSelectedAddress(null)
        // Get latitude & longitude from address.
        Geocode.fromAddress(k).then(
            (response) => {
                setSuggestions(response.results)
                // const { lat, lng } = response.results[0].geometry.location;
                // console.log(lat, lng);
                console.log(response.results)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const { ref } = usePlacesWidget({
        apiKey: 'AIzaSyB8zi0FbQWcuOpXbyy2OCpvMSkZbyv2A5U',
        onPlaceSelected: (place) => {
            console.log(place);
        },
        options: {
            types: ["(in)"],
            componentRestrictions: { country: "in" },
        },
    });

    const updateCandidateCurrentLocation = async () => {
        console.log("Updating current location");
        const updateCandidateCurrentLocationResponse = await fetch(BASE_URL_API + "/updateCandidateCurrentLocation",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    formattedAddress: selectedAddress.formatted_address,
                    latitude: selectedAddress.geometry.location.lat,
                    longitude: selectedAddress.geometry.location.lng,
                    executiveIdentifier : authCtx.userIdentifier
                })
            });

        if (!updateCandidateCurrentLocationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCandidateCurrentLocationRespo = await updateCandidateCurrentLocationResponse.json();
            if (updateCandidateCurrentLocationRespo.status === "success") {
                toast("Current location updated successfully")
            } else {
                if (updateCandidateCurrentLocationRespo.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    toast("Could not update the current location, please try again!")
                }
            }
            console.log(updateCandidateCurrentLocationRespo);
        }
    }

    useEffect(() => {
        if (selectedAddress !== null) {
            isChanging === true && updateCandidateCurrentLocation()
        }
    }, [selectedAddress])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {isChanging === true ?
                <>
                    {selectedAddress === null ?
                        <input placeholder="Enter the address..."
                            onChange={(e) => getCordinates(e.target.value)}
                            autoFocus={true}
                        />
                        :
                        <div className={styles.selectedAddressWrapper}>
                            <div className={styles.selectedAddressTxt}>
                                {selectedAddress.formatted_address}
                            </div>
                            <div
                                className={styles.changeBtn}
                                onClick={() => {
                                    setIsChanging(true)
                                    setSuggestions([])
                                    setSelectedAddress(null)
                                }
                                }
                            >
                                Change
                            </div>
                        </div>
                    }
                </>
                :
                <div className={styles.selectedAddressWrapper}>
                    <div className={styles.selectedAddressTxt}>
                        {selectedUser.currentLocation!=='' ? selectedUser.currentLocation : "Please update user's current location.."}
                    </div>
                    <div
                        className={styles.changeBtn}
                        onClick={() => {
                            setIsChanging(true)
                            setSuggestions([])
                            setSelectedAddress(null)
                        }
                        }
                    >
                        Update
                    </div>
                </div>
            }

            {suggestions.length > 0 &&
                selectedAddress === null &&
                <div className={styles.suggestionsWrapper}>
                    {suggestions.map((item, index) => (
                        <div key={index} className={styles.eachSuggestion}
                            onClick={() => setSelectedAddress(item)}
                        >
                            {item.formatted_address}
                        </div>
                    ))}
                </div>
            }

            {/* <input ref={ref} style={{ width: "90%" }} defaultValue="Noida" /> */}
        </div>
    )
}

export default RbPICurrentLocationNCordi