import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../Tasks/CreateStylistProfile.module.css'


const CreateStylistProfile = props => {
    let navigate = useNavigate();
    const authCtx = useContext(AuthContext)
    const { isOpen, abortCreation, item } = props;
    const [dob, setDob] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [fullName, setFullName] = useState('')


    window.onclick = function (event) {
        if (event.target.id === "leadProfileCreationContainer") {
            setDob('')
            abortCreation()
        }
    };

    const registerStylist = async () => {
        setIsRegistering(true)
        const registerStylistResponse = await fetch(BASE_URL_API + "/registerUser",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier,
                    phone: item.leadIdentifier,
                    fullName: fullName,
                    stylist_gender: item.gender,
                    stylist_birthdate: dob + ` 00:00:01`
                })
            })

        if (!registerStylistResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const registerStylistRespo = await registerStylistResponse.json();
            if (registerStylistRespo.status === 'success') {
                if (registerStylistRespo.response.registrationStatus === '1') {
                    navigate('/manage-users/grow/' + registerStylistRespo.response.userId)
                } else {
                    navigate('/manage-users/grow/' + registerStylistRespo.response.userId)
                }
            } else {
                if (registerStylistRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(registerStylistRespo)
        }
        setIsRegistering(false)
    }


    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="leadProfileCreationContainer"
                style={{ animation: `0.1s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
            >
                <div className={styles.creationForm}>
                    <div className={styles.creationFormHeader}>
                        Creating {item.firstName}'s Profile
                    </div>
                    <div className={styles.creationFormBody}>
                        <div className={styles.eachFieldWrapper}>
                            {item.leadIdentifier}
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <input value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Please enter candidate's full name..." />
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            {item.gender}
                        </div>
                        <div className={styles.eachFieldWrapper}>
                            <input type="date" onChange={(e) => setDob(e.target.value)} value={dob} autoFocus={true} />
                        </div>
                        {isRegistering === false ?
                            <div className={styles.createNowBtn}>
                                {fullName !== '' &&
                                    dob !== '' && <button onClick={() => registerStylist()}>Create Now</button>
                                }
                            </div>
                            :
                            <div className={styles.createNowBtn}>
                                <button>
                                    Creating now...
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateStylistProfile