import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ManageAllBusinessAccount.module.css'

const ManageAllBusinessAccount = () => {
    const authCtx = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [allBusinessAccounts, setAllBusinessAccounts] = useState([])

    const getAllBusinessAccounts = async () => {
        const getAllBusinessAccountsResponse = await fetch(BASE_URL_API + "/getAllBusinessAccounts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token
                })
            });

        if (!getAllBusinessAccountsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllBusinessAccountsRespo = await getAllBusinessAccountsResponse.json()
            if (getAllBusinessAccountsRespo.status === "success") {
                setAllBusinessAccounts(getAllBusinessAccountsRespo.response)
            } else {
                if (getAllBusinessAccountsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllBusinessAccounts([])
                }
            }
            console.log(getAllBusinessAccountsRespo)
        }
    }

    useEffect(() => {
        getAllBusinessAccounts()
    }, [])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    Manage All Business Accounts
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableHeader}>
                            <div className={styles.storeDetails}>
                                Account Details
                            </div>
                            <div className={styles.username}>
                                Username
                            </div>
                            <div className={styles.phoneNumber}>
                                Phone
                            </div>
                            <div className={styles.email}>
                                E-mail
                            </div>
                            <div className={styles.action}>
                                Created On
                            </div>

                        </div>
                        <div className={styles.tableBody}>
                            {isLoading === true ?
                                <div>
                                    <div>Hold On! Loading Data...</div>
                                </div>
                                :
                                allBusinessAccounts?.length > 0 ?
                                    allBusinessAccounts?.map((item, index) => (
                                        <div key={index} className={styles.eachAccountRow} >
                                            <div className={styles.storeDetails}>
                                                <div className={styles.storeName}>
                                                    {item?.company_name}
                                                </div>
                                                <div className={styles.address}>
                                                    {item?.address}
                                                </div>
                                            </div>
                                            <div className={styles.username}>
                                                {item?.username}
                                            </div>
                                            <div className={styles.phoneNumber}>
                                                {item?.phoneNumber}
                                            </div>
                                            <div className={styles.email}>
                                                {item?.email}
                                            </div>
                                            <div className={styles.action}>
                                                {item?.vendorIdentifier}
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div>
                                        <div>
                                            No records found!
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageAllBusinessAccount