import React, { useContext, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './CAEJShowStorePocs.module.css'

const CAEJShowStorePocs = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, togglePocOpen, storeIdentifier } = props
    const [pocData, setPocData] = useState([])

    window.onclick = event => event.target.id === "caejsspid" ? togglePocOpen() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            togglePocOpen()
        }
    });

    const getStorePocs = async () => {
        const getStorePocsResponse = await fetch(BASE_URL_API + "/getStorePocs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier
                })
            });

        if (!getStorePocsResponse.ok) {
            console.log("Something went wrong : Server error")
        } else {
            const getStorePocsRespo = await getStorePocsResponse.json()
            if (getStorePocsRespo.status === "success") {
                setPocData(getStorePocsRespo.response)
            } else {
                if (getStorePocsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setPocData([])
                }
            }
            console.log(getStorePocsRespo.response)
        }
    }

    useEffect(() => {
        getStorePocs()
    }, [storeIdentifier])





    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="caejsspid">
                    <div className={styles.mainContent}>
                        <div className={styles.pocHeader}>
                            <div className={styles.closeBtn}><button onClick={() => togglePocOpen()}>Close</button></div>
                            <div>Store POCs </div>
                            <div className={styles.addEditPoc}>
                                <a target="_blank" href={`/manage-stores/${storeIdentifier}`}>Add / Update POCs</a>
                            </div>
                        </div>
                        <div className={styles.storePocWrapper}>
                            {pocData?.map((item, index) => (
                                <div key={index} className={styles.eachPocWrapper}>
                                    <div className={styles.leftWrapper}>
                                        <div>
                                            <FaUser size={24} />
                                        </div>
                                        <div>
                                            {item?.isPrimary && <div className={styles.primaryTag}>Primary</div>}
                                        </div>

                                    </div>
                                    <div className={styles.contentWrapper}>
                                        <div className={styles.eachRow}>
                                            <div className={styles.personnelName}>
                                                {item?.personnelName}
                                            </div>
                                            <div className={styles.personnelGender}>
                                                {item?.personnelGender}
                                            </div>
                                        </div>
                                        <div className={styles.designationWrapper}>
                                            <div className={styles.designation}>
                                                {item?.designation}
                                            </div>
                                        </div>
                                        <div className={styles.eachRow}>
                                            <div>
                                                {item?.phoneNumber}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default CAEJShowStorePocs