import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../References/urls';
import AuthContext from '../store/auth-context';
import styles from '../Styles/Components/AddNewSkill.module.css'

const AddNewSkill = props => {
    const authCtx = useContext(AuthContext);
    const isVisible = props.isVisible;
    const isVisibleHandler = props.isVisibleHandler;
    const categoryOptions = props.categoryOptions;
    const getCategoryOptions = props.getCategoryOptions;
    const setIsLoading = props.setIsLoading;

    const [skill, setSkill] = useState('');
    const [category, setCategory] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [for_gender, setFor_gender] = useState('');

    const categorySelectHandler = (e) => {
        let category = JSON.parse(e.target.value);
        setCategory(category.category_name);
        setCategoryId(category.ID);
    }

    const genderHandler = (e) => {
        setFor_gender(e)
    }

    const skillHandler = (e) => {
        setSkill(e.target.value);
    }

    const submitSkillHandler = () => {
        addSkill();
        console.log(skill, category, for_gender, categoryId);
    }

    const addSkill = async () => {
        setIsLoading(true);
        const addSkillResponse = await fetch(BASE_URL_API + "/addSkill",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                skill : skill,
                category : category,
                categoryId : categoryId,
                for_gender : for_gender
            })
        });

        if(!addSkillResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const addSkillRespo = await addSkillResponse.json();
            console.log(addSkillRespo);
            isVisibleHandler(false);
            getCategoryOptions();
            
        }
        setIsLoading(false);
    }


    return (
        <div className={isVisible == true ? styles.mainContainer : styles.mainContainerHide} >
            <div className={styles.mainBody}>
                <div >
                    <div className={styles.eachFieldInSkill}>
                        <input
                            placeholder="Enter Skill name..."
                            onChange={(e) => skillHandler(e)}
                        >

                        </input>
                    </div>
                    <div className={styles.eachFieldInSkill}>
                        <select
                            onChange={(e) => categorySelectHandler(e)}
                        >
                            <option>Select Category</option>
                            {categoryOptions != null &&
                                categoryOptions.map((item, index) => (
                                    <option
                                        key={index}
                                        value={JSON.stringify(item)}
                                    >
                                        {item.category_name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={styles.eachFieldInSkill}>
                        <select
                            onChange={(e) => genderHandler(e.target.value)}
                        >
                            <option>Select Gender</option>
                            <option value="2">Female</option>
                            <option value="1">Male</option>
                        </select>
                    </div>
                </div>
                <div className={styles.addNewContainer} >
                    <button onClick={() => isVisibleHandler(false)}>Cancel</button>
                    {skill != "" && category != "" && for_gender != "" &&
                        <button
                            onClick={() => submitSkillHandler()}
                        >
                            Submit
                        </button>}
                </div>
            </div>
        </div>
    )
}

export default AddNewSkill