import React, { useContext, useEffect, useState } from 'react'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './ApplicationNotes.module.css'

const ApplicationNotes = (props) => {
    const { applicationIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [allNotes, setAllNotes] = useState([])
    const [content, setContent] = useState('')
    const [isAdding, setIsAdding] = useState(false)

    const getApplicationNotes = async () => {
        console.log("loading notes...")
        const getApplicationNotesResponse = await fetch(BASE_URL_API + "/getApplicationNotes",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier,
                    addedByIdentifier: authCtx.userIdentifier
                })
            });

        if (!getApplicationNotesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationNotesRespo = await getApplicationNotesResponse.json()
            if (getApplicationNotesRespo.status === "success") {
                setAllNotes(getApplicationNotesRespo?.response)
            } else {
                if (getApplicationNotesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllNotes([])
                }
            }
            console.log(getApplicationNotesRespo)
        }
    }

    useEffect(() => {
        getApplicationNotes()
    }, [applicationIdentifier])

    const addApplicationNote = async () => {
        setIsAdding(true)
        const addApplicationNoteResponse = await fetch(BASE_URL_API + "/addApplicationNote",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifier: applicationIdentifier,
                    // jobIdentifier : jobIdentifier,
                    // stylistIdentifier : stylistIdentifier,
                    content: content,
                    addedByIdentifier: authCtx.userIdentifier,
                    // addedByLabel : addedByLabel
                })
            });

        if (!addApplicationNoteResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addApplicationNoteRespo = await addApplicationNoteResponse.json()
            if (addApplicationNoteRespo.status === "success") {
                getApplicationNotes()
                setContent('')
            } else {
                if (addApplicationNoteRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
        }
        setIsAdding(false)
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.rightContentContainer}>
                <div className={styles.smartNotesWrapper}>
                    <div className={styles.topWrapper}>
                        Smart notes
                    </div>
                    <div className={styles.chatWrapper}>
                        {allNotes?.map((item, index) => (
                            <div key={index} className={styles.eachNoteWrapper} >
                                {item?.content}
                                <div className={styles.timeAgo}>
                                    {humanReadableTimePassed(item?.createdOn)}
                                </div>
                            </div>
                        ))}
                        <p className={styles.thatsAll}>That's all!</p>
                    </div>
                </div>

                <div className={styles.txtWrapper}>
                    <div className={styles.inputWrapper}>
                        <textarea placeholder="Add some notes" value={content} onChange={(e) => setContent(e.target.value)} >

                        </textarea>
                    </div>
                    <div className={styles.addBtnWrapper}>
                    {isAdding === true ? <button>Adding...</button>:<button onClick={() => addApplicationNote()} >Add</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationNotes