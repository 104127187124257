import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from './ManageBusinessAccount.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageBusinessAccount = (props) => {
    const { isOpen, onClose, onSuccess, vendorIdentifier } = props;
    const authCtx = useContext(AuthContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [hasAlreadyCreated, setHasAlreadyCreated] = useState(false)
    const [isCreating, setIsCreating] = useState(false)

    const createBusinessAccount = async () => {
        if (username !== '') {
            if (password !== '') {
                setIsCreating(true)
                const createBusinessAccountResponse = await fetch(BASE_URL_API + "/createBusinessAccount",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            vendorIdentifier: vendorIdentifier,
                            username: username,
                            password: password,
                            executiveIdentifier : authCtx?.userIdentifier
                        })
                    });

                if (!createBusinessAccountResponse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const createBusinessAccountRespo = await createBusinessAccountResponse.json()
                    if (createBusinessAccountRespo.status === "success") {
                        onSuccess()
                    } else {
                        if (createBusinessAccountRespo.message === "token Expired, please login again!") {
                            authCtx.logout()
                        } else {
                            toast.warn(createBusinessAccountRespo?.message)
                        }
                    }
                    console.log(createBusinessAccountRespo)
                }
                setIsCreating(false)
            } else {
                alert("Please enter a valid password")
            }
        } else {
            alert("Please enter a valid username")
        }

    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const sanitizeUsername = (username) => {
        // Ensure it's a string
        if (typeof username !== 'string') {
            return '';
        }

        // Convert to lowercase
        username = username.toLowerCase();

        // Remove non-alphanumeric characters
        username = username.replace(/[^a-z0-9]/g, '');

        // Truncate to maximum length
        username = username.substring(0, 20);

        return username;
    }

    const generatePassword = () => {
        const length = generateRandomNumber(6, 12)
        const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
        const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        const numbers = "0123456789";

        const allCharacters = lowercaseLetters + uppercaseLetters + numbers;

        let passwordTemp = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * allCharacters.length);
            passwordTemp += allCharacters[randomIndex];
        }

        setPassword(passwordTemp);

    }

    useEffect(() => {
        generatePassword()
    }, [])




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    {!!vendorIdentifier ?
                        hasAlreadyCreated === false ?
                            <div className={styles.mainContent}>
                                <div className={styles.mainHeader}>
                                    Create Business Account
                                </div>
                                <div className={styles.mainBody}>
                                    <div className={styles.usernameWrapper}>
                                        <input placeholder="Please enter a username"
                                            onChange={(e) => setUsername(sanitizeUsername(e.target.value))}
                                            value={username}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.passwordWrapper}>
                                        <input placeholder="Please enter a password" value={password} readOnly onClick={() => alert("You can't enter the password manually!")} />
                                        <button onClick={() => generatePassword()} >Generate Password</button>
                                    </div>
                                    <div className={styles.createBtnWrapper}>
                                        {isCreating === true ?
                                            <button>Creating Now...</button>
                                            :
                                            <button onClick={() => createBusinessAccount()}>Create Now</button>
                                        }
                                    </div>
                                </div>
                                <div className={styles.mainFooter}>
                                    <button onClick={() => onClose()} >Close</button>
                                </div>
                            </div>
                            :
                            <div className={styles.mainContent}>
                                <div className={styles.mainHeader}>
                                    Already Created!
                                </div>
                                <div className={styles.mainBody}>
                                </div>
                                <div className={styles.mainFooter}>
                                </div>
                            </div>
                        :
                        <div className={styles.mainContent}>
                            <div className={styles.mainHeader}>
                                Invalid vendor details served!
                            </div>
                            <div className={styles.mainBody}>
                                Kindly go back or refresh the page!
                            </div>
                            <div className={styles.mainFooter}>
                                If problem persists, refresh it again, then contact admin!
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ManageBusinessAccount