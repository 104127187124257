import React, { useContext, useEffect, useState } from 'react'
import styles from '../ResumeBuilding/RbExperience.module.css'
import WorkExperienceCard from './WorkExperienceCard';
import { BiLoaderCircle } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { set } from 'date-fns';

import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
    ShimmerCategoryList,
    ShimmerCard,
} from "react-shimmer-effects";
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import EditWorkExperience from './EditWorkExperience';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RbExperience = props => {
    const authCtx = useContext(AuthContext);
    let selectedProfileType = props.selectedProfileType;
    let selectedUser = props.selectedUser;
    const [addedExperience, setAddedExperience] = useState(null)
    const [fetchingExperiences, setFetchingExperiences] = useState(true)

    const [profileName, setProfileName] = useState('')
    const [institutionName, setInstitutionName] = useState('')
    const [instiAddress, setInstiAddress] = useState('')
    const [workDescription, setWorkDescription] = useState('')
    const [startDate, setStartDate] = useState('');
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(true)
    const [endDate, setEndDate] = useState('')
    const [modeOfSalary, setModeOfSalary] = useState('')
    const [currentMonthlySalary, setCurrentMonthlySalary] = useState('')
    const [reasonOfLeaving, setReasonOfLeaving] = useState('')
    const [isValidated, setIsValidated] = useState(false)
    const [formSubmitStatus, setFormSubmitStatus] = useState('NOT_SUBMITTING'); //NOT_SUBMITTING, SUBMITTING, DONE, FAILED

    const [editingExperience, setEditingExperience] = useState(null)
    const [isEditingExperience, setIsEditingExperience] = useState(false)

    useEffect(() => {
        if (profileName.length > 2) {
            if (institutionName.length > 2) {
                if (instiAddress.length > 2) {
                    if (workDescription.length > 2) {
                        if (modeOfSalary.length > 0) {
                            setIsValidated(true)
                        } else {
                            setIsValidated(false)
                        }
                    } else {
                        setIsValidated(false)
                    }
                } else {
                    setIsValidated(false);
                }
            } else {
                setIsValidated(false)
            }
        } else {
            setIsValidated(false);
        }
    }, [profileName, institutionName, instiAddress, workDescription, modeOfSalary])


    const submitFormHandler = async () => {
        setFormSubmitStatus('SUBMITTING')
        const submitExperienceResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'addNew',
                    sectionType: 'experience',

                    designation: profileName,
                    salon_name: institutionName,
                    start_date: startDate,
                    isCurrentlyWorking: isCurrentlyWorking,
                    end_date: endDate,
                    address: instiAddress,
                    event_description: workDescription,
                    monthlySalary: currentMonthlySalary,
                    modeOfSalary: modeOfSalary,
                    reasonOfLeaving: reasonOfLeaving
                })
            });

        if (!submitExperienceResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const submitExperienceRespo = await submitExperienceResponse.json();
            if (submitExperienceRespo.status === 'success') {
                getExperiences();
                setFormSubmitStatus('DONE')
            } else {
                setFormSubmitStatus('FAILED');
            }
            console.log(submitExperienceRespo);
        }
        console.log(profileName, institutionName, instiAddress, workDescription, startDate, endDate, isCurrentlyWorking, currentMonthlySalary, modeOfSalary)
    }

    const addNewExperienceHandler = () => {
        setFormSubmitStatus('NOT_SUBMITTING')
        setProfileName('');
        setInstitutionName('');
        setInstiAddress('');
        setWorkDescription('');
        setStartDate('');
        setEndDate('');
        setIsCurrentlyWorking(false);
        setModeOfSalary('')
    }

    const tryAgainHandler = () => {
        setFormSubmitStatus('NOT_SUBMITTING');
    }

    const getExperiences = async () => {
        setFetchingExperiences(true)
        const getExperiencesResponse = await fetch(BASE_URL_API + "/addMoreSections",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    dataType: 'updated',
                    sectionType: 'experience',
                })
            });

        if (!getExperiencesResponse.ok) {
            console.log("Something went wrong : server Error!");
        } else {
            const getExperiencesRespo = await getExperiencesResponse.json();
            if (getExperiencesRespo.status === 'success') {
                setAddedExperience(getExperiencesRespo.response);
            } else {
                if (getExperiencesRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    console.log(getExperiencesRespo);
                }
            }
        }
        setFetchingExperiences(false)
    }

    useEffect(() => {
        getExperiences()
    }, [selectedUser])

    const editExperienceHandler = (k) => {
        setEditingExperience(k)
        setIsEditingExperience(true)
    }

    const editExperienceToggle = () => {
        setIsEditingExperience(!isEditingExperience)
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <EditWorkExperience 
                item={editingExperience}
                isEditingExperience={isEditingExperience}
                editExperienceToggle={editExperienceToggle}
                getExperiences={getExperiences}
                toast={toast}
            />
            <div className={styles.leftWrapper}>
                <div className={styles.headerWrapper}>
                    {selectedProfileType} - {addedExperience?.length}
                </div>
                <div className={styles.bodyWrapper}>
                    {fetchingExperiences === true ?
                        <div>
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                            <ShimmerThumbnail height={160} className={styles.containerLoader} />
                        </div>
                        :
                        <div>
                            {addedExperience !== null ?
                                <div>
                                    {addedExperience.map((item, index) => (
                                        <WorkExperienceCard
                                            key={index}
                                            item={item}
                                            editExperienceHandler={editExperienceHandler}
                                        />
                                    ))}
                                </div>
                                :
                                <div>
                                    No records found!
                                </div>
                            }

                        </div>
                    }

                </div>
            </div>
            <div className={styles.rightWrapper}>
                {formSubmitStatus === 'SUBMITTING' ?
                    <>
                        <div className={styles.rightHeaderWrapper}>
                            Adding new {selectedProfileType}...
                        </div>
                        <div className={styles.rightBodyWrapper}>
                            <div className={styles.rightBodyLoader}>
                                <div className={styles.rightBodyLoaderContainer}>
                                    <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" />
                                    <div className={styles.rightBodyLoaderContent}>
                                        <p>
                                            Hold on, buddy!
                                        </p>
                                        <p>
                                            I'm just adding the candidate experience!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightFooterWrapper}>

                        </div>
                    </>
                    :
                    formSubmitStatus === 'NOT_SUBMITTING' ?
                        <>
                            <div className={styles.rightHeaderWrapper}>
                                Add new {selectedProfileType}
                            </div>
                            <div className={styles.rightBodyWrapper}>
                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Enter the profile name
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input placeholder="Beautician / Manager ..."
                                            onChange={(e) => setProfileName(e.target.value)}
                                            value={profileName}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Salon / Institution / Company Name
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input placeholder="Salon / Institution / Parlor / Company Name..."
                                            onChange={(e) => setInstitutionName(e.target.value)}
                                            value={institutionName}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input placeholder="Address of your work area..."
                                            onChange={(e) => setInstiAddress(e.target.value)}
                                            value={instiAddress}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Description About your work!
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <textarea
                                            onChange={(e) => setWorkDescription(e.target.value)}
                                            value={workDescription}
                                            placeholder="What kind of work candidate use to do there.. "
                                        ></textarea>
                                    </div>

                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Start Date of Work
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="date"
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />

                                    </div>
                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Are you currently working at
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <button className={isCurrentlyWorking === false ? styles.optionBtnSelected : styles.optionBtn}
                                            onClick={() => setIsCurrentlyWorking(false)}
                                        >
                                            No
                                        </button>
                                        <button className={isCurrentlyWorking === true ? styles.optionBtnSelected : styles.optionBtn}
                                            onClick={() => setIsCurrentlyWorking(true)}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>

                                {isCurrentlyWorking === false &&
                                    <div className={styles.eachFieldWrapper}>
                                        <div className={styles.fieldTitle}>
                                            Last date of work
                                        </div>
                                        <div className={styles.fieldInput}>
                                            <input type="date"
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Current Monthly Salary
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text"
                                            onChange={(e) => setCurrentMonthlySalary(e.target.value)}
                                            value={currentMonthlySalary}
                                        />
                                    </div>
                                </div>

                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Mode of Salary Credit
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <button onClick={() => setModeOfSalary('Cash')}
                                            className={modeOfSalary === 'Cash' ? styles.optionBtnSelected : styles.optionBtn}
                                        >
                                            Cash
                                        </button>
                                        <button onClick={() => setModeOfSalary('Online')}
                                            className={modeOfSalary === 'Online' ? styles.optionBtnSelected : styles.optionBtn}
                                        >
                                            Online
                                        </button>
                                    </div>
                                </div>


                                <div className={styles.eachFieldWrapper}>
                                    <div className={styles.fieldTitle}>
                                        Reason of leaving
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <textarea placeholder="Why did the candidate leave this job?"
                                            onChange={(e) => setReasonOfLeaving(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>




                            </div>
                            <div className={styles.rightFooterWrapper}>
                                <div className={styles.rightFooterContainer}>
                                    <button className={styles.secondaryBtn}>Cancel</button>
                                    {isValidated === true ?
                                        <button className={styles.primaryBtn}
                                            onClick={() => submitFormHandler()}
                                        >
                                            Save
                                        </button>
                                        :
                                        <button className={styles.primaryBtnDull}>Save</button>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        formSubmitStatus === 'DONE' ?
                            <>
                                <div className={styles.rightHeaderWrapper}>
                                    Adding new {selectedProfileType}...
                                </div>
                                <div className={styles.rightBodyWrapper}>
                                    <div className={styles.rightBodyLoader}>
                                        <div className={styles.rightBodyLoaderContainer}>
                                            <div>
                                                <IoCheckmarkDoneCircleOutline
                                                    size={44}
                                                    color="green"
                                                />
                                            </div>
                                            <div className={styles.rightBodyLoaderContent}>
                                                <p>
                                                    Successfully Added!
                                                </p>
                                                <button className={styles.addNewBtn}
                                                    onClick={() => addNewExperienceHandler()}
                                                >
                                                    Add New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.rightFooterWrapper}>

                                </div>
                            </>
                            :
                            formSubmitStatus === 'FAILED' ?
                                <>
                                    <div className={styles.rightHeaderWrapper}>
                                        Adding new {selectedProfileType}...
                                    </div>
                                    <div className={styles.rightBodyWrapper}>
                                        <div className={styles.rightBodyLoader}>
                                            <div className={styles.rightBodyLoaderContainer}>
                                                <div>
                                                    <ImCancelCircle
                                                        size={40}
                                                        color='red'
                                                    />
                                                </div>
                                                <div className={styles.rightBodyLoaderContent}>
                                                    <p>
                                                        Updation failed!
                                                    </p>
                                                    <button
                                                        className={styles.addNewBtn}
                                                        onClick={() => tryAgainHandler()}
                                                    >
                                                        Please try again!
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.rightFooterWrapper}>

                                    </div>
                                </>
                                :
                                <div>
                                    -
                                </div>
                }

            </div>
        </div>
    )
}

export default RbExperience