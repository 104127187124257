import React, { useContext, useEffect, useState } from 'react';
import styles from './PlanServices.module.css';
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';

const PlanServices = (props) => {
    const { isOpen, olderPlanBenefits, agreementIdentifier, getAgreementDetails, closeSetup, onSuccess } = props;
    const authCtx = useContext(AuthContext)

    const [planBenefits, setPlanBenefits] = useState(olderPlanBenefits);
    const [isUpdatingBenefit, setIsUpdatingBenefit] = useState(false)

    const featureToggler = (title) => {
        setPlanBenefits(prevBenefits => {
            const exists = prevBenefits.find(item => item.title === title);
            if (exists) {
                return prevBenefits.filter(item => item.title !== title);
            } else {
                return [...prevBenefits, { title: title, value: null }];
            }
        });
    };

    const updateFeatureValue = (title, value) => {
        // console.log("Going to upload : ", title, value)
        setPlanBenefits(prevBenefits =>
            prevBenefits.map(benefit =>
                benefit.title === title ? { ...benefit, value } : benefit
            )
        );
    };


    const updateBenefits = async () => {
        // console.log(planBenefits, agreementIdentifier)
        setIsUpdatingBenefit(true)
        const updatePlanBenefitsInAgreementResponse = await fetch(BASE_URL_API + "/updatePlanBenefitsInAgreement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier,
                    benefits: JSON.stringify(planBenefits)
                })
            });

        if (!updatePlanBenefitsInAgreementResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updatePlanBenefitsInAgreementRespo = await updatePlanBenefitsInAgreementResponse.json()
            if (updatePlanBenefitsInAgreementRespo.status === "success") {
                onSuccess()
            } else {
                if (updatePlanBenefitsInAgreementRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }

            console.log(updatePlanBenefitsInAgreementRespo)
        }
        setIsUpdatingBenefit(false)

    }

    // console.log(planBenefits)

    useEffect(() => {
        if (!isOpen) {
            getAgreementDetails()
        }
    }, [isOpen])





    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <div>
                            <div className={styles.headerWrapper}>
                                <div>
                                    <div>Toggle Benefit Details</div>
                                </div>
                                <div>
                                    <button onClick={() => closeSetup()} >Cancel</button>
                                    {isUpdatingBenefit === true ?
                                        <button >Updating Benefit...</button>
                                        :
                                        <button onClick={() => updateBenefits()} >Update Benefit</button>
                                    }
                                </div>
                            </div>
                            <div className={styles.tableBodyWrapper}>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Annual Fee")}>
                                        {planBenefits.find(item => item?.title === "Annual Fee") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Annual Fee</div>
                                    {planBenefits.some(item => item?.title === "Annual Fee") === true ?
                                        <div className={styles.actionWrapper}><input placeholder="" value={planBenefits?.find(item => item?.title === "Annual Fee").value} onChange={(e) => updateFeatureValue("Annual Fee", e.target.value)} /></div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Placement Fee")}>
                                        {planBenefits.find(item => item?.title === "Placement Fee") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Placement Fee</div>
                                    {planBenefits.some(item => item?.title === "Placement Fee") === true ?
                                        <div className={styles.actionWrapper}><input placeholder="" value={planBenefits?.find(item => item?.title === "Placement Fee").value} onChange={(e) => updateFeatureValue("Placement Fee", e.target.value)} /></div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Replacement Period")}>
                                        {planBenefits.find(item => item?.title === "Replacement Period") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Replacement Period</div>
                                    {planBenefits.some(item => item?.title === "Replacement Period") === true ?
                                        <div className={styles.actionWrapper}><input placeholder="" value={planBenefits?.find(item => item?.title === "Replacement Period").value} onChange={(e) => updateFeatureValue("Replacement Period", e.target.value)} /></div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Placement Within")}>
                                        {planBenefits.find(item => item?.title === "Placement Within") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Placement Within</div>
                                    {planBenefits.some(item => item?.title === "Placement Within") === true ?
                                        <div className={styles.actionWrapper}><input placeholder="" value={planBenefits?.find(item => item?.title === "Placement Within").value} onChange={(e) => updateFeatureValue("Placement Within", e.target.value)} /></div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Pre-scanning Interviews")}>
                                        {planBenefits.find(item => item?.title === "Pre-scanning Interviews") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Pre-scanning Interviews</div>
                                    {planBenefits.some(item => item?.title === "Pre-scanning Interviews") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Pre-scanning Interviews", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Pre-scanning Interviews").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Pre-scanning Interviews", "No")}
                                                className={planBenefits?.find(item => item?.title === "Pre-scanning Interviews").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Pre-scanning Demo / Trial")}>
                                        {planBenefits.find(item => item?.title === "Pre-scanning Demo / Trial") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Pre-scanning Demo / Trial</div>
                                    {planBenefits.some(item => item?.title === "Pre-scanning Demo / Trial") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Pre-scanning Demo / Trial", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Pre-scanning Demo / Trial").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Pre-scanning Demo / Trial", "No")}
                                                className={planBenefits?.find(item => item?.title === "Pre-scanning Demo / Trial").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Employee Verification")}>
                                        {planBenefits.find(item => item?.title === "Employee Verification") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Employee Verification</div>
                                    {planBenefits.some(item => item?.title === "Employee Verification") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Employee Verification", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Employee Verification").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Employee Verification", "No")}
                                                className={planBenefits?.find(item => item?.title === "Employee Verification").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Background Check")}>
                                        {planBenefits.find(item => item?.title === "Background Check") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Background Check</div>
                                    {planBenefits.some(item => item?.title === "Background Check") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Background Check", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Background Check").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Background Check", "No")}
                                                className={planBenefits?.find(item => item?.title === "Background Check").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Offer letters")}>
                                        {planBenefits.find(item => item?.title === "Offer letters") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Offer letters</div>
                                    {planBenefits.some(item => item?.title === "Offer letters") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Offer letters", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Offer letters").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Offer letters", "No")}
                                                className={planBenefits?.find(item => item?.title === "Offer letters").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Agreements")}>
                                        {planBenefits.find(item => item?.title === "Agreements") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Agreements</div>
                                    {planBenefits.some(item => item?.title === "Agreements") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Agreements", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Agreements").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>

                                            <button onClick={() => updateFeatureValue("Agreements", "No")}
                                                className={planBenefits?.find(item => item?.title === "Agreements").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Payroll Management")}>
                                        {planBenefits.find(item => item?.title === "Payroll Management") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Payroll Management</div>
                                    {planBenefits.some(item => item?.title === "Payroll Management") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Payroll Management", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Payroll Management").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Payroll Management", "No")}
                                                className={planBenefits?.find(item => item?.title === "Payroll Management").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Performance Reports")}>
                                        {planBenefits.find(item => item?.title === "Performance Reports") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Performance Reports</div>
                                    {planBenefits.some(item => item?.title === "Performance Reports") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Performance Reports", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Performance Reports").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Performance Reports", "No")}
                                                className={planBenefits?.find(item => item?.title === "Performance Reports").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Training")}>
                                        {planBenefits.find(item => item?.title === "Training") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Training</div>
                                    {planBenefits.some(item => item?.title === "Training") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Training", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Training").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Training", "No")}
                                                className={planBenefits?.find(item => item?.title === "Training").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>
                                <div className={styles.eachRowWrapper}>
                                    <div className={styles.icon} onClick={() => featureToggler("Dedicated Support")}>
                                        {planBenefits.find(item => item?.title === "Dedicated Support") ? <BsToggleOn size={30} /> : <BsToggleOff size={30} />}
                                    </div>
                                    <div className={styles.benefit} >Dedicated Support</div>
                                    {planBenefits.some(item => item?.title === "Dedicated Support") === true ?
                                        <div className={styles.actionWrapper}>
                                            <button onClick={() => updateFeatureValue("Dedicated Support", "Yes")}
                                                className={planBenefits?.find(item => item?.title === "Dedicated Support").value === "Yes" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                Yes
                                            </button>
                                            <button onClick={() => updateFeatureValue("Dedicated Support", "No")}
                                                className={planBenefits?.find(item => item?.title === "Dedicated Support").value === "No" ? styles.activeOption : styles.inactiveOption}
                                            >
                                                No
                                            </button>
                                        </div>
                                        :
                                        <div className={styles.actionWrapper}></div>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default PlanServices;
