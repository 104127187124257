import React, { useContext, useState } from 'react'
import AuthContext from '../../store/auth-context'
import styles from './EachCourseLevelInterestForm.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL_API } from '../../References/urls';

const EachCourseLevelInterestForm = props => {
    const authCtx = useContext(AuthContext)
    const { leadIdentifier, activeProgram, activeDomain, level, scaleHolder, updateLevelHandler } = props
    const [isUpdating, setIsUpdating] = useState(false)


    const updateInterestHandler = async (l, interestLevel) => {
        setIsUpdating(true)
        const updateCourseLeadInterestLevelResponse = await fetch(BASE_URL_API + "/updateCourseLeadInterestLevel",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: leadIdentifier,
                    programType: activeProgram,
                    domain: activeDomain,
                    programLevel: l,
                    interestLevel: interestLevel,
                    addedBy : authCtx.userIdentifier
                })
            });

        if (!updateCourseLeadInterestLevelResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCourseInterestLevelRespo = await updateCourseLeadInterestLevelResponse.json();
            if (updateCourseInterestLevelRespo.status === "success") {
                toast.success("Successfully updated the interest level")
            } else {
                if (updateCourseInterestLevelRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(updateCourseInterestLevelRespo)
                }
            }
            console.log(updateCourseInterestLevelRespo.message)
        }
        setIsUpdating(false)
    }


    return (
        <div className={styles.eachLevelWrapper}>
            <ToastContainer />
            <div className={styles.levelTitle}>
                <button>Basic</button>
            </div>
            <div>
                <div className={styles.scaleReference}>
                    <div>Lowest</div>
                    <div>Highest</div>
                </div>
                <div className={styles.scaleBlocks}>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "1" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '1')}
                    >
                        1
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "2" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '2')}
                    >
                        2
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "3" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '3')}
                    >
                        3
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "4" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '4')}
                    >
                        4
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "5" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '5')}
                    >
                        5
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "6" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '6')}
                    >
                        6
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "7" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '7')}
                    >
                        7
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "8" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '8')}
                    >
                        8
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "9" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '9')}
                    >
                        9
                    </button>
                    <button className={scaleHolder.find(item => item.level === level)?.scale === "10" ? styles.selectedScaleBlocks : styles.defaultScaleBlocks}
                        onClick={() => updateLevelHandler(level, '10')}
                    >
                        10
                    </button>
                </div>
                <div className={styles.actionWrapper}>
                    {isUpdating === true ?
                        <div className={styles.pleaseWaitBtn}>Please wait... </div>
                        :
                        (scaleHolder.find(item => item.level === level)?.scale !== null ?
                            <button className={styles.saveBtn} onClick={() => updateInterestHandler(level, scaleHolder.find(item => item.level === level)?.scale)} >Save</button>
                            :
                            <div className={styles.selectToUpdateTxt}>Select To Update</div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default EachCourseLevelInterestForm