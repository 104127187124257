import React from 'react'
import styles from './QPJobTitle.module.css'

const QPJobTitle = (props) => {
    const { jobTitle, jobTitleHandler, currentSlideHandler } = props

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.optionTitle}>
                        Enter Job Title
                    </div>
                    <div className={styles.optionsWrapper}>
                        <input placeholder="Please enter job title...." autoFocus
                            value={jobTitle}
                            onChange={(e) => jobTitleHandler(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.nextBtnWrapper}>
                    <button onClick={() => currentSlideHandler('Skills')}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default QPJobTitle