import React, { useContext, useEffect, useState } from 'react'
import styles from './ManageEachJob.module.css'
import { IoMdLink } from "react-icons/io";
import { AiOutlineClose, AiFillDelete } from "react-icons/ai";
import { FaExternalLinkAlt, FaUsers, FaRupeeSign } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { IoIosListBox } from "react-icons/io";
import UpdateJobPaidStatus from '../../Components/ManageJobs/UpdateJobPaidStatus';
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import { Link } from 'react-router-dom';
import CAEJShowStorePocs from '../../Components/CreateApplications/CAEJShowStorePocs';
import CAEJShowJobDescription from '../../Components/CreateApplications/CAEJShowJobDescription';
import { getTimeDifferenceHumanReadable, humanReadableTimePassed } from '../../Helper/helpers';
import JTApplicationsData from '../../Components/Jobs/JobsTracker/JTApplicationsData';
import { getExecutiveFullName } from '../../Helper/executivesData';








const ManageEachJob = () => {
    const authCtx = useContext(AuthContext)
    const { jobIdentifier } = useParams()
    const [isUpdatingJobPayingStatus, setIsUpdatingJobPayingStatus] = useState(false)
    const toggleJobPayingStatus = () => setIsUpdatingJobPayingStatus(false)
    const [jobResponse, setJobResponse] = useState(null)
    const [isShowingPoc, setIsShowingPoc] = useState(false)
    const [isShowingJd, setIsShowingJd] = useState(false)
    const [isShowingApplicationData, setIsShowingApplicationData] = useState(false)

    const getJobDetailsForTask = async () => {
        const getJobDetailsForTaskResponse = await fetch(BASE_URL_API + "/getJobDetailsForTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    jobIdentifier: jobIdentifier
                })
            });

        if (!getJobDetailsForTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getJobDetailsForTaskRespo = await getJobDetailsForTaskResponse.json()
            if (getJobDetailsForTaskRespo.status === "success") {
                setJobResponse(getJobDetailsForTaskRespo?.response)
            } else {
                if (getJobDetailsForTaskRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setJobResponse(null)
                }
            }

        }
    }

    useEffect(() => {
        getJobDetailsForTask()
    }, [])

    const getPocHandler = () => setIsShowingPoc(!isShowingPoc)
    const getJDHandler = () => setIsShowingJd(!isShowingJd)
    const getAppliedCandidatesHandler = () => setIsShowingApplicationData(!isShowingApplicationData)

    console.log(jobResponse)


    return (
        <div className={styles.mainWrapper}>
            {isUpdatingJobPayingStatus === true && <UpdateJobPaidStatus isOpen={isUpdatingJobPayingStatus} onClose={toggleJobPayingStatus} jobIdentifier={jobIdentifier} />}
            {isShowingPoc === true && <CAEJShowStorePocs isOpen={isShowingPoc} togglePocOpen={getPocHandler} storeIdentifier={jobResponse?.store_id} />}
            {isShowingJd && <CAEJShowJobDescription isOpen={isShowingJd} jd={jobResponse} closeHandler={getJDHandler} />}
            {/* {isCurrentStatusOpen && <CAEJCurrentStatusToggler isOpen={isCurrentStatusOpen} onClose={currentStatusPopUpToggle} onSuccess={getJobDetailsForTask} jobIdentifier={jobIdentifier} defaultStatus={jobDetails?.currentStatus} />} */}
            {isShowingApplicationData && <JTApplicationsData isOpen={isShowingApplicationData} viewApplicationHandler={getAppliedCandidatesHandler} jobIdentifier={jobIdentifier} />}

            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.eachActionBox} onClick={() => getPocHandler()} title="Show Store POCs" >
                        <MdPhone size={20} />
                    </div>
                    <div className={styles.eachActionBox} onClick={() => getJDHandler()} title="Show JD" >
                        <IoIosListBox size={20} />
                    </div>
                    <div className={styles.eachActionBox} onClick={() => getAppliedCandidatesHandler()} title="Show Applied Candidates">
                        <FaUsers size={20} />
                    </div>
                    <div className={styles.eachActionBox}>
                        <IoMdLink size={20} />
                    </div>
                    <div className={styles.eachActionBox}>
                        <AiFillDelete size={20} />
                    </div>
                    <div className={styles.eachActionBox}>
                        <AiOutlineClose size={20} />
                    </div>
                    <div className={styles.eachActionBox} onClick={() => setIsUpdatingJobPayingStatus(true)}>
                        <FaRupeeSign size={20} />
                    </div>
                    <Link to={`/create-applications/${jobIdentifier}`} className={styles.eachActionBox}>
                        <FaExternalLinkAlt size={20} />
                    </Link>
                </div>
                <div className={styles.rightColWrapper}>
                    <div className={styles.mainHeaderWrapper}>
                        <div>
                            {jobResponse?.company_name}
                        </div>
                        <div>
                            {jobResponse?.address}
                        </div>
                        <div>
                            {jobResponse?.currentStatus}
                        </div>
                        <div>
                            {jobResponse?.job_category_name}
                        </div>
                    </div>
                    <div className={styles.mainBodyWrapper}>

                        <div className={styles.timelineWrapper}>
                            {jobResponse?.currentStatus !== 'JOB_ALLOTED' &&
                                <div className={styles.eachEventWrapper}>
                                    <div className={styles.eventTitle}>
                                        <div className={styles.eventTag}>
                                            {jobResponse?.currentStatus}
                                        </div>
                                    </div>
                                    <div className={styles.eventDescription}>
                                        Last status reported as {jobResponse?.currentStatus} marked {getTimeDifferenceHumanReadable(jobResponse?.lastAllotedOn, jobResponse?.lastStatusUpdatedOn)} by {getExecutiveFullName(jobResponse?.lastStatusUpdatedBy)} {humanReadableTimePassed(jobResponse?.lastStatusUpdatedOn)}
                                    </div>
                                </div>
                            }
                            {jobResponse?.isPaidHiring !== null &&
                                <div className={styles.eachEventWrapper}>
                                    <div className={styles.eventTitle}>
                                        <div className={styles.eventTag}>
                                            {jobResponse?.isPaidHiring === "1" && `Paid Hiring`}
                                            {jobResponse?.isPaidHiring === "0" && `Unpaid Hiring`}
                                        </div>
                                    </div>
                                    <div className={styles.eventDescription}>
                                        {jobResponse?.isPaidHiring === "1" ? `Job marked as Paid Hiring` : `Job marked as Unpaid Hiring`} by {getExecutiveFullName(jobResponse?.paidHiringMarkedBy)} on {jobResponse?.paidHiringMarkedOn}
                                    </div>
                                </div>
                            }
                            <div className={styles.eachEventWrapper}>
                                <div className={styles.eventTitle}>
                                    <div className={styles.eventTag}>
                                        Job Alloted
                                    </div>
                                </div>
                                <div className={styles.eventDescription}>
                                    Job Alloted To {getExecutiveFullName(jobResponse?.lastAllotedTo)} {getTimeDifferenceHumanReadable(jobResponse?.creation_date, jobResponse?.lastAllotedOn)} on {jobResponse?.lastAllotedOn}
                                </div>
                            </div>
                            <div className={styles.eachEventWrapper}>
                                <div className={styles.eventTitle}>
                                    <div className={styles.eventTag}>
                                        Job Posted
                                    </div>
                                </div>
                                <div className={styles.eventDescription}>
                                    Posted Job {humanReadableTimePassed(jobResponse?.creation_date)}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageEachJob