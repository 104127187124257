import React, { useContext, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import TEachAudioCard from '../../Components/Trainings/TEachAudioCard'
import AuthContext from '../../store/auth-context'
import styles from './THome.module.css'

const THome = () => {
    const authCtx = useContext(AuthContext)

    const [audios, setAudios] = useState([
        {
            count : 1,
            type: "Labelled",
            profile: "Beautician",
            languageTags: [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1684393685664.wav"
        },
        {
            count : 2,
            type: "Labelled",
            profile: "Make-up Artist",
            languageTags: [
                "Hindi", "English", "Hinglish"
            ],
            candidateNature : "resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "2 Years approx.",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1684405525410.wav"
        },
        {
            count : 3,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Freshers",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1684561861013.wav"
        },
        {
            count : 4,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1684581053056.wav"
        },
        {
            count : 5,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1685090075850.wav"
        },
        {
            count : 6,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Candidate's Relative",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1685090752226.wav"
        },
        {
            count : 7,
            type: "Unknown",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1685093063949.wav"
        },
        {
            count : 8,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/record-1685094495451.wav"
        },
        {
            count : 9,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/AUD-20230605-WA0002.aac"
        },
        {
            count : 10,
            type: "Labelled",
            profile: "Beautician",
            languageTags : [
                "Hindi"
            ],
            candidateNature : "less resistive",
            candidateGender : "Female",
            candidateExperienceLevel : "Experienced",
            receiverType : "Self",
            src: "https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-candidate-call-samples/AUD-20230605-WA0001.aac"
        }
    ])
    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                    <Link to="/training">
                        <div className={styles.eachHeaderNavigation}>
                            Trainings
                        </div>
                    </Link>
                </div>
                <div>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.optionContainer}>
                {audios.map((item, index) => (
                    <TEachAudioCard item={item} key={index} />
                ))}
            </div>
        </div>
    )
}

export default THome