import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Course/AddNewSubModule.module.css';

const AddNewSubModule = props => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    authCtx.token === "" && navigate("/");

    const setIsLoading = props.setIsLoading;
    const getSubModules = props.getSubModules;

    const courseId = props.courseId;
    const moduleId = props.moduleId;
    const [addSubModule, setAddSubModule] = useState(false);
    const [subModuleOrder, setSubModuleOrder] = useState('');
    const [subModuleTitle, setSubModuleTitle] = useState('');
    const [subModuleSubTitle, setSubModuleSubTitle] = useState('');
    const [subModuleType, setSubModuleType] = useState('');

    // console.log(courseId, moduleId)

    

    const addCourseSubModule = async () => {
        setIsLoading(true);
        const addCourseSubModuleResponse = await fetch(BASE_URL_API + "/addCourseSubModule",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    courseId: courseId,
                    moduleId: moduleId,
                    subModuleOrder: subModuleOrder,
                    subModuleTitle: subModuleTitle,
                    subModuleSubTitle: subModuleSubTitle,
                    subModuleType: subModuleType,
                })
            });

        if (!addCourseSubModuleResponse.ok) {
            alert("Something went wrong : Server Error!");
        } else {
            const addCourseSubModuleRespo = await addCourseSubModuleResponse.json();
            if (addCourseSubModuleRespo.status == "success") {
                getSubModules();
            } else {
                navigate("/");
            }
            console.log(addCourseSubModuleRespo);
        }
        setIsLoading(false);
    }

    // console.log(subModuleTitle, subModuleSubTitle, subModuleOrder, subModuleType)
    return (
        <div className={styles.mainContainer}>
            <div className={styles.addNewSubModuleContainer}>
                {addSubModule == false ?
                    <div className={styles.addNewWrapper}>
                        <button onClick={() => setAddSubModule(true)}>Add New Submodule +</button>
                    </div>
                    :
                    <div className={styles.addNewSubModuleFields}>
                        <div className={styles.eachFieldContainer}>
                            <input placeholder="Enter submodule title..." onChange={(e) => setSubModuleTitle(e.target.value)} ></input>
                        </div>
                        <div className={styles.eachFieldContainer}>
                            <input placeholder="Enter subModule Sub title..." onChange={(e) => setSubModuleSubTitle(e.target.value)} ></input>
                        </div>
                        <div className={styles.eachFieldContainer}>
                            <select onChange={(e) => setSubModuleType(e.target.value)}>
                                <option value="">Select SubModule Type</option>
                                <option value="VIDEO">VIDEO</option>
                                <option value="IMAGE">IMAGE</option>
                                <option value="PDF">PDF</option>
                                <option value="CLIPS">CLIPS</option>
                                <option value="ASSESSMENT">ASSESSMENT</option>
                            </select>
                            <select onChange={(e) => setSubModuleOrder(e.target.value)}>
                                <option value="">Select Sub Module Order</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className={styles.actionBtns}>
                            <button onClick={() => setAddSubModule(false)}>Cancel</button>
                            {subModuleTitle != "" && subModuleSubTitle != "" && subModuleType != "" && subModuleOrder != "" &&
                                <button onClick={() => addCourseSubModule()}>Submit</button>}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default AddNewSubModule