import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbPIExpectedSalary.module.css'

const RbPIExpectedSalary = props => {
    let selectedUser = props.selectedUser;
    const authCtx = useContext(AuthContext)

    const [showSubmitBtn, setShowSubmitBtn] = useState(false)
    const [minimumSalaryExpectation, setMinimumSalaryExpectation] = useState(selectedUser.minimumSalaryExpectation);
    const [salaryUpdationStatus, setSalaryUpdationStatus] = useState('NOT_UPDATING');  //UPDATING, UPDATED, FAILED

    const salaryEnterHandler = (k) => {
        setMinimumSalaryExpectation(k);
        if (k > 0) {
            setShowSubmitBtn(true)
        } else {
            setShowSubmitBtn(false)
        }
    }

    const updateSalaryExpectation = async () => {
        setSalaryUpdationStatus('UPDATING')
        setShowSubmitBtn(false)
        const updateSalaryExpectationResponse = await fetch(BASE_URL_API + "/updateSalaryExpectation",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    stylist_id: selectedUser.stylist_id,
                    minimumSalaryExpectation: minimumSalaryExpectation
                })
            });

        if (!updateSalaryExpectationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateSalaryExpectationRespo = await updateSalaryExpectationResponse.json();
            if (updateSalaryExpectationRespo.status === 'success') {
                console.log(updateSalaryExpectationRespo)
                setSalaryUpdationStatus('UPDATED')
            } else {
                if (updateSalaryExpectationRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setSalaryUpdationStatus('FAILED')
                    console.log(updateSalaryExpectationRespo);
                }
            }
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                Expected minimum salary
            </div>
            <div className={styles.mainBody}>
                <input
                    placeholder="Expected minimum salary..."
                    onChange={(e) => salaryEnterHandler(e.target.value)}
                    type="number"
                    value={minimumSalaryExpectation}
                />
                {showSubmitBtn === true &&
                    <button
                        onClick={() => updateSalaryExpectation()}
                    >
                        Update
                    </button>
                }
                {salaryUpdationStatus === "UPDATING" ?
                    <div className={styles.beingUpdated}>
                        Salary is being updated...
                    </div>
                    : salaryUpdationStatus === "UPDATED" ?
                        <div className={styles.updated}>
                            Updated!
                        </div>
                        : salaryUpdationStatus === "FAILED" ?
                            <div className={styles.failed}>
                                Updation failed!
                            </div>
                            :
                            <div></div>
                }
            </div>
        </div>
    )
}

export default RbPIExpectedSalary