import React, { useContext, useEffect, useState } from 'react'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { humanReadableTimePassed } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './EachJobDetails.module.css'

const EachJobDetails = props => {
    const { jobData } = props
    const authCtx = useContext(AuthContext)
    const [isFetchingStoreDetails, setIsFetchingStoreDetails] = useState(true);
    const [storeDetails, setStoreDetails] = useState(null);

    const getSalonStoreDetails = async () => {
        if (jobData.store_id != "") {
            setIsFetchingStoreDetails(true);
            const getSalonStoreDetailsResponse = await fetch(BASE_URL_API + "/getStoreDetails",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        storeIdentifier: jobData.store_id
                    })
                });

            if (!getSalonStoreDetailsResponse.ok) {
                alert("Something went wrong, server error, could not fetched salon details!")
            } else {
                const getSalonStoreDetailsRespo = await getSalonStoreDetailsResponse.json();
                if (getSalonStoreDetailsRespo.status == "success") {
                    setStoreDetails(getSalonStoreDetailsRespo.response);
                } else {
                    if (getSalonStoreDetailsRespo.message == "token Expired, please login again!") {
                        // alert("Session expired! Please login again!");
                        authCtx.logout();
                    } else {
                        console.log(getSalonStoreDetailsRespo)
                    }
                }
            }
            setIsFetchingStoreDetails(false);
        } else {
            console.log(jobData)
        }
    }

    useEffect(() => {
        if (jobData !== "") {
            getSalonStoreDetails();
        }
    }, [jobData.job_id]);

    console.log(storeDetails)

    return (
        <div>
            {isFetchingStoreDetails === true ?
                <div className={styles.salonDetailsSectionLoader}>
                    <ShimmerThumbnail />
                </div>
                :
                storeDetails !== null &&
                <div className={styles.salonDetailsSection}>
                    <div className={styles.eachDetailsField}>
                        <div className={styles.eachDetailsFieldTitle}>Store Details</div>
                        <div className={styles.eachDetailsFieldValue}>{storeDetails.company_name}</div>
                        <div className={styles.address}>{storeDetails.address}</div>
                    </div>
                    <div className={styles.threeCol}>
                        <div className={styles.eachDetailsField}>
                            <div className={styles.eachDetailsFieldTitle}>Service Type</div>
                            <div className={styles.eachDetailsFieldValue}>{storeDetails.serviceType}</div>
                        </div>
                        <div className={styles.eachDetailsField}>
                            <div className={styles.eachDetailsFieldTitle}>Tier</div>
                            <div className={styles.eachDetailsFieldValue}>{storeDetails.tier}</div>
                        </div>
                        <div className={styles.eachDetailsField}>
                            <div className={styles.eachDetailsFieldTitle}>Total Member</div>
                            <div className={styles.eachDetailsFieldValue}>{storeDetails.totalMember}</div>
                        </div>
                    </div>

                    <div className={styles.threeCol}>
                        <div className={styles.eachDetailsField}>
                            <div className={styles.eachDetailsFieldTitle}>Working Days</div>
                            <div className={styles.eachDetailsFieldValue}>{storeDetails.workingDays}</div>
                        </div>
                        <div className={styles.eachDetailsField}>
                            <div className={styles.eachDetailsFieldTitle}>Member Since</div>
                            <div className={styles.eachDetailsFieldValue}>{humanReadableTimePassed(storeDetails.created_on)}</div>
                        </div>

                    </div>
                    <div className={styles.threeCol}>
                        {jobData.jobRoute != "" &&
                            <a target="_blank" href={"https://stylelink.in/job" + "/" + jobData.jobRoute} className={styles.eachDetailsField}>
                                View Job At Website
                            </a>}
                        <a target="_blank" href={"https://lokaci.com/" + storeDetails.routeCity + "/" + storeDetails.route} className={styles.eachDetailsField}>
                            Visit Salon
                        </a>
                    </div>


                    <div>


                        <div>{jobData.job_id}</div>
                        <div className={styles.jobCategoryContainer}>
                            <h3>{jobData.job_category_name}</h3>
                        </div>
                        <div className={styles.jobCategoryContainer}>
                            <h3>Salary Range</h3>
                            &#8377;{jobData.salary_min} - &#8377;{jobData.salary_max}
                        </div>
                        <div className={styles.skillContainer}>
                            <h3>Skills</h3>
                            {jobData.skill}
                        </div>
                        <div className={styles.description}
                            dangerouslySetInnerHTML={{ __html: jobData.description }}
                        >
                        </div>
                        <div className={styles.openingType}>
                            {jobData.opening_type}
                        </div>
                    </div>


                </div>
            }
        </div>
    )
}

export default EachJobDetails