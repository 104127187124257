import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context';
import styles from '../ManageJobs/EachJobHeader.module.css'
import { TbView360 } from "react-icons/tb";

const EachJobHeader = props => {
    const { activeTab, activeTabHandler } = props
    const authCtx = useContext(AuthContext);
    let jobData = props.jobData;
    const getAllJobs = props.getAllJobs;
    const [categoriesData, setCategoriesData] = useState([])

    const getCategoryOptions = async () => {
        const getCategoryOptionsResponse = await fetch(BASE_URL_API + "/getCategoryOptions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getCategoryOptionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCategoryOptionsRespo = await getCategoryOptionsResponse.json();
            if (getCategoryOptionsRespo.status === 'success') {
                setCategoriesData(getCategoryOptionsRespo.response);
            } else {
                if (getCategoryOptionsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    console.log(getCategoryOptionsRespo)
                }
            }
        }
    }

    useEffect(() => {
        getCategoryOptions();
    }, [])

    const updateJobCategory = async (k) => {
        if (jobData.job_id !== "") {
            const updateJobCategoryResponse = await fetch(BASE_URL_API + "/updateJobCategory",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        job_id: jobData.job_id,
                        categoryToUpdate: JSON.parse(k)
                    })
                });

            if (!updateJobCategoryResponse.ok) {
                console.log("Something went wrong : Server Error!");
            } else {
                const updateJobCategoryRespo = await updateJobCategoryResponse.json();
                if (updateJobCategoryRespo.status === 'success') {
                    getAllJobs();
                    // console.log(updateJobCategoryRespo.response);
                } else {
                    if (updateJobCategoryRespo.message === "token Expired, please login again!") {
                        authCtx.logout();
                    } else {
                        console.log(updateJobCategoryRespo.response);
                    }
                }
            }
        } else {
            alert("Something went wrong : Sorry! Contact Admin!");
        }
    }

    console.log(activeTab, "activetab at header")

    return (
        <div className={styles.jobHeaderContainer}>
            <div className={styles.upperSectionWrapper}>
                <div className={styles.jobCategoryContainer}>
                    <h3>{jobData.title}</h3>
                    <select
                        onChange={(e) => updateJobCategory(e.target.value)}
                    >
                        {categoriesData.map((item, index) => (
                            <option key={index} selected={item.ID === jobData.job_category_id ? true : false}
                                value={JSON.stringify(item)}
                            >
                                {item.category_name}
                            </option> 
                        ))}
                    </select>
                </div>
                <div>
                    <div title="View job on stylelink website" className={styles.eachNavBtn}>
                        <TbView360 size={28} color="#00000086" />
                    </div>
                    <div></div>
                </div>
            </div>
            <div className={styles.navigatorWrapper}>
                <button className={activeTab==="CD" ? styles.activeBtn : styles.defBtn} onClick={() => activeTabHandler("CD")} >Contacts Details</button>
                <button className={activeTab==="JD" ? styles.activeBtn : styles.defBtn} onClick={() => activeTabHandler("JD")} >Job Details</button>
            </div>

        </div>
    )
}

export default EachJobHeader