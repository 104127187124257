import React, { useContext, useEffect, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import AuthContext from '../../store/auth-context'
import styles from '../Tasks/TasksHome.module.css'
import CreateStylistProfile from '../../Components/Tasks/CreateStylistProfile'
import ActionWrapper from './ActionWrapper'
import EachMiniLead from '../../Components/Tasks/EachMiniLead'
import { BASE_URL_API } from '../../References/urls'
import EachLargeLead from '../../Components/Tasks/EachLargeLead'
import LeadsSummaryInTask from './LeadsSummaryInTask'

const TasksHome = () => {
    const authCtx = useContext(AuthContext)
    const [availableLeads, setAvailableLeads] = useState([])
    const [currentLead, setCurrentLead] = useState('')
    const [isCollectingLeads, setIsCollectingLeads] = useState(true)
    
    const updateCurrentLead = (k) => {
        setCurrentLead(currentLead.leadIdentifier !== k.leadIdentifier ? k : '')
    }

    const [isOpen, setIsOpen] = useState(false)
    const abortCreation = () => setIsOpen(false)
    const initiateProfileCreation = () => setIsOpen(true)

    const [actionCreationOpen, setActionCreationOpen] = useState(false)
    const startActionCreation = () => {
        setActionCreationOpen(true)
    }
    const abortActionCreation = () => {
        setActionCreationOpen(false)
    }

    const adminGetLeadsForTask = async () => {
        setIsCollectingLeads(true)
        const adminGetLeadsForTaskResponse = await fetch(BASE_URL_API + "/adminGetLeadsForTask",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    executiveIdentifier: authCtx.userIdentifier
                })
            });

        if (!adminGetLeadsForTaskResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const adminGetLeadsForTaskRespo = await adminGetLeadsForTaskResponse.json();
            if (adminGetLeadsForTaskRespo.status === 'success') {
                setAvailableLeads(adminGetLeadsForTaskRespo.response)
            } else {
                if (adminGetLeadsForTaskRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAvailableLeads([])
                    console.log(adminGetLeadsForTaskRespo)
                }
            }
        }
        setIsCollectingLeads(false)
    }

    useEffect(() => {
        adminGetLeadsForTask()
    }, [])

    const updateLeadAction = async (action, actionDetails) => {


        const updateLeadActionResponse = await fetch(BASE_URL_API + "/updateLeadAction",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    leadIdentifier: currentLead.leadIdentifier,
                    executiveIdentifier: authCtx.userIdentifier,
                    action: action,
                    actionDetails: actionDetails
                })
            });

        if (!updateLeadActionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateLeadActionRespo = await updateLeadActionResponse.json();
            if (updateLeadActionRespo.status === 'success') {
                if(action==="LEAD_SKIPPED") {
                    setCurrentLead('')
                    adminGetLeadsForTask()
                }
            } else {
                if (updateLeadActionRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateLeadActionRespo);
        }


    }

    useEffect(() => {
        if (currentLead !== '') {
            updateLeadAction('LEAD_OPENED', 'Lead successfully opened for action')
        }
    }, [currentLead])


    return (
        <div className={styles.mainContainer}>
            <CreateStylistProfile
                isOpen={isOpen}
                abortCreation={abortCreation}
                item={currentLead}
            />
            <ActionWrapper
                isOpen={actionCreationOpen}
                abortActionCreation={abortActionCreation}
                initiateProfileCreation={initiateProfileCreation}
                updateLeadAction={updateLeadAction}
            />
            <div className={styles.header}>
                <div className={styles.headerNavigation}>
                    <Link to="/">
                        <div className={styles.eachHeaderNavigation}>
                            Home
                        </div>
                    </Link>
                    <Link to="/performance-summary">
                        <div className={styles.eachHeaderNavigation}>
                            Summary
                        </div>
                    </Link>
                    <Link to="/tasks">
                        <div className={styles.eachHeaderNavigation}>
                            Tasks
                        </div>
                    </Link>
                </div>
                <div>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout />
                    </button>
                </div>
            </div>
            <div className={styles.optionContainer}>
                <LeadsSummaryInTask />
                <div className={styles.contentBodyWrapper}>
                    {isCollectingLeads === true ?
                        <div className={styles.leadListingWrapper}>
                            <div className={styles.leadListingWrapperHead}>
                                <div className={styles.lookingForLeads}>Looking for the leads..</div>
                            </div>

                            <div className={styles.leadListingWrapperBody}>
                                <div className={styles.collectionLeadMsg}>
                                    <div>
                                        Hold for a moment!
                                    </div>
                                    <div>
                                        Looking for any assigned available leads!
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.leadListingWrapper}>
                            {availableLeads.length > 0 ?
                                <div className={styles.leadListingWrapperHead}>
                                    <div className={styles.newTag}>New</div>
                                    <div className={styles.leadCountTag}>{availableLeads.length} Leads</div>
                                </div>
                                :
                                <div className={styles.leadListingWrapperHead}>
                                    <div>No new leads available</div>
                                </div>
                            }

                            <div className={styles.leadListingWrapperBody}>
                                {availableLeads.map((item, index) => (
                                    <EachMiniLead
                                        key={index}
                                        item={item}
                                        isCurrentLead={item.leadIdentifier === currentLead.leadIdentifier ? true : false}
                                        updateCurrentLead={updateCurrentLead}
                                    />
                                ))}
                            </div>
                        </div>
                    }

                    <div className={styles.eachLeadDetailWrapper}>
                        <div className={styles.eldwLeft}>
                            {currentLead !== '' ?
                                <EachLargeLead 
                                    currentLead={currentLead} 
                                    updateLeadAction={updateLeadAction}
                                    startActionCreation={startActionCreation}
                                    initiateProfileCreation={initiateProfileCreation}
                                />
                                :
                                <div className={styles.leadProfileSummaryWrapper}>
                                    <div className={styles.noLeadsSelected}>
                                        No lead selected!
                                    </div>
                                </div>
                            }

                        </div>
                        <div className={styles.eldwRight}>
                            <div className={styles.willLiveSoon}>
                                Updates will be live soon...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TasksHome