import React from 'react'
import styles from './AppCalendarFilter.module.css'


const AppCalendarFilter = props => {
    const { isFilterOpen, acfCloser, appCurrentStatus, appCurrentStatusHandler, allExecutivesData, selectedExecutive, selectExecutiveHandler } = props
    window.onclick = event => event.target.id === "acfContainer" ? acfCloser() : null

    console.log(allExecutivesData)

    if (!isFilterOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="acfContainer">
                <div className={styles.mainContent}>
                    <div>
                        <div className={styles.statusTitle}>
                            Select Executive 
                        </div>
                        <div className={styles.executiveWrapper}>
                            <select onChange={(e) => selectExecutiveHandler(e.target.value)}>
                                <option value="">Select Executive</option>
                                {allExecutivesData.map((item, index) => (
                                    <option key={index} value={item?.userID} >{item?.firstName} {item?.middleName} {item?.lastName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={styles.statusWrapper}>
                        <div className={styles.statusTitle}>
                            Application Status
                        </div>
                        <div className={styles.statusContainer}>
                            <button
                                onClick={() => appCurrentStatusHandler("ALL")}
                                className={appCurrentStatus === "ALL" ? styles.activeBtn : styles.defBtn}
                            >
                                All
                            </button>
                            <button
                                onClick={() => appCurrentStatusHandler("ONGOING")}
                                className={appCurrentStatus === "ONGOING" ? styles.activeBtn : styles.defBtn}
                            >
                                Ongoing
                            </button>
                            <button
                                onClick={() => appCurrentStatusHandler("HIRED")}
                                className={appCurrentStatus === "HIRED" ? styles.activeBtn : styles.defBtn}
                            >
                                Hired
                            </button>
                            <button
                                onClick={() => appCurrentStatusHandler("REJECTED")}
                                className={appCurrentStatus === "REJECTED" ? styles.activeBtn : styles.defBtn}
                            >
                                Rejected
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppCalendarFilter