import React, { useEffect, useRef, useState } from 'react'
import { humanReadableTimePassed } from '../../../Helper/helpers';
import styles from '../ProfileRemarks/PREachChat.module.css'

const PREachChat = props => {
    let remarks = props.remarks;
    let getProfileRemarks = props.getProfileRemarks;
    const [called, setCalled] = useState(false);


    const divRef = useRef(null);

    useEffect(() => {
        divRef.current.scrollTop = divRef.current.scrollHeight;
    }, [divRef]);

    // const handleScroll = () => {
    //     if (divRef.current.scrollTop + divRef.current.clientHeight >= divRef.current.scrollHeight) {
    //         // console.log("Reached bottom");
    //     } else if (divRef.current.scrollTop <= 0 && called!==true) {
    //         setCalled(true);
    //         getProfileRemarks(10)
    //         // console.log("Reached Top");
    //     } else {
    //         // console.log(divRef.current.scrollTop);
    //     }
    //     // console.log("Is called : ", called)
    // }

    // useEffect(() => {
    //     divRef.current.addEventListener('scroll', handleScroll);
    // }, [])

    


    return (
        <div className={styles.mainWrapper}
            ref={divRef}
            // onScroll={handleScroll}
        >
            {remarks.length > 0 ?
                <div className={styles.mainContainer}
                >
                    {remarks.map((item, index) => (
                        <div key={index} className={styles.eachChatMainWrapper}>
                            <div className={styles.chatBox}>
                                <div className={styles.chatTxt}>
                                    {item.content}
                                </div>
                                <div className={styles.timeTxt}>
                                    <div className={styles.addedByName}>{item?.addedByName!=='Admin' && "By "+item?.addedByName}</div>
                                    {humanReadableTimePassed(item.addedOn)}
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                :
                <div className={styles.notAdded}>
                    You haven't added any remarks yet!
                </div>
            }
        </div>
    )
}

export default PREachChat