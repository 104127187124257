import React, { useContext, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { FaFilter } from 'react-icons/fa'
import { AiOutlineClose } from "react-icons/ai";
import AuthContext from '../../../store/auth-context';
import styles from '../ManageAcademies/RDMAcadSearchBar.module.css'
import RDMAcademiesAddNew from './RDMAcademiesAddNew';
import { BASE_URL_API } from '../../../References/urls';
import RDMAcademiesEachParent from './RDMAcademiesEachParent';

const RDMAcadSearchBar = () => {
    const authCtx = useContext(AuthContext);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [resultsData, setResultsData] = useState([])


    const searchKeyHandler = async (k) => {
        if (k.length > 2) {
            setIsFetchingData(true)
            const getSearchedParentAcademiesResponse = await fetch(BASE_URL_API + "/getSearchedParentAcademies",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        searchKey: k
                    })
                });

            if (!getSearchedParentAcademiesResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const getSearchedParentAcademiesRespo = await getSearchedParentAcademiesResponse.json();
                if (getSearchedParentAcademiesRespo.status === 'success') {
                    setResultsData(getSearchedParentAcademiesRespo.response)
                } else {
                    if (getSearchedParentAcademiesRespo.message === 'token Expired, please login again!') {
                        authCtx.logout()
                    } else {
                        setResultsData([])
                        console.log(getSearchedParentAcademiesRespo.response);
                    }
                }
                setIsFetchingData(false)
            }
        } else {
            setIsFetchingData(false)
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.searchBarWrapper}>
                {showSearchBox === false ?
                    <div className={styles.mainContainer}>
                        <div className={styles.leftSection}>

                        </div>
                        <div className={styles.rightSection}>
                            <div className={styles.eachBtnWrapper}
                                onClick={() => setShowSearchBox(true)}
                            >
                                <BsSearch />
                            </div>
                            <div className={styles.eachBtnWrapper}>
                                <FaFilter />
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.mainContainer}>
                        <div className={styles.leftSection}>
                            <div className={styles.eachBtnWrapper}
                                onClick={() => setShowSearchBox(false)}
                            >
                                <BiArrowBack />
                            </div>
                            <div className={styles.eachInputWrapper}>
                                <input
                                    placeholder="Search for anything..."
                                    onChange={(e) => searchKeyHandler(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>

            {isFetchingData === true ?
                <div className={styles.searchResultsWrapper}>
                    <div className={styles.fetchingMsg}>
                        Finding the best results for you...
                    </div>
                </div>
                :
                <div className={styles.searchResultsWrapper}>
                    {resultsData.length > 0 ?
                        <div className={styles.suggestedListWrapper}>
                            <div className={styles.suggestedListHeader}>{resultsData.length} result(s) found!</div>
                            <div className={styles.suggestedListBody}>
                                {resultsData.map((item, index) => (
                                    <RDMAcademiesEachParent 
                                        key={index}
                                        item={item}
                                    />
                                ))}
                            </div>
                        </div>
                        :
                        <div className={styles.addNewListWrapper}>
                            <div className={styles.nrfBox}>
                                No results found!
                            </div>
                            <RDMAcademiesAddNew

                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default RDMAcadSearchBar