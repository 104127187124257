import React, { useContext, useEffect, useState } from 'react'
import { BsDot } from 'react-icons/bs'
import { MdArrowBack, MdChevronRight } from 'react-icons/md'
import { TbCornerRightUpDouble } from 'react-icons/tb'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getAge } from '../../Helper/helpers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './GSResults.module.css'

const GSResults = () => {
    const { key } = useParams()
    const authCtx = useContext(AuthContext)
    const [results, setResults] = useState([])

    const globalSearchResults = async () => {
        const globalSearchResultsResponse = await fetch(BASE_URL_API + "/globalSearchResults",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    key: key
                })
            });

        if (!globalSearchResultsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const globalSearchResultsRespo = await globalSearchResultsResponse.json()
            if (globalSearchResultsRespo.status === "success") {
                setResults(globalSearchResultsRespo.response)
            } else {
                if (globalSearchResultsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(globalSearchResultsRespo)
        }
    }

    useEffect(() => {
        globalSearchResults()
    }, [key])

    console.log(results)
    console.log(results?.profileLeadsData?.firstName)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.upperHeader}>
                    <Link to={"/global-search"} >
                        <div className={styles.backBtn}>
                            <MdArrowBack />
                        </div>
                        <div className={styles.searchKey}>
                            {key}
                        </div>
                    </Link>
                </div>
                <div className={styles.resultsCount}>
                    10 results found!
                </div>
            </div>

            <div className={styles.mainBody}>
                <div className={styles.leftWrapper}>
                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionMainTitle}>
                            <span> User Profile </span> <BsDot size={25} color="#00000060" /><span className={styles.sectionSubTitle}>  Stylist that has been registered with us.</span>
                        </div>

                        <div>
                            {results?.profileCreatedData !== null ?
                                <div>
                                    {/* {JSON.stringify(results?.profileCreatedData)} */}
                                    {results?.profileCreatedData?.map((item, index) => (
                                        <div>
                                            <div>
                                                <div className={styles.pdWrapper}>
                                                    <div className={styles.stylistName}>
                                                        {item?.stylist_name}, &nbsp;
                                                    </div>
                                                    <div className={styles.ageNGender}>
                                                        {getAge(item?.stylist_birthdate)} years old, {item?.stylist_gender}
                                                    </div>
                                                </div>

                                                <div className={styles.domainWrapper}>
                                                    {item?.primaryWorkDomain} {item?.secondaryWorkDomain !== "" ? "cum " + item?.secondaryWorkDomain : ""} {item?.tertiaryWorkDomain !== "" ? "cum " + item?.tertiaryWorkDomain : ""}
                                                </div>

                                                <div className={styles.registeredOn}>
                                                    Registered On {item?.registred_on} via {item?.registeredVia === "ADMIN" ? "Admin" : "Mobile App"}
                                                </div>
                                            </div>
                                            <div>

                                                <div className={styles.currentLocation}>
                                                    {item?.currentLocation}
                                                </div>
                                            </div>
                                            <div className={styles.viewProfileBtn}>
                                                <a href={"/manage-users/grow/" + item?.stylist_id} target="_blank" >View complete profile</a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div>
                                    No profile has been created with the number.
                                </div>
                            }
                        </div>
                    </div>

                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionMainTitle}>
                            <span>Profile Leads </span> <BsDot size={25} color="#00000060" /><span className={styles.sectionSubTitle}>  Leads that has been added to our system to create profile.</span>
                        </div>
                        {/* {JSON.stringify(results?.profileLeadsData)} */}
                        {results?.profileLeadsData?.map((item, index) => (
                            <div key={index}>
                                <div className={styles.leadTimeline}>
                                    <div className={styles.eachEvent}>
                                        Added on {item?.addedOn}
                                        <MdChevronRight size={20} />
                                    </div>
                                    {item?.assignedTo !== null ?
                                        <div className={styles.eachEvent}>
                                            Assigned on {item?.assignedOn}
                                            <MdChevronRight size={20} />
                                        </div>
                                        :
                                        ''
                                    }
                                    {item?.isInvalid === "0" ?
                                        <div className={styles.ifContacted}>
                                            <div className={styles.eachEvent}>
                                                Contacted on {item?.leadValidatedOn}
                                                <MdChevronRight size={20} />
                                            </div>
                                            <div className={styles.eachEvent}>
                                                Profile created on {item?.convertedOn}
                                            </div>
                                        </div>
                                        :
                                        <div>

                                        </div>
                                    }
                                </div>

                                <div>
                                    {item?.leadSource}
                                </div>

                                <div>
                                    {item?.firstName} {item?.lastName}, {item?.gender}, {item?.city}
                                </div>
                                <div>
                                    {item?.assignedTo === null ?
                                        <span>
                                            Haven't assigned to anyone!
                                        </span>
                                        :
                                        <span>
                                            Assigned to {item?.executiveFirstName} {item?.executiveLastName}
                                        </span>
                                    }
                                </div>
                                <div>
                                    {item?.isInvalid === "0" ?
                                        <div>
                                            <div>
                                                Lead converted By {item?.executiveFirstName} {item?.executiveLastName}
                                            </div>

                                        </div>
                                        :
                                        <div>
                                            <div>
                                                Lead marked invalid on {item?.markInvalidOn}
                                            </div>
                                            <div className={styles.invalidReason}>
                                                {item?.invalidReason}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {item?.isConverted === "0" &&
                                    <div className={styles.goToLeadBtn}>
                                        <Link to={"/tasks/lead/" + item?.phone}>
                                            Go to Lead
                                        </Link>
                                    </div>
                                }
                            </div>
                        ))}

                    </div>

                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionMainTitle}>
                            <span>Salon Partners </span> <BsDot size={25} color="#00000060" /><span className={styles.sectionSubTitle}>  Leads that has been added to our system to create profile.</span>
                        </div>
                        <div>

                        </div>
                    </div>

                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionMainTitle}>
                            <span>Salon Leads </span> <BsDot size={25} color="#00000060" /><span className={styles.sectionSubTitle}>  Leads that has been added to our system to create profile.</span>
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
                <div className={styles.rightWrapper}>

                </div>
            </div>
        </div>
    )
}

export default GSResults