import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../store/auth-context';
import styles from '../ResumeBuilding/RbAcademyInstiSelector.module.css'

const RbAcademyInstiSelector = props => {
    let selectAcademyHandler = props.selectAcademyHandler;
    let selectedAcademy = props.selectedAcademy;

    const authCtx = useContext(AuthContext);
    const [showSuggestedList, setShowSuggestedList] = useState(false)
    const [suggestedList, setSuggestedList] = useState([])

    const getSuggestedInstitutionsList = async (k) => {
        if (k.length > 2) {
            setShowSuggestedList(true)

            const getSuggestedInstitutionsListResponse = await fetch(BASE_URL_API+"/getSuggestedInstitutionsList",
            {
                method : 'POST',
                body : JSON.stringify({
                    token : authCtx.token,
                    searchKey : k
                })
            });

            if(!getSuggestedInstitutionsListResponse.ok) {
                console.log("Something went Wrong : Server Error");
            } else {
                const getSuggestedInstitutionsListRespo = await getSuggestedInstitutionsListResponse.json();
                if(getSuggestedInstitutionsListRespo.status==='success') {
                    setSuggestedList(getSuggestedInstitutionsListRespo.response);
                } else {
                    if(getSuggestedInstitutionsListRespo.status==='failed') {
                        if(getSuggestedInstitutionsListRespo.message==='no results found!') {
                            setSuggestedList([])
                        } else {

                        }
                    }
                }
                console.log(getSuggestedInstitutionsListRespo);
            }
        } else {
            setShowSuggestedList(false)

        }
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerWrapper}>
                <div>
                    <div className={styles.fieldTitle}>
                        Institution Name
                    </div>
                    <div className={styles.subCaption}>
                        Enter the name of institution from where you have done course / certificate / diploma / workshop?
                    </div>
                </div>

                {selectedAcademy === '' ?
                    <div className={styles.inputField}>
                        <input
                            onChange={(e) => getSuggestedInstitutionsList(e.target.value)}
                            className={showSuggestedList === true ? styles.inputBoxLater : styles.inputBox}
                            placeholder="search for any academy..."
                        />
                        {showSuggestedList === true &&
                            <div className={styles.suggestedListWrapper}>
                                <div className={styles.suggestedListHeader}>
                                    Suggested academies - ({suggestedList.length} results found!)
                                </div>
                                <div className={styles.suggestedListBody}>
                                    <div className={styles.eachElementWrapper}
                                        onClick={() => selectAcademyHandler({
                                            "academy_id" : "LOCAL",
                                            "academy_name" : "LOCAL",
                                            "academy_address" : "LOCAL"
                                        })}
                                    >
                                        <div className={styles.instiName}>
                                            Local Parlor / Academy
                                        </div>
                                        <div className={styles.instiAddress}>
                                            Local
                                        </div>
                                    </div>
                                    {suggestedList.map((item, index) => (
                                        <div className={styles.eachElementWrapper}
                                            key={index}
                                            onClick={() => selectAcademyHandler(item)}
                                        >
                                            <div className={styles.instiName}>
                                                {item.academy_name}
                                            </div>
                                            <div className={styles.instiAddress}>
                                                {item.academy_address}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>

                    :
                    <div className={styles.selectedInputField}>
                        <div className={styles.selectedInstiWrapper}>
                            <div className={styles.selectedInstiName}>
                                {selectedAcademy.academy_name}
                            </div>
                        </div>
                        <div className={styles.changeBtnWrapper}>
                            <button onClick={() => selectAcademyHandler('')}>Change</button>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default RbAcademyInstiSelector