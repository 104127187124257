import React from 'react'

import styles from '../Admin/AdminHome.module.css'
import AdminNavigator from './AdminNavigator'

const AdminHome = () => {
    return (
        <div className={styles.mainWrapper}>
            <AdminNavigator />
            <div className={styles.mainContainer}>
                <div className={styles.leftWrapper}>
                    <div>
                        Welcome to Stylelink Admin
                    </div>

                </div>
                <div className={styles.rightWrapper}>
                    AdminHome
                </div>
            </div>
        </div>
    )
}

export default AdminHome